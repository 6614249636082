import {httpPost} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

const filterAndTransformForSave = (state) => {
    return Object.entries(state)
        .filter(([, value]) => value)
        .map(([key]) => key);
};

export const transformSelectedStateForSaveRequest = ({
    allSchools = {
        selectedSchools: {},
        selectedClusters: {},
    },
    mySchools = {
        selectedSchools: {},
        selectedClusters: {},
    },
    selectedFilterMode,
}) => {
    const transformedMySchools = {
        selectedSchools: filterAndTransformForSave(
            mySchools.selectedSchools || {},
        ),
        selectedClusters: filterAndTransformForSave(
            mySchools.selectedClusters || {},
        ),
    };

    const transformedAllSchools = {
        selectedSchools: filterAndTransformForSave(
            allSchools.selectedSchools || {},
        ),
        selectedClusters: filterAndTransformForSave(
            allSchools.selectedClusters || {},
        ),
    };

    return {
        mySchools: transformedMySchools,
        allSchools: transformedAllSchools,
        selectedFilterMode,
    };
};

export const saveFilterSettingsService = ({
    allSchools,
    mySchools,
    selectedFilterMode,
}) => {
    const postData = transformSelectedStateForSaveRequest({
        allSchools,
        mySchools,
        selectedFilterMode,
    });
    return httpPost(url.GROUP_GLOBAL_FILTERS.SAVE_FILTER_SETTINGS, postData);
};
