import {isToday, isYesterday, isTomorrow, format} from 'date-fns';

import externalConfig from 'Config/External';

export const DEFAULT_KPI_CACHE_LIFE_TIME = 300000; // 5 minutes
export const DEFAULT_KPI_DATA_DEBOUNCE_TIME = 100; // 100 ms
export const DEFAULT_KPI_SETTINGS_DEBOUNCE_TIME = 1000; // 1 second

export const urlGenerator = (filters) =>
    filters
        .reduce((acc, curr) => {
            if (curr.selected) {
                return acc + `${curr.id},`;
            }
            return acc;
        }, '')
        .slice(0, -1) || 'false';

// These must match the values defined in getFilterType in the SIS directory:
// application/modules/default/src/HomepageDashboard/Ui/Service/Filters.php
export const STUDENT_GROUP_TYPES = {
    REGISTRATION_FORM: 'registration-form',
    YEAR_GROUP: 'year-group',
    FACULTY: 'faculty',
    DEPARTMENT: 'department',
    HOUSE: 'house',
    TEACHING_GROUP: 'teaching-group',
    COURSE: 'course',
    INTERVENTION_GROUP: 'intervention-group',
};

export const sortStudentGroupFilters = (unsortedStudentGroupFilters) => {
    const grouped = unsortedStudentGroupFilters.reduce(
        (acc, curr) => {
            switch (curr.type) {
                case STUDENT_GROUP_TYPES.REGISTRATION_FORM:
                    return {
                        ...acc,
                        registrationForm: [...acc.registrationForm, curr],
                    };

                case STUDENT_GROUP_TYPES.YEAR_GROUP:
                    return {
                        ...acc,
                        yearGroups: [...acc.yearGroups, curr],
                    };

                case STUDENT_GROUP_TYPES.FACULTY:
                    return {
                        ...acc,
                        faculty: [...acc.faculty, curr],
                    };

                case STUDENT_GROUP_TYPES.DEPARTMENT:
                    return {
                        ...acc,
                        department: [...acc.department, curr],
                    };

                case STUDENT_GROUP_TYPES.HOUSE:
                    return {
                        ...acc,
                        house: [...acc.house, curr],
                    };

                case STUDENT_GROUP_TYPES.TEACHING_GROUP:
                    return {
                        ...acc,
                        teachingGroups: [...acc.teachingGroups, curr],
                    };

                case STUDENT_GROUP_TYPES.COURSE:
                    return {
                        ...acc,
                        course: [...acc.course, curr],
                    };

                case STUDENT_GROUP_TYPES.INTERVENTION_GROUP:
                    return {
                        ...acc,
                        interventionGroup: [...acc.interventionGroup, curr],
                    };

                default:
                    return {
                        ...acc,
                        other: [...acc.other, curr],
                    };
            }
        },
        {
            registrationForm: [],
            yearGroups: [],
            faculty: [],
            department: [],
            house: [],
            teachingGroups: [],
            course: [],
            interventionGroup: [],
            other: [],
        },
    );

    const compareCallback = (a, b) =>
        a.displayName.toUpperCase() > b.displayName.toUpperCase() ? 1 : -1;

    const sortedStudentGroups = [
        ...grouped.registrationForm.sort(compareCallback),
        ...grouped.yearGroups.sort(compareCallback),
        ...grouped.faculty.sort(compareCallback),
        ...grouped.department.sort(compareCallback),
        ...grouped.house.sort(compareCallback),
        ...grouped.teachingGroups.sort(compareCallback),
        ...grouped.course.sort(compareCallback),
        ...grouped.interventionGroup.sort(compareCallback),
        ...grouped.other.sort(compareCallback),
    ];

    return sortedStudentGroups;
};

// This can maybe be turned into a selector, depending on how we do the redux integration
export const getSelectedCount = (items) =>
    items?.reduce((acc, item) => (item.selected ? acc + 1 : acc), 0) || 0;

export const formatNotificationDate = (date, isBirthday) => {
    const dateInMilliseconds = date * 1000;
    let formatedDate;
    if (isToday(dateInMilliseconds)) {
        formatedDate = 'Today';
    } else if (isYesterday(dateInMilliseconds)) {
        formatedDate = 'Yesterday';
    } else if (isTomorrow(dateInMilliseconds)) {
        formatedDate = 'Tomorrow';
    } else {
        formatedDate = format(dateInMilliseconds, 'd MMMM');
    }

    if (!isBirthday) {
        formatedDate += `, ${format(dateInMilliseconds, 'HH:mm')}`;
    }

    return formatedDate;
};

export const isNonNegativeNumber = (x) => typeof x === 'number' && x >= 0;

export const getSanitizedNetworkSettings = () => {
    const {
        homepageNetworkingSettings: {
            kpiCacheLifeTime,
            kpiDataDebounceTime,
            kpiSettingsDebounceTime,
        } = {},
    } = externalConfig.getConfig();

    const networkSettings = {
        kpiCacheLifeTime: DEFAULT_KPI_CACHE_LIFE_TIME,
        kpiDataDebounceTime: DEFAULT_KPI_DATA_DEBOUNCE_TIME,
        kpiSettingsDebounceTime: DEFAULT_KPI_SETTINGS_DEBOUNCE_TIME,
    };

    if (isNonNegativeNumber(kpiCacheLifeTime)) {
        networkSettings.kpiCacheLifeTime = kpiCacheLifeTime;
    }
    if (isNonNegativeNumber(kpiDataDebounceTime)) {
        networkSettings.kpiDataDebounceTime = kpiDataDebounceTime;
    }
    if (isNonNegativeNumber(kpiSettingsDebounceTime)) {
        networkSettings.kpiSettingsDebounceTime = kpiSettingsDebounceTime;
    }
    return networkSettings;
};
