import {Button} from 'Components/button/base';
import {CreateActionPopup} from 'Components/createActionPopup';
import exportInterface from 'Interfaces/Base';
import {addWindow, removeWindow} from 'Interfaces/WindowManager';

type ShowCreateActionPopupParams = {
    createActions: {
        title: string;
        url: string;
        disabled?: boolean;
    }[];
    createText: string;
    sectionTitle: string;
    rendererParams: {
        contentRequestUrl: string;
        elementToRefocus: HTMLElement | null;
    };
};

export const showCreateActionPopup = (params: ShowCreateActionPopupParams) => {
    const {
        createActions,
        createText,
        sectionTitle,
        rendererParams: {contentRequestUrl, elementToRefocus},
    } = params;

    addWindow({
        content: (
            <CreateActionPopup
                createActions={createActions}
                createText={createText}
                sectionTitle={sectionTitle}
            />
        ),
        footerItems: [
            <Button
                color="grey"
                text="Cancel"
                onClick={() => removeWindow()}
            />,
        ],
        title: 'What would you like to do?',
        rendererParams: {
            contentRequestUrl,
            type: 'window',
            elementToRefocus,
            refocusCallback: null,
        },
    });
};

export const registerCreateActionPopupInterface = () => {
    exportInterface('CreateActionPopup', {
        showCreateActionPopup,
    });
};
