// disabling exhaustive-deps for this file because we don't want the effects firing
// on isFirstRender changing
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef} from 'react';

import {logEventToGainsight} from 'Root/core/services/gainsight';
import {useIsFirstRender} from 'Root/core/utils/useIsFirstRender';
import helper from 'Utils/helper';

type UseReportGainsightLoggingParams = {
    showArborReports: boolean;
    showMyCustomReports: boolean;
    showOthersCustomReports: boolean;
    selectedCategory: string;
    searchQuery: string;
};

export const useReportFilterGainsightLogging = (
    params: UseReportGainsightLoggingParams,
) => {
    const {
        showArborReports,
        showMyCustomReports,
        showOthersCustomReports,
        selectedCategory,
        searchQuery,
    } = params;

    const isFirstRender = useIsFirstRender();

    const logSearchQueryDebouncedRef = useRef(
        helper.debounce((query: string) => {
            logEventToGainsight('ReportingLibrary_SearchQueryChanged', {
                query,
            });
            // very long debounce intentionally, to prevent duplicate events for the same query
            // might still get a few dupes from hunt-and-peckers though
        }, 2000),
    );

    useEffect(() => {
        if (!isFirstRender) {
            logEventToGainsight('ReportingLibrary_ReportOriginFilterChanged', {
                reportOrigin: 'Arbor Reports',
                enabled: showArborReports,
            });
        }
    }, [showArborReports]);

    useEffect(() => {
        if (!isFirstRender) {
            logEventToGainsight('ReportingLibrary_ReportOriginFilterChanged', {
                reportOrigin: 'My Custom Reports',
                enabled: showMyCustomReports,
            });
        }
    }, [showMyCustomReports]);

    useEffect(() => {
        if (!isFirstRender) {
            logEventToGainsight('ReportingLibrary_ReportOriginFilterChanged', {
                reportOrigin: 'Other Custom Reports',
                enabled: showOthersCustomReports,
            });
        }
    }, [showOthersCustomReports]);

    useEffect(() => {
        if (!isFirstRender) {
            logEventToGainsight('ReportingLibrary_CategoryFilterChanged', {
                category: selectedCategory,
            });
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (!isFirstRender) {
            logSearchQueryDebouncedRef.current(searchQuery);
        }
    }, [searchQuery]);
};
