import external from 'Config/External';

const style = `
background: #68aa22;
color: white;
font-weight: bold;
padding: 3px;
`;

export default function printVersion() {
    // eslint-disable-next-line no-console
    console.log(
        `%cArbor FE library version: ${external.config.feVersion}`,
        style,
    );
}
