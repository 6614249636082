import {useState} from 'react';

import {Button} from 'Components/button/base';

import './alphaToggleButton.scss';

export type AlphaToggleButtonProps = {
    label: string;
    storageKey: string;
};

export const AlphaToggleButton = (props: AlphaToggleButtonProps) => {
    const {label, storageKey} = props;

    const [toggleState, setToggleState] = useState<boolean>(
        localStorage.getItem(storageKey) === 'true',
    );

    const toggle = () => {
        if (toggleState) {
            localStorage.setItem(storageKey, 'false');
        } else {
            localStorage.setItem(storageKey, 'true');
        }

        setToggleState(!toggleState);
    };

    return (
        <Button
            color="grey"
            onClick={toggle}
            text={label + (toggleState ? ' (enabled)' : ' (disabled)')}
            className="alpha-toggle-button"
        />
    );
};
