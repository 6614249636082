import {ColumnTitleIcon} from './types';

export const filterIconsData = (iconsString: string) => {
    const parsedData = JSON.parse(iconsString) as ColumnTitleIcon[];
    if (typeof parsedData?.map !== 'function') {
        console.error('invalid icons data in column title', parsedData);
        return null;
    }

    return parsedData.filter((item) => item?.show !== false);
};
