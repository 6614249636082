export const AskArbor = () => {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.2717 5.5744L15.6248 6.2467C15.8489 6.31004 16 6.51465 16 6.74363C16 6.9726 15.8489 7.17722 15.6248 7.24055L13.2717 7.91286L12.5994 10.2659C12.5361 10.49 12.3315 10.6411 12.1025 10.6411C11.8735 10.6411 11.6689 10.49 11.6056 10.2659L10.9333 7.91286L8.58021 7.24055C8.3561 7.17722 8.20508 6.9726 8.20508 6.74363C8.20508 6.51465 8.3561 6.31004 8.58021 6.2467L10.9333 5.5744L11.6056 3.22132C11.6689 2.99722 11.8735 2.84619 12.1025 2.84619C12.3315 2.84619 12.5361 2.99722 12.5994 3.22132L13.2717 5.5744Z"
                fill="#F7941D"
            />
            <path
                d="M7.37526 2.03593L8.79949 2.44285C8.93513 2.48118 9.02654 2.60503 9.02654 2.74362C9.02654 2.88221 8.93513 3.00605 8.79949 3.04439L7.37526 3.45131L6.96834 4.87554C6.93 5.01118 6.80616 5.10259 6.66757 5.10259C6.52898 5.10259 6.40513 5.01118 6.3668 4.87554L5.95988 3.45131L4.53565 3.04439C4.4 3.00605 4.30859 2.88221 4.30859 2.74362C4.30859 2.60503 4.4 2.48118 4.53565 2.44285L5.95988 2.03593L6.3668 0.611695C6.40513 0.476054 6.52898 0.384644 6.66757 0.384644C6.80616 0.384644 6.93 0.476054 6.96834 0.611695L7.37526 2.03593Z"
                fill="#CFDD27"
            />
            <path
                d="M7.06667 10.6821L10.3486 11.6021C10.6612 11.6887 10.8718 11.9687 10.8718 12.2821C10.8718 12.5954 10.6612 12.8754 10.3486 12.9621L7.06667 13.8821L6.12897 17.1021C6.04064 17.4087 5.75526 17.6154 5.4359 17.6154C5.11654 17.6154 4.83115 17.4087 4.74282 17.1021L3.80513 13.8821L0.523205 12.9621C0.210641 12.8754 0 12.5954 0 12.2821C0 11.9687 0.210641 11.6887 0.523205 11.6021L3.80513 10.6821L4.74282 7.46206C4.83115 7.1554 5.11654 6.94873 5.4359 6.94873C5.75526 6.94873 6.04064 7.1554 6.12897 7.46206L7.06667 10.6821Z"
                fill="#7BB93C"
            />
        </svg>
    );
};
