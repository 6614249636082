import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {SystemButton} from 'Components/button/base';
import {SimpleText} from 'Components/simpleText';
import getCurrencySymbol from 'Interfaces/currency/Currency';
import {deselectItem} from 'State/actionCreators/Basket';

class BasketProperty extends Component {
    render() {
        const {title, price, currencyCode, serviceId} = this.props;
        return (
            <div className="basket-property-container">
                <div className="basket-property">
                    <span className="property-label">
                        <SimpleText>{title}</SimpleText>
                    </span>
                    <span className="property-price">{`${getCurrencySymbol(
                        currencyCode,
                    )}${price}`}</span>
                    <span className="property-close">
                        <SystemButton
                            ariaLabel="Remove item from basket"
                            icon="remove"
                            onClick={() => this.props.deselectItem(serviceId)}
                        />
                    </span>
                </div>
            </div>
        );
    }
}

BasketProperty.propTypes = {
    title: PropTypes.string,
    price: PropTypes.number,
    serviceId: PropTypes.string,
    currencyCode: PropTypes.string,
    type: PropTypes.string,
    deselectItem: PropTypes.func,
};

export default connect(null, {deselectItem})(BasketProperty);
