import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';

export default function LoaderHOC(WrappedComponent) {
    class Loader extends Component {
        render() {
            const {showMask, ...props} = this.props;
            return (
                <div className={classNames('arbor-loader', {showMask})}>
                    <div
                        className="loader-overlay"
                        aria-label={showMask ? 'Loading...' : null}
                    />
                    <WrappedComponent {...props} />
                </div>
            );
        }
    }

    Loader.propTypes = {
        showMask: PropTypes.bool,
    };

    return Loader;
}
