export const BACKSPACE_KEY_CODE = 8;
export const TAB_KEY_CODE = 9;
export const ENTER_KEY_CODE = 13;
export const ESCAPE_KEY_CODE = 27;
export const SPACE_KEY_CODE = 32;
export const ARROW_LEFT_KEY_CODE = 37;
export const ARROW_UP_KEY_CODE = 38;
export const ARROW_RIGHT_KEY_CODE = 39;
export const ARROW_DOWN_KEY_CODE = 40;
export const DELETE_KEY_CODE = 46;
export const Z_KEY_CODE = 90;
export const A_KEY_CODE = 65;
