/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 9/11/18
 * Time: 12:46
 */

import {interceptBadResponse} from 'Interfaces/httpClient/interceptors/InterceptBadResponse';
import {interceptResponse} from 'Interfaces/httpClient/interceptors/InterceptResponse';

export function initLegacyInterceptors() {
    Ext.Ajax.on({
        requestcomplete: {fn: interceptResponse},
        requestexception: {fn: interceptBadResponse},
    });
}
