import {BreadCrumbs} from './breadCrumbs';
import {ColumnTitle} from './ColumnTitle';
import {ColumnTitleData, TitleAction} from './types';

import type {ReactNode} from 'react';

import './column.scss';
type ColumnProps = {
    children: ReactNode;
    minColumnWidth: number;
    columnTitle?: ColumnTitleData;
    backButtonUrl?: string;
    backButton?: string;
    titleAction?: TitleAction;
};

export const Column = ({
    children,
    minColumnWidth,
    columnTitle,
    backButtonUrl,
    backButton,
    titleAction,
}: ColumnProps) => {
    return (
        <section
            aria-label="Main content"
            className="column-flex-wrapper"
            style={{minWidth: `${minColumnWidth}px`}}
        >
            <BreadCrumbs />
            <div className="main-content-wrapper" role="presentation">
                {columnTitle && (
                    <ColumnTitle
                        columnTitle={columnTitle}
                        backButtonUrl={backButtonUrl}
                        backButton={backButton}
                        titleAction={titleAction}
                    ></ColumnTitle>
                )}
                {children}
            </div>
        </section>
    );
};
