export type UnsavedDataSource = {
    isInvalid: (context?: string) => boolean;
    getValidationMessage: (context?: string) => string;
    formActionNames?: string[];
    dismiss?: () => void;
};

class UnsavedDataRegistry {
    private dataSources: Record<number, UnsavedDataSource>;
    private nextId: number;

    constructor() {
        this.dataSources = {};
        this.nextId = 0;
    }

    registerDataSource = (dataSource: UnsavedDataSource) => {
        const id = this.nextId;
        this.nextId++;
        this.dataSources[id] = dataSource;

        return id;
    };

    deregisterDataSource = (id) => {
        if (this.dataSources[id]) {
            delete this.dataSources[id];
        }
    };

    hasInvalidDataSources = (
        args: {formActionName?: string; context?: string} = {},
    ) => {
        const {formActionName, context} = args;
        let dataSourcesArray = Object.values(this.dataSources);

        if (formActionName) {
            dataSourcesArray = dataSourcesArray.filter((source) =>
                source.formActionNames?.includes(formActionName),
            );
        }

        for (const source of dataSourcesArray) {
            if (source.isInvalid(context)) {
                return true;
            }
        }
        return false;
    };

    getErrorMessage = (
        args: {
            formActionName?: string;
            context?: string;
        } = {},
    ) => {
        const {formActionName, context} = args;

        let invalidDataSources = Object.values(this.dataSources).filter(
            (source) => source.isInvalid(),
        );

        if (formActionName) {
            invalidDataSources = invalidDataSources.filter((source) =>
                source.formActionNames?.includes(formActionName),
            );
        }

        return invalidDataSources
            .map((source) => source.getValidationMessage(context))
            .join('\n');
    };

    dismiss = (args: {formActionName?: string} = {}) => {
        const {formActionName} = args;

        let dataSources: UnsavedDataSource[];

        if (formActionName) {
            dataSources = Object.values(this.dataSources).filter((source) =>
                source.formActionNames?.includes(formActionName),
            );
        } else {
            dataSources = Object.values(this.dataSources);
        }

        dataSources.forEach((source) => {
            if (typeof source.dismiss === 'function') {
                source.dismiss();
            }
        });
    };
}

let unsavedDataRegistryInstance: UnsavedDataRegistry | null = null;
export const getUnsavedDataRegistryService = () => {
    if (!unsavedDataRegistryInstance) {
        unsavedDataRegistryInstance = new UnsavedDataRegistry();
    }

    return unsavedDataRegistryInstance;
};
