import {useEffect, useState} from 'react';

import {Button} from 'Components/button/base';
import {
    Combobox,
    ComboboxValue,
    useFetchComboboxOptions,
} from 'Components/combobox';
import {FocusTrappingModal} from 'Components/focusTrappingModal';

import {ISendEmergencyAlertParams} from '../sendEmergencyAlert';

import './additionalInfoModal.scss';

type AdditionaInfoModalProps = {
    onClose: () => void;
    sendAlert: (data: ISendEmergencyAlertParams) => void;
};

export const AdditionalInfoModal = ({
    onClose,
    sendAlert,
}: AdditionaInfoModalProps) => {
    const [location, setLocation] = useState<ComboboxValue | undefined>();
    const [event, setEvent] = useState<ComboboxValue | undefined>();
    const [alertType, setAlertType] = useState<ComboboxValue>();
    const [students, setStudents] = useState<ComboboxValue[]>([]);
    const [comment, setComment] = useState('');

    const [isFetching, setIsFetching] = useState(false);

    const sendAlertWithAdditionalInfo = () => {
        setIsFetching(true);
        sendAlert({
            location: location || '',
            event: event || '',
            comment: comment,
            alertType: alertType || '',
            students: students,
        });
        // sendAlert closes the modal in the finally block and handles errors,
        // so no need to setIsFetching(false) or to handle errors here
    };

    const {
        isLoading: isLocationOptionsLoading,
        comboboxOptions: locationOptions,
        error: locationOptionsError,
        initialOptionValues: initialLocationOptionValues,
    } = useFetchComboboxOptions({optionsUrl: '/emergency-alert/locations'});
    const {
        isLoading: isEventOptionsLoading,
        comboboxOptions: eventOptions,
        error: eventOptionsError,
        initialOptionValues: initialEventOptionValues,
    } = useFetchComboboxOptions({optionsUrl: '/emergency-alert/events'});
    const {
        isLoading: isAlertTypeLoading,
        comboboxOptions: alertTypeOptions,
        error: alertTypeError,
    } = useFetchComboboxOptions({optionsUrl: '/emergency-alert/alert-types'});
    const {
        isLoading: isStudentOptionsLoading,
        comboboxOptions: studentOptions,
        error: studentOptionsError,
    } = useFetchComboboxOptions({optionsUrl: '/emergency-alert/students'});

    useEffect(() => {
        if (initialLocationOptionValues.length > 0) {
            setLocation(initialLocationOptionValues[0]);
        }
    }, [initialLocationOptionValues]);

    useEffect(() => {
        if (initialEventOptionValues.length > 0) {
            setEvent(initialEventOptionValues[0]);
        }
    }, [initialEventOptionValues]);

    const withError =
        locationOptionsError ||
        eventOptionsError ||
        alertTypeError ||
        studentOptionsError;

    return (
        <FocusTrappingModal
            onClose={onClose}
            key="emergency-alert-info-modal"
            title="Edit your information"
        >
            <div
                className="emergency-alert-modal__container"
                data-testid="emergency-alert-info-modal"
            >
                <p className="emergency-alert-modal__help-text">
                    The timer has stopped. Add or make changes to the
                    information, then send.
                </p>
                <Combobox
                    items={locationOptions}
                    label="Location"
                    initialValue={initialLocationOptionValues}
                    placeholder="Select location from the drop-down list"
                    isLoading={isLocationOptionsLoading}
                    onChange={(value) => setLocation(value)}
                    inlineLabel={false}
                />
                <Combobox
                    items={eventOptions}
                    label="Event"
                    initialValue={initialEventOptionValues}
                    placeholder="Select an event or leave blank"
                    isLoading={isEventOptionsLoading}
                    onChange={(value) => setEvent(value)}
                    inlineLabel={false}
                />
                <Combobox
                    items={alertTypeOptions}
                    label="Emergency alert type"
                    placeholder="Select a type from the list below if available"
                    isLoading={isAlertTypeLoading}
                    onChange={(value) => setAlertType(value)}
                    inlineLabel={false}
                />
                <Combobox
                    items={studentOptions}
                    label="Students involved"
                    placeholder="Select involved students from the drop-down list"
                    isMultiselect
                    isLoading={isStudentOptionsLoading}
                    onChange={(value) => setStudents(value)}
                    inlineLabel={false}
                />
                <label className="emergency-alert-modal__form-field">
                    <span className="emergency-alert-modal__form-field-label">
                        Comments
                    </span>
                    <textarea
                        className="emergency-alert-modal__form-field-textarea"
                        rows={3}
                        value={comment}
                        placeholder="Include any information that can help staff on call e.g. bring an Epi Pen or any other safety notices"
                        onChange={(e) => setComment(e.target.value)}
                    />
                </label>

                {withError && (
                    <div className="emergency-alert-modal__error-text">
                        Something went wrong fetching alert options. You can
                        still attempt to send the alert.
                    </div>
                )}

                <div className="emergency-alert-modal__footer">
                    <Button
                        text="Cancel alert"
                        onClick={() => {
                            onClose();
                        }}
                    />
                    <Button
                        text="Send alert"
                        color="green"
                        disabled={isFetching}
                        onClick={sendAlertWithAdditionalInfo}
                    />
                </div>
            </div>
        </FocusTrappingModal>
    );
};
