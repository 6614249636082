import PropTypes from 'prop-types';

import {ExtComponentRenderer} from 'Renderer/ExtComponentRenderer';

export const FallbackComponent = ({name}) => {
    if (
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test'
    ) {
        return (
            <div style={{padding: '20px', background: 'LightPink'}}>{name}</div>
        );
    }
    return <div />;
};

FallbackComponent.propTypes = {
    name: PropTypes.string,
};

// Finds the constructor for the Ext component.
// This can be mocked if necessary in unit tests for the rendering pipeline.
export const getExtComponentConstructor = (path: string) => {
    // On creating the constructor, check that the Ext class is actually defined, to avoid erros later
    // on when the component is constructed.
    const ExtClass = Ext.ClassManager && Ext.ClassManager.lookupName(path);
    if (!ExtClass) {
        if (process.env.NODE_ENV !== 'test') {
            console.warn('No Ext component found for path:', path);
        }
        return () => <FallbackComponent name={path} />;
    }

    return (props) => <ExtComponentRenderer componentName={path} {...props} />;
};
