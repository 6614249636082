import {lazy, Suspense} from 'react';

const DrillDownTable = lazy(() => import('./DrillDownAsDefaultExport'));

export const LazyLoadedDrillDown = (props) => {
    return (
        <Suspense fallback={<div> Loading data </div>}>
            <DrillDownTable {...props} />
        </Suspense>
    );
};
