import {httpGet} from 'Interfaces/httpClient';

export interface IAlertOptions {
    staffName: string;
    location:
        | {
              label: string;
              value: string;
          }
        | undefined;
    event:
        | {
              label: string;
              value: string;
          }
        | undefined;
}

export const fetchAlertOptions = async () => {
    return httpGet('/emergency-alert/options/');
};
