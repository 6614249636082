import {useState, useEffect, useRef} from 'react';

import {httpGet} from 'Interfaces/httpClient';
import {reportToSisSentry} from 'Services/raven/Raven';
import url from 'Services/url/Url';
import helper from 'Utils/helper';

import {FilterableEntity} from '../SearchFilters/searchFilters';

import {groupSearchResults} from './groupSearchResults';

export type SearchItem = {
    clickUrl: string;
    description?: string;
    group: string;
    groupId: string;
    title: string;
    icon?: string;
};

const sendRequest = (
    query: string,
    filter: FilterableEntity | undefined,
    onlyCurrent: boolean,
    signal: AbortSignal | undefined,
): Promise<any> => {
    return httpGet(
        url.GLOBAL_SEARCH.AUTOCOMPLETE,
        {query, filter, includePastResults: !onlyCurrent},
        signal,
    );
};

type UseSearchQueryParams = {
    query: string;
    shouldSuppressSearch?: boolean;
    filter: FilterableEntity;
    onlyCurrentResults: boolean;
};

// A hook for querying search results with built-in debouncing
export const useSearchQuery = (params: UseSearchQueryParams) => {
    const {
        query: queryValue,
        shouldSuppressSearch,
        filter,
        onlyCurrentResults,
    } = params;

    const [searchGroups, setSearchGroups] = useState<SearchItem[][]>([]);
    const [isQueryValid, setIsQueryValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);
    const [hasError, setHasError] = useState(false);
    const signalRef = useRef<AbortController | null>();

    const handleRequest = (
        query: string,
        rawFilter: FilterableEntity,
        onlyCurrent: boolean,
    ) => {
        let controller: undefined | AbortController;
        if (typeof AbortController !== 'undefined') {
            controller = new AbortController();
            signalRef.current = controller;
        }

        let searchFilter: FilterableEntity | undefined;
        if (typeof rawFilter === 'number') {
            searchFilter = rawFilter;
        }
        sendRequest(
            query,
            searchFilter,
            onlyCurrent,
            controller && controller.signal,
        )
            .then((response) => {
                if (response && response.items) {
                    const responseItems: SearchItem[] = response.items;
                    const grouped = groupSearchResults(responseItems);
                    setSearchGroups(grouped);
                } else {
                    setSearchGroups([]);
                }
            })
            .catch((response) => {
                if (!response || response.status !== 401) {
                    reportToSisSentry(new Error('Search Bar Request Error!'), {
                        query,
                        response,
                    });
                }
                console.error(response);
                setHasError(response);
                setSearchGroups([]);
            })
            .finally(() => {
                setIsLoading(false);
                setIsFetchingCompleted(true);
            });
    };

    const searchDebounceDelay = 600;
    const handleRequestDebounced = useRef(
        helper.debounce(handleRequest, searchDebounceDelay),
    );

    const checkLength = (query: string) => {
        const minSearchLength = 2;
        return query.length >= minSearchLength;
    };

    useEffect(() => {
        if (signalRef.current) {
            signalRef.current.abort();
            signalRef.current = null;
        }
        if (checkLength(queryValue)) {
            setIsQueryValid(true);

            if (!(filter === 'all' || typeof filter === 'number')) {
                setSearchGroups([]);
                return;
            }

            if (!shouldSuppressSearch) {
                setIsLoading(true);
                setIsFetchingCompleted(false);
                handleRequestDebounced.current(
                    queryValue,
                    filter,
                    onlyCurrentResults,
                );
            }
        } else {
            setIsQueryValid(false);
            handleRequestDebounced.current.clear();
            setIsFetchingCompleted(false);
            setIsLoading(false);
        }
    }, [queryValue, shouldSuppressSearch, filter, onlyCurrentResults]);

    // allows the component calling this hook to cancel any debounced
    // requests that are yet to be made
    useEffect(() => {
        if (shouldSuppressSearch) {
            signalRef.current?.abort();
            handleRequestDebounced.current.clear();
        }
    }, [shouldSuppressSearch]);

    return {
        searchGroups,
        isLoading,
        isQueryValid,
        hasError,
        isFetchingCompleted,
    };
};
