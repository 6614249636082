import PropTypes from 'prop-types';

import {Button} from 'Components/button/base';
import {removeAllSlideovers} from 'Interfaces/Slideover';
import {removeWindow} from 'Interfaces/WindowManager';
import buttonWrapperExt from 'Wrappers/ButtonWithExtModal';

// TODO [MIS-36583]: This wrapper is probably unnecessary and should be removed
const WrappedButton = buttonWrapperExt(Button);

const CancelButton = ({onClick, ...rest}) => {
    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
            return;
        }
        const containerType = rest?.rendererParams?.type;
        if (containerType === 'slideover') {
            removeAllSlideovers();
        } else if (containerType === 'window') {
            removeWindow();
        } else {
            // This case is only needed if a react cancel button ends up rendered
            // inside an Ext window, which is not quite unlikely.
            // When we have refactored more windows to react, we can remove this
            const containerWindow = e.currentTarget.closest('.x-window');
            if (containerWindow && containerWindow.id) {
                const component = Ext.getCmp(containerWindow.id);
                if (component) {
                    component.close();
                }
            }
        }
    };

    return (
        <WrappedButton
            text={'Cancel'}
            className="arbor-cancel-button"
            {...rest}
            onClick={handleClick}
        />
    );
};

CancelButton.propTypes = {
    onClick: PropTypes.func,
};

export default CancelButton;
