import {Component, ReactNode} from 'react';
import * as React from 'react';
import {createPortal} from 'react-dom';

import {APP_CONTAINER_ID, TOOLTIP_PORTAL_ID} from 'Constants/idStrings';
import {
    FloatingElementDirection,
    getFloatingElementPosition,
} from 'Utils/getFloatingElementPosition';
import helper from 'Utils/helper';

import Tooltip from './Tooltip';
import TooltipAdvanced from './TooltipAdvanced';
import Manager from './TooltipManager';

import './tooltipWrapper.scss';

interface ITooltipWrapperProps {
    tooltip?: string | ReactNode;
    tooltipUrl?: string;
    tooltipHeader?: string;
    tooltipSubHeader?: string;
}

interface ITooltipWrapperState {
    arrowX?: number;
    arrowY?: number;
    position?: FloatingElementDirection;
    show: boolean;
    portalStyle: React.CSSProperties;
}

export default function <T extends object>(
    WrappedComponent: React.ComponentType<T>,
) {
    class TooltipWrapper extends Component<
        ITooltipWrapperProps & T,
        ITooltipWrapperState
    > {
        private id: string;
        private el?: HTMLElement;
        private tooltipEl: HTMLElement | null;
        private tooltipPlaceholderEl: HTMLElement | null;
        constructor(props: ITooltipWrapperProps & T) {
            super(props);
            this.state = {portalStyle: {visibility: 'hidden'}, show: false};
            this.tooltipEl = null;
            this.tooltipPlaceholderEl =
                document.getElementById(TOOLTIP_PORTAL_ID);
            this.id = helper.getUid();

            if (!this.tooltipPlaceholderEl) {
                // Fallback in case placeholder div is not already created (e.g. in tests)
                this.tooltipPlaceholderEl = document.createElement('div');
                this.tooltipPlaceholderEl.setAttribute('id', TOOLTIP_PORTAL_ID);
                document.body.appendChild(this.tooltipPlaceholderEl);
            }
        }
        componentDidMount = () => {
            Manager.register(this.id, 'show', this.showTooltip, 400);
            Manager.register(this.id, 'hide', this.hideTooltip, 400);

            const mainLayoutBody = document.getElementById(APP_CONTAINER_ID);
            if (mainLayoutBody) {
                mainLayoutBody.addEventListener('scroll', this.hideTooltip);
            }
        };
        componentWillUnmount() {
            const mainLayoutBody = document.getElementById(APP_CONTAINER_ID);
            if (mainLayoutBody) {
                mainLayoutBody.removeEventListener('scroll', this.hideTooltip);
            }
        }
        handleMouseEnter = () => {
            Manager.execute(this.id, 'show');
        };
        handleMouseLeave = () => {
            Manager.execute(this.id, 'hide');
        };
        handleFocusEnter = () => {
            Manager.execute(this.id, 'show');
        };
        handleFocusLeave = () => {
            // When focus leaves we want to hide the tooltip with no delay

            // As 'show' events are delayed 400ms, we need to clear any pending show events,
            // else the queued show event will happen after hide function is called.
            Manager.clear(this.id, 'show');

            this.hideTooltip();
        };
        handleTooltipShow = () => {
            this.setTooltipPosition();
        };

        setTooltipPosition = () => {
            const tooltipPosition = getFloatingElementPosition(
                this.el,
                this.tooltipEl,
                {offset: 12},
            );
            if (
                !tooltipPosition ||
                !tooltipPosition.position ||
                (tooltipPosition.arrowX && tooltipPosition.arrowX < 0) ||
                (tooltipPosition.arrowY && tooltipPosition.arrowY < 0)
            ) {
                this.hideTooltip();
            }
            if (!tooltipPosition) {
                return;
            }
            const {arrowX, arrowY, position, x, y} = tooltipPosition;
            this.setState({
                portalStyle: {
                    left: x + 'px',
                    top: y + 'px',
                    visibility: 'visible',
                },
                arrowX,
                arrowY,
                position,
            });
        };

        // Fix when refactor to hooks
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setRef = (el: any) => {
            if (el && el.childNodes && el.childNodes[0]) {
                this.el = el.childNodes[0];
            } else {
                // eslint-disable-next-line no-undefined
                this.el = undefined;
            }
        };

        setTooltipRef = (el) => {
            this.tooltipEl = el;
        };

        showTooltip = () => {
            // This function can be called with a delay (see Manager.register), so
            // we need to check it still exists before calling setState

            if (this.el) {
                this.setState({show: true});
            }
        };

        hideTooltip = () => {
            // This function can be called with a delay (see Manager.register), so
            // we need to check it still exists before calling setState
            if (this.el) {
                this.setState({
                    portalStyle: {visibility: 'hidden'},
                    show: false,
                });
            }
        };
        renderTooltip = (tooltipProps) => {
            const {tooltip, tooltipUrl, tooltipHeader, tooltipSubHeader} =
                tooltipProps;

            const {arrowX, arrowY, position, show} = this.state;

            // We use the same mouse enter/leave handlers for the tooltip as the wrapped component,
            // so that when you hover over the tooltip it acts the same as if you were still hovering
            // over the wrapped component, and the tooltip doesn't close.
            const commonTooltipProps = {
                arrowX,
                arrowY,
                position,
                onShow: this.handleTooltipShow,
                content: tooltip,
                visible: show,
                targetElement: this.el,
            };
            if (tooltip && !helper.isHTML(tooltip)) {
                return (
                    <div
                        style={{
                            ...this.state.portalStyle,
                            position: 'absolute',
                            pointerEvents: 'all',
                        }}
                        ref={this.setTooltipRef}
                    >
                        <Tooltip
                            className="mis-simple-tooltip"
                            {...commonTooltipProps}
                        />
                    </div>
                );
            }
            if (tooltip || tooltipUrl) {
                return (
                    <div
                        style={{
                            ...this.state.portalStyle,
                            position: 'absolute',
                            pointerEvents: 'all',
                        }}
                        ref={this.setTooltipRef}
                    >
                        <TooltipAdvanced
                            tooltipUrl={tooltipUrl}
                            header={tooltipHeader}
                            subHeader={tooltipSubHeader}
                            {...commonTooltipProps}
                        />
                    </div>
                );
            }
            return null;
        };

        render() {
            const {
                tooltip,
                tooltipUrl,
                tooltipHeader,
                tooltipSubHeader,
                ...rest
            } = this.props;

            const tooltipProps = {
                tooltip,
                tooltipUrl,
                tooltipHeader,
                tooltipSubHeader,
            };

            // If none of the necessary props have been supplied, simply render the wrapped component
            if (
                !tooltip &&
                !tooltipUrl &&
                !tooltipHeader &&
                !tooltipSubHeader
            ) {
                return <WrappedComponent {...(rest as T)} />;
            }

            return (
                <div
                    ref={this.setRef}
                    className="arbor-tooltip-wrapper"
                    onFocus={this.handleFocusEnter}
                    onBlur={this.handleFocusLeave}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    role="presentation"
                    data-testid="tooltip-wrapper"
                >
                    <WrappedComponent {...(rest as T)} />
                    {this.tooltipPlaceholderEl &&
                        // TODO see if this cast is safe
                        (createPortal(
                            this.renderTooltip(tooltipProps),
                            this.tooltipPlaceholderEl,
                        ) as ReactNode)}
                </div>
            );
        }
    }

    return TooltipWrapper;
}
