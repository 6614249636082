import classnames from 'classnames';
import isString from 'lodash-es/isString';
import {PureComponent} from 'react';

import {FloatingElementDirection} from 'Root/core/utils/getFloatingElementPosition';

import type {ReactNode, MouseEventHandler} from 'react';

import './tooltip.scss';

interface ITooltipProps {
    className?: string;
    content?: ReactNode;
    position?: FloatingElementDirection;
    arrowX?: number;
    arrowY?: number;
    visible: boolean;
    onShow?: () => void;
    mouseEnterHandler?: MouseEventHandler<HTMLDivElement>;
    mouseLeaveHandler?: MouseEventHandler<HTMLDivElement>;
    targetElement?: Element | null;
    isLoading?: boolean;
    enableArrow?: boolean;
}

export default class Tooltip extends PureComponent<ITooltipProps> {
    componentDidMount = () => {
        if (this.props.visible && typeof this.props.onShow === 'function') {
            this.props.onShow();
        }
    };
    componentDidUpdate = (previousProps: ITooltipProps) => {
        const hasTargetChanged =
            previousProps.targetElement !== this.props.targetElement;
        const hasLoadingChanged =
            previousProps.isLoading !== this.props.isLoading;
        if (
            this.props.visible &&
            (!previousProps.visible || hasTargetChanged || hasLoadingChanged) &&
            typeof this.props.onShow === 'function'
        ) {
            this.props.onShow();
        }
    };
    renderContent = (content) => ({__html: content});
    render() {
        const {
            className,
            content,
            position,
            arrowX,
            arrowY,
            visible,
            mouseEnterHandler,
            mouseLeaveHandler,
            isLoading,
            enableArrow = true,
        } = this.props;

        return (
            visible && (
                <div
                    className={classnames('arbor-tooltip', position, className)}
                    role="tooltip"
                    onMouseEnter={mouseEnterHandler}
                    onMouseLeave={mouseLeaveHandler}
                >
                    {enableArrow && (
                        <svg
                            viewBox="0 0 10 10"
                            width="21px"
                            className="tooltip-arrow"
                            style={{left: arrowX, top: arrowY}}
                        >
                            <polygon
                                points="0,0 10,0 5,5"
                                stroke="none"
                                className="tooltip-arrow-border-layer"
                            />
                            <polygon points="0.5,0 9.5,0 5,4.5" stroke="none" />
                        </svg>
                    )}
                    {isLoading && (
                        <div className="arbor-loader showMask arbor-tooltip__loading" />
                    )}
                    {!isLoading &&
                        (isString(content) ? (
                            <div
                                dangerouslySetInnerHTML={this.renderContent(
                                    content,
                                )}
                            />
                        ) : (
                            content
                        ))}
                </div>
            )
        );
    }
}
