/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:07
 */

import {createUrlWithAdditionalParams} from 'Services/url/createUrlWithAdditionalParams';
import {removeSisUrlParameters} from 'Services/url/removeSisUrlParameters';
import {removeUrlParams} from 'Services/url/removeUrlParams';
import {
    appendFormatJson,
    createUrlWithReplacedHash,
    findHashInUrl,
} from 'Services/url/Url';

import exportInterface from './Base';

export {appendFormatJson} from 'Services/url/Url';
export {removeSisUrlParameters} from 'Services/url/removeSisUrlParameters';
export {createUrlWithAdditionalParams} from 'Services/url/createUrlWithAdditionalParams';
export {removeUrlParams} from 'Services/url/removeUrlParams';

export const registerUrlInterface = () => {
    exportInterface('Url', {
        appendFormatJson,
        removeSisUrlParameters,
        createUrlWithReplacedHash,
        findHashInUrl,
        createUrlWithAdditionalParams,
        removeUrlParams,
    });
};
