import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Icon} from 'Components/icon';
import Box from 'Components/layout/Box';
import Container from 'Components/layout/container/Container';
import {SimpleText} from 'Components/simpleText';
import getSymbol from 'Services/currency/Currency';
import {registerItem, selectItem} from 'State/actionCreators/Basket';
import {
    selectItemById,
    selectIsParentBundleAdded,
} from 'State/selectors/Basket';

import {PurchaseButton} from './PurchaseButton';
import PurchasePanelGroupProperty from './purchasePanelGroupProperty/PurchasePanelGroupProperty';
import PurchasePanelProperty from './purchasePanelProperty/PurchasePanelProperty';

import './purchasePanel.scss';

export class UnconnectedPurchasePanel extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            buttonLabel: '',
            selected: false,
            // These 2 values are needed for getDerivedStateFromProps to access previous props
            previousParentBundleIsAdded: props.parentBundleIsAdded,
            previousCurrentItemIsSelected:
                props.currentItem && props.currentItem.selected,
        };
    }

    handleOnAddClick = (id) => {
        this.props.onSelectItem(id);
    };

    componentDidMount() {
        if (this.props.type === 'group' || this.props.type === 'group-bundle') {
            return;
        }
        // Register item in redux state
        this.props.onRegisterItem({
            title: this.props.title,
            price: this.props.price,
            serviceId: this.props.serviceId,
            type: this.props.type,
            vat: this.props.vat,
            currencyCode: this.props.currencyCode,
            purchasePanel: {
                bundleRelatedPanels:
                    this.props.purchasePanel.bundleRelatedPanels,
            },
        });
        // Set item in local state
        this.setState({
            buttonLabel: this.props.buttonLabel,
            selected: !(
                this.props.type === 'single' || this.props.type === 'bundle'
            ), // If type is not single/bundle adding to basket should not be allowed
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            (nextProps.currentItem &&
                nextProps.currentItem.selected !==
                    prevState.previousCurrentItemIsSelected) ||
            nextProps.parentBundleIsAdded !==
                prevState.previousParentBundleIsAdded
        ) {
            return {
                selected:
                    nextProps.currentItem.selected ||
                    nextProps.parentBundleIsAdded,
                buttonLabel:
                    nextProps.currentItem.selected ||
                    nextProps.parentBundleIsAdded
                        ? _t('Added')
                        : nextProps.buttonLabel,
                previousCurrentItemIsSelected:
                    nextProps.currentItem && nextProps.currentItem.selected,
                previousParentBundleIsAdded: nextProps.parentBundleIsAdded,
            };
        }
        return {
            previousCurrentItemIsSelected:
                nextProps.currentItem && nextProps.currentItem.selected,
            previousParentBundleIsAdded: nextProps.parentBundleIsAdded,
        };
    }

    render() {
        const {
            title,
            subtitle,
            currencyCode,
            price,
            type,
            purchasePanel: {pictureUrl, properties, groupProperties},
            priceIcon,
            serviceId,
            children,
            parentBundleIsAdded,
        } = this.props;

        const {buttonLabel, selected} = this.state;

        let joinedTitle = title;
        if (subtitle) {
            joinedTitle += subtitle;
        }

        return (
            <div
                className={classnames('arbor-purchase-panel', {
                    'arbor-purchase-selected': selected && !parentBundleIsAdded,
                    'arbor-parent-bundle-selected': parentBundleIsAdded,
                    'arbor-purchase-bundle':
                        type === 'bundle' || type === 'group-bundle',
                })}
            >
                <Container
                    className="arbor-purchase-panel-header"
                    align="center"
                    wrap="nowrap"
                >
                    <Box grow className="arbor-purchase-panel-title">
                        <SimpleText>{joinedTitle}</SimpleText>
                    </Box>
                    {price && currencyCode && (
                        <span className="arbor-purchase-panel-price">
                            {getSymbol(currencyCode) + Number(price).toFixed(2)}
                            {priceIcon && <Icon iconName={priceIcon} />}
                        </span>
                    )}
                    {buttonLabel !== '' && (
                        <PurchaseButton
                            text={buttonLabel}
                            color="green"
                            onClick={this.handleOnAddClick}
                            id={serviceId}
                            disabled={selected}
                        />
                    )}
                </Container>
                <Container
                    className="arbor-purchase-panel-content"
                    wrap="nowrap"
                >
                    <Box className="arbor-purchase-panel-image">
                        <img alt="Report picture" src={pictureUrl} />
                    </Box>
                    <Container justify="flex-end">
                        <Box grow>{children}</Box>
                        {properties && (
                            <Box className="arbor-purchase-panel-properties">
                                {properties.map((property) => (
                                    <PurchasePanelProperty
                                        key={property.label}
                                        {...property}
                                    />
                                ))}
                            </Box>
                        )}
                        {groupProperties && (
                            <Box
                                className="arbor-purchase-panel-group-properties"
                                grow
                            >
                                {groupProperties.map((property) => (
                                    <PurchasePanelGroupProperty
                                        key={property.label}
                                        {...property}
                                    />
                                ))}
                            </Box>
                        )}
                    </Container>
                </Container>
            </div>
        );
    }
}

UnconnectedPurchasePanel.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    currencyCode: PropTypes.string,
    price: PropTypes.number,
    priceIcon: PropTypes.node,
    vat: PropTypes.number,
    serviceId: PropTypes.string,
    type: PropTypes.string,
    purchasePanel: PropTypes.shape({
        pictureUrl: PropTypes.string,
        properties: PropTypes.array,
        groupProperties: PropTypes.array,
        bundleRelatedPanels: PropTypes.array,
    }),
    children: PropTypes.node,
    onRegisterItem: PropTypes.func,
    onSelectItem: PropTypes.func,
    currentItem: PropTypes.object,
    parentBundleIsAdded: PropTypes.bool,
};

UnconnectedPurchasePanel.default = {
    parentBundleIsSelected: false,
};

const mapStateToProps = (state, props) => {
    return {
        currentItem: selectItemById(state, props.serviceId),
        parentBundleIsAdded: selectIsParentBundleAdded(state, props.serviceId),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRegisterItem: (item) => {
            dispatch(registerItem(item));
        },
        onSelectItem: (id) => {
            dispatch(selectItem(id));
        },
    };
};
export const PurchasePanel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedPurchasePanel);
