import {
    APP_CONTAINER_ID,
    HEADER_CONTAINER_ID,
    SLIDEOVER_PLACEHOLDER_ID,
    REACT_WINDOW_MANAGER_PLACEHOLDER_ID,
    EXT_WINDOW_MANAGER_PLACEHOLDER_ID,
    SYSTEM_NOTIFICATION_PLACEHOLDER_ID,
    SYSTEM_MODAL_PLACEHOLDER_ID,
    SESSION_MODAL_PLACEHOLDER_ID,
    WINDOW_PLACEHOLDER_ID,
    STRIPE_WINDOW_PLACEHOLDER_ID,
    SIMPLE_TOOLTIP_PLACEHOLDER_ID,
} from 'Constants/idStrings';
import {
    setAppRootContainer,
    setExtWindowRootContainer,
    setFocusTrappingModalRootContainer,
    setHeaderRootContainer,
    setReactWindowRootContainer,
    setSessionModalRootContainer,
    setSimpleTooltipRootContainer,
    setSlideoverRootContainer,
    setSystemModalRootContainer,
    setSystemNotificationRootContainer,
} from 'Interfaces/renderTargets';

export function createPlaceholderDivs() {
    // Create placeholder divs for rendering react elements to
    const headerContainer = document.createElement('div');
    headerContainer.setAttribute('id', HEADER_CONTAINER_ID);
    headerContainer.setAttribute('tabIndex', '-1');
    document.body.appendChild(headerContainer);
    setHeaderRootContainer(headerContainer);

    const appContainer = document.createElement('div');
    appContainer.setAttribute('id', APP_CONTAINER_ID);
    appContainer.setAttribute('class', 'app-container');
    appContainer.setAttribute('role', 'presentation');
    document.body.appendChild(appContainer);
    setAppRootContainer(appContainer);

    const slideoverPlaceholder = document.createElement('div');
    slideoverPlaceholder.setAttribute('id', SLIDEOVER_PLACEHOLDER_ID);
    slideoverPlaceholder.setAttribute('role', 'presentation');
    slideoverPlaceholder.style.zIndex = '18000';
    slideoverPlaceholder.style.position = 'relative';
    document.body.appendChild(slideoverPlaceholder);
    setSlideoverRootContainer(slideoverPlaceholder);

    const focusTrappingModalPlaceholder = document.createElement('div');
    focusTrappingModalPlaceholder.setAttribute('id', WINDOW_PLACEHOLDER_ID);
    focusTrappingModalPlaceholder.setAttribute('role', 'presentation');
    focusTrappingModalPlaceholder.style.zIndex = '18500';
    focusTrappingModalPlaceholder.style.position = 'relative';
    document.body.appendChild(focusTrappingModalPlaceholder);
    setFocusTrappingModalRootContainer(focusTrappingModalPlaceholder);

    const reactWindowManagerPlaceholder = document.createElement('div');
    reactWindowManagerPlaceholder.setAttribute(
        'id',
        REACT_WINDOW_MANAGER_PLACEHOLDER_ID,
    );
    reactWindowManagerPlaceholder.setAttribute('role', 'presentation');
    reactWindowManagerPlaceholder.style.zIndex = '18600';
    reactWindowManagerPlaceholder.style.position = 'relative';
    document.body.appendChild(reactWindowManagerPlaceholder);
    setReactWindowRootContainer(reactWindowManagerPlaceholder);

    const extWindowManagerPlaceholder = document.createElement('div');
    extWindowManagerPlaceholder.setAttribute(
        'id',
        EXT_WINDOW_MANAGER_PLACEHOLDER_ID,
    );
    extWindowManagerPlaceholder.setAttribute('role', 'presentation');
    extWindowManagerPlaceholder.style.zIndex = '18700';
    extWindowManagerPlaceholder.style.position = 'relative';
    document.body.appendChild(extWindowManagerPlaceholder);
    setExtWindowRootContainer(extWindowManagerPlaceholder);

    // Note the Ext.js ZIndexManager will render floating elements such as
    // dropdowns and datepickers startign at a z-index of 19000
    // It does this by 10000 + 9000.
    // All our z-indexes for our layers that possibly contain ext elements
    // should be below 19000

    const stripeWindowPlaceholder = document.createElement('div');
    stripeWindowPlaceholder.setAttribute('id', STRIPE_WINDOW_PLACEHOLDER_ID);
    stripeWindowPlaceholder.setAttribute('role', 'presentation');
    stripeWindowPlaceholder.style.zIndex = '19100';
    stripeWindowPlaceholder.style.position = 'relative';
    document.body.appendChild(stripeWindowPlaceholder);

    const systemNotificationPlaceholder = document.createElement('div');
    systemNotificationPlaceholder.setAttribute(
        'id',
        SYSTEM_NOTIFICATION_PLACEHOLDER_ID,
    );
    systemNotificationPlaceholder.setAttribute('role', 'presentation');
    systemNotificationPlaceholder.style.zIndex = '19050';
    systemNotificationPlaceholder.style.position = 'relative';
    document.body.appendChild(systemNotificationPlaceholder);
    setSystemNotificationRootContainer(systemNotificationPlaceholder);

    const systemModalPlaceholder = document.createElement('div');
    systemModalPlaceholder.setAttribute('id', SYSTEM_MODAL_PLACEHOLDER_ID);
    systemModalPlaceholder.setAttribute('role', 'presentation');
    systemModalPlaceholder.style.zIndex = '19200';
    systemModalPlaceholder.style.position = 'relative';
    document.body.appendChild(systemModalPlaceholder);
    setSystemModalRootContainer(systemModalPlaceholder);

    const sessionModalPlaceholder = document.createElement('div');
    sessionModalPlaceholder.setAttribute('id', SESSION_MODAL_PLACEHOLDER_ID);
    sessionModalPlaceholder.setAttribute('role', 'presentation');
    sessionModalPlaceholder.style.zIndex = '20000';
    sessionModalPlaceholder.style.position = 'relative';
    document.body.appendChild(sessionModalPlaceholder);
    setSessionModalRootContainer(sessionModalPlaceholder);

    const simpleTooltipPlaceholder = document.createElement('div');
    simpleTooltipPlaceholder.setAttribute('id', SIMPLE_TOOLTIP_PLACEHOLDER_ID);
    simpleTooltipPlaceholder.setAttribute('role', 'presentation');
    simpleTooltipPlaceholder.style.zIndex = '50000';
    simpleTooltipPlaceholder.style.position = 'relative';
    document.body.appendChild(simpleTooltipPlaceholder);
    setSimpleTooltipRootContainer(simpleTooltipPlaceholder);
}
