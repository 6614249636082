/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 9/10/18
 * Time: 16:48
 */
import exportInterface from 'Interfaces/Base';
import {unmaskMain} from 'Interfaces/Mask';
import interceptBadResponseService from 'Services/httpClient/interceptors/InterceptBadResponse';

export function interceptBadResponse(connection, response) {
    interceptBadResponseService({
        response: {...response, data: response.responseText},
    });
    unmaskMain();
}

export const registerInterceptBadResponseInterface = () => {
    exportInterface('Interceptors', {interceptBadResponse});
};
