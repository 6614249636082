import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {setInitialFilters} from 'BackendRenderedComponents/dashboard/redux/actions';

import {DashboardTabs} from './tabs';

import './dashboardKpis.scss';

export class UnconnectedDashboardKpis extends Component {
    componentDidMount() {
        const {demographics, kpiGroups, studentGroups, tabs} = this.props;

        this.props.actions.setInitialFilters({
            demographics: this.stringTokenToId(demographics),
            studentGroups: this.stringTokenToId(studentGroups),
            kpiGroups,
            tabs,
        });
    }

    stringTokenToId = (data) =>
        data.map(({stringToken, ...rest}) => ({id: stringToken, ...rest}));

    render() {
        return (
            <section
                className="dashboard-kpis-container"
                aria-label="Dashboard KPIs"
            >
                <DashboardTabs />
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            setInitialFilters,
        },
        dispatch,
    ),
});

export const DashboardKpis = connect(
    null,
    mapDispatchToProps,
)(UnconnectedDashboardKpis);

UnconnectedDashboardKpis.propTypes = {
    studentGroups: PropTypes.arrayOf(
        PropTypes.exact({
            stringToken: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    demographics: PropTypes.arrayOf(
        PropTypes.exact({
            stringToken: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    kpiGroups: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    tabs: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        setInitialFilters: PropTypes.func,
    }),
};
