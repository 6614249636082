import {lazy, Suspense} from 'react';

import type {VennDiagramProps} from './types';

const VennDiagram = lazy(() => import('./VennDiagramAsDefaultExport'));

export const LazyLoadedVennDiagram = (props: VennDiagramProps) => {
    return (
        <Suspense fallback={<div> Loading data </div>}>
            <VennDiagram {...props} />
        </Suspense>
    );
};
