import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {NavigationTree} from 'Components/navigationTree';
import {toggleSubNavItemAction} from 'State/navigation/actions';
import {NavigationReducerState} from 'State/navigation/reducers';
import {
    selectExpandedIds,
    selectIsNewNavigation,
    selectSubNavTreeData,
    selectIsSubNavHiddenByUser,
    selectCurrentPageId,
    selectStaticParentId,
    selectIsFavouritesEnabled,
} from 'State/navigation/selectors';
import {
    getScreenType,
    MOBILE,
    ScreenType,
    TABLET_PORTRAIT,
} from 'Utils/screenType';

import './subNav.scss';

type SubNavProps = {
    isMobileLayout: boolean;
    expandedIds: Record<string, boolean>;
    treeData: ReturnType<typeof selectSubNavTreeData>;
    isSubNavHiddenByUser: ReturnType<typeof selectIsSubNavHiddenByUser>;
    toggleSubNavItem: typeof toggleSubNavItemAction;
    currentPageId: ReturnType<typeof selectCurrentPageId>;
    staticParentId: ReturnType<typeof selectStaticParentId>;
    isFavouritesEnabled: ReturnType<typeof selectIsFavouritesEnabled>;
};

export const UnconnectedSubNav = ({
    treeData,
    expandedIds,
    toggleSubNavItem,
    isMobileLayout,
    isSubNavHiddenByUser,
    currentPageId,
    staticParentId,
    isFavouritesEnabled,
}: SubNavProps) => {
    const subNavTitle = treeData.text;
    const subNavTreeData = treeData.items;
    const screenType: ScreenType = getScreenType();

    if (!subNavTitle && (subNavTreeData?.length ?? 0) === 0) {
        return null;
    }
    return (
        <>
            {(!isSubNavHiddenByUser ||
                screenType === MOBILE ||
                screenType === TABLET_PORTRAIT) && (
                <nav
                    aria-label="Side navigation"
                    className="sub-nav__container"
                >
                    <h2 className="sub-nav__header">{subNavTitle}</h2>
                    {subNavTreeData && (
                        <NavigationTree
                            toggleItemExpanded={toggleSubNavItem}
                            expandedIds={expandedIds}
                            isMobileLayout={isMobileLayout}
                            treeData={subNavTreeData}
                            currentPageId={currentPageId}
                            staticParentId={staticParentId}
                            isFavouritesEnabled={isFavouritesEnabled}
                            gainsightEventType={
                                isMobileLayout
                                    ? 'Burger Menu Subnav Link'
                                    : 'Subnav Link'
                            }
                        />
                    )}
                </nav>
            )}
        </>
    );
};

const mapStateToProps = (state: {navigation: NavigationReducerState}) => ({
    expandedIds: selectExpandedIds(state),
    treeData: selectSubNavTreeData(state),
    isNewNavigation: selectIsNewNavigation(state),
    isSubNavHiddenByUser: selectIsSubNavHiddenByUser(state),
    currentPageId: selectCurrentPageId(state),
    staticParentId: selectStaticParentId(state),
    isFavouritesEnabled: selectIsFavouritesEnabled(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleSubNavItem: toggleSubNavItemAction,
        },
        dispatch,
    );

export const SubNav = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedSubNav);
