import classNames from 'classnames';
import {useState, useRef, useEffect} from 'react';
import * as React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import {
    CellMeasurer,
    CellMeasurerCache,
} from 'react-virtualized/dist/commonjs/CellMeasurer';
import List from 'react-virtualized/dist/commonjs/List';

import {DropdownContainer} from 'Components/dropdown';
import {FormFieldWrapper} from 'Components/formField';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {relayoutExtContainer} from 'Root/core/utils/relayoutExtContainer';
import {findLastIndex} from 'Utils/findLastIndex';
import {
    BACKSPACE_KEY_CODE,
    ENTER_KEY_CODE,
    ESCAPE_KEY_CODE,
    ARROW_DOWN_KEY_CODE,
    ARROW_UP_KEY_CODE,
    A_KEY_CODE,
} from 'Utils/keyCodeConstants';

import {filterComboboxOptions} from './filterComboboxOptions';
import {sortComboboxOptions} from './sortComboboxOptions';
import {
    SelectedItemProps,
    ComboboxItem,
    SelectedItems,
    ComboboxProps,
    ComboboxPropsWithMultiselect,
    FreeTextItem,
    ComboboxValue,
} from './types';
import {createInitialSelectedValues, isAnythingSelected} from './utils';

import './combobox.scss';

const SelectedItem = ({
    children,
    onClick,
    isMultiselect,
}: SelectedItemProps) => (
    <span
        onClick={onClick}
        className={classNames('combobox__selected-item', {
            'combobox__selected-item--multiselect': !isMultiselect,
        })}
    >
        {children}
        <Icon iconName="remove" />
    </span>
);

// Needs to be decalred outside of the component, else a new empty array
// is created on each render, which causes a loop due to useEffect dependency
const defaultInitialValue = [];

export const Combobox = (props: ComboboxProps) => {
    const {
        items,
        isMultiselect = false,
        label,
        placeholder,
        isLoading,
        initialValue = defaultInitialValue,
        inlineLabel = true,
        disabled = false,
        tooltip,
        tooltipUrl,
        errors,
        allowFreeText,
        remoteSort,
        setRef,
        required,
        rendererParams,
    } = props;
    const isLoadingRef = useRef(isLoading);
    useEffect(() => {
        isLoadingRef.current = isLoading;
    }, [isLoading]);

    const isMacRef = useRef(navigator.userAgent.indexOf('Mac OS X') !== -1);

    const inputRef = useRef<HTMLInputElement>(null);
    const comboboxContentsRef = useRef<HTMLDivElement>(null);
    const virtulisedListRef = useRef<List>(null);
    const cacheRef = useRef(
        new CellMeasurerCache({
            defaultHeight: 25,
            minHeight: 25,
            fixedWidth: true,
        }),
    );
    const [searchValue, setSearchValue] = useState('');

    const [filteredItems, setFilteredItems] = useState(() =>
        sortComboboxOptions(items, remoteSort),
    );

    const [highlightedItemIndex, setHighlightedItemIndex] = useState(0);

    const withMultiselect = (
        p: ComboboxProps,
    ): p is ComboboxPropsWithMultiselect => p.isMultiselect === true;

    const [selectedItems, setSelectedItems] = useState<SelectedItems>(() =>
        createInitialSelectedValues(
            initialValue,
            {},
            items,
            isMultiselect,
            !!isLoading,
        ),
    );

    const [freeTextItems, setFreeTextItems] = useState<FreeTextItem[]>([]);

    useEffect(() => {
        // As items is a dependency of this effect, it's important that items
        // has a stable reference if it isn't changing (i.e. useMemo can be helpful)
        // in parent components if items are calculated
        setSelectedItems((prevSelectedItems) =>
            createInitialSelectedValues(
                initialValue,
                prevSelectedItems,
                items,
                isMultiselect,
                // using a ref for isLoading instead of passing as a dependency as we don't
                // want this ref to run when the lodaing state triggers, as this happens
                // before the items are finalised
                !!isLoadingRef.current,
            ),
        );
    }, [initialValue, items, isMultiselect]);

    useEffect(() => {
        cacheRef.current.clearAll();

        const calculateNewHighlightedItemIndex = (
            newFilteredItemsGrouped: ComboboxItem[],
            currentIndex: number,
        ) => {
            let newHighlightedItemIndex = currentIndex;
            if (currentIndex > newFilteredItemsGrouped.length - 1) {
                newHighlightedItemIndex = 0;
                // This resets the highlighting back to the first option when the index goes out of range.
            }
            if (newFilteredItemsGrouped[newHighlightedItemIndex]?.isGroup) {
                newHighlightedItemIndex += 1;
            }

            return newHighlightedItemIndex;
        };

        const newFilteredItems = filterComboboxOptions(items, searchValue);

        const sortedItems = sortComboboxOptions(
            newFilteredItems,
            remoteSort,
            searchValue,
        );

        setFilteredItems(sortedItems);

        setHighlightedItemIndex((currentIndex) =>
            calculateNewHighlightedItemIndex(sortedItems, currentIndex),
        );
    }, [searchValue, items, remoteSort]);

    useEffect(() => {
        if (isLoading) {
            // We don't want to trigger any onchanges during the loading state
            return;
        }
        if (typeof props?.onChange === 'function') {
            if (freeTextItems.length > 0 && !withMultiselect(props)) {
                const [firstItem] = freeTextItems;
                props.onChange(firstItem.label);
            } else {
                const itemsToSend = [
                    ...Object.entries(selectedItems)
                        .filter(([, value]) => value)
                        .map(([key]) => key),
                    ...freeTextItems.map(({label: itemLabel}) => itemLabel),
                ];
                if (withMultiselect(props)) {
                    props.onChange(itemsToSend);
                } else {
                    props.onChange(itemsToSend[0] ?? '');
                }
            }
        }
        // only relayout if inside a filter panel to fix button location
        if (rendererParams?.parentFilterPanelId) {
            relayoutExtContainer(inputRef.current);
        }
        // MIS-47959 for some reason the linter will only be happy with the whole props object being added in.
        // i've had a go at refactoring things around here, but it's quite messy to do so, and outside
        // the scope of this ticket I think
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems, freeTextItems]);

    useEffect(() => {
        // We only do virtualisation if we have a large number of items, so have
        // 2 possible scroll techniques to use.
        if (virtulisedListRef.current) {
            // Scroll index is passed as prop
            return;
        }

        // Virtulisation not being used, so use browser's normal Element.scrollIntoView()
        if (!comboboxContentsRef.current) return;
        const comboboxContentChildren = comboboxContentsRef.current.children;
        if (
            !comboboxContentChildren ||
            comboboxContentChildren.length === 0 ||
            !comboboxContentChildren[highlightedItemIndex]
        )
            return;
        comboboxContentChildren[highlightedItemIndex].scrollIntoView({
            block: 'nearest',
        });
    }, [highlightedItemIndex]);

    const handleFreeTextAdd = (
        value: string,
        closeDropdown: ({
            shouldMaintainFocus,
        }: {
            shouldMaintainFocus?: boolean;
        }) => void,
    ) => {
        if (isMultiselect) {
            setFreeTextItems([...freeTextItems, {label: value}]);
        } else {
            setSelectedItems({});
            setFreeTextItems([{label: value}]);
        }
        closeDropdown({shouldMaintainFocus: true});
    };

    const handleSelect = (
        item: ComboboxItem,
        closeDropdown: ({
            shouldMaintainFocus,
        }: {
            shouldMaintainFocus?: boolean;
        }) => void,
    ) => {
        if (isMultiselect) {
            setSelectedItems((prev) => ({
                ...prev,
                [item.value]: !prev[item.value],
            }));
        } else {
            setFreeTextItems([]);
            setSelectedItems((prev) => ({
                [item.value]: !prev[item.value],
            }));
            setSearchValue('');
            closeDropdown({shouldMaintainFocus: true});
        }
    };

    const removeLastSelected = () => {
        if (freeTextItems.length > 0) {
            setFreeTextItems((prev) => prev.slice(0, prev.length - 1));
        } else {
            setSelectedItems((prev) => {
                const lastIndex = findLastIndex(
                    items,
                    (item) => selectedItems[item.value],
                );
                if (lastIndex >= 0) {
                    return {
                        ...prev,
                        [items[lastIndex].value]: false,
                    };
                }
                return prev;
            });
        }
    };

    const onInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        openDropdown: () => void,
    ) => {
        const value = event.target.value;
        setSearchValue(value);
        openDropdown();
    };

    const handleInputKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        openDropdown: () => void,
        closeDropdown: ({
            shouldMaintainFocus,
        }: {
            shouldMaintainFocus?: boolean;
        }) => void,
        isDropdownVisible: boolean,
    ) => {
        if (event.keyCode === BACKSPACE_KEY_CODE && !searchValue) {
            removeLastSelected();
        }
        if (event.keyCode === ESCAPE_KEY_CODE && isDropdownVisible) {
            closeDropdown({shouldMaintainFocus: true});
            event.stopPropagation();
        }

        if (event.keyCode === ARROW_UP_KEY_CODE) {
            let newHighlightedItemIndex = highlightedItemIndex;
            if (
                highlightedItemIndex > 0 &&
                !filteredItems[highlightedItemIndex - 1].isGroup
            ) {
                newHighlightedItemIndex = highlightedItemIndex - 1;
            }
            if (
                highlightedItemIndex > 1 &&
                filteredItems[highlightedItemIndex - 1].isGroup
            ) {
                newHighlightedItemIndex = highlightedItemIndex - 2;
            }
            setHighlightedItemIndex(newHighlightedItemIndex);
        }

        if (event.keyCode === ARROW_DOWN_KEY_CODE) {
            if (!isDropdownVisible) {
                openDropdown();
            } else if (highlightedItemIndex < filteredItems.length - 1) {
                let newHighlightedItemIndex = highlightedItemIndex;
                newHighlightedItemIndex += 1;
                if (filteredItems[newHighlightedItemIndex].isGroup) {
                    newHighlightedItemIndex += 1;
                }
                setHighlightedItemIndex(newHighlightedItemIndex);
            }
        }

        if (event.keyCode === ENTER_KEY_CODE) {
            if (!isDropdownVisible) {
                openDropdown();
            } else if (filteredItems[highlightedItemIndex]) {
                handleSelect(
                    filteredItems[highlightedItemIndex],
                    closeDropdown,
                );
            } else if (allowFreeText) {
                handleFreeTextAdd(searchValue, closeDropdown);
            }
        }

        if (
            event.keyCode === A_KEY_CODE &&
            (isMacRef.current ? event.metaKey : event.ctrlKey) &&
            isDropdownVisible &&
            isMultiselect
        ) {
            setSelectedItems(
                filteredItems.reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.value]: true,
                    }),
                    {},
                ),
            );
        }
    };

    const handleItemKeyDown = (
        event: React.KeyboardEvent<HTMLButtonElement>,
        closeDropdown: ({
            shouldMaintainFocus,
        }: {
            shouldMaintainFocus?: boolean;
        }) => void,
    ) => {
        if (event.keyCode === ESCAPE_KEY_CODE) {
            closeDropdown({shouldMaintainFocus: true});
            event.stopPropagation();
        }
    };

    const renderComboboxRow = ({
        item,
        key,
        closeDropdown,
    }: {
        item: ComboboxItem;
        key: ComboboxValue;
        closeDropdown: () => void;
    }) => (
        <KeyboardFocusableButton
            insetFocusRing
            key={key}
            onKeyDown={(e) => handleItemKeyDown(e, closeDropdown)}
            buttonWrapperClassName={classNames(
                'combobox__dropdown-item__wrapper',
                {
                    'combobox__dropdown-item__wrapper--selected':
                        selectedItems[item.value],
                },
                {
                    'combobox__dropdown-item__wrapper--highlighted':
                        item === filteredItems[highlightedItemIndex],
                },
                {
                    'combobox__dropdown-item__group-label': item.isGroup,
                },
            )}
            onClick={() => handleSelect(item, closeDropdown)}
            tabIndex={-1}
            disabled={item.isGroup}
        >
            {item.icon && (
                <Icon
                    iconName={item.icon}
                    className="combobox__icon"
                    aria-label={item.icon}
                />
            )}
            {item.dropdownLabel ?? item.label}
            {typeof item.size !== 'undefined' ? (
                <span className="combobox__item-size"> ({item.size})</span>
            ) : (
                ''
            )}
        </KeyboardFocusableButton>
    );

    const virtualisedComboboxRow =
        (closeDropdown: () => void) =>
        ({index, style, parent, key}) => {
            const item = filteredItems[index];
            if (!item) {
                console.error(
                    '!item in VirtualisedComboboxItem',
                    filteredItems,
                    index,
                );
                return null;
            }
            return (
                <CellMeasurer
                    cache={cacheRef.current}
                    columnIndex={0}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    {({registerChild}) => (
                        <div
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore ts2322
                            ref={registerChild}
                            role="presentation"
                            style={style}
                        >
                            {renderComboboxRow({
                                item,
                                closeDropdown,
                                key,
                            })}
                        </div>
                    )}
                </CellMeasurer>
            );
        };

    const renderComboboxRows = ({
        comboboxItems,
        closeDropdown,
        isDropdownVisible,
    }: {
        comboboxItems: ComboboxItem[];
        closeDropdown: () => void;
        isDropdownVisible: boolean;
    }) => {
        if (comboboxItems.length === 0) {
            if (allowFreeText) {
                return (
                    <div className="combobox__empty-item-list">
                        {searchValue} (new value)
                    </div>
                );
            }

            return (
                <div className="combobox__empty-item-list">
                    No results found
                </div>
            );
        }

        if (comboboxItems.length < 30) {
            // This is because the List from react-virtulized requires a fixed height,
            // so by not virtualising, we avoid needing to calculate the correct height
            // and remove the chance of leaving gaps, or having unnecessary scrolling.
            return comboboxItems.map((item) =>
                renderComboboxRow({
                    item,
                    key: item.value,
                    closeDropdown,
                }),
            );
        }

        if (!isDropdownVisible) {
            // Ensure that the Autosizer unmounts when dropdown closes and remounts when it
            // re-opens, so that it calculates item size again.
            return null;
        }
        // Do virtulisation with react-window to avoid rendering bottlenecks. Speedup is
        // especially noticeable when you have more than 1000 items
        return (
            <AutoSizer
                disableHeight
                onResize={() => {
                    if (typeof cacheRef.current?.clearAll === 'function') {
                        // May be worth debouncing this function if we run into performance issues
                        // when resizing the screen when comboboxes with many items are open.
                        // Fairly niche cache so maybe not necessary
                        cacheRef.current.clearAll();
                    }
                }}
            >
                {({width}) => (
                    <List
                        width={width}
                        ref={virtulisedListRef}
                        deferredMeasurementCache={cacheRef.current}
                        rowHeight={cacheRef.current.rowHeight}
                        height={Math.min(300, filteredItems.length * 25)}
                        rowCount={filteredItems.length}
                        scrollToAlignment="center"
                        scrollToIndex={highlightedItemIndex}
                        rowRenderer={virtualisedComboboxRow(closeDropdown)}
                    ></List>
                )}
            </AutoSizer>
        );
    };

    return (
        <FormFieldWrapper
            disabled={disabled}
            label={label}
            inlineLabel={inlineLabel}
            focusCallback={() => {
                if (typeof inputRef?.current?.focus === 'function') {
                    inputRef.current.focus();
                }
            }}
            tooltip={tooltip}
            tooltipUrl={tooltipUrl}
            errors={errors}
            setRef={setRef}
            required={required}
        >
            {isLoading ? (
                <div className="combobox__skeleton"></div>
            ) : (
                <DropdownContainer
                    shiftFocusWithUpAndDownArrows={false}
                    openOnFocus={false}
                    restrictPositionToVertical
                    dropdownClosedCallback={() => {
                        setHighlightedItemIndex(
                            filteredItems.findIndex((item) => !item.isGroup),
                        );
                        if (searchValue) {
                            setSearchValue('');
                        }
                        relayoutExtContainer(inputRef.current);
                    }}
                    renderDropdownField={({
                        toggleDropdown,
                        isDropdownVisible,
                        openDropdown,
                        closeDropdown,
                    }) => (
                        <label className="combobox__input-label">
                            <div>
                                <div
                                    data-testid="Combobox-selectedItems"
                                    className="combobox__selected-items-container"
                                >
                                    {items
                                        .filter(
                                            ({value}) => selectedItems[value],
                                        )
                                        .map((item) => (
                                            <SelectedItem
                                                key={item.value}
                                                isMultiselect={isMultiselect}
                                                onClick={() =>
                                                    handleSelect(
                                                        item,
                                                        closeDropdown,
                                                    )
                                                }
                                            >
                                                {item.icon && (
                                                    <Icon
                                                        iconName={item.icon}
                                                        className="combobox__icon"
                                                        aria-label={item.icon}
                                                    />
                                                )}
                                                {item.label}
                                                {typeof item.size !==
                                                'undefined' ? (
                                                    <span className="combobox__item-size">
                                                        ({item.size})
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </SelectedItem>
                                        ))}
                                    {freeTextItems.map((item, index) => (
                                        <SelectedItem
                                            key={index}
                                            isMultiselect={isMultiselect}
                                            onClick={removeLastSelected}
                                        >
                                            {item.label}
                                        </SelectedItem>
                                    ))}
                                    <input
                                        ref={inputRef}
                                        className="combobox__input"
                                        data-testid="Combobox-input"
                                        type="text"
                                        value={searchValue}
                                        disabled={disabled}
                                        onChange={(e) =>
                                            onInputChange(e, openDropdown)
                                        }
                                        onKeyDown={(e) =>
                                            handleInputKeyDown(
                                                e,
                                                openDropdown,
                                                closeDropdown,
                                                isDropdownVisible,
                                            )
                                        }
                                        placeholder={
                                            isAnythingSelected(selectedItems)
                                                ? ''
                                                : placeholder
                                        }
                                        onClick={() => openDropdown()}
                                    />
                                </div>
                            </div>
                            <Icon
                                onClick={(e) => {
                                    if (disabled) {
                                        return;
                                    }
                                    if (isDropdownVisible) {
                                        // to be able to close the dropdown
                                        e.preventDefault();
                                    }
                                    toggleDropdown();
                                }}
                                iconName={
                                    isDropdownVisible
                                        ? 'arrow-go-up'
                                        : 'arrow-go-down'
                                }
                                className={classNames('combobox__input-icon', {
                                    'combobox__input-icon--disabled': disabled,
                                })}
                            />
                        </label>
                    )}
                    renderDropdownContents={({
                        closeDropdown,
                        isDropdownVisible,
                    }) => (
                        <div
                            className="combobox__dropdown-container"
                            data-testid="Combobox-optionsContainer"
                            ref={comboboxContentsRef}
                        >
                            {renderComboboxRows({
                                comboboxItems: filteredItems,
                                closeDropdown,
                                isDropdownVisible,
                            })}
                        </div>
                    )}
                    shiftFocusWithLeftAndRightArrows
                />
            )}
        </FormFieldWrapper>
    );
};
