import classNames from 'classnames';
import {useRef} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {loadPage, downloadFile} from 'Interfaces/Loader';
import {
    getUserNotificationService,
    INotification,
} from 'Services/userNotifications/UserNotification';
import utils from 'Utils/helper';
import {BACKSPACE_KEY_CODE, DELETE_KEY_CODE} from 'Utils/keyCodeConstants';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import './notificationDropdownItem.scss';

type NotificationDropdownItemProps = {
    onClick?: () => void;
    notification: INotification;
    onDismiss?: () => void;
    hideNotification?: (id: number) => void;
};

export function NotificationDropdownItem({
    onClick,
    notification,
    onDismiss,
    hideNotification,
}: NotificationDropdownItemProps) {
    const {
        title,
        content,
        image,
        url,
        isRead,
        action,
        created,
        previewAutoDismissable,
    } = notification;

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const stopTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    };

    useComponentDidMount(() => {
        if (previewAutoDismissable && typeof hideNotification === 'function') {
            timerRef.current = setTimeout(() => {
                hideNotification(notification.userNotificationId);
            }, 5000);

            return () => {
                stopTimer();
            };
        }
        return () => null;
    });

    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick();
        }

        getUserNotificationService().markNotificationAsRead(notification);

        let method;

        switch (action) {
            case 'loadPage':
                method = loadPage;
                break;
            case 'download':
                method = downloadFile;
                break;
            default: //Unsupported notification type
                //eslint-disable-line
                console.error('Unsupported notification action');
                break;
        }

        if (method) {
            method(url);
        }
    };

    const dismissNotification = () => {
        stopTimer();
        getUserNotificationService().markNotificationAsHidden(notification);
        if (typeof onDismiss === 'function') {
            onDismiss();
        }
    };

    const handleKeydownPress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.keyCode === BACKSPACE_KEY_CODE || e.keyCode === DELETE_KEY_CODE) {
            dismissNotification();
        }
    };

    return (
        <li className="arbor-notification-item__container">
            <KeyboardFocusableButton
                onClick={handleClick}
                onKeyDown={handleKeydownPress}
                className={classNames('arbor-notification-item', {
                    'arbor-notification-item--read': isRead,
                })}
                buttonWrapperClassName="arbor-notification-item__button-wrapper"
                insetFocusRing
                tabIndex={-1}
            >
                <div className="notification-item-img">
                    <img src={image} alt="" />
                </div>
                <div className="notification-item-content">
                    <h4>{title}</h4>
                    <div>{content}</div>
                </div>
                <div className="notification-item__right">
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                            dismissNotification();
                        }}
                        className="notification-item__hide-button"
                        data-testid="NotificationDrpdownItem-dismissIcon"
                    >
                        <Icon iconName="cross" />
                    </div>
                    <div className="notification-item-time">
                        {utils.timestampToString(created)}
                    </div>
                </div>
            </KeyboardFocusableButton>
        </li>
    );
}
