import DOMPurify from 'dompurify';

interface StyleRule {
    attributeName: string;
    validate?(value: string): boolean;
}

const styleRules: StyleRule[] = [
    {attributeName: 'font-weight'},
    {attributeName: 'font-size'},
    {attributeName: 'font-style'},
    {attributeName: 'font-family'},
    {attributeName: 'font'},

    {attributeName: 'color'},

    {attributeName: 'display'},
    {attributeName: 'position'},
    {attributeName: 'vertical-align'},
    {attributeName: 'text-align'},
    {attributeName: 'float'},

    {attributeName: 'border'},

    {attributeName: 'padding'},
    {attributeName: 'padding-top'},
    {attributeName: 'padding-left'},
    {attributeName: 'padding-right'},
    {attributeName: 'padding-bottom'},

    {attributeName: 'width'},
    {attributeName: 'height'},
    {attributeName: 'max-width'},
    {attributeName: 'max-height'},

    {attributeName: 'margin'},
    {attributeName: 'margin-top'},
    {attributeName: 'margin-left'},
    {attributeName: 'margin-right'},
    {attributeName: 'margin-bottom'},

    {attributeName: 'top'},
    {attributeName: 'left'},
    {attributeName: 'right'},
    {attributeName: 'bottom'},

    {
        attributeName: 'background',
        validate(str) {
            return str.indexOf('url(') === -1;
        },
    },
    {
        attributeName: 'background-image',
        validate(str) {
            return str.indexOf('url(') === -1;
        },
    },
    {attributeName: 'background-attachment'},
    {attributeName: 'background-clip'},
    {attributeName: 'background-color'},
    {attributeName: 'background-origin'},
    {attributeName: 'background-position'},
    {attributeName: 'background-repeat'},
    {attributeName: 'background-size'},
];

const sanitizeStyle = (node: HTMLUnknownElement) => {
    if (node.hasAttribute('style')) {
        for (let i = 0; i < node.style.length; i++) {
            const propertyName = node.style.item(i);
            const rule = styleRules.find(
                (value) => value.attributeName === propertyName,
            );

            if (typeof rule === 'undefined') {
                // rule isn't in whitelist
                node.style.removeProperty(propertyName);
            } else {
                if (
                    typeof rule.validate === 'function' &&
                    !rule.validate(node.style.getPropertyValue(propertyName))
                ) {
                    node.style.removeProperty(propertyName);
                }
            }
        }
    }

    if (node.hasChildNodes()) {
        node.childNodes.forEach((childNode) => {
            if (childNode instanceof HTMLElement) {
                sanitizeStyle(childNode);
            }
        });
    }
};

export const sanitizeInlineStyles = (content: string): string => {
    DOMPurify.addHook('afterSanitizeAttributes', sanitizeStyle);
    const cleanText = DOMPurify.sanitize(content);
    DOMPurify.removeHook('afterSanitizeAttributes');

    return cleanText;
};
