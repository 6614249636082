import {useRef, useState} from 'react';

import {DropdownMenu} from 'Components/dropdown/dropdownMenu';
import {SECTION_SELECTOR} from 'Config/Events';
import {usePubSub} from 'Services/pubsub';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {getAllSectionsWithinColumn} from './helpers';

import './selectors.scss';

export const SectionSelector = ({}) => {
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState('all');

    const sectionSelectorRef = useRef();

    const updateSectionList = () => {
        const sectionsInColumn = getAllSectionsWithinColumn(
            sectionSelectorRef.current,
        );
        setSections(
            [...sectionsInColumn].sort((a, b) => {
                return (a.title ?? '').localeCompare(b.title ?? ''); // Alphabetical sorting
            }),
        );
    };

    useComponentDidMount(() => {
        updateSectionList();
    });
    usePubSub(SECTION_SELECTOR.RELOAD, () => {
        updateSectionList();
    });

    const showSection = (value) => {
        setSelectedSection(value);

        if (value === 'all') {
            sections.forEach((section) => section.show());
            return;
        }
        sections.forEach((section) =>
            section.id === value ? section.show() : section.hide(),
        );
    };

    const options = [
        {
            value: 'all',
            title: 'All Sections',
            onClick: () => showSection('all'),
        },
        ...sections.map(({id: value, title}) => ({
            value,
            title,
            onClick: () => showSection(value),
        })),
    ];

    return (
        <section
            ref={sectionSelectorRef}
            className="selector__wrapper"
            aria-label="Section Selector"
        >
            <label className="selector__label">View</label>
            <div className="selector__container">
                <DropdownMenu
                    label={
                        options.find(
                            (option) => option.value === selectedSection,
                        ).title
                    }
                    items={options}
                    color="light-grey"
                    // This is close to the height of the original Ext SectionSelector (300px),
                    // but with a bit of extra height so that it cuts off the next element so
                    // that it is more obviously scrollable
                    maxHeight={313}
                />
            </div>
        </section>
    );
};

SectionSelector.propTypes = {};
