import {TransformedNavigationItem} from '../types';

import {findItemInTree} from './findItemInTree';

export const findEntryPointInTree = (
    entryPoint: string,
    treeData: TransformedNavigationItem[],
): TransformedNavigationItem | null => {
    return findItemInTree(
        (item: TransformedNavigationItem) =>
            !!(item.entryPoint && item.entryPoint.includes(entryPoint)),
        treeData,
    );
};
