import {TransformedNavigationItem} from '../types';

import {findItemInTree} from './findItemInTree';

export const findIdInTree = (
    id: string,
    treeData: TransformedNavigationItem[],
): TransformedNavigationItem | null => {
    return findItemInTree(
        (item: TransformedNavigationItem) => item.id === id,
        treeData,
    );
};
