import {Component} from 'react';
import {connect} from 'react-redux';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

import {slideover as slideoverDuration} from 'Config/Durations';
import {SLIDEOVER} from 'Config/Events';
import {addSystemModal} from 'Interfaces/SystemModal';
import pubsub from 'Services/pubsub/Pubsub';
import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';
import {getUnsavedDataRegistryService} from 'Services/unsavedData';
import {selectUserFavouritePages} from 'State/navigation/selectors';

import {Slideover} from './Slideover';
import {SlideoverData} from './types';

import './slideoverManager.scss';

type SlideoverManagerState = {
    slideovers: SlideoverData[];
    hideAllSlideovers: boolean;
};
type SlideoverManagerProps = {
    userFavouritePages: UserFavouritePage[];
};
class UnconnectedSlideoverManager extends Component<
    SlideoverManagerProps,
    SlideoverManagerState
> {
    // This is a Last-in, First-out (LIFO) stack for rendering slideovers.
    constructor(props: SlideoverManagerProps) {
        super(props);

        this.state = {
            hideAllSlideovers: false,
            slideovers: [],
        };
    }
    private unsubscribers: (() => void)[] = [];
    componentDidUpdate(
        _prevProps: SlideoverManagerProps,
        prevState: SlideoverManagerState,
    ) {
        if (
            prevState.slideovers.length > 0 &&
            this.state.slideovers.length === 0
        ) {
            const fieldsWithActiveClass =
                document.querySelectorAll('.list-row-active');

            [...fieldsWithActiveClass].forEach((node) => {
                const extNode = Ext.getCmp(node.id);
                if (extNode) {
                    extNode.removeCls('list-row-active');
                } else {
                    node.classList.remove('list-row-active');
                }
            });
        }
    }

    componentDidMount() {
        const subscriptionAddSlideover = pubsub.subscribe(
            SLIDEOVER.ADD,
            this.handleAdd,
        );
        const subscriptionRemoveSlideover = pubsub.subscribe(
            SLIDEOVER.REMOVE,
            this.handleRemove,
        );
        const subscriptionRemoveAllSlideovers = pubsub.subscribe(
            SLIDEOVER.REMOVE_ALL,
            this.handleRemoveAll,
        );
        const subscriptionRemoveAllSlideoversNoAnimation = pubsub.subscribe(
            SLIDEOVER.REMOVE_ALL_WITHOUT_ANIMATION,
            this.handleRemoveAllWithoutAnimation,
        );

        this.unsubscribers = [
            () => pubsub.unsubscribe(SLIDEOVER.ADD, subscriptionAddSlideover),
            () =>
                pubsub.unsubscribe(
                    SLIDEOVER.REMOVE,
                    subscriptionRemoveSlideover,
                ),
            () =>
                pubsub.unsubscribe(
                    SLIDEOVER.REMOVE_ALL,
                    subscriptionRemoveAllSlideovers,
                ),
            () =>
                pubsub.unsubscribe(
                    SLIDEOVER.REMOVE_ALL_WITHOUT_ANIMATION,
                    subscriptionRemoveAllSlideoversNoAnimation,
                ),
        ];
    }

    componentWillUnmount() {
        this.unsubscribers.forEach((unsubscribeFunction) =>
            unsubscribeFunction(),
        );
    }

    handleAdd = (slideover: SlideoverData) => {
        this.setState((prevState) => ({
            slideovers: [...prevState.slideovers, slideover],
        }));
    };

    refocusElementThatTriggeredSlideover = (slideover: SlideoverData) => {
        const rendererParams = slideover?.rendererParams;
        const elementToRefocus = rendererParams?.elementToRefocus;
        const refocusCallback = rendererParams?.refocusCallback;
        if (refocusCallback && typeof refocusCallback === 'function') {
            refocusCallback();
        } else if (
            elementToRefocus &&
            typeof elementToRefocus.focus === 'function'
        ) {
            elementToRefocus.focus();
        }
    };

    handleRemove = () => {
        const removeSlideover = () => {
            this.refocusElementThatTriggeredSlideover(
                this.state.slideovers?.[this.state.slideovers.length - 1],
            );

            // Removes the last slideover in list
            this.setState((prevState) => ({
                slideovers: prevState.slideovers.slice(0, -1),
            }));
        };

        if (getUnsavedDataRegistryService().hasInvalidDataSources()) {
            addSystemModal({
                icon: 'info',
                title: 'Changes may not be saved!',
                message: getUnsavedDataRegistryService().getErrorMessage(),
                onConfirm: () => {
                    getUnsavedDataRegistryService().dismiss();
                    removeSlideover();
                },
                confirmationButtonText: 'Leave without saving your changes',
            });
        } else {
            getUnsavedDataRegistryService().dismiss();
            removeSlideover();
        }
    };
    handleRemoveAll = () => {
        const removeAllSlideovers = () => {
            // We want to refocus the element that triggered the first slideover in the stack, as
            // the focusElements from slideovers higher in the stack will be within the stack
            this.refocusElementThatTriggeredSlideover(
                this.state.slideovers?.[0],
            );

            // Removes all slidovers from list
            this.setState({slideovers: []});
        };

        if (getUnsavedDataRegistryService().hasInvalidDataSources()) {
            addSystemModal({
                icon: 'info',
                title: 'Changes may not be saved!',
                message: getUnsavedDataRegistryService().getErrorMessage(),
                onConfirm: () => {
                    getUnsavedDataRegistryService().dismiss();
                    removeAllSlideovers();
                },
                confirmationButtonText: 'Leave without saving your changes',
            });
        } else {
            getUnsavedDataRegistryService().dismiss();
            removeAllSlideovers();
        }
    };
    handleRemoveAllWithoutAnimation = () => {
        // Removes all slidovers from list without animation
        // We set hideAllSlideovers to true then false so that the exit animation is disabled
        // during page changes.

        // unsaved data confirmation isn't called here because this function is called when
        // loading a new page, and we don't want a redundant check
        this.setState({slideovers: [], hideAllSlideovers: true}, () => {
            this.setState({hideAllSlideovers: false});
        });
    };

    render() {
        return (
            <div
                className={`slideover-manager ${
                    this.state.slideovers.length === 0
                        ? 'hide-individual-backgrounds'
                        : ''
                }`}
            >
                {!this.state.hideAllSlideovers && (
                    <TransitionGroup>
                        {this.state.slideovers.length > 0 && (
                            <CSSTransition
                                classNames="slideover-background-transition"
                                timeout={slideoverDuration}
                                key="slideover_background"
                                enter={false}
                            >
                                <div className="slideover-background-overlay" />
                            </CSSTransition>
                        )}
                        {this.state.slideovers.map((slideover, index) => (
                            <CSSTransition
                                classNames="slideover-transition"
                                timeout={slideoverDuration}
                                key={`slideover_${index}`}
                            >
                                <div
                                    className={
                                        index > 0
                                            ? 'slideover-individual-background'
                                            : ''
                                    }
                                >
                                    <Slideover
                                        header={slideover.header}
                                        body={slideover.body}
                                        footerItems={slideover.footerItems}
                                        handleRemove={this.handleRemove}
                                        rendererParams={
                                            slideover.rendererParams
                                        }
                                        userFavouritePages={
                                            this.props.userFavouritePages
                                        }
                                    />
                                </div>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userFavouritePages: selectUserFavouritePages(state),
});

export const SlideoverManager = connect(mapStateToProps)(
    UnconnectedSlideoverManager,
);
