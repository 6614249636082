import classnames from 'classnames';

import {parseUnsafeInt} from 'Root/core/utils/parseUnsafeInt';
import {splitArrayIntoChunks} from 'Utils/splitArrayIntoChunks';

import type {ReactNode} from 'react';

import './kpiPanelGroup.scss';

type KpiPanelGroupProps = {
    children: ReactNode[];
    maxColumns?: number | string;
};

export const KpiPanelGroup = ({children, maxColumns}: KpiPanelGroupProps) => {
    const parsedMaxColumns = parseUnsafeInt(maxColumns, 4);

    const numberOfColumns =
        children &&
        (children.length > parsedMaxColumns
            ? parsedMaxColumns
            : children.length);

    if (!children) {
        return null;
    }

    return splitArrayIntoChunks(children, parsedMaxColumns).map(
        (chunk, index) => (
            <div
                className={classnames('kpi-panel-group', {
                    'kpi-panel-group__1-panel': numberOfColumns <= 1,
                    'kpi-panel-group__2-panels': numberOfColumns === 2,
                    'kpi-panel-group__3-panels': numberOfColumns === 3,
                    'kpi-panel-group__4-panels': numberOfColumns >= 4,
                })}
                key={index}
            >
                {chunk}
                {chunk.length < numberOfColumns &&
                    // Add empty divs to fill the empty columns in the final row, to avoid
                    // items stretching. This is so that the KPI Panels all line up neatly
                    new Array(numberOfColumns - chunk.length)
                        .fill(0)
                        .map((_emptyPanel, innerIndex) => (
                            <div key={innerIndex} />
                        ))}
            </div>
        ),
    );
};
