import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import getCurrencySymbol from 'Interfaces/currency/Currency';

class BasketCalculator extends Component {
    render() {
        const {subTotal, vat, total, currencyCode} = this.props;
        return (
            <div className="basket-calculator">
                <div className="calculator-row">
                    <div className="label">Sub-total</div>
                    <div className="sum">{`${getCurrencySymbol(
                        currencyCode,
                    )}${subTotal}`}</div>
                </div>
                <div className="calculator-row">
                    <div className="label">VAT</div>
                    <div className="sum">{`${getCurrencySymbol(
                        currencyCode,
                    )}${vat}`}</div>
                </div>
                <div className="calculator-row">
                    <div className="label bold">Total</div>
                    <div className="sum">{`${getCurrencySymbol(
                        currencyCode,
                    )}${total}`}</div>
                </div>
            </div>
        );
    }
}

BasketCalculator.propTypes = {
    subTotal: PropTypes.number,
    vat: PropTypes.number,
    total: PropTypes.number,
    currencyCode: PropTypes.string,
};

export default connect((state) => ({
    subTotal: state.basket.calculator.subTotal,
    vat: state.basket.calculator.vat,
    total: state.basket.calculator.total,
}))(BasketCalculator);
