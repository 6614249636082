import {Fragment} from 'react';

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
export function removeExcessWhitespace(input: string) {
    return input.trim().replace(/\ +/g, ' ');
}

// Split text into highlighted and non-highlighted sections based on a search query
export const Highlighter = ({
    text = '',
    highlight = '',
}: {
    text?: string;
    highlight: string | string[];
}) => {
    let matchRegex: RegExp | null = null;

    if (typeof highlight === 'string') {
        const strippedHighlight = removeExcessWhitespace(highlight);
        if (strippedHighlight === '') {
            return <>{text}</>;
        }
        matchRegex = new RegExp(escapeRegExp(strippedHighlight), 'gi');
    } else {
        const strippedHighlights = highlight
            .map((item) => removeExcessWhitespace(item))
            .filter((item) => item !== '');
        if (strippedHighlights.length === 0) {
            return <>{text}</>;
        }
        matchRegex = new RegExp(
            strippedHighlights.map((item) => escapeRegExp(item)).join('|'),
            'gi',
        );
    }

    const matches = [...text.matchAll(matchRegex)];

    return (
        <>
            {text.split(matchRegex).map((nonBoldText, index, arr) => (
                <Fragment key={index}>
                    {nonBoldText}
                    {index + 1 !== arr.length && <mark>{matches[index]}</mark>}
                </Fragment>
            ))}
        </>
    );
};
