import {httpPost} from 'Interfaces/httpClient';
import {appendFormatJson} from 'Interfaces/Url';
import {GenericResponse} from 'Root/types/response';
import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';

type UserFavouritePageResponse = {
    userFavouritePages: UserFavouritePage[];
};

const baseFormUrl = '/user-favourite-page';
const createUrl = `${baseFormUrl}/create`;
const updateUrl = `${baseFormUrl}/update`;
const deleteUrl = `${baseFormUrl}/delete`;

export const updateFavouritePageRequest = async ({
    userFavouritePageId,
    url,
    customFavouriteName,
}: {
    userFavouritePageId?: number;
    url: string;
    customFavouriteName: string;
}) => {
    try {
        const response = await httpPost<
            GenericResponse & UserFavouritePage & UserFavouritePageResponse
        >(appendFormatJson(updateUrl), {
            fields: {
                userFavouritePageId: {value: userFavouritePageId || null},
                route: {value: url},
                customName: {value: customFavouriteName},
            },
        });

        if (!response.userFavouritePages && !response.userFavouritePageId) {
            throw new Error(
                'Something went wrong when trying to update favourite page',
            );
        }

        return {
            userFavouritePageId:
                response.userFavouritePageId || userFavouritePageId,
            url,
            customFavouriteName,
            userFavouritePages: response.userFavouritePages,
        };
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export const createFavouritePageRequest = async ({
    url,
    customFavouriteName,
}: {
    userFavouritePageId?: number;
    url: string;
    customFavouriteName: string;
}) => {
    try {
        const response = await httpPost<GenericResponse & UserFavouritePage>(
            appendFormatJson(createUrl),
            {
                fields: {
                    userFavouritePageId: {value: null},
                    route: {value: url},
                    customName: {value: customFavouriteName},
                },
            },
        );

        if (!response.success && !response.userFavouritePageId) {
            throw new Error(
                'Something went wrong when trying to create favourite page',
            );
        }

        return {
            userFavouritePageId: response.userFavouritePageId,
            url,
            customFavouriteName,
        };
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};

export const deleteCustomFavouritePageRequest = async (
    userFavouritePageId: number,
    url: string,
) => {
    try {
        const response = await httpPost<
            GenericResponse & UserFavouritePage & UserFavouritePageResponse
        >(appendFormatJson(deleteUrl), {
            fields: {
                userFavouritePageId: {value: userFavouritePageId},
                route: {value: url},
            },
        });
        if (!response.userFavouritePages) {
            throw new Error(
                'Something went wrong when trying to delete favourite page',
            );
        }
        return {
            userFavouritePages: response.userFavouritePages,
        };
    } catch (error) {
        console.error(error);
        throw new Error(error);
    }
};
