import {initDropdownPortal} from 'Components/dropdown';
import {renderSimpleTooltipManager} from 'Components/tooltip/renderSimpleTooltipManager';
import {
    initTooltipPortal,
    initTableTooltipPortal,
} from 'Components/tooltip/TooltipManager';
import {registerInterfaces} from 'Interfaces/registerInterfaces';
import {renderSlideoverManager} from 'Renderer/slideover';
import {renderSystemModalManager} from 'Renderer/systemModal';
import {renderSystemNotificationManager} from 'Renderer/systemNotification';
import {renderWindowManager} from 'Renderer/window';
import {initLegacyInterceptors} from 'Services/bridges/BootstrapBridge';
import {initDevTools} from 'Services/devTools/DevTools';
import {initRaven} from 'Services/raven/Raven';
import {initNotificationPreviewPortal} from 'Services/userNotifications/UserNotification';
import printVersion from 'Utils/versionPrinter';

import {createPlaceholderDivs} from './placeholderDivs';

export function preLoginInitialization() {
    registerInterfaces();
    initRaven();

    createPlaceholderDivs();
    initLegacyInterceptors();

    printVersion();

    renderSlideoverManager();
    renderSystemNotificationManager();
    renderSystemModalManager();
    renderWindowManager();
    renderSimpleTooltipManager();

    initTooltipPortal();
    initTableTooltipPortal();
    initDropdownPortal();
    initNotificationPreviewPortal();
    initDevTools();
}
