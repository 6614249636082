import {UserFavouritePage} from 'Root/core/services/session/fetchCurrentUserSettings';

export const sortFavouritePagesAlphabetically = (
    favouritePages: UserFavouritePage[],
) => {
    const copy = [...favouritePages];
    // Avoid mutation of input
    return copy.sort((pageA, pageB) =>
        pageA.customName
            .toLowerCase()
            .localeCompare(pageB.customName.toLowerCase()),
    );
};
