import classNames from 'classnames';

import {loadPage} from 'Interfaces/Loader';
import {getHrefFromUrl} from 'Utils/getHrefFromUrl';

import './keyboardFocusableLink.scss';

interface IKeyboardFocusableLinkProps
    extends React.ButtonHTMLAttributes<HTMLAnchorElement> {
    setRef?: React.LegacyRef<HTMLAnchorElement>;
    url?: string;
    target?: string;
    className?: string;
    linkWrapperClassName?: string;
    borderRadius?: string;
    margin?: string;
    style?: React.CSSProperties;
    insetFocusRing?: boolean;
    disableDefaultLinkStyles?: boolean;
}

export const KeyboardFocusableLink = ({
    children,
    setRef,
    url,
    target,
    onClick,
    className,
    linkWrapperClassName,
    borderRadius,
    margin,
    style,
    insetFocusRing,
    disableDefaultLinkStyles,
    disabled,
    ...others
}: IKeyboardFocusableLinkProps) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        // We have an <a> tag with href and a click handler.
        // If a modifier key is pressed when clicking, we don't do anything in the handler,
        // allowing the <a> to do the browser's standard behaviour on links (e.g. cmd click to
        // open in a new tab).
        // If it is a normal click, we use the Loader.loadpage function instead to do client-side
        // navigation. However if `target === '_blank'` is set then we open in a new window/tab
        // depending on browser settings. (target="_blank" is the normal api for doing this in <a> tags)

        if (disabled) {
            return;
        }

        if (typeof onClick === 'function') {
            onClick(event);
        }

        if (!url) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        const {shiftKey, altKey, ctrlKey, metaKey} = event;
        if (!shiftKey && !altKey && !ctrlKey && !metaKey) {
            event.preventDefault();
            event.stopPropagation();

            if (target === '_blank') {
                window.open(url);
            } else {
                loadPage(url, {elementToRefocus: event?.currentTarget});
            }
        }
    };
    return (
        // The purpose of this link is to allow a focus state to be applied, but only from
        // keyboard interactions. The <span> will inherit any text-based styles and border radius
        // from the <a>. All other styles should be applied to the inner <span>.
        // The key to this component, is that the inner <span> has tabIndex="-1". This means that
        // it cannot be automatically focused by the keyboard. If you click on the <span> then it
        // will not be focused, and neither will the <a> behind it as focus events do not
        // propagate. If you tab to the component from previous element, then the <a> will
        // be focused. This means we can apply styles if the <a> element is focused by the
        // keyboard. The keyboard-focusable-link-wrapper class removes the default link styling
        // and applies a focus state to the link.
        // Loosely based on this article: https://www.kizu.ru/keyboard-only-focus/
        //
        // If you want to apply border radius to the component, you should pass it to the
        // borderRadius prop. This is so that it can be inherited by the <span> and by the
        // :focus::after pseudoelement. If you want to apply any other style to the link element
        // such as margin, you can use linkWrapperClassName prop.
        //
        // If you need to apply styles to the link based on the link states, such as disabled,
        // you should use a linkWrapperClassName prop and use a child selector for the <span>.
        <a
            ref={setRef}
            {...(disabled ? {} : {href: getHrefFromUrl(url)})}
            className={classNames(
                'keyboard-focusable-link-wrapper',
                {'keyboard-focusable-link-wrapper__inset': insetFocusRing},
                {
                    'keyboard-focusable-link-wrapper__disable-default-styles':
                        disableDefaultLinkStyles,
                },
                linkWrapperClassName,
            )}
            style={{borderRadius, margin}}
            onClick={handleClick}
            {...others}
        >
            <span className={className} style={style} tabIndex={-1}>
                {children}
            </span>
        </a>
    );
};
