import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import {Button} from 'Components/button/base';
import {ENTITY_TYPES} from 'Constants/entityTypes';
import {focusFirstFocusableElement} from 'Root/core/utils/focusUtils';
import {ENTER_KEY_CODE, SPACE_KEY_CODE} from 'Utils/keyCodeConstants';

import './searchFilters.scss';

export type FilterableEntity =
    | 'all'
    | 'page'
    | 'help centre'
    | 'favourite'
    | number;

type SearchFiltersProps = {
    entityType: FilterableEntity;
    onFilterChange: (newEntityType: FilterableEntity) => void;
    onlyCurrent: boolean;
    onCurrentToggle: (onlyCurrent: boolean) => void;
};

const entitiesToFilter: {
    entityTypeId: FilterableEntity;
    displayNamePlural: string;
}[] = [
    {
        entityTypeId: 'all',
        displayNamePlural: 'All',
    },
    ENTITY_TYPES.STUDENT,
    ENTITY_TYPES.GUARDIAN,
    ENTITY_TYPES.STAFF,
    {entityTypeId: 'page', displayNamePlural: 'Pages'},
    {entityTypeId: 'favourite', displayNamePlural: 'Favourites'},
    ENTITY_TYPES.CUSTOM_REPORT,
    {entityTypeId: 'help centre', displayNamePlural: 'Help Centre'},
];

export const SearchFilters = (props: SearchFiltersProps) => {
    const {entityType, onFilterChange, onlyCurrent, onCurrentToggle} = props;

    const [isFocusInFiltersContainer, setIsFocusInFiltersContainer] =
        useState(false);

    const disableCurrentToggle =
        entityType === 'page' ||
        entityType === 'favourite' ||
        entityType === 'help centre' ||
        entityType === ENTITY_TYPES.CUSTOM_REPORT.entityTypeId;

    const updateFilter = (newEntityType: FilterableEntity | 'all') => {
        if (newEntityType === 'all') {
            onFilterChange('all');
        } else {
            if (entityType === newEntityType) {
                // if the same filter is clicked again, we return to all
                onFilterChange('all');
                return;
            }
            onFilterChange(newEntityType);
        }
    };

    let disabledToggleLabelText = 'Current students, guardians, and staff only';
    if (entityType === ENTITY_TYPES.STUDENT.entityTypeId) {
        disabledToggleLabelText = 'Current students only';
    } else if (entityType === ENTITY_TYPES.GUARDIAN.entityTypeId) {
        disabledToggleLabelText = 'Current guardians only';
    } else if (entityType === ENTITY_TYPES.STAFF.entityTypeId) {
        disabledToggleLabelText = 'Current staff only';
    }

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // there seems to be a race condition when setting arbor-hidden-from-focus-utils
        // in JSX, so we set it here instead
        if (isFocusInFiltersContainer) {
            focusFirstFocusableElement(containerRef.current);
            containerRef.current?.setAttribute(
                'arbor-hidden-from-focus-utils',
                'true',
            );
        } else {
            containerRef.current?.removeAttribute(
                'arbor-hidden-from-focus-utils',
            );
        }
    }, [isFocusInFiltersContainer]);

    const containerKeyDownHandler = (event: React.KeyboardEvent) => {
        if (
            !isFocusInFiltersContainer &&
            containerRef.current &&
            (event.keyCode === ENTER_KEY_CODE ||
                event.keyCode === SPACE_KEY_CODE)
        ) {
            event.preventDefault();
            event.stopPropagation();
            setIsFocusInFiltersContainer(true);
        }
    };

    return (
        <div
            className="search-filters__container"
            ref={containerRef}
            tabIndex={0}
            onKeyDown={containerKeyDownHandler}
            onBlur={(event) => {
                if (!containerRef.current?.contains(event.relatedTarget)) {
                    setIsFocusInFiltersContainer(false);
                }
            }}
            aria-label="Open search filters"
        >
            <div className="search-filter__toggle-container">
                {entitiesToFilter.map((entity) => {
                    const isSelected = entityType === entity.entityTypeId;
                    return (
                        <Button
                            key={entity.entityTypeId}
                            text={entity.displayNamePlural}
                            onClick={(event) => {
                                event.preventDefault();
                                updateFilter(entity.entityTypeId);
                            }}
                            className={classNames('search-filters__button', {
                                'search-filters__button--active': isSelected,
                            })}
                            fontSize="14px"
                            makeInvisibleToFocusUtils={
                                !isFocusInFiltersContainer
                            }
                        />
                    );
                })}
            </div>
            <div
                className={classNames(
                    'search-filter__current-toggle-container',
                    {
                        'search-filter__current-toggle-container--disabled':
                            disableCurrentToggle,
                    },
                )}
            >
                <label>
                    <input
                        tabIndex={0}
                        type="checkbox"
                        className="search-filter__current-toggle"
                        checked={onlyCurrent}
                        onChange={(event) =>
                            onCurrentToggle(event.target.checked)
                        }
                        {...((!isFocusInFiltersContainer ||
                            disableCurrentToggle) && {
                            'arbor-hidden-from-focus-utils': 'true',
                        })}
                        disabled={disableCurrentToggle}
                        onKeyDown={(event) => {
                            if (
                                event.keyCode === ENTER_KEY_CODE ||
                                event.keyCode === SPACE_KEY_CODE
                            ) {
                                onCurrentToggle(!onlyCurrent);
                            }
                        }}
                    />
                    {disabledToggleLabelText}
                </label>
            </div>
        </div>
    );
};
