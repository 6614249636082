import PropTypes from 'prop-types';
import {Component} from 'react';

import {FilterTag, filterTagColorsPropType} from 'Components/filters';
import {BACKSPACE_KEY_CODE} from 'Utils/keyCodeConstants';

import './selectedFilters.scss';

export class SelectedFilters extends Component {
    focusNextTag = (e, currentId) => {
        const isMouseEvent = e.detail > 0;

        if (isMouseEvent) {
            return;
        }

        const {filterGroups} = this.props;

        const allElements = [].concat.apply(
            [],
            filterGroups.map((group) => group.filters),
        );

        if (allElements.length === 1) {
            return;
        }

        const currentElementIndex = allElements.findIndex(
            (element) => element.id === currentId,
        );
        if (currentElementIndex === -1) {
            return;
        }

        let elementToFocus;
        if (currentElementIndex >= allElements.length - 1) {
            elementToFocus = allElements[currentElementIndex - 1];
        } else {
            elementToFocus = allElements[currentElementIndex + 1];
        }

        this[elementToFocus.id].focus();
    };

    handleTagClick = (id, onFilterTagClicked) => (e) => {
        this.focusNextTag(e, id);
        onFilterTagClicked(id);
    };

    handleKeydownPress = (id, onFilterTagClicked) => (e) => {
        if (e.keyCode === BACKSPACE_KEY_CODE) {
            this.handleTagClick(id, onFilterTagClicked)(e);
        }
    };

    render() {
        const {filterGroups, isLoading, isInactive} = this.props;

        if (isLoading) {
            return (
                <span className="selected-filters__container">
                    <span className="selected-filters__tag--loading" />
                    <span className="selected-filters__tag--loading" />
                </span>
            );
        }

        // It is important that the container and all children are spans, so that the
        // tags correctly wrap onto several lines
        return (
            <span
                className="selected-filters__container"
                data-testid="selected-filters"
            >
                {filterGroups &&
                    typeof filterGroups.map === 'function' &&
                    filterGroups.map(
                        (filterGroup) =>
                            filterGroup.filters &&
                            typeof filterGroup.filters.map === 'function' &&
                            filterGroup.filters.map((filter) => (
                                <FilterTag
                                    key={filter.id}
                                    color={filterGroup.color}
                                    filter={filter}
                                    onClick={this.handleTagClick(
                                        filter.id,
                                        filterGroup.onFilterTagClicked,
                                    )}
                                    onKeyDown={this.handleKeydownPress(
                                        filter.id,
                                        filterGroup.onFilterTagClicked,
                                    )}
                                    setRef={(element) => {
                                        this[filter.id] = element;
                                    }}
                                    isInactive={isInactive}
                                />
                            )),
                    )}
            </span>
        );
    }
}

SelectedFilters.propTypes = {
    filterGroups: PropTypes.arrayOf({
        color: filterTagColorsPropType,
        filters: PropTypes.arrayOf(
            PropTypes.exact({
                id: PropTypes.string,
                displayName: PropTypes.string,
                selected: PropTypes.bool,
            }),
        ),
        onFilterTagClicked: PropTypes.func,
    }),
    isLoading: PropTypes.bool,
    isInactive: PropTypes.bool,
};
