import {TransformedNavigationItem} from 'Root/core/state/navigation/types';

import {prependZeros} from './prependZeros';

type SubNavItem = {
    fields: {
        text?: {
            value?: string;
        };
        url?: {
            value?: string;
        };
        selected?: {
            value?: boolean;
        };
    };
    expanded?: boolean;
    items?: SubNavItem[];
};

type ValidSubnavItem = {
    fields: {
        text: {
            value: string;
        };
        url?: {
            value?: string;
        };
        selected?: {
            value?: boolean;
        };
    };
    expanded?: boolean;
    items?: SubNavItem[];
};

type TreeData = {
    items: SubNavItem[];
};

// The subnav data from the backend often has an empty last item with no text, url or children
function isValidItem(item: SubNavItem): item is ValidSubnavItem {
    return (
        item.fields &&
        'text' in item.fields &&
        typeof item.fields.text?.value !== 'undefined'
    );
}

const transformTreeItem =
    (parentId = 'subnav') =>
    (item: SubNavItem, index: number) => {
        const {text, url, selected} = item.fields;

        const itemId = `${parentId}_${prependZeros(index)}`;

        const childItems: undefined | false | TransformedNavigationItem[] =
            item.items &&
            item.items.length > 0 &&
            item.items.filter(isValidItem).map(transformTreeItem(itemId));

        const transformedItem: TransformedNavigationItem = {
            id: itemId,
            ...(text && text.value ? {text: text.value} : {}),
            ...(url && url.value ? {url: url.value} : {}),
            ...(selected && selected.value ? {selected: selected.value} : {}),
            ...(childItems
                ? {
                      items: childItems,
                  }
                : {}),
            ...(item.expanded && childItems
                ? {initiallyExpanded: item.expanded}
                : {}),
        };

        return transformedItem;
    };

export const transformOldSubnavData = (treeData: TreeData) => {
    if (!(treeData && treeData.items && treeData.items.length > 0)) {
        return [];
    }

    const transformedTreeData = treeData.items
        .filter(isValidItem)
        .map(transformTreeItem());
    return transformedTreeData;
};
