import {sanitizeSimpleText} from 'Utils/sanitizeHtml';

import './simpleText.scss';

type SimpleTextProps = {
    children?: any; // Need to see if children is ever non-string
    html?: string;
    id?: string;
};

const createMarkup = (dirtyContent: string) => {
    const cleanContent = sanitizeSimpleText(dirtyContent);

    return {__html: cleanContent};
};

export const SimpleText = ({children, html = '', id}: SimpleTextProps) => {
    let htmlContent: {__html: string};

    if (children) {
        htmlContent = createMarkup(children);
    } else {
        htmlContent = createMarkup(html);
    }

    return (
        <div
            className="arbor-simple-text"
            role="paragraph"
            dangerouslySetInnerHTML={htmlContent}
            id={id}
        />
    );
};
