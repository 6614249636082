import axios, {AxiosInstance, Cancel, AxiosRequestConfig} from 'axios';

import interceptBadResponse from 'Services/httpClient/interceptors/InterceptBadResponse';
import interceptResponse from 'Services/httpClient/interceptors/InterceptResponse';
import {reportToSisSentry} from 'Services/raven/Raven';

const CancelToken = axios.CancelToken;
let cancel: Cancel;

class HttpClient {
    constructor() {
        this.$http = axios.create({
            withCredentials: true,
        });

        this.$http.interceptors.response.use(
            interceptResponse,
            interceptBadResponse,
        );
    }

    $http: AxiosInstance;

    _reportIfPathIsInvalid(path: string, type: 'GET' | 'POST') {
        if (typeof path === 'undefined' || path.indexOf('undefined') !== -1) {
            reportToSisSentry(new Error('Requested Undefined Path Error!'), {
                url: path,
                type: type,
            });
        }
    }

    get<T = any, R = T, D = any>(
        path: string,
        params?: any,
        signal?: AbortSignal,
    ) {
        this._reportIfPathIsInvalid(path, 'GET');

        return this.$http.get<T, R, D>(path, {
            cancelToken: new CancelToken(function executor(c) {
                (cancel as any) = c;
                cancel.message = path;
            }),
            params: params,
            signal,
        });
    }

    post<T = any, R = T, D = any>(
        path: string,
        data?: any,
        config: AxiosRequestConfig = {},
    ) {
        this._reportIfPathIsInvalid(path, 'POST');

        return this.$http.post<T, R, D>(path, data, config);
    }
}

let httpClientInstance: HttpClient | null = null;
export const getHttpClientService = () => {
    if (!httpClientInstance) {
        httpClientInstance = new HttpClient();
    }
    return httpClientInstance;
};
