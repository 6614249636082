import {useRef, useState} from 'react';

import {httpPost} from 'Interfaces/httpClient';
import {appendFormatJson} from 'Interfaces/Url';

export const useAutoFillRequest = ({autoFillUrl, autoFillParams}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [autoFillContent, setAutoFillContent] = useState('');
    const signalRef = useRef<AbortController | null>();

    const submitFunction = async (userContent?: string) => {
        setIsLoading(true);
        setError('');
        setAutoFillContent('');

        let controller: undefined | AbortController;
        if (typeof AbortController !== 'undefined') {
            controller = new AbortController();
            signalRef.current = controller;
        }
        try {
            const response = await httpPost(
                appendFormatJson(autoFillUrl),
                {
                    fields: {
                        autoFillParams: {value: autoFillParams},
                        userContent: {value: userContent},
                    },
                },
                {
                    ...(signalRef.current
                        ? {signal: signalRef.current.signal}
                        : {}),
                },
            );
            if (typeof response === 'undefined') {
                // When you cancel a request in the UI, the response will be undefined,
                // and we don't need to show an error message
                return;
            }
            if (
                typeof response.items?.[0]?.fields?.autoFillContent?.value ===
                'string'
            ) {
                setAutoFillContent(
                    response.items[0].fields.autoFillContent.value,
                );
            }
        } catch (e) {
            console.error('Error getting autoFill data', e, {
                autoFillUrl,
                autoFillParams,
                userContent,
            });
            setError('Error generating text');
        } finally {
            setIsLoading(false);
        }
    };
    const cancelFunction = () => {
        signalRef.current?.abort();
    };

    return {submitFunction, cancelFunction, isLoading, error, autoFillContent};
};
