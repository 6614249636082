import {ReactNode} from 'react';

import {ResponsiveContainer} from 'BackendRenderedComponents/responsiveContainer';

import {KpiDisplay} from './kpiDisplay';

import './kpiChartBox.scss';

type KpiChartBoxProps = {
    sizeRatio?: number;
    title?: string;
    children: ReactNode[];
    showBorder?: boolean;
    description?: string;
    kpiUrl?: string;
    allowFullscreen?: boolean;
};

export const KpiChartBox = (props: KpiChartBoxProps) => {
    const {
        title,
        children,
        showBorder = false,
        description,
        kpiUrl,
        allowFullscreen,
        sizeRatio,
    } = props;

    return (
        <ResponsiveContainer
            allowFullscreen={allowFullscreen}
            showBorder={showBorder}
            title={title}
            className="kpi-chart-box__outer-container"
            sizeRatio={`${sizeRatio}`}
        >
            <div className="kpi-chart-box__container">
                {description && (
                    <span className="kpi-chart-box__description">
                        {description}
                    </span>
                )}
                {kpiUrl && <KpiDisplay url={kpiUrl} />}
                {children}
            </div>
        </ResponsiveContainer>
    );
};
