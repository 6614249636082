import PropTypes from 'prop-types';
import {Children} from 'react';

import CancelButton from 'Components/button/cancelButton/CancelButton';
import {DeleteButton} from 'Components/button/deleteButton';
import {FormActionButton} from 'Components/button/formActionButton';
import Container from 'Components/layout/container/Container';

import './actionPanel.scss';

function ActionPanel(props) {
    const rearrangeItems = () => {
        let cancelButtons = [],
            deleteButtons = [],
            formActionButtons = [],
            formHidden = [];

        Children.forEach(props.children, function (button) {
            const buttonType = button.xtype || button.type;

            if (button.key && button.key.startsWith('Arbor.ExtNative.Hidden')) {
                // Note: the key gets set by the setComponentKey function in renderUIContent,
                // so we can be confident in using it
                formHidden.push(button);
            } else {
                switch (buttonType) {
                    case 'mis-button-cancel':
                    case CancelButton:
                        cancelButtons.push(button);
                        break;
                    case 'mis-button-delete':
                    case DeleteButton:
                        deleteButtons.push(button);
                        break;
                    case 'mis-button-form-action':
                    case FormActionButton:
                        formActionButtons.push(button);
                        break;
                    default:
                        formActionButtons.push(button);
                        break;
                }
            }
        });

        return [
            ...deleteButtons,
            ...cancelButtons,
            ...formActionButtons,
            ...formHidden,
        ];
    };

    return (
        <Container className="arbor-action-panel" align="center">
            {rearrangeItems()}
        </Container>
    );
}

ActionPanel.propTypes = {
    children: PropTypes.node,
};

export default ActionPanel;
