import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {toggleFilterById} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectDemographicFilter} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {FILTER_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {getSelectedCount} from 'BackendRenderedComponents/dashboard/utils';
import {DropdownContainer} from 'Components/dropdown';
import {
    MultiSelectDropdownContents,
    MultiSelectDropdownField,
} from 'Components/dropdown/multiSelectDropdown';

import './demographicFilterDropdown.scss';

const MAX_NUMBER_OF_SELECTED_DEMOGRAPHICS = 2;

const UnconnectedDemographicFilterDropdown = ({actions, demographics}) => {
    const demographicChanged = ({id}) => {
        actions.toggleFilterById({
            filterType: FILTER_TYPE_ENUM.DEMOGRAPHIC,
            id,
        });
    };
    const selectedCount = getSelectedCount(demographics);

    let description;
    if (selectedCount === 0) {
        description = 'Select demographics';
    } else if (selectedCount === demographics.length) {
        description = 'Demographics: All';
    } else if (selectedCount === MAX_NUMBER_OF_SELECTED_DEMOGRAPHICS) {
        description = `Demographics: Max ${selectedCount} selected`;
    } else {
        description = `Demographics: ${selectedCount} selected`;
    }
    return (
        <div className="demographic-filter-dropdown-container">
            <DropdownContainer
                renderDropdownField={(dropdownProps) => (
                    <MultiSelectDropdownField
                        {...dropdownProps}
                        description={description}
                    />
                )}
                renderDropdownContents={(dropdownProps) => (
                    <MultiSelectDropdownContents
                        {...dropdownProps}
                        items={demographics}
                        onChange={demographicChanged}
                        maxSelected={MAX_NUMBER_OF_SELECTED_DEMOGRAPHICS}
                    />
                )}
            />
        </div>
    );
};

UnconnectedDemographicFilterDropdown.propTypes = {
    demographics: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        toggleFilterById: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    demographics: selectDemographicFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            toggleFilterById,
        },
        dispatch,
    ),
});

export const DemographicFilterDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedDemographicFilterDropdown);
