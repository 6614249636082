import {TAB_IDS} from './types';

export const selectDashboard = (state) => state.dashboard;

export const selectKpiFilters = (state) => selectDashboard(state).kpi.filters;
export const selectKpiData = (state, tabId) =>
    selectDashboard(state).kpi.kpiData[tabId];

export const selectKpiDataLoading = (state, tabId) =>
    selectKpiData(state, tabId).isLoading;
export const selectWholeSchoolLoading = (state) =>
    selectDashboard(state).kpi.wholeSchool.isLoading;

export const selectDemographicFilter = (state) =>
    selectKpiFilters(state).demographics;

export const selectKpiGroupFilter = (state) =>
    selectKpiFilters(state).kpiGroups;

export const selectAttainmentSelected = (state) => {
    const kpiGroups = selectKpiGroupFilter(state);
    const attainment =
        kpiGroups && kpiGroups.find((group) => group.id === 'attainment');
    return attainment && attainment.selected;
};

export const selectAttendanceSelected = (state) => {
    const kpiGroups = selectKpiGroupFilter(state);
    const attendance =
        kpiGroups && kpiGroups.find((group) => group.id === 'attendance');
    return attendance && attendance.selected;
};

export const selectBehaviourSelected = (state) => {
    const kpiGroups = selectKpiGroupFilter(state);
    const behaviour =
        kpiGroups && kpiGroups.find((group) => group.id === 'behaviour');
    return behaviour && behaviour.selected;
};

export const selectStudentGroupFilter = (state) =>
    selectKpiFilters(state).studentGroups;

export const selectTabFilter = (state) => {
    const tabs = selectKpiFilters(state).tabs;
    // Make sure only valid tabs are returned
    return tabs.filter(
        (tab) =>
            tab.id === TAB_IDS.MY_STUDENTS || tab.id === TAB_IDS.WHOLE_SCHOOL,
    );
};

export const selectIsMyStudentsTabSelected = (state) => {
    const tabFilters = selectTabFilter(state);
    return (
        tabFilters.findIndex(
            (tab) => tab.id === TAB_IDS.MY_STUDENTS && tab.selected,
        ) > -1
    );
};
export const selectIsWholeSchoolTabSelected = (state) => {
    const tabFilters = selectTabFilter(state);
    return (
        tabFilters.findIndex(
            (tab) => tab.id === TAB_IDS.WHOLE_SCHOOL && tab.selected,
        ) > -1
    );
};

export const selectSelectedDemographicFilter = (state) =>
    selectKpiFilters(state).demographics.filter((filter) => filter.selected);

export const selectSelectedStudentGroupFilter = (state) =>
    selectKpiFilters(state).studentGroups.filter((filter) => filter.selected);

export const selectNumberOfSelectedFilters = (state) => {
    const demographicFiltersSelected = selectSelectedDemographicFilter(state);
    const studentGroupFiltersSelected = selectSelectedStudentGroupFilter(state);
    return (
        (demographicFiltersSelected && demographicFiltersSelected.length) +
        (studentGroupFiltersSelected && studentGroupFiltersSelected.length)
    );
};

export const selectNotifications = (state) =>
    selectDashboard(state).notifications;

export const selectStudentAlerts = (state) =>
    selectNotifications(state).studentAlerts;
export const selectToDos = (state) => selectNotifications(state).toDos;
export const selectSchoolNotices = (state) =>
    selectNotifications(state).schoolNotices;

// TODO [MIS-37258]: Should investigate a clerner and more performant way of writing these selectors
export const selectNumberOfStudents = (state, tabId) =>
    selectKpiData(state, tabId).numberOfStudents;
export const selectKpiGroups = (state, tabId) =>
    selectKpiData(state, tabId).kpiGroups;

export const selectAttendance = (state, tabId) =>
    selectKpiGroups(state, tabId) &&
    selectKpiGroups(state, tabId).find((group) => group.id === 'attendance');
export const selectBehaviour = (state, tabId) =>
    selectKpiGroups(state, tabId) &&
    selectKpiGroups(state, tabId).find((group) => group.id === 'behaviour');
export const selectAttainment = (state, tabId) =>
    selectKpiGroups(state, tabId) &&
    selectKpiGroups(state, tabId).find((group) => group.id === 'attainment');

export const selectAttendanceKpis = (state, tabId) =>
    selectAttendance(state, tabId) && selectAttendance(state, tabId).kpis;
export const selectBehaviourKpis = (state, tabId) =>
    selectBehaviour(state, tabId) && selectBehaviour(state, tabId).kpis;
export const selectAttainmentKpis = (state, tabId) =>
    selectAttainment(state, tabId) && selectAttainment(state, tabId).kpis;
