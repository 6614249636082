export const getHrefFromUrl = (url?: string) => {
    // We need to pass a non-undefined url for the href of <a> tags else the link
    // will not be focusable.
    if (!url) {
        return '';
    }

    // Relative urls are passed from the backend in the format /controller/action
    // If we want to be able to open these in a new tab/window we need to add `/?`
    // to the start, else the backend returns a json object instead of a whole page.
    return url.startsWith('/') ? `/?${url}` : url;
};
