import {useState, useRef} from 'react';

import {usePubSub} from 'Root/core/services/pubsub';
import {
    getFloatingElementPosition,
    IFloatingElementPosition,
} from 'Root/core/utils/getFloatingElementPosition';

import './simpleTooltipManager.scss';

export const SimpleTooltipManager = () => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [tooltipPosition, setTooltipPosition] =
        useState<IFloatingElementPosition | null>(null);

    const [tooltipText, setTooltipText] = useState<string | null>(null);
    const [title, setTitle] = useState<string | null>(null);

    usePubSub('showSimpleTooltip', (data) => {
        if (typeof data.title === 'string') {
            setTitle(data.title);
        }
        if (typeof data.tooltip === 'string') {
            setTooltipText(data.tooltip);
        }
        if (data.elementRef) {
            setTimeout(() => {
                setTooltipPosition(
                    getFloatingElementPosition(
                        data.elementRef.current,
                        tooltipRef.current,
                        {
                            preferPosition: data.preferPosition,
                        },
                    ),
                );
            }, 0);
        }
    });

    usePubSub('hideSimpleTooltip', () => {
        setTitle(null);
        setTooltipText(null);
        setTooltipPosition(null);
    });

    const isThereContent = title !== null || tooltipText !== null;
    return (
        <div
            ref={tooltipRef}
            className="simple-tooltip"
            style={
                tooltipPosition && isThereContent
                    ? {top: tooltipPosition.y, left: tooltipPosition.x}
                    : {visibility: 'hidden'}
            }
        >
            {title && <div className="simple-tooltip--title">{title}</div>}
            {tooltipText && (
                <div className="simple-tooltip--text">{tooltipText}</div>
            )}
        </div>
    );
};
