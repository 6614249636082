import {createElement, ReactNode} from 'react';
import {createRoot} from 'react-dom/client';

import {getComponentContructor, isExtComponent} from 'Renderer/componentCache';
import {renderWithStore} from 'Renderer/Store';

import {findMatchingRequiredValidator} from './findMatchingRequiredValidator';
import {getIsComboboxAlphaModeEnabled} from './getIsComboboxAlphaModeEnabled';
import {BackendComponentData, RendererParams} from './Renderer';

export const setComponentKey = (
    component: {componentName: string; props?: object},
    index: number,
) => {
    // A helper function to be used when mapping over arrays and calling renderUIContent
    const componentKey = `${component.componentName}_${index}`;
    component.props = component.props
        ? {key: componentKey, ...component.props}
        : {key: componentKey};
};

export const renderReactComponentToExtConfig = (reactComponent: ReactNode) => {
    // render a react component to a div using ReactDOM and place inside a Ext component config.
    // The result of this function can be passed into Ext.create() or added as a child "item" of an
    // Ext component
    // This function takes care of unmounting the react component when the Ext component is destroyed.
    const target = document.createElement('div');
    target.setAttribute('role', 'presentation');
    const root = createRoot(target);
    root.render(renderWithStore(reactComponent));
    return {
        xtype: 'component',
        contentEl: target,
        ariaRole: 'presentation',
        listeners: {
            destroy: function () {
                setTimeout(() => {
                    // Avoid console warnings about unmounting during rendering
                    // https://stackoverflow.com/a/74445760
                    root.unmount();
                }, 0);
            },
        },
    };
};

export const renderReactOrExtComponentToExtConfig = (child) => {
    if (!child || Object.keys(child).length === 0) {
        return null;
    }

    if (child.xtype) {
        // we have an ext component

        // Don't pass the items prop unless it is actually defined, else you will overwrite
        // any components that define items in their config
        const {items, ...otherProps} = child;
        if (items && items.length > 0) {
            return child;
        }
        return otherProps;
    }
    return renderReactComponentToExtConfig(child);
};

// Takes a child component or array of children and renders to Ext config object.
// The result of this function can be passed as the "items" attribute of an Ext component.
// Handles any combination of react of Ext children.
export const renderChildrenToExtConfig = (children) => {
    if (!children) {
        return null;
    }

    if (typeof children?.map === 'function') {
        return children.map((child) =>
            renderReactOrExtComponentToExtConfig(child),
        );
    }
    return [renderReactOrExtComponentToExtConfig(children)];
};

/**
 * Method that takes the json response data and creates React element out of the received configuration.
 * React element is returned and then rendered to the page using ReactDOM.
 */
export function renderUIContent(
    data: BackendComponentData,
    rendererParams: RendererParams,
) {
    // Note: this will currently render reactified Ext components if they are part of the tree.
    if (!data.props) {
        data.props = {};
    }

    if (rendererParams) {
        data.props.rendererParams = rendererParams;
    }

    if (
        rendererParams?.parentFormActions &&
        typeof data?.props?.actionMappings !== 'undefined' &&
        !data?.props?.required &&
        data.componentName !== 'Arbor.table.ReactTable'
    ) {
        // If we have a form field with action mappings, we need to check
        // parentFormActions for matching "required" validation
        const parentFormActions = rendererParams.parentFormActions;
        const actionMappings: Record<string, string> =
            data.props.actionMappings;
        if (findMatchingRequiredValidator(actionMappings, parentFormActions)) {
            data.props.required = true;
        }
    }

    if (data.content && typeof data.content === 'string') {
        // This is used in the SimpleText component to render its text content
        data.props.html = data.content;
        delete data.content;
    }

    const childContent =
        data.content && typeof data.content !== 'string'
            ? data.content.map((cont, index) => {
                  setComponentKey(cont, index);
                  return renderUIContent(cont, rendererParams);
              })
            : data.content;

    const renderedElement = createElement(
        getComponentContructor(data.componentName),
        data.props,
        childContent,
    ) as ReactNode;
    return renderedElement;
}

/**
 * Method that takes the json response data and creates a Ext configuration object.
 * This configuration object is returned and then used to create Ext objects.
 *
 * @method
 * @public
 * @since v2.2.0
 * @param {Object} data - Data (JSON response) which should be converted to Ext configuration object.
 * @returns {Object} componentObject - Configuration object for Ext component
 *
 * @memberof Renderer
 */
export function renderExtContent(
    data: BackendComponentData,
    rendererParams: RendererParams,
) {
    let dockedItems = [];

    let childRendererParams = rendererParams;
    if (
        data.componentName === 'Arbor.filter.FilterPanel' &&
        typeof data.props?.id !== 'undefined'
    ) {
        // Passing parentFilterPanelId to all descendents so it can be used in
        // form field registering
        // When Filter Panel is refactored to React, this should be moved to renderUiContent
        childRendererParams = {
            ...rendererParams,
            parentFilterPanelId: data.props?.id,
        };
    }
    if (
        rendererParams?.parentFormActions &&
        typeof data?.props?.actionMappings !== 'undefined' &&
        !data?.props?.required
    ) {
        // If we have a form field with action mappings, we need to check
        // parentFormActions for matching "required" validation
        const parentFormActions = rendererParams.parentFormActions;
        const actionMappings: Record<string, string> =
            data.props.actionMappings;
        if (findMatchingRequiredValidator(actionMappings, parentFormActions)) {
            data.props.required = true;
        }
    }

    if (data.props) {
        if (data.props.dockedItems && data.props.dockedItems.length > 0) {
            dockedItems = data.props.dockedItems.map((item) => {
                return renderExtContent(item, childRendererParams);
            });
        }

        for (const property in data.props) {
            if (data.props.hasOwnProperty(property)) {
                if (
                    typeof data.props[property] === 'object' &&
                    data.props[property].componentName
                ) {
                    data.props[property] = renderExtContent(
                        data.props[property],
                        childRendererParams,
                    );
                }
            }
        }
    }

    const componentObject = {
        xtype: data.xtype,
        ...data.props,
        dockedItems,
    };

    if (rendererParams) {
        componentObject.rendererParams = rendererParams;
    }

    if (data.type === 'component') {
        if (
            data.content &&
            typeof data.content !== 'string' &&
            data.content.length > 0
        ) {
            componentObject.items = data.content.map((item) => {
                if (
                    item.componentName === 'Arbor.formfield.TagField' &&
                    // FormFieldContainer has a behaviour where it copies child fields,
                    // and this doesn't work with React comboboxes
                    data.componentName !== 'Arbor.container.FormFieldContainer'
                ) {
                    if (getIsComboboxAlphaModeEnabled()) {
                        item.componentName = 'Arbor.formfield.ReactCombobox';
                    }
                }

                if (isExtComponent(item.componentName)) {
                    return renderExtContent(item, childRendererParams);
                }
                const reactComponent = renderUIContent(
                    item,
                    childRendererParams,
                );
                return renderReactComponentToExtConfig(reactComponent);
            });
        }
    } else {
        componentObject.html = data.content;
    }

    return componentObject;
}
