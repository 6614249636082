/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 6/5/18
 * Time: 17:05
 */

import _getCurrencySymbol from 'Services/currency/Currency';

import exportInterface from '../Base';

export default function getCurrencySymbol(currencySymbol) {
    return _getCurrencySymbol(currencySymbol);
}

export const registerCurrencyInterface = () => {
    exportInterface('Currency', {getCurrencySymbol});
};
