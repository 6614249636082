import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {toggleFilterById} from 'BackendRenderedComponents/dashboard/redux/actions';
import {
    selectSelectedDemographicFilter,
    selectSelectedStudentGroupFilter,
} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {FILTER_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {FILTER_TAG_COLOR_ENUM, SelectedFilters} from 'Components/filters';

const UnconnectedSelectedDashboardFilters = ({
    demographics,
    studentGroups,
    actions,
}) => (
    <SelectedFilters
        filterGroups={[
            {
                color: FILTER_TAG_COLOR_ENUM.BLUE,
                filters: studentGroups,
                onFilterTagClicked: (id) =>
                    actions.toggleFilterById({
                        id,
                        filterType: FILTER_TYPE_ENUM.STUDENT_GROUP,
                    }),
            },
            {
                color: FILTER_TAG_COLOR_ENUM.PINK,
                filters: demographics,
                onFilterTagClicked: (id) =>
                    actions.toggleFilterById({
                        id,
                        filterType: FILTER_TYPE_ENUM.DEMOGRAPHIC,
                    }),
            },
        ]}
    />
);

UnconnectedSelectedDashboardFilters.propTypes = {
    demographics: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    studentGroups: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        toggleFilterById: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    studentGroups: selectSelectedStudentGroupFilter(state),
    demographics: selectSelectedDemographicFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            toggleFilterById,
        },
        dispatch,
    ),
});

export const SelectedDashboardFilters = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedSelectedDashboardFilters);
