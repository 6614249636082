import {WINDOW_MANAGER} from 'Config/Events';
import {WindowData} from 'Renderer/window/types';
import pubsub from 'Services/pubsub/Pubsub';
import helper from 'Utils/helper';

import exportInterface from './Base';

export const addRandomIdIfNecessary = <T extends {id?: string}>(
    objectData: T,
) => ({
    ...objectData,
    id: objectData.id ?? helper.getUid(),
});

export const addWindow = (windowData: WindowData) => {
    pubsub.publish(WINDOW_MANAGER.ADD, addRandomIdIfNecessary(windowData));
};
export const removeWindow = () => {
    pubsub.publish(WINDOW_MANAGER.REMOVE);
};
export const removeAllWindows = () => {
    pubsub.publish(WINDOW_MANAGER.REMOVE_ALL);
};
export const showWindowOverlay = () => {
    pubsub.publish(WINDOW_MANAGER.SHOW);
};
export const hideWindowOverlay = () => {
    pubsub.publish(WINDOW_MANAGER.HIDE);
};
export const removeAllAndAddWindow = (windowData: WindowData) => {
    pubsub.publish(
        WINDOW_MANAGER.REMOVE_ALL_AND_ADD,
        addRandomIdIfNecessary(windowData),
    );
};

export const registerWindowManagerInterface = () => {
    exportInterface('WindowManager', {
        showWindowOverlay,
        hideWindowOverlay,
        removeWindow,
        removeAllWindows,
        removeAllAndAddWindow,
    });
};
