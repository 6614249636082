// This function is to initialise an object the same shape as a template object.
// It will not work for nested objects.
export const initialiseFlatObjectFromTemplate = (templateObject, value) => {
    return Object.keys(templateObject).reduce(
        (result, key) => ({...result, [key]: value}),
        {},
    );
};

export const sanitizeString = (value) => {
    if (value === null || typeof value === 'undefined') {
        return '';
    }
    return value;
};

export const validateField = (fieldName, value, validationSchema) => {
    if (!validationSchema || !validationSchema[fieldName]) {
        return {isFieldValid: true, errorMessage: ''};
    }

    const {regex, msg} = validationSchema[fieldName];

    if (!(regex && typeof regex.test === 'function')) {
        console.warn(
            `Incorrect validation schema for field "${fieldName}": `,
            regex,
        );
        return {isFieldValid: true, errorMessage: ''};
    }

    const validationErrorMessage = msg || `${fieldName} is not valid`;

    const isFieldValid = regex.test(sanitizeString(value));

    const errorMessage = isFieldValid ? '' : validationErrorMessage;

    return {isFieldValid, errorMessage};
};
