import {useState, useEffect, useRef} from 'react';

const defaultCountdownLength = 5;

interface ICountDownTimer {
    timeLeft: number;
    stopTimer: () => void;
}

interface ICountDownTimerProps {
    initialTimeLeft?: number;
    callBackOnZero?: () => void;
}

export const useCountDownTimer = ({
    initialTimeLeft,
    callBackOnZero,
}: ICountDownTimerProps): ICountDownTimer => {
    const [timeLeft, setTimeLeft] = useState(
        initialTimeLeft || defaultCountdownLength,
    );
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const stopTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimeLeft((time) => time - 1);
        }, 1000);
        return () => {
            stopTimer();
        };
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) {
            stopTimer();
            if (typeof callBackOnZero === 'function') {
                callBackOnZero();
            }
        }
        // we don't want this effect to be triggered by a new callback being passed in
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    return {timeLeft, stopTimer};
};
