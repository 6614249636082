import {RendererData} from './Renderer';

/**
 * Method that takes the json response data and creates the modals out of the modals array.
 * Used for modals rendering.
 */
export function renderModels(pageData: RendererData) {
    if (pageData.models) {
        return pageData.models.map((value) => {
            const extModel = {
                id: value.id,
                extend: value.extend,
                fields: value.fields,
                ...(value.idProperty ? {idProperty: value.idProperty} : {}),
            };
            Ext.define(value.id, extModel);
            return value.id;
        });
    }
    return null;
}
