export const ArborHQIcon = () => (
    <svg
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_6659_3131)">
            <path
                d="M17.0198 7.83059C17.6221 7.15231 18.0155 6.3144 18.1528 5.41773C18.29 4.52106 18.1651 3.60383 17.7932 2.77647C17.4213 1.94911 16.8182 1.24687 16.0564 0.75428C15.2947 0.261694 14.4069 -0.000242447 13.4998 1.68387e-07C13.2502 1.68387e-07 13.0108 0.0991595 12.8343 0.275664C12.6578 0.452169 12.5586 0.691561 12.5586 0.941177C12.5586 1.19079 12.6578 1.43018 12.8343 1.60669C13.0108 1.78319 13.2502 1.88235 13.4998 1.88235C14.2486 1.88235 14.9668 2.17983 15.4963 2.70935C16.0258 3.23886 16.3233 3.95704 16.3233 4.70588C16.322 5.20023 16.1909 5.68556 15.9431 6.11334C15.6954 6.54112 15.3397 6.89636 14.9115 7.14353C14.772 7.22402 14.6554 7.33898 14.5731 7.4774C14.4907 7.61583 14.4452 7.77308 14.4409 7.93412C14.437 8.0939 14.4738 8.25204 14.5479 8.39365C14.622 8.53527 14.731 8.65567 14.8645 8.74353L15.2315 8.98824L15.3539 9.05412C16.4884 9.5922 17.4455 10.4433 18.1125 11.5071C18.7794 12.571 19.1285 13.8032 19.1186 15.0588C19.1186 15.3084 19.2178 15.5478 19.3943 15.7243C19.5708 15.9008 19.8102 16 20.0598 16C20.3094 16 20.5488 15.9008 20.7253 15.7243C20.9018 15.5478 21.0009 15.3084 21.0009 15.0588C21.0086 13.6145 20.6469 12.1922 19.9501 10.9271C19.2533 9.662 18.2446 8.59606 17.0198 7.83059Z"
                fill="#776E6A"
            />
            <path
                d="M10.6343 8.20706C11.1365 7.77236 11.5393 7.23472 11.8154 6.63061C12.0914 6.0265 12.2343 5.37008 12.2343 4.70588C12.2343 3.4578 11.7385 2.26085 10.856 1.37832C9.97347 0.495797 8.77651 0 7.52844 0C6.28036 0 5.0834 0.495797 4.20087 1.37832C3.31835 2.26085 2.82255 3.4578 2.82255 4.70588C2.82255 5.37008 2.96543 6.0265 3.24149 6.63061C3.51756 7.23472 3.92036 7.77236 4.42255 8.20706C3.10504 8.80366 1.98723 9.76709 1.20277 10.9822C0.418313 12.1972 0.000408713 13.6125 -0.000976562 15.0588C-0.000976563 15.3084 0.0981828 15.5478 0.274688 15.7243C0.451192 15.9008 0.690584 16 0.9402 16C1.18982 16 1.42921 15.9008 1.60571 15.7243C1.78222 15.5478 1.88138 15.3084 1.88138 15.0588C1.88138 13.5611 2.47633 12.1248 3.53536 11.0658C4.59439 10.0067 6.03074 9.41177 7.52844 9.41177C9.02613 9.41177 10.4625 10.0067 11.5215 11.0658C12.5805 12.1248 13.1755 13.5611 13.1755 15.0588C13.1755 15.3084 13.2747 15.5478 13.4512 15.7243C13.6277 15.9008 13.8671 16 14.1167 16C14.3663 16 14.6057 15.9008 14.7822 15.7243C14.9587 15.5478 15.0578 15.3084 15.0578 15.0588C15.0565 13.6125 14.6386 12.1972 13.8541 10.9822C13.0696 9.76709 11.9518 8.80366 10.6343 8.20706ZM7.52844 7.52941C6.96999 7.52941 6.42409 7.36381 5.95977 7.05356C5.49544 6.74331 5.13354 6.30233 4.91983 5.7864C4.70613 5.27047 4.65021 4.70275 4.75916 4.15504C4.86811 3.60733 5.13702 3.10422 5.5319 2.70935C5.92678 2.31447 6.42988 2.04555 6.97759 1.93661C7.5253 1.82766 8.09302 1.88358 8.60895 2.09728C9.12489 2.31099 9.56586 2.67289 9.87611 3.13721C10.1864 3.60154 10.352 4.14744 10.352 4.70588C10.352 5.45473 10.0545 6.17291 9.52497 6.70242C8.99546 7.23193 8.27728 7.52941 7.52844 7.52941Z"
                fill="#776E6A"
            />
        </g>
        <defs>
            <clipPath id="clip0_6659_3131">
                <rect width="21" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
