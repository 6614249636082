export type SectionRegistryEntry = {
    show: () => void;
    hide: () => void;
    title: string;
    container: Element | null;
    id: number;
};

class SectionRegistry {
    private sections: Record<number, SectionRegistryEntry & {id: number}> = {};
    private nextId = 0;

    registerSection = (section: Omit<SectionRegistryEntry, 'id'>) => {
        this.sections[this.nextId] = {...section, id: this.nextId};
        return this.nextId++;
    };

    deregisterSection = (id: number) => {
        delete this.sections[id];
    };

    getSectionsInColumn = (column: Element): SectionRegistryEntry[] => {
        return Object.keys(this.sections)
            .filter((key) => {
                return column.contains(this.sections[key].container);
            })
            .map((key) => {
                return this.sections[key];
            });
    };
}

let sectionRegistryInstance: SectionRegistry | null = null;
export const getSectionRegistryService = () => {
    if (!sectionRegistryInstance) {
        sectionRegistryInstance = new SectionRegistry();
    }

    return sectionRegistryInstance;
};
