import {splitSisUrlIntoSections} from '../splitSisUrlIntoSections';

// The SIS backend allows a format for url parameters by appending
// key value pairs to the url in the format /key/value
// The core part of the url has format [/module]?/controller/action
export const removeSisUrlParameters = (inputUrl: string) => {
    if (!inputUrl || typeof inputUrl.split !== 'function') {
        console.error(
            'invalid inputUrl supplied to removeSisUrlParameters',
            inputUrl,
        );
        return '';
    }
    const {baseUrl} = splitSisUrlIntoSections(inputUrl);
    return baseUrl;
};
