import {Button} from 'Components/button/base';
import {loadPage} from 'Interfaces/Loader';

import {SnippetData} from '../types';

type EditSnippetButtonProps = {
    snippet?: SnippetData;
    context: {objectTypeId: number; objectId: number};
};

export const EditSnippetButton = (props: EditSnippetButtonProps) => {
    const {snippet, context} = props;

    const disabled = typeof snippet === 'undefined';

    let pageUrl = '';
    if (snippet) {
        pageUrl = `/snippet-ui/edit-snippet/id/${snippet.id}/context/${context.objectTypeId},${context.objectId}`;
    }

    return (
        <Button
            text="Edit Snippet"
            color="orange"
            disabled={disabled}
            onClick={() => loadPage(pageUrl)}
        />
    );
};
