import {useState} from 'react';

import {Button} from 'Components/button/base';

import {
    CreateSnippetButton,
    DeleteSnippetButton,
    EditSnippetButton,
} from './snippetManagementButtons';
import {SnippetTable} from './snippetTable';
import {SnippetData} from './types';

import {useSnippetData} from '.';

import './snippetModal.scss';

type SnippetModalProps = {
    context: {
        objectId: number;
        objectTypeId: number;
    };
    replacement: {
        objectId: number;
        objectTypeId: number;
    };
    closeCallback: () => void;
    insertCallback: (content: string) => void;
};

export const SnippetModal = (props: SnippetModalProps) => {
    const {context, replacement, closeCallback, insertCallback} = props;

    const {snippetList, fetchSnippetList, isLoading} = useSnippetData(props);

    const [selectedSnippet, setSelectedSnippet] = useState<
        SnippetData | undefined
    >();

    const [globalSnippetsHidden, setGlobalSnippetsHidden] = useState(false);

    return (
        <div className="snippet-modal__container">
            <Button
                text={
                    globalSnippetsHidden
                        ? 'Show Global Snippets'
                        : 'Hide Global Snippets'
                }
                onClick={() => {
                    setGlobalSnippetsHidden(!globalSnippetsHidden);
                }}
            />
            <SnippetTable
                onSelectionChanged={(newSnippet) =>
                    setSelectedSnippet(newSnippet)
                }
                {...{context, replacement}}
                snippetList={snippetList}
                hideGlobalSnippets={globalSnippetsHidden}
                isLoading={isLoading}
            />
            <div className="snippet-modal__snippet-management-button-container">
                <CreateSnippetButton context={context} />
                <EditSnippetButton
                    context={context}
                    snippet={selectedSnippet}
                />
                <DeleteSnippetButton
                    snippet={selectedSnippet}
                    onDelete={fetchSnippetList}
                />
            </div>
            <div className="snippet-modal__footer">
                <Button text="Cancel" onClick={closeCallback} />
                <Button
                    text="Insert Snippet"
                    color="green"
                    disabled={typeof selectedSnippet === 'undefined'}
                    onClick={() => {
                        insertCallback(selectedSnippet?.snippetValue || '');
                        closeCallback();
                    }}
                />
            </div>
        </div>
    );
};
