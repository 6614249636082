/* eslint-disable camelcase */

import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import {FormEventHandler, useState} from 'react';

import {Button} from 'Components/button/base';
import {httpPost} from 'Interfaces/httpClient';
import {loadCurrentPage} from 'Interfaces/Loader';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {parseCurrencyString} from 'Utils/currency';

import {style, stripeStyle} from './style';

import type {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

type SisServerResponse = null | {
    success?: boolean;
    items?: {
        fields: {
            error_message: {value: string};
            payment_intent_status: {value: string};
            client_secret: {value: string};
        };
    }[];
    action_params?: {
        validation_errors?: Record<string, string[]>;
    };
};

const _addValueToFields = (fields: object) => {
    const valueFields = {};
    Object.keys(fields).forEach((key) => {
        if (typeof fields[key] === 'object' && fields[key] !== null) {
            // Already object
            valueFields[key] = fields[key];
        } else if (
            typeof fields[key] === 'string' &&
            fields[key].indexOf('"value"') !== -1
        ) {
            // Already JSON encoded
            valueFields[key] = {value: JSON.parse(fields[key])};
        } else {
            // Flat data
            valueFields[key] = {value: fields[key]};
        }
    });

    return valueFields;
};

const _getDecimalAmount = (amount: string | number | null, currency = '£') => {
    if (typeof amount === 'string') {
        return currency + parseCurrencyString(amount);
    }
    if (typeof amount === 'number') {
        return currency + amount.toFixed(2);
    }
    return amount;
};

type CheckoutFormProps = {
    amount: string | number;
    banAmex?: boolean;
    currency?: string;
    fields: Record<string, unknown>;
    isSmsStripeAccount?: boolean;
    onModalClose: () => void;
    onSuccess: () => void;
    scaAuthUrl?: string;
    scaRejectedAuthUrl?: string;
    url: string;
};

const CheckoutForm = ({
    amount,
    banAmex,
    currency = '£',
    isSmsStripeAccount = false,
    fields = {},
    onModalClose,
    onSuccess,
    scaRejectedAuthUrl,
    scaAuthUrl,
    url,
}: CheckoutFormProps) => {
    const elements = useElements();
    const stripe = useStripe();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [cardNumberError, setCardNumberError] = useState<{
        code: string;
        message: string;
    } | null>(null);
    const [cvcError, setCvcError] = useState<{
        code: string;
        message: string;
    } | null>(null);
    const [cardExpiryError, setCardExpiryError] = useState<{
        code: string;
        message: string;
    } | null>(null);
    const [globalError, setGlobalError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isItemSoldOut, setIsItemSoldOut] = useState(false);

    const onChangeCardNumber = (event: StripeCardNumberElementChangeEvent) => {
        if (banAmex && event.brand === 'amex') {
            event.error = {
                code: 'card_not_supported',
                message:
                    'Your card is not supported. Please use Visa or MasterCard card instead.',
                type: 'validation_error',
            };
            event.complete = false;
        }
        if (event.error) {
            setGlobalError(null);
            setCardNumberError(event.error);
        } else {
            setGlobalError(null);
            setCardNumberError(null);
        }
    };

    const onChangeCVC = (event: StripeCardCvcElementChangeEvent) => {
        if (event.error) {
            setGlobalError(null);
            setCvcError(event.error);
        } else {
            setGlobalError(null);
            setCvcError(null);
        }
    };

    const onChangeExpiryDate = (event: StripeCardExpiryElementChangeEvent) => {
        if (event.error) {
            setGlobalError(null);
            setCardExpiryError(event.error);
        } else {
            setGlobalError(null);
            setCardExpiryError(null);
        }
    };

    const showFormError = (errorMessage: string) => {
        unmaskMain();
        if (
            errorMessage ===
            'Sorry, the item you are trying to buy has now sold out. Please contact your school if you require further information.'
        ) {
            setIsItemSoldOut(true);
        }
        setGlobalError(errorMessage);
        setIsSubmitting(false);
    };

    const showFormErrorWithLog = (
        serverResponse: SisServerResponse,
        errorMessage?: string | null,
    ) => {
        showFormError(errorMessage ?? 'There was an error, please try again!');
        console.error(
            'Error with payment related XHR response!',
            errorMessage,
            serverResponse,
        );
        reportToSisSentry(
            new Error('Error with payment related XHR response!'),
            {
                response: serverResponse,
            },
        );
    };

    const handleServerResponse = async (serverResponse: SisServerResponse) => {
        unmaskMain();

        // Handle no response
        if (!serverResponse) {
            showFormErrorWithLog(serverResponse);
            return;
        }

        // Handle no success message or success false
        if (!serverResponse.success) {
            // For some reason server is sending some errors this way
            if (serverResponse.items?.[0]?.fields?.error_message?.value) {
                showFormErrorWithLog(
                    serverResponse,
                    serverResponse.items[0].fields.error_message.value,
                );
                return;
            }
            // Handle validation errors sent from server
            const validationErrors =
                serverResponse?.action_params?.validation_errors;
            if (validationErrors) {
                let errorMessages: string | null = '';
                const keys = Object.keys(validationErrors);
                keys.forEach((key) => {
                    errorMessages += validationErrors[key].join('\n');
                });
                if (errorMessages === '') {
                    errorMessages = null;
                }
                showFormErrorWithLog(serverResponse, errorMessages);
                return;
            }

            // Show generic error
            showFormErrorWithLog(serverResponse);
            return;
        }
        // Handle no required items
        if (
            !serverResponse.items ||
            !serverResponse.items[0] ||
            !serverResponse.items[0].fields
        ) {
            showFormErrorWithLog(serverResponse);
            return;
        }

        const responseFields = serverResponse.items[0].fields;
        if (
            responseFields.error_message &&
            responseFields.error_message.value !== ''
        ) {
            showFormError(responseFields.error_message.value);
            return;
        }
        if (responseFields.payment_intent_status?.value === 'succeeded') {
            onSuccess();
            return;
        }
        if (!stripe) {
            console.error('!stripe', stripe, elements);
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        if (
            responseFields.payment_intent_status?.value === 'requires_action' ||
            responseFields.payment_intent_status?.value ===
                'requires_source_action'
        ) {
            // Use Stripe.js to handle required card action
            maskMain('Payment Step 2...');

            const result = await stripe.handleCardAction(
                responseFields.client_secret.value,
            );

            if (result.error) {
                showFormError(result.error.message ?? 'Unknown card error');
                if (!result.error?.payment_intent?.id) {
                    console.error(
                        'No payment intent id in second payment reqest',
                        result,
                    );
                    unmaskMain();
                    return;
                }
                const sendUrl = scaRejectedAuthUrl
                    ? scaRejectedAuthUrl
                    : '/stripe-incoming-card-transaction/cancel-two-factor-authentication';
                await httpPost(sendUrl + '?format=json', {
                    fields: _addValueToFields({
                        payment_intent_id: result.error.payment_intent.id,
                        stripe_action_approved: false,
                        stripe_approved_at: null,
                        stripe_status: result.error.payment_intent.status,
                        stripe_error_message: result.error.message,
                    }),
                });
                unmaskMain();
            } else if (result.paymentIntent) {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                const sendUrl = scaAuthUrl ? scaAuthUrl : url;

                // TODO add type for this response
                const confirmAuthResponse = await httpPost(
                    sendUrl + '?format=json',
                    {
                        fields: _addValueToFields({
                            ...fields,
                            payment_intent_id: result.paymentIntent.id,
                            stripe_action_approved: true,
                            stripe_approved_at: Math.floor(Date.now() / 1000),
                            stripe_status: result.paymentIntent.status,
                            stripe_error_message: null,
                        }),
                    },
                );

                unmaskMain();
                handleServerResponse(confirmAuthResponse);
            }
            return;
        }
        if (
            isSmsStripeAccount &&
            responseFields.payment_intent_status &&
            !responseFields.payment_intent_status.value &&
            responseFields.client_secret &&
            !responseFields.client_secret.value
        ) {
            onSuccess();
            return;
        }

        showFormErrorWithLog(serverResponse);
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.error('!stripe || !elements', stripe, elements);
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardNumberElement);

        if (card === null) {
            console.error('No card!');
            return;
        }

        if (name === null || name === '') {
            // Validate name because there is no live stripe validation
            setNameError('Name can not be empty.');
            // If some of the fields are empty we can go on with calling createPaymentMethod function because it will stop execution, and calling it is neccessary to show all error messages
            if (
                !elements.getElement(CardNumberElement) &&
                !elements.getElement(CardCvcElement) &&
                !elements.getElement(CardExpiryElement)
            ) {
                return;
            }
        }

        if (email === null || email === '') {
            // Validate email because there is no live stripe validation
            setEmailError('Email can not be empty.');
            // If some of the fields are empty we can go on with calling createPaymentMethod function because it will stop execution, and calling it is neccessary to show all error messages
            if (
                !elements.getElement(CardNumberElement) &&
                !elements.getElement(CardCvcElement) &&
                !elements.getElement(CardExpiryElement)
            ) {
                return;
            }
        }

        setIsSubmitting(true);
        setGlobalError(null);
        maskMain('Payment Step 1...');
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
                name,
                email,
            },
        });
        if (payload.error) {
            console.error('Payment Step 1 Error', payload.error);
            setGlobalError(payload.error.message ?? null);
            setIsSubmitting(false);
            unmaskMain();
            return;
        }
        const paymentMethod = payload.paymentMethod;

        const params = {
            fields: _addValueToFields({
                ...fields,
                payment_method_id: paymentMethod.id,
                email: {
                    value: paymentMethod.billing_details.email,
                },
            }),
        };
        let serverResponse: SisServerResponse;
        try {
            serverResponse = await httpPost(url + '?format=json', params);
        } catch (error) {
            // An error modal will be shown to the user by the http client,
            // so no need to display anything here. But need to unmask to prevent
            // the loading spinner being stuck on forever
            console.error('Error posting to payment url', error);
            unmaskMain();
            setIsSubmitting(false);
            return;
        }
        handleServerResponse(serverResponse);
    };
    const onCancelClick = () => {
        onModalClose();
        if (isItemSoldOut) {
            loadCurrentPage();
        }
    };

    const isDisabled = isSubmitting || !amount || amount === '£null' || !stripe;

    const decimalAmount = _getDecimalAmount(amount, currency);

    if (!stripe) {
        return <div style={{height: '211px'}}>Loading...</div>;
    }
    return (
        <form onSubmit={handleSubmit} style={{width: '100%', paddingTop: 5}}>
            {globalError && (
                <div
                    style={style.globalError}
                    className={'checkout-form-global-error-wrapper'}
                >
                    <span
                        style={style.globalErrorIcon}
                        className={'arbor-icon arbor-icon-error'}
                    ></span>
                    <div style={style.globalErrorBlock}>
                        <h3 style={style.globalErroHeading}>
                            {isItemSoldOut ? 'Item Sold Out' : 'Payment Failed'}
                        </h3>
                        <div style={style.globalErrorText}>{globalError}</div>
                    </div>
                </div>
            )}

            <div style={style.fieldBlock}>
                <div style={style.row}>
                    <label style={style.label} htmlFor="cardNumber">
                        Card Number
                    </label>
                    <div style={style.field}>
                        <CardNumberElement
                            id="cardNumber"
                            onChange={onChangeCardNumber}
                            options={{
                                // Disables Stripe Link. Could be enabled if we want
                                disableLink: true,
                                style: stripeStyle,
                            }}
                        />
                    </div>
                </div>
                {cardNumberError && (
                    <div style={style.error}>
                        <span>{cardNumberError.message}</span>
                    </div>
                )}
            </div>

            <div style={style.fieldBlock}>
                <div style={style.row}>
                    <label style={style.label} htmlFor="expiry">
                        Card Expiration
                    </label>
                    <div style={style.field}>
                        <CardExpiryElement
                            id="expiry"
                            options={{
                                style: stripeStyle,
                            }}
                            onChange={onChangeExpiryDate}
                        />
                    </div>
                </div>
                {cardExpiryError && (
                    <div style={style.error}>
                        <span>{cardExpiryError.message}</span>
                    </div>
                )}
            </div>

            <div style={style.fieldBlock}>
                <div style={style.row}>
                    <label style={style.label} htmlFor="name">
                        {'Name on card'}
                    </label>
                    <div style={style.field}>
                        <input
                            id="name"
                            required
                            style={style.inputField}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                {nameError && (
                    <div style={style.error}>
                        <span>{nameError}</span>
                    </div>
                )}
            </div>

            <div style={style.fieldBlock}>
                <div style={style.row}>
                    <label style={style.label} htmlFor="email">
                        {'Email'}
                    </label>
                    <div style={style.field}>
                        <input
                            id="email"
                            required
                            style={style.inputField}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                </div>
                {emailError && (
                    <div style={style.error}>
                        <span>{emailError}</span>
                    </div>
                )}
            </div>

            <div style={style.fieldBlock}>
                <div style={style.row}>
                    <label style={style.label} htmlFor="cvc">
                        CVC
                    </label>
                    <div style={style.field}>
                        <CardCvcElement
                            id="cvc"
                            options={{
                                style: stripeStyle,
                            }}
                            onChange={onChangeCVC}
                        />
                    </div>
                </div>
                {cvcError && (
                    <div style={style.error}>
                        <span>{cvcError.message}</span>
                    </div>
                )}
            </div>
            <div style={style.buttonContainer}>
                <div style={style.submitButton}>
                    <Button
                        text={'Pay ' + decimalAmount}
                        color="green"
                        type="submit"
                        disabled={isDisabled}
                    />
                </div>
                <div style={style.cancelButton}>
                    <Button
                        text="Cancel"
                        color="grey"
                        onClick={onCancelClick}
                    />
                </div>
            </div>
        </form>
    );
};

export default CheckoutForm;
