import {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Button} from 'Components/button/base';
import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {logEventToGainsight} from 'Services/gainsight';
import {
    addUserFavouritePageAction,
    userFavouritePagesAction,
} from 'State/navigation/actions';

import {
    createFavouritePageRequest,
    deleteCustomFavouritePageRequest,
    updateFavouritePageRequest,
} from './favouritePagePopupUtils';

import './favouritePagePopup.scss';

export type FavouritePagePopupProps = {
    onClose: () => void;
    url: string;
    isFavourite?: boolean;
    userFavouritePageId?: number;
    customName: string;
    setUserFavouritePages: typeof userFavouritePagesAction;
    addUserFavouritePage: typeof addUserFavouritePageAction;
    isCurrentPage?: boolean;
};

const UnconnectedFavouritePagePopup = ({
    onClose,
    url,
    isFavourite,
    userFavouritePageId,
    customName,
    setUserFavouritePages,
    addUserFavouritePage,
    isCurrentPage,
}: FavouritePagePopupProps) => {
    const [customFavouriteName, setCustomFavouriteName] =
        useState<string>(customName);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const title = isFavourite
        ? 'Edit or Remove Favourite'
        : 'Add to Favourites';

    const errorMessage = () => (
        <div className="favourite-page-popup__error">
            Something went wrong! Unable to update favourite page!
        </div>
    );

    const renderBanner = () =>
        isFavourite ? (
            <div className="favourite-page-popup__banner">
                What can you do here? <br />
                <ol>
                    <li>
                        Change the name of your favourited page to make it more
                        relevant to you.
                    </li>
                    <li>
                        If you no longer need quick access to a specific page,
                        remove it from your Favourites.
                    </li>
                    <li>
                        Changed your mind? No worries! You can always re-add a
                        page to your Favourites.
                    </li>
                </ol>
            </div>
        ) : (
            <div className="favourite-page-popup__banner">
                Here’s a few things you should know about Favourites:
                <br />
                <ol>
                    <li>
                        Your favourited page will be displayed on your Homepage
                        for easy access.
                    </li>
                    <li>
                        You can change the name below, your colleagues will
                        still see either the default name or their own specified
                        title.
                    </li>
                </ol>
            </div>
        );
    const submitFavouritePage = async () => {
        try {
            setShowError(false);
            setIsSubmitting(true);
            if (isFavourite) {
                const response = await updateFavouritePageRequest({
                    userFavouritePageId,
                    url,
                    customFavouriteName,
                });

                const {userFavouritePages} = response;

                if (userFavouritePages) {
                    setUserFavouritePages(userFavouritePages);
                }
                logEventToGainsight('CustomFavouriteModified', {
                    url,
                });
            } else {
                const response = await createFavouritePageRequest({
                    url,
                    customFavouriteName,
                });

                addUserFavouritePage(
                    response.userFavouritePageId,
                    url,
                    customFavouriteName,
                );
                logEventToGainsight('CustomFavouriteAdded', {
                    url,
                });
            }

            onClose();
        } catch (error) {
            console.error('Error submitting favourite page request', error);
            setShowError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const deleteCustomFavouritePage = async () => {
        if (!userFavouritePageId && userFavouritePageId !== 0) {
            throw new Error('no ID found for custom favourite page!');
        }
        try {
            setShowError(false);
            setIsSubmitting(true);
            const response = await deleteCustomFavouritePageRequest(
                userFavouritePageId,
                url,
            );

            const {userFavouritePages} = response;
            if (userFavouritePages) {
                setUserFavouritePages(userFavouritePages);
            }
            logEventToGainsight('CustomFavouriteRemoved', {
                url,
            });
            onClose();
        } catch (error) {
            console.error('Error deleting favourite page', error);
            setShowError(true);
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <FocusTrappingModal
            className="favourite-page-popup"
            onClose={onClose}
            removeChildrenPadding={true}
        >
            <h2 className="favourite-page-popup__header">{title}</h2>
            {renderBanner()}
            <div className="favourite-page-popup-input-container">
                <span className="favourite-page-popup-input">
                    <label className="favourite-page-popup-input__label">
                        URL
                    </label>
                    <label className="favourite-page-popup-input__field">
                        {isCurrentPage
                            ? `${window.location.search.slice(1)}${
                                  window.location.hash
                                      ? '/' + window.location.hash
                                      : ''
                              }`
                            : url}
                    </label>
                </span>
                <span className="favourite-page-popup-input">
                    <label
                        className="favourite-page-popup-input__label"
                        htmlFor={'customName'}
                    >
                        Favourite Name
                    </label>
                    <input
                        className="favourite-page-popup-input__field"
                        type={'text'}
                        name={'customName'}
                        value={customFavouriteName}
                        onChange={(e) => {
                            setCustomFavouriteName(e.target.value);
                        }}
                    ></input>
                </span>
                {showError && errorMessage()}
            </div>
            <div className="favourite-page-popup-button-container">
                <div className="favourite-page-popup-button__remove">
                    {isFavourite && (
                        <Button
                            uiType="primary"
                            color="red"
                            text="Remove"
                            onClick={deleteCustomFavouritePage}
                            dataTestId="DeleteButton"
                            disabled={isSubmitting}
                        />
                    )}
                </div>
                <div className="favourite-page-popup-button">
                    <Button
                        uiType="primary"
                        color="grey"
                        text="Cancel"
                        onClick={onClose}
                    />
                    <Button
                        uiType="primary"
                        color="green"
                        text={isFavourite ? 'Update' : 'Add to Favourites'}
                        onClick={submitFavouritePage}
                        dataTestId="SubmitButton"
                        disabled={isSubmitting}
                    />
                </div>
            </div>
        </FocusTrappingModal>
    );
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserFavouritePages: userFavouritePagesAction,
            addUserFavouritePage: addUserFavouritePageAction,
        },
        dispatch,
    );

export const FavouritePagePopup = connect(
    null,
    mapDispatchToProps,
)(UnconnectedFavouritePagePopup);
