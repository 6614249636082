import PropTypes from 'prop-types';

import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import './shortcutPanel.scss';

export function ShortcutPanel({items, title: panelTitle}) {
    return (
        <section className="shortcut-panel-container" aria-label={panelTitle}>
            <div className="shortcut-panel-header" aria-hidden>
                {panelTitle}
            </div>
            <ul className="shortcut-panel-list-container">
                {items.map(({title, url}) => {
                    return (
                        <li key={`${title}-${url}`}>
                            <KeyboardFocusableLink
                                url={url}
                                className="shortcut-panel-url"
                                linkWrapperClassName="shortcut-panel-url__wrapper"
                                insetFocusRing
                            >
                                {title}
                            </KeyboardFocusableLink>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

ShortcutPanel.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            url: PropTypes.string,
        }),
    ),
    title: PropTypes.string,
};

ShortcutPanel.defaultProps = {
    items: [],
    title: '',
};
