import classNames from 'classnames';
import {useState} from 'react';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';

import {FavIconSvg} from './FavIconSvg';
import {FavouritePagePopup} from './FavouritePagePopup';

type FavouriteIconProps = {
    isCurrentPage?: boolean;
    url: string;
    userFavouritePage?: UserFavouritePage;
    pageName: string;
};

const KeyboardFocusableButtonWithTooltip = tooltipWrapper(
    KeyboardFocusableButton,
);

export const FavouriteIcon = ({
    isCurrentPage,
    url,
    userFavouritePage,
    pageName,
}: FavouriteIconProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    return (
        <>
            {isModalVisible && (
                <FavouritePagePopup
                    onClose={() => {
                        setIsModalVisible(false);
                    }}
                    url={url}
                    isFavourite={!!userFavouritePage}
                    customName={userFavouritePage?.customName || pageName}
                    userFavouritePageId={userFavouritePage?.userFavouritePageId}
                    isCurrentPage={isCurrentPage}
                />
            )}

            <KeyboardFocusableButtonWithTooltip
                onClick={() => {
                    setIsModalVisible(true);
                }}
                buttonWrapperClassName={classNames(
                    'navigation-tree__item-fav-icon',
                    {
                        'navigation-tree__item-fav-icon--selected':
                            isCurrentPage,
                        'navigation-tree__item-fav-icon--favourited':
                            !!userFavouritePage,
                        'navigation-tree__item-fav-icon--favourited--selected':
                            !!userFavouritePage && isCurrentPage,
                    },
                )}
                tooltip={
                    userFavouritePage
                        ? 'Edit or remove from Favourites'
                        : 'Add to Favourites'
                }
                insetFocusRing
                aria-label={
                    userFavouritePage
                        ? 'Edit or remove from Favourites'
                        : 'Add to Favourites'
                }
            >
                <FavIconSvg />
            </KeyboardFocusableButtonWithTooltip>
        </>
    );
};
