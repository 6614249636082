import classnames from 'classnames';
import * as React from 'react';

import {Icon} from 'Components/icon';
import {SearchField} from 'Components/searchField';

import './searchFilterDropdownField.scss';

interface ISearchFilterDropdownField {
    isDropdownVisible: boolean;
    toggleDropdown: () => void;
    openDropdown: () => void;
    searchValue: string;
    handleSearchValueChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
    placeholderText?: string;
    isLoading: boolean;
}

export const SearchFilterDropdownField = ({
    isDropdownVisible,
    toggleDropdown,
    openDropdown,
    searchValue,
    handleSearchValueChange,
    placeholderText = 'Your search matched no items.',
    isLoading,
}: ISearchFilterDropdownField) => {
    if (isLoading) {
        return <div className="search-filter-dropdown-field--loading" />;
    }

    return (
        <div onKeyDown={openDropdown}>
            <SearchField
                value={searchValue}
                onChange={handleSearchValueChange}
                onFocus={openDropdown}
                placeholder={placeholderText}
                inputClassName={classnames({
                    'search-filter-dropdown-field--active': isDropdownVisible,
                })}
            />
            <button
                tabIndex={-1}
                onClick={toggleDropdown}
                className="search-filter-dropdown-field__arrow-button"
            >
                <Icon
                    className="search-filter-dropdown-field__arrow-icon"
                    iconName={
                        isDropdownVisible ? 'arrow-go-up' : 'arrow-go-down'
                    }
                />
            </button>
        </div>
    );
};
