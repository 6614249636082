import PropTypes, {arrayOf} from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchKpiDataRequest} from 'BackendRenderedComponents/dashboard/redux/actions';
import {
    selectAttendanceKpis,
    selectBehaviourKpis,
    selectAttainmentKpis,
    selectNumberOfStudents,
    selectKpiDataLoading,
    selectNumberOfSelectedFilters,
} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {TAB_IDS} from 'BackendRenderedComponents/dashboard/redux/types';
import {LoadingDots} from 'Components/animations';

import {
    StudentGroupFilterDropdown,
    DemographicFilterDropdown,
    ViewFilterDropdown,
} from '../dropdowns';
import {KpiPanels} from '../KpiPanels';

import {SelectedDashboardFilters} from './SelectedDashboardFilters';

import './myStudentsTab.scss';

class UnconnectedMyStudentsTab extends Component {
    componentDidMount = () => {
        this.props.actions.fetchKpiDataRequest({tabId: TAB_IDS.MY_STUDENTS});
    };
    render() {
        const {
            attendanceKpis,
            behaviourKpis,
            attainmentKpis,
            numberOfStudents,
            numberOfSelectedFilters,
            isLoading,
        } = this.props;
        return (
            <div>
                <div>
                    {numberOfSelectedFilters > 0 ? (
                        <span className="my-students-tab-student-number">
                            Showing:{' '}
                            {isLoading ? <LoadingDots /> : numberOfStudents}{' '}
                            students
                        </span>
                    ) : (
                        <span className="my-students-tab-student-number">
                            Showing: All your students (
                            {isLoading ? <LoadingDots /> : numberOfStudents})
                        </span>
                    )}
                    <SelectedDashboardFilters />
                </div>
                <div className="dropdowns-container">
                    <StudentGroupFilterDropdown />
                    <DemographicFilterDropdown />
                    <ViewFilterDropdown />
                </div>
                <KpiPanels
                    attendanceKpis={attendanceKpis}
                    behaviourKpis={behaviourKpis}
                    attainmentKpis={attainmentKpis}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

const kpiPropType = PropTypes.shape({
    url: PropTypes.string,
    html: PropTypes.string.isRequired,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipUrl: PropTypes.string,
});

UnconnectedMyStudentsTab.propTypes = {
    attendanceKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    attainmentKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    behaviourKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    numberOfStudents: PropTypes.number,
    numberOfSelectedFilters: PropTypes.number,
    isLoading: PropTypes.bool,
    actions: PropTypes.exact({
        fetchKpiDataRequest: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    attendanceKpis: selectAttendanceKpis(state, TAB_IDS.MY_STUDENTS),
    behaviourKpis: selectBehaviourKpis(state, TAB_IDS.MY_STUDENTS),
    attainmentKpis: selectAttainmentKpis(state, TAB_IDS.MY_STUDENTS),
    numberOfStudents: selectNumberOfStudents(state, TAB_IDS.MY_STUDENTS),
    numberOfSelectedFilters: selectNumberOfSelectedFilters(state),
    isLoading: selectKpiDataLoading(state, TAB_IDS.MY_STUDENTS),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchKpiDataRequest,
        },
        dispatch,
    ),
});

export const MyStudentsTab = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedMyStudentsTab);
