import {SideBarButton} from 'App/header/buttons/sideBarButton';
import {logEventToGainsight} from 'Services/gainsight';
import url from 'Services/url/Url';

import {ArborHQIcon} from './ArborHQIcon';

const handleClick = () => window.open(url.ARBOR.COMMUNITY, '_blank');

export const ArborHQButton = () => (
    <SideBarButton
        tooltip="Engage with the Arbor Community"
        onClick={() => {
            handleClick();
            logEventToGainsight('ArborCommunityButtonClicked', {});
        }}
        buttonText="Arbor HQ"
    >
        <ArborHQIcon />
    </SideBarButton>
);
