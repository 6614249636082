import DOMPurify from 'dompurify';

import {sanitizeLinks} from './sanitizeLinks';

/**
 * This list is fairly arbitary for now. can add/remove as needed
 * */
const allowedTags = [
    'a',
    'b',
    'br',
    'div',
    'em',
    'i',
    'li',
    'ol',
    'p',
    's',
    'span',
    'strong',
    'u',
    'ul',
] as const;

export const sanitizeChatPanelMessage = (dirtyText: string): string => {
    DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

    const cleanText = DOMPurify.sanitize(dirtyText, {
        ALLOWED_TAGS: [...allowedTags],
    });

    DOMPurify.removeHook('afterSanitizeAttributes');

    return cleanText;
};
