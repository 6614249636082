import {useState} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';

import {NewTabButton} from './NewTabButton';
import {Highlighter} from './SearchDropdownContents/Highlighter';
import {filterPages} from './utils/filterPages';

const DEFAULT_NUMBER_OF_PAGES = 8;

type PageSearchProps = {
    searchValue: string;
    filteredPages: ReturnType<typeof filterPages>;
    title: string;
};

export const PageSearch = ({
    searchValue,
    filteredPages,
    title,
}: PageSearchProps) => {
    const [showAllPages, setShowAllPages] = useState(false);

    if (searchValue === '') {
        return null;
    }

    if (filteredPages.length === 0) {
        return null;
    }
    return (
        <div className="search-dropdown__group-container">
            <div className="search-dropdown__group-title">
                {title} ({filteredPages.length}){' '}
                {filteredPages.length > DEFAULT_NUMBER_OF_PAGES && (
                    <KeyboardFocusableButton
                        buttonWrapperClassName="search-dropdown__secondary-small__wrapper"
                        className="search-dropdown__secondary-small"
                        borderRadius="4px"
                        onClick={(e) => {
                            setShowAllPages((prev) => !prev);
                            e.stopPropagation();
                        }}
                    >
                        {showAllPages ? 'Hide' : 'Show all'}
                    </KeyboardFocusableButton>
                )}
            </div>
            {filteredPages.map((item, itemIndex) =>
                !showAllPages && itemIndex >= DEFAULT_NUMBER_OF_PAGES ? null : (
                    <KeyboardFocusableLink
                        url={item.url}
                        insetFocusRing
                        tabIndex={-1}
                        key={itemIndex}
                        linkWrapperClassName="search-dropdown__group-item__container"
                        onClick={() => {
                            logEventToGainsight('SearchItemVisited', {
                                type: title,
                                matchType: item.matchType,
                                url: item.url,
                            });
                            if (title === 'Favourites') {
                                logEventToGainsight('CustomFavouriteClicked', {
                                    url: item.url,
                                    location: 'Search',
                                    clickedFrom: getCurrentPageFromUrl(),
                                });
                            }
                        }}
                    >
                        <span className="search-dropdown__group-item__icon">
                            <Icon iconName="page" />
                        </span>
                        <span className="search-dropdown__group-item__title">
                            <Highlighter
                                text={item.text}
                                highlight={
                                    item.matchType === 'word-match'
                                        ? item.words
                                        : searchValue
                                }
                            />
                        </span>
                        {item.matchType === 'alternative-title-match' && (
                            <span className="search-dropdown__group-item__alternativeName">
                                (
                                <Highlighter
                                    text={item.alternativeName}
                                    highlight={searchValue}
                                />
                                )
                            </span>
                        )}
                        <span className="search-dropdown__group-item__description">
                            <Highlighter
                                text={item.description}
                                highlight={
                                    item.matchType === 'word-match'
                                        ? item.words
                                        : searchValue
                                }
                            />
                        </span>

                        {item.url && <NewTabButton itemUrl={item.url} />}
                    </KeyboardFocusableLink>
                ),
            )}
        </div>
    );
};
