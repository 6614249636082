export const calculateBreadCrumbItemsToHide = (
    container: HTMLDivElement,
    breadCrumbItemWidthsRef: React.MutableRefObject<number[]>,
) => {
    const children = [...container.children];
    // get the widths of each breadcrumb item, the '/' divider is included
    const childWidths = children.map((child) => child.clientWidth);

    // before any elements are hidden store the widths of all items in a ref so we can show them again
    // when screen is resized
    if (typeof breadCrumbItemWidthsRef.current[0] === 'undefined') {
        breadCrumbItemWidthsRef.current = childWidths;
    }
    // Subtract 1 to prevent the final letter getting clipped off in edge case with
    // partial pixel widths. Prevent going negative just in case this causes issues.
    const safeContainerWidth = Math.max(0, container.clientWidth - 1);

    const {numberOfElementsToHide} =
        breadCrumbItemWidthsRef.current.reduceRight(
            (acc, curr, index) => {
                if (acc.numberOfElementsToHide > 0) {
                    return acc;
                }
                // We want to find the first item from the end that doesn't fit
                if (
                    safeContainerWidth - acc.totalWidthOfShownChildren >=
                    curr
                ) {
                    return {
                        numberOfElementsToHide: 0,
                        totalWidthOfShownChildren:
                            acc.totalWidthOfShownChildren + curr,
                    };
                }
                return {
                    numberOfElementsToHide: index + 1,
                    totalWidthOfShownChildren: acc.totalWidthOfShownChildren,
                };
            },
            {
                numberOfElementsToHide: 0,
                totalWidthOfShownChildren: 0,
            },
        );

    return numberOfElementsToHide;
};
