import classNames from 'classnames';
// Note: this is the same HTML structure as the main site loading spinner.
// For the main spinner the HTML is in the layout.phtml file in the `sis` repo
export const LoadingSpinner = ({className}: {className?: string}) => (
    <div
        className={classNames('loading-spinner', [className])}
        aria-label="Loading spinner"
        role="img"
    >
        <div className="loading-spinner__inner" role="presentation">
            <div
                className="loading-spinner__circle loading-spinner__circle-left"
                role="presentation"
            ></div>
            <div
                className="loading-spinner__circle loading-spinner__circle-top"
                role="presentation"
            ></div>
            <div
                className="loading-spinner__circle loading-spinner__circle-right"
                role="presentation"
            ></div>
            <div
                className="loading-spinner__circle loading-spinner__circle-bottom"
                role="presentation"
            ></div>
        </div>
    </div>
);
