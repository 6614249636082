import {httpPost} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

export const sendLoginRequest = (username, password) => {
    // Note: email address can also be used for the username parameter

    const postData = {
        items: [
            {
                username,
                password,
            },
        ],
    };

    return httpPost(url.AUTH.LOGIN, postData);
};
