import {INotification} from 'Services/userNotifications/UserNotification';

import {NotificationDropdownItem} from './NotificationDropdownItem';

import './itemsContainer.scss';

export const ItemsContainer = ({items}: {items: INotification[]}) => {
    return (
        <div className="notification-button-dropdown__item-container">
            {items.length > 0 ? (
                <ul tabIndex={-1}>
                    {items.map((item, index) => (
                        <NotificationDropdownItem
                            key={index}
                            notification={item}
                        />
                    ))}
                </ul>
            ) : (
                <p className="notification-button-dropdown__empty-text">
                    There are no items to show.
                </p>
            )}
        </div>
    );
};
