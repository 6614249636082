import classNames from 'classnames';

import Loader from 'Services/Loader';
import {getHrefFromUrl} from 'Utils/getHrefFromUrl';

import {NewTabIcon} from './NewTabIcon';

import './newTabButton.scss';

type NewTabButtonProps = {itemUrl?: string; forceHoverStyles?: boolean};

export const NewTabButton = ({
    itemUrl,
    forceHoverStyles,
}: NewTabButtonProps) => {
    return (
        <span
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                Loader.openInNewTab(getHrefFromUrl(itemUrl));
            }}
            className={classNames('new-tab-button', {
                'new-tab-button--force-hover': forceHoverStyles,
            })}
            aria-hidden
        >
            <NewTabIcon />
        </span>
    );
};
