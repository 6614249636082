import {useEffect, useMemo, useRef, useState} from 'react';

import {
    Combobox as CoreCombobox,
    ComboboxItem,
    ComboboxValue,
    useFetchComboboxOptions,
} from 'Components/combobox';
import {
    useFormFieldRegistry,
    extractCommonFormFieldParams,
} from 'Interfaces/formFields';
import {RendererParams} from 'Renderer/Renderer';

import {getSafeSubmitValue} from './getSafeSubmitValue';
import {transformBackendValues} from './transformBackendValues';

export type BackendComboboxValueItem = {
    value: ComboboxValue;
    label: string;
    group?: string;
    icon?: string;
};

type BackendRenderedComboboxOptionWithWrappingObjects = {
    fields: {
        label: {
            value: string;
        };
        dropdownLabel?: {
            value: string;
        };
        value: {
            value: ComboboxValue;
        };
        icon?: {
            value: string | null;
        };
        group?: {
            value: string | null;
        };
        selected?: {
            value: boolean;
        };
        topOption?: {
            value: boolean;
        };
    };
};
type BackendRenderedComboboxOption = {
    label: string;
    dropdownLabel?: string;
    value: ComboboxValue;
    icon?: string | null;
    group?: string | null;
    selected?: boolean;
    topOption?: boolean;
};

export type BackendRenderedComboboxProps = {
    tooltipMIS?: string;
    tooltipUrl?: string;
    tooltipHTML?: string;
    fieldLabel?: string;
    emptyText?: string;
    value?: (ComboboxValue | BackendComboboxValueItem)[] | ComboboxValue;
    actionMappings?: Record<string, string>;
    name: string;
    bind?: {
        parentValues: Record<string, string>;
    };
    reference?: string; // used as an ID for dependee form fields
    url?: string;
    disabled?: boolean;
    autoSelect?: boolean;
    multiSelect?: boolean;
    options?: (
        | BackendRenderedComboboxOptionWithWrappingObjects
        | BackendRenderedComboboxOption
    )[];
    children?: any; // not sure if used
    remoteSort?: boolean;
    forceSelection?: boolean; // related to allowFreeText in the Ext component - can remove from PHP Layer when Ext component is removed
    createNewOnEnter?: boolean; // related to allowFreeText in the Ext component - can remove from PHP Layer when Ext component is removed
    createNewOnBlur?: boolean; // related to allowFreeText in the Ext component - can remove from PHP Layer when Ext component is removed
    allowFreeText?: boolean;
    autoLoad?: boolean; // Only used when `dependsOn`. Can probably remove
    rendererParams: RendererParams;
    dependencyFields?: string[];
    required?: boolean;
    defaultValueOnEmpty?: string | number;
    id: string;
};

export const Combobox = (props: BackendRenderedComboboxProps) => {
    const {
        fieldLabel,
        url,
        emptyText,
        disabled,
        value: backendValues,
        options,
        multiSelect,
        tooltipHTML,
        tooltipMIS,
        tooltipUrl,
        allowFreeText,
        remoteSort,
        dependencyFields,
        required,
        defaultValueOnEmpty,
        rendererParams,
    } = props;

    const comboboxRef = useRef<HTMLDivElement>(null);

    const transformedOptions: ComboboxItem[] = useMemo(
        () =>
            options?.map((option) => {
                if ('fields' in option) {
                    const {
                        label,
                        dropdownLabel,
                        value,
                        icon,
                        group,
                        selected,
                        topOption,
                    } = option.fields;
                    return {
                        label: label?.value,
                        dropdownLabel: dropdownLabel?.value,
                        selected: selected?.value,
                        value: value?.value,
                        icon: icon?.value,
                        group: group?.value,
                        topOption: topOption?.value,
                    };
                }
                const {
                    label,
                    dropdownLabel,
                    value,
                    icon,
                    group,
                    selected,
                    topOption,
                } = option;
                return {
                    label,
                    dropdownLabel,
                    selected,
                    value,
                    icon,
                    group,
                    topOption,
                };
            }) ?? [],
        [options],
    );
    const initialValueFromProps = useMemo(() => {
        /**
         * In backend rendered comboboxes, the props never change once rendered,
         * so we can compute the derived initial value from props and re-use whenever
         * needed.
         */
        const transformedValues = transformBackendValues(backendValues);
        const transformedValuesWithOptionSelectionApplied =
            transformedValues.length > 0
                ? transformedValues
                : transformedOptions
                      ?.filter(({selected, value}) => selected && value !== '')
                      .map((item) => item.value);
        return transformedValuesWithOptionSelectionApplied;
    }, [backendValues, transformedOptions]);

    // If you want to pass a value into the inner combobox component, update this state.
    // Used when initialising remote/local value, and when updating values from the form
    // field registry.
    const [innerComboboxDefaultValue, setInnerComboboxDefaultValue] = useState<
        ComboboxValue[]
    >(() => {
        return initialValueFromProps;
    });

    const isRemoteCombobox = typeof url !== 'undefined';
    const [errors, setErrors] = useState<string[]>([]);
    const [currentValueState, setValueState] = useState<
        ComboboxValue[] | ComboboxValue
    >(() => {
        if (multiSelect) {
            return initialValueFromProps;
        }
        return initialValueFromProps.length === 0
            ? ''
            : initialValueFromProps[0];
    });
    // Started getting stale values on submit, so adding this extra ref.
    // Hopefully can figure out why and remove the need for this ref.
    const currentValueRef = useRef(currentValueState);
    const updateValue = (newValue: ComboboxValue | ComboboxValue[]) => {
        setValueState(newValue);
        currentValueRef.current = newValue;
        setErrors([]);
    };

    const {
        isLoading,
        comboboxOptions: remoteComboboxOptions,
        refetchComboboxOptions,
    } = useFetchComboboxOptions({
        optionsUrl: url,
        hasDependencyFields: !!dependencyFields?.length,
        onOptionsFetched: ({initialValues: selectedValuesInRemoteOptions}) => {
            // An example of initial values being set in a remote combobox is
            // used in the following slideover:
            // /department-ui/add-academic-unit-to-department/department-id/2

            setInnerComboboxDefaultValue((prevValue) => {
                // Combine initial values that come from props, and from
                // selected remote options. Use Set to de-duplicate
                const combinedInitialValue = [
                    ...new Set([
                        ...selectedValuesInRemoteOptions,

                        ...(Array.isArray(currentValueRef.current)
                            ? currentValueRef.current
                            : [currentValueRef.current]),
                    ]),
                ];
                // Use a deep comparison to check if the values have actually changed.
                // When re-fetching dependent comboboxes it's quite common that the
                // seleceted values don't actualy change.
                // When they haven't changed return prevValues so that object references don't change
                // This helps avoid unnecessary renders, effects etc.
                if (
                    JSON.stringify(prevValue) ===
                    JSON.stringify(combinedInitialValue)
                ) {
                    return prevValue;
                }
                return combinedInitialValue;
            });
        },
    });

    const {updateFormRegistryValue} = useFormFieldRegistry({
        ...extractCommonFormFieldParams(props),
        getSubmitValue() {
            const currentValue = currentValueRef.current;
            return getSafeSubmitValue(currentValue, defaultValueOnEmpty);
        },
        getValue() {
            const currentValue = currentValueRef.current;
            return getSafeSubmitValue(currentValue, defaultValueOnEmpty);
        },
        markInvalid(errorMessages) {
            if (Array.isArray(errorMessages)) {
                setErrors(errorMessages);
            } else {
                setErrors([errorMessages]);
            }
        },
        fetchNewData: refetchComboboxOptions,
        setValue: (newValue) => {
            if (typeof newValue === 'string') {
                updateValue(newValue);
                setInnerComboboxDefaultValue([newValue]);
                return;
            }
            if (Array.isArray(newValue)) {
                if (newValue.length === 0) {
                    // maybe must do setInnerComboboxDefaultValue, and let the onchange flow back out the combobox
                    updateValue(multiSelect ? [] : '');
                    setInnerComboboxDefaultValue([]);
                    return;
                }

                const containsNonStringValue =
                    typeof newValue.find(
                        (valueInArray) => typeof valueInArray !== 'string',
                    ) !== 'undefined';

                if (containsNonStringValue) {
                    console.error(
                        'Non-string values in array in Combobox setValue from useFormFieldRegistry',
                        newValue,
                    );
                    const filteredValue = newValue.filter(
                        (item) => typeof item === 'string',
                    ) as string[];
                    updateValue(filteredValue);
                    setInnerComboboxDefaultValue(filteredValue);
                    return;
                }
                updateValue(newValue as string[]);
                setInnerComboboxDefaultValue(newValue as string[]);
                return;
            }
            if (newValue === null) {
                updateValue(multiSelect ? [] : '');
                setInnerComboboxDefaultValue([]);
                return;
            }
            console.error(
                'Invalid value in Combobox useFormFieldRegistry -> setvalue',
                newValue,
            );
        },
        clearValue: () => {
            updateValue(multiSelect ? [] : '');
            setInnerComboboxDefaultValue([]);
        },
        getFilterPanelTitleItem: () => {
            const strippedLabel = (fieldLabel ?? '').replace(/\.\.\./g, '');
            const getComboboxlabel = (valueToCheck: ComboboxValue) => {
                const optionsToCheck = isRemoteCombobox
                    ? remoteComboboxOptions
                    : transformedOptions;
                return (
                    optionsToCheck.find((item) => item.value === valueToCheck)
                        ?.label ?? ''
                );
            };
            const currentValue = currentValueRef.current;

            if (
                currentValue === '' ||
                (Array.isArray(currentValue) && currentValue?.length === 0)
            ) {
                return '';
            }
            if (
                typeof currentValue === 'string' ||
                typeof currentValue === 'number'
            ) {
                return `${strippedLabel} <b>${getComboboxlabel(
                    currentValue,
                )}</b>`;
            }
            return `${strippedLabel} ${currentValue
                .map((item) => `<b>${getComboboxlabel(item)}</b>`)
                .join(' or ')}`;
        },
    });

    useEffect(() => {
        updateFormRegistryValue(
            getSafeSubmitValue(currentValueState, defaultValueOnEmpty),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValueState]);

    const comboboxItems = useMemo(
        () => (isRemoteCombobox ? remoteComboboxOptions : transformedOptions),
        [isRemoteCombobox, remoteComboboxOptions, transformedOptions],
    );
    return (
        <CoreCombobox
            setRef={comboboxRef}
            label={fieldLabel}
            isLoading={isRemoteCombobox && isLoading}
            placeholder={emptyText}
            items={comboboxItems}
            disabled={disabled}
            initialValue={innerComboboxDefaultValue}
            isMultiselect={multiSelect}
            tooltip={tooltipMIS ?? tooltipHTML}
            tooltipUrl={tooltipUrl}
            errors={errors}
            onChange={updateValue}
            allowFreeText={allowFreeText}
            remoteSort={remoteSort}
            required={required}
            rendererParams={rendererParams}
        />
    );
};
