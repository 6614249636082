import {useRef} from 'react';
import * as React from 'react';

import {IButtonProps} from 'Components/button/base';

interface IButtonHOCExt extends IButtonProps {
    confirmationTitle?: string;
    confirmationButtonText?: string;
    confirmationButtonColor?: string;
    confirmationText?: string;
}
export default function (WrappedComponent: React.ComponentType<IButtonProps>) {
    const ButtonHOCExt = (props: IButtonHOCExt) => {
        const {
            confirmationTitle = 'Confirmation',
            confirmationButtonText = 'Yes',
            confirmationButtonColor,
            confirmationText,
            ...buttonProps
        } = props;

        const {color = 'default', onClick} = buttonProps;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const extModal = useRef<any | null>(null);

        const showExtModal = () => {
            extModal.current = Ext.create('Mis.window.Window', {
                header: {
                    title: confirmationTitle,
                },
                maxWidth: 400,
                bodyPadding: 5,
                floating: true,
                items: [
                    {
                        xtype: 'mis-simple-text',
                        html: confirmationText,
                    },
                ],
                dockedItems: [
                    {
                        dock: 'bottom',
                        xtype: 'mis-actionpanel',
                        items: [
                            {
                                xtype: 'mis-button',
                                text: 'Cancel',
                                cls: 'mis-btn-left-align',
                                handler: () => {
                                    extModal?.current?.close();
                                    extModal?.current?.destroy();
                                },
                            },
                            {
                                xtype: 'mis-button',
                                text: confirmationButtonText,
                                cls: 'mis-btn-right-align',
                                ui: confirmationButtonColor
                                    ? confirmationButtonColor
                                    : color,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                handler: (e: any) => {
                                    extModal?.current?.destroy();

                                    if (typeof onClick === 'function') {
                                        onClick(e);
                                    }
                                },
                            },
                        ],
                    },
                ],
            }).show();
        };

        const onWrapperClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (typeof onClick === 'function') {
                onClick(e);
            }
        };

        return typeof confirmationText !== 'undefined' ? (
            <WrappedComponent {...buttonProps} onClick={showExtModal} />
        ) : (
            <WrappedComponent {...buttonProps} onClick={onWrapperClick} />
        );
    };

    return ButtonHOCExt;
}
