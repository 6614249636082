export const instantly = 0;
export const immediately = 50;
export const quickly = 100;
export const promptly = 200;
export const slowly = 400;
export const paused = 3200;
export const slide = 250;
export const slower = 1200;
export const autoDismiss = 10000;

// This needs to match the value $slideover-transition-time in styles/contants.scss
export const slideover = 300;
