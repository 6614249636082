import {useEffect, useRef, useState} from 'react';

import {BurgerMenuBackButton} from 'App/header/buttons/burgerMenuBackButton/BurgerMenuBackButton';
import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {focusFirstFocusableElement} from 'Root/core/utils/focusUtils';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';
import url from 'Services/url/Url';

import {CalendarWidgetItem} from './CalendarWidgetItem';
import {getCalendarDataService} from './getCalendarDataService';

import './calendarWidget.scss';

const CalendarItemWithTooltip = tooltipWrapper(CalendarWidgetItem);
interface ICalendarWidgetProps {
    configs: {
        url?: string;
    };
    objectId?: string;
    objectTypeId?: string;
    titleUrl?: string;
    title?: string;
    backButtonOnClick?: () => void;
    focusHeaderOnOpen?: boolean;
    widgetLocation: string;
}

export interface ICalendarWidgetItem {
    colorIndex: string;
    startTime: string;
    endTime: string;
    title: string;
    location: string | null;
    url: string;
    tooltipUrl?: string;
    eventDate?: string;
    widgetLocation?: string;
}

export const CalendarWidget = ({
    configs,
    objectId,
    objectTypeId,
    title = 'My Calendar',
    titleUrl,
    backButtonOnClick,
    focusHeaderOnOpen,
    widgetLocation = 'HomePage',
}: ICalendarWidgetProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<ICalendarWidgetItem[]>([]);
    const headerRef = useRef<HTMLAnchorElement>(null);

    const getCalendarData = async (urlToFetch: string) => {
        const calendarItems = await getCalendarDataService(urlToFetch);
        setItems(calendarItems);
        setIsLoading(false);
    };

    useEffect(() => {
        let urlToFetch = '';
        if (configs.url) {
            urlToFetch = configs.url;
        } else {
            urlToFetch =
                url.WIDGET_DATA.GET_CALENDAR_DATA +
                'object-id/' +
                objectId +
                '/object-type-id/' +
                objectTypeId +
                '/';
        }
        getCalendarData(urlToFetch);
    }, [configs.url, objectId, objectTypeId]);

    if (focusHeaderOnOpen && headerRef.current) {
        focusFirstFocusableElement(headerRef.current);
    }

    return (
        <section
            className="calendar-widget-container"
            aria-label={`Calendar Widget: ${title}`}
        >
            {typeof backButtonOnClick === 'function' && (
                <BurgerMenuBackButton
                    backButtonOnClick={backButtonOnClick}
                    backButtonText="Back"
                />
            )}
            <KeyboardFocusableLink
                url={titleUrl}
                disableDefaultLinkStyles
                insetFocusRing
                setRef={headerRef}
                onClick={() =>
                    logEventToGainsight('CalendarWidgetClicked', {
                        type: 'Header',
                        location: widgetLocation,
                        clickedFrom: getCurrentPageFromUrl(),
                    })
                }
            >
                <div className="calendar-widget-header">
                    <span className="calendar-widget-header-title">
                        {title}
                    </span>
                    <Icon
                        iconName="arrow-go-right"
                        className="arrow-go-right"
                    />
                </div>
            </KeyboardFocusableLink>
            {isLoading ? (
                <div className="calendar-widget-loading">Loading...</div>
            ) : (
                <div className="calendar-widget-list__container">
                    {items?.length <= 0 ? (
                        <div className="calendar-widget-empty-message">
                            No events to display.
                        </div>
                    ) : (
                        <ul className="calendar-widget-list">
                            {items.map((item, index) => (
                                <div key={index}>
                                    {item.eventDate !==
                                        items[index - 1]?.eventDate &&
                                        item.eventDate !==
                                            items[0].eventDate && (
                                            <div className="calendar-widget-date_label">
                                                {item.eventDate}
                                            </div>
                                        )}
                                    <CalendarItemWithTooltip
                                        colorIndex={item.colorIndex}
                                        startTime={item.startTime}
                                        endTime={item.endTime}
                                        title={item.title}
                                        location={item.location}
                                        url={item.url}
                                        tooltipUrl={item.tooltipUrl}
                                        widgetLocation={widgetLocation}
                                    />
                                </div>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </section>
    );
};
