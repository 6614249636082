import {useState} from 'react';

import {Button} from 'Components/button/base';
import {LoadingSpinner} from 'Components/loadingSpinner';

import {useAutoFillRequest} from './useAutoFillRequest';

import './autoFillModal.scss';

type AutoFillModalProps = {
    autoFillUrl: string;
    autoFillParams?: object;
    closeCallback: () => void;
    insertCallback: (content: string) => void;
};

export const AutoFillModal = ({
    autoFillUrl,
    autoFillParams,
    insertCallback,
    closeCallback,
}: AutoFillModalProps) => {
    const [userContent, setUserContent] = useState('');

    const {isLoading, error, autoFillContent, submitFunction, cancelFunction} =
        useAutoFillRequest({autoFillParams, autoFillUrl});

    return (
        <form
            className="auto-fill-modal__form"
            onSubmit={(event) => {
                event.preventDefault();
                submitFunction(userContent);
            }}
        >
            <p>
                We can help you write this. We can find contextual information,
                and combine it with anything you enter in the text box below.
            </p>
            <label className="auto-fill-modal__form-field">
                <span className="auto-fill-modal__form-field-label">
                    (Optional) Extra information you'd like to include
                </span>
                <textarea
                    className="auto-fill-modal__form-field-textarea"
                    rows={3}
                    value={userContent}
                    placeholder="Add relevant information that Arbor might not know"
                    onChange={(e) => setUserContent(e.target.value)}
                />
            </label>
            <div
                role="presentation"
                className="auto-fill-modal__footer-buttons"
            >
                <Button
                    text="Generate text"
                    disabled={isLoading}
                    color="green"
                    type="submit"
                />
            </div>
            <div className="auto-fill-modal__response-container">
                {isLoading && (
                    <p className="auto-fill-modal__loading-message">
                        Your message is being generated, this may take a little
                        while.
                    </p>
                )}
                {isLoading && <LoadingSpinner />}
                {isLoading && (
                    <Button text="Cancel Request" onClick={cancelFunction} />
                )}
                {autoFillContent}
                {error && <p className="auto-fill-modal__error">{error}</p>}
            </div>
            {autoFillContent && (
                <p>
                    Please make sure you read the text carefully, as we can't
                    guarantee the accuracy of it. You can still make edits after
                    it has been added to the text editor.
                </p>
            )}
            <div
                role="presentation"
                className="auto-fill-modal__footer-buttons"
            >
                <Button
                    text="Cancel"
                    onClick={() => {
                        closeCallback();
                    }}
                />
                <Button
                    text="Add to editor"
                    disabled={autoFillContent === ''}
                    color="green"
                    onClick={() => {
                        insertCallback(autoFillContent);
                        closeCallback();
                    }}
                />
            </div>
        </form>
    );
};
