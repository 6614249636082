import './pill.scss';

export type PillColor = 'green' | 'orange';

type PillProps = {
    text: string;
    color?: PillColor;
};

export const Pill = ({text, color = 'green'}: PillProps) => {
    return <span className={`pill pill--${color}`}>{text}</span>;
};
