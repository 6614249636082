import DOMPurify from 'dompurify';

import {sanitizeLinks} from './sanitizeLinks';

const allowedSchoolNoticesTags = [
    'a',
    'b',
    'i',
    'div',
    'span',
    'p',
    'ol',
    'ul',
    'li',
];
const allowedSchoolNoticesAttributes = ['href'];

export const sanitizeSchoolNotices = (content: string) => {
    // this hook happens after sanitizing, so we don't have to specify link-specific
    // attributes in allowedSchoolNoticesAttributes
    DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

    const cleanText = DOMPurify.sanitize(content, {
        ALLOWED_TAGS: allowedSchoolNoticesTags,
        ALLOWED_ATTR: allowedSchoolNoticesAttributes,
    });

    DOMPurify.removeHook('afterSanitizeAttributes');

    return cleanText;
};
