import {httpPost} from 'Interfaces/httpClient';

export type AgentMessageResponse = {
    items: {
        text: string;
        id: string;
        chatId?: string;
        timedOut?: boolean;
    }[];
};

export type GetNewAgentMessageParams = {
    chatId?: string;
    chatUrl: string;
    userMessage: string;
    userInputDeviceLastUsed?: string;
    canvasMode: boolean;
    canvasPusherChannel?: string;
    chatHistory?: any;
    canvasHistory?: any;
};

const SETTING_REQUEST_TIMEOUT = 55000;
export const GET_NEW_AGENT_MESSAGE_FALLBACK_ID = 'fallback';

export const getNewAgentMessage = async ({
    chatId = undefined,
    chatUrl,
    userMessage,
    userInputDeviceLastUsed,
    canvasMode,
    canvasPusherChannel = undefined,
    chatHistory,
    canvasHistory,
}: GetNewAgentMessageParams): Promise<{
    text: string;
    id: string;
    chatId?: string;
    timedOut?: boolean;
} | null> => {
    const controller = new AbortController();
    const signal = controller.signal;

    const httpPromise = httpPost(
        chatUrl,
        {
            chatId,
            userMessage,
            userInputDeviceLastUsed,
            canvasMode,
            canvasPusherChannel,
            chatHistory,
            canvasHistory,
        },
        {signal},
    );

    if (!canvasMode) {
        try {
            const response: AgentMessageResponse = await httpPromise;
            return response.items[0] || null;
        } catch (error: any) {
            if (error.name === 'AbortError') {
                console.warn('HTTP request aborted due to timeout.');
            } else {
                console.error('Error in getNewAgentMessage', error);
            }
            return null;
        }
    }

    const fallbackPromise = new Promise<AgentMessageResponse>((resolve) =>
        setTimeout(() => {
            controller.abort();
            resolve({
                items: [
                    {
                        text: "Hold tight, I'm working on that for you...",
                        id: GET_NEW_AGENT_MESSAGE_FALLBACK_ID,
                        timedOut: true, // Mark the fallback response as timed out.
                    },
                ],
            });
        }, SETTING_REQUEST_TIMEOUT),
    );

    try {
        const response: AgentMessageResponse = await Promise.race([
            httpPromise,
            fallbackPromise,
        ]);
        return response.items[0] || null;
    } catch (error) {
        console.error('Error in getNewAgentMessage', error);
        return null;
    }
};
