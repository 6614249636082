/* global aptrinsic */

import ExternalConfig from 'Config/External';

import {addGainsightScript} from './addGainsightScript';

// Get object in the form expected by Gainsight/aptrinsic API
// Exported for testing only
export const selectLoggableIdentityFromUserData = (userData) => {
    if (typeof userData !== 'object' || userData === null) {
        return {};
    }

    return {
        user: {id: `${userData.userId}:${userData.applicationId}`},
        account: {id: userData.applicationId},
    };
};

// Call the Gainsight aptrinsic API to log the current user's identity
export const logUserIdentity = (userData) => {
    if (typeof aptrinsic !== 'function') {
        console.error('aptrinsic object should be initialized by now!');
        return;
    }

    const identity = selectLoggableIdentityFromUserData(userData);

    if (identity.user && identity.user.id) {
        aptrinsic('identify', identity.user, identity.account);
    }
};

export const initialiseGainsight = (userData) => {
    // Gainsight is only enabled for certain user roles, and the backend sends the value
    // userData?.gainsight?.enabled to tell us whether to enable or not
    if (
        userData?.gainsight?.enabled &&
        typeof userData?.gainsight?.key !== 'undefined'
    ) {
        try {
            addGainsightScript(userData?.gainsight?.key);
            logUserIdentity(userData);
        } catch (error) {
            console.error('Error when setting up gainsight:', error);
        }
    }
};

export const logEventToGainsight = (eventName: string, data: unknown) => {
    if (typeof aptrinsic === 'function') {
        aptrinsic('track', eventName, data);
    } else if (ExternalConfig.getConfig().debugEnvironment) {
        // In dev mode we don't have Gainsight set up. These logs are added
        // to make it easy to test in dev mode whether an event would get sent
        // to Gainsight or not
        // eslint-disable-next-line no-console
        console.info('Gainsight dev log:', eventName, data);
    }
};
