export const memoize = <T extends (...args: unknown[]) => unknown>(
    f: T,
): {
    (...args: Parameters<T>): ReturnType<T>;
    clear: () => void;
} => {
    const cache = {};

    const memoizedFunction = (...args) => {
        const key = JSON.stringify(args);
        if (typeof cache[key] === 'undefined') {
            cache[key] = f(...args);
        }

        return cache[key];
    };

    memoizedFunction.clear = () => {
        Object.keys(cache).forEach((key) => {
            delete cache[key];
        });
    };

    return memoizedFunction;
};
