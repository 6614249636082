export function registerItem(item) {
    return {
        type: 'BASKET::REGISTER_ITEM',
        item,
    };
}

export function selectItem(id, lastInBundle) {
    return {
        type: 'BASKET::SELECT_ITEM',
        id,
        lastInBundle,
    };
}

export function updatePrice(price) {
    return {
        type: 'CHECKOUT::UPDATE_PRICE',
        price,
    };
}

export function deselectItem(id) {
    return {
        type: 'BASKET::DESELECT_ITEM',
        id,
    };
}

export function clearBasket() {
    return {
        type: 'BASKET::CLEAR_BASKET',
    };
}

export function upgradeToBundle(id) {
    return {
        type: 'BASKET::UPGRADE_TO_BUNDLE',
        bundleId: id,
    };
}
