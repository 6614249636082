import {TableHeaderButton} from 'Components/sharedTableSubComponents/tableHeaderButton';

interface IFullScreenButtonProps {
    isFullScreen: boolean;
    setIsFullScreen: (newValue: boolean) => void;
}

const ExpandIcon = () => (
    <svg width="16px" height="16px" viewBox="0 0 242.133 242.133" aria-hidden>
        <path d="M227.133,83.033c8.283,0,15-6.716,15-15V15c0-8.284-6.717-15-15-15H174.1c-8.284,0-15,6.716-15,15  s6.716,15,15,15h16.82l-69.854,69.854L51.213,30h16.82c8.284,0,15-6.716,15-15s-6.716-15-15-15H15C6.717,0,0,6.716,0,15v53.033  c0,8.284,6.717,15,15,15c8.285,0,15-6.716,15-15v-16.82l69.854,69.854L30,190.92V174.1c0-8.284-6.715-15-15-15  c-8.283,0-15,6.716-15,15v53.033c0,8.284,6.717,15,15,15h53.033c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15h-16.82  l69.854-69.854l69.854,69.854H174.1c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h53.033c8.283,0,15-6.716,15-15V174.1  c0-8.284-6.717-15-15-15c-8.285,0-15,6.716-15,15v16.82l-69.854-69.854l69.854-69.854v16.82  C212.133,76.317,218.848,83.033,227.133,83.033z" />
    </svg>
);

const CollapseIcon = () => (
    <svg width="18px" height="18px" viewBox="3 3 18 18" aria-hidden>
        <path d="M19 9h-2.58l3.29-3.29a1 1 0 1 0-1.42-1.42L15 7.57V5a1 1 0 0 0-1-1 1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2z" />
        <path d="M10 13H5a1 1 0 0 0 0 2h2.57l-3.28 3.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L9 16.42V19a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1z" />
    </svg>
);

export const FullScreenButton = ({
    isFullScreen,
    setIsFullScreen,
}: IFullScreenButtonProps) => (
    <TableHeaderButton
        onClick={() => {
            setIsFullScreen(!isFullScreen);
        }}
        focusOnClick
        aria-label={
            isFullScreen ? 'Exit full-screen mode' : 'Enter full-screen mode'
        }
        tooltip={
            isFullScreen ? 'Exit full-screen mode' : 'Enter full-screen mode'
        }
    >
        {isFullScreen ? <CollapseIcon /> : <ExpandIcon />}
    </TableHeaderButton>
);
