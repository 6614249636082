import {forwardRef} from 'react';

import {Button, IButtonProps} from 'Components/button/base';
import {DropdownContainer} from 'Components/dropdown';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import buttonWrapper from 'Wrappers/Button';

const WrappedButton = buttonWrapper(Button);

import './dropdownButton.scss';

export interface IDropdownButtonItem {
    text?: string;
    url: string;
    icon?: string;
    role?: 'load-page' | 'download-file';
    disabled: boolean;
}

export interface IDropdownButtonItemSeparator {
    isDropdownButtonItemSeparator: true;
}

function isItemSeparator(
    item: IDropdownButtonItem | IDropdownButtonItemSeparator,
): item is IDropdownButtonItemSeparator {
    return (
        'isDropdownButtonItemSeparator' in item &&
        item.isDropdownButtonItemSeparator === true
    );
}

interface IDropdownButtonProps extends IButtonProps {
    menu: (IDropdownButtonItem | IDropdownButtonItemSeparator)[];
    onItemClick: (item: IDropdownButtonItem) => void;
}

export const DropdownButton = forwardRef<
    HTMLButtonElement,
    IDropdownButtonProps
>(({menu, onItemClick, ...others}, ref) => {
    return (
        <DropdownContainer
            containerClassName="dropdown-button__container"
            renderDropdownField={({
                toggleDropdown,
                isDropdownVisible,
                toggleDropdownOnEnterOrSpace,
            }) => (
                <WrappedButton
                    {...others}
                    onClick={toggleDropdown}
                    onKeyDown={toggleDropdownOnEnterOrSpace}
                    iconRight={
                        isDropdownVisible ? 'arrow-go-up' : 'arrow-go-down'
                    }
                    className="dropdown-button__button"
                    setRef={ref}
                />
            )}
            renderDropdownContents={({closeDropdown}) => (
                <ul className="dropdown-button__contents">
                    {menu.map((item, index) => (
                        <li key={index}>
                            {isItemSeparator(item) ? (
                                <div className="dropdown-button__separator" />
                            ) : (
                                <KeyboardFocusableButton
                                    buttonWrapperClassName="dropdown-button-item__wrapper"
                                    onClick={() => {
                                        onItemClick(item);
                                        closeDropdown();
                                    }}
                                    tabIndex={-1}
                                    insetFocusRing
                                    disabled={item.disabled}
                                >
                                    {item.text}
                                </KeyboardFocusableButton>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        />
    );
});
