import {getMinColumnWidthForComponent} from './getMinColumnWidthForComponent';

const defaultMinColumnWidth = 300;

const findMinColumnWidthInSubTree = (acc, current) => {
    const childWidths =
        current.content && current.content.reduce
            ? current.content.reduce(findMinColumnWidthInSubTree, 0)
            : 0;
    return Math.max(
        acc,
        childWidths,
        getMinColumnWidthForComponent(current.componentName),
    );
};

export const findMinColumnWidth = (content) => {
    return content.reduce(findMinColumnWidthInSubTree, defaultMinColumnWidth);
};
