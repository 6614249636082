import {useRef} from 'react';

import {Icon} from 'Components/icon';
import {useSimpleTooltip} from 'Components/tooltip';

type PromptControls = {
    progressMessageDrawerVisible: boolean;
    setProgressMessageDrawerVisible: (visible: boolean) => void;
};
export const ProgressMessageControls = ({
    progressMessageDrawerVisible,
    setProgressMessageDrawerVisible,
}: PromptControls) => {
    const downArrowRef = useRef<HTMLSpanElement>(null);
    const rightArrowRef = useRef<HTMLSpanElement>(null);
    const {tooltipEventHandlers: downArrowEventHandlers} = useSimpleTooltip({
        tooltip: 'Hide thinking',
        elementRef: downArrowRef,
        preferPosition: 'bottom',
    });
    const {tooltipEventHandlers: rightArrowEventHandlers} = useSimpleTooltip({
        tooltip: 'Show thinking',
        elementRef: rightArrowRef,
        preferPosition: 'bottom',
    });

    return (
        <>
            {progressMessageDrawerVisible ? (
                <Icon
                    setRef={downArrowRef}
                    {...downArrowEventHandlers}
                    iconName="down"
                    className="canvas__header__placeholder-show-hide-progress-icon"
                    onClick={() => setProgressMessageDrawerVisible(false)}
                />
            ) : (
                <Icon
                    setRef={rightArrowRef}
                    {...rightArrowEventHandlers}
                    iconName="right"
                    className="canvas__header__placeholder-show-hide-progress-icon"
                    onClick={() => setProgressMessageDrawerVisible(true)}
                />
            )}
        </>
    );
};
