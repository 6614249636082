export const parseUnsafeInt = (
    unsafeInt: number | string | undefined,
    defaultValue: number | undefined = 0,
) => {
    if (typeof unsafeInt === 'number' && unsafeInt > 0) {
        return unsafeInt;
    }
    if (typeof unsafeInt === 'string') {
        const parsedString = parseInt(unsafeInt, 10);
        if (!isNaN(parsedString) && parsedString > 0) {
            return parsedString;
        }
    }

    return defaultValue;
};
