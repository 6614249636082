import {ComboboxValue} from 'Components/combobox';

import {BackendComboboxValueItem} from './Combobox';

export const transformBackendValues = (
    backendValues:
        | (ComboboxValue | BackendComboboxValueItem)[]
        | ComboboxValue
        | undefined,
) => {
    if (typeof backendValues === 'undefined' || backendValues === '[]') {
        return [];
    }
    if (
        typeof backendValues === 'string' ||
        typeof backendValues === 'number'
    ) {
        return [backendValues];
    }
    return backendValues?.map((item) => {
        if (typeof item === 'string' || typeof item === 'number') {
            return item;
        }
        return item.value ?? ''; // check if this fallback case makes sense
    });
};
