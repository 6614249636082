import {SECTION_SELECTOR} from 'Config/Events';
import pubsub from 'Services/pubsub/Pubsub';

import {exportInterfaceSingleton} from './Base';

class SectionSelector {
    reload() {
        pubsub.publish(SECTION_SELECTOR.RELOAD);
    }
}

export const registerSectionSelectorInterface = () => {
    exportInterfaceSingleton('SectionSelector', new SectionSelector());
};
