import classNames from 'classnames';
import React, {useState, useEffect} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import {useAutoFillRequest} from '../useAutoFillRequest';

type CheckboxProps = {
    keyName: string;
    label: string;
    checkBoxState: 'checked' | 'unchecked' | 'partial';
    optionValue: string;
};

type Option = {
    keyName: string;
    label: string;
    isChecked: boolean;
    optionValue: string;
};

type AutoFillOptions = {
    [key: string]: {
        checked: boolean;
    };
};

type StepOneScreenProps = {
    defaultOptions: Option[];
    otherOptions: Option[];
    autoFillOptions: AutoFillOptions;
    setAutoFillOptions: (options: AutoFillOptions) => void;
    autoFillUrl: string;
    userInputTextAreaRef: React.RefObject<HTMLTextAreaElement>;
    userInputContent: string;
    setUserInputContent: (content: string) => void;
    showToolGuideLink?: {
        url: string;
        display: boolean;
    };
};

export const StepOneScreen = ({
    defaultOptions,
    otherOptions,
    autoFillOptions,
    setAutoFillOptions,
    autoFillUrl,
    userInputTextAreaRef,
    userInputContent,
    setUserInputContent,
    showToolGuideLink,
}: StepOneScreenProps) => {
    const icons = {
        checked: 'checkbox-checked',
        unchecked: 'checkbox-unchecked',
        partial: 'minus',
    };
    const [isOpen, setIsOpen] = useState(false);
    const toggleSection = () => {
        setIsOpen((prev) => !prev);
    };

    const {url, display} = showToolGuideLink || {};

    const [localAutoFillOptions, setLocalAutoFillOptions] =
        useState(autoFillOptions);

    const {submitFunction} = useAutoFillRequest({autoFillOptions, autoFillUrl});

    const Checkbox = ({keyName, label, checkBoxState}: CheckboxProps) => {
        const handleClick = () => {
            setLocalAutoFillOptions((prevStates) => {
                if (!prevStates[keyName]) {
                    console.error(
                        `Key "${keyName}" not found in autoFillOptions.`,
                    );
                    return prevStates;
                }
                return {
                    ...prevStates,
                    [keyName]: {
                        ...prevStates[keyName],
                        checked: !prevStates[keyName].checked,
                    },
                };
            });
        };

        return (
            <label className="auto-fill-modal__user-preference">
                <span
                    onClick={handleClick}
                    className="auto-fill-modal_checkbox-icon"
                >
                    <Icon iconName={icons[checkBoxState]} />
                </span>
                {label}
            </label>
        );
    };

    const toggleSelectAll = (
        checkBoxState: 'checked' | 'unchecked',
        optionType: 'default' | 'other',
    ) => {
        setLocalAutoFillOptions((prevStates) => {
            const optionsToUpdate =
                optionType === 'default' ? defaultOptions : otherOptions;
            const newCheckedState = checkBoxState === 'checked';
            return optionsToUpdate.reduce(
                (acc, {keyName}) => {
                    acc[keyName] = {
                        ...prevStates[keyName],
                        checked: newCheckedState,
                    };
                    return acc;
                },
                {...prevStates},
            );
        });
    };

    const getCheckboxOptionsIcon = (
        checkboxOptions: {
            keyName: string;
            label: string;
            isChecked: boolean;
            optionValue: string;
        }[],
    ) => {
        const allChecked = Object.values(checkboxOptions).every(
            (option) => option.isChecked,
        );
        const noneChecked = Object.values(checkboxOptions).every(
            (option) => !option.isChecked,
        );

        if (allChecked) return icons.checked;
        if (noneChecked) return icons.unchecked;
        return icons.partial;
    };

    useEffect(() => {
        setAutoFillOptions(localAutoFillOptions);
    }, [localAutoFillOptions]);

    return (
        <>
            <div className="auto-fill-modal__user-input">
                {(defaultOptions.length > 0 || otherOptions.length > 0) && (
                    <>
                        <div
                            className="collapsible-header"
                            onClick={toggleSection}
                        >
                            <span>What do you want to include?</span>
                            <span
                                className={classNames('toggle-icon', {
                                    open: isOpen,
                                })}
                            ></span>
                        </div>
                    </>
                )}
                {isOpen && (
                    <div className="collapsible-content">
                        <span className="auto-fill-modal__user-input-container">
                            {defaultOptions.length > 0 && (
                                <>
                                    <span>Default information, such as:</span>
                                    <div className="checkbox-grid">
                                        <label className="auto-fill-modal_select-deselect-all">
                                            <span
                                                className={`auto-fill-modal_checkbox-icon ${
                                                    getCheckboxOptionsIcon(
                                                        defaultOptions,
                                                    ) === 'minus'
                                                        ? 'auto-fill-modal_checkbox-icon-partial'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    const isAllSelected =
                                                        defaultOptions.every(
                                                            (option) =>
                                                                option.isChecked,
                                                        );
                                                    toggleSelectAll(
                                                        isAllSelected
                                                            ? 'unchecked'
                                                            : 'checked',
                                                        'default',
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    iconName={getCheckboxOptionsIcon(
                                                        defaultOptions,
                                                    )}
                                                />
                                            </span>
                                            Select/deselect all
                                        </label>

                                        {defaultOptions.map(
                                            (formOption, index) => (
                                                <div
                                                    key={formOption.keyName}
                                                    className="checkbox-grid-item"
                                                    style={{
                                                        gridColumn:
                                                            (index % 2) + 2, // Alternates between columns 2 and 3
                                                    }}
                                                >
                                                    <Checkbox
                                                        keyName={
                                                            formOption.keyName
                                                        }
                                                        label={formOption.label}
                                                        checkBoxState={
                                                            localAutoFillOptions[
                                                                formOption.label
                                                            ]?.checked
                                                                ? 'checked'
                                                                : 'unchecked'
                                                        }
                                                        optionValue={
                                                            formOption.optionValue
                                                        }
                                                    />
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                            {otherOptions.length > 0 && (
                                <>
                                    <span>
                                        Contextual information, such as:
                                    </span>
                                    <div className="checkbox-grid">
                                        <label className="auto-fill-modal_select-deselect-all">
                                            <span
                                                className={`auto-fill-modal_checkbox-icon ${
                                                    getCheckboxOptionsIcon(
                                                        otherOptions,
                                                    ) === 'minus'
                                                        ? 'auto-fill-modal_checkbox-icon-partial'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    const isAllSelected =
                                                        otherOptions.every(
                                                            (option) =>
                                                                option.isChecked,
                                                        );
                                                    toggleSelectAll(
                                                        isAllSelected
                                                            ? 'unchecked'
                                                            : 'checked',
                                                        'other',
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    iconName={getCheckboxOptionsIcon(
                                                        otherOptions,
                                                    )}
                                                />
                                            </span>
                                            Select/deselect all
                                        </label>

                                        {otherOptions.map(
                                            (formOption, index) => (
                                                <div
                                                    key={formOption.keyName}
                                                    className="checkbox-grid-item"
                                                    style={{
                                                        gridColumn:
                                                            (index % 2) + 2, // Alternates between columns 2 and 3
                                                    }}
                                                >
                                                    <Checkbox
                                                        keyName={
                                                            formOption.keyName
                                                        }
                                                        label={formOption.label}
                                                        checkBoxState={
                                                            localAutoFillOptions[
                                                                formOption.label
                                                            ]?.checked
                                                                ? 'checked'
                                                                : 'unchecked'
                                                        }
                                                        optionValue={
                                                            formOption.optionValue
                                                        }
                                                    />
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </span>
                    </div>
                )}

                {display ? (
                    <span className="auto-fill-modal__user-input-label">
                        Summarise what you want to say (You can edit the tone
                        guide{' '}
                        <KeyboardFocusableLink url={url} target="_blank">
                            here
                        </KeyboardFocusableLink>
                        )
                    </span>
                ) : (
                    <span className="auto-fill-modal__user-input-label">
                        Summarise what you want to say
                    </span>
                )}
                <textarea
                    ref={userInputTextAreaRef}
                    className="auto-fill-modal__textarea"
                    rows={5}
                    value={userInputContent}
                    placeholder=""
                    onChange={(e) => setUserInputContent(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            submitFunction(userInputContent);
                        }
                    }}
                />
            </div>
        </>
    );
};
