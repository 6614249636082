import classnames from 'classnames';
import PropTypes from 'prop-types';

import {FormAction, FormActionButton} from 'Components/button/formActionButton';

import {IButtonProps} from '../base';

type DeleteButtonProps = IButtonProps & {
    role: string;
    currentAction: FormAction;
};
export function DeleteButton(props: DeleteButtonProps) {
    const {currentAction, className, ...rest} = props;

    return (
        <FormActionButton
            text="Delete"
            color="red"
            currentAction={currentAction}
            className={classnames(
                'arbor-btn-left-align delete-button',
                className,
            )}
            {...rest}
        />
    );
}

DeleteButton.propTypes = {
    currentAction: PropTypes.object,
    className: PropTypes.string,
};
