import {useRef, useState} from 'react';

import {httpPost} from 'Interfaces/httpClient';
import {appendFormatJson} from 'Interfaces/Url';

export const useAutoFillRequest = ({autoFillUrl, autoFillOptions}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [generatedContent, setGeneratedContent] = useState('');
    const signalRef = useRef<AbortController | null>();

    const submitFunction = async (userContent?: string) => {
        setIsLoading(true);
        setError('');
        setGeneratedContent('');

        let controller: undefined | AbortController;
        if (typeof AbortController !== 'undefined') {
            controller = new AbortController();
            signalRef.current = controller;
        }
        try {
            const response = await httpPost(
                appendFormatJson(autoFillUrl),
                {
                    fields: {
                        autoFillOptions: {value: autoFillOptions},
                        userContent: {value: userContent},
                    },
                },
                {
                    ...(signalRef.current
                        ? {signal: signalRef.current.signal}
                        : {}),
                },
            );
            if (typeof response === 'undefined') {
                // When you cancel a request in the UI, the response will be undefined,
                // and we don't need to show an error message
                return;
            }
            if (
                typeof response.items?.[0]?.fields?.autoFillContent?.value ===
                'string'
            ) {
                setGeneratedContent(
                    response.items[0].fields.autoFillContent.value,
                );
            }
        } catch (e) {
            console.error('Error getting autoFill data', e, {
                autoFillUrl,
                autoFillOptions,
                userContent,
            });
            setError('Error generating text');
        } finally {
            setIsLoading(false);
        }
    };
    const cancelFunction = () => {
        signalRef.current?.abort();
    };

    return {
        submitFunction,
        cancelFunction,
        setGeneratedContent,
        isLoading,
        error,
        generatedContent,
    };
};
