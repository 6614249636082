import {useState} from 'react';

import {httpPost} from 'Interfaces/httpClient';

export const useRefetchFormValue = (
    url?: string,
    refetchedCallback?: (newValue: unknown) => void,
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newFormValue, setNewFormValue] = useState<unknown>(null);
    const [error, setError] = useState('');

    const refetchFormValue = async ({
        dependeeFormFieldValues,
        successCallback,
        failureCallback,
    }: {
        dependeeFormFieldValues: Record<string, unknown>;
        successCallback: () => void;
        failureCallback: () => void;
    }) => {
        if (typeof url === 'undefined') {
            console.error('refetchFormValue, url === "undefined"');
            return;
        }
        const params = {
            'parent-combo-values': dependeeFormFieldValues,
            // It's a bit weird that we send this instead of just parent-combo-values, however
            // it is definietely used a bunch in SIS.
            'parent-combo-value':
                dependeeFormFieldValues[
                    Object.keys(dependeeFormFieldValues)[0]
                ],
        };
        try {
            setIsLoading(true);
            const response = await httpPost(url, params);
            const newValue = response?.items?.[0]?.value ?? null;
            setNewFormValue(newValue);
            if (typeof refetchedCallback === 'function') {
                // To be used by the parent component
                refetchedCallback(newValue);
            }
            if (typeof successCallback === 'function') {
                // Used by the FormField service
                successCallback();
            }
        } catch (e) {
            console.error(e);
            setError(
                `Something went wrong fetching new form value from: ${url}.`,
            );
            if (typeof failureCallback === 'function') {
                // Used by the FormField service
                failureCallback();
            }
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        refetchFormValue,
        error,
        newFormValue,
    };
};
