import exportInterface from './Base';

export function maskMain(message?: string) {
    const loadingSpinner = document.querySelector('.loading-spinner');
    if (!loadingSpinner) {
        console.error('HTML for loading spinner not found.');
        return;
    }
    loadingSpinner.classList.remove('loading-spinner--hide');
    const loadingSpinnerText = loadingSpinner.querySelector(
        '.loading-spinner__text',
    );
    if (loadingSpinnerText) {
        loadingSpinnerText.innerHTML = message ?? 'Loading...';
    }
}

export function unmaskMain() {
    const loadingSpinner = document.querySelector('.loading-spinner');
    if (!loadingSpinner) {
        console.error('HTML for loading spinner not found.');
        return;
    }
    loadingSpinner.classList.add('loading-spinner--hide');
}

export const registerMaskInterface = () => {
    exportInterface('Mask', {maskMain, unmaskMain});
};
