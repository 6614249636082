export const SearchIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        role="presentation"
    >
        <path
            fill="#3C3735"
            fillRule="evenodd"
            d="M15.642 17.589c.25.259.606.411.974.411.758 0 1.386-.628 1.384-1.384 0-.366-.152-.724-.4-.972l-3.71-3.711c.876-1.268 1.34-2.782 1.34-4.318C15.23 3.41 11.825 0 7.616 0 3.407 0 0 3.41 0 7.615c0 4.207 3.407 7.616 7.615 7.616 1.538 0 3.052-.465 4.318-1.343l3.71 3.7zM2.77 7.614c0-2.67 2.174-4.846 4.846-4.846 2.673 0 4.848 2.173 4.848 4.846 0 2.672-2.175 4.848-4.848 4.848-2.672 0-4.846-2.177-4.846-4.848z"
            clipRule="evenodd"
        />
    </svg>
);
