import {useComponentDidMount} from 'Utils/useComponentDidMount';

import Pubsub from './Pubsub';
import {AnyFunction} from './types';

export const usePubSub = (eventName: string, handlerFunction: AnyFunction) => {
    useComponentDidMount(() => {
        const subscriptionId = Pubsub.subscribe(eventName, handlerFunction);
        return () => {
            Pubsub.unsubscribe(eventName, subscriptionId);
        };
    });
};
