import {connect} from 'react-redux';

import {Favourites} from 'BackendRenderedComponents/favourites';
import {DropdownContainer} from 'Components/dropdown';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';
import {selectIsFavouritesEnabled} from 'State/navigation/selectors';

import {SideBarButton} from '../sideBarButton';

import {FavouritesIcon} from './FavouritesIcon';

type UnconnectedFavouritesButtonProps = {
    isDesktop?: boolean;
    isFavouritesEnabled?: ReturnType<typeof selectIsFavouritesEnabled>;
};

const UnconnectedFavouritesButton = ({
    isDesktop,
    isFavouritesEnabled,
}: UnconnectedFavouritesButtonProps) => {
    if (!isFavouritesEnabled) {
        return null;
    }

    return (
        <DropdownContainer
            renderDropdownField={({toggleDropdown, isDropdownVisible}) => (
                <SideBarButton
                    onClick={() => {
                        toggleDropdown();
                        logEventToGainsight('FavouritesSideBarButtonClicked', {
                            clickedFrom: getCurrentPageFromUrl(),
                        });
                    }}
                    buttonText="Favourites"
                    tooltip="Quick access to saved pages"
                    preferTooltipPosition={isDesktop ? 'right' : 'bottom'}
                    isActive={isDropdownVisible}
                >
                    <FavouritesIcon />
                </SideBarButton>
            )}
            renderDropdownContents={() => (
                <Favourites widgetLocation={isDesktop ? 'SideBar' : 'Header'} />
            )}
            dropdownWidth="320px"
            preferPosition={isDesktop ? 'right' : 'bottom'}
            alignDropdown="end"
            offset={0}
        />
    );
};

const mapStateToProps = (state) => ({
    isFavouritesEnabled: selectIsFavouritesEnabled(state),
});

export const FavouritesButton = connect(mapStateToProps)(
    UnconnectedFavouritesButton,
);
