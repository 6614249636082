import PropTypes from 'prop-types';

import {Button} from 'Components/button/base';
import {httpGet} from 'Interfaces/httpClient';
import {loadCurrentPage, loadPage} from 'Interfaces/Loader';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

function RequestButton(props) {
    const handleClick = () => {
        const {pageUrl} = props;

        if (!pageUrl) {
            console.error('No url provided to RequestButton!');
            return;
        }

        maskMain(_t('Loading...'));

        httpGet(pageUrl)
            .then((response) => {
                if (response?.action_params?.reloadPage) {
                    loadCurrentPage(true);
                }

                let url;
                if (response && response.action_params) {
                    url = response.action_params.loadPage;
                }

                if (url) {
                    loadPage(url);
                }
            })
            .catch(() => {
                unmaskMain();
            });
    };

    return <WrappedButton {...props} onClick={handleClick} />;
}

RequestButton.propTypes = {
    pageUrl: PropTypes.string,
};

export default RequestButton;
