import {TransformedNavigationItem} from './types';

export const createExpandedIdsFromTreeData = (
    staticTreeData: TransformedNavigationItem[],
    currentRouteId: string | null | undefined,
) => {
    const expandedIds: Record<string, boolean> = {};

    if (!currentRouteId) {
        return expandedIds;
    }

    const findExpandedIdsInTree = (
        treeData: TransformedNavigationItem[],
        currentPageId: string,
    ) => {
        return treeData.filter((item) => {
            if (currentPageId.includes(item.id) || item.initiallyExpanded) {
                expandedIds[item.id] = true;
                if (item.items) {
                    findExpandedIdsInTree(item.items, currentPageId);
                }
                if (item.subTree) {
                    expandedIds[item.subTree.id] = true;
                    findExpandedIdsInTree(item.subTree.items, currentPageId);
                }
                return true;
            }
            return false;
        });
    };
    findExpandedIdsInTree(staticTreeData, currentRouteId);
    return expandedIds;
};
