interface IHideableTooltip {
    hideForKeyboard: () => void;
}

function isHideableTooltip(tooltip: unknown): tooltip is IHideableTooltip {
    return (
        tooltip !== null &&
        typeof tooltip === 'object' &&
        'hideForKeyboard' in tooltip &&
        typeof tooltip.hideForKeyboard === 'function'
    );
}

export const closeAllExtTooltips = () => {
    const tooltips = Ext.ComponentQuery.query('mis-tooltip');
    tooltips.forEach((tooltip) => {
        if (isHideableTooltip(tooltip)) {
            tooltip.hideForKeyboard();
        }
    });
};
