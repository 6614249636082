import {INotification} from 'Services/userNotifications/UserNotification';

import {NotificationDropdownItem} from './NotificationDropdownItem';

import './notificationPreview.scss';

type NotificationPreviewProps = {
    notifications: INotification[];
    onNotificationClick: (id: number) => void;
    hideNotification: (id: number) => void;
};

export const NotificationPreview = ({
    notifications,
    onNotificationClick,
    hideNotification,
}: NotificationPreviewProps) => {
    if (notifications.length > 0) {
        return (
            <div className="notification-preview__container">
                <ul className="notification-preview__items-container">
                    {notifications.map((notification) => (
                        <NotificationDropdownItem
                            key={notification.userNotificationId}
                            notification={notification}
                            onClick={() =>
                                onNotificationClick(
                                    notification.userNotificationId,
                                )
                            }
                            onDismiss={() =>
                                onNotificationClick(
                                    notification.userNotificationId,
                                )
                            }
                            hideNotification={() =>
                                hideNotification(
                                    notification.userNotificationId,
                                )
                            }
                        />
                    ))}
                </ul>
            </div>
        );
    }

    return null;
};
