import PropTypes from 'prop-types';

export const Intervention = ({
    style = {},
    width = '100%',
    className = '',
    viewBox = '0 0 16 16',
}) => (
    <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M13.914 9l.493.084.1-.584h-.593V9zM9 13.914h-.5v.593l.584-.1L9 13.914zm-2 0l-.084.493.584.1v-.593H7zM2.086 9v-.5h-.593l.1.584L2.086 9zm0-2l-.493-.084-.1.584h.593V7zM7 2.086h.5v-.593l-.584.1.084.493zm2 0l.084-.493-.584-.1v.593H9zM13.914 7v.5h.593l-.1-.584-.493.084zM8 16.5c4.695 0 8.5-3.805 8.5-8.5h-1c0 4.143-3.357 7.5-7.5 7.5v1zM-.5 8c0 4.695 3.805 8.5 8.5 8.5v-1C3.857 15.5.5 12.143.5 8h-1zM8-.5C3.305-.5-.5 3.305-.5 8h1C.5 3.857 3.857.5 8 .5v-1zM16.5 8c0-4.695-3.805-8.5-8.5-8.5v1c4.143 0 7.5 3.357 7.5 7.5h1zM8 10c1.105 0 2-.895 2-2H9c0 .552-.448 1-1 1v1zM6 8c0 1.105.895 2 2 2V9c-.552 0-1-.448-1-1H6zm2-2c-1.105 0-2 .895-2 2h1c0-.552.448-1 1-1V6zm2 2c0-1.105-.895-2-2-2v1c.552 0 1 .448 1 1h1zm2 .5v-1h-1v1h1zm0 0h-1c0 .552.448 1 1 1v-1zm1.914 0H12v1h1.914v-1zm-4.83 5.907c2.725-.464 4.859-2.598 5.323-5.323l-.986-.168c-.393 2.307-2.198 4.112-4.505 4.505l.168.986zM8.5 12v1.914h1V12h-1zm0 0h1c0-.552-.448-1-1-1v1zm-1 0h1v-1h-1v1zm0 0v-1c-.552 0-1 .448-1 1h1zm0 1.914V12h-1v1.914h1zm-5.907-4.83c.464 2.725 2.598 4.859 5.323 5.323l.168-.986c-2.307-.393-4.112-2.198-4.505-4.505l-.986.168zM4 8.5H2.086v1H4v-1zm0 0v1c.552 0 1-.448 1-1H4zm0-1v1h1v-1H4zm0 0h1c0-.552-.448-1-1-1v1zm-1.914 0H4v-1H2.086v1zm4.83-5.907c-2.725.464-4.859 2.598-5.323 5.323l.986.168c.393-2.307 2.198-4.112 4.505-4.505l-.168-.986zM7.5 4V2.086h-1V4h1zm0 0h-1c0 .552.448 1 1 1V4zm1 0h-1v1h1V4zm0 0v1c.552 0 1-.448 1-1h-1zm0-1.914V4h1V2.086h-1zm5.907 4.83c-.464-2.725-2.598-4.859-5.323-5.323l-.168.986c2.307.393 4.112 2.198 4.505 4.505l.986-.168zM12 7.5h1.914v-1H12v1zm0 0v-1c-.552 0-1 .448-1 1h1z" />
    </svg>
);

Intervention.propTypes = {
    style: PropTypes.object,
    width: PropTypes.string,
    className: PropTypes.string,
    viewBox: PropTypes.string,
};
