import PropTypes from 'prop-types';

import {Button} from 'Components/button/base';
import buttonWrapperExt from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapperExt(Button);

function SystemModalButton(props) {
    const {systemModal, ...rest} = props;
    const decoded = systemModal ? JSON.parse(systemModal) : {};
    const handleClick = () => {
        if (decoded.errorType === 'user') {
            Arbor.Interfaces.Error.raiseUserError(decoded.message);
        } else if (decoded.errorType === 'error') {
            Arbor.Interfaces.Error.raiseError(decoded.title, decoded.message);
        } else if (decoded.errorType === 'dev') {
            Arbor.Interfaces.Error.raiseDevError({...decoded});
        }
    };

    return (
        <WrappedButton
            className="arbor-system-modal-button"
            {...rest}
            onClick={handleClick}
        />
    );
}

SystemModalButton.propTypes = {
    systemModal: PropTypes.string.isRequired,
};

export default SystemModalButton;
