// For absolute urls the important character is :, as this is used to seperate
// the scheme from the rest of the url. i.e. http://www.example.com
// https://datatracker.ietf.org/doc/html/rfc3986#section-4.3
// Instead of blacklisting this, we take a more cautious whitelist-based approach.
// If we find any cases where this is insufficient we should rethink this logic
export const isUrlPartSuspicious = (urlPart: string) =>
    /[^\w\-\_.]/g.test(urlPart);

export const isAbsoluteUrl = (inputUrl: string) => {
    if (inputUrl.startsWith('//')) {
        // Test for "Network-path reference" style absolute urls
        // https://datatracker.ietf.org/doc/html/rfc3986#section-4.3
        return true;
    }

    // Remove leading slash
    const trimmedUrl = inputUrl.startsWith('/')
        ? inputUrl.substring(1)
        : inputUrl;

    const substrings = trimmedUrl.split('/');

    return isUrlPartSuspicious(substrings[0]);
};
