import {useState} from 'react';

import {JiraFeedbackModal} from 'App/header/buttons/jiraFeedback/JiraFeedbackModal';
import {SideBarButton} from 'App/header/buttons/sideBarButton';
import {Icon} from 'Components/icon';
import {getUser} from 'Interfaces/Session';

export const JiraFeedbackButton = () => {
    const {isParentPortalOrStudentPortal} = getUser() || {};
    const [showModal, setShowModal] = useState(false);

    if (isParentPortalOrStudentPortal) {
        return null;
    }

    return (
        <>
            <SideBarButton
                tooltip="Help us make Arbor even better"
                buttonText="Share product idea"
                onClick={() => setShowModal(true)}
                className="side-bar-button--feedback"
            >
                <Icon iconName="lucide-lightbulb" />
            </SideBarButton>
            {showModal && (
                <JiraFeedbackModal closeModal={() => setShowModal(false)} />
            )}
        </>
    );
};
