interface IClosableWindow {
    close: () => void;
}

function isClosableWindow(window: unknown): window is IClosableWindow {
    return (
        window !== null &&
        typeof window === 'object' &&
        'close' in window &&
        typeof window.close === 'function'
    );
}

export const closeAllExtWindows = () => {
    const windows = Ext.ComponentQuery.query('mis-window');
    windows.forEach((window) => {
        if (isClosableWindow(window)) {
            window.close();
        }
    });
};
