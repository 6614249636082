import classnames from 'classnames';
import PropTypes from 'prop-types';

import {LoadingDots} from 'Components/animations';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import Loader from 'Services/Loader';

import {NotificationItem} from './NotificationItem';

import './notificationPanel.scss';

const LoadingSkeleton = () => (
    <div className="dashboard-notification-panel-content">
        <div className="dashboard-notification-panel-item-wrapper">
            <div className="dashboard-notification-panel-loading-bar"></div>
        </div>
        <div className="dashboard-notification-panel-item-wrapper">
            <div className="dashboard-notification-panel-loading-bar"></div>
        </div>
        <div
            className={classnames(
                'dashboard-notification-panel-item-wrapper',
                'dashboard-notification-panel-item-wrapper__end-position',
            )}
        >
            <div className="dashboard-notification-panel-loading-bar"></div>
        </div>
    </div>
);

const NUMBER_OF_NOTIFICATIONS_PER_PANEL = 3;

export function NotificationPanel({
    title,
    total,
    items,
    isLoading,
    onDismiss: handleDismiss,
    redirectUrl,
    emptyState,
}) {
    const renderContent = () => {
        if (isLoading) {
            return <LoadingSkeleton />;
        } else if (!items || items.length === 0) {
            return emptyState;
        }
        return (
            <div className="dashboard-notification-panel-content">
                {items
                    .slice(0, NUMBER_OF_NOTIFICATIONS_PER_PANEL)
                    .map(
                        (
                            {
                                id,
                                title: itemTitle,
                                createdAt,
                                url,
                                type: iconType,
                            },
                            index,
                        ) => (
                            <div
                                key={`item-${index}-${id}`}
                                className={classnames(
                                    'dashboard-notification-panel-item-wrapper',
                                    {
                                        'dashboard-notification-panel-item-wrapper__end-position':
                                            index ===
                                            NUMBER_OF_NOTIFICATIONS_PER_PANEL -
                                                1,
                                    },
                                )}
                            >
                                <NotificationItem
                                    id={id}
                                    url={url}
                                    title={itemTitle}
                                    subtitle={createdAt}
                                    iconType={iconType}
                                    onDismiss={handleDismiss}
                                />
                            </div>
                        ),
                    )}
            </div>
        );
    };

    const handleHeaderClicked = () => {
        if (redirectUrl) {
            Loader.loadPage(redirectUrl);
        }
    };

    return (
        <section
            aria-label={`Dashboard Notification Panel: ${title}`}
            className="dashboard-notification-panel-container"
        >
            <KeyboardFocusableButton
                className="dashboard-notification-panel-header"
                buttonWrapperClassName="dashboard-notification-panel-header-button-wrapper"
                onClick={handleHeaderClicked}
            >
                <span
                    aria-hidden
                    className="dashboard-notification-panel-header-title"
                >
                    {title}
                </span>
                {isLoading ? <LoadingDots /> : `(${total})`}
                <Icon
                    className="dashboard-notification-panel-header-icon"
                    iconName="arrow-go-right"
                />
            </KeyboardFocusableButton>
            {renderContent()}
        </section>
    );
}

NotificationPanel.propTypes = {
    title: PropTypes.string,
    total: PropTypes.number,
    isLoading: PropTypes.bool,
    onDismiss: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconType: PropTypes.string,
        }),
    ),
    redirectUrl: PropTypes.string,
    emptyState: PropTypes.node,
};
