import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';

import {Icon} from 'Components/icon';

import './textField.scss';

export class TextField extends Component {
    render() {
        const {errorText, showErrorText, className, ...rest} = this.props;

        const shouldShowErrorText = errorText && showErrorText;

        return (
            <div className="textfield-container">
                {shouldShowErrorText && (
                    <div className="textfield-error-message">{errorText}</div>
                )}
                <div className="textfield-input-wrapper">
                    <input
                        className={classNames(
                            'textfield-input',
                            {
                                'input-error-background-color':
                                    shouldShowErrorText,
                            },
                            className,
                        )}
                        {...rest}
                    />
                    {shouldShowErrorText && (
                        <div className="textfield-error-icon-container">
                            <Icon
                                iconName="error"
                                className="textfield-error-icon"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

TextField.propTypes = {
    errorText: PropTypes.string,
    showErrorText: PropTypes.bool,
    className: PropTypes.string,
};
// All of the typical props for a HTML input can also be passed
