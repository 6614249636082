import helper from 'Utils/helper';

type TooltipManagerEvent = {
    eventName: string;
    debouncedFunction: {
        (): void;
        clear(): void;
        flush(): void;
    };
};

class TooltipManager {
    private evts: Record<string, Record<string, TooltipManagerEvent>>;
    private queue: TooltipManagerEvent[];

    constructor() {
        this.evts = {};
        this.queue = [];
    }
    register = (
        id: string,
        eventName: string,
        event: () => void,
        wait: number,
    ) => {
        if (!this.evts[id]) {
            this.evts[id] = {};
        }
        this.evts[id][eventName] = {
            debouncedFunction: helper.debounce(event, wait),
            eventName,
        };
    };
    execute = (id: string, eventName: string) => {
        if (this.queue.length) {
            const queueItem = this.queue.pop();
            //we only want to clear show events to prevent a chance of hide events being missed
            if (queueItem?.eventName === 'show') {
                queueItem.debouncedFunction.clear();
            }
        }
        // when we show a tooltip we clear any hide events on elements with the same id so it doesn't flash
        if (eventName === 'show') {
            this.clear(id, 'hide');
        }
        this.evts[id][eventName].debouncedFunction();
        this.queue.push(this.evts[id][eventName]);
    };
    clear = (id: string, eventName: string) => {
        this.evts[id][eventName].debouncedFunction.clear();
    };
}
export default new TooltipManager();
export function initTooltipPortal() {
    const tooltipPortal = document.createElement('div');
    tooltipPortal.id = 'tooltip-portal';
    // This should be the largest z-index in the whole site,
    // so that tooltips work on windows and slideovers.
    tooltipPortal.style.pointerEvents = 'none';
    tooltipPortal.style['z-index'] = 50000;
    tooltipPortal.style.position = 'absolute';
    tooltipPortal.style.top = '0';
    tooltipPortal.style.bottom = '0';
    tooltipPortal.style.left = '0';
    tooltipPortal.style.right = '0';
    document.body.appendChild(tooltipPortal);
}

// Note we are adding this here instead of the Table module as the Table is lazy loaded
export function initTableTooltipPortal() {
    const tableTooltipPortal = document.createElement('div');
    tableTooltipPortal.id = 'table-tooltip-portal';
    // This should be the largest z-index in the whole site,
    // so that tooltips work on windows and slideovers.
    tableTooltipPortal.style['z-index'] = 50000;
    tableTooltipPortal.style.position = 'fixed';
    document.body.appendChild(tableTooltipPortal);
}
