import {
    ARROW_DOWN_KEY_CODE,
    ARROW_UP_KEY_CODE,
    TAB_KEY_CODE,
} from 'Utils/keyCodeConstants';

const focusableElementsQuery =
    'button:enabled, [href], input:not(input[type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';

const getFocusableElements = (containerElementRef: HTMLElement | null) => {
    if (containerElementRef) {
        const query = containerElementRef.querySelectorAll<HTMLElement>(
            focusableElementsQuery,
        );

        // this is so that we have an explicit way to exclude otherwise focusable elements from being
        // focused
        return Array.from(query).filter(
            (element) =>
                !(
                    element.getAttribute('arbor-hidden-from-focus-utils') ===
                    'true'
                ),
        );
    }
    return [];
};

export const focusFirstFocusableElement = (
    containerElementRef: HTMLElement | null,
) => {
    if (
        containerElementRef &&
        typeof containerElementRef.querySelector === 'function'
    ) {
        const firstFocusableElement = containerElementRef.querySelector(
            focusableElementsQuery,
        ) as HTMLElement;

        if (
            firstFocusableElement &&
            typeof firstFocusableElement.focus === 'function'
        ) {
            firstFocusableElement.focus();
            return true;
        }
    }
    return false;
};

export const focusTrappingTabKeyHandler = (
    containerElementRef: HTMLElement | null,
    event: KeyboardEvent | React.KeyboardEvent,
) => {
    if (event.keyCode === TAB_KEY_CODE) {
        // Trap focus within an element. To be used on components like Modals and slideovers to
        // conform to accessibility guidelines WCAG 2.4.3:
        // "As long as the dialog is open, focus is limited to the elements of the dialog."
        // https://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms-focus-order.html)
        //
        // Based on https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
        //
        // Note:
        // - If there are no focusable elements within the container, focus trapping won't work.
        //   However modals and slideovers should always have at least 1 focuable element (the close button).
        // - If containerElementRef is defined, but not a valid DOM node, then getFocusableElements will
        //   throw an error. (This case is almost certainly an incorrect use of focusTrappingTabKeyHandler).

        const focusableElements = getFocusableElements(containerElementRef);
        const firstFocusableElement = focusableElements && focusableElements[0];
        const lastFocusableElement =
            focusableElements &&
            focusableElements[focusableElements.length - 1];

        if (event.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (
                document.activeElement === firstFocusableElement ||
                // Happens if you click on a non focusable element
                document.activeElement === containerElementRef
            ) {
                lastFocusableElement.focus(); // add focus for the last focusable element
                event.preventDefault();
            }
        } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
                // if focused has reached to last focusable element then focus first focusable element after pressing tab
                firstFocusableElement.focus(); // add focus for the first focusable element
                event.preventDefault();
            }
        }
    }
};

// This was defined in DropdownContainer. Not sure whether to merge with focusableElementsQuery
const keyboardFocusableElements = ['a', 'button:enabled'];
const keyboardFocusableElementsQuery = keyboardFocusableElements.join(', ');
export const shiftFocusWithKeys =
    ({
        forwardKeyCodes = [ARROW_DOWN_KEY_CODE],
        backwardKeyCodes = [ARROW_UP_KEY_CODE],
        containerElement,
        tabHandler,
        backFromFirstHandler,
        useFocusableElementsQuery,
    }: {
        forwardKeyCodes?: number[];
        backwardKeyCodes?: number[];
        containerElement: Element | null;
        tabHandler?: (e: React.KeyboardEvent) => void;
        backFromFirstHandler?: (e: React.KeyboardEvent) => void;
        useFocusableElementsQuery?: boolean;
    }) =>
    (e: React.KeyboardEvent) => {
        if (!containerElement) {
            console.error('No container element in shiftFocusWithKeys');
            return;
        }
        if (e.keyCode === TAB_KEY_CODE && typeof tabHandler === 'function') {
            tabHandler(e);
        }

        const query = useFocusableElementsQuery
            ? focusableElementsQuery
            : keyboardFocusableElementsQuery;

        const isForwardKeyPressed =
            forwardKeyCodes &&
            forwardKeyCodes.findIndex((keyCode) => keyCode === e.keyCode) > -1;
        const isBackwardKeyPressed =
            backwardKeyCodes &&
            backwardKeyCodes.findIndex((keyCode) => keyCode === e.keyCode) > -1;

        if (isForwardKeyPressed || isBackwardKeyPressed) {
            // We want to move the focus forward or backwards in the dom

            e.preventDefault();

            // Check if focus lies within the dropdownList
            if (document && containerElement.contains(document.activeElement)) {
                // Find all enabled children, as we skip disabled items when navigating the list
                const elementsQuery: undefined | NodeListOf<HTMLElement> =
                    containerElement.querySelectorAll(query);
                const enabledElements =
                    elementsQuery &&
                    Array.from(elementsQuery).filter(
                        (element) =>
                            !(
                                element.getAttribute(
                                    'arbor-hidden-from-focus-utils',
                                ) === 'true'
                            ),
                    );

                // Find the index of the currently focused child
                const focusedIndex = enabledElements?.findIndex((element) =>
                    element?.contains(document.activeElement),
                );
                if (
                    enabledElements &&
                    typeof focusedIndex !== 'undefined' &&
                    focusedIndex > -1
                ) {
                    // Shift the index up or down based on key pressed. If down key pressed
                    // on last child, do nothing.
                    const indexToFocus = isForwardKeyPressed
                        ? focusedIndex + 1
                        : focusedIndex - 1;

                    if (
                        indexToFocus > -1 &&
                        indexToFocus <= enabledElements.length - 1
                    ) {
                        enabledElements[indexToFocus]?.focus();
                    } else if (
                        indexToFocus === -1 &&
                        typeof backFromFirstHandler === 'function'
                    ) {
                        backFromFirstHandler(e);
                    }
                }
            }
        }
    };
