import {Button} from 'Components/button/base';
import {submitMisForm} from 'Interfaces/form/Form';
import {loadCurrentPage} from 'Interfaces/Loader';
import {RendererParams} from 'Renderer/Renderer';
import {loadPageWithModifierKeys} from 'Utils/loadPageWithModifierKeys';

import exportInterface from '../Base';
import {addWindow, removeWindow} from '../WindowManager';

import './decisionPopup.scss';

type ShowDecisionPopupParams = {
    buttons: {
        label: string;
        role: 'loadPage' | 'reloadPage' | 'resubmitForm';
        url?: string;
        color?: 'red' | 'orange' | 'green' | 'grey';
        tooltip?: string;
        additionalUrlParams?: Record<string, string>;
    }[];
    description: string;
    title: string;
};

type PreviousFormParams = {
    config: any;
    mode: string;
};

export const showDecisionPopup = (
    popupData: ShowDecisionPopupParams,
    previousFormParams: PreviousFormParams,
    rendererParams: RendererParams,
) => {
    const {buttons, description, title} = popupData;
    const {contentRequestUrl} = rendererParams;
    try {
        addWindow({
            content: description,
            footerItems: (
                <div className="decision-popup__footer-buttons">
                    <Button
                        color="grey"
                        text="Cancel"
                        onClick={() => removeWindow()}
                    />
                    {buttons.map((button, index) => (
                        <Button
                            key={index}
                            color={button.color}
                            tooltipMIS={button.tooltip}
                            text={button.label}
                            onClick={(clickEvent) => {
                                if (button.role === 'loadPage' && button.url) {
                                    loadPageWithModifierKeys(
                                        button.url,
                                        clickEvent,
                                    );
                                    removeWindow();
                                    return;
                                }
                                if (button.role === 'reloadPage') {
                                    loadCurrentPage();
                                    removeWindow();
                                    return;
                                }
                                if (button.role === 'resubmitForm') {
                                    submitMisForm(
                                        previousFormParams.config,
                                        previousFormParams.mode,
                                        {
                                            additionalUrlParams:
                                                button.additionalUrlParams,
                                        },
                                    );
                                    removeWindow();
                                    return;
                                }
                            }}
                        />
                    ))}
                </div>
            ),
            title,
            rendererParams: {
                contentRequestUrl,
                type: 'window',
                elementToRefocus: null,
                refocusCallback: null,
            },
        });
    } catch (e) {
        console.error('Error showing decision popup', e);
    }
};

export const registerDecisionPopupInterface = () => {
    exportInterface('DecisionPopup', {showDecisionPopup});
};
