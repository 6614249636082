import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {PurchasePanel} from 'BackendRenderedComponents/purchasePanel';
import {selectItem} from 'State/actionCreators/Basket';
import {selectAllItems} from 'State/selectors/Basket';

import './bundlePurchasePanel.scss';

export class UnconnectedBundlePurchasePanel extends Component {
    componentDidUpdate(prevProps) {
        const {allItems, purchasePanel, serviceId, onSelectItem} = this.props;

        // If bundle is not selected and all of its children are, select it and show message
        if (
            !prevProps.allItems[serviceId].selected &&
            !allItems[serviceId].selected
        ) {
            if (
                purchasePanel.bundleRelatedPanels &&
                purchasePanel.bundleRelatedPanels.length > 0
            ) {
                let allChildrenSelected = true;
                purchasePanel.bundleRelatedPanels.forEach((childPanel) => {
                    if (!allItems[childPanel._serviceId].selected) {
                        allChildrenSelected = false;
                    }
                });
                // all children are added
                if (allChildrenSelected) {
                    onSelectItem(serviceId, true);
                }
            }
        }
    }

    render() {
        return <PurchasePanel {...this.props} type="bundle" />;
    }
}

UnconnectedBundlePurchasePanel.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    currencyCode: PropTypes.string,
    price: PropTypes.number,
    priceIcon: PropTypes.node,
    vat: PropTypes.number,
    serviceId: PropTypes.string,
    type: PropTypes.string,
    purchasePanel: PropTypes.shape({
        pictureUrl: PropTypes.string,
        properties: PropTypes.array,
        groupProperties: PropTypes.array,
        bundleRelatedPanels: PropTypes.array,
    }),
    children: PropTypes.node,
    registerItem: PropTypes.func,
    selectItem: PropTypes.func,
    allItems: PropTypes.object,
    onSelectItem: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        allItems: selectAllItems(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectItem: (id, lastInBundleSelected) => {
            dispatch(selectItem(id, lastInBundleSelected));
        },
    };
};

export const BundlePurchasePanel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedBundlePurchasePanel);
