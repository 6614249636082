import './loadingKpiBox.scss';
import './kpiBox.scss';

export const LoadingKpiBox = () => (
    <div className="old-kpi-box kpi-box-loading">
        <div className="kpi-box-loading-value"></div>
        <div className="kpi-box-loading-bars">
            <div className="kpi-box-loading-bar"></div>
            <div className="kpi-box-loading-bar"></div>
        </div>
    </div>
);
