import classnames from 'classnames';
import {Component, ReactNode} from 'react';
import * as React from 'react';

import {Icon} from 'Components/icon';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';

import {AskArborLogo} from './AskArborLogo';

import './button.scss';
import './secondaryButton.scss';
import './primaryButton.scss';

interface IButtonContentProps {
    text?: string | number;
    icon?: string;
    iconRight?: string;
    askArborLogo?: boolean;
    fontSize?: string;
}

const ButtonContent = ({
    icon,
    iconRight,
    text,
    askArborLogo,
    fontSize,
}: IButtonContentProps) => (
    <span className="btn-content" role="presentation">
        {askArborLogo && <AskArborLogo />}
        {icon && <Icon aria-label={`Icon: ${icon}`} iconName={icon} />}
        {typeof text !== 'undefined' && text !== null && (
            <span className="btn-label" {...(fontSize && {style: {fontSize}})}>
                {text}
            </span>
        )}
        {iconRight && (
            <Icon aria-label={`Icon: ${iconRight}`} iconName={iconRight} />
        )}
    </span>
);

// We wrap the tooltip around the content so that it still works even if the button is
// in a disabled state (Chrome blocks the mouse events otherwise).
const ButtonContentWithTooltip = tooltipWrapper(ButtonContent);

export interface IButtonProps {
    className?: string;
    color?: 'green' | 'red' | 'orange' | 'grey' | '';
    uiType?: 'primary' | 'secondary';
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void; // Not required as buttons with type="submit" don't need it
    onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    text?: string | number;
    icon?: string;
    iconRight?: string;
    small?: boolean;
    tabIndex?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipMIS?: any;
    tooltipHTML?: string;
    setRef?: React.LegacyRef<HTMLButtonElement> | (() => void);
    type?: 'button' | 'submit' | 'reset';
    ariaLabel?: string;
    ariaRole?: string;
    dataTestId?: string;
    askArborLogo?: boolean;
    v2?: boolean;
    children?: ReactNode;
    fontSize?: string;
    makeInvisibleToFocusUtils?: boolean;
}

export class Button extends Component<IButtonProps> {
    render() {
        const {
            className,
            color,
            disabled,
            onClick,
            small,
            tabIndex,
            tooltipMIS,
            tooltipHTML,
            setRef,
            onKeyDown,
            onKeyUp,
            icon,
            iconRight,
            text,
            uiType: unsafeUiType,
            type,
            ariaLabel,
            ariaRole,
            dataTestId,
            askArborLogo,
            v2,
            fontSize,
            makeInvisibleToFocusUtils,
        } = this.props;

        const uiType =
            typeof unsafeUiType === 'undefined' ? 'primary' : unsafeUiType;

        return (
            <button
                {...(makeInvisibleToFocusUtils && {
                    'arbor-hidden-from-focus-utils': 'true',
                })}
                ref={setRef}
                className={classnames(
                    'arbor-btn',
                    {
                        [`arbor-btn-${color}`]: color,
                        'arbor-btn-small': !!small,
                        'arbor-btn-primary': uiType === 'primary',
                        'arbor-btn-secondary': uiType === 'secondary',
                        'arbor-btn-v2': v2,
                    },
                    className,
                )}
                disabled={disabled}
                type={type}
                onClick={onClick}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                tabIndex={tabIndex}
                aria-label={ariaLabel}
                data-testid={dataTestId}
                role={ariaRole}
            >
                {tooltipMIS || tooltipHTML ? (
                    <ButtonContentWithTooltip
                        icon={icon}
                        iconRight={iconRight}
                        text={text}
                        tooltip={tooltipMIS || tooltipHTML}
                        askArborLogo={askArborLogo}
                        fontSize={fontSize}
                    />
                ) : (
                    <ButtonContent
                        icon={icon}
                        iconRight={iconRight}
                        text={text}
                        askArborLogo={askArborLogo}
                        fontSize={fontSize}
                    />
                )}
            </button>
        );
    }
}
