export const openLinkWithTemporaryATag = (
    href: string,
    modifiers: {
        shiftKey?: boolean;
        altKey?: boolean;
        ctrlKey?: boolean;
        metaKey?: boolean;
    },
) => {
    // Forward the click event to an <a> tag, as this will do modifier handling for us,
    // so we don't need to worry about cross browser differences, or user/OS settings.

    const aTag = document.createElement('a');

    aTag.href = href;

    const fakeMouseEvent = new MouseEvent('click', modifiers);
    aTag?.dispatchEvent(fakeMouseEvent);
    if (typeof aTag?.remove === 'function') {
        aTag.remove();
    }
};
