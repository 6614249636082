import {Component, LegacyRef} from 'react';
import {connect} from 'react-redux';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {bindActionCreators} from 'redux';

import {CalendarWidget} from 'Components/calendarWidget';
import {SubNav} from 'Components/subNav';
import {slideover as slideoverDuration} from 'Config/Durations';
import {getUser} from 'Interfaces/Session';
import {
    closeBurgerMenuAction,
    selectIsBurgerMenuVisible,
} from 'State/burgerMenu';
import {
    selectIsFavouritesEnabled,
    selectNavigationTreeData,
} from 'State/navigation/selectors';
import {focusFirstFocusableElement} from 'Utils/focusUtils';
import {ESCAPE_KEY_CODE} from 'Utils/keyCodeConstants';
import {MOBILE, ScreenType} from 'Utils/screenType';

import {
    ArborHQButton,
    EmergencyAlertButton,
    focusBurgerMenuButton,
    HelpCentreButton,
    SignOutButton,
    HomeButton,
    CalendarButton,
    TrainingHubButton,
    AskArborButton,
} from '../buttons';
import {JiraFeedbackButton} from '../buttons/jiraFeedback/JiraFeedbackButton';
import {NavigationRootItems, NavigationSubTree} from '../navigation';
import {SearchBar} from '../searchbar';

import './burgerMenu.scss';

type UnconnectedBurgerMenuProps = {
    closeBurgerMenu: () => void;
    isBurgerMenuVisible: boolean;
    screenType: ScreenType;
    navigation: ReturnType<typeof selectNavigationTreeData>;
    isFavouritesEnabled: ReturnType<typeof selectIsFavouritesEnabled>;
};
type UnconnectedBurgerMenuState = {
    currentNavigationSubTreeId: string | null;
    calendarVisible: boolean;
};

export class UnconnectedBurgerMenu extends Component<
    UnconnectedBurgerMenuProps,
    UnconnectedBurgerMenuState
> {
    containerRef: HTMLElement | null = null;
    setContainerRef: LegacyRef<HTMLDivElement> | undefined = (element) => {
        this.containerRef = element;
    };

    calendarRef: HTMLElement | null = null;
    setCalendarRef: LegacyRef<HTMLDivElement> | undefined = (element) => {
        this.calendarRef = element;
    };

    constructor(props: UnconnectedBurgerMenuProps) {
        super(props);

        this.state = {
            currentNavigationSubTreeId: null,
            calendarVisible: false,
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.keyDownHandler, false);
    }

    componentDidUpdate = (
        prevProps: UnconnectedBurgerMenuProps,
        prevState: UnconnectedBurgerMenuState,
    ) => {
        const {isBurgerMenuVisible} = this.props;
        const {currentNavigationSubTreeId, calendarVisible} = this.state;
        if (isBurgerMenuVisible && !prevProps.isBurgerMenuVisible) {
            if (this.containerRef) {
                focusFirstFocusableElement(this.containerRef);
            }
        }
        if (!isBurgerMenuVisible && prevProps.isBurgerMenuVisible) {
            this.setState({
                currentNavigationSubTreeId: null,
                calendarVisible: false,
            });
        }
        if (
            (currentNavigationSubTreeId &&
                currentNavigationSubTreeId !==
                    prevState.currentNavigationSubTreeId) ||
            (calendarVisible && calendarVisible !== prevState.calendarVisible)
        ) {
            // make sure we don't lose focus when entering the navigation subTree
            if (this.containerRef) {
                focusFirstFocusableElement(this.containerRef);
            }
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownHandler, false);
    }

    keyDownHandler = (event: KeyboardEvent) => {
        const {closeBurgerMenu, isBurgerMenuVisible} = this.props;

        if (event.keyCode === ESCAPE_KEY_CODE && isBurgerMenuVisible) {
            closeBurgerMenu();
            focusBurgerMenuButton();
        }
    };

    showNavigationSubTree = (subTreeId: string) => {
        this.setState({
            currentNavigationSubTreeId: subTreeId,
        });
    };

    hideNavigationSubTree = () => {
        const subTreeId = this.state.currentNavigationSubTreeId;
        this.setState(
            {
                currentNavigationSubTreeId: null,
            },
            () => {
                const rootElement = document.getElementById(
                    `navigation-root-item_${subTreeId}`,
                );
                if (rootElement) {
                    rootElement.focus();
                }
            },
        );
    };

    showCalendar = () => {
        this.setState({
            calendarVisible: true,
        });
    };

    hideCalendar = () => {
        this.setState(
            {
                calendarVisible: false,
            },
            () => {
                if (this.calendarRef) {
                    focusFirstFocusableElement(this.calendarRef);
                }
            },
        );
    };

    render() {
        const {
            showSearch,
            showCommunityButton,
            showTrainingHubButton,
            calendarUrl,
            showAskArborButton,
        } = getUser() || {};
        const {
            closeBurgerMenu,
            isBurgerMenuVisible,
            navigation,
            screenType,
            isFavouritesEnabled,
        } = this.props;
        const {currentNavigationSubTreeId, calendarVisible} = this.state;

        const MenuContent = () => {
            if (currentNavigationSubTreeId && navigation) {
                return (
                    <NavigationSubTree
                        navigationData={navigation.find(
                            (item) => item.id === currentNavigationSubTreeId,
                        )}
                        onBackButtonClicked={this.hideNavigationSubTree}
                        isFavouritesEnabled={isFavouritesEnabled}
                    />
                );
            } else if (calendarVisible && calendarUrl) {
                return (
                    <CalendarWidget
                        widgetLocation="BurgerMenu"
                        titleUrl="/my-mis-ui/calendar/"
                        configs={{
                            url: calendarUrl,
                        }}
                        backButtonOnClick={this.hideCalendar}
                    />
                );
            }

            return (
                <>
                    {showSearch && screenType === MOBILE && (
                        <div className="burger-menu__search-container">
                            <SearchBar isDesktop={false} isMobile={true} />
                        </div>
                    )}
                    {showAskArborButton && <AskArborButton />}
                    <HomeButton />
                    {calendarUrl && (
                        <div ref={this.setCalendarRef}>
                            <CalendarButton
                                onClick={this.showCalendar}
                                isBurgerMenu
                            />
                        </div>
                    )}
                    <HelpCentreButton />
                    {showCommunityButton && <ArborHQButton />}
                    {showTrainingHubButton && <TrainingHubButton />}
                    <JiraFeedbackButton />
                    {screenType === MOBILE && <EmergencyAlertButton />}
                    <div className="burger-menu__navigation-container">
                        <NavigationRootItems
                            navigationData={navigation}
                            showNavigationSubTree={this.showNavigationSubTree}
                        />
                    </div>
                    <div className="burger-menu__sub-nav-container">
                        <SubNav isMobileLayout />
                    </div>
                    <SignOutButton />
                </>
            );
        };

        return (
            <TransitionGroup>
                {isBurgerMenuVisible && (
                    <CSSTransition
                        classNames="burger-menu-background-transition"
                        timeout={slideoverDuration}
                        key="burger-menu-background"
                    >
                        <div
                            className="burger-menu-background"
                            onClick={closeBurgerMenu}
                        />
                    </CSSTransition>
                )}
                {isBurgerMenuVisible && (
                    <CSSTransition
                        classNames="burger-menu-container-transition"
                        timeout={slideoverDuration}
                        key="burger-menu-container"
                    >
                        <div
                            className="burger-menu-container"
                            ref={this.setContainerRef}
                            aria-label="Main Navigation"
                            data-testid="burger-menu-container"
                        >
                            <MenuContent />
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
        );
    }
}

const mapStateToProps = (state) => ({
    isBurgerMenuVisible: selectIsBurgerMenuVisible(state),
    navigation: selectNavigationTreeData(state),
    isFavouritesEnabled: selectIsFavouritesEnabled(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({closeBurgerMenu: closeBurgerMenuAction}, dispatch);

export const BurgerMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedBurgerMenu);
