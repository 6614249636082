import {ComboboxItem} from './types';

export const filterComboboxOptions = (
    items: ComboboxItem[],
    searchValue: string,
) => {
    let newFilteredItems = items;
    if (searchValue !== '') {
        newFilteredItems = items.filter((item) => {
            const isSearchValueInLabel =
                (item.dropdownLabel ?? item.label)
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) !== -1;

            const isSearchValueInGroupName =
                item.group &&
                item.group !== '(No Group)' &&
                item.group.toLowerCase().indexOf(searchValue.toLowerCase()) !==
                    -1;

            return isSearchValueInLabel || isSearchValueInGroupName;
        });
    }
    return newFilteredItems;
};
