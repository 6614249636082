import {useState} from 'react';

import {Button, IButtonProps} from 'Components/button/base';
import {submitMisForm} from 'Interfaces/form/Form';
import {addSystemNotification} from 'Interfaces/SystemNotification';
import buttonWrapperExt from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapperExt(Button);

export type FormAction = {
    actionUrl: string;
    formActionName: string;
    validators?:
        | []
        | Record<
              string,
              | []
              | {
                    type: 'endDate' | 'required' | 'default';
                    startDate?: string;
                }
          >;
    success?: {
        _formActionName: string;
        _type: string;
        _url?: string;
        _partialId?: string;
        _componentId?: string;
    }[];
};

type FormActionButtonProps = IButtonProps & {
    role: string;
    currentAction: FormAction;
};

export function FormActionButton(props: FormActionButtonProps) {
    const {currentAction, role = 'form', disabled, ...rest} = props;
    const [buttonDisabled, setbuttonDisabled] = useState(disabled || false);

    const finishedCallback = () => {
        setbuttonDisabled(false);
    };

    const submitForm = () => {
        if (!currentAction) {
            addSystemNotification({
                icon: 'error',
                type: 'error',
                message: 'Could not submit form. No currentAction provided.',
            });
            return;
        }
        setbuttonDisabled(true);
        submitMisForm(currentAction, role, {}, finishedCallback);
    };

    return (
        <WrappedButton
            text="Save Changes"
            color="green"
            {...rest}
            onClick={submitForm}
            disabled={buttonDisabled}
        />
    );
}
