import exportInterface from 'Interfaces/Base';

import type {FormAction} from 'Components/button/formActionButton';
import type {FormField} from 'Services/formField/FormField';

export function submitMisForm(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any,
    mode: string | undefined,
    resubmitParams: {
        additionalUrlParams?: Record<string, string>;
    } = {},
    finishedCallback?: () => void,
) {
    Mis.application.Form.submitMisForm(
        config,
        mode,
        resubmitParams,
        finishedCallback,
    );
}
export const getFields = (formActionName: string) =>
    Mis.application.Form.getFields(formActionName) as FormField[];

export const checkIfValid = ({
    fields,
    formActionName,
    validators,
    fieldName,
    field,
    value,
}: {
    fields: FormField[];
    formActionName: string;
    validators: FormAction['validators'];
    fieldName: string;
    field: FormField;
    value: unknown;
}) =>
    Mis.application.Form.checkIfValid(
        fields,
        formActionName,
        validators,
        fieldName,
        field,
        value,
    );

export const registerFormInterface = () => {
    exportInterface('Form', {submitMisForm});
};
