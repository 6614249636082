import Cookies from 'js-cookie';

import {httpPost} from 'Interfaces/httpClient';
import {loadCurrentPage, loadPage} from 'Interfaces/Loader';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import {removeAllSlideoversWithoutAnimation} from 'Interfaces/Slideover';
import {addSystemModal} from 'Interfaces/SystemModal';
import {postLoginInitialization} from 'Root/postLoginInitialization';
import {renderLoginPage} from 'Root/renderLoginPage';
import urlConfig from 'Services/url/Url';
import {closeAllExtTooltips} from 'Utils/closeAllExtTooltips';
import {closeAllExtWindows} from 'Utils/closeAllExtWindows';

import {
    fetchCurrentUserSettings,
    ILoggedInUserSettings,
    ILoggedOutUserSettings,
    isLoggedInUserSettings,
    IUserSettingsResponse,
} from './fetchCurrentUserSettings';
import {
    sessionTime,
    sessionCheckTime,
    logoutDelayTime,
} from './sessionConstants';
import {
    renderSessionTimeoutModal,
    unmountSessionTimeoutModal,
} from './sessionTimeoutModal';

export interface ILoginData {
    items: [
        {
            // eslint-disable-next-line camelcase
            display_name?: string;
            email?: string;
            firstTimeLogin?: boolean;
            // eslint-disable-next-line camelcase
            logged_in?: boolean;
            loginIssues?: string[];
            loginIssuePage?: string;
            message?: string;
            // eslint-disable-next-line camelcase
            session_id?: string;
            username?: string;
        },
    ];
}

class SessionService {
    userSettings: ILoggedInUserSettings | ILoggedOutUserSettings = {}; // Populated by auth API call

    lastRequestTimeLocation: string | null = null;
    logoutCheck: NodeJS.Timeout | null = null;

    // Entrypoint
    // If the user is not logged in, the getUserInfo request is successful but has logged_in=false
    initialiseSession = async () => {
        await this.getUserInfo('session.initialiseSession');

        unmaskMain();
        if (!this.isLoggedIn()) {
            // Previously we would do a long process of trying to load a page and
            // waiting for the 403. I'm not sure why they hadn't been just rendering login straight
            // away before. Need to test out if there are any cases this breaks
            renderLoginPage();
            return;
        }
        if (this.loginIssues()) {
            postLoginInitialization();
            loadPage(this.loginIssuePage());
            return;
        }
        postLoginInitialization();
        loadCurrentPage(false);
    };

    isLoggedIn = () => {
        return isLoggedInUserSettings(this.userSettings);
    };

    setLoggedIn = (value?: boolean) => {
        this.userSettings.logged_in = value ?? false; // eslint-disable-line camelcase
    };

    getUser = () => {
        if (!isLoggedInUserSettings(this.userSettings)) {
            // We deal with all cases where the user is not fully logged in within
            // the session interface, so expose fully logged in settings externally
            // as this makes it easier to have good type coverage
            return null;
        }
        return this.userSettings;
    };

    loginIssuePage = () => {
        if (isLoggedInUserSettings(this.userSettings)) {
            return this.userSettings.loginIssuePage;
        }
        return null;
    };

    setLoginIssuePage = (value?: string) => {
        if (isLoggedInUserSettings(this.userSettings)) {
            this.userSettings.loginIssuePage = value;
        }
    };

    loginIssues = () => {
        const loginIssuePage = this.loginIssuePage();
        return loginIssuePage && loginIssuePage.length > 0;
    };

    successLoginHandler = async (loginData: ILoginData) => {
        this.setLoggedIn(loginData.items[0].logged_in);
        this.setLoginIssuePage(loginData.items[0].loginIssuePage);

        if (this.loginIssuePage()) {
            postLoginInitialization();
            loadPage(this.loginIssuePage());
            return;
        }

        // We fetched user info on initial page load, but we need to fetch again now that
        // we have logged in so that we get an ILoggedInUserSettings response
        await this.getUserInfo('session.successLoginHandler');
        if (this.loginIssuePage()) {
            postLoginInitialization();
            loadPage(this.loginIssuePage());
            return;
        }
        postLoginInitialization();
        loadCurrentPage();
    };

    setLastRequestTime = (time: number) => {
        // Use localStorage to store session data; if it's disabled fall back to Cookies
        try {
            localStorage.setItem('lastRequestTime', String(time));

            this.lastRequestTimeLocation = 'localStorage';
        } catch (e) {
            Cookies.set('lastRequestTime', String(time));

            this.lastRequestTimeLocation = 'cookies';
        }
    };

    getLastRequestTime = () => {
        // Note: we return 0 if we cannot find a request time, so that we always return a number
        // and any comparison logic will still work.
        if (this.lastRequestTimeLocation === 'localStorage') {
            const requestTime = localStorage.getItem('lastRequestTime');
            if (requestTime === null || isNaN(parseInt(requestTime))) {
                return 0;
            }
            return parseInt(requestTime);
        }
        if (this.lastRequestTimeLocation === 'cookies') {
            const requestTime = Cookies.get('lastRequestTime');
            if (
                typeof requestTime === 'undefined' ||
                isNaN(parseInt(requestTime))
            ) {
                return 0;
            }
            return parseInt(requestTime);
        }
        return 0;
    };

    clearLastRequestTime = () => {
        if (this.lastRequestTimeLocation === 'localStorage') {
            localStorage.removeItem('lastRequestTime');
        } else if (this.lastRequestTimeLocation === 'cookies') {
            Cookies.remove('lastRequestTime');
        }
    };

    logOut = () => {
        maskMain();

        this.setLoggedIn(false);
        this.clearLastRequestTime();
        closeAllExtTooltips();
        closeAllExtWindows();
        removeAllSlideoversWithoutAnimation();

        httpPost(urlConfig.AUTH.LOGOUT).then(function (data: {url?: string}) {
            const url = data.url ? data.url : '/';
            window.location.href = url;
        });
    };

    getUserInfo = async (sourceTag?: string) => {
        maskMain();

        try {
            const response: IUserSettingsResponse =
                await fetchCurrentUserSettings(
                    sourceTag ?? 'session.getUserInfo',
                );

            // Store user settings data in class
            this.userSettings = {...response.items[0]};

            if (response.action_params && response.action_params.message) {
                // There is code in currentUserSettingsAction to send a message via action params
                // if the request has no cookies.
                // However don't think this will ever happen because we have a cookies check in
                // layout.phtml
                addSystemModal({
                    message: response.action_params.message,
                    icon: 'warning',
                });
            }
        } catch (e) {
            // TODO [MIS-44072] Upgrade axios and use e: Error | AxiosError
            console.error('Error getting user info (getUserInfoFailure)', e);
        } finally {
            unmaskMain();
            this.processUserInfo();
        }
    };

    processUserInfo = () => {
        if (this.isLoggedIn() && !this.loginIssues()) {
            this.startSession();
        }
    };

    startSession = () => {
        this.setLastRequestTime(Date.now());

        if (this.logoutCheck) {
            // Ensure we don't accidentally set 2 of the same interval
            clearInterval(this.logoutCheck);
        }
        this.logoutCheck = setInterval(() => {
            const timeSinceLastRequest = Date.now() - this.getLastRequestTime();

            if (timeSinceLastRequest > sessionTime) {
                if (this.logoutCheck) {
                    clearInterval(this.logoutCheck);
                }
                if (
                    timeSinceLastRequest >
                    sessionTime + logoutDelayTime + sessionCheckTime
                ) {
                    // e.g. when iPad is woken up from hibernation
                    this.logOut();
                } else {
                    // normal auto-logout process with the 'prompt' to log out.
                    this.startLogOut();
                }
            }
        }, sessionCheckTime);
    };

    startLogOut = () => {
        // Render the <SessionTimeoutModal> component, which counts down to zero then calls
        // onTimeRanOut(). The user can cancel the countdown, which calls refreshSession()
        renderSessionTimeoutModal({
            refreshSession: () => {
                unmountSessionTimeoutModal();

                loadCurrentPage();
                this.startSession();
            },
            onTimeRanOut: () => {
                if (Date.now() - this.getLastRequestTime() > sessionTime) {
                    unmountSessionTimeoutModal();
                    this.logOut();
                } else {
                    // This edge case is weird, it seems to be if a valid request is sent
                    // during the time the session timeout modal is shown
                    unmountSessionTimeoutModal();
                    this.startSession();
                }
            },
        });
    };
}

let sessionServiceInstance: SessionService | null = null;

export const getSessionService = () => {
    if (!sessionServiceInstance) {
        sessionServiceInstance = new SessionService();
    }
    return sessionServiceInstance;
};

export const _resetSessionServiceInstance = () => {
    sessionServiceInstance = null;
};
