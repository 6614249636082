import {useEffect, useRef, useState} from 'react';

import {SYSTEM_MODAL} from 'Config/Events';
import {usePubSub} from 'Services/pubsub';

import {returnFocusToTopOfVisualStack} from './returnFocusToTopOfVisualStack';
import {SystemModal} from './SystemModal';
import {SystemModalConfig} from './types';

import './systemModal.scss';

export const SystemModalManager = () => {
    const [modals, setModals] = useState<
        (SystemModalConfig & {modalIndex: number})[]
    >([]);
    const currentModalIndexRef = useRef(-1);

    const handleAdd = (modal: SystemModalConfig = {}) => {
        if (!Object.keys(modal).length) {
            console.error(
                'handleAdd should not be called with an empty modal parameter',
            );
            return;
        }

        const modalWithIndex = {
            ...modal,
            modalIndex: ++currentModalIndexRef.current,
        };

        setModals([...modals, modalWithIndex]);
    };

    const handleRemove = (index) => {
        setModals(modals.filter((modal) => modal.modalIndex !== index));
    };

    usePubSub(SYSTEM_MODAL.ADD, handleAdd);
    usePubSub(SYSTEM_MODAL.REMOVE, handleRemove);

    useEffect(() => {
        if (currentModalIndexRef.current !== -1 && modals.length === 0) {
            returnFocusToTopOfVisualStack();
        }
    }, [modals]);

    const [firstModal] = modals;
    return (
        <div className="system-modal-manager" role="presentation">
            {firstModal && (
                <div className="modal-background-overlay">
                    <SystemModal
                        {...firstModal}
                        onClose={() => handleRemove(firstModal.modalIndex)}
                    />
                </div>
            )}
        </div>
    );
};
