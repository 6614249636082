import {exportInterfaceSingleton} from './Base';
import {raiseDevError} from './error/Error';

class RefreshableComponents {
    constructor() {
        this._componentRegister = {};
    }

    register(componentId, refreshComponent) {
        this._componentRegister[componentId] = refreshComponent;
    }

    refreshComponent(componentId, params) {
        if (this._componentRegister[componentId]) {
            this._componentRegister[componentId]();
        } else {
            // try with Ext
            const cmp = Ext.ComponentQuery.query('#' + componentId)[0];

            if (cmp?.refreshComponent) {
                cmp.refreshComponent(params);
            } else {
                raiseDevError({
                    message:
                        "Can\t reload the component '" +
                        componentId +
                        "': component not found",
                });
            }
        }
    }

    remove(componentId) {
        delete this._componentRegister[componentId];
    }
}

const refreshableComponents = new RefreshableComponents();

export const registerRefreshableComponentsInterface = () => {
    exportInterfaceSingleton('RefreshableComponents', refreshableComponents);
};

export default refreshableComponents;
