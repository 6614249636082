export const splitArrayIntoChunks = <T>(inputArray: T[], chunkSize: number) => {
    if (!chunkSize || chunkSize <= 0) {
        throw new Error(`Invalid chunkSize: ${chunkSize}`);
    }

    const outputArray: T[][] = [];
    for (
        let i = 0, inputLength = inputArray.length;
        i < inputLength;
        i += chunkSize
    ) {
        outputArray.push(inputArray.slice(i, i + chunkSize));
    }
    return outputArray;
};
