import {httpGet} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

const normalizeOptionsReducer = (acc, curr) => {
    acc[curr.id] = curr;
    return acc;
};

export const normalizeOptionsResponse = (response) => {
    if (
        !response ||
        !(response.schools && typeof response.schools.reduce === 'function') ||
        !(response.clusters && typeof response.clusters.reduce === 'function')
    ) {
        return {
            schools: [],
            clusters: [],
        };
    }
    const {schools, clusters} = response;
    return {
        schoolOptions: schools.reduce(normalizeOptionsReducer, {}),
        clusterOptions: clusters.reduce(normalizeOptionsReducer, {}),
    };
};

export const getFilterOptionsService = async () => {
    const response = await httpGet(url.GROUP_GLOBAL_FILTERS.GET_FILTER_OPTIONS);
    return normalizeOptionsResponse(response);
};
