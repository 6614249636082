import {Component} from 'react';

import * as keys from 'Config/keyboard';

export default function KeyHandlerHOC(WrappedComponent, config = {}) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.evts = {};
            this.cbMap = this.generateCbMap();
        }
        handleKey = (event) => {
            event.stopPropagation();
            const keyCode = event.keyCode || event.which;
            if (!this.cbMap[event.type][keyCode]) {
                return;
            }
            const instance = this.getInstance();
            if (
                typeof instance[this.cbMap[event.type][keyCode]] === 'function'
            ) {
                instance[this.cbMap[event.type][keyCode]](event);
                return;
            }
            throw new Error(
                `WrappedComponent lacks a  ${
                    this.cbMap[event.type][keyCode]
                }(event) function for processing click events.`,
            );
        };
        findKeyName = (keyValue) => {
            for (let key in keys) {
                // eslint-disable-next-line import/namespace
                if (keyValue === keys[key]) {
                    let name = key.slice(0, key.lastIndexOf('_')).toLowerCase();
                    return name[0].toUpperCase() + name.substr(1);
                }
            }
            return null;
        };
        generateCbMap = () => {
            let hashMap = {};
            for (let evt in config) {
                if (['onKeyDown', 'onKeyUp', 'onKeyPressed'].includes(evt)) {
                    let eventType = evt.slice(2).toLowerCase();
                    Object.assign(
                        hashMap,
                        config[evt].reduce((obj, each) => {
                            if (!each) {
                                throw new Error(
                                    'WrappedComponent lacks a proper keyCode.',
                                );
                            }
                            this.evts[evt] = this.handleKey.bind(this);
                            if (!obj[eventType]) {
                                obj[eventType] = {};
                            }
                            obj[eventType][each] = evt.replace(
                                'Key',
                                this.findKeyName(each),
                            );
                            return obj;
                        }, {}),
                    );
                } else {
                    throw new Error('Only onKey events are supported.');
                }
            }
            return hashMap;
        };
        getInstance() {
            const ref = this.instanceRef;
            return ref.getInstance ? ref.getInstance() : ref;
        }
        getRef = (ref) => (this.instanceRef = ref);
        render() {
            const {...props} = this.props;
            props.ref = this.getRef;
            return (
                <div {...this.evts}>
                    <WrappedComponent {...props} />
                </div>
            );
        }
    };
}
