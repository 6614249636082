import classNames from 'classnames';
import {useLayoutEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {DropdownContainer} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {NavigationTree} from 'Components/navigationTree';
import {
    selectAllBreadCrumbData,
    selectIsNewNavigation,
    selectCurrentPageId,
    selectStaticParentId,
    selectIsSubNavHiddenByUser,
} from 'Root/core/state/navigation/selectors';
import {logEventToGainsight} from 'Services/gainsight';
import {toggleSubNavItemAction} from 'State/navigation/actions';
import {DESKTOP, getScreenType, ScreenType, TABLET} from 'Utils/screenType';

import {calculateBreadCrumbItemsToHide} from './calculateBreadCrumbItemsToHide';
import {CopyBreadCrumbsButton} from './CopyBreadCrumbsButton';
import {ToggleSideNavButton} from './ToggleSideNavButton';

import type {TransformedNavigationItem} from 'State/navigation/types';

import './breadCrumbs.scss';

type BreadCrumbFolderProps = {
    item: TransformedNavigationItem;
    currentPageId?: string | null;
    staticParentId?: string | null;
};
const BreadCrumbFolder = ({
    item,
    currentPageId,
    staticParentId,
}: BreadCrumbFolderProps) => {
    const [expandedIds, setExpandedIds] = useState<Record<string, boolean>>({});
    return (
        <DropdownContainer
            dropdownWidth="300px"
            renderDropdownField={({toggleDropdown, isDropdownVisible}) => (
                <KeyboardFocusableButton
                    borderRadius="4px"
                    buttonWrapperClassName={classNames(
                        'bread-crumb__folder-container',
                        {
                            'bread-crumb__folder-container--expanded':
                                isDropdownVisible,
                        },
                    )}
                    onClick={() => toggleDropdown()}
                >
                    <Icon
                        className="bread-crumb-arrow"
                        iconName="arrow-go-down"
                    />
                    {item.text}
                </KeyboardFocusableButton>
            )}
            renderDropdownContents={() => {
                if (typeof item.items === 'undefined') {
                    console.error(
                        'BreadCrumbFolder rendered with no items',
                        item,
                    );
                    return null;
                }
                return (
                    <NavigationTree
                        includeSubModules
                        treeData={item.items}
                        expandedIds={expandedIds}
                        currentPageId={currentPageId}
                        staticParentId={staticParentId}
                        toggleItemExpanded={({itemId}) =>
                            setExpandedIds({
                                ...expandedIds,
                                [itemId]: !expandedIds[itemId],
                            })
                        }
                        gainsightEventType="Breadcrumb Folder Link"
                    />
                );
            }}
        />
    );
};

type BreadCrumbItemProps = {
    item: TransformedNavigationItem;
    currentPageId: string | null;
    staticParentId: string | null;
    isCurrentPage: boolean;
    allBreadCrumbData?: TransformedNavigationItem[];
};
const BreadCrumbItem = ({
    item,
    currentPageId,
    staticParentId,
    isCurrentPage,
    allBreadCrumbData,
}: BreadCrumbItemProps) => (
    <div role="presentation" className="bread-crumb-item__wrapper">
        {item.url && (
            <KeyboardFocusableLink
                disableDefaultLinkStyles
                url={item.url}
                linkWrapperClassName="bread-crumb__link-container"
                style={{fontWeight: isCurrentPage ? 'bold' : 'normal'}}
                onClick={() => {
                    logEventToGainsight('NavigationItemVisited', {
                        url: item.url ?? item.entryPoint,
                        type: 'Breadcrumb Link',
                    });
                }}
            >
                {item.text}
            </KeyboardFocusableLink>
        )}
        {item.items && (
            <BreadCrumbFolder
                item={item}
                currentPageId={currentPageId}
                staticParentId={staticParentId}
            />
        )}
        {!isCurrentPage && (
            <div role="presentation" className="bread-crumb-divider">
                {'/'}
            </div>
        )}
        {isCurrentPage && (
            <CopyBreadCrumbsButton breadCrumbData={allBreadCrumbData || []} />
        )}
    </div>
);

type BreadCrumbProps = {
    isMobileLayout?: boolean;
    isNewNavigation: ReturnType<typeof selectIsNewNavigation>;
    allBreadCrumbData: ReturnType<typeof selectAllBreadCrumbData>;
    currentPageId: ReturnType<typeof selectCurrentPageId>;
    staticParentId: ReturnType<typeof selectStaticParentId>;
    isSubNavHiddenByUser: ReturnType<typeof selectIsSubNavHiddenByUser>;
};
export const UnconnectedBreadCrumbs = ({
    isNewNavigation,
    allBreadCrumbData,
    currentPageId,
    staticParentId,
    isSubNavHiddenByUser,
}: BreadCrumbProps) => {
    const [collapseCrumbs, setCollapseCrumbs] = useState<boolean>(true);
    const [numberOfElementsToHide, setNumberOfElementsToHide] =
        useState<number>(0);
    const [showCollapseBreadCrumbsButton, setShowCollapseBreadCrumbsButton] =
        useState<boolean>(false);

    const breadCrumbContainerRef = useRef<HTMLDivElement | null>(null);
    const breadCrumbItemWidthsRef = useRef<number[]>([]);
    const screenType: ScreenType = getScreenType();

    useLayoutEffect(() => {
        const fitBreadCrumbsIntoContainer = () => {
            const container = breadCrumbContainerRef.current;
            if (!container) {
                return;
            }
            // if container is not overflowing and the list is expanded, return
            if (
                container.clientWidth === container.scrollWidth &&
                !collapseCrumbs
            ) {
                return;
            }

            const childElementsToHide = calculateBreadCrumbItemsToHide(
                container,
                breadCrumbItemWidthsRef,
            );
            setNumberOfElementsToHide(childElementsToHide);

            if (childElementsToHide > 0) {
                setShowCollapseBreadCrumbsButton(true);
            } else {
                setShowCollapseBreadCrumbsButton(false);
            }
        };

        fitBreadCrumbsIntoContainer();
        const resizeHandler = () => {
            fitBreadCrumbsIntoContainer();
        };
        addEventListener('resize', resizeHandler);

        return () => {
            removeEventListener('resize', resizeHandler);
        };
    }, [collapseCrumbs, isSubNavHiddenByUser]);

    if (
        !isNewNavigation ||
        !allBreadCrumbData ||
        allBreadCrumbData.length === 0
    ) {
        return null;
    }

    const renderVariableBreadCrumbs = () =>
        allBreadCrumbData?.map((item, index) => {
            if (index === 0) {
                // First item is rendered earlier in component
                return null;
            }
            // numberOfElementsToHide is state that will tell us how many breadcrumbs to hide to prevent overflow
            if (collapseCrumbs && index <= numberOfElementsToHide) {
                return null;
            }
            const isCurrentPage = index + 1 === allBreadCrumbData.length;

            return (
                <BreadCrumbItem
                    item={item}
                    key={index}
                    currentPageId={currentPageId}
                    staticParentId={staticParentId}
                    isCurrentPage={isCurrentPage}
                    allBreadCrumbData={allBreadCrumbData}
                />
            );
        });

    return (
        <nav className="bread-crumbs__outer-wrapper" aria-label="bread crumbs">
            <div
                role="presentation"
                className={classNames('bread-crumbs__container', {
                    'bread-crumbs__container-wrap': !collapseCrumbs,
                })}
            >
                {(screenType === DESKTOP || screenType === TABLET) && (
                    <div className="toggle-side-nav-button__wrapper">
                        <ToggleSideNavButton
                            isSubNavHiddenByUser={isSubNavHiddenByUser}
                        />
                    </div>
                )}
                <BreadCrumbItem
                    item={allBreadCrumbData[0]}
                    currentPageId={currentPageId}
                    staticParentId={staticParentId}
                    isCurrentPage={false}
                />
                {showCollapseBreadCrumbsButton && (
                    <div className="bread-crumb-item__wrapper">
                        <KeyboardFocusableButton
                            borderRadius="5px"
                            buttonWrapperClassName={classNames(
                                'bread-crumb-expand-button',
                                {
                                    'bread-crumb-expand-button__active':
                                        !collapseCrumbs,
                                },
                            )}
                            onClick={() => {
                                setCollapseCrumbs(!collapseCrumbs);
                                if (!collapseCrumbs) {
                                    setNumberOfElementsToHide(0);
                                }
                            }}
                        >
                            {'···'}
                        </KeyboardFocusableButton>
                        <div
                            role="presentation"
                            className="bread-crumb-divider"
                        >
                            {'/'}
                        </div>
                    </div>
                )}

                <span
                    role="presentation"
                    className="bread-crumbs__variable-items-container"
                    // It's important that we have a stable ref to this span
                    style={collapseCrumbs ? {} : {position: 'absolute'}}
                    ref={breadCrumbContainerRef}
                >
                    {collapseCrumbs && renderVariableBreadCrumbs()}
                </span>
                {!collapseCrumbs && renderVariableBreadCrumbs()}
            </div>
        </nav>
    );
};

const mapStateToProps = (state) => ({
    isNewNavigation: selectIsNewNavigation(state),
    allBreadCrumbData: selectAllBreadCrumbData(state),
    currentPageId: selectCurrentPageId(state),
    staticParentId: selectStaticParentId(state),
    isSubNavHiddenByUser: selectIsSubNavHiddenByUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleSubNavItem: toggleSubNavItemAction,
        },
        dispatch,
    );

export const BreadCrumbs = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedBreadCrumbs);
