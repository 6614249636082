import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {loadPage} from 'Interfaces/Loader';
import url from 'Services/url/Url';

import './askArborButton.scss';

const AskArborLogo = () => (
    <svg viewBox="0 0 14 14">
        <path
            d="M9.76794 5.1605L10.9845 5.50809C11.1003 5.54083 11.1784 5.64662 11.1784 5.765C11.1784 5.88338 11.1003 5.98917 10.9845 6.02191L9.76794 6.3695L9.42036 7.58606C9.38761 7.70192 9.28183 7.78 9.16344 7.78C9.04506 7.78 8.93928 7.70192 8.90653 7.58606L8.55894 6.3695L7.34239 6.02191C7.22653 5.98917 7.14844 5.88338 7.14844 5.765C7.14844 5.64662 7.22653 5.54083 7.34239 5.50809L8.55894 5.1605L8.90653 3.94394C8.93928 3.82808 9.04506 3.75 9.16344 3.75C9.28183 3.75 9.38761 3.82808 9.42036 3.94394L9.76794 5.1605Z"
            fill="#F7941D"
        />
        <path
            d="M6.67581 3.31451L7.42144 3.52755C7.49245 3.54762 7.54031 3.61246 7.54031 3.68501C7.54031 3.75757 7.49245 3.82241 7.42144 3.84247L6.67581 4.05551L6.46277 4.80114C6.4427 4.87216 6.37786 4.92001 6.30531 4.92001C6.23275 4.92001 6.16791 4.87216 6.14784 4.80114L5.93481 4.05551L5.18918 3.84247C5.11816 3.82241 5.07031 3.75757 5.07031 3.68501C5.07031 3.61246 5.11816 3.54762 5.18918 3.52755L5.93481 3.31451L6.14784 2.56888C6.16791 2.49787 6.23275 2.45001 6.30531 2.45001C6.37786 2.45001 6.4427 2.49787 6.46277 2.56888L6.67581 3.31451Z"
            fill="#CFDD27"
        />
        <path
            d="M6.49287 7.78652L8.18036 8.26865C8.34107 8.31407 8.44938 8.46081 8.44938 8.62502C8.44938 8.78922 8.34107 8.93596 8.18036 8.98138L6.49287 9.46352L6.01074 11.151C5.96532 11.3117 5.81857 11.42 5.65437 11.42C5.49016 11.42 5.34342 11.3117 5.298 11.151L4.81587 9.46352L3.1284 8.98138C2.96768 8.93596 2.85938 8.78922 2.85938 8.62502C2.85938 8.46081 2.96768 8.31407 3.1284 8.26865L4.81587 7.78652L5.298 6.09904C5.34342 5.93832 5.49016 5.83002 5.65437 5.83002C5.81857 5.83002 5.96532 5.93832 6.01074 6.09904L6.49287 7.78652Z"
            fill="#7BB93C"
        />
    </svg>
);

export const AskArborButton = () => {
    const handleClick = () => {
        loadPage(url.ASK_ARBOR.CHAT_PANEL);
    };

    return (
        <KeyboardFocusableButton
            onClick={handleClick}
            className="ask-arbor-button"
            buttonWrapperClassName="ask-arbor-button__wrapper"
        >
            <AskArborLogo />
            <span className="ask-arbor-button__text">Ask Arbor</span>
        </KeyboardFocusableButton>
    );
};
