import classNames from 'classnames';
import {useRef, useState} from 'react';
import {connect} from 'react-redux';

import {DropdownContainer} from 'Components/dropdown';
import {ClearButton} from 'Components/searchField';
import {areFloatingUIElementsOpen} from 'Interfaces/FloatingUIElements';
import {loadPage} from 'Interfaces/Loader';
import {MAC_CMD_CHARCODE} from 'Root/core/utils/symbolConstants';
import url from 'Services/url/Url';
import {NavigationReducerState} from 'State/navigation/reducers';
import {
    selectFlattenedNavigationTree,
    selectUserFavouritePages,
} from 'State/navigation/selectors';
import {ESCAPE_KEY_CODE} from 'Utils/keyCodeConstants';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {SearchDropdownContents} from './SearchDropdownContents';
import {FilterableEntity} from './SearchFilters/searchFilters';
import {SearchIcon} from './SearchIcon';
import {useHelpCentreSearch, useSearchQuery} from './utils';
import {filterPages} from './utils/filterPages';

import './searchbar.scss';

type SearchBarProps = {
    isDesktop?: boolean;
    isMobile?: boolean;
    flattenedTree: ReturnType<typeof selectFlattenedNavigationTree>;
    userFavouritePages: ReturnType<typeof selectUserFavouritePages>;
};

export const UnconnectedSearchBar = ({
    isDesktop = true,
    isMobile,
    flattenedTree,
    userFavouritePages,
}: SearchBarProps) => {
    const [inputValue, setInputValue] = useState('');
    const [searchFilter, setSearchFilter] = useState<FilterableEntity>('all');
    const [onlyCurrentResults, setOnlyCurrentResults] = useState(true);
    const clearInputField = () => {
        setInputValue('');
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const isMacRef = useRef(navigator.userAgent.indexOf('Mac OS X') !== -1);

    const inputRef = useRef<HTMLInputElement>(null);

    useComponentDidMount(() => {
        const listener = (e: KeyboardEvent) => {
            if (
                e.key === 'k' &&
                (isMacRef.current ? e.metaKey : e.ctrlKey) &&
                !areFloatingUIElementsOpen()
            ) {
                // some browsers have ctrl+k focusing the address bar
                e.preventDefault();

                if (inputRef.current) {
                    clearInputField();
                    inputRef.current.focus();
                }
            }
        };

        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    });

    const {searchGroups, isLoading, isQueryValid, isFetchingCompleted} =
        useSearchQuery({
            query: inputValue,
            shouldSuppressSearch: !isDropdownOpen,
            filter: searchFilter,
            onlyCurrentResults,
        });

    const filteredPages =
        searchFilter === 'all' || searchFilter === 'page'
            ? filterPages(flattenedTree, inputValue)
            : [];
    const filteredFavouritePages =
        searchFilter === 'all' || searchFilter === 'favourite'
            ? filterPages(
                  userFavouritePages.map((page) => ({
                      id: String(page.userFavouritePageId),
                      url: page.route,
                      text: page.customName,
                  })),
                  inputValue,
              )
            : [];

    let shouldSuppressHelpCentreSearch;

    if (searchFilter === 'help centre') {
        shouldSuppressHelpCentreSearch = false;
    } else {
        shouldSuppressHelpCentreSearch =
            !isDropdownOpen ||
            !isQueryValid ||
            !isFetchingCompleted ||
            searchGroups.length !== 0 ||
            filteredPages.length !== 0 ||
            filteredFavouritePages.length !== 0 ||
            searchFilter !== 'all';
    }

    const {articles, isLoading: isArticlesLoading} = useHelpCentreSearch({
        query: inputValue,
        shouldSuppressSearch: shouldSuppressHelpCentreSearch,
    });

    const handleChange =
        ({openDropdown}) =>
        (e) => {
            const newInputValue = e.target.value;
            setInputValue(newInputValue);
            openDropdown();
        };

    const handleFocus =
        ({openDropdown}) =>
        () => {
            openDropdown();
        };

    const handleSubmit =
        ({closeDropdown}) =>
        () => {
            if (inputValue.length) {
                if (
                    typeof (document.activeElement as HTMLElement)?.blur ===
                    'function'
                ) {
                    (document.activeElement as HTMLElement).blur();
                }
                closeDropdown();
                loadPage(
                    url.GLOBAL_SEARCH.SEARCH_UI +
                        inputValue +
                        '/search-period/ALL/academic-year-id/',
                );
            }
        };

    const handleKeyDown = (e) => {
        if (e.keyCode === ESCAPE_KEY_CODE) {
            clearInputField();
        }
    };

    const handleKeyPress =
        ({closeDropdown}) =>
        (e) => {
            if (e.key === 'Enter' && e.target.value) {
                handleSubmit({closeDropdown})();
            }
        };

    const handleClearIconClick =
        ({focusDropdownField}) =>
        () => {
            clearInputField();
            focusDropdownField();
        };

    const isTablet = !isDesktop && !isMobile;
    return (
        <DropdownContainer
            containerClassName={classNames('search-bar__container', {
                'search-bar__container--tablet-screen': isTablet,
            })}
            dropdownWidth="600px"
            alignDropdown="start"
            fieldClassName="search-bar__input-container"
            renderDropdownField={({
                closeDropdown,
                focusDropdownField,
                openDropdown,
            }) => (
                <>
                    <input
                        aria-label="Search for pages, students, guardians and more"
                        value={inputValue}
                        onFocus={handleFocus({openDropdown})}
                        onChange={handleChange({openDropdown})}
                        onKeyPress={handleKeyPress({closeDropdown})}
                        onKeyDown={handleKeyDown}
                        placeholder="Search or ask..."
                        className="search-bar__input"
                        autoComplete="off" // Prevent browsers trying to autofill this field
                        ref={inputRef}
                    />
                    <span
                        className={classNames('searchbar__icon__container', {
                            'searchbar__icon--tablet-screen': isTablet,
                            'searchbar__icon--mobile-screen': isMobile,
                        })}
                    >
                        <SearchIcon />
                    </span>
                    <div className="search-bar__float-right">
                        <span className="search-bar__shortcut-label">
                            <span className="search-bar__shortcut-label-key">
                                {isMacRef.current
                                    ? String.fromCharCode(MAC_CMD_CHARCODE)
                                    : 'Ctrl'}
                            </span>
                            +
                            <span className="search-bar__shortcut-label-key">
                                K
                            </span>
                        </span>
                        {inputValue && (
                            <ClearButton
                                onClick={handleClearIconClick({
                                    focusDropdownField,
                                })}
                                {...(isTablet
                                    ? {
                                          className:
                                              'search-bar__clear-icon__button-container--tablet-screen',
                                      }
                                    : {})}
                            />
                        )}
                    </div>
                </>
            )}
            renderDropdownContents={({closeDropdown}) => (
                <SearchDropdownContents
                    isLoading={isLoading}
                    searchValue={inputValue}
                    groups={searchGroups}
                    onViewAllClick={handleSubmit({closeDropdown})}
                    shouldShowResults={isQueryValid}
                    filteredPages={filteredPages}
                    filteredFavouritePages={filteredFavouritePages}
                    articles={articles}
                    isArticlesLoading={isArticlesLoading}
                    searchFilter={searchFilter}
                    onSearchFilterChange={setSearchFilter}
                    onlyCurrentResults={onlyCurrentResults}
                    onCurrentToggleChange={setOnlyCurrentResults}
                />
            )}
            dropdownOpenedHandler={() => {
                setIsDropdownOpen(true);
            }}
            dropdownClosedCallback={() => {
                setIsDropdownOpen(false);
            }}
            useFocusableElementsQuery
        />
    );
};
const mapStateToProps = (state: {navigation: NavigationReducerState}) => ({
    flattenedTree: selectFlattenedNavigationTree(state),
    userFavouritePages: selectUserFavouritePages(state),
});
export const SearchBar = connect(mapStateToProps)(UnconnectedSearchBar);
