import classNames from 'classnames';

import {Button} from 'Components/button/base';
import {Icon} from 'Components/icon';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {back} from 'Interfaces/history/History';
import {loadPageWithModifierKeys} from 'Root/core/utils/loadPageWithModifierKeys';
import {stripHTMLTagsAndDecodeSymbols} from 'Root/core/utils/sanitizeHtml';

import {filterIconsData} from './FilterIconsData';
import {ColumnTitleData, TitleAction} from './types';

import './columnTitle.scss';

const IconWithTooltip = tooltipWrapper(Icon);

type ColumnTitleProps = {
    columnTitle?: ColumnTitleData;
    backButtonUrl?: string;
    backButton?: string;
    titleAction?: TitleAction;
};

export const ColumnTitle = ({
    columnTitle,
    backButtonUrl,
    backButton,
    titleAction,
}: ColumnTitleProps) => {
    const parsedIcons = columnTitle?.icons
        ? filterIconsData(columnTitle.icons)
        : null;

    return (
        <div className="column-title">
            {(backButtonUrl || backButton) && (
                <Button
                    className="column-title__button"
                    onClick={(e) => {
                        if (backButtonUrl) {
                            loadPageWithModifierKeys(backButtonUrl, e);
                            return;
                        }
                        if (backButton) {
                            back();
                        }
                    }}
                    text="« Back"
                />
            )}
            <h1
                className={classNames('column-title__name', {
                    'column-title__name--center': backButtonUrl || backButton,
                })}
                style={columnTitle?.color ? {color: columnTitle.color} : {}}
            >
                {stripHTMLTagsAndDecodeSymbols(columnTitle?.title ?? '')}
                {columnTitle?.tooltip && (
                    <IconWithTooltip
                        className="column-title__icon"
                        iconName="help"
                        tooltip={columnTitle?.tooltip}
                    ></IconWithTooltip>
                )}

                {parsedIcons &&
                    parsedIcons.map((icon, index) => (
                        <IconWithTooltip
                            className="column-title__icon"
                            iconName={icon.name}
                            tooltip={icon.tooltip}
                            key={index}
                        ></IconWithTooltip>
                    ))}
            </h1>

            {titleAction && (
                <Button
                    className="column-title__button"
                    onClick={(e) => {
                        if (titleAction.url) {
                            loadPageWithModifierKeys(titleAction.url, e);
                        }
                    }}
                    disabled={titleAction.disabled}
                    tooltipMIS={titleAction.tooltip}
                    color={titleAction.color}
                    text={titleAction.title}
                    askArborLogo={titleAction.askArborLogo}
                />
            )}
        </div>
    );
};
