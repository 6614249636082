import type {FlattenedTreeItem} from 'State/navigation/utils/flattenNavigationTree';

export const filterPages = (
    flattenedTree: FlattenedTreeItem[],
    inputValue: string,
) => {
    if (inputValue === '') {
        return [];
    }
    const categoricalFiltered = flattenedTree.reduce<{
        titleMatches: (FlattenedTreeItem & {
            matchType: 'title-exact';
            text: string;
        })[];
        descriptionMatches: (FlattenedTreeItem & {
            matchType: 'description-exact';
            description: string;
        })[];
        wordMatches: (FlattenedTreeItem & {
            matchType: 'word-match';
            description?: string;
            text: string;
            words: string[];
        })[];
        alternativeTitleMatches: (FlattenedTreeItem & {
            matchType: 'alternative-title-match';
            alternativeName: string;
        })[];
    }>(
        (acc, item) => {
            const {text, description} = item;
            if (item.hidden) {
                return acc;
            }
            if (typeof text === 'undefined') {
                return acc;
            }
            const lowerInput = inputValue.toLowerCase();
            const lowerText = text.toLowerCase();
            if (lowerText.includes(lowerInput)) {
                return {
                    ...acc,
                    titleMatches: [
                        ...acc.titleMatches,
                        {
                            ...item,
                            matchType: 'title-exact',
                            text,
                        },
                    ],
                };
            }
            if (item.alternativeNames) {
                const foundNameMatch = item.alternativeNames.find((name) => {
                    const lowerAlternateName = name.toLowerCase();
                    return lowerAlternateName.includes(lowerInput);
                });
                if (foundNameMatch) {
                    return {
                        ...acc,
                        alternativeTitleMatches: [
                            ...acc.alternativeTitleMatches,
                            {
                                ...item,
                                matchType: 'alternative-title-match',
                                alternativeName: foundNameMatch,
                            },
                        ],
                    };
                }
            }

            if (
                typeof description !== 'undefined' &&
                description.toLowerCase().includes(lowerInput)
            ) {
                return {
                    ...acc,
                    descriptionMatches: [
                        ...acc.descriptionMatches,
                        {
                            ...item,
                            matchType: 'description-exact',
                            description,
                        },
                    ],
                };
            }

            if (inputValue.includes(' ')) {
                // try splitting up input value and searching for each word
                const words = lowerInput
                    .split(' ')
                    .filter(
                        (splitItem) =>
                            typeof splitItem === 'string' && splitItem !== '',
                    );
                if (
                    !words.find(
                        (word) =>
                            !(
                                typeof description !== 'undefined' &&
                                description?.toLowerCase().includes(word)
                            ) && !lowerText.includes(word),
                    )
                ) {
                    return {
                        ...acc,
                        wordMatches: [
                            ...acc.wordMatches,
                            {
                                ...item,
                                matchType: 'word-match',
                                description,
                                text,
                                words,
                            },
                        ],
                    };
                }
            }

            return acc;
        },
        {
            titleMatches: [],
            descriptionMatches: [],
            wordMatches: [],
            alternativeTitleMatches: [],
        },
    );

    // For matches in the title, order by position in string of match
    categoricalFiltered.titleMatches.sort((itemA, itemB) => {
        const itemAIndex = itemA.text
            .toLowerCase()
            .indexOf(inputValue.toLowerCase());
        const itemBIndex = itemB.text
            .toLowerCase()
            .indexOf(inputValue.toLowerCase());
        return itemAIndex - itemBIndex;
    });

    return [
        ...categoricalFiltered.titleMatches,
        ...categoricalFiltered.alternativeTitleMatches,
        ...categoricalFiltered.descriptionMatches,
        ...categoricalFiltered.wordMatches,
    ];
};
