import classNames from 'classnames';

import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import './InfoPanel.scss';
type infoPanelProperty = {
    label: string;
    value: string;
    url: string;
};

type infoPanelTag = {
    color: string;
    label: string;
};

type infoPanelProps = {
    header: {
        hidden: boolean;
    };
    id: string;
    picture?: string;
    pictureUrl?: string;
    subtitle?: string;
    title: string;
    url?: string;
    type?: string;
    properties: infoPanelProperty[];
    tags?: infoPanelTag[];
};

export const InfoPanel = (props: infoPanelProps) => {
    const {picture, subtitle, title, properties, pictureUrl, tags, url} = props;
    const studentPictureUrl =
        typeof pictureUrl === 'string' && pictureUrl !== '' ? pictureUrl : url;
    const studentImgClass = picture?.includes('circle')
        ? 'info-panel-picture--circle'
        : 'info-panel-picture';

    return (
        <section aria-label="Info Panel Component" className="info-panel">
            <section
                aria-label="Info Panel Header"
                className="info-panel-header"
            >
                {url ? (
                    <KeyboardFocusableLink url={url} disableDefaultLinkStyles>
                        <h3
                            className="info-panel-title info-panel-title--link"
                            dangerouslySetInnerHTML={{__html: title}}
                        ></h3>
                    </KeyboardFocusableLink>
                ) : (
                    <h3
                        className="info-panel-title"
                        dangerouslySetInnerHTML={{__html: title}}
                    ></h3>
                )}
                {subtitle && (
                    <span
                        className="info-panel-subtitle"
                        dangerouslySetInnerHTML={{__html: subtitle}}
                    ></span>
                )}
            </section>
            <section
                aria-label="Info Panel Content"
                className="info-panel-content"
            >
                <section
                    className={classNames('info-panel-properties', {
                        'info-panel-properties--no-tags':
                            !tags || tags.length === 0,
                    })}
                >
                    <ul className="info-panel-properties">
                        {properties.map((property, index) =>
                            property.url ? (
                                <KeyboardFocusableLink
                                    url={property.url}
                                    disableDefaultLinkStyles
                                    linkWrapperClassName="info-panel-property--link info-panel-property"
                                    key={index}
                                >
                                    <li>
                                        <h4 className="info-panel-property-label">
                                            {property.label}
                                        </h4>
                                        <h4 className="info-panel-property-value">
                                            {property.value}
                                            {property.url && (
                                                <Icon
                                                    iconName="arrow-go-right"
                                                    className={
                                                        'arrow-go-right info-panel-icon'
                                                    }
                                                />
                                            )}
                                        </h4>
                                    </li>
                                </KeyboardFocusableLink>
                            ) : (
                                <li className="info-panel-property" key={index}>
                                    <div className="info-panel-property-label">
                                        {property.label}
                                    </div>
                                    <div className="info-panel-property-value">
                                        {property.value}
                                    </div>
                                </li>
                            ),
                        )}
                    </ul>
                </section>
                {tags && tags.length > 0 && (
                    <ul
                        aria-label="Info Panel Tags"
                        className="info-panel-tags"
                    >
                        {tags.map((tag, index) => (
                            <li
                                style={{color: tag.color}}
                                className={
                                    'info-panel-tag info-panel-tag--' +
                                    tag.color
                                }
                                key={index}
                            >
                                {tag.label}
                            </li>
                        ))}
                    </ul>
                )}
            </section>
            {pictureUrl || url ? (
                <KeyboardFocusableLink
                    url={studentPictureUrl}
                    disableDefaultLinkStyles
                    linkWrapperClassName={
                        'info-panel-picture info-panel-picture--link ' +
                        studentImgClass
                    }
                    role="presentation"
                >
                    <img
                        alt="Info panel picture"
                        className="info-panel-picture-inner"
                        src={picture}
                    />
                </KeyboardFocusableLink>
            ) : (
                <div
                    role="presentation"
                    className={'info-panel-picture ' + studentImgClass}
                >
                    <img
                        alt="Info panel picture"
                        className="info-panel-picture-inner"
                        src={picture}
                    />
                </div>
            )}
        </section>
    );
};
