import {JSXElementConstructor, ReactElement} from 'react';

import './Layout.scss';
type LayoutColumnProps = {
    flex?: number;
    children:
        | ReactElement<any, string | JSXElementConstructor<any>>[]
        | ReactElement<any, string | JSXElementConstructor<any>>;
};
export const LayoutColumn = ({children, flex}: LayoutColumnProps) => {
    return (
        <div style={{flex: flex || 1}} className="layout-column">
            {Array.isArray(children) ? (
                children.map((child) => (
                    <div className="column-element">{child}</div>
                ))
            ) : (
                <div>{children}</div>
            )}
        </div>
    );
};
