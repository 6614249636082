import {SYSTEM_NOTIFICATION} from 'Config/Events';
import pubsub from 'Services/pubsub/Pubsub';
import {ISystemNotification} from 'Types/systemNotifications';

import exportInterface from './Base';

export const addSystemNotification = (
    systemNotification: ISystemNotification,
) => {
    pubsub.publish(SYSTEM_NOTIFICATION.ADD, systemNotification);
};

export const registerSystemNotificationInterface = () => {
    exportInterface('SystemNotication', {add: addSystemNotification});
};
