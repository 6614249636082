import classnames from 'classnames';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {logEventToGainsight} from 'Services/gainsight';

import {FavouriteIcon} from './FavouriteIcon';

import type {TransformedNavigationItem} from 'State/navigation/types';

const IconWithTooltip = tooltipWrapper(Icon);

import './navigationTree.scss';

type NavigationItemProps = {
    expandedIds: Record<string, boolean>;
    toggleItemExpanded: (params: {itemId: string}) => void;
    item: TransformedNavigationItem;
    includeSubModules?: boolean;
    currentPageId?: string | null;
    isFavouritesEnabled?: boolean;
    isMobileLayout?: boolean;
    gainsightEventType?: string;
};

export const NavigationItem = ({
    item,
    expandedIds,
    toggleItemExpanded,
    includeSubModules,
    currentPageId,
    isFavouritesEnabled,
    gainsightEventType,
}: NavigationItemProps) => {
    const {favouritedPage} = item;

    const isFolderContainingCurrentPage = !!item?.items?.find(
        (innerItem) => innerItem.id === currentPageId,
    );
    const isItemExpanded = expandedIds[item.id];
    return (includeSubModules || !item.entryPoint) &&
        item.items &&
        item.items.length > 0 ? (
        <>
            <KeyboardFocusableButton
                className="navigation-tree__item"
                buttonWrapperClassName={classnames(
                    'navigation-tree__item__wrapper',
                    {
                        'navigation-tree__item__wrapper--hidden': item.hidden,
                        'navigation-tree__item-container--contains-selected-item':
                            isFolderContainingCurrentPage,
                    },
                )}
                borderRadius="0"
                insetFocusRing
                onClick={() => toggleItemExpanded({itemId: item.id})}
            >
                <span
                    className={classnames('navigation-tree__item__icon', {
                        'navigation-tree__item__icon--closed': !isItemExpanded,
                        'navigation-tree__item__icon--expanded': isItemExpanded,
                    })}
                    aria-label={
                        isItemExpanded
                            ? '(Click to collapse)'
                            : '(Click to expand)'
                    }
                />
                <span>{item.text}</span>
                {item.hidden && (
                    <IconWithTooltip
                        iconName="info"
                        tooltip="Folder is hidden because all child items are hidden"
                    ></IconWithTooltip>
                )}
            </KeyboardFocusableButton>
            {isItemExpanded && (
                <ul
                    className={classnames('navigation-tree__sub-items', {
                        'navigation-tree__item-container--contains-selected-item':
                            isFolderContainingCurrentPage,
                    })}
                >
                    {item.items.map((subItem, index) => (
                        <li
                            className="navigation-tree__sub-item-container"
                            key={index}
                        >
                            <NavigationItem
                                item={subItem}
                                expandedIds={expandedIds}
                                toggleItemExpanded={toggleItemExpanded}
                                currentPageId={currentPageId}
                                isFavouritesEnabled={isFavouritesEnabled}
                                gainsightEventType={gainsightEventType}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </>
    ) : (
        <>
            <div
                role="presentation"
                className={classnames('navigation-tree__item__page-link', {
                    'navigation-tree__item__page-link--selected':
                        item.selected ||
                        (currentPageId && item.id === currentPageId),
                    'navigation-tree__item__page-link--hidden': item.hidden,
                })}
            >
                <KeyboardFocusableLink
                    className="navigation-tree__item"
                    linkWrapperClassName={classnames(
                        'navigation-tree__item__wrapper',
                    )}
                    borderRadius="0"
                    insetFocusRing
                    url={item.url ?? item.entryPoint}
                    onClick={() =>
                        logEventToGainsight('NavigationItemVisited', {
                            url: item.url ?? item.entryPoint,
                            type: gainsightEventType,
                        })
                    }
                    disableDefaultLinkStyles
                >
                    <span
                        className={classnames('navigation-tree__item__icon', {
                            'navigation-tree__item__icon--closed':
                                !item.entryPoint &&
                                item.items &&
                                item.items.length > 0,
                        })}
                    />
                    <span>{item.text}</span>
                    {item.hidden && (
                        <IconWithTooltip
                            iconName="info"
                            tooltip={`Hidden because of ${
                                item.hiddenReason ??
                                JSON.stringify({
                                    isAllowedByAcl: item.isAllowedByAcl,
                                    isFeatureEnabled: item.isFeatureEnabled,
                                    isModuleEnabled: item.isModuleEnabled,
                                    unresolvedParams: item.unresolvedParams,
                                    isHiddenByCustomCondition:
                                        item.isHiddenByCustomCondition,
                                })
                            }`}
                        ></IconWithTooltip>
                    )}
                </KeyboardFocusableLink>
                {isFavouritesEnabled && item.text && item.url && (
                    <FavouriteIcon
                        url={item.url}
                        isCurrentPage={
                            item.selected || item.id === currentPageId
                        }
                        userFavouritePage={favouritedPage}
                        pageName={item.text}
                    />
                )}
            </div>
            {item.subTree && (
                <div role="presentation" className="navigation-tree__sub-items">
                    <NavigationItem
                        item={item.subTree}
                        expandedIds={expandedIds}
                        toggleItemExpanded={toggleItemExpanded}
                        currentPageId={currentPageId}
                        isFavouritesEnabled={isFavouritesEnabled}
                        gainsightEventType={gainsightEventType}
                    />
                </div>
            )}
        </>
    );
};

type NavigationTreeProps = {
    expandedIds: Record<string, boolean>;
    toggleItemExpanded: (params: {itemId: string}) => void;
    treeData: TransformedNavigationItem[];
    isMobileLayout?: boolean;
    includeSubModules?: boolean;
    currentPageId?: string | null;
    staticParentId?: string | null;
    isFavouritesEnabled?: boolean;
    gainsightEventType?: string;
};

export const NavigationTree = ({
    treeData,
    expandedIds,
    toggleItemExpanded,
    isMobileLayout,
    includeSubModules,
    currentPageId,
    isFavouritesEnabled,
    gainsightEventType,
}: NavigationTreeProps) => {
    if (!(treeData && treeData.length > 0)) {
        return null;
    }
    return (
        <ul
            className={classnames('navigation-tree__items', {
                'navigation-tree__items--mobile': isMobileLayout,
            })}
        >
            {treeData &&
                treeData.map((item, index) => (
                    <li
                        key={`navigation-tree-item-${index}`}
                        className="navigation-tree__item-container"
                    >
                        <NavigationItem
                            item={item}
                            expandedIds={expandedIds}
                            toggleItemExpanded={toggleItemExpanded}
                            includeSubModules={includeSubModules}
                            currentPageId={currentPageId}
                            isFavouritesEnabled={isFavouritesEnabled}
                            isMobileLayout={isMobileLayout}
                            gainsightEventType={gainsightEventType}
                        />
                    </li>
                ))}
        </ul>
    );
};
