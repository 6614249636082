import {getUnsavedDataRegistryService} from 'Services/unsavedData';

import exportInterface from './Base';

export const hasInvalidDataSources = (args?: {formActionName?: string}) => {
    return getUnsavedDataRegistryService().hasInvalidDataSources(args);
};

export const getErrorMessage = (args?: {formActionName?: string}) => {
    return getUnsavedDataRegistryService().getErrorMessage(args);
};

export const dismiss = (args?: {formActionName?: string}) => {
    return getUnsavedDataRegistryService().dismiss(args);
};

export const registerUnsavedDataInterface = () => {
    exportInterface('UnsavedData', {
        hasInvalidDataSources,
        getErrorMessage,
        dismiss,
    });
};
