export const addGainsightScript = (gainsightKey) => {
    // This code is the contents of the gainsight script that was previously added in layout.phtml

    // It is an IIFE that loads a gainsight script, and has a buffer for events that are triggered
    // before the script is loaded

    // See https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK

    /* eslint-disable no-unused-expressions, no-sequences */
    (function (n, t, a, e, co) {
        var i = 'aptrinsic';
        (n[i] =
            n[i] ||
            function () {
                (n[i].q = n[i].q || []).push(arguments);
            }),
            (n[i].p = e);
        n[i].c = co;
        var r = t.createElement('script');
        (r.async = !0), (r.src = a + '?a=' + e);
        var c = t.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(r, c);
    })(
        window,
        document,
        'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
        gainsightKey,
    );
    /* eslint-enable no-unused-expressions, no-sequences */
};
