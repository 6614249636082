import classNames from 'classnames';
import * as React from 'react';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import {ClearIcon} from './ClearIcon';

import './clearButton.scss';

export const ClearButton = ({
    onClick,
    className,
}: React.HTMLProps<HTMLButtonElement>) => {
    return (
        <span
            data-testid="SearchField-clearButton"
            className={classNames(
                'search-field__clear-button__container',
                className,
            )}
        >
            <KeyboardFocusableButton
                onClick={onClick}
                buttonWrapperClassName="search-field__clear-icon__container"
                aria-label="Clear search results"
            >
                <ClearIcon />
            </KeyboardFocusableButton>
        </span>
    );
};
