import PropTypes from 'prop-types';

import {Icon} from 'Components/icon';

import {Behaviour} from './Behaviour';
import {Cog} from './Cog';
import {Comment} from './Comment';
import {Cover} from './Cover';
import {Intervention} from './Intervention';
import {LineChart} from './LineChart';
import {Staff} from './Staff';
import {Star} from './Star';
import {Student} from './Student';
import {Students} from './Students';

export function DropdownMenuIcon({type, className}) {
    const defaultWidth = '16px';

    switch (type) {
        case '':
            return null;

        case 'intervention':
            return <Intervention width={defaultWidth} className={className} />;

        case 'student':
            return <Student width={defaultWidth} className={className} />;

        case 'group':
            return <Students width={defaultWidth} className={className} />;

        case 'exchange':
            return <Cover width={defaultWidth} className={className} />;

        case 'teacher':
            return <Staff width={defaultWidth} className={className} />;

        case 'gear':
            return <Cog width={defaultWidth} className={className} />;

        case 'line-chart':
            return <LineChart width={defaultWidth} className={className} />;

        case 'mail':
            return <Comment width={defaultWidth} className={className} />;

        case 'star':
            return <Star width={defaultWidth} className={className} />;

        case 'behaviour':
            return <Behaviour width={defaultWidth} className={className} />;

        default:
            return <Icon iconName={type} className={className} />;
    }
}

DropdownMenuIcon.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
};
