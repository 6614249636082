import PropTypes from 'prop-types';

import './notificationEmptyState.scss';

export const NotificationEmptyState = ({title, description, icon}) => (
    <div className="notification-empty-state-container">
        <span className="notification-empty-state-left-column">{icon}</span>
        <span className="notification-empty-state-right-column">
            <h3 className="notification-empty-state-title">{title}</h3>
            <p className="notification-empty-state-description">
                {description}
            </p>
        </span>
    </div>
);

NotificationEmptyState.propTypes = {
    title: PropTypes.string,
    description: PropTypes.node,
    icon: PropTypes.node,
};
