import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState, useCallback} from 'react';
import * as React from 'react';
import {createPortal} from 'react-dom';

import {LOADER} from 'Config/Events';
import {usePubSub} from 'Root/core/services/pubsub';
import {focusFirstFocusableElement, shiftFocusWithKeys} from 'Utils/focusUtils';
import {
    FloatingElementDirection,
    FloatingElementOffAxisAlignment,
    getFloatingElementPosition,
    IFloatingElementPosition,
} from 'Utils/getFloatingElementPosition';
import {
    ENTER_KEY_CODE,
    SPACE_KEY_CODE,
    ESCAPE_KEY_CODE,
    ARROW_UP_KEY_CODE,
    ARROW_DOWN_KEY_CODE,
    ARROW_LEFT_KEY_CODE,
    ARROW_RIGHT_KEY_CODE,
} from 'Utils/keyCodeConstants';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import type {ReactNode} from 'react';

import './dropdownContainer.scss';

export const DROPDOWN_PORTAL_ID = 'dropdown-portal';

export function initDropdownPortal() {
    const dropdownPortal = document.createElement('div');
    dropdownPortal.id = DROPDOWN_PORTAL_ID;
    dropdownPortal.style['z-index'] = 19950;
    dropdownPortal.style.position = 'fixed';
    document.body.appendChild(dropdownPortal);

    return dropdownPortal;
}

export interface IDropdownFieldRenderProps {
    dropdownContentsRef: HTMLDivElement | null;
    getDropdownFieldFocus: () => boolean;
    getDropdownContentsFocus: () => boolean;
    focusDropdownField: () => void;
    isDropdownVisible: boolean;
    openDropdown: () => void;
    closeDropdown: ({
        shouldMaintainFocus,
    }: {
        shouldMaintainFocus?: boolean;
    }) => void;
    toggleDropdown: () => void;
    toggleDropdownOnEnterOrSpace: (event: React.KeyboardEvent) => void;
}

export interface IDropdownContentsRenderProps {
    dropdownContentsRef: HTMLDivElement | null;
    dropdownFieldRef: HTMLDivElement | null;
    getDropdownFieldFocus: () => boolean;
    getDropdownContentsFocus: () => boolean;
    focusDropdownField: () => void;
    isDropdownVisible: boolean;
    closeDropdown: (params?: {shouldMaintainFocus?: boolean}) => void;
}

interface IDropdownContainerProps {
    renderDropdownField: (props: IDropdownFieldRenderProps) => ReactNode;
    renderDropdownContents: (props: IDropdownContentsRenderProps) => ReactNode;
    shiftFocusWithUpAndDownArrows?: boolean;
    shiftFocusWithLeftAndRightArrows?: boolean;
    dropdownWidth?: string;
    dropdownOpenedHandler?: () => void;
    containerClassName?: string;
    openOnFocus?: boolean;
    restrictPositionToVertical?: boolean;
    dropdownClosedCallback?: (isVisible: boolean) => void;
    preferPosition?: FloatingElementDirection;
    alignDropdown?: FloatingElementOffAxisAlignment;
    offset?: number;
    fieldClassName?: string;
    useFocusableElementsQuery?: boolean;
}

export const DropdownContainer = ({
    renderDropdownField,
    renderDropdownContents,
    shiftFocusWithUpAndDownArrows = true,
    shiftFocusWithLeftAndRightArrows,
    dropdownWidth,
    dropdownOpenedHandler,
    containerClassName,
    openOnFocus = true,
    restrictPositionToVertical = false,
    dropdownClosedCallback: dropdownClosedCallback,
    preferPosition,
    offset,
    alignDropdown,
    fieldClassName,
    useFocusableElementsQuery,
}: IDropdownContainerProps) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const isMouseDown = useRef(false);

    const dropdownField = useRef<HTMLDivElement>(null);
    const dropdownContents = useRef<HTMLDivElement>(null);

    const [dropdownPosition, setDropdownPosition] =
        useState<IFloatingElementPosition | null>(null);

    const targetNode = useRef(document.getElementById(DROPDOWN_PORTAL_ID));

    useEffect(() => {
        if (!targetNode.current) {
            // Fallback in case placeholder div is not already created (e.g. in tests)
            targetNode.current = initDropdownPortal();
        }
    }, []);

    const openDropdown = () => {
        setIsDropdownVisible(true);
    };
    const closeDropdown = ({shouldMaintainFocus = false} = {}) => {
        if (dropdownField.current?.hasAttribute('force-open')) {
            return;
        }

        setIsDropdownVisible(false);

        if (shouldMaintainFocus) {
            focusFirstFocusableElement(dropdownField.current);
        }
    };
    const toggleDropdown = () => {
        if (dropdownField.current?.hasAttribute('force-open')) {
            setIsDropdownVisible(true);
            return;
        }

        setIsDropdownVisible((prevValue) => !prevValue);
    };

    usePubSub(LOADER.PAGE_LOAD, () => {
        closeDropdown();
    });

    useComponentDidMount(() => {
        const mousedownListener = () => {
            // This is used in conjunction with the mouseupListener, and the containerFocus and
            // containerFocus callbacks. "mousedown" events occur before "focus" or "blur" events,
            // and so we can use them to keep track of whether the user is currently clicking when
            // the focus/blur event happens.
            isMouseDown.current = true;
        };
        const mouseupListener = () => {
            isMouseDown.current = false;
        };
        const clickListener = (e: MouseEvent) => {
            // Listen for clicks outside the dropdown and close the dropdown
            if (
                !(
                    dropdownField.current?.contains(e.target as Node) ||
                    dropdownContents.current?.contains(e.target as Node)
                )
            ) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', mousedownListener);
        document.addEventListener('mouseup', mouseupListener);
        document.addEventListener('click', clickListener);
        return () => {
            document.removeEventListener('mousedown', mousedownListener);
            document.removeEventListener('mouseup', mouseupListener);
            document.removeEventListener('click', clickListener);
        };
    });

    const repositionDropdown = useCallback(() => {
        if (dropdownField.current && dropdownContents.current) {
            setDropdownPosition(
                getFloatingElementPosition(
                    dropdownField.current,
                    dropdownContents.current,
                    {
                        offset: offset ?? 4,
                        allowHorizontal: !restrictPositionToVertical,
                        preferPosition,
                        offAxisAlignment: alignDropdown,
                    },
                ),
            );
        }
    }, [alignDropdown, preferPosition, restrictPositionToVertical, offset]);

    useResizeObserver(dropdownField.current, () => {
        if (isDropdownVisible) {
            repositionDropdown();
        }
    });

    useEffect(() => {
        const onScroll: EventListener = (e) => {
            if (dropdownContents.current?.contains(e.target as Node)) {
                // Don't reposition on scrolls inside the dropdown contents, so that
                // dropdowns can scroll internally without affecting anything
                return;
            }
            repositionDropdown();
        };
        const onResize: EventListener = () => {
            repositionDropdown();
        };

        if (isDropdownVisible) {
            // While dropdown was closed, the screen may have been resized, scrolled, and so
            // positioning twice helps us ensure that the position is accurate.
            repositionDropdown();
            setTimeout(() => {
                repositionDropdown();
            });
            if (
                dropdownOpenedHandler &&
                typeof dropdownOpenedHandler === 'function'
            ) {
                dropdownOpenedHandler();
            }

            // 3rd parameter set to `true` to use the capture phase of the event,
            // as we want to listen to all scroll events
            document.addEventListener('scroll', onScroll, true);
            window.addEventListener('resize', onResize);
        } else {
            if (
                dropdownClosedCallback &&
                typeof dropdownClosedCallback === 'function'
            ) {
                dropdownClosedCallback(false);
            }

            document.removeEventListener('scroll', onScroll, true);
            window.removeEventListener('resize', onResize);
        }
        return () => {
            if (isDropdownVisible) {
                document.removeEventListener('scroll', onScroll, true);
                window.removeEventListener('resize', onResize);
            }
        };
        // some of the "dependencies" of this effect are mutable, but we only want it to
        // run on dropdown visibility changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownVisible]);

    const getDropdownFieldFocus = () => {
        return dropdownField.current?.contains(document.activeElement) ?? false;
    };
    const getDropdownContentsFocus = () => {
        return (
            dropdownContents.current?.contains(document.activeElement) ?? false
        );
    };
    const focusDropdownField = () => {
        focusFirstFocusableElement(dropdownField.current);
    };

    const containerKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        // Key handling that affects the component and all children
        if (e.keyCode === ESCAPE_KEY_CODE) {
            e.preventDefault();
            focusDropdownField();
            closeDropdown();
        }
    };
    const containerFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        // We only want this to be triggered when the field is focused by keyboard
        if (!isMouseDown.current && openOnFocus) {
            const container = e.currentTarget;
            const previouslyFocusedElement = e.relatedTarget;
            if (
                !container.contains(previouslyFocusedElement) &&
                !dropdownContents.current?.contains(previouslyFocusedElement)
            ) {
                // Focus has just entered the dropdown, so open the dropdown
                openDropdown();
            }
        }
    };
    const containerBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        // We only want this to be triggered when the field is blurred by keyboard
        if (!isMouseDown.current) {
            const nextElementToReceveFocus = e.relatedTarget;
            if (
                !dropdownField.current?.contains(nextElementToReceveFocus) &&
                !dropdownContents.current?.contains(nextElementToReceveFocus)
            ) {
                // The focus is no longer anywhere in the dropdown, so close the dropdown
                closeDropdown();
            }
        }
    };

    const toggleDropdownOnEnterOrSpace = (e: React.KeyboardEvent) => {
        if (e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) {
            e.preventDefault();
            focusDropdownField();
            toggleDropdown();
        }
    };

    const focusDropdownContentsOnDownArrow = (e: React.KeyboardEvent) => {
        // This only works on a onKeyDown callback because onKeyPress ignores arrow keys
        if (
            shiftFocusWithUpAndDownArrows &&
            e.keyCode === ARROW_DOWN_KEY_CODE
        ) {
            e.preventDefault();
            focusFirstFocusableElement(dropdownContents.current);
        }
    };

    const dropdownContentsKeyPress = () => {
        // Note: this function should be applied to the keyDown event instead of keyPress
        // because keyPress ignores arrow keys

        // Keyboard controls to shift the focus forward and backwards through the focusable
        // children of the dropdownContents are set here. getShiftFocusWithLeftAndRightArrows defaults
        // to true as it normally useful to have on a dropdown. Make sure that any other Key handlers
        // in the dropdown contents do not call e.preventDefault unnecessarily else it will break these
        // keyboard controls (For example if adding a key listener for the "x" key, only call preventDefault
        // if the "x" key has actually been pressed).
        return shiftFocusWithKeys({
            forwardKeyCodes: [
                ...(shiftFocusWithUpAndDownArrows ? [ARROW_DOWN_KEY_CODE] : []),
                ...(shiftFocusWithLeftAndRightArrows
                    ? [ARROW_RIGHT_KEY_CODE]
                    : []),
            ],
            backwardKeyCodes: [
                ...(shiftFocusWithUpAndDownArrows ? [ARROW_UP_KEY_CODE] : []),
                ...(shiftFocusWithLeftAndRightArrows
                    ? [ARROW_LEFT_KEY_CODE]
                    : []),
            ],
            containerElement: dropdownContents.current,
            tabHandler: () => {
                focusDropdownField();
                closeDropdown();
            },
            backFromFirstHandler: () => {
                focusDropdownField();
            },
            useFocusableElementsQuery,
        });
        // Note: the return type of this function needs to be a function: (e) => {}
    };

    const renderContents = () => (
        <div
            ref={dropdownContents}
            className={classNames('dropdown-contents-wrapper', {
                'dropdown-open': isDropdownVisible,
            })}
            onKeyDown={dropdownContentsKeyPress()}
            style={{
                ...(dropdownWidth
                    ? {minWidth: `min(100vw, ${dropdownWidth})`}
                    : {}),
                ...(dropdownPosition !== null &&
                typeof dropdownPosition !== 'undefined'
                    ? {
                          left: dropdownPosition.x + 'px',
                          ...(dropdownPosition.position === 'top'
                              ? {bottom: dropdownPosition.bottom}
                              : {top: dropdownPosition.y + 'px'}),
                          maxWidth: dropdownPosition.maxWidth,
                          maxHeight: dropdownPosition.maxHeight,
                          width: dropdownPosition.fixedElementWidth,
                      }
                    : {}),
            }}
        >
            {renderDropdownContents({
                dropdownContentsRef: dropdownContents.current,
                dropdownFieldRef: dropdownField.current,
                getDropdownFieldFocus: getDropdownFieldFocus,
                focusDropdownField: focusDropdownField,
                getDropdownContentsFocus: getDropdownContentsFocus,
                closeDropdown: closeDropdown,
                isDropdownVisible: isDropdownVisible,
            })}
        </div>
    );

    return (
        <div
            className={classNames('dropdown-container', containerClassName, {
                'dropdown-open': isDropdownVisible,
            })}
            onFocus={containerFocus}
            onBlur={containerBlur}
            onKeyDown={containerKeyPress}
            data-testid="dropdown-container"
            role="presentation"
        >
            <div
                className={classNames('dropdown-field-wrapper', fieldClassName)}
                ref={dropdownField}
                onKeyDown={focusDropdownContentsOnDownArrow}
                role="presentation"
            >
                {renderDropdownField({
                    dropdownContentsRef: dropdownContents.current,
                    getDropdownFieldFocus: getDropdownFieldFocus,
                    getDropdownContentsFocus: getDropdownContentsFocus,
                    focusDropdownField: focusDropdownField,
                    isDropdownVisible,
                    openDropdown: openDropdown,
                    closeDropdown: closeDropdown,
                    toggleDropdown: toggleDropdown,
                    toggleDropdownOnEnterOrSpace: toggleDropdownOnEnterOrSpace,
                })}
            </div>
            {targetNode.current
                ? createPortal(renderContents(), targetNode.current)
                : renderContents()}
        </div>
    );
};

// Note these two exports for proptypes should be deleted once we've refactored
// all use-cases to typescript
export const dropdownFieldRenderProps = {
    dropdownContentsRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
    getDropdownFieldFocus: PropTypes.func,
    getDropdownContentsFocus: PropTypes.func,
    focusDropdownField: PropTypes.func,
    isDropdownVisible: PropTypes.bool,
    openDropdown: PropTypes.func,
    closeDropdown: PropTypes.func,
    toggleDropdown: PropTypes.func,
    toggleDropdownOnEnterOrSpace: PropTypes.func,
};

export const dropdownContentsRenderProps = {
    dropdownFieldRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any}),
    ]),
    getDropdownFieldFocus: PropTypes.func,
    getDropdownContentsFocus: PropTypes.func,
    focusDropdownField: PropTypes.func,
    closeDropdown: PropTypes.func,
};
