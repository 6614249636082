export const TrainingHubIcon = () => {
    return (
        <svg
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6659_3139)">
                <path
                    d="M19.5142 5.17926L18.5617 4.65538C18.5617 4.65538 10.5377 0.360867 10.4453 0.309106C10.3529 0.257346 9.91016 -0.000267053 9.5 0.000489799C9.08984 0.00124665 8.81641 0.198975 8.58203 0.309106L0.482878 4.65538C0.336287 4.73845 0.214357 4.85893 0.129526 5.00451C0.0446952 5.15009 0 5.31557 0 5.48407C0 5.65256 0.0446952 5.81804 0.129526 5.96363C0.214357 6.10921 0.336287 6.22968 0.482878 6.31276L2.85465 7.62724V12.1422C2.85465 12.9 3.15571 13.6269 3.69161 14.1628C4.22751 14.6987 4.95434 14.9997 5.71221 14.9997H13.3324C14.0902 14.9997 14.8171 14.6987 15.353 14.1628C15.8889 13.6269 16.1899 12.9 16.1899 12.1422V7.62724L18.095 6.56042V9.28462C18.095 9.53724 18.1953 9.77952 18.3739 9.95815C18.5526 10.1368 18.7949 10.2371 19.0475 10.2371C19.3001 10.2371 19.5424 10.1368 19.721 9.95815C19.8996 9.77952 20 9.53724 20 9.28462V6.00796C19.9997 5.83917 19.9546 5.6735 19.8692 5.52789C19.7839 5.38228 19.6613 5.26197 19.5142 5.17926ZM14.2849 12.1422C14.2849 12.3948 14.1845 12.6371 14.0059 12.8157C13.8273 12.9943 13.585 13.0947 13.3324 13.0947H5.71221C5.45959 13.0947 5.21731 12.9943 5.03868 12.8157C4.86004 12.6371 4.75969 12.3948 4.75969 12.1422V8.68453L9.05555 11.0658L9.19843 11.123H9.28416C9.36322 11.1329 9.44322 11.1329 9.52229 11.123C9.60135 11.1329 9.68135 11.1329 9.76042 11.123H9.84614C9.89671 11.1123 9.94505 11.093 9.98902 11.0658L14.2849 8.68453V12.1422ZM9.52229 9.15127L2.9118 5.47454L9.52229 1.79782L16.1328 5.47454L9.52229 9.15127Z"
                    fill="#776E6A"
                />
            </g>
            <defs>
                <clipPath id="clip0_6659_3139">
                    <rect width="20" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
