import {httpGet} from 'Interfaces/httpClient';

import {CanvasElement} from '../chatCanvas/ChatCanvas';
import {ChatMessage} from '../chatPanel/ChatPanel';

export type HistoricCanvasResponseType = {
    items: Array<{
        chatItems?: ChatMessage[];
        canvasJson?: CanvasElement[];
    }>;
};

export type HistoricData = {
    chatHistory: ChatMessage[];
    canvasHistory: CanvasElement[];
};

export const getHistoricCanvas = async (url: string): Promise<HistoricData> => {
    try {
        const response: HistoricCanvasResponseType = await httpGet(url);
        if (!response || !response.items) {
            console.error('Invalid response in getHistoricData', url, response);
            return {chatHistory: [], canvasHistory: []};
        }

        const chatHistory: ChatMessage[] = [];
        const canvasHistory: CanvasElement[] = [];
        if (response.items[0]?.chatItems) {
            chatHistory.push(...response.items[0].chatItems);
        }
        if (response.items[0]?.canvasJson) {
            canvasHistory.push(...response.items[0].canvasJson);
        }
        return {chatHistory, canvasHistory};
    } catch (e) {
        console.error('Error in getHistoricData', e, url);
        return {chatHistory: [], canvasHistory: []};
    }
};
