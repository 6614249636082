/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:07
 */

import {APP_CONTAINER_ID} from 'Constants/idStrings';

import {exportInterfaceSingleton} from './Base';

import 'scroll-behavior-polyfill';

let instance = null;

class Scroll {
    constructor() {
        if (!instance) {
            instance = this;
        }
        this.mainLayoutBody = null;
        this.savedPosition = {};
        return instance;
    }

    _getMainLayoutBody() {
        if (!this.mainLayoutBody) {
            this.mainLayoutBody = document.getElementById(APP_CONTAINER_ID);
        }
        return this.mainLayoutBody;
    }

    scrollTop(domElement, scrollY) {
        if (scrollY || scrollY === 0) {
            domElement.scrollTop = scrollY;
        }
    }

    scrollPageTop() {
        this.scrollTop(this._getMainLayoutBody(), 0);
    }

    scrollPageBy(scrollY) {
        // Note: smooth scroll behaviour is not supported in IE or Safari, so is polyfilled
        this._getMainLayoutBody().scrollBy({
            top: scrollY,
            left: 0,
            behavior: 'smooth',
        });
    }

    savePageScroll(key) {
        if (key) {
            this.savedPosition[key] = this._getMainLayoutBody().scrollTop;
        } else {
            this.savedPosition.default = this._getMainLayoutBody().scrollTop;
        }
    }

    restorePageScroll(key) {
        this.scrollTop(
            this._getMainLayoutBody(),
            this.savedPosition[key ? key : 'default'],
        );
    }

    getClosestScrollContainer = (element) => {
        if (!(element && typeof element.closest === 'function')) {
            return document.getElementById(APP_CONTAINER_ID);
        }

        const slideoverContainer = element.closest('.slideover-content');
        if (slideoverContainer) {
            return slideoverContainer;
        }

        const extWindowContainer = element.closest('.mis-window-body');
        if (extWindowContainer) {
            return extWindowContainer;
        }
        const reactWindowContainer = element.closest(
            '.window-manager__window-content',
        );
        if (reactWindowContainer) {
            return reactWindowContainer;
        }

        return document.getElementById(APP_CONTAINER_ID);
    };
}

let scroll = new Scroll();

export const registerScrollInterface = () => {
    exportInterfaceSingleton('Scroll', scroll);
};

export default scroll;
