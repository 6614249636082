import {Component} from 'react';

import Box from 'Components/layout/Box';

class Container extends Component {
    render() {
        return <Box container wrap="wrap" {...this.props} />;
    }
}

export default Container;
