import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Button} from 'Components/button/base';
import {getCurrentUrl} from 'Interfaces/history/History';
import {httpPost} from 'Interfaces/httpClient';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import {removeWindow} from 'Interfaces/WindowManager';
import Loader from 'Services/Loader';
import {appendFormatJson} from 'Services/url/Url';
import {selectSelectedIds} from 'State/selectors/Basket';
import buttonWrapper from 'Wrappers/Button';

const WrappedButton = buttonWrapper(Button);

class ApplyDiscountButton extends Component {
    callApi = (jsonData) => {
        const {pageUrl} = this.props;

        httpPost(appendFormatJson(pageUrl), jsonData)
            .then((response) => {
                unmaskMain();
                if (response.error) {
                    this.discountCodeField.component.markInvalid(
                        response.message,
                    );
                    return;
                }
                if (this.props.rendererParams?.type === 'window') {
                    removeWindow();
                }

                const requestData = {
                    fields: {
                        selectedIds: {
                            value: this.props.selectedIds,
                        },
                        isBundle: {
                            // #TODO Remove isBundle when it is removed on server side
                            value: false,
                        },
                    },
                };

                Loader.loadPage(getCurrentUrl() + '/reload/true', {
                    jsonData: requestData,
                });
            })
            .catch((response) => {
                unmaskMain();
                throw new Error(response.message);
            });
    };

    handleClick = () => {
        if (this.props.extNodeEl) {
            this.discountCodeField = this.props.extNodeEl
                .up('.x-autocontainer-outerCt')
                .down('input');
        }
        const discountCode =
            this.discountCodeField && this.discountCodeField.getValue();
        let jsonData = {
            selectedIds: [],
            discountCode: discountCode,
        };

        jsonData.selectedIds = this.props.selectedIds;

        maskMain(_t('Loading...'));
        this.callApi(jsonData);
    };

    render() {
        const {text, color, ...rest} = this.props;
        return (
            <WrappedButton
                {...rest}
                text={text}
                color={color}
                onClick={this.handleClick}
            />
        );
    }
}

ApplyDiscountButton.defaultProps = {
    color: 'green',
};

ApplyDiscountButton.propTypes = {
    pageUrl: PropTypes.string,
    text: PropTypes.string,
    extNodeEl: PropTypes.object,
    color: PropTypes.string,
    selectedIds: PropTypes.array,
    rendererParams: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
    };
};

export default connect(mapStateToProps, undefined, undefined, {
    forwardRef: true,
})(ApplyDiscountButton);
