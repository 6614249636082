import {useState} from 'react';

import {getUser} from 'Interfaces/Session';
import {FloatingElementDirection} from 'Utils/getFloatingElementPosition';

import {SideBarButton} from '../sideBarButton';

import {EmergencyAlertIcon} from './EmergencyAlertIcon';
import {EmergencyAlertModal} from './EmergencyAlertModal';

type EmergencyAlertButtonProps = {
    preferTooltipPosition?: FloatingElementDirection;
};
export const EmergencyAlertButton = ({
    preferTooltipPosition,
}: EmergencyAlertButtonProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClick = () => setIsModalVisible(!isModalVisible);

    const user = getUser() || {};
    const {showAlertButton} = user;

    if (!showAlertButton) {
        return null;
    }

    return (
        <>
            <SideBarButton
                onClick={handleClick}
                tooltip="Send an emergency alert to staff on call"
                buttonText="Emergency Alert"
                preferTooltipPosition={preferTooltipPosition}
            >
                <EmergencyAlertIcon />
            </SideBarButton>
            {isModalVisible && (
                <EmergencyAlertModal onClose={() => setIsModalVisible(false)} />
            )}
        </>
    );
};
