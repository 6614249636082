import {useRef} from 'react';

import Banner from 'BackendRenderedComponents/banner/Banner';
import {Card} from 'Components/card';
import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {SimpleText} from 'Components/simpleText';
import {getUser} from 'Interfaces/Session';

import './jiraFeedbackModal.scss';

export const jiraIdeaValue = 11832;
export const jiraImprovementValue = 11833;

export const jiraMISValue = 11831;
export const jiraMATValue = 11830;

export const JiraFeedbackModal = ({closeModal}: {closeModal: () => void}) => {
    const userData = useRef(getUser());

    const openJiraIssueCollector = (feedbackType: number) => {
        // global var, set by jira script in SIS, sets the feedback type and institution type jira fields on the ticket.
        ATL_JQ_PAGE_PROPS.fieldValues.customfield_14050 = [feedbackType];
        ATL_JQ_PAGE_PROPS.fieldValues.customfield_14049 = [
            userData.current?.institutionType === 'MAT'
                ? jiraMATValue
                : jiraMISValue,
        ];
        // global var, set by jira script in SIS, triggers the jira issue collector modal to open.
        showCollectorDialog();
        closeModal();
    };

    const helpCentreUrl =
        'https://support.arbor-education.com/hc/en-us/articles/360017607397-Contact-us-How-can-I-get-in-touch-with-Arbor-s-Support-Team#h_01H9064[…]X77ZZ2C1F';

    return (
        <FocusTrappingModal
            className="jira-feedback-modal"
            title="Help us make Arbor even better"
            onClose={closeModal}
            sizing="medium"
        >
            <Banner icon="info" type="info">
                <SimpleText>
                    We love hearing your ideas about how to make Arbor even
                    better. But with such a large community of schools using
                    Arbor, we're not able to respond to ideas individually.
                </SimpleText>
            </Banner>
            <div className="jira-feedback-modal__body">
                <Card
                    icon="lucide-mouse-pointer-click"
                    iconColor="green"
                    title="Tell us how an existing feature could be improved"
                    onClick={() => openJiraIssueCollector(jiraImprovementValue)}
                />
                <Card
                    icon="lucide-lightbulb"
                    iconColor="green"
                    title="Share an idea for a new way that Arbor could save you time"
                    onClick={() => openJiraIssueCollector(jiraIdeaValue)}
                />
                <p>
                    Need help using Arbor? Visit our{' '}
                    <a href={helpCentreUrl} target="_blank">
                        Help Centre
                    </a>{' '}
                    to find all the ways you can contact your support channels.
                </p>
            </div>
        </FocusTrappingModal>
    );
};
