import {SISFormFieldCommonProps} from 'Services/formField/FormField';

export const extractCommonFormFieldParams = ({
    dependencyFields,
    reference,
    required,
    actionMappings,
    rendererParams,
    name,
    id,
}: SISFormFieldCommonProps & {id: string}) => {
    return {
        dependencyFields,
        reference,
        required,
        actionMappings,
        name,
        componentId: id,
        parentFilterPanelId: rendererParams?.parentFilterPanelId,
    };
};
