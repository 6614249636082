import {useState} from 'react';

import {Button} from 'Components/button/base';
import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {getUser} from 'Interfaces/Session';
import {addSystemNotification} from 'Interfaces/SystemNotification';

import {AdditionalInfoModal} from './additionalInfoModal';
import {
    sendEmergencyAlert,
    ISendEmergencyAlertParams,
} from './sendEmergencyAlert';
import {Spinner} from './Spinner';
import {useCountDownTimer} from './useCountDownTimer';
import {useFetchAlertOptions} from './useFetchAlertOptions';

import './emergencyAlertModal.scss';

interface IEmergencyAlertModal {
    onClose: () => void;
}

const DEFAULT_INITIAL_TIME_LEFT = 5;

export const EmergencyAlertModal = ({onClose}: IEmergencyAlertModal) => {
    const {isLoading, alertOptions, error} = useFetchAlertOptions();
    const [isEditingInformation, setIsEditingInformation] = useState(false);

    const {sendAlertTime} = getUser() || {};

    const sendAlert = async (alertData: ISendEmergencyAlertParams) => {
        try {
            const response = await sendEmergencyAlert(alertData);
            if (response.success) {
                const notification = response.notifications?.[0];
                addSystemNotification(
                    notification ?? {
                        icon: 'tick',
                        type: 'success',
                        message: 'Emergency alert sent successfully',
                    },
                );
            } else {
                throw new Error();
            }
        } catch (err) {
            addSystemNotification({
                icon: 'error',
                type: 'error',
                message: 'Something went wrong sending the emergency alert',
            });
            console.error(err);
        } finally {
            onClose();
        }
    };

    const {timeLeft, stopTimer} = useCountDownTimer({
        initialTimeLeft: sendAlertTime || DEFAULT_INITIAL_TIME_LEFT,
        callBackOnZero: async () => {
            sendAlert({
                location: alertOptions?.location?.value || '',
                event: alertOptions?.event?.value || '',
            });
        },
    });

    const renderCountdownModal = () => (
        <FocusTrappingModal
            onClose={onClose}
            key="emergency-alert-countdown-modal"
            title="An emergency alert will be sent in"
        >
            <div
                className="emergency-alert-modal__container"
                data-testid="emergency-alert-countdown-modal"
            >
                <div className="emergency-alert-modal__countdown-wrapper">
                    <div className="emergency-alert-modal__countdown-container">
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <div
                                className="emergency-alert-modal__countdown-timer"
                                data-testid="emergency-alert-countdown-timer"
                            >
                                {timeLeft}
                            </div>
                        )}
                    </div>
                </div>
                <h3 className="emergency-alert-modal__subtitle">
                    We are sending the following information
                </h3>

                <div className="emergency-alert-modal__info-tiles">
                    <div>
                        <div>Staff requesting help</div>
                        <b>{alertOptions?.staffName || 'Not known'}</b>
                    </div>
                    <div>
                        <div>Location</div>
                        <b>{alertOptions?.location?.label || 'Not known'}</b>
                    </div>
                    <div>
                        <div>Event</div>
                        <b>{alertOptions?.event?.label || 'Not known'}</b>
                    </div>
                </div>
                {error && (
                    <p className="emergency-alert-modal__error-text">{error}</p>
                )}
                <Button
                    text="Edit or add information"
                    color="orange"
                    onClick={() => {
                        stopTimer();
                        setIsEditingInformation(true);
                    }}
                />
                <Button
                    text="Cancel alert"
                    onClick={() => {
                        stopTimer();
                        onClose();
                    }}
                />
            </div>
        </FocusTrappingModal>
    );

    const renderAdditionalInfoModal = () => (
        <AdditionalInfoModal onClose={onClose} sendAlert={sendAlert} />
    );

    return isEditingInformation
        ? renderAdditionalInfoModal()
        : renderCountdownModal();
};
