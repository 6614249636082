import {httpPost} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

export const sendResetPasswordEmail = (email) => {
    const postData = {
        items: {
            email,
        },
    };

    return httpPost(url.AUTH.RESET_PASSWORD, postData);
};
