import {SnippetModal} from 'Components/snippetModal';

import exportInterface from './Base';
import {addWindow, removeWindow} from './WindowManager';

type SnippetModalParams = {
    contentRequestUrl;
    replacement: {objectTypeId: number; objectId: number};
    context: {objectTypeId: number; objectId: number};
    insertCallback: (content: string) => void;
    refocusCallback: () => void;
};

export const showSnippetModal = (params: SnippetModalParams) => {
    const {
        contentRequestUrl,
        replacement,
        context,
        insertCallback,
        refocusCallback,
    } = params;

    addWindow({
        content: (
            <SnippetModal
                {...{replacement, context}}
                closeCallback={() => removeWindow()}
                insertCallback={insertCallback}
            />
        ),
        footerItems: null,
        title: 'Snippets',
        rendererParams: {
            contentRequestUrl,
            type: 'window',
            elementToRefocus: null,
            refocusCallback: refocusCallback,
        },
    });
};

export const registerSnippetInterface = () => {
    exportInterface('Snippet', {
        showSnippetModal,
    });
};
