import {lazy, Suspense} from 'react';

import type {PowerBIDashboardProps} from './types';

const PowerBiDashboard = lazy(
    () => import('./PowerBIDashboardAsDefaultExport'),
);

export const LazyLoadedPowerBIDashboard = (props: PowerBIDashboardProps) => {
    return (
        <Suspense fallback={<div> Loading data </div>}>
            <PowerBiDashboard {...props} />
        </Suspense>
    );
};
