import {useState} from 'react';
import Dropzone from 'react-dropzone';

import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {LoadingSpinner} from 'Components/loadingSpinner';
import {httpPost} from 'Interfaces/httpClient';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';

import {AttachedFile} from './attachedFile';

import './attachments.scss';

export type AttachmentsProps = {
    allowUpload?: boolean;
    allowDelete?: boolean;
    title?: string;
    titleUrl?: string;
    fileController?: string;
    createAction?: string;
    readAction?: string;
    deleteAction?: string;
    downloadAction?: string;
    objectTypeId?: string;
    objectId?: string;
    module?: string;
};

export type AttachedFileProps = {
    fields: {
        mimeType: {value: string};
        mimeTypeDescription: {value: string};
        fileName: {value: string};
        fileSize: {value: string};
        file_id: {value: string};
    };
};

export const Attachments = (props) => {
    const {
        allowUpload = true,
        allowDelete = true,
        title,
        titleUrl,
        fileController,
        createAction,
        readAction,
        deleteAction,
        downloadAction,
        objectTypeId,
        objectId,
        module = '',
    }: AttachmentsProps = props;

    const readObjectPath =
        objectTypeId && objectId
            ? `/object-type-id/${objectTypeId}/object-id/${objectId}/`
            : '';
    const [files, setFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const readFiles = async () => {
        try {
            const uploadedFiles = await httpPost(
                `${module}/${fileController}/${readAction}${readObjectPath}`,
            );
            setFiles(uploadedFiles.items);
        } catch (error) {
            console.error(error);
        }
    };

    const uploadFiles = async (acceptedFiles) => {
        setIsLoading(true);
        for (const file of acceptedFiles) {
            const formData = new FormData();
            formData.append('file', file);
            await httpPost(
                `${module}/${fileController}/${createAction}`,
                formData,
            );
        }
        readFiles();
        setIsLoading(false);
    };

    useComponentDidMount(() => {
        readFiles();
    });

    return (
        <section className="attachments-container">
            <KeyboardFocusableLink
                url={titleUrl}
                disableDefaultLinkStyles
                insetFocusRing
            >
                <div className="attachments-header">
                    <span className="attachments-widget-header-title">
                        {title}
                    </span>

                    <Icon
                        iconName="arrow-go-right"
                        className="arrow-go-right"
                    />
                </div>
            </KeyboardFocusableLink>

            {allowUpload && (
                <div className="dropzone-outer">
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            uploadFiles(acceptedFiles);
                        }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section className="dropzone-inner">
                                {isLoading ? (
                                    <LoadingSpinner className="attachment-loading-spinner" />
                                ) : (
                                    <div
                                        {...getRootProps()}
                                        className="dropzone-input"
                                    >
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content">
                                            <Icon
                                                iconName="upload"
                                                className="dropzone-upload-icon"
                                            />
                                            <p className="dropzone-text">
                                                Drag or{' '}
                                                <span className="dropzone-text__green">
                                                    click to upload
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </section>
                        )}
                    </Dropzone>
                </div>
            )}
            {files.map((file, index) => (
                <AttachedFile
                    key={index}
                    file={file}
                    lastElement={index === files.length - 1}
                    fileController={fileController}
                    deleteAction={allowDelete && deleteAction}
                    onActionClicked={readFiles}
                    downloadAction={downloadAction}
                    module={module}
                />
            ))}
        </section>
    );
};
