import {formatDistanceToNow} from 'date-fns';

export default {
    random(length) {
        return Math.random()
            .toString(36)
            .substring(!length ? 8 : length);
    },
    timestampToString(timestamp) {
        return formatDistanceToNow(timestamp * 1000, {addSuffix: true});
    },
    debounce<T extends (...args: unknown[]) => unknown>(
        func: T,
        wait = 300,
        immediate?: boolean,
    ): {
        (...args: Parameters<T>): ReturnType<T>;
        clear: () => void;
        flush: () => void;
    } {
        let timeout, args, context, timestamp, result;
        function later() {
            const last = Date.now() - timestamp;
            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    context = args = null;
                }
            }
        }
        const debounced = function () {
            args = arguments; // eslint-disable-line prefer-rest-params
            timestamp = Date.now();
            const callNow = immediate && !timeout;
            if (!timeout) {
                timeout = setTimeout(later, wait);
            }
            if (callNow) {
                result = func.apply(this, args);
                context = args = null;
            }
            return result;
        };
        debounced.clear = function () {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
        debounced.flush = function () {
            if (timeout) {
                result = func.apply(context, args);
                context = args = null;
                clearTimeout(timeout);
                timeout = null;
            }
        };
        return debounced;
    },
    range(min, max, required = false) {
        return function (props, propName) {
            const value = props[propName];

            if (typeof value === 'undefined') {
                if (required) {
                    return new Error(
                        `Sorry you must include a number for ${propName}.`,
                    );
                }
                return null;
            }

            if (
                !(
                    typeof value === 'number' &&
                    isFinite(value) &&
                    Math.floor(value) === value
                )
            ) {
                return new Error(`Sorry ${propName} must be a number.`);
            }

            return value >= min && value <= max
                ? null
                : new Error(
                      `${propName}: Must be within range of ${min} to ${max}`,
                  );
        };
    },

    getUid() {
        return (
            Math.random().toString(36).substring(2) +
            new Date().getTime().toString(36)
        );
    },
    isHTML(str) {
        const a = document.createElement('div');
        a.innerHTML = str;

        for (let c = a.childNodes, i = c.length; i--; ) {
            if (c[i].nodeType === 1) {
                return true;
            }
        }

        return false;
    },
};
