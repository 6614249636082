import helper from 'Utils/helper';

import {AnyFunction} from './types';

class Pubsub {
    private events: Record<string, Record<string, AnyFunction>>;
    constructor() {
        this.events = {};
    }

    /**
     * @param eventName string
     * @param fn
     * @returns subscriptionId
     */
    subscribe(eventName: string, fn: AnyFunction) {
        const subscriptionId = helper.getUid();

        this.events[eventName] = this.events[eventName] ?? {};
        this.events[eventName][subscriptionId] = fn;
        return subscriptionId;
    }

    unsubscribe(eventName: string, subscriptionId: string) {
        if (typeof this.events[eventName] !== 'undefined') {
            delete this.events[eventName][subscriptionId];
        }
    }

    publish(eventName: string, data?: unknown) {
        if (this.events[eventName]) {
            Object.keys(this.events[eventName]).forEach((key) => {
                const subscribedFunction = this.events[eventName][key];
                subscribedFunction(data);
            });
        }
    }
}

export default new Pubsub();
