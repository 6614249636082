import {Button, IButtonProps} from 'Components/button/base';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

export function RefreshPageButton(props: IButtonProps) {
    const handleClick = () => {
        window.location.reload();
    };

    return (
        <WrappedButton
            {...props}
            className="arbor-refresh-page-button"
            onClick={handleClick}
        />
    );
}
