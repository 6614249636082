import {ComboboxItem} from 'Components/combobox';
import {httpGet, httpPost} from 'Interfaces/httpClient';

type ComboboxOptionsResponse = {
    items: {
        fields: {
            group: {value: string};
            label: {value: string};
            selected: {value: boolean};
            value: {value: string};
            dropdownLabel: {value: string};
            icon?: {value: string};
            size?: {value: number};
        };
    }[];
};

export const fetchComboboxOptions = async (
    optionsUrl: string,
    params?: unknown,
) => {
    const response: ComboboxOptionsResponse =
        typeof params === 'undefined'
            ? await httpGet(optionsUrl)
            : await httpPost(optionsUrl, params);

    const options: ComboboxItem[] = (response?.items || []).map((option) => {
        if (!option.fields) {
            console.error('no fields in option!', option, response);
        }
        return {
            label: option.fields?.label?.value,
            value: option.fields?.value?.value,
            selected: option.fields?.selected?.value,
            group: option.fields?.group?.value,
            dropdownLabel: option.fields?.dropdownLabel?.value,
            icon: option.fields?.icon?.value,
            size: option.fields?.size?.value,
        };
    });

    return options;
};
