import classnames from 'classnames';
import {Children, cloneElement, FunctionComponentElement} from 'react';

import {Icon} from 'Components/icon';
import {SimpleText} from 'Components/simpleText';
import Text from 'Config/Text';

import './banner.scss';

type Child = FunctionComponentElement<any>;

type Props = {
    type?: 'system' | 'info' | 'warning' | 'error';
    title?: string;
    icon?: string;
    children?: Child | Child[];
};

export const Banner = ({type = 'system', title, children, icon}: Props) => {
    const colourByType = () => {
        switch (type) {
            case 'warning':
                return 'orange';
            case 'error':
                return 'red';
            default:
                break;
        }
    };

    const childrenArr = Children.toArray(children) as Child[];

    const childHasActions = childrenArr.find((child) =>
        child?.key?.includes('Arbor.button'),
    );

    const findSimpleTexts = (item) => item.type === SimpleText;

    return (
        <section
            aria-label="Banner"
            className={classnames('arbor-information-banner', {
                [`arbor-banner-type-${type}`]: type,
            })}
        >
            {icon && (
                <div className="icon-area">
                    <Icon iconName={icon} />
                </div>
            )}
            <div className="content-actions-area">
                <div className="title-content-area">
                    {title && <div className="title">{title}</div>}
                    <div className="content">
                        {children
                            ? Children.toArray(children).filter(findSimpleTexts)
                            : Text.BANNER.EMPTY_TEXT}
                    </div>
                </div>
                {children && childHasActions && (
                    <div className="actions-area">
                        {Children.map(children, (child) => {
                            if (child?.key?.includes('Arbor.button')) {
                                return child.props.uiType === 'primary'
                                    ? cloneElement(child, {
                                          color: colourByType(),
                                      })
                                    : cloneElement(child, {
                                          color: 'grey',
                                      });
                            }
                        })}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Banner;
