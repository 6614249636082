export const SET_INITIAL_FILTERS = 'DASHBOARD_KPIS::SET_INITIAL_FILTERS';

export const TOGGLE_FILTER_BY_ID = 'DASHBOARD_KPIS::TOGGLE_FILTER_BY_ID';
export const SET_SELECTED_TAB_BY_ID = 'DASHBOARD_KPIS::SET_SELECTED_TAB_BY_ID';

export const SAVE_FILTERS_REQUEST =
    'DASHBOARD_KPIS::SAVE_DASHBOARD_FILTERS_REQUEST';
export const SAVE_FILTERS_SUCCESS =
    'DASHBOARD_KPIS::SAVE_DASHBOARD_FILTERS_SUCCESS';
export const SAVE_FILTERS_FAILURE =
    'DASHBOARD_KPIS::SAVE_DASHBOARD_FILTERS_FAILURE';

export const FETCH_KPI_DATA_REQUEST = 'DASHBOARD_KPIS::FETCH_KPI_DATA_REQUEST';
export const FETCH_KPI_DATA_SUCCESS = 'DASHBOARD_KPIS::FETCH_KPI_DATA_SUCCESS';
export const FETCH_KPI_DATA_CACHED = 'DASHBOARD_KPIS::FETCH_KPI_DATA_CACHED';
export const FETCH_KPI_DATA_FAILURE = 'DASHBOARD_KPIS::FETCH_KPI_DATA_FAILURE';

export const GET_TO_DOS_REQUEST = 'DASHBOARD_KPIS::GET_TO_DOS_REQUEST';
export const GET_TO_DOS_SUCCESS = 'DASHBOARD_KPIS::GET_TO_DOS_SUCCESS';
export const GET_TO_DOS_FAILURE = 'DASHBOARD_KPIS::GET_TO_DOS_FAILURE';

export const GET_SCHOOL_NOTICES_REQUEST =
    'DASHBOARD_KPIS::GET_SCHOOL_NOTICES_REQUEST';
export const GET_SCHOOL_NOTICES_SUCCESS =
    'DASHBOARD_KPIS::GET_SCHOOL_NOTICES_SUCCESS';
export const GET_SCHOOL_NOTICES_FAILURE =
    'DASHBOARD_KPIS::GET_SCHOOL_NOTICES_FAILURE';

export const GET_STUDENT_ALERTS_REQUEST =
    'DASHBOARD_KPIS::GET_STUDENT_ALERTS_REQUEST';
export const GET_STUDENT_ALERTS_SUCCESS =
    'DASHBOARD_KPIS::GET_STUDENT_ALERTS_SUCCESS';
export const GET_STUDENT_ALERTS_FAILURE =
    'DASHBOARD_KPIS::GET_STUDENT_ALERTS_FAILURE';

export const DISMISS_STUDENT_ALERT_REQUEST =
    'DASHBOARD_KPIS::DISMISS_STUDENT_ALERT_REQUEST';
export const DISMISS_STUDENT_ALERT_SUCCESS =
    'DASHBOARD_KPIS::DISMISS_STUDENT_ALERT_SUCCESS';
export const DISMISS_STUDENT_ALERT_FAILURE =
    'DASHBOARD_KPIS::DISMISS_STUDENT_ALERT_FAILURE';
