import exportInterface from 'Interfaces/Base';
import {getSessionService, ILoginData} from 'Services/session';

export function initialiseSession() {
    return getSessionService().initialiseSession();
}

export function isLoggedIn() {
    return getSessionService().isLoggedIn();
}

export function getUser() {
    return getSessionService().getUser();
}

export function loginIssuePage() {
    return getSessionService().loginIssuePage();
}

export function successLoginHandler(loginData: ILoginData) {
    return getSessionService().successLoginHandler(loginData);
}

export function getLastRequestTime() {
    return getSessionService().getLastRequestTime();
}

export function setLastRequestTime(time: number) {
    return getSessionService().setLastRequestTime(time);
}

export function logOut() {
    return getSessionService().logOut();
}

export const registerSessionInterface = () => {
    exportInterface('Session', {
        initialiseSession,
        isLoggedIn,
        loginIssuePage,
        getLastRequestTime,
        getUser,
        setLastRequestTime,
    });
};
