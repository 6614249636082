import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {findDOMNode} from 'react-dom';

import {Button} from 'Components/button/base';
import {SYSTEM_NOTIFICATION} from 'Config/Events';
import textConfig from 'Config/Text';
import pubsub from 'Services/pubsub/Pubsub';
import buttonWrapper from 'Wrappers/Button';
const WrappedButton = buttonWrapper(Button);

class CopyButton extends Component {
    componentDidMount() {
        const copyValue = this.props.copyValue;
        const el = findDOMNode(this);

        if (copyValue && el) {
            let clipboard = this.initClipboard(el, copyValue);

            clipboard.on('success', this.publishSystemNotification);

            this.clipboard = clipboard;
        }
    }

    componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy();
        }
    }

    initClipboard = (el, value) => {
        return new Clipboard(el, {
            text: function () {
                return value;
            },
        });
    };

    publishSystemNotification = () => {
        pubsub.publish(SYSTEM_NOTIFICATION.ADD, {
            message: _t(textConfig.CLIPBOARD.SUCCESS),
            type: 'success',
            icon: 'tick',
        });
    };

    render() {
        return (
            <WrappedButton
                text="Copy to Clipboard"
                {...this.props}
                onClick={() => {}}
            />
        );
    }
}

CopyButton.propTypes = {
    copyValue: PropTypes.string,
};

export default CopyButton;
