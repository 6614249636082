import {useState, useEffect} from 'react';

import {fetchAlertOptions, IAlertOptions} from './fetchAlertOptions';

export const useFetchAlertOptions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [alertOptions, setAlertOptions] = useState<IAlertOptions | null>(
        null,
    );
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAlertOptions();
                const [options = {}] = response?.items || [];
                setIsLoading(false);
                setAlertOptions(options);
            } catch (e) {
                console.error(e);
                setError(
                    'Something went wrong fetching alert options. You can still attempt to send the alert.',
                );
            }
        };
        fetchData();
    }, []);

    return {isLoading, alertOptions, error};
};
