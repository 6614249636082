// Everything should work via redux
import exportInterface from 'Interfaces/Base';
import {registerItem, selectItem, deselectItem} from 'Services/basket/Basket';

export function registerBasket(basket) {
    Mis.application.Basket.registerBasket(basket);
}

export function registerPurchasableItem(item) {
    Mis.application.Basket.registerPurchasableItem(item);
    registerItem({
        pictureUrl: item.pictureUrl,
        type: item.type,
        title: item.title,
        subtitle: item.subtitle,
        titleIcon: item.titleIcon,
        price: item.price,
        priceIcon: item.priceIcon,
        vat: item.vat,
        currencyCode: item.currencyCode,
        buttonLabel: item.buttonLabel,
        serviceId: item.serviceId,
        checkoutUrl: item.checkoutUrl,
    });
}

export function selectItemsForPurchase(item) {
    //Mis.application.Basket.selectItemsForPurchase(item);//moved to redux
    selectItem(item.serviceId);
}

export function deselectItemsForPurchase(serviceId) {
    //Mis.application.Basket.deselectItemsForPurchase(item);//TODO: this is tight connected with purchase use case and shoud be refactored
    deselectItem(serviceId);
}

export function clearBasket() {
    Mis.application.Basket.clearBasket();
}

export function getSelectedIds() {
    return Mis.application.Basket.getSelectedIds();
}

export function getTotalAmount() {
    return Mis.application.Basket.getTotalAmount(); //Already in redux store
}

export function getBundleId() {
    return Mis.application.Basket.getBundleId();
}

export function getCurrencyCode() {
    return Mis.application.Basket.basket.currencyCode; //Already in redux store
}

export function isBundlePurchase() {
    return Mis.application.Basket.bundlePurchase;
}

export function setBundlePurchase(bundlePurchase) {
    Mis.application.Basket.bundlePurchase = bundlePurchase;
}

export const registerBasketInterface = () => {
    exportInterface('Basket', {
        registerBasket,
        registerPurchasableItem,
        selectItemsForPurchase,
        deselectItemsForPurchase,
        clearBasket,
        getSelectedIds,
        getTotalAmount,
        getBundleId,
        getCurrencyCode,
        isBundlePurchase,
        setBundlePurchase,
    });
};
