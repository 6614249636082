import React from 'react';

type HtmlTableProps = {
    id?: string;
    content: Content;
    className?: string;
    title?: string;
};

type Column = {
    dataIndex: string;
    text: string;
    sortable: boolean;
};

type Row = {
    fields: Record<
        string,
        {
            value:
                | string
                | number
                | Record<string, string | number>
                | Array<Record<string, string | number>>;
        }
    >;
};

type Content = {
    columns: Column[];
    data: Row[];
};

export const CanvasHtmlTable: React.FC<HtmlTableProps> = ({
    id,
    content,
    className,
    title,
}) => {
    try {
        // This is required in case the LLM malforms the table. This will prevent the whole chat from crashing.
        const {columns, data} = content;

        const renderCellContent = (
            value:
                | string
                | number
                | Record<string, string | number>
                | Array<Record<string, string | number>>
                | undefined,
        ) => {
            if (Array.isArray(value)) {
                return (
                    <ul>
                        {value.map((item, index) => (
                            <li key={index}>
                                {Object.entries(item).map(([key, val]) => (
                                    <div key={key}>
                                        <strong>{key}:</strong> {val}
                                    </div>
                                ))}
                            </li>
                        ))}
                    </ul>
                );
            }
            if (typeof value === 'object' && value !== null) {
                return (
                    <ul>
                        {Object.entries(value).map(([key, val]) => (
                            <li key={key}>
                                <strong>{key}:</strong> {val}
                            </li>
                        ))}
                    </ul>
                );
            }
            return value || '';
        };

        return (
            <table
                id={`hidden-export-table-${id}`}
                className={`canvas-html-table ${className || ''}`}
            >
                <thead>
                    <tr>
                        {columns?.map((column, index) => (
                            <th key={`header-${index}`}>{column.text}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={`row-${rowIndex}`}>
                            {columns?.map((column, cellIndex) => (
                                <td key={`cell-${rowIndex}-${cellIndex}`}>
                                    {renderCellContent(
                                        row.fields[column.dataIndex]?.value,
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    } catch (error) {
        return (
            <div className="canvas__element-container no-print no-export">
                <div className="canvas__element-banner">
                    Sorry, I'm having trouble showing a table
                    {title && <>with the data: '{title}'</>}
                    .
                    <br />
                    Please try rephrasing the question and try again.
                </div>
            </div>
        );
    }
};
