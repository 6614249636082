import classNames from 'classnames';

import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import './eventBox.scss';

interface IEventBoxSectionProps {
    url?: string;
    html: string;
    title: string;
    cls?: string;
}

export const EventBoxSection = ({
    url,
    html,
    title,
    cls,
}: IEventBoxSectionProps) => {
    return (
        <KeyboardFocusableLink
            disableDefaultLinkStyles
            url={url}
            linkWrapperClassName={classNames(
                {
                    'event-box-section__wrapper--no-url':
                        typeof url === 'undefined',
                },
                'event-box-section__wrapper',
            )}
        >
            <div className="event-box-section__contents">
                <div className="event-box-section__title">
                    {title}{' '}
                    <Icon
                        iconName="arrow-go-right"
                        className="event-box-section__arrow-right"
                    ></Icon>
                </div>
                <div
                    className={cls}
                    dangerouslySetInnerHTML={{__html: html}}
                ></div>
            </div>
        </KeyboardFocusableLink>
    );
};
