import PropTypes from 'prop-types';

import {SchoolNotices} from './SchoolNotices';
import {StudentAlerts} from './StudentAlerts';
import {ToDos} from './Todos';

import './dashboardNotifications.scss';

export function DashboardNotifications({
    dataTestId,
    schoolNoticesRedirectUrl,
    studentAlertsRedirectUrl,
    toDosRedirectUrl,
}) {
    return (
        <div
            role="presentation"
            className="dashboard-notifications-container"
            data-testid={dataTestId}
        >
            <ToDos redirectUrl={toDosRedirectUrl} />
            <StudentAlerts redirectUrl={studentAlertsRedirectUrl} />
            <SchoolNotices redirectUrl={schoolNoticesRedirectUrl} />
        </div>
    );
}

DashboardNotifications.propTypes = {
    dataTestId: PropTypes.string,
    schoolNoticesRedirectUrl: PropTypes.string,
    studentAlertsRedirectUrl: PropTypes.string,
    toDosRedirectUrl: PropTypes.string,
};
