import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import {SystemButton} from 'Components/button/base';
import Box from 'Components/layout/Box';
import Container from 'Components/layout/container/Container';

import './checkoutPaymentPanelHeader.scss';

// Only used by CheckoutPaymentPanel
class CheckoutPaymentPanelHeader extends PureComponent {
    render() {
        const {title, subtitleText, collapsible, collapsed, onCollapse} =
            this.props;

        let icon = collapsed ? 'arrow-go-down' : 'arrow-go-up';

        return (
            <Container
                className="arbor-checkout-payment-panel-header"
                onClick={onCollapse}
            >
                <Box grow>
                    <h3>{title}</h3>
                </Box>
                {subtitleText && <h4>{subtitleText}</h4>}
                {collapsible && <SystemButton icon={icon} />}
            </Container>
        );
    }
}

CheckoutPaymentPanelHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitleText: PropTypes.string,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
};

export default CheckoutPaymentPanelHeader;
