import {TransformedNavigationItem} from '../types';

export const getBreadCrumbsData = (
    treeData: TransformedNavigationItem[],
    id: string,
): TransformedNavigationItem[] => {
    const matchingItem = treeData.find((item) => id === item.id);
    if (matchingItem) {
        return [matchingItem];
    }

    const containsSelected = treeData.find((item) => id.includes(item.id));
    if (containsSelected && typeof containsSelected?.items !== 'undefined') {
        return [
            containsSelected,
            ...getBreadCrumbsData(containsSelected.items, id),
        ];
    }
    if (containsSelected && typeof containsSelected?.subTree !== 'undefined') {
        return [
            containsSelected,
            containsSelected.subTree,
            ...getBreadCrumbsData(containsSelected.subTree.items, id),
        ];
    }
    return [];
};
