import isArray from 'lodash-es/isArray';
import {createElement, ReactNode} from 'react';

import {Column} from 'Components/column';
import {APP_CONTAINER_ID} from 'Constants/idStrings';
import {raiseDevError} from 'Interfaces/error/Error';
import {pushState} from 'Interfaces/history/History';
import {
    renderToAppRoot,
    unmountAppRoot,
    setAppRootContainer,
} from 'Interfaces/renderTargets';
import {getUser} from 'Interfaces/Session';
import {isExtComponent, findMinColumnWidth} from 'Renderer/componentCache';
import {ExtLayoutRenderer} from 'Renderer/ExtLayoutRenderer';
import {
    renderUIContent,
    renderExtContent,
    setComponentKey,
} from 'Renderer/Helper';
import {IEBannerData} from 'Renderer/IEBannerData';
import {
    BackendComponentData,
    PageRendererData,
    RendererParams,
} from 'Renderer/Renderer';
import {renderWithStore} from 'Renderer/Store';
import {
    transformNewNavigationData,
    transformOldSubnavData,
} from 'State/navigation/api';
import {detectInternetExplorer} from 'Utils/browserDetection';

import {clearDynamicNavData} from './clearDynamicNavData';
import {MainLayout} from './MainLayout';
import {updateDynamicNavData} from './updateDynamicNavData';
import {WidgetZoneColumn} from './widgetZoneColumn';

const renderWidgetZoneColumnContent = (
    data: BackendComponentData,
    rendererParams: RendererParams,
) => {
    if (typeof data?.content === 'string') {
        console.error(
            'String content in renderWidgetZoneColumnContent',
            data.content,
        );
        return null;
    }
    const columnChildren =
        data.content &&
        data.content.map((child, index) => {
            setComponentKey(child, index);

            if (isExtComponent(child.componentName)) {
                return renderExtContent(child, rendererParams);
            }
            return renderUIContent(child, rendererParams);
        });

    const columnContainsExtChildren =
        data.content &&
        data.content.find((child) => isExtComponent(child.componentName));

    if (!columnContainsExtChildren) {
        return createElement(WidgetZoneColumn, data.props, columnChildren);
    }

    return createElement(
        WidgetZoneColumn,
        data.props,
        createElement(
            ExtLayoutRenderer,
            {
                ...data.props,
                componentName: 'Mis.container.ExtLayoutContainer',
            },
            columnChildren,
        ),
    );
};

const renderColumnContent = (
    data: BackendComponentData,
    rendererParams: RendererParams,
) => {
    if (typeof data?.content === 'string') {
        console.error('String content in renderColumnContent', data.content);
        return null;
    }
    const content = data.content ?? [];
    // Add Internet Explorer deprecation banner if on the homepage
    if (rendererParams?.contentRequestUrl === '/home-ui/index') {
        const isBrowserIE = detectInternetExplorer();
        if (isBrowserIE) {
            content.unshift(IEBannerData);
        }
    }

    let childRendererParams = rendererParams;
    if (typeof data?.props?.formActions !== 'undefined') {
        childRendererParams = {
            ...rendererParams,
            parentFormActions: data.props.formActions,
        };
    }

    const minColumnWidth = findMinColumnWidth(content);

    const columnChildren = content.map((child, index) => {
        setComponentKey(child, index);

        if (isExtComponent(child.componentName)) {
            return renderExtContent(child, childRendererParams);
        }
        return renderUIContent(child, childRendererParams);
    });

    const columnContainsExtChildren = content.find((child) =>
        isExtComponent(child.componentName),
    );

    if (!columnContainsExtChildren) {
        return createElement(
            Column,
            {
                ...data.props,
                minColumnWidth,
            },
            columnChildren,
        );
    }

    return createElement(
        Column,
        {
            ...data.props,
            minColumnWidth,
        },
        createElement(
            ExtLayoutRenderer,
            {
                ...data.props,
                componentName: 'Mis.container.ExtLayoutContainer',
            },
            columnChildren,
        ),
    );
};

const renderPageContent = (
    data: PageRendererData,
    rendererParams: RendererParams,
) => {
    // This will render Column or WidgetZoneColumn components

    if (typeof data.content === 'string' || !isArray(data.content)) {
        // TODO [MIS-37802]: Add sentry logging to detect if this ever happens
        console.warn('Non-array data for page content', data);
        return data.content;
    }

    return data.content.map((cont, index) => {
        setComponentKey(cont, index);

        if (cont.componentName === 'Arbor.container.Column') {
            // Need use some special rendering logic for the Column component
            return renderColumnContent(cont, rendererParams);
        }
        if (cont.componentName === 'Arbor.container.WidgetZoneColumn') {
            return renderWidgetZoneColumnContent(cont, rendererParams);
        }
        console.error(
            'Page should only contain Column and WidgetZoneColumn',
            cont,
        );
        return renderUIContent(cont, rendererParams);
    });
};

/**
 * Method that takes the json response, then creates React elements using renderUIContent and renders it to main application layout.
 * Used for page rendering.
 *
 * @method
 * @public
 * @since v2.2.0
 * @param {Object} pageData - Data (JSON response) where partial view should be found.
 *
 * @see renderSubNav
 * @see renderPageContent
 * @memberof Renderer
 */

export function renderPage(
    pageData: PageRendererData,
    rendererParams: RendererParams & {contentRequestUrl: string},
    skipHistoryPush: boolean,
) {
    // Unmount the previous page so the new page can be safely rendered
    unmountAppRoot();
    const appContainer = document.getElementById(
        APP_CONTAINER_ID,
    ) as HTMLElement;
    setAppRootContainer(appContainer);

    if (rendererParams.redirectUrl) {
        // rederectUrl should always trigger a history push, regardless of skipHistoryPush.
        // This is to handle edge case around `loadCurrentPage` during login.
        pushState({}, '', '?' + rendererParams.redirectUrl);
        document.title = getUser()?.organizationName ?? 'Arbor';
    } else if (!skipHistoryPush) {
        pushState({}, '', '?' + rendererParams.contentRequestUrl);
        document.title = getUser()?.organizationName ?? 'Arbor';
    }

    try {
        if (pageData.navigation && pageData.navigation.items?.length > 0) {
            const {
                items,
                name,
                parentStaticRoute,
                parentStaticTreeOptionalParams,
            } = pageData.navigation;
            const newTreeData = transformNewNavigationData(
                items,
                rendererParams.contentRequestUrl,
                'dynamicNav',
            );
            updateDynamicNavData({
                treeData: newTreeData,
                title: name,
                parentStaticRoute,
                currentUrl:
                    rendererParams.redirectUrl ??
                    rendererParams.contentRequestUrl,
                parentStaticTreeOptionalParams,
                isNewDynamicNavigationData: true,
            });
        } else if (
            pageData.subNav &&
            pageData.subNav.props &&
            pageData.subNav.props.props
        ) {
            const {treeData, label} = pageData.subNav.props.props;
            updateDynamicNavData({
                treeData: transformOldSubnavData(treeData),
                title: label,
                currentUrl:
                    rendererParams.redirectUrl ??
                    rendererParams.contentRequestUrl,
                isNewDynamicNavigationData: false,
            });
        } else {
            clearDynamicNavData({
                currentUrl:
                    rendererParams.redirectUrl ??
                    rendererParams.contentRequestUrl,
            });
        }

        const renderedPage = renderWithStore(
            createElement(
                MainLayout,
                null,
                renderPageContent(pageData, rendererParams) as ReactNode[],
            ),
        );

        renderToAppRoot(renderedPage);
    } catch (e) {
        raiseDevError({title: 'Page render error!', message: e.message});
        Raven.captureException(e, {
            extra: {
                message: {
                    error: e,
                },
            },
        });
        console.error('Page render error!', e);
    }
}
