import * as React from 'react';

import {Button, IButtonProps} from 'Components/button/base';
import {loadPage} from 'Interfaces/Loader';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type LoadPageButtonProps = IButtonProps & {
    pageUrl?: string;
    event?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    reload?: boolean;
    scope?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    jsonData?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export function LoadPageButton(props: LoadPageButtonProps) {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const {pageUrl, event, reload, scope, jsonData} = props;

        // I don't think the event, reload, scope or  props are ever actually used
        // jsonData is passed from the BasketProperties component

        if (pageUrl) {
            loadPage(pageUrl, {
                event,
                reload,
                scope,
                jsonData,
                elementToRefocus: e?.currentTarget,
            });
        } else {
            throw new Error('No url provided!');
        }
    };

    return (
        <WrappedButton
            {...props}
            className="arbor-load-page-button"
            onClick={handleClick}
        />
    );
}
