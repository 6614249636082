import classNames from 'classnames';
import * as React from 'react';

import './keyboardFocusableButton.scss';

interface IKeyboardFocusableButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    setRef?: React.LegacyRef<HTMLButtonElement>;
    className?: string;
    buttonWrapperClassName?: string;
    borderRadius?: string;
    style?: React.CSSProperties;
    insetFocusRing?: boolean;
}

export const KeyboardFocusableButton = ({
    children,
    className,
    buttonWrapperClassName,
    borderRadius,
    setRef,
    style,
    insetFocusRing,
    ...others
}: IKeyboardFocusableButtonProps) => (
    // The purpose of this button is to allow a focus state to be applied, but only from
    // keyboard interactions. The <span> should be styled to actually look like a button,
    // and the <button> should have no style (other than border-radius) and simply wrap
    // the <span>.
    // The key to this component, is that the inner <span> has tabIndex="-1". This means that
    // it cannot be automatically focused by the keyboard. If you click on the <span> then it
    // will not be focused, and neither will the <button> behind it as focus events do not
    // propagate. If you tab to the component from previous element, then the <button> will
    // be focused. This means we can apply styles if the <button> element is focused by the
    // keyboard. The keyboard-focusable-button-wrapper class removes the default button styling
    // and applies a focus state to the button.
    // Loosely based on this article: https://www.kizu.ru/keyboard-only-focus/
    //
    // If you want to apply border radius to the component, you should pass it to the
    // borderRadius prop. This is so that it can be inherited by the <span> and by the
    // :focus::after pseudoelement. If you want to apply any other style to the button element
    // such as margin, you can use buttonWrapperClassName prop.
    //
    // If you need to apply styles to the button based on the button states, such as disabled,
    // you should use a buttonWrapperClassName prop and use a child selector for the <span>.
    <button
        ref={setRef}
        className={classNames(
            'keyboard-focusable-button-wrapper',
            {'keyboard-focusable-button-wrapper__inset': insetFocusRing},
            buttonWrapperClassName,
        )}
        style={{borderRadius: borderRadius}}
        {...others}
    >
        <span
            className={className}
            style={style}
            tabIndex={-1}
            role="presentation"
        >
            {children}
        </span>
    </button>
);
