import PropTypes from 'prop-types';

import {EmailFormModals} from './EmailFormModals';

export const FirstTimeLoginModals = ({onClose}) => (
    <EmailFormModals
        onClose={onClose}
        formTitle="First time logging in?"
        submitButtonText="Set password"
        formDescription={
            <div>
                <p>
                    Don’t worry! Just tell us the email address you will use to
                    log into Arbor and we’ll send you an email with a link to
                    set your password.
                </p>
                <p>
                    Unsure which email address to use?
                    <br />
                    Contact your school administrator and they will help you
                    out.
                </p>
            </div>
        }
        renderSuccessMessage={(successEmail) => (
            <div>
                <p>
                    We’ve sent an email to {successEmail} with a link to set
                    your password.
                </p>
                <p>
                    Didn’t receive the email yet?
                    <br />
                    Please check your spam folder. Contact your school
                    administrator if you don’t receive the email in the next 30
                    minutes.
                </p>
            </div>
        )}
    />
);
FirstTimeLoginModals.propTypes = {
    onClose: PropTypes.func,
};
