export const isUrlExternal = (url: string) => {
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    const domain = matches && matches[1]; // domain will be null if no match is found

    if (window.location.host !== domain && /^(f|ht)tps?:\/\//i.test(url)) {
        return true;
    }

    return false;
};
