import {splitSisUrlIntoSections} from 'Services/url/splitSisUrlIntoSections';

import {findRouteInTree} from '../utils';

import {prependZeros} from './prependZeros';

import type {TransformedNavigationItem} from 'State/navigation/types';

const getRouteFromUrl = (url: string) => splitSisUrlIntoSections(url).baseUrl;

type NavigationSubTree = {
    name: string;
    items: NavigationItem[];
};
export type NavigationItem = {
    name: string;
    hidden?: boolean;
    isAllowedByAcl?: boolean;
    isFeatureEnabled?: boolean;
    isModuleEnabled?: boolean;
    entryPoint?: string;
    unresolvedParams?: string[];
    hiddenReason?: string;
    optionalParams?: string[];
    alternativeNames?: string[];
    isDuplicate?: boolean;
} & (
    | {
          url: string;
          items?: NavigationItem[];
          subTree?: NavigationSubTree;
      }
    | {
          url?: string;
          items: NavigationItem[];
          subTree?: undefined;
      }
);

const transformTreeItem =
    (
        requestedRoute: string,
        parentId: string,
        parentEntryPoint: string | null,
    ) =>
    (item: NavigationItem, index: number) => {
        const {name, url, items, subTree, entryPoint, ...others} = item;

        const itemId = `${parentId}_${prependZeros(index)}`;

        const childItems: undefined | false | TransformedNavigationItem[] =
            items &&
            items.length > 0 &&
            items.map(
                transformTreeItem(
                    requestedRoute,
                    itemId,
                    entryPoint ?? parentEntryPoint,
                ),
            );

        const transformedSubTree =
            typeof subTree?.items !== 'undefined' && subTree.items.length > 0
                ? {
                      text: subTree.name,
                      id: `${itemId}_subTree`,
                      items: subTree.items.map(
                          transformTreeItem(
                              requestedRoute,
                              `${itemId}_subTree`,
                              entryPoint ?? parentEntryPoint,
                          ),
                      ),
                  }
                : null;

        let entryPointId: string | null = null;
        if (entryPoint && childItems) {
            const entryPointData = findRouteInTree(entryPoint, childItems);
            if (entryPointData) {
                entryPointId = entryPointData.id;
            }
        }

        const transformedItem: TransformedNavigationItem = {
            id: itemId,
            text: name,
            ...(url ? {url} : {}),
            ...(typeof url !== 'undefined' &&
            getRouteFromUrl(url) === requestedRoute
                ? {selected: true}
                : {}),
            ...(childItems
                ? {
                      items: childItems,
                  }
                : {}),
            ...(transformedSubTree
                ? {
                      subTree: transformedSubTree,
                  }
                : {}),
            ...(parentEntryPoint ? {parentEntryPoint} : {}),
            ...(entryPoint ? {entryPoint} : {}),
            ...(entryPointId ? {entryPointId} : {}),
            ...others,
        };

        return transformedItem;
    };

export const transformNewNavigationData = (
    treeData: NavigationItem[],
    contentRequestUrl: string,
    parentId: string,
) => {
    const requestedRoute = getRouteFromUrl(contentRequestUrl);

    if (!(treeData && treeData && treeData.length > 0)) {
        return [];
    }

    const transformedTreeData = treeData.map(
        transformTreeItem(requestedRoute, parentId, null),
    );
    return transformedTreeData;
};
