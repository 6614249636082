/*
    Doing this because in the output of tiny, we want it to be wrapped in a div with 
    the `mce-content-body` class, but we don't want this div in the editor (since 
    Tiny's iframe has that class on the body tag), so it gets stripped out here
*/

import {memoize} from 'Root/core/utils/memoize';

import {preprocessPastedContent} from './preprocessPastedContent';

const rawSanitizeEditorInput = async (rawValue = ''): Promise<string> => {
    // adding the paste preprocessing here as well, so that we don't get bad
    // markup from templates or previous drafts infecting new content
    const value = await preprocessPastedContent(rawValue);

    if (value.startsWith('<div')) {
        const container = document.createElement('div');
        container.innerHTML = value;

        const inner = container.firstChild as HTMLElement;
        const result = inner?.innerHTML || '';

        // doing this to prevent a potential memory leak
        container.remove();

        if (inner?.classList.contains('mce-content-body')) {
            return result;
        }
    }

    return value;
};

export const sanitizeEditorInput = memoize(rawSanitizeEditorInput);
