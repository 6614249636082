import {Component} from 'react';

import {LoadingDots} from 'Components/animations';
import {Button} from 'Components/button/base';
import {DropdownContainer} from 'Components/dropdown';
import {
    SearchFilterDropdownContents,
    SearchFilterDropdownField,
} from 'Components/dropdown/searchFilterDropdown';
import {FILTER_TAG_COLOR_ENUM, SelectedFilters} from 'Components/filters';
import Loader from 'Services/Loader';

import {
    saveFilterSettingsService,
    getFilterSettingsService,
    getFilterOptionsService,
} from './apiService';
import {ALL_SCHOOLS, MY_SCHOOLS} from './filterModes';
import {ToggleButton} from './toggleButton';
import {
    getSelectedOptionsFromIDs,
    transformOptions,
    getNumberOfSchoolsCopy,
    getSchoolsEmptyTextCopy,
    getClustersEmptyTextCopy,
} from './utils';

import './groupGlobalFilter.scss';

const CLUSTERS = 'selectedClusters';
const SCHOOLS = 'selectedSchools';

const initialSelectedState = {
    mySchools: {
        [CLUSTERS]: {},
        [SCHOOLS]: {},
    },
    allSchools: {
        [CLUSTERS]: {},
        [SCHOOLS]: {},
    },
};

export class GroupGlobalFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...initialSelectedState,
            clusterOptions: {},
            schoolOptions: {},
            selectedFilterMode: ALL_SCHOOLS,
            isFilterDirty: false,
            numberOfSchools: 0,
            isLoading: true,
            errorText: null,
            clusterSearchValue: '',
            schoolSearchValue: '',
        };
    }

    async fetchData() {
        this.setState({isLoading: true});
        try {
            const [
                {selectedFilterMode, numberOfSchools, mySchools, allSchools},
                {schoolOptions, clusterOptions},
            ] = await Promise.all([
                getFilterSettingsService(),
                getFilterOptionsService(),
            ]);

            this.setState({
                isLoading: false,
                mySchools,
                allSchools,
                clusterOptions,
                schoolOptions,
                selectedFilterMode,
                numberOfSchools,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                isLoading: false,
                errorText:
                    'Something went wrong while loading your filters. Please refresh the page. Should the error persists, please contact us.',
            });
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    saveFilters = async () => {
        const {mySchools, allSchools, selectedFilterMode} = this.state;

        this.setState({isLoading: true});
        try {
            await saveFilterSettingsService({
                mySchools,
                allSchools,
                selectedFilterMode,
            });
            this.setState({
                isFilterDirty: false,
                isLoading: false,
            });
            Loader.loadCurrentPage();
        } catch (e) {
            console.error('Error saving filters', e);
            this.setState({
                isLoading: false,
                errorText:
                    'It looks like something went wrong when we loaded your filters. To fix it, please try refreshing the page. If that doesn’t work, please get in contact with us at myteam@arbor-education.com',
            });
        }
    };

    clearFilters = () => {
        const {selectedFilterMode} = this.state;

        this.setState(
            {
                [selectedFilterMode]: {
                    [SCHOOLS]: {},
                    [CLUSTERS]: {},
                },
            },
            () => this.saveFilters(),
        );
    };

    changeFilterMode = (newMode) => {
        this.setState({selectedFilterMode: newMode}, () => {
            this.saveFilters();
        });
    };

    toggleFilter = (filterType) => (id) => {
        const {selectedFilterMode} = this.state;

        this.setState((prevState) => ({
            isFilterDirty: true,
            [selectedFilterMode]: {
                ...prevState[selectedFilterMode],
                [filterType]: {
                    ...prevState[selectedFilterMode][filterType],
                    [id]: !prevState[selectedFilterMode][filterType][id],
                },
            },
        }));
    };

    isClearButtonDisabled = () => {
        const {selectedFilterMode} = this.state;

        return (
            !Object.entries(this.state[selectedFilterMode][CLUSTERS]).some(
                ([, value]) => value,
            ) &&
            !Object.entries(this.state[selectedFilterMode][SCHOOLS]).some(
                ([, value]) => value,
            )
        );
    };

    handleClusterSearchValueChange = (e) => {
        this.setState({clusterSearchValue: e.target.value});
    };

    handleSchoolSearchValueChange = (e) => {
        this.setState({schoolSearchValue: e.target.value});
    };

    render() {
        const {
            clusterOptions,
            schoolOptions,
            selectedFilterMode,
            isFilterDirty,
            numberOfSchools,
            isLoading,
            mySchools,
            allSchools,
            errorText,
            clusterSearchValue,
            schoolSearchValue,
        } = this.state;

        const isMySchoolSelected = selectedFilterMode === MY_SCHOOLS;
        const selectedClusterIDs = isMySchoolSelected
            ? mySchools.selectedClusters
            : allSchools.selectedClusters;
        const selectedSchoolIDs = isMySchoolSelected
            ? mySchools.selectedSchools
            : allSchools.selectedSchools;

        const selectedSchools = getSelectedOptionsFromIDs(
            schoolOptions,
            selectedSchoolIDs,
        );
        const selectedClusters = getSelectedOptionsFromIDs(
            clusterOptions,
            selectedClusterIDs,
        );

        const transformedClusterOptions = transformOptions(
            clusterOptions,
            selectedClusterIDs,
        );
        const transformedSchoolOptions = transformOptions(
            schoolOptions,
            selectedSchoolIDs,
        );
        const transformedMySchoolOptions = transformedSchoolOptions.filter(
            ({isMySchool}) => (isMySchoolSelected ? isMySchool : true),
        );

        return (
            <div
                className="group-global-filter__container"
                data-testid="test-container"
            >
                <ToggleButton
                    isLoading={isLoading}
                    options={[
                        {
                            id: MY_SCHOOLS,
                            label: 'My Schools',
                        },
                        {
                            id: ALL_SCHOOLS,
                            label: 'All Schools',
                        },
                    ]}
                    selectedOption={selectedFilterMode}
                    onChange={this.changeFilterMode}
                />
                <div className="group-global-filter__selected-filters-container">
                    {errorText !== null && (
                        <div className="group-global-filter__error-message">
                            {errorText}
                        </div>
                    )}
                    <div className="group-global-filter__number-of-schools">
                        {isLoading && (
                            <>
                                Showing: <LoadingDots />
                            </>
                        )}
                        {!isLoading &&
                            getNumberOfSchoolsCopy({
                                isFilterDirty,
                                isMySchoolSelected,
                                isAnyFilterSelected:
                                    selectedClusters.length > 0 ||
                                    selectedSchools.length > 0,
                                numberOfSchools,
                            })}
                    </div>
                    <SelectedFilters
                        isLoading={isLoading}
                        isInactive={isFilterDirty}
                        filterGroups={[
                            {
                                color: FILTER_TAG_COLOR_ENUM.BLUE,
                                filters: selectedClusters,
                                onFilterTagClicked: this.toggleFilter(CLUSTERS),
                            },
                            {
                                color: FILTER_TAG_COLOR_ENUM.PINK,
                                filters: selectedSchools,
                                onFilterTagClicked: this.toggleFilter(SCHOOLS),
                            },
                        ]}
                    />
                </div>
                <div className="group-global-filter__actions-container">
                    <DropdownContainer
                        containerClassName="group-global-filter__clusters-dropdown"
                        renderDropdownField={(fieldProps) => (
                            <SearchFilterDropdownField
                                {...fieldProps}
                                searchValue={clusterSearchValue}
                                handleSearchValueChange={
                                    this.handleClusterSearchValueChange
                                }
                                placeholderText="Search school clusters e.g. Cluster A"
                                isLoading={isLoading}
                            />
                        )}
                        renderDropdownContents={(contentsProps) => (
                            <SearchFilterDropdownContents
                                {...contentsProps}
                                searchValue={clusterSearchValue}
                                searchItems={transformedClusterOptions}
                                onSearchItemClicked={this.toggleFilter(
                                    CLUSTERS,
                                )}
                                emptyText={getClustersEmptyTextCopy({
                                    clusterSearchValue,
                                    clusterOptionsLength:
                                        transformedClusterOptions.length,
                                })}
                            />
                        )}
                        shiftFocusWithLeftAndRightArrows
                    />
                    <DropdownContainer
                        containerClassName="group-global-filter__schools-dropdown"
                        renderDropdownField={(fieldProps) => (
                            <SearchFilterDropdownField
                                {...fieldProps}
                                searchValue={schoolSearchValue}
                                handleSearchValueChange={
                                    this.handleSchoolSearchValueChange
                                }
                                placeholderText="Search schools e.g. School A"
                                isLoading={isLoading}
                            />
                        )}
                        renderDropdownContents={(contentsProps) => (
                            <SearchFilterDropdownContents
                                {...contentsProps}
                                searchValue={schoolSearchValue}
                                searchItems={
                                    isMySchoolSelected
                                        ? transformedMySchoolOptions
                                        : transformedSchoolOptions
                                }
                                onSearchItemClicked={this.toggleFilter(SCHOOLS)}
                                emptyText={getSchoolsEmptyTextCopy({
                                    schoolSearchValue,
                                    isMySchoolSelected,
                                    mySchoolOptionsLength:
                                        transformedMySchoolOptions.length,
                                    schoolOptionsLength:
                                        transformedSchoolOptions.length,
                                })}
                            />
                        )}
                        shiftFocusWithLeftAndRightArrows
                    />
                    {isLoading ? (
                        <div className="group-global-filter__action-buttons-container group-global-filter__action-buttons-container--loading">
                            <div className="group-global-filter__button-wrapper group-global-filter__button-wrapper--loading"></div>
                            <div className="group-global-filter__button-wrapper group-global-filter__button-wrapper--loading"></div>
                        </div>
                    ) : (
                        <div
                            className="group-global-filter__action-buttons-container"
                            data-testid="not-loading"
                        >
                            <Button
                                text="Apply filter"
                                disabled={!isFilterDirty}
                                onClick={this.saveFilters}
                                color="green"
                                dataTestId="GroupGlobalFilters-applyButton"
                            />
                            <Button
                                text="Clear"
                                disabled={this.isClearButtonDisabled()}
                                onClick={this.clearFilters}
                                dataTestId="GroupGlobalFilters-clearButton"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
