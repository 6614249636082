import {httpGet} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

import {transformNavigationData} from './transformNavigationData';
import {transformNewNavigationData} from './transformNewNavigationData';

export const getNavigationDataService = async () => {
    const response = await httpGet(url.NAVIGATION.MAIN_MENU);

    if ('action_params' in response) {
        // We have the old format of navigation data
        if (response.items) {
            return {
                treeData: transformNavigationData(response),
                isNewNavigation: false,
            };
        }
        return {
            treeData: [],
            isNewNavigation: false,
        };
    }
    // We have the new format of navigation data
    const transformedData = transformNewNavigationData(
        response.items,
        '',
        'root',
    );
    return {
        treeData: transformedData,
        isNewNavigation: true,
    };
};
