import {WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID} from 'Constants/idStrings';
import {DEPENDEE_FIELD_NO_FORM_ACTION_NAME} from 'Interfaces/formFields/constants';

import exportInterface from '../Base';

export function getWindowPlaceholderId() {
    return WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID;
}
export function getDependeeFieldNoFormActionNameConstant() {
    return DEPENDEE_FIELD_NO_FORM_ACTION_NAME;
}

export const registerConstantsInterface = () => {
    exportInterface('Constants', {
        getWindowPlaceholderId,
        getDependeeFieldNoFormActionNameConstant,
    });
};
