/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 6/7/18
 * Time: 16:08
 */

import {reportToSisSentry as _reportToSisSentry} from 'Services/raven/Raven';

import exportInterface from '../Base';

export default function reportToSisSentry(error, extra) {
    _reportToSisSentry(error, extra);
}

export const registerRavenInterface = () => {
    exportInterface('Raven', {reportToSisSentry});
};
