import {addWindow, removeWindow} from 'Interfaces/WindowManager';

import {AutoFillModal} from './AutoFillModal';

type AutoFillModalParams = {
    contentRequestUrl?: string;
    insertCallback: (content: string) => void;
    refocusCallback: () => void;
    autoFillUrl: string;
    autoFillParams?: object;
};
export const showAutoFillModal = (params: AutoFillModalParams) => {
    const {
        contentRequestUrl,
        insertCallback,
        refocusCallback,
        autoFillUrl,
        autoFillParams,
    } = params;

    addWindow({
        content: (
            <AutoFillModal
                closeCallback={() => removeWindow()}
                insertCallback={insertCallback}
                autoFillUrl={autoFillUrl}
                autoFillParams={autoFillParams}
            />
        ),
        footerItems: null,
        title: 'Get some help writing',
        rendererParams: {
            contentRequestUrl,
            type: 'window',
            elementToRefocus: null,
            refocusCallback: refocusCallback,
        },
    });
};
