/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 6/5/18
 * Time: 16:29
 */

const currencySymbols = Object.freeze({
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
});

export default function getCurrencySymbol(currencyCode) {
    if (currencyCode && currencySymbols[currencyCode.toUpperCase()]) {
        return currencySymbols[currencyCode.toUpperCase()];
    }
    return '';
}
