export const findQueryStringInUrl = (url: string) => {
    const queryStringWithKeyAndValueRegex = /\?([^\/\?]*=[^\/\?]*$)/g;
    const keyAndValueResult = queryStringWithKeyAndValueRegex.exec(url);
    if (Array.isArray(keyAndValueResult)) {
        return keyAndValueResult[1];
    }

    // Some queries have just a key, and no value. However we must
    // be careful as in SIS urls we also use ? in the middle of urls,
    // such as pm4.uk.arbor.sc/?/student-ui/browse, and there are certain
    // valid cases that could get confused with query params,
    // i.e. pm4.uk.arbor.sc/?home-ui , which is a
    // shorthand for pm4.uk.arbor.sc/?/home-ui/index
    const queryFinderRegex = /[^\/]\?([^\/]*$)/g;
    const result = queryFinderRegex.exec(url);

    if (!Array.isArray(result)) {
        return null;
    }
    return result[1];
};
