/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 8/27/18
 * Time: 11:38
 */

import * as actions from 'State/actionCreators/Basket';
import Store from 'State/Store';

export function registerItem(item) {
    if (item.type && (item.type === 'single' || item.type === 'bundle')) {
        Store.getStore().dispatch(actions.registerItem(item));
    }
}

export function selectItem(id) {
    Store.getStore().dispatch(actions.selectItem(id));
}

export function deselectItem(id) {
    Store.getStore().dispatch(actions.deselectItem(id));
}
