import {sanitizeSimpleText} from 'Utils/sanitizeHtml';

import './simpleTableContainer.scss';

type SimpleTableContainerProps = {
    html: string;
};

/**
 * A simple container for Census Tables, that will render the HTML content passed
 * This component provides a CSS class which will give the table, some default styles,
 * as well as getting links to work with our client-side router (see sanisizeSimpleText)
 */
export const SimpleTableContainer = (props: SimpleTableContainerProps) => {
    return (
        <div
            className="simple-table-container"
            role="presentation"
            dangerouslySetInnerHTML={{__html: sanitizeSimpleText(props.html)}}
        />
    );
};
