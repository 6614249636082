import {ComboboxItem} from './types';

const NO_GROUP_KEY = '(No Group)';

export const splitIntoGroups = (items: ComboboxItem[]) => {
    const groups: Record<string, ComboboxItem[]> = {};
    items.forEach((item) => {
        const groupName = item.group ?? NO_GROUP_KEY;
        if (typeof groups[groupName] === 'undefined') {
            groups[groupName] = [];
        }
        groups[groupName].push(item);
    });
    return groups;
};

const labelSortComparator =
    ({
        searchValue,
        onlySortTopOptions,
    }: {
        searchValue?: string;
        onlySortTopOptions?: boolean;
    }) =>
    (a: ComboboxItem, b: ComboboxItem) => {
        const labelComparison = a.label
            .toString()
            .localeCompare(b.label.toString(), 'en', {
                numeric: true,
                sensitivity: 'base',
            });
        if (searchValue && searchValue !== '') {
            // Sort by position in the label that the search value appears
            const aIndex = a.label
                .toLowerCase()
                .indexOf(searchValue.toLowerCase());
            const bIndex = b.label
                .toLowerCase()
                .indexOf(searchValue.toLowerCase());
            let searchLabelIndexComparison = aIndex - bIndex;
            if (aIndex >= 0 && bIndex === -1) {
                searchLabelIndexComparison = -1;
            } else if (aIndex === -1 && bIndex >= 0) {
                searchLabelIndexComparison = 1;
            }

            // Sort labels alphabetically that contain the search value in the same position
            let searchLabelComparison = 0;
            if (aIndex >= 0) {
                searchLabelComparison = labelComparison;
            }

            return (
                searchLabelIndexComparison ||
                searchLabelComparison ||
                labelComparison
            );
        }
        if (a.topOption) {
            return -1;
        } else if (b.topOption) {
            return 1;
        }
        if (onlySortTopOptions) {
            return 0;
        }
        return labelComparison;
    };

const sortWithinGroup = (
    items: ComboboxItem[],
    params: {
        searchValue?: string;
        onlySortTopOptions?: boolean;
    },
) => {
    // Array.sort modifies array in place, so first make a copy
    return [...items].sort(labelSortComparator(params));
};

export const sortComboboxOptions = (
    items: ComboboxItem[],
    onlySortTopOptions?: boolean,
    searchValue?: string,
) => {
    const groups = splitIntoGroups(items);

    const sortParams = {
        searchValue,
        onlySortTopOptions,
    };

    const internallySortedGroups = Object.keys(groups).map((key) => {
        const groupItems = groups[key];
        const sortedItems = sortWithinGroup(groupItems, sortParams);
        return {groupKey: key, items: sortedItems};
    });

    internallySortedGroups.sort((groupA, groupB) => {
        // Since groups are sorted, if we compare the first items of the groups,
        // we can sort the groups such that the groups with the best match comes first
        const labelSortValue = labelSortComparator(sortParams)(
            groupA.items[0],
            groupB.items[0],
        );
        if (labelSortValue !== 0) {
            return labelSortValue;
        }
        if (sortParams.onlySortTopOptions) {
            return 0;
        }
        if (typeof searchValue === 'undefined' || searchValue === '') {
            return 0;
        }

        // Sort by position in the group label that the search value appears
        let searchGroupIndexComparison = 0;
        const groupAIndex = groupA.groupKey
            .toLowerCase()
            .indexOf(searchValue.toLowerCase());
        const groupBIndex = groupB.groupKey
            .toLowerCase()
            .indexOf(searchValue.toLowerCase());
        searchGroupIndexComparison = groupAIndex - groupBIndex;
        if (searchGroupIndexComparison !== 0) {
            return searchGroupIndexComparison;
        }

        return groupA.toString().localeCompare(groupB.toString(), 'en', {
            numeric: true,
            sensitivity: 'base',
        });
    });

    if (
        internallySortedGroups.length === 1 &&
        internallySortedGroups[0].groupKey === NO_GROUP_KEY
    ) {
        return internallySortedGroups[0].items;
    }
    return internallySortedGroups.flatMap((group) => [
        // Add labels to groups, and merge all groups into a flat list
        {
            value: group.groupKey + '_group_name_value',
            label: group.groupKey,
            isGroup: true,
        },
        ...group.items,
    ]);
};
