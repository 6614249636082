import {SideBarButton} from 'App/header/buttons/sideBarButton';
import {loadPage} from 'Interfaces/Loader';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';
import url from 'Services/url/Url';

import {HomeIcon} from './HomeIcon';

const handleLoadHomepageClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
) => {
    logEventToGainsight('HomeButtonClicked', {
        clickedFrom: getCurrentPageFromUrl(),
    });
    const {shiftKey, altKey, ctrlKey, metaKey} = e;
    if (!shiftKey && !altKey && !ctrlKey && !metaKey) {
        e.preventDefault();
        e.stopPropagation();
        loadPage(url.HOME_PAGE);
    }
};
type HomeButtonProps = {
    tooltip?: string;
};
export const HomeButton = ({tooltip}: HomeButtonProps) => (
    <SideBarButton
        tooltip={tooltip}
        onClick={handleLoadHomepageClick}
        buttonText="Home"
    >
        <HomeIcon />
    </SideBarButton>
);
