import PropTypes from 'prop-types';
import {Component} from 'react';

import CheckoutPaymentPanelContent from './CheckoutPaymentPanelContent';
import CheckoutPaymentPanelHeader from './CheckoutPaymentPanelHeader';

import './checkoutPaymentPanel.scss';

// Only used when buying sms credits
class CheckoutPaymentPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsible: props.collapsible,
            collapsed: props.collapsed,
        };
    }

    handleCollapse = () => {
        this.setState((state) => ({
            collapsed: !state.collapsed,
        }));
    };

    render() {
        const {title, subtitleText, children} = this.props;

        const {collapsible, collapsed} = this.state;

        return (
            <div className="arbor-checkout-payment-panel">
                <CheckoutPaymentPanelHeader
                    title={title}
                    subtitleText={subtitleText}
                    collapsible={collapsible}
                    collapsed={collapsed}
                    onCollapse={this.handleCollapse}
                />
                <CheckoutPaymentPanelContent collapsed={collapsed}>
                    {children}
                </CheckoutPaymentPanelContent>
            </div>
        );
    }
}

CheckoutPaymentPanel.propTypes = {
    title: PropTypes.string,
    subtitleText: PropTypes.string,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    children: PropTypes.node,
};

export default CheckoutPaymentPanel;
