import {createUrlWithAdditionalParams} from './createUrlWithAdditionalParams';
import {removeTrailingSlashes} from './slashHelpers';

export const createUrlWithReplacedHash = (
    inputUrl: string,
    newHash: string | null | undefined,
    reloadPage?: boolean,
) => {
    if (!newHash) {
        const url = removeTrailingSlashes(inputUrl);
        // Remove any existing hashes
        const urlWithoutHash = url.replace(/\/h\/[^\/?]+/, '');
        if (reloadPage) {
            return createUrlWithAdditionalParams(urlWithoutHash, {
                'reloaded-by-filter-panel': 'true',
            });
        }
        return urlWithoutHash;
    }
    if (reloadPage) {
        return createUrlWithAdditionalParams(inputUrl, {
            h: newHash,
            'reloaded-by-filter-panel': 'true',
        });
    }

    return createUrlWithAdditionalParams(inputUrl, {h: newHash});
};
