import {useEffect, useRef, useState} from 'react';

import {NewKpiData, OldKpiData} from 'Components/kpiPanel';
import {useIsMounted} from 'Utils/useIsMounted';

import {getKpiDataService} from './getKpiDataService';

export const useKpiData = (url?: string) => {
    const _isMounted = useIsMounted();
    const signalRef = useRef<AbortController | null>();
    const [items, setItems] = useState<OldKpiData[] | NewKpiData[] | null>(
        null,
    );
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const cancelRequestOnUnmount = () => {
            if (signalRef.current) {
                signalRef.current.abort();
                signalRef.current = null;
            }
        };
        if (!_isMounted) {
            return cancelRequestOnUnmount;
        }
        if (signalRef.current) {
            signalRef.current.abort();
            signalRef.current = null;
        }

        const fetchData = async () => {
            if (url) {
                setIsLoading(true);
                setError(null);
                try {
                    let controller: undefined | AbortController;
                    if (typeof AbortController !== 'undefined') {
                        controller = new AbortController();
                        signalRef.current = controller;
                    }
                    const kpiData = await getKpiDataService(
                        url,
                        controller && controller.signal,
                    );
                    if (!_isMounted) {
                        return;
                    }
                    setItems(kpiData);
                } catch (e) {
                    // TODO [MIS-37023]: Improve this error handling!
                    console.error('Error fetching KPI Data', e);
                    if (!_isMounted) {
                        return;
                    }
                    setError('Error fetching KPI Data');
                } finally {
                    if (!_isMounted) {
                        return;
                    }
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return cancelRequestOnUnmount;
    }, [url, _isMounted]);

    return {items, error, isLoading};
};
