import classNames from 'classnames';
import {useRef, useState} from 'react';
import embed, {Result, VisualizationSpec} from 'vega-embed';

import {httpGet} from 'Interfaces/httpClient';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';

import './vegaLiteChart.scss';

type VegaLiteChartProps = {
    chartSpec?: string;
    chartSpecUrl?: string;
    data?: string;
    dataUrl?: string;
    hideBorder?: boolean;
};

export const VegaLiteChart = (props: VegaLiteChartProps) => {
    const {
        chartSpec: rawChartSpec,
        chartSpecUrl,
        data: rawData,
        dataUrl,
        hideBorder,
    } = props;
    const containerRef = useRef<HTMLDivElement>(null);

    const embedResultRef = useRef<Result>();

    const [hasError, setHasError] = useState(false);

    const initializeChart = async () => {
        let chartSpec: VisualizationSpec = {};
        if (rawChartSpec) {
            chartSpec = JSON.parse(rawChartSpec);
        } else if (chartSpecUrl) {
            chartSpec = await httpGet(chartSpecUrl);
        }

        if (rawData) {
            chartSpec.data = JSON.parse(rawData);
        } else if (dataUrl) {
            chartSpec.data = await httpGet(dataUrl);
        }

        if (containerRef.current) {
            try {
                embedResultRef.current = await embed(
                    containerRef.current,
                    chartSpec,
                    {
                        actions: false,
                        theme: 'powerbi',
                    },
                );
            } catch (error) {
                console.error(error);
                setHasError(true);
            }
        } else {
            console.error('Container ref for Vega Lite chart not found');
            setHasError(true);
        }
    };

    useComponentDidMount(() => {
        initializeChart();

        return () => {
            if (embedResultRef.current) {
                embedResultRef.current.finalize();
            }
        };
    });

    return (
        <div
            className={classNames('vega-lite-chart__container', {
                'vega-lite-chart__container--no-border': hideBorder,
            })}
            ref={containerRef}
            tabIndex={0}
        >
            {hasError ? 'Error Loading Chart' : 'Loading Chart...'}
        </div>
    );
};
