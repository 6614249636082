import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Button} from 'Components/button/base';
import {raiseDevError, raiseError} from 'Interfaces/error/Error';
import {httpPost} from 'Interfaces/httpClient';
import {downloadFile} from 'Interfaces/Loader';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import {showMsg, showOkButton} from 'Interfaces/window/Window';
import {removeWindow} from 'Interfaces/WindowManager';
import Loader from 'Services/Loader';
import url, {appendFormatJson} from 'Services/url/Url';
import {selectSelectedIds} from 'State/selectors/Basket';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

// Only used in buying SMS credits
class CheckoutDownloadInvoiceButton extends Component {
    successHandler = () => {
        showMsg({
            title: _t('Thanks for your order!'),
            message: _t(
                'Your reports will be available in the My Reports section of your portal as soon as we\'ve received your payment. As a reminder, your schools will need to sign up or log in to their own portal to access their reports themselves at: <a href="' +
                    url.ARBOR.LOGIN +
                    '">' +
                    url.ARBOR.LOGIN +
                    '</a>.',
            ),
            width: 500,
            closable: false,
            buttons: showOkButton(),
            buttonText: {
                ok: _t('Go to My Reports page'),
            },
            fn: function () {
                Loader.loadPage(url.GROUP.MY_REPORTS);
            },
        });
    };

    handleClick = () => {
        const pageUrl = this.props.pageUrl;

        if (!pageUrl) {
            throw new Error("pageUrl hasn't been provided!");
        }

        let jsonData = {
            fields: {
                selectedIds: {
                    value: this.props.selectedIds,
                },
            },
        };

        if (this.props.rendererParams?.type === 'window') {
            removeWindow();
        }

        maskMain(_t('Loading...'));

        if (this.props.rendererParams?.role !== 'group-purchase-window') {
            this.successHandler = function () {
                Loader.loadCurrentPage();
            };
        }

        let errorCallback = function () {
            raiseDevError({
                title: 'Checkout error!',
                message: 'Error in CheckoutDownloadInvoiceButton component!',
            });
        };

        httpPost(appendFormatJson(pageUrl), jsonData)
            .then((response) => {
                unmaskMain();
                downloadFile(
                    response.action_params.fileUrl,
                    null,
                    this.successHandler,
                    errorCallback,
                );
            })
            .catch((response) => {
                unmaskMain();
                raiseError(
                    'Error',
                    _t(
                        'There was a problem processing your request. Please refresh the page, and contact us if you continue to experience issues.',
                    ),
                );
                Raven.captureException(
                    new Error('Checkout Download Invoice Error'),
                    {
                        extra: {
                            message: {
                                response,
                                requestedUrl: appendFormatJson(pageUrl),
                            },
                        },
                    },
                );
            });
    };

    render() {
        const {
            // don't want to pass it to Button
            pageUrl, // eslint-disable-line
            text,
            ...rest
        } = this.props;

        return (
            <WrappedButton
                {...rest}
                text={text}
                color="green"
                onClick={this.handleClick}
            />
        );
    }
}

CheckoutDownloadInvoiceButton.propTypes = {
    pageUrl: PropTypes.string,
    text: PropTypes.string,
    extNodeEl: PropTypes.object,
    selectedIds: PropTypes.array,
    rendererParams: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
    };
};

export default connect(mapStateToProps, undefined, undefined, {
    forwardRef: true,
})(CheckoutDownloadInvoiceButton);
