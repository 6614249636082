// These widths are the minimum width that a page column has to be for each component to fit.
// Columns have a default min width of 300px so widths lower this can be ignored.

// Note: all widths are in px
export const minColumnWidths = {
    'Arbor.container.FormFieldContainer': 1000,
    'Arbor.formfield.HtmlEditor': 950,
    'Arbor.formfield.DateRange': 850,
    'Arbor.container.CommunicationCenter': 800,
    'Arbor.attendance.AttendancePanel': 800,
    'Arbor.attendance.AttendanceProfilePanel': 800,
    'Arbor.container.LayoutContainer': 600,
    'Arbor.table.Table': 600,
    'Arbor.dataview.DataView': 600,
    'Arbor.formfield.CheckboxGroup': 500,
    'Arbor.colorPicker.ColorPicker': 475,
    'Arbor.chart.Chart': 450,
    'Arbor.container.PropertyRow': 450,
    'Arbor.formfield.TagField': 450,
    'Arbor.filter.FilterFieldStudent': 430,
    'Arbor.filter.FilterFieldGroup': 440,
    'Arbor.formfield.Combobox': 440,
    'Arbor.formfield.DateTime': 440,
    'Arbor.ExtNative.FileField': 440,
    'Arbor.container.GroupedButtons': 420,
    'Arbor.filter.FilterFieldCheckbox': 400,
    'Arbor.formfield.RadioGroup': 400,
    'Arbor.formfield.TextArea': 400,
    'Arbor.container.InfoPanel': 400,
    'Arbor.selector.SectionSelector': 380,
    'Arbor.purchase.PurchasePanel': 360,
    'Arbor.gridlist.GridList': 350,
    'Arbor.formfield.Currency': 350,
    'Arbor.container.DashboardKpis': 330,
    'Arbor.formfield.Time': 330,
    'Arbor.container.NewKpiPanel': 330,
    'Arbor.container.KpiPanel': 330,
    'Arbor.container.Dashboard': 330,
    'Arbor.toolbar.PagingToolbar': 320,
    'Arbor.calendar.Calendar': 320,
};
