import {getSlideoverRoot} from 'Interfaces/renderTargets';
import {renderWithStore} from 'Renderer/Store';

import {SlideoverManager} from './SlideoverManager';

import 'Interfaces/Slideover';

export function renderSlideoverManager() {
    getSlideoverRoot()?.render(renderWithStore(<SlideoverManager />));
}
