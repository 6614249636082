import classNames from 'classnames';
import * as React from 'react';

import {DropdownContainer} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import {DropdownMenuIcon} from './DropdownMenuIcon';

import type {ReactNode} from 'react';

import './dropdownMenu.scss';

const ColorOptions = {
    orange: 'orange',
    lightGrey: 'light-grey',
    blue: 'blue',
    transparent: 'transparent',
    green: 'green',
} as const;

interface IDropdownMenuItem {
    title: string;
    icon?: string;
    url?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    disabled?: boolean;
}

interface IDropdownMenuProps {
    items?: IDropdownMenuItem[];
    label: string;
    color?: (typeof ColorOptions)[keyof typeof ColorOptions];
    maxHeight?: number;
    isDisabled?: boolean;
    iconLeft?: ReactNode;
    dropdownWidth?: string;
    hideLabelOnNarrowScreens?: boolean;
    ariaLabel?: string;
    fieldClassName?: string;
}

export const DropdownMenu = ({
    items,
    label,
    color = ColorOptions.orange,
    maxHeight,
    isDisabled,
    iconLeft,
    dropdownWidth,
    hideLabelOnNarrowScreens,
    ariaLabel,
    fieldClassName,
}: IDropdownMenuProps) => {
    return (
        <div className="dropdown-menu-container">
            <DropdownContainer
                fieldClassName={fieldClassName}
                dropdownWidth={dropdownWidth}
                renderDropdownField={({
                    toggleDropdown,
                    isDropdownVisible,
                    toggleDropdownOnEnterOrSpace,
                }) => (
                    <KeyboardFocusableButton
                        className="dropdown-menu-button"
                        buttonWrapperClassName={classNames(
                            'dropdown-menu-button__wrapper',
                            {
                                [`dropdown-menu-button__wrapper--${color}`]:
                                    color,
                                'dropdown-menu-button__wrapper--disabled':
                                    isDisabled,
                            },
                        )}
                        onClick={toggleDropdown}
                        onKeyDown={toggleDropdownOnEnterOrSpace}
                        data-testid="DropdownMenu-menuButton"
                        disabled={isDisabled}
                        aria-label={ariaLabel}
                    >
                        {iconLeft}
                        <span
                            className={classNames(
                                'dropdown-menu-button__text',
                                {
                                    'dropdown-menu-button__text--responsive':
                                        hideLabelOnNarrowScreens,
                                },
                            )}
                        >
                            {label}
                        </span>
                        <Icon
                            iconName={
                                isDropdownVisible
                                    ? 'arrow-go-up'
                                    : 'arrow-go-down'
                            }
                            className="dropdown-menu-button-icon"
                            aria-hidden
                        />
                    </KeyboardFocusableButton>
                )}
                renderDropdownContents={({closeDropdown}) => (
                    <ul
                        className="dropdown-menu-content-container"
                        style={
                            maxHeight
                                ? {
                                      maxHeight: `${maxHeight}px`,
                                      overflowY: 'auto',
                                  }
                                : {}
                        }
                    >
                        {items &&
                            items.map(
                                (
                                    {
                                        title,
                                        icon,
                                        url,
                                        onClick,
                                        disabled: itemDisabled,
                                    },
                                    index,
                                ) => {
                                    return (
                                        <li key={`${title}-${icon}-${index}`}>
                                            <KeyboardFocusableLink
                                                url={url}
                                                className="dropdown-menu-url"
                                                linkWrapperClassName={classNames(
                                                    'dropdown-menu-url__wrapper',
                                                    {
                                                        'dropdown-menu-url__wrapper--disabled':
                                                            itemDisabled,
                                                    },
                                                )}
                                                insetFocusRing
                                                tabIndex={-1}
                                                disabled={itemDisabled}
                                                onClick={(event) => {
                                                    if (onClick) {
                                                        closeDropdown({
                                                            shouldMaintainFocus:
                                                                true,
                                                        });
                                                        onClick(event);
                                                    }
                                                }}
                                                data-testid={`DropdownMenu-menuItem_${index}`}
                                            >
                                                {icon && (
                                                    <span className="dropdown-menu-url-icon-container">
                                                        <DropdownMenuIcon
                                                            type={icon}
                                                            className="dropdown-menu-url-icon"
                                                        />
                                                    </span>
                                                )}
                                                <span className="dropdown-menu-url-text">
                                                    {title}
                                                </span>
                                            </KeyboardFocusableLink>
                                        </li>
                                    );
                                },
                            )}
                    </ul>
                )}
            />
        </div>
    );
};
