import {useEffect, useRef} from 'react';
import * as React from 'react';

import {Button} from 'Components/button/base';
import {LoadPageButton} from 'Components/button/loadPageButton';
import {FavouriteIcon} from 'Components/navigationTree/FavouriteIcon';
import {
    focusFirstFocusableElement,
    focusTrappingTabKeyHandler,
} from 'Utils/focusUtils';

import {SlideoverProps} from './types';

import './slideoverManager.scss';

export const Slideover = ({
    header,
    body,
    footerItems,
    handleRemove,
    userFavouritePages,
    rendererParams,
}: SlideoverProps) => {
    const slideoverRef = useRef<HTMLDivElement>(null);
    const userFavouritePage = userFavouritePages?.find(
        (page) => page.route === rendererParams.contentRequestUrl,
    );
    useEffect(() => {
        focusFirstFocusableElement(slideoverRef.current);
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        focusTrappingTabKeyHandler(slideoverRef.current, e);
    };

    return (
        <section
            role="dialog"
            aria-label={`Slideover: ${header.title}`}
            className="slideover-container"
            ref={slideoverRef}
            onKeyDown={handleKeyDown}
        >
            <section aria-label="Slideover header" className="slideover-header">
                {header.backButton ? (
                    <Button
                        text="&#x00AB; Back"
                        onClick={handleRemove}
                        className="slideover-header-back-button"
                    />
                ) : (
                    <div />
                )}
                <div className="slideover-header-title">
                    {header.title}
                    {header.enableCustomFavourite && (
                        <FavouriteIcon
                            url={rendererParams.contentRequestUrl || ''}
                            pageName={
                                userFavouritePage?.customName ||
                                header.title ||
                                ''
                            }
                            userFavouritePage={userFavouritePage}
                            isCurrentPage={false}
                        />
                    )}
                </div>
                {header.editUrl ? (
                    <LoadPageButton
                        text="Edit"
                        color="orange"
                        pageUrl={header.editUrl}
                        disabled={!header.editUrlAccessible}
                        tooltipMIS={header.editUrlTooltip}
                    />
                ) : (
                    <div />
                )}
            </section>
            <section
                className="slideover-content"
                aria-label="Slideover content"
            >
                {body}
            </section>
            {footerItems && footerItems.length > 0 && (
                <section
                    className="slideover-footer"
                    aria-label="Slideover footer"
                >
                    {footerItems}
                </section>
            )}
        </section>
    );
};
