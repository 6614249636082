import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import {Button} from 'Components/button/base';

export class PurchaseButton extends PureComponent {
    handleClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.id);
        }
    };

    render() {
        return <Button {...this.props} onClick={this.handleClick} />;
    }
}

PurchaseButton.defaultProps = {
    color: 'green',
};

PurchaseButton.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
};
