/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 9/10/18
 * Time: 16:48
 */
import exportInterface from 'Interfaces/Base';
import {unmaskMain} from 'Interfaces/Mask';
import interceptResponseService from 'Services/httpClient/interceptors/InterceptResponse';

export function interceptResponse(connection, response) {
    interceptResponseService({...response, data: response.responseText});
    unmaskMain();
}

export const registerInterceptResponseInterface = () => {
    exportInterface('Interceptors', {interceptResponse});
};
