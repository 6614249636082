import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import './azureButton.scss';

const handleClick = () => {
    window.location = '/azure-oauth/login';
};

export function AzureButton() {
    return (
        <KeyboardFocusableButton
            onClick={handleClick}
            buttonWrapperClassName="azure-button-container__wrapper"
            className="azure-button-container"
        >
            <span className="azure-icon-container">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="none"
                    viewBox="0 0 19 19"
                >
                    <path fill="#F25022" d="M9 0H0v9h9V0z" />
                    <path fill="#00A4EF" d="M9 10H0v9h9v-9z" />
                    <path fill="#7FBA00" d="M19 0h-9v9h9V0z" />
                    <path fill="#FFB900" d="M19 10h-9v9h9v-9z" />
                </svg>
            </span>
            <span className="azure-label-container">Log in with Microsoft</span>
        </KeyboardFocusableButton>
    );
}
