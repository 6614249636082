/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/6/18
 * Time: 18:30
 */

import {createElement} from 'react';
import {createRoot} from 'react-dom/client';

import {raiseDevError} from 'Interfaces/error/Error';
import {unmountPartialById} from 'Interfaces/Loader';
import {registerAdHocRoot} from 'Interfaces/renderTargets';
import {isExtComponent} from 'Renderer/componentCache';

import {ExtLayoutRenderer} from './ExtLayoutRenderer';
import {renderUIContent, renderExtContent, setComponentKey} from './Helper';
import {
    ExtPartialComponent,
    PartialPageRendererData,
    RendererParams,
} from './Renderer';

/**
 * Method that takes the json response data and DOM node for partial view, then renders the partial view into the given DOM element.
 * It uses ReactDOM to render the created React element.
 * Used for partials rendering.
 *
 * @method
 * @public
 * @since v2.2.0
 * @param {Object} partialData - Data (JSON response) where partial view should be found.
 * @param {Object} partial - DOM Element where partial view should be rendered
 *
 * @see componentCache
 * @see getComponentContructor
 * @see renderUIContent
 * @memberof Renderer
 */
export function renderPartial(
    partialData: PartialPageRendererData,
    partial: ExtPartialComponent | null,
    rendererParams: RendererParams,
) {
    try {
        if (partial && !partial.destroyed && partial.getEl()) {
            if (typeof partial.id === 'string') {
                unmountPartialById(partial.id);
            }
            if (typeof partialData.content === 'string') {
                console.error('renderPartial, string content', partialData);
                return;
            }
            const partialChildren =
                partialData.content &&
                partialData.content.map((child, index) => {
                    setComponentKey(child, index);

                    if (isExtComponent(child.componentName)) {
                        return renderExtContent(child, rendererParams);
                    }
                    return renderUIContent(child, rendererParams);
                });

            const renderedPartial = createElement(
                ExtLayoutRenderer,
                {
                    ...partialData.props,
                    componentName: 'Mis.container.ExtLayoutContainer',
                    itemId: 'partial-container-' + partial.id,
                },
                partialChildren,
            );

            const renderTarget = partial.getEl().dom as HTMLElement;
            const root = createRoot(renderTarget);
            registerAdHocRoot(root, renderTarget.id);
            root.render(renderedPartial);
        }
    } catch (e) {
        // TODO [MIS-37898] Should add error handling to display to the user what's gone wrong
        raiseDevError({
            title: 'Partial Page render error!',
            message: e.message,
        });
        Raven.captureException(e, {
            extra: {
                message: {
                    error: e,
                    partial,
                    partialString: JSON.stringify(partial),
                },
            },
        });
        console.error(e);
    }
}
