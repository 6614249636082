import DOMPurify from 'dompurify';

import {sanitizeLinks} from './sanitizeLinks';

export const sanitizeSimpleText = (dirtyText: string) => {
    DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

    const cleanText = DOMPurify.sanitize(dirtyText);

    DOMPurify.removeHook('afterSanitizeAttributes');

    return cleanText;
};
