import classnames from 'classnames';

import {NewKpiBoxTrend, NewKpiData} from '../types';

import './newKpiBox.scss';

const getTrendImageCls = (trend: NewKpiBoxTrend) => {
    const {icon, color} = trend;

    if (icon === 'up-arrow') {
        if (color === 'green') {
            return 'upward-positive';
        }
        return 'upward-negative';
    }
    if (icon === 'down-arrow') {
        if (color === 'green') {
            return 'downward-positive';
        }
        return 'downward-negative';
    }
    if (icon === 'unchanged') {
        return 'unchanged';
    }
    return '';
};

const getBarWidth = (
    {
        min,
        max,
        midPoint,
        unbounded,
    }: {
        min: number;
        max: number;
        midPoint?: number | null;
        unbounded?: boolean;
    },
    value: number,
) => {
    const normalisedMidpoint = midPoint || 0;
    const fillPercentage = unbounded ? 85 : 100;

    const width = Math.floor(
        (Math.abs(normalisedMidpoint - value) / (max - min)) * fillPercentage,
    );
    return isNaN(width) ? '0%' : `${width}%`;
};

const getAlignment = ({min, max, midPoint}, value) => {
    if (midPoint === null) {
        return {left: '0%'};
    }

    const calculatedMidPoint = (max + min) / 2;
    if (value > calculatedMidPoint) {
        return {left: '50%'};
    }
    return {right: '50%'};
};

type NewKpiBoxProps = NewKpiData & {
    isClickable?: boolean;
    isSelected?: boolean;
    hideBorder?: boolean;
};

export const NewKpiBox = ({
    isClickable,
    isSelected,
    bars,
    mainValue,
    mainValueColor,
    mainValueUnits,
    max,
    midPoint,
    min,
    segments,
    numberOfSegments,
    subTitle,
    trend,
    unbounded,
    title,
    description,
    hideBorder,
}: NewKpiBoxProps) => {
    return (
        <div
            data-testid="new-kpi-box"
            className={classnames('new-kpi-box', {
                'new-kpi-box--clickable': isClickable,
                'new-kpi-box--selected': isSelected,
                'new-kpi-box--hide-border': hideBorder,
            })}
        >
            <div className="new-kpi-box__title">{title}</div>
            <div className="new-kpi-box__subtitle">{subTitle}</div>
            <div
                className={classnames('new-kpi-box__main-value', [
                    mainValueColor,
                ])}
            >
                {mainValue}
                {mainValueUnits && (
                    <span className="percentage-unit">{mainValueUnits}</span>
                )}
            </div>
            {trend && (
                <div className="new-kpi-box__trend">
                    <div
                        className={classnames('new-kpi-box__trend-image', [
                            getTrendImageCls(trend),
                        ])}
                    />
                    <div className="new-kpi-box__trend-label">
                        <span className={classnames([trend.color])}>
                            {trend.valueLabel}
                        </span>{' '}
                        {trend.comparisonLabel}
                    </div>
                </div>
            )}
            {bars && typeof min === 'number' && typeof max === 'number' && (
                <div className="new-kpi-box__bars">
                    <div className="new-kpi-box__bars-name">
                        {bars.map((bar, index) => (
                            <div className="new-kpi-box__bar-name" key={index}>
                                <span>{bar.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className="new-kpi-box__bars-container">
                        {midPoint !== null &&
                            typeof midPoint !== 'undefined' && (
                                <div className="new-kpi-box__mid-point-separator" />
                            )}
                        {segments &&
                            numberOfSegments &&
                            Array.from(Array(numberOfSegments).keys()).map(
                                (_segment, index) => (
                                    <div
                                        className="new-kpi-box__segment-separator"
                                        key={index}
                                        style={{
                                            left: `${
                                                (100 / numberOfSegments) *
                                                (index + 1)
                                            }%`,
                                        }}
                                    />
                                ),
                            )}
                        {bars.map((bar, index) => (
                            <div
                                key={index}
                                className={classnames(
                                    'new-kpi-box__bar-container',
                                )}
                            >
                                <div
                                    className={classnames(
                                        'new-kpi-box__bar-content',
                                        [bar.color],
                                    )}
                                    style={{
                                        maxWidth: getBarWidth(
                                            {min, max, midPoint, unbounded},
                                            bar.value,
                                        ),
                                        ...getAlignment(
                                            {min, max, midPoint},
                                            bar.value,
                                        ),
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="new-kpi-box__bars-label">
                        {bars.map((bar, index) => (
                            <div
                                key={index}
                                className={classnames(
                                    'new-kpi-box__bar-label',
                                    [bar.color],
                                )}
                            >
                                {bar.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {description && (
                <p className="new-kpi-box__description">{description}</p>
            )}
        </div>
    );
};
