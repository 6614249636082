type WordCounterProps = {
    isValid: boolean;
    remainingCharacterCount: number;
    totalCharacterCount: number;
    wordCount: number;
    type: 'word' | 'sms';
    characterCount: number;
    hasCharacterLimit: boolean;
    signatureLength: number;
    stdMessageLength: number;
    smsNon7BitCoding: boolean;
    estimatedCredits: number;
};

export const WordCounter = (props: WordCounterProps) => {
    const {
        isValid,
        remainingCharacterCount,
        totalCharacterCount,
        hasCharacterLimit,
        estimatedCredits,
        wordCount,
        type,
        characterCount,
        signatureLength,
        stdMessageLength,
        smsNon7BitCoding,
    } = props;

    const validityClass = `html-editor__word-counter--${
        isValid ? 'valid' : 'invalid'
    }`;

    if (type === 'word') {
        return (
            <div
                data-testid="WordCounter__container"
                className="html-editor__word-counter"
            >
                {hasCharacterLimit && (
                    <>
                        <span
                            data-testid="WordCounter__remainingCharacterCount"
                            className={validityClass}
                        >
                            {remainingCharacterCount}
                        </span>{' '}
                        character
                        {remainingCharacterCount !== 1 && 's'} remaining,{' '}
                    </>
                )}
                <span
                    data-testid="WordCounter__wordCount"
                    className={validityClass}
                >
                    {wordCount}
                </span>{' '}
                word{wordCount !== 1 && 's'}
            </div>
        );
    }

    return (
        <div
            data-testid="WordCounter__container"
            className="html-editor__word-counter"
        >
            Estimated:{' '}
            <span
                data-testid="WordCounter__totalCharacterCount"
                className={validityClass}
            >
                {totalCharacterCount}
            </span>{' '}
            character{totalCharacterCount !== 1 && 's'} ({characterCount} in
            message + {signatureLength} in signature) remaining,{' '}
            <span data-testid="WordCounter__estimatedCredits">
                {estimatedCredits}
            </span>{' '}
            credit
            {estimatedCredits !== 1 && 's'} ({stdMessageLength} characters per
            credit
            {smsNon7BitCoding && ' - non-standard charset used'})
        </div>
    );
};
