/**
 * Used to help with sorting and filtering of numeric ids
 * */
export const prependZeros = (input: number) => {
    const stringified = String(input);
    if (stringified.length === 1) {
        return `00${stringified}`;
    }
    if (stringified.length === 2) {
        return `0${stringified}`;
    }
    if (stringified.length === 3) {
        return stringified;
    }
    // Probably don't need to go any deeper unles we have >999 items in one folder
    return stringified;
};
