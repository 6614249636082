import PropTypes from 'prop-types';
import {Component} from 'react';

import {Button} from 'Components/button/base';
import Modal from 'Components/modal/Modal';

import './button.scss';

export default function (WrappedComponent) {
    class ButtonHOC extends Component {
        constructor() {
            super();
            this.state = {modalOpened: false};
        }
        handleModal = (newState) => {
            return this.setState({modalOpened: newState});
        };
        showModal = () => this.handleModal(true);
        hideModal = () => this.handleModal(false);
        renderContent = () => {
            const footer = (
                <div>
                    <Button text={'No'} onClick={() => this.hideModal()} />
                    <Button
                        text={this.props.confirmationButtonText}
                        onClick={this.onConfirmClick}
                        color={this.props.confirmationButtonColor}
                    />
                </div>
            );
            return this.props.confirmationText ? (
                <div className="button-wrapper__container--with-confirmation-modal">
                    <WrappedComponent
                        {...this.props}
                        onClick={this.showModal}
                    />
                    <Modal
                        isOpen={this.state.modalOpened}
                        shouldCloseOnEsc={false}
                        onClose={this.hideModal}
                        title={this.props.confirmationTitle}
                        footer={footer}
                    >
                        {this.props.confirmationText}
                    </Modal>
                </div>
            ) : (
                <WrappedComponent
                    {...this.props}
                    onClick={this.onWrapperClick}
                />
            );
        };
        render() {
            return this.renderContent();
        }
        onConfirmClick = () => {
            this.hideModal();
            if (this.props.onClick) {
                this.props.onClick();
            }
        };
        onWrapperClick = () => {
            this.showModal();
            if (this.props.onClick) {
                this.props.onClick();
            }
        };
    }
    ButtonHOC.propTypes = {
        confirmationTitle: PropTypes.string,
        confirmationButtonText: PropTypes.string,
        confirmationText: PropTypes.string,
        confirmationButtonColor: PropTypes.string,
        url: PropTypes.string,
        onClick: PropTypes.func,
    };
    ButtonHOC.defaultProps = {
        confirmationTitle: 'Confirmation',
        confirmationButtonText: 'Yes',
        url: null,
    };
    return ButtonHOC;
}
