export const validationRules = {
    required: {
        regex: /\S/,
        msg: 'This field is required',
    },
    email: {
        regex: /.+@.+/,
        msg: 'The email is incomplete or incorrectly formatted. Please update this address to follow the example "name@domain.com"',
    },
};
