import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';

import {
    DropdownContainer,
    IDropdownContentsRenderProps,
    IDropdownFieldRenderProps,
} from 'Components/dropdown';
import {FormFieldWrapper} from 'Components/formField';
import {
    useFormFieldRegistry,
    useRefetchFormValue,
    extractCommonFormFieldParams,
} from 'Interfaces/formFields';
import {focusFirstFocusableElement} from 'Root/core/utils/focusUtils';
import {SISFormFieldCommonProps} from 'Services/formField/FormField';

import {colorNames, colorPalette, getColorFromName} from './colorPalette';

import './colorPicker.scss';

export type ColorPickerProps = {
    value: string;
    autofocus?: boolean;
} & SISFormFieldCommonProps;

export const ColorPicker = (props: ColorPickerProps) => {
    const {
        fieldLabel,
        tooltip,
        tooltipMIS,
        tooltipUrl,
        value: initialValue,
        url,
        required,
    } = props;

    const [value, setValue] = useState<string | null>(initialValue);
    const currentValueRef = useRef(value);
    const [errors, setErrors] = useState<string[]>([]);

    const containerRef = useRef<HTMLElement | null>(null);

    const updateValue = (newValue: string | null) => {
        setValue(newValue);
        setErrors([]);
        currentValueRef.current = newValue;
    };

    const {isLoading, refetchFormValue} = useRefetchFormValue(
        url,
        (newValue) => {
            if (typeof newValue === 'string' || newValue === null) {
                updateValue(newValue);
            }
        },
    );

    const {updateFormRegistryValue} = useFormFieldRegistry({
        ...extractCommonFormFieldParams(props),
        getSubmitValue() {
            return currentValueRef.current;
        },
        getValue() {
            return currentValueRef.current;
        },
        markInvalid(errorMessages) {
            if (Array.isArray(errorMessages)) {
                setErrors(errorMessages);
            } else {
                setErrors([errorMessages]);
            }
        },
        fetchNewData: refetchFormValue,
        setValue: (newValue) => {
            if (typeof newValue !== 'string') {
                console.error(
                    'Non string value in ColorPicker useFormFieldRegistry->setvalue',
                    newValue,
                );
                return;
            }
            setValue(newValue);
        },
    });

    useEffect(() => {
        // Need to check for duplicate versions on mount
        updateFormRegistryValue(value);
    }, [value, updateFormRegistryValue]);

    const renderColorField = ({
        toggleDropdown,
        isDropdownVisible,
        closeDropdown,
    }: IDropdownFieldRenderProps) => {
        const currentColor = getColorFromName(value);

        return (
            <div className="color-picker-field__container">
                <button
                    aria-label="Open color palette"
                    className="color-picker-field__background"
                    onClick={toggleDropdown}
                >
                    {currentColor && (
                        <>
                            <span
                                className={classNames(
                                    'color-picker-field__color',
                                    {
                                        'color-picker-field__color--grey-border':
                                            currentColor.name ===
                                            colorNames.white,
                                    },
                                )}
                                key="color preview"
                                style={{
                                    background: currentColor.background,
                                }}
                            />
                            <span className="color-picker-field__text">
                                {currentColor.displayName}
                            </span>
                            <span
                                data-testid="ColorPicker_clearButton"
                                aria-label="Clear color selection"
                                className="color-picker-filed__clear-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closeDropdown({
                                        shouldMaintainFocus: true,
                                    });
                                    updateValue(null);
                                }}
                            />
                        </>
                    )}
                </button>
                <span
                    className={classNames(
                        'color-picker-field__open-button',
                        'color-picker-field__down-arrow-icon',
                        {
                            'color-picker-field__open-button--active':
                                isDropdownVisible,
                        },
                    )}
                    onClick={toggleDropdown}
                    data-testid="ColorPicker_openDropdown"
                />
            </div>
        );
    };

    const renderColorPalette = ({
        closeDropdown,
    }: IDropdownContentsRenderProps) => {
        return (
            <div
                data-testid="ColorPicker_paletteContainer"
                className="color-picker-palette__container"
            >
                {colorPalette.map((colorGroup) => (
                    <span
                        className="color-picker-palette__column"
                        key={`column-${colorGroup.name}`}
                    >
                        {colorGroup.colors.map((color) => (
                            <button
                                className={classNames(
                                    'color-picker-palette__color',
                                    {
                                        'color-picker-palette__color--active':
                                            color.name === value,
                                        'color-picker-palette__color--grey-border':
                                            color.name === colorNames.white,
                                    },
                                )}
                                key={`color: ${color.name}`}
                                onClick={() => {
                                    updateValue(color.name);
                                    closeDropdown({
                                        shouldMaintainFocus: true,
                                    });
                                }}
                                style={{
                                    background: color.background,
                                }}
                            />
                        ))}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <FormFieldWrapper
            label={fieldLabel}
            required={required}
            tooltip={tooltipMIS ?? tooltip}
            tooltipUrl={tooltipUrl}
            isLoading={isLoading}
            focusCallback={() => {
                // Wrapped in a timeout because DropdownContainer has a click listener on the
                // document for clicks outside the field to close the dropdown
                setTimeout(() => {
                    if (containerRef.current) {
                        focusFirstFocusableElement(containerRef.current);
                    }
                });
            }}
        >
            <span className="color-picker__container" ref={containerRef}>
                <DropdownContainer
                    renderDropdownField={renderColorField}
                    renderDropdownContents={renderColorPalette}
                    dropdownWidth="322px"
                    openOnFocus
                />
                {errors.length > 0 && (
                    <ul
                        role="presentation"
                        className="color-picker__error-list"
                    >
                        {errors.map((error) => (
                            <li>{error}</li>
                        ))}
                    </ul>
                )}
            </span>
        </FormFieldWrapper>
    );
};
