import PropTypes from 'prop-types';

import type {ReactNode} from 'react';

import './dropdownContentsEmptyText.scss';

interface IDropdownContentsEmptyTextProps {
    children: ReactNode;
}

// TODO MIS-37383: This component shouldn't be exposed but used as a part of DropdownContainer
export const DropdownContentsEmptyText = ({
    children,
}: IDropdownContentsEmptyTextProps) => (
    <p className="dropdown-contents-empty-text">{children}</p>
);

DropdownContentsEmptyText.propTypes = {
    children: PropTypes.node,
};
