import {TransformedNavigationItem} from '../types';

export const addDynamicNavToModuleNav = (
    moduleNav: TransformedNavigationItem[],
    dynamicNav: TransformedNavigationItem[],
    staticParentId: string,
    title: string,
) => {
    const combined: TransformedNavigationItem[] = moduleNav.map((item) => {
        return {
            ...item,
            ...(item.items
                ? {
                      items: addDynamicNavToModuleNav(
                          item.items,
                          dynamicNav,
                          staticParentId,
                          title,
                      ),
                  }
                : {}),
            ...(item.id === staticParentId
                ? {
                      subTree: {
                          items: dynamicNav,
                          id: 'dynamicNav',
                          text: title,
                      },
                  }
                : {}),
        };
    });
    return combined;
};
