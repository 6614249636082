import {HeaderContainer} from 'App/header';
import {APP_CONTAINER_ID, HEADER_CONTAINER_ID} from 'Constants/idStrings';
import {
    getHeaderRoot,
    setAppRootContainer,
    unmountAppRoot,
} from 'Interfaces/renderTargets';
import {getUser} from 'Interfaces/Session';
import {renderWithStore} from 'Renderer/Store';
import {userFavouritePagesAction} from 'Root/core/state/navigation/actions';
import Store from 'Root/core/state/Store';
import {initialiseGainsight} from 'Services/gainsight';
import {configureRaven} from 'Services/raven/Raven';
import {getUserNotificationService} from 'Services/userNotifications/UserNotification';

export function postLoginInitialization() {
    // Before we render anything, make sure the login page has been unmounted
    unmountAppRoot();
    const appContainer = document.getElementById(
        APP_CONTAINER_ID,
    ) as HTMLElement;
    setAppRootContainer(appContainer);

    initialiseGainsight(getUser());
    getUserNotificationService().init(getUser()?.notifications);
    configureRaven(getUser());
    const userFavouritePages = getUser()?.userFavouritePages;
    if (userFavouritePages) {
        Store.getStore().dispatch(userFavouritePagesAction(userFavouritePages));
    }

    const headerNode = document.getElementById(HEADER_CONTAINER_ID);

    if (headerNode === null) {
        throw new Error('Header container not found!');
    }

    getHeaderRoot()?.render(renderWithStore(<HeaderContainer />));
}
