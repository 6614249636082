import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {focusFirstFocusableElement} from 'Utils/focusUtils';

import './skipToContentButton.scss';

const focusMainContent = () => {
    const pageContainer = document.querySelector('.page-container');
    focusFirstFocusableElement(pageContainer);
};

export const SkipToContentButton = () => (
    <KeyboardFocusableButton
        tabIndex={1}
        buttonWrapperClassName="skip-to-content-button__wrapper"
        onClick={() => focusMainContent()}
    >
        Skip to content
    </KeyboardFocusableButton>
);
