import {Button, IButtonProps} from 'Components/button/base';
import {submitMisForm} from 'Interfaces/form/Form';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type UploadButtonProps = IButtonProps & {
    currentAction?: unknown;
};
export function UploadButton(props: UploadButtonProps) {
    const {currentAction} = props;

    const onClickHandler = () => {
        if (currentAction) {
            submitMisForm(currentAction, 'upload');
        } else {
            throw new Error('No current action provided!');
        }
    };

    return (
        <WrappedButton
            {...props}
            className="arbor-upload-button"
            onClick={onClickHandler}
        />
    );
}

UploadButton.defaultProps = {
    text: 'Upload',
};
