import classNames from 'classnames';
import {useState} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

export const ChartToggle = (props) => {
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
    const handleSetKey = (newKey: number) => {
        setSelectedGroupIndex(newKey);
    };

    return (
        <section aria-label="Chart group">
            <div id="chart-toggle-buttons" role="tablist">
                {Object.keys(props.children).map((chartGroupKey, index) => {
                    const chartGroupProps = props.children[chartGroupKey].props;
                    if (props.children.length > 1) {
                        return (
                            <KeyboardFocusableButton
                                onClick={() => {
                                    handleSetKey(index);
                                }}
                                className={classNames('chart-toggle-button', {
                                    'chart-toggle-button--selected':
                                        selectedGroupIndex === index,
                                })}
                                key={chartGroupKey}
                                role="tab"
                                aria-selected={
                                    selectedGroupIndex === index
                                        ? 'true'
                                        : 'false'
                                }
                            >
                                <Icon
                                    iconName={chartGroupProps.icon}
                                    className="chart-toggle-button__icon"
                                ></Icon>

                                {chartGroupProps.label}
                            </KeyboardFocusableButton>
                        );
                    }
                    return null;
                })}
            </div>
            <div id="chart-toggle-charts">
                {props.children.map((child, index) => ({
                    ...child,
                    props: {
                        ...child.props,
                        isSelected: index === selectedGroupIndex,
                    },
                }))}
            </div>
        </section>
    );
};
