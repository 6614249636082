import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Children, cloneElement, Component} from 'react';

import {Icon} from 'Components/icon';
import {SimpleText} from 'Components/simpleText';
import Text from 'Config/Text';

import './banner.scss';

class Banner extends Component {
    colorByType = () => {
        let color;
        switch (this.props.type) {
            case 'warning':
                color = 'orange';
                break;
            case 'error':
                color = 'red';
                break;
            default:
                break;
        }
        return color;
    };

    hasActions = (childrenItems) =>
        childrenItems.find((child) => child.key.startsWith('Arbor.button'));

    findSimpleTexts = (item) => item.type === SimpleText;

    render() {
        const {type, title, children, icon} = this.props;

        return (
            <section
                aria-label="Banner"
                className={classnames('arbor-information-banner', {
                    [`arbor-banner-type-${type}`]: type,
                })}
            >
                {icon && (
                    <div className="icon-area">
                        <Icon iconName={icon} />
                    </div>
                )}
                <div className="content-actions-area">
                    <div className="title-content-area">
                        {title && <div className="title">{title}</div>}
                        <div className="content">
                            {children
                                ? children.filter(this.findSimpleTexts)
                                : Text.BANNER.EMPTY_TEXT}
                        </div>
                    </div>
                    {children && this.hasActions(children) && (
                        <div className="actions-area">
                            {Children.map(children, (thisArg) => {
                                if (thisArg.key.startsWith('Arbor.button')) {
                                    return thisArg.props.uiType === 'primary'
                                        ? cloneElement(thisArg, {
                                              color: this.colorByType(),
                                          })
                                        : cloneElement(thisArg, {
                                              color: 'grey',
                                          });
                                }
                            })}
                        </div>
                    )}
                </div>
            </section>
        );
    }
}

Banner.propTypes = {
    type: PropTypes.oneOf(['system', 'info', 'warning', 'error']),
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.node,
};

Banner.defaultProps = {
    type: 'system',
};

export default Banner;
