import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';

import {SystemButton} from 'Components/button/base';
import {ESC_KEY, TAB_KEY} from 'Config/keyboard';

import keyHandlerHOC from './KeyHandler';

import './modal.scss';

class Modal extends Component {
    componentDidMount() {
        this.focusableChildren = [];
        if (this.modal) {
            this.focusableChildren = [
                this.modal,
                ...[...this.modal.querySelectorAll('input, button, li')].sort(
                    (a, b) => {
                        if (
                            a.getAttribute('tabindex') <
                            b.getAttribute('tabindex')
                        ) {
                            return a;
                        }
                        return b;
                    },
                ),
            ];
            this.focusContent();
        }
    }
    shouldComponentUpdate = (nextProps) => {
        if (!nextProps.isOpen && !this.props.closable) {
            return false;
        }
        return true;
    };

    onEscDown = () => {
        if (this.props.shouldCloseOnEsc) {
            this.props.onClose();
        }
    };
    onTabDown = (event) => {
        event.preventDefault();
        this.focusManager(this.modal);
        return;
    };
    focusManager = (rootEl) => {
        let hasFocused = rootEl.contains(document.activeElement);
        if (this.focusableChildren.length) {
            if (hasFocused) {
                for (let i = 0; i < this.focusableChildren.length; i++) {
                    let each = this.focusableChildren[i];
                    if (each === document.activeElement) {
                        if (this.focusableChildren[i + 1]) {
                            this.focusableChildren[i + 1].focus();
                        } else {
                            this.focusableChildren[0].focus();
                        }
                        break;
                    }
                }
            } else {
                this.focusableChildren[1].focus();
            }
        } else {
            this.focusableChildren[0].focus();
        }
    };
    contentHasFocus = () =>
        document.activeElement === this.modal ||
        this.modal.contains(document.activeElement);

    focusContent = () =>
        this.modal && !this.contentHasFocus() && this.modal.focus();

    setRef = (modal) => {
        this.modal = modal;
    };
    handleClose = () => {
        if (this.props.closable) {
            this.props.onClose();
        }
        return;
    };

    prepareHTMLContent = (content) => ({__html: content});

    render() {
        const {isOpen, title, footer, children, className, size, closable} =
            this.props;

        return isOpen ? (
            <div
                className={classnames(
                    'modal modal-overlay vertical-align horizontal-align',
                    className,
                )}
                ref={this.setRef}
                tabIndex="0"
            >
                <div className={classnames('modal-content', size)}>
                    <div className="modal-header">
                        <span className="modal-title">{title}</span>
                        {closable && (
                            <SystemButton
                                icon="remove"
                                className="modal-close"
                                onClick={this.handleClose}
                            />
                        )}
                    </div>
                    {typeof children === 'string' ? (
                        <div
                            className="modal-body"
                            dangerouslySetInnerHTML={this.prepareHTMLContent(
                                children,
                            )}
                        />
                    ) : (
                        <div className="modal-body">{children}</div>
                    )}
                    <div className="modal-footer">{footer}</div>
                </div>
            </div>
        ) : null;
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    shouldCloseOnEsc: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    className: PropTypes.string,
    closable: PropTypes.bool,
};

Modal.defaultProps = {
    isOpen: false,
    shouldCloseOnEsc: true,
    size: 'small',
    closable: true,
};

export default keyHandlerHOC(Modal, {onKeyDown: [ESC_KEY, TAB_KEY]});
