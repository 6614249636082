import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {setSelectedTabById} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectTabFilter} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {TAB_IDS} from 'BackendRenderedComponents/dashboard/redux/types';

import {MyStudentsTab} from './MyStudentsTab';
import {Tabs} from './Tabs';
import {WholeSchoolTab} from './WholeSchoolTab';

const UnconnectedDashboardTabs = ({tabs, actions}) => {
    const tabClicked = (index) => {
        const id = tabs[index].id;
        actions.setSelectedTabById({id});
    };
    return (
        <Tabs
            selectedTabIndex={tabs.findIndex((tab) => tab.selected)}
            tabClickedHandler={tabClicked}
            tabs={tabs.map((tab) =>
                tab.id === TAB_IDS.MY_STUDENTS
                    ? {
                          title: 'My Students',
                          contents: <MyStudentsTab />,
                      }
                    : {
                          title: 'Whole School',
                          contents: <WholeSchoolTab />,
                      },
            )}
        />
    );
};

UnconnectedDashboardTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        setSelectedTabById: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    tabs: selectTabFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            setSelectedTabById,
        },
        dispatch,
    ),
});

export const DashboardTabs = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedDashboardTabs);
