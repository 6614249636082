import {Button} from 'Components/button/base';
import {loadPage} from 'Interfaces/Loader';

type CreateSnippetButtonProps = {
    context: {objectTypeId: number; objectId: number};
};

export const CreateSnippetButton = (props: CreateSnippetButtonProps) => {
    const {context} = props;

    const pageUrl = `/snippet-ui/create-snippet/context/${context.objectTypeId},${context.objectId}`;

    return (
        <Button
            text="New Snippet"
            color="green"
            onClick={() => {
                loadPage(pageUrl);
            }}
        />
    );
};
