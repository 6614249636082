import {connect} from 'react-redux';

import {Icon} from 'Components/icon';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {FavouriteIcon} from 'Components/navigationTree/FavouriteIcon';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {logEventToGainsight} from 'Services/gainsight';
import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';
import {selectUserFavouritePages} from 'State/navigation/selectors';

import './favourites.scss';

const IconWithTooltip = tooltipWrapper(Icon);

type FavouriteItemProps = {
    page: UserFavouritePage;
    widgetLocation: 'Header' | 'HomePage' | 'SideBar';
};

const FavouriteItem = ({page, widgetLocation}: FavouriteItemProps) => {
    const {customName, route} = page;
    return (
        <li className="favourites-list-item">
            <KeyboardFocusableLink
                linkWrapperClassName="favourites-list-item__link"
                url={route}
                onClick={() =>
                    logEventToGainsight('CustomFavouriteClicked', {
                        url: route,
                        location: widgetLocation,
                        clickedFrom: getCurrentPageFromUrl(),
                    })
                }
                disableDefaultLinkStyles
                insetFocusRing
                className="favourites-list-item__label"
            >
                {customName}
            </KeyboardFocusableLink>
            <FavouriteIcon
                url={route}
                userFavouritePage={page}
                pageName={customName}
                isCurrentPage={false}
            />
        </li>
    );
};

type FavouritesProps = {
    userFavouritePages: ReturnType<typeof selectUserFavouritePages>;
    widgetLocation: 'Header' | 'HomePage' | 'SideBar';
};

const UnconnectedFavourites = ({
    userFavouritePages,
    widgetLocation = 'HomePage',
}: FavouritesProps) => {
    return (
        <div className="favourites-container">
            <div className="favourites-header">
                Favourites
                <IconWithTooltip
                    tooltip={
                        'Add favourites by going to the specific page in Arbor and tapping the star icon in the side navigation. Remove favourites by tapping the star icon on the homepage, or by tapping the icon in the side navigation of the favourited page.'
                    }
                    iconName="info"
                    className="favourites-header-icon"
                />
            </div>
            <ul className="favourites-list">
                {userFavouritePages.map((page, index) => (
                    <FavouriteItem
                        key={`${index}_${page.userFavouritePageId}`}
                        page={page}
                        widgetLocation={widgetLocation}
                    />
                ))}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userFavouritePages: selectUserFavouritePages(state),
});

export const Favourites = connect(mapStateToProps)(UnconnectedFavourites);
