import {useEffect, useRef} from 'react';

import {RefreshPageButton} from 'BackendRenderedComponents/button';
import {Button} from 'Components/button/base';
import {Icon} from 'Components/icon';
import Text from 'Config/Text';
import {focusFirstFocusableElement} from 'Root/core/utils/focusUtils';
import {ESCAPE_KEY_CODE} from 'Root/core/utils/keyCodeConstants';

import {HelpCentreErrorButton} from './HelpCentreErrorButton';
import {SystemModalConfig} from './types';

import './systemModal.scss';

type SystemModalProps = SystemModalConfig & {
    onClose: () => void;
};

export const SystemModal = (props: SystemModalProps) => {
    const {
        exception,
        icon,
        message,
        sentryId,
        isNotFoundException,
        title,
        onClose,
        onConfirm: unsafeOnConfirm,
        confirmationButtonText,
    } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);

    const closeModalOnEscKeyPress = (event: KeyboardEvent) => {
        if (event.keyCode === ESCAPE_KEY_CODE) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', closeModalOnEscKeyPress, false);

        if (containerRef.current) {
            focusFirstFocusableElement(containerRef.current);
        }

        return () => {
            document.removeEventListener(
                'keydown',
                closeModalOnEscKeyPress,
                false,
            );
        };
    }, []);

    const renderFooterContent = () => {
        if (typeof sentryId === 'string') {
            return (
                <>
                    <Button text="Close" onClick={onClose} />
                    <RefreshPageButton text="Refresh page" color="green" />
                </>
            );
        }
        if (typeof unsafeOnConfirm === 'function') {
            const onConfirm = () => {
                onClose();
                unsafeOnConfirm();
            };

            return (
                <>
                    <Button text="Cancel" onClick={onClose} />
                    <Button
                        text={confirmationButtonText ?? 'Confirm'}
                        onClick={onConfirm}
                    />
                </>
            );
        }
        return <Button text="OK" onClick={onClose} />;
    };

    const Title = () => {
        if (sentryId || title === Text.ERROR.GENERIC_TITLE) {
            if (isNotFoundException) {
                return <h3>Sorry, we can't find this page</h3>;
            }
            return <h3>Sorry, something went wrong</h3>;
        } else if (title) {
            return <h3>{title}</h3>;
        }

        return <></>;
    };

    const Message = () => {
        const supportOptions = (
            <>
                <ul>
                    <li>
                        <b>Arbor supported:</b>{' '}
                        <HelpCentreErrorButton sentryId={sentryId} /> to send an
                        email to our Support Team using our Contact Us form (the
                        error ID will be added automatically).
                    </li>
                    <li>
                        <b>Partner supported:</b> Please get in touch with your
                        Support Partner, and let them know the error ID below.
                    </li>
                </ul>
                <p>ID:{sentryId}</p>
            </>
        );
        if (sentryId || message === Text.ERROR.GENERIC_MESSAGE) {
            if (isNotFoundException) {
                return (
                    <div>
                        <b>Please try finding the page using the navigation.</b>{' '}
                        If you can't find what you're looking for, there are two
                        options depending on your support plan:
                        {supportOptions}
                    </div>
                );
            }
            return (
                <div>
                    <b>Try refreshing the page and starting again.</b> If the
                    problem continues, there are two options depending on your
                    support plan:
                    {supportOptions}
                </div>
            );
        } else if (message) {
            return <div dangerouslySetInnerHTML={{__html: message}} />;
        }

        return <></>;
    };

    return (
        <div
            className="system-modal-container"
            role="alertdialog"
            aria-modal="true"
            aria-label={title}
            ref={containerRef}
        >
            <div className="system-modal-left" role="presentation">
                {icon && <Icon iconName={icon} />}
            </div>
            <div className="system-modal-right" role="presentation">
                <section
                    className="system-modal-body"
                    aria-label="System modal main content"
                >
                    <Title />
                    <Message />

                    {exception && (
                        <div>
                            <b>Exception: </b>
                            {exception}
                        </div>
                    )}
                </section>
                <section
                    className="system-modal-footer"
                    aria-label="System modal footer"
                >
                    {renderFooterContent()}
                </section>
            </div>
        </div>
    );
};
