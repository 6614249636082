import classNames from 'classnames';
import {useRef} from 'react';
import * as React from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';

import './tableHeaderButton.scss';

const KeyboardFocusableButtonWithTooltip = tooltipWrapper(
    KeyboardFocusableButton,
);

export type TableHeaderButtonProps =
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        focusOnClick?: boolean;
        tooltip?: string;
        icon?: string;
        flipIconX?: boolean;
    };

export const TableHeaderButton = ({
    children,
    onClick,
    focusOnClick,
    tooltip,
    icon,
    flipIconX,
    className,
    ...others
}: TableHeaderButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    return (
        <KeyboardFocusableButtonWithTooltip
            buttonWrapperClassName="table-header-button__wrapper"
            className={classNames('table-header-button', className)}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (typeof onClick === 'function') {
                    onClick(e);
                }
                if (
                    focusOnClick &&
                    typeof buttonRef?.current?.focus === 'function'
                ) {
                    // Sometimes we want to focus the button to make sure the tooltip repositions
                    // itself and we have a visible indicator of the button. I.e. on FullScreenButton
                    buttonRef.current.focus();
                }
            }}
            borderRadius="4px"
            tooltip={tooltip}
            setRef={buttonRef}
            {...others}
        >
            {icon && (
                <Icon
                    iconName={icon}
                    className="table-header-button__icon"
                    flipX={flipIconX}
                />
            )}
            {children}
        </KeyboardFocusableButtonWithTooltip>
    );
};
