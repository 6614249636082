import PropTypes from 'prop-types';
import {Component} from 'react';

import './checkout.scss';

// Only used when buying sms credits
class CheckoutProperty extends Component {
    render() {
        return (
            <div className="arbor-checkout">
                <div>{this.props.data.text}</div>
                <div>{this.props.data.price}</div>
            </div>
        );
    }
}

CheckoutProperty.propTypes = {
    data: PropTypes.object,
};

export default CheckoutProperty;
