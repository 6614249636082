// GSM-7 characters, each counted as 1 character in an SMS.
// https://www.wikiwand.com/en/GSM_03.38
export const GSM_7_BIT_CHARS = [
    '@',
    '£',
    '$',
    '¥',
    'è',
    'é',
    'ù',
    'ì',
    'ò',
    'Ç',
    '\n',
    'Ø',
    'ø',
    '\r',
    'Å',
    'å',
    'Δ',
    '_',
    'Φ',
    'Γ',
    'Λ',
    'Ω',
    'Π',
    'Ψ',
    'Σ',
    'Θ',
    'Ξ',
    'Æ',
    'æ',
    'ß',
    'É',
    ' ',
    '!',
    '"',
    '#',
    '¤',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '¡',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Ä',
    'Ö',
    'Ñ',
    'Ü',
    '§',
    '¿',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'ä',
    'ö',
    'ñ',
    'ü',
    'à',
    // non-breaking space
    String.fromCharCode(160),
];

// GSM-7 charset extensions: counted as 2 characters in an SMS
export const CHARSET_7_BIT_EXT = [
    '\f',
    '^',
    '{',
    '}',
    '\\',
    '[',
    '~',
    ']',
    '|',
    '€',
];
