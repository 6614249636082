const fieldPadding = 4;
const lineHeight = 28;

const green = '#68aa22';

const wrapperStyle = {
    main: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '20px',
    },
    successIcon: {
        color: green,
        fontSize: '44px',
        lineHeight: '44px',
    },
    text: {
        color: '#ADA8A6',
        fontSize: '18px',
        fontWeight: 'bold',
    },
} as const;

const style = {
    fieldBlock: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
    field: {
        border: '1px solid #dfdcdb',
        borderRadius: '3px',
        flex: 1.4,
        width: '100%',
        minWidth: '145px',
        padding: fieldPadding + 'px',
    },
    label: {
        lineHeight: lineHeight + 'px',
        fontWeight: 'bold',
        flex: 1,
        width: '100%',
        minWidth: '100px',
        textAlign: 'right',
        paddingRight: '20px',
    },
    error: {
        color: '#cb0d0d',
        textAlign: 'right',
    },
    inputField: {
        display: 'inline-block',
        backgroundColor: 'transparent',
        padding: '0px',
        border: '0px',
        height: lineHeight - 2 * fieldPadding + 'px',
        width: '100%',
        color: '#3c3735',
        font: "300 14px/22px 'PT Sans', 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif",
    },
    buttonContainer: {
        marginTop: '20px',
    },
    submitButton: {
        float: 'right',
        // marginRight: '-19px'
    },
    cancelButton: {
        float: 'right',
        marginRight: '10px',
    },
    globalError: {
        display: 'flex',
        backgroundColor: '#fde2e2',
        borderColor: '#f5c6cb',
        marginBottom: '5px',
    },
    globalErrorBlock: {
        display: 'inline-block',
        width: '329px',
    },
    globalErrorIcon: {
        color: '#9d1010',
        position: 'relative',
        margin: '10px',
    },
    globalErroHeading: {
        fontWeight: 'bold',
        margin: '10px 0 5px 0',
    },
    globalErrorText: {
        maxHeight: '60px',
        overflow: 'auto',
        fontSize: '13px',
        margin: '0px 0 10px 0',
    },
    success: {
        fontWeight: 'bold',
    },
    successButton: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        margin: '10px',
    },
} as const;

const stripeStyle = {
    base: {
        display: 'inline-block',
        backgroundColor: 'white',
        height: '28px',
        color: '#3c3735',
        fontSize: '14px',
        fontFamily: '"PT Sans", Helvetica, sans-serif',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    invalid: {
        color: '#cb0d0d',
        iconColor: '#cb0d0d',
    },
} as const;

const amexErrorStyle = {
    invalid: stripeStyle.invalid,
    base: {
        color: '#cb0d0d',
    },
} as const;

export {style, stripeStyle, amexErrorStyle, wrapperStyle};
