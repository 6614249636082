import {AxiosResponse} from 'axios';

import {ResponseData} from './InterceptResponse';

export const detectPageRedirect = ({
    responseURL,
    jsonResponse,
    response,
}: {
    responseURL: unknown;
    jsonResponse: string | ResponseData | undefined;
    response: AxiosResponse<unknown>;
}): string | null => {
    if (!responseURL || typeof responseURL !== 'string') {
        return null;
    }
    if (!jsonResponse || typeof jsonResponse === 'string') {
        return null;
    }
    if (jsonResponse.type !== 'page') {
        // Only need to check for redirects on pages, as these are the only ones where we change the url
        // Trying to detect redirects in other cases is potentially risky, as the response data may be in an unexpected shape
        return null;
    }

    if (response?.config?.params) {
        // No need to do the redirect check when the request had params, as this doesn't
        // happen when fetching pages, or if it did the redirect won't work without the parameters.
        return null;
    }

    try {
        // Try to detect redirects, as we can't directly see
        // 302 status in the response
        // Not sure if this ever throws, but added try catch and logging just in case

        const parsedResponseUrl = responseURL.startsWith(window.location.origin)
            ? responseURL
                  .replace(window.location.origin, '')
                  .replace(/^(\/\?)/, '')
            : responseURL;

        const requestedUrl = response?.config?.url;
        if (requestedUrl !== parsedResponseUrl) {
            return parsedResponseUrl;
        }
    } catch (e) {
        console.error('Error parsing redirect responseUrl', e);
        Raven.captureException(e, {
            extra: {
                message: {
                    error: e,
                    responseURL,
                },
            },
        });
    }
    return null;
};
