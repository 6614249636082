import {format, parse} from 'date-fns';

import {httpGet} from 'Interfaces/httpClient';
import {GenericResponse} from 'Types/response';
interface ICalendarWidgetResponseItem {
    fields: {
        // eslint-disable-next-line camelcase
        color_index: {
            value: string;
        };
        // eslint-disable-next-line camelcase
        start_datetime: {
            value: string;
        };
        // eslint-disable-next-line camelcase
        end_datetime: {
            value: string;
        };
        title: {
            value: string;
        };
        location: {
            value: string | null;
        };
        url: {
            value: string;
        };
        tooltipUrl?: {
            value: string;
        };
    };
}

type ICalendarWidgetResponse = GenericResponse<ICalendarWidgetResponseItem>;
export const transformCalendarWidgetItems = (
    item: ICalendarWidgetResponseItem,
) => {
    //remove date to just display start time and end time
    const startTimeToFormat = parse(
        item.fields.start_datetime.value,
        'yyyy-MM-dd HH:mm:ss',
        new Date(),
    );
    const startTime = format(startTimeToFormat, 'HH:mm');
    const eventDate = format(startTimeToFormat, 'EEEE d LLLL');
    const endTimeToFormat = parse(
        item.fields.end_datetime.value,
        'yyyy-MM-dd HH:mm:ss',
        new Date(),
    );
    const endTime = format(endTimeToFormat, 'HH:mm');
    return {
        colorIndex: item.fields.color_index.value,
        startTime: startTime,
        endTime: endTime,
        title: item.fields.title.value,
        location: item.fields.location.value,
        url: item.fields.url.value,
        tooltipUrl: item.fields.tooltipUrl?.value,
        eventDate: eventDate,
    };
};

export const getCalendarDataService = async (urlToFetch: string) => {
    const response: ICalendarWidgetResponse = await httpGet(urlToFetch);
    const transformedItems = response.items.map((item) =>
        transformCalendarWidgetItems(item),
    );
    return transformedItems;
};
