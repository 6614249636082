import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Button} from 'Components/button/base';
import {raiseError} from 'Interfaces/error/Error';
import {httpPost} from 'Interfaces/httpClient';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import {showMsg, showOkButton} from 'Interfaces/window/Window';
import {removeWindow} from 'Interfaces/WindowManager';
import Loader from 'Services/Loader';
import url, {appendFormatJson} from 'Services/url/Url';
import {selectSelectedIds} from 'State/selectors/Basket';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';

const WrappedButton = buttonWrapper(Button);

// Only used in buying SMS credits
class CheckoutGoCardlessButton extends Component {
    successHandler = () => {
        showMsg({
            title: _t('Purchase complete!'),
            message: _t(
                'Your reports are now available in the My Reports section of your portal. As a reminder, your schools will need to sign up or log in to their own portal to access their reports themselves at: <a href="' +
                    url.ARBOR.LOGIN +
                    '">' +
                    url.ARBOR.LOGIN +
                    '</a>.',
            ),
            width: 500,
            closable: false,
            buttons: showOkButton(),
            buttonText: {
                ok: _t('Go to My Reports page'),
            },
            fn: function () {
                Loader.loadPage(url.GROUP.MY_REPORTS);
            },
        });
    };

    callApi = (jsonData) =>
        httpPost(appendFormatJson(this.props.pageUrl), jsonData)
            .then((response) => {
                unmaskMain();
                if (response.action_params.preAuthorizationUrl) {
                    window.location.replace(
                        response.action_params.preAuthorizationUrl,
                    );
                }

                this.successHandler();
            })
            .catch((response) => {
                unmaskMain();
                raiseError(
                    'Error',
                    _t(
                        'There was a problem processing your payment. Your card has not been charged. <br /> The message from our payment provider was: ',
                    ) + response.message,
                );
                Raven.captureException(
                    new Error('Checkout Go Cardless Error'),
                    {
                        extra: {
                            message: {
                                response,
                                requestedUrl: appendFormatJson(
                                    this.props.pageUrl,
                                ),
                            },
                        },
                    },
                );
            });

    handleClick = () => {
        if (this.props.pageUrl) {
            var jsonData = {};

            if (this.props.rendererParams?.role !== 'group-purchase-window') {
                jsonData.fields = {
                    selectedIds: {
                        value: this.props.selectedIds,
                    },
                };

                this.successHandler = function () {
                    Loader.loadCurrentPage();
                };
            }

            if (this.props.rendererParams?.type === 'window') {
                removeWindow();
            }

            maskMain(_t('Loading...'));

            this.callApi(jsonData);
        } else {
            throw new Error('No URL provided!');
        }
    };

    render() {
        const {text, ...rest} = this.props;
        return (
            <WrappedButton
                {...rest}
                text={text}
                color="green"
                onClick={this.handleClick}
            />
        );
    }
}

CheckoutGoCardlessButton.propTypes = {
    pageUrl: PropTypes.string,
    text: PropTypes.string,
    extNodeEl: PropTypes.object,
    selectedIds: PropTypes.selectedIds,
    rendererParams: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
    };
};

export default connect(mapStateToProps, undefined, undefined, {
    forwardRef: true,
})(CheckoutGoCardlessButton);
