import {useEffect, useRef, useState} from 'react';

import {httpGet} from 'Interfaces/httpClient';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';

import {REPORTING_DASHBOARD_SESSION_STORAGE_KEY} from './constants';
import {ReportData} from './ReportingDashboard';
import {useReportFilterGainsightLogging} from './useReportFilterGainsightLogging';
import {isCustomReportHelper} from './utils';

const getUniqueCategories = (reports: ReportData[]) => {
    const allCategories: string[] = [];
    reports.forEach((report) =>
        allCategories.push(...(report.categories || [])),
    );
    allCategories.sort();
    return ['All', ...new Set(allCategories)].filter(
        (category) => category !== null,
    );
};

const sanitiseData = (dirtyResponse: ReportData[]) => {
    return dirtyResponse.filter((thisReport, index) => {
        return (
            dirtyResponse.findIndex(
                (thatReport) =>
                    JSON.stringify(thisReport) === JSON.stringify(thatReport),
            ) === index
        );
    });
};

type SavedReportFilters = {
    searchQuery?: string;
    selectedCategory?: string;
    showArborReports?: boolean;
    showOthersCustomReports?: boolean;
    showMyCustomReports?: boolean;
};

export const useReportsData = (fetchUrl: string) => {
    const savedFilterStateRef = useRef<SavedReportFilters>(
        JSON.parse(
            sessionStorage.getItem(REPORTING_DASHBOARD_SESSION_STORAGE_KEY) ||
                '{}',
        ),
    );

    const [reportsList, setReportsList] = useState<ReportData[]>([]);
    const [filteredReports, setFilteredReports] = useState<ReportData[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        () => savedFilterStateRef.current.searchQuery || '',
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        () => savedFilterStateRef.current.selectedCategory || 'All',
    );
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [showArborReports, setShowArborReports] = useState<boolean>(
        savedFilterStateRef.current.showArborReports ?? true,
    );
    const [showOthersCustomReports, setShowOthersCustomReports] =
        useState<boolean>(
            savedFilterStateRef.current.showOthersCustomReports ?? true,
        );
    const [showMyCustomReports, setShowMyCustomReports] = useState<boolean>(
        savedFilterStateRef.current.showMyCustomReports ?? true,
    );

    useComponentDidMount(() => {
        setIsLoading(true);
        httpGet(fetchUrl)
            .then((dirtyResponse) => {
                const response = sanitiseData(dirtyResponse);
                setReportsList(response);
                setFilteredReports(response);
                setCategories(getUniqueCategories(response));
            })
            .catch((response) => {
                if (!response || response.status !== 401) {
                    reportToSisSentry(new Error('Error fetching report list'), {
                        response,
                    });
                }
                console.error(response);
                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    useEffect(() => {
        const safeSearchQuery = searchQuery.trim().toLowerCase();
        let filteredList = reportsList;
        // Find out what the origin of the report is, then ask is that one of the allowed origins
        filteredList = filteredList.filter((report) => {
            if (isCustomReportHelper(report)) {
                if (report.isCreatedByCurrentUser) {
                    return showMyCustomReports;
                }
                return showOthersCustomReports;
            }
            return showArborReports;
        });
        if (selectedCategory !== 'All') {
            filteredList = filteredList.filter((report) => {
                return report.categories?.includes(selectedCategory);
            });
        }
        if (safeSearchQuery) {
            filteredList = filteredList.filter(
                (report) =>
                    report.name.toLowerCase().includes(safeSearchQuery) ||
                    report.description?.toLowerCase().includes(safeSearchQuery),
            );
        }

        setFilteredReports(filteredList);
    }, [
        selectedCategory,
        searchQuery,
        reportsList,
        showArborReports,
        showMyCustomReports,
        showOthersCustomReports,
    ]);

    useEffect(() => {
        savedFilterStateRef.current = {
            searchQuery,
            selectedCategory,
            showArborReports,
            showOthersCustomReports: showOthersCustomReports,
            showMyCustomReports: showMyCustomReports,
        };
        sessionStorage.setItem(
            REPORTING_DASHBOARD_SESSION_STORAGE_KEY,
            JSON.stringify(savedFilterStateRef.current),
        );
    }, [
        selectedCategory,
        searchQuery,
        showArborReports,
        showOthersCustomReports,
        showMyCustomReports,
    ]);

    useReportFilterGainsightLogging({
        showArborReports,
        showMyCustomReports,
        showOthersCustomReports,
        selectedCategory,
        searchQuery,
    });

    return {
        reportsList,
        filteredReports,
        categories,
        setSearchQuery,
        selectedCategory,
        setSelectedCategory,
        isLoading,
        hasError,
        searchQuery,
        showArborReports,
        showOthersCustomReports,
        showMyCustomReports,
        setShowArborReports,
        setShowOthersCustomReports,
        setShowMyCustomReports,
    };
};
