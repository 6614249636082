import {SLIDEOVER} from 'Config/Events';
import {SlideoverData} from 'Renderer/slideover/types';
import pubsub from 'Services/pubsub/Pubsub';

import exportInterface from './Base';
import {addRandomIdIfNecessary} from './WindowManager';

let latestSlideoverUrl = '';

export const addSlideover = (slideover: SlideoverData) => {
    pubsub.publish(SLIDEOVER.ADD, addRandomIdIfNecessary(slideover));
};

export const removeSlideover = () => {
    pubsub.publish(SLIDEOVER.REMOVE);
};

export const removeAllSlideovers = () => {
    pubsub.publish(SLIDEOVER.REMOVE_ALL);
};

export const removeAllSlideoversWithoutAnimation = () => {
    pubsub.publish(SLIDEOVER.REMOVE_ALL_WITHOUT_ANIMATION);
};

export const getLatestSlideover = () => {
    return latestSlideoverUrl;
};

export const setLatestSlideover = (url: string) => {
    latestSlideoverUrl = url;
};

export const registerSlideoverInterface = () => {
    exportInterface('Slideover', {
        removeSlideover,
        removeAllSlideovers,
    });
};
