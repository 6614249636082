export const checkEditorCanBeCleared = (editorContent: string) => {
    const contentTags = [
        '<table',
        '<img',
        '<style',
        '<svg',
        '<a',
        '<b',
        '<i',
        '<ul',
        '<ol',
        '<li',
        '&nbsp;',
        '<br',
    ];

    if (
        editorContent !== '' &&
        !contentTags.some((tag) => editorContent.includes(tag))
    ) {
        return true;
    }
    return false;
};
