import {IButtonProps} from 'Components/button/base';
import {FormAction, FormActionButton} from 'Components/button/formActionButton';
type SaveChangesButtonProps = IButtonProps & {
    role: string;
    currentAction: FormAction;
};
export function SaveChangesButton(props: SaveChangesButtonProps) {
    const {currentAction, role, ...rest} = props;

    return (
        <FormActionButton
            currentAction={currentAction}
            role={role}
            className="arbor-save-changes-button"
            {...rest}
        />
    );
}

SaveChangesButton.defaultProps = {
    color: 'green',
    text: 'Save Changes',
};
