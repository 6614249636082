import {TransformedNavigationItem} from '../types';

export const removeHiddenItemsFromTree = (
    treeData: TransformedNavigationItem[],
) => {
    const transformedData: TransformedNavigationItem[] = treeData
        .filter((item) => !item.hidden)
        .map((item) => {
            const {items, subTree} = item;
            return {
                ...item,
                ...(items
                    ? {
                          items: removeHiddenItemsFromTree(items),
                      }
                    : {}),
                ...(subTree
                    ? {
                          subTree: {
                              ...subTree,
                              items: removeHiddenItemsFromTree(subTree.items),
                          },
                      }
                    : {}),
            };
        });
    return transformedData;
};
