import classNames from 'classnames';
import * as React from 'react';

import {FormFieldLabel, IFormFieldLabelProps} from './FormFieldLabel';

import './formFieldWrapper.scss';

type FormFieldWrapperProps = IFormFieldLabelProps & {
    errors?: string[];
    isLoading?: boolean;
    setRef?: React.RefObject<HTMLDivElement>;
    className?: string;
};
export const FormFieldWrapper = (props: FormFieldWrapperProps) => {
    const {className = ''} = props;
    return (
        <div
            role="presentation"
            ref={props.setRef}
            className={classNames('form-field-wrapper', className, {
                'form-field-wrapper--inline-label': props.inlineLabel ?? true,
            })}
        >
            {props.label && <FormFieldLabel {...props} />}
            <div
                role="presentation"
                className={classNames('form-field-contents', {
                    'form-field-contents--loading': props.isLoading,
                })}
            >
                {props.children}
                {props.errors && props.errors.length > 0 && (
                    <ul role="presentation" className="form-field__error-list">
                        {props.errors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
