let instance = null;

class External {
    constructor() {
        if (!instance) {
            instance = this;
        }

        this.config = {};

        this.load();

        return instance;
    }

    load() {
        this.config = window.externalConfig;
    }

    getConfig() {
        return this.config;
    }

    setConfig(prop, val) {
        this.config[prop] = val;
    }
}

export default new External();
