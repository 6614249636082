import classNames from 'classnames';
import * as React from 'react';

import './icon.scss';

interface IIconProps extends React.ButtonHTMLAttributes<HTMLSpanElement> {
    iconName: string;
    flipX?: boolean;
    setRef?: React.RefObject<HTMLSpanElement>;
}

export const Icon = ({
    className,
    iconName,
    flipX,
    setRef,
    ...others
}: IIconProps) => (
    <span
        className={classNames(
            'arbor-icon',
            'arbor-icon-' + iconName,
            {'arbor-icon--flip-x': flipX},
            className,
        )}
        ref={setRef}
        {...others}
    />
);
