import {useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';

import {httpGet} from 'Interfaces/httpClient';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {logEventToGainsight} from 'Root/core/services/gainsight';
import Url from 'Services/url/Url';

import {checkHelpCentreQuery} from './checkHelpCentreQuery';
import {SearchItem} from './useSearchQuery';

type UseHelpCentreQueryParams = {
    query: string;
    shouldSuppressSearch?: boolean;
};

const sleep = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

export const useHelpCentreSearch = (params: UseHelpCentreQueryParams) => {
    const {query: queryValue, shouldSuppressSearch} = params;

    const [articles, setArticles] = useState<SearchItem[]>([]);
    const [isQueryValid, setIsQueryValid] = useState(false);

    const request = (query: string) =>
        httpGet<unknown, {items?: SearchItem[]; success?: boolean}>(
            Url.GLOBAL_SEARCH.HELP_CENTRE_SEARCH,
            {query},
            // signal,
        );

    const {data, isError, error, isLoading, isPending} = useQuery({
        queryKey: [
            'helpcentre',
            queryValue,
            // a bit of a hack, adding in so that changes to shouldSuppressSearch
            // triggers a refresh of tanstack, so that loading states still get
            // updated properly if something other than the query changes (e.g.
            // whether the search dropdown is open/closed)
            shouldSuppressSearch,
        ],
        // eslint-disable-next-line consistent-return
        queryFn: async ({
            signal,
        }): Promise<{items?: SearchItem[]; success?: boolean}> => {
            await sleep(2000);
            if (!signal?.aborted) {
                return request(queryValue);
            }

            return new Promise((resolve) =>
                resolve({items: [], success: true}),
            );
        },
        enabled: checkHelpCentreQuery(queryValue) && !shouldSuppressSearch,
        // means that results are never considered stale, i.e. enables cacheing for
        // the duration of the session
        staleTime: Infinity,
    });

    useEffect(() => {
        if (isError) {
            reportToSisSentry(
                new Error('Search Bar Help Centre Request Error!'),
                {queryValue, error},
            );
            console.error(error);
        }
    }, [isError, error, queryValue]);

    useEffect(() => {
        if (shouldSuppressSearch) {
            setArticles([]);
        } else if (checkHelpCentreQuery(queryValue)) {
            setIsQueryValid(true);
        } else {
            setIsQueryValid(false);
        }
    }, [queryValue, shouldSuppressSearch]);

    useEffect(() => {
        // We want to update the articles if the search has become unsuppressed,
        // but the query hasn't changed (e.g. opening and closing the dropdown)
        const hasSearchBecomeUnsuppressed = !shouldSuppressSearch && data;

        // tanstack sets data to null before refetching, so to avoid clearing the loading
        // state early, we do nothing if tanstack hasn't resolved the request yet.
        // We also check both the loading and pending state, because they are subtly different
        // and updated at different points in the the request lifecycle
        if (!(isLoading || isPending) || hasSearchBecomeUnsuppressed) {
            if (!data || !data.items || !data.success) {
                console.error('Invalid Help Centre Search response', data);
                setArticles([]);
                return;
            }

            const firstFivePages = data.items.slice(0, 5);

            setArticles(firstFivePages);

            if (firstFivePages.length === 0) {
                logEventToGainsight('HelpCentreSearchNoResults', {
                    queryValue,
                });
            }
        }
    }, [data, isLoading, isPending, queryValue, shouldSuppressSearch]);

    return {
        isQueryValid,
        hasError: isError,
        articles,
        isLoading,
    };
};
