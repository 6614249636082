import {NavigationReducerState} from './reducers';
import {
    findRouteInTree,
    findEntryPointInTree,
    findIdInTree,
    addMatchingOptionalParamsToItems,
    getBreadCrumbsData,
    addDynamicNavToModuleNav,
    removeHiddenItemsFromTree,
    addFavouritesToTree,
    flattenNavigationTree,
} from './utils';

import type {TransformedNavigationItem} from 'State/navigation/types';

type ReduxState = {
    navigation: NavigationReducerState;
};

export const selectNavigationTreeData = (state: ReduxState) => {
    const {
        treeData,
        currentUrlParams,
        parentStaticTreeOptionalParams,
        isDebugUiEnabled,
        userFavouritePages,
    } = state.navigation;

    const filteredTree = isDebugUiEnabled
        ? treeData
        : removeHiddenItemsFromTree(treeData);

    const treeDataWithFavourites = addFavouritesToTree(
        filteredTree,
        userFavouritePages,
    );

    if (!currentUrlParams && !parentStaticTreeOptionalParams) {
        return treeDataWithFavourites;
    }
    return addMatchingOptionalParamsToItems(
        treeDataWithFavourites,
        currentUrlParams,
        parentStaticTreeOptionalParams,
    );
};

const selectDynamicTreeData = (state: ReduxState) => {
    const {dynamicTreeData, isDebugUiEnabled, userFavouritePages} =
        state.navigation;
    const dynamicTreeDataWithFavourites = addFavouritesToTree(
        dynamicTreeData,
        userFavouritePages,
    );
    return isDebugUiEnabled
        ? dynamicTreeDataWithFavourites
        : removeHiddenItemsFromTree(dynamicTreeDataWithFavourites);
};

export const selectFlattenedNavigationTree = (state: ReduxState) => {
    const staticTree = selectNavigationTreeData(state);
    const flattenedStaticTree = flattenNavigationTree(staticTree);
    return flattenedStaticTree;
};

export const selectIsNewNavigation = (state: ReduxState) =>
    state.navigation.isNewNavigation;
export const selectCurrentPageId = (state: ReduxState) =>
    state.navigation.currentPageId;
export const selectStaticParentId = (state: ReduxState) =>
    state.navigation.staticParentId;
export const selectExpandedIds = (state: ReduxState) =>
    state.navigation.expandedIds;
export const selectDynamicTreeTitle = (state: ReduxState) =>
    state.navigation.dynamicTreeTitle;
export const selectIsSubNavHiddenByUser = (state: ReduxState) =>
    state.navigation.isSubNavHiddenByUser;
export const selectIsDebugUiEnabled = (state: ReduxState) =>
    state.navigation.isDebugUiEnabled;
export const selectIsCurrentPageTheHomePage = (state: ReduxState) => {
    if (!state.navigation.currentRoute) {
        return false;
    }
    return (
        state.navigation.currentRoute.includes('/home-ui/index') ||
        state.navigation.currentRoute.includes(
            '/guardians/home-ui/dashboard',
        ) ||
        state.navigation.currentRoute.includes('/students/home-ui/dashboard')
    );
};

export const selectDynamicBreadCrumbData = (state: ReduxState) => {
    const {currentPageId, dynamicTreeTitle} = state.navigation;
    const dynamicTreeData = selectDynamicTreeData(state);
    if (!currentPageId || dynamicTreeData.length === 0) {
        return null;
    }

    return [
        {
            items: dynamicTreeData,
            text: dynamicTreeTitle,
            id: 'dynamic-breadcrumb-root',
        } as TransformedNavigationItem,
        ...getBreadCrumbsData(dynamicTreeData, currentPageId),
    ];
};

export const selectRootBreadCrumbData = (state: ReduxState) => {
    const {currentPageId, staticParentId} = state.navigation;
    const dynamicTreeData = selectDynamicTreeData(state);
    const treeData = selectNavigationTreeData(state);

    if (staticParentId) {
        return getBreadCrumbsData(treeData, staticParentId);
    }

    if (selectIsCurrentPageTheHomePage(state)) {
        return null;
    }

    if (!currentPageId) {
        return null;
    }
    const rootBreadCrumbData = getBreadCrumbsData(treeData, currentPageId);
    if (rootBreadCrumbData.length > 0) {
        return rootBreadCrumbData;
    }

    // The following edge case happens for pages in subtrees that have not
    // been converted to the new navigation system yet.
    // Can be removed once all navigation files have been converted
    const currentPageDynamicTree = findIdInTree(currentPageId, dynamicTreeData);
    if (!currentPageDynamicTree?.url) {
        return [];
    }
    const currentPageInStaticTree = findRouteInTree(
        currentPageDynamicTree.url,
        treeData,
    );
    if (!currentPageInStaticTree?.id) {
        return [];
    }
    return getBreadCrumbsData(treeData, currentPageInStaticTree.id);
};

export const selectAllBreadCrumbData = (state: ReduxState) => {
    const rootBreadCrumbDatax = selectRootBreadCrumbData(state);
    const dynamicBreadCrumbDatax = selectDynamicBreadCrumbData(state);

    return [...(rootBreadCrumbDatax ?? []), ...(dynamicBreadCrumbDatax ?? [])];
};

const selectModuleNavigationTreeData = (state: ReduxState) => {
    const {currentPageId, staticParentId} = state.navigation;
    if (selectIsCurrentPageTheHomePage(state)) {
        return null;
    }

    const treeData = selectNavigationTreeData(state);

    if (!currentPageId) {
        return null;
    }
    // If we are currently inside a dynamic tree, we will have staticParentId in the store.
    const foundRoute = findIdInTree(staticParentId ?? currentPageId, treeData);

    if (!foundRoute) {
        return null;
    }

    if (foundRoute.parentEntryPoint) {
        const foundEntryPoint = findEntryPointInTree(
            foundRoute.parentEntryPoint,
            treeData,
        );
        if (foundEntryPoint && foundEntryPoint.items) {
            return foundEntryPoint;
        }
    }

    if (foundRoute.id.includes('_')) {
        // Any node other than the root of navigation includes a _ seperated path as its id
        const parentId = foundRoute.id.replace(/_[^_]+$/, '');
        const parentRoute = findIdInTree(parentId, treeData);
        return parentRoute;
    }
    return null;
};

export const selectSubNavTreeData = (state: ReduxState) => {
    const {dynamicTreeTitle, staticParentId} = state.navigation;
    const dynamicTreeData = selectDynamicTreeData(state);

    const moduleNavigationTreeData = selectModuleNavigationTreeData(state);
    if (!moduleNavigationTreeData || !moduleNavigationTreeData.items) {
        return {
            text: dynamicTreeTitle,
            items: dynamicTreeData,
        };
    }
    const combinedNav: TransformedNavigationItem = {
        ...moduleNavigationTreeData,
        ...(staticParentId && dynamicTreeTitle
            ? {
                  items: addDynamicNavToModuleNav(
                      moduleNavigationTreeData.items,
                      dynamicTreeData,
                      staticParentId,
                      dynamicTreeTitle,
                  ),
              }
            : {}),
    };
    return combinedNav;
};

export const selectIsFavouritesEnabled = (state: ReduxState) =>
    state.navigation.isFavouritesEnabled;
export const selectUserFavouritePages = (state: ReduxState) =>
    state.navigation.userFavouritePages;
