import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {SideBar} from 'Components/sideBar';
import {SubNav} from 'Components/subNav';
import {selectScreenType} from 'State/screenType/selectors';
import {screenTypePropType, DESKTOP, TABLET} from 'Utils/screenType';

import './mainLayout.scss';

export function UnconnectedMainLayout(props) {
    const {children, screenType} = props;
    return (
        <div className="app-inner-container" role="presentation">
            {(screenType === DESKTOP || screenType === TABLET) && <SideBar />}
            {(screenType === DESKTOP || screenType === TABLET) && <SubNav />}
            <div className="page-container" role="presentation">
                {children}
            </div>
        </div>
    );
}
UnconnectedMainLayout.propTypes = {
    children: PropTypes.node,
    screenType: screenTypePropType,
};

const mapStateToProps = (state) => ({
    screenType: selectScreenType(state),
});

export const MainLayout = connect(mapStateToProps)(UnconnectedMainLayout);
