import {
    EXT_WINDOW_MANAGER_PLACEHOLDER_ID,
    REACT_WINDOW_MANAGER_PLACEHOLDER_ID,
    SLIDEOVER_PLACEHOLDER_ID,
    WINDOW_PLACEHOLDER_ID,
} from 'Constants/idStrings';
import {focusFirstFocusableElement} from 'Root/core/utils/focusUtils';

export const returnFocusToTopOfVisualStack = () => {
    const containerIdToChildClassMap = {
        [EXT_WINDOW_MANAGER_PLACEHOLDER_ID]: 'mis-window',
        [REACT_WINDOW_MANAGER_PLACEHOLDER_ID]:
            'window-manager__window-container',
        [WINDOW_PLACEHOLDER_ID]: 'focus-trapping-modal__container',
        [SLIDEOVER_PLACEHOLDER_ID]: 'slideover-container',
    };

    for (const id in containerIdToChildClassMap) {
        if (containerIdToChildClassMap.hasOwnProperty(id)) {
            const container = document.getElementById(id);
            const children = container?.getElementsByClassName(
                containerIdToChildClassMap[id],
            );
            if (children?.length) {
                const lastChild = Array.from(children).pop() as HTMLElement;

                focusFirstFocusableElement(lastChild);
                return;
            }
        }
    }

    // fallback on the app container
    focusFirstFocusableElement(document.querySelector('.page-container'));
};
