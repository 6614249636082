import {Button} from 'Components/button/base';
import {httpGet} from 'Interfaces/httpClient';
import {appendFormatJson} from 'Interfaces/Url';
import buttonWrapper from 'Wrappers/Button';

import {SnippetData} from '../types';

const WrappedButton = buttonWrapper(Button);

type DeleteSnippetButtonProps = {
    snippet?: SnippetData;
    onDelete: () => void;
};

export const DeleteSnippetButton = (props: DeleteSnippetButtonProps) => {
    const {snippet, onDelete} = props;

    const disabled = typeof snippet === 'undefined';

    let deleteUrl = '';
    if (snippet) {
        deleteUrl = `/snippet/delete/id/${snippet.id}`;
    }

    return (
        <WrappedButton
            text="Delete Snippet"
            color="red"
            disabled={disabled}
            onClick={async () => {
                await httpGet(appendFormatJson(deleteUrl));
                onDelete();
            }}
            confirmationText="Do you want to delete the selected snippet?"
            confirmationButtonText="Yes, delete"
            confirmationTitle="Delete snippet"
            confirmationButtonColor="red"
        />
    );
};
