import {createElement, ReactNode} from 'react';
import {Provider as ReduxProvider} from 'react-redux';

import Store from 'State/Store';

import {RendererData} from './Renderer';

/**
 * Method that takes the json response data, checks if there are stores to be created, and create Ext stores.
 * The store can be:
 * dataTreeStore -> Ext.data.TreeStore
 * dataArrayStore -> Ext.data.ArrayStore
 * default -> Mis.data.Store
 * Used for stores rendering.
 *
 * @see http://docs.sencha.com/extjs/6.2.0/classic/Ext.data.TreeStore.html
 * @see http://docs.sencha.com/extjs/6.2.0/classic/Ext.data.ArrayStore.html
 * @see https://github.com/arbor-education/arbor-fe-library/blob/develop/client/Mis/data/Store.js
 *
 */
export function renderStores(pageData: RendererData) {
    const stores = {};
    if (pageData.stores) {
        pageData.stores.forEach((value) => {
            switch (value.dataStoreType) {
                case 'dataTreeStore': {
                    // TODO [MIS-37653] I think this is never used
                    stores[value.storeId] = new Ext.data.TreeStore(value);
                    break;
                }
                case 'dataArrayStore': {
                    // TODO [MIS-37653] I think this is never used
                    stores[value.storeId] = new Ext.data.ArrayStore(value);
                    break;
                }
                default: {
                    stores[value.storeId] = new Mis.data.Store(value);
                    break;
                }
            }
        });
        return stores;
    }
    return null;
}

export function renderWithStore(Component: ReactNode) {
    return createElement(
        ReduxProvider,
        // explicitly setting children to null because while children is technically optional
        // it's types as ReactNode, which can't be undefined, but can be null
        {store: Store.getStore(), children: null},
        Component,
    );
}
