import classNames from 'classnames';
import {useState} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {SimpleText} from 'Components/simpleText';
import {loadPage} from 'Interfaces/Loader';

import {TreeDataChild} from '../types';

import './row.scss';

interface RowProps {
    rowData: TreeDataChild;
    className?: string;
}

const Row = (props: RowProps) => {
    const {rowData} = props;

    const [isExpanded, setIsExpanded] = useState(rowData.expanded);
    const rowId = `row-${rowData.fields.id.value}`;

    const isRowClickable = Boolean(rowData.fields.url?.value);

    const hasChildren =
        Array.isArray(rowData.children) && rowData.children.length > 0;

    return (
        <li
            id={rowId}
            role="treeitem"
            aria-expanded={isExpanded}
            className={classNames('row__container', {
                'row__container--clickable': isRowClickable,
            })}
            onClick={() => {
                if (rowData.fields.url) {
                    loadPage(rowData.fields.url.value);
                }
            }}
        >
            <div className="row__content">
                {rowData.expandable && hasChildren && (
                    <KeyboardFocusableButton
                        aria-expanded={isExpanded}
                        aria-controls={rowId}
                        aria-label={`${isExpanded ? 'collapse' : 'expand'} row`}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <Icon
                            className={classNames({
                                'row__expansion-button': rowData.expandable,
                            })}
                            iconName={isExpanded ? 'minus' : 'plus'}
                        />
                    </KeyboardFocusableButton>
                )}
                <SimpleText
                    id={`${rowId}-text`}
                    html={rowData.fields?.text.value}
                />
                {isRowClickable && (
                    <KeyboardFocusableLink
                        url={rowData.fields.url?.value}
                        aria-labelledby={`${rowId}-text`}
                        linkWrapperClassName="row__click-icon"
                    >
                        <Icon iconName="right" />
                    </KeyboardFocusableLink>
                )}
            </div>
            {rowData.children && isExpanded && (
                <ul className="row__children-container">
                    {rowData.children.map((child) => {
                        return (
                            <Row key={child.fields.id.value} rowData={child} />
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

export default Row;
