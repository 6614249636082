import classNames from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import {ControlledDropdownContainer} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {logEventToGainsight} from 'Services/gainsight';
import {selectNavigationTreeData} from 'State/navigation/selectors';
import {focusFirstFocusableElement, shiftFocusWithKeys} from 'Utils/focusUtils';
import {
    ARROW_DOWN_KEY_CODE,
    ARROW_LEFT_KEY_CODE,
    ARROW_RIGHT_KEY_CODE,
    ESCAPE_KEY_CODE,
} from 'Utils/keyCodeConstants';

import type {ReactNode} from 'react';
import type {TransformedNavigationItem} from 'State/navigation/types';

import './navigationDropdownMenu.scss';

type NavigationDropdownMenuItemProps = {
    navigationItem: TransformedNavigationItem;
    renderNestedNav: (
        nestedItems: TransformedNavigationItem[],
        dropdownFieldRef: HTMLDivElement | null,
        containerElement: HTMLDivElement | null,
    ) => ReactNode;
    parentElement: HTMLDivElement | null;
    currentHoveredElementId: string | null;
    setCurrentHoveredElementId: (elementId: string | null) => void;
    currentEntryPointTargetId: string | null;
    setCurrentEntryPointTargetId: (elementId: string | null) => void;
    hideMenu: () => void;
    isMenuVisible: boolean;
};
const NavigationDropdownMenuItem = ({
    navigationItem,
    renderNestedNav,
    currentHoveredElementId,
    setCurrentHoveredElementId,
    currentEntryPointTargetId,
    setCurrentEntryPointTargetId,
    parentElement,
    hideMenu,
    isMenuVisible,
}: NavigationDropdownMenuItemProps) => {
    const itemRef = useRef<HTMLLIElement>(null);
    const {entryPoint, entryPointId, id, items, text, url} = navigationItem;
    const isMenuItemVisible =
        (isMenuVisible &&
            (currentHoveredElementId?.includes(navigationItem.id) ||
                currentEntryPointTargetId?.includes(navigationItem.id))) ||
        false;
    return (
        <li
            className="nested-li"
            role="menuitem"
            ref={itemRef}
            onKeyDown={(e) => {
                if (e.keyCode === ARROW_LEFT_KEY_CODE) {
                    focusFirstFocusableElement(parentElement);
                    e.stopPropagation();
                }
            }}
        >
            {items ? (
                <ControlledDropdownContainer
                    dropdownWidth="170px"
                    preferPosition="right"
                    alignDropdown="end"
                    offset={0}
                    isDropdownVisible={isMenuItemVisible}
                    contentsWrapperClassName="navigation-dropdown-menu__dropdown-contents-wrapper"
                    renderDropdownField={({dropdownContentsRef}) =>
                        entryPoint ? (
                            <KeyboardFocusableLink
                                borderRadius="0"
                                insetFocusRing
                                disableDefaultLinkStyles
                                onClick={() => {
                                    logEventToGainsight(
                                        'NavigationItemVisited',
                                        {
                                            url: entryPoint,
                                            type: 'Top Nav Entry Point',
                                        },
                                    );
                                    hideMenu();
                                }}
                                url={entryPoint}
                                linkWrapperClassName="navigation-nested-menu-item__wrapper"
                                className="navigation-nested-menu-item__link navigation-nested-menu-item__link--entry-point"
                                onMouseEnter={() => {
                                    setCurrentHoveredElementId(id);
                                    if (entryPointId) {
                                        setCurrentEntryPointTargetId(
                                            entryPointId,
                                        );
                                    }
                                }}
                                onMouseLeave={() => {
                                    setCurrentEntryPointTargetId(null);
                                }}
                                onFocus={() => {
                                    setCurrentHoveredElementId(id);
                                    if (entryPointId) {
                                        setCurrentEntryPointTargetId(
                                            entryPointId,
                                        );
                                    }
                                }}
                                onBlur={() => {
                                    setCurrentEntryPointTargetId(null);
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === ARROW_RIGHT_KEY_CODE) {
                                        focusFirstFocusableElement(
                                            dropdownContentsRef,
                                        );
                                    }
                                }}
                            >
                                <span className="navigation-nested-menu-item__text">
                                    {text}
                                </span>
                                <Icon
                                    iconName="arrow-go-right"
                                    className="arrow-go-right"
                                />
                            </KeyboardFocusableLink>
                        ) : (
                            <KeyboardFocusableButton
                                borderRadius="0"
                                insetFocusRing
                                className="navigation-nested-menu-item__button"
                                buttonWrapperClassName="navigation-nested-menu-item__wrapper"
                                onMouseEnter={() => {
                                    setCurrentHoveredElementId(id);
                                }}
                                onFocus={() => {
                                    setCurrentHoveredElementId(id);
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === ARROW_RIGHT_KEY_CODE) {
                                        focusFirstFocusableElement(
                                            dropdownContentsRef,
                                        );
                                    }
                                }}
                            >
                                <span className="navigation-nested-menu-item__text">
                                    {text}
                                </span>
                                <Icon
                                    iconName="arrow-go-right"
                                    className="arrow-go-right"
                                />
                            </KeyboardFocusableButton>
                        )
                    }
                    renderDropdownContents={({
                        dropdownFieldRef,
                        dropdownContentsRef,
                    }) =>
                        renderNestedNav(
                            items,
                            dropdownFieldRef,
                            dropdownContentsRef,
                        )
                    }
                />
            ) : (
                <KeyboardFocusableLink
                    borderRadius="0"
                    insetFocusRing
                    disableDefaultLinkStyles
                    onClick={() => {
                        logEventToGainsight('NavigationItemVisited', {
                            url,
                            type: 'Top Nav Link',
                        });
                        hideMenu();
                    }}
                    url={url}
                    linkWrapperClassName="navigation-nested-menu-item__wrapper"
                    className={classNames('navigation-nested-menu-item__link', {
                        'navigation-nested-menu-item__link--is-current-entry-point-target':
                            id === currentEntryPointTargetId,
                    })}
                    onMouseEnter={() => {
                        setCurrentHoveredElementId(id);
                    }}
                    onFocus={() => {
                        setCurrentHoveredElementId(id);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === ARROW_LEFT_KEY_CODE) {
                            focusFirstFocusableElement(parentElement);
                        }
                    }}
                >
                    {text}
                </KeyboardFocusableLink>
            )}
        </li>
    );
};

type UnconnectedNavigationDropdownMenuProps = {
    navigation: ReturnType<typeof selectNavigationTreeData>;
};
export const UnconnectedNavigationDropdownMenu = ({
    navigation,
}: UnconnectedNavigationDropdownMenuProps) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [currentHoveredElementId, setCurrentHoveredElementId] = useState<
        string | null
    >(null);
    const [currentEntryPointTargetId, setCurrentEntryPointTargetId] = useState<
        string | null
    >(null);
    const menuListRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const clickListener = (e: MouseEvent) => {
            // Listen for clicks outside the dropdown and close the dropdown
            if (
                !(e.target as Element)?.closest(
                    '.menu-li, .navigation__nested-ul, .nested-li',
                )
            ) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener('click', clickListener);
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, []);

    const renderNestedNav = (
        nestedItems: TransformedNavigationItem[],
        parentElement: HTMLDivElement | null,
        containerElement: HTMLDivElement | null,
    ) => {
        return (
            <ul
                role="menu"
                className="navigation__nested-ul"
                onKeyDown={(e) => {
                    if (e.keyCode === ESCAPE_KEY_CODE) {
                        focusFirstFocusableElement(parentElement);
                        setIsMenuVisible(false);
                    }
                    shiftFocusWithKeys({
                        containerElement,
                        tabHandler: () => {
                            focusFirstFocusableElement(parentElement);
                        },
                        backFromFirstHandler: () => {
                            focusFirstFocusableElement(parentElement);
                        },
                    })(e);
                }}
            >
                {nestedItems?.map((childItem, index) =>
                    childItem.hidden ? null : (
                        <NavigationDropdownMenuItem
                            key={index}
                            navigationItem={childItem}
                            renderNestedNav={renderNestedNav}
                            parentElement={parentElement}
                            currentHoveredElementId={currentHoveredElementId}
                            setCurrentHoveredElementId={
                                setCurrentHoveredElementId
                            }
                            currentEntryPointTargetId={
                                currentEntryPointTargetId
                            }
                            setCurrentEntryPointTargetId={
                                setCurrentEntryPointTargetId
                            }
                            hideMenu={() => setIsMenuVisible(false)}
                            isMenuVisible={isMenuVisible}
                        />
                    ),
                )}
            </ul>
        );
    };

    return (
        <nav
            aria-label="Main navigation menu"
            className="header-menu-wrapper"
            data-testid="header-main-menu"
            onBlur={(e) => {
                if (
                    !(e.relatedTarget as Element)?.closest(
                        '.menu-li, .navigation__nested-ul, .nested-li',
                    )
                ) {
                    setIsMenuVisible(false);
                    setCurrentHoveredElementId(null);
                    setCurrentEntryPointTargetId(null);
                }
            }}
        >
            <ul className="menu-ul" ref={menuListRef} role="menubar">
                {navigation &&
                    navigation.length > 0 &&
                    navigation.map((item, index) => {
                        if (item.hidden) {
                            return null;
                        }
                        const isMenuItemVisible =
                            (isMenuVisible &&
                                currentHoveredElementId?.includes(item.id)) ??
                            false;
                        return (
                            <li key={index} className="menu-li">
                                <ControlledDropdownContainer
                                    isDropdownVisible={isMenuItemVisible}
                                    dropdownWidth="170px"
                                    alignDropdown="end"
                                    offset={12}
                                    contentsWrapperClassName="navigation-dropdown-menu__dropdown-contents-wrapper"
                                    renderDropdownField={({
                                        dropdownContentsRef,
                                    }) => (
                                        <KeyboardFocusableButton
                                            onClick={(e) => {
                                                if (
                                                    currentHoveredElementId !==
                                                    item.id
                                                ) {
                                                    // Needed for screen readers as they don't trigger the hover
                                                    e.currentTarget.focus();
                                                    setCurrentHoveredElementId(
                                                        item.id,
                                                    );
                                                    setIsMenuVisible(true);
                                                } else {
                                                    setIsMenuVisible(
                                                        (prevValue) =>
                                                            !prevValue,
                                                    );
                                                }
                                            }}
                                            onFocus={() => {
                                                setCurrentHoveredElementId(
                                                    item.id,
                                                );
                                            }}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.keyCode ===
                                                    ARROW_DOWN_KEY_CODE
                                                ) {
                                                    setIsMenuVisible(true);
                                                    setTimeout(() => {
                                                        focusFirstFocusableElement(
                                                            dropdownContentsRef,
                                                        );
                                                    }, 0);
                                                }
                                                if (
                                                    e.keyCode ===
                                                    ESCAPE_KEY_CODE
                                                ) {
                                                    setIsMenuVisible(false);
                                                }
                                                shiftFocusWithKeys({
                                                    forwardKeyCodes: [
                                                        ARROW_RIGHT_KEY_CODE,
                                                    ],
                                                    backwardKeyCodes: [
                                                        ARROW_LEFT_KEY_CODE,
                                                    ],
                                                    containerElement:
                                                        menuListRef.current,
                                                })(e);
                                            }}
                                            className="navigation-root-menu-item__button"
                                            buttonWrapperClassName={classNames(
                                                'navigation-root-menu-item__wrapper',
                                                {
                                                    'navigation-root-item--active':
                                                        isMenuItemVisible,
                                                },
                                            )}
                                            borderRadius="3px"
                                            onMouseEnter={() => {
                                                setCurrentHoveredElementId(
                                                    item.id,
                                                );
                                            }}
                                        >
                                            <span className="navigation-root-menu-item-text">
                                                {item.text}
                                            </span>
                                            <Icon
                                                iconName="arrow-go-down"
                                                className={classNames(
                                                    'arrow-go-down',
                                                )}
                                            />
                                        </KeyboardFocusableButton>
                                    )}
                                    renderDropdownContents={({
                                        dropdownFieldRef,
                                        dropdownContentsRef,
                                    }) =>
                                        item.items
                                            ? renderNestedNav(
                                                  item.items,
                                                  dropdownFieldRef,
                                                  dropdownContentsRef,
                                              )
                                            : null
                                    }
                                />
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
};

const mapStateToProps = (state) => ({
    navigation: selectNavigationTreeData(state),
});

export const NavigationDropdownMenu = connect(mapStateToProps)(
    UnconnectedNavigationDropdownMenu,
);
