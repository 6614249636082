import {addWindow, removeWindow} from 'Interfaces/WindowManager';

import {AutoFillModal} from './AutoFillModal';

type AutoFillModalParams = {
    contentRequestUrl?: string;
    insertCallback: (content: string) => void;
    refocusCallback: () => void;
    autoFillUrl: string;
    suggestTextOptions?: {
        [key: string]: {label: string; checked: boolean; optionValue: string};
    };
    showToolGuideLink?: {
        url: string;
        display: boolean;
    };
};
export const showAutoFillModal = (params: AutoFillModalParams) => {
    const {
        contentRequestUrl,
        insertCallback,
        refocusCallback,
        autoFillUrl,
        suggestTextOptions,
        showToolGuideLink,
    } = params;

    addWindow({
        content: (
            <AutoFillModal
                closeCallback={() => removeWindow()}
                insertCallback={insertCallback}
                autoFillUrl={autoFillUrl}
                suggestTextOptions={suggestTextOptions}
                showToolGuideLink={showToolGuideLink}
            />
        ),
        footerItems: null,
        title: 'Suggest Text',
        rendererParams: {
            contentRequestUrl,
            type: 'window',
            elementToRefocus: null,
            refocusCallback: refocusCallback,
        },
        sizing: 'medium',
    });
};
