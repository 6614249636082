import {SISMergeFieldConfig} from '../types';

export const transformSisMergeFieldConfig = (cfg: SISMergeFieldConfig) => {
    const {items} = cfg.props.menu;
    const result: {label: string; value: string}[] = [];
    items.forEach((item) => {
        const {
            text,
            metaData: {value},
        } = item;
        result.push({
            label: text,
            value,
        });
    });

    return result;
};
