export type FloatingElementOffAxisAlignment = 'start' | 'end' | 'center';
export type FloatingElementDirection = 'top' | 'bottom' | 'left' | 'right';
export interface IFloatingElementPosition {
    x?: number;
    y?: number;
    bottom?: number;
    arrowX?: number;
    arrowY?: number;
    position?: FloatingElementDirection;
    maxHeight?: number;
    maxWidth?: number;
    fixedElementWidth?: number;
}

// Returns offset for sticky element on axis, where it will be aligned with fixed element
const _alignElements = (
    positionA: number,
    lengthA: number,
    lengthB: number,
    containerSize: number,
    offAxisAlignment?: FloatingElementOffAxisAlignment,
) => {
    if (offAxisAlignment === 'end') {
        if (positionA + lengthB <= containerSize) {
            return positionA;
        }
    }
    if (offAxisAlignment === 'start') {
        if (positionA + lengthA - lengthB >= 0) {
            return positionA + lengthA - lengthB;
        }
    }

    const center = positionA + lengthA / 2;
    if (center + lengthB / 2 <= containerSize) {
        if (center - lengthB / 2 >= 0) {
            return center - lengthB / 2;
        }
        return 0;
    }
    return containerSize - lengthB;
};

interface IFloatingElementParams {
    offset?: number;
    allowHorizontal?: boolean;
    preferPosition?: FloatingElementDirection;
    offAxisAlignment?: FloatingElementOffAxisAlignment;
}

//Returns absolute position for element that needs to be sticked to another element ex. tooltip, dropdown.
export const getFloatingElementPosition = (
    fixedEl,
    stickyEl,
    params?: IFloatingElementParams,
) => {
    if (
        !(fixedEl instanceof HTMLElement) ||
        !(stickyEl instanceof HTMLElement)
    ) {
        console.error(
            'value passed to getFloatingElementPosition not a HTMLElement',
            fixedEl,
            stickyEl,
        );
        return null;
    }

    const {
        offset = 0,
        allowHorizontal = true,
        preferPosition = 'bottom',
    } = params || {};

    const windowHeight = window.innerHeight,
        windowWidth = window.innerWidth,
        stickyElDim = stickyEl.getBoundingClientRect(),
        fixedElDim = fixedEl.getBoundingClientRect();

    // Calculate the correct side to display the sticky element on
    let foundDirection: FloatingElementDirection | undefined;

    const positionCheckFunctions: Record<
        FloatingElementDirection,
        () => boolean
    > = {
        bottom: () =>
            windowHeight - fixedElDim.bottom > stickyElDim.height + offset,
        top: () => fixedElDim.top > stickyElDim.height + offset,
        right: () =>
            allowHorizontal &&
            windowWidth - fixedElDim.right > stickyElDim.width + offset,
        left: () =>
            allowHorizontal && fixedElDim.left > stickyElDim.width + offset,
    };
    switch (preferPosition) {
        case 'bottom':
            foundDirection = (['bottom', 'top', 'right', 'left'] as const).find(
                (item) => positionCheckFunctions[item](),
            );
            break;
        case 'top':
            foundDirection = (['top', 'bottom', 'right', 'left'] as const).find(
                (item) => positionCheckFunctions[item](),
            );
            break;
        case 'left':
            foundDirection = (['left', 'right', 'bottom', 'top'] as const).find(
                (item) => positionCheckFunctions[item](),
            );
            break;
        case 'right':
            foundDirection = (['right', 'left', 'bottom', 'top'] as const).find(
                (item) => positionCheckFunctions[item](),
            );
            break;
    }

    if (typeof foundDirection === 'undefined') {
        // There is not a complete fit on any side. This is most likely on mobile.
        // Set position to top or bottom depending on which has more space, and allow
        // the dropdown contents to scroll

        if (windowHeight - fixedElDim.bottom >= fixedElDim.top) {
            foundDirection = 'bottom';
        } else {
            foundDirection = 'top';
        }
    }

    const coordinates: IFloatingElementPosition = {};
    switch (foundDirection) {
        case 'top':
            coordinates.x = Math.max(
                0,
                _alignElements(
                    fixedElDim.left,
                    fixedElDim.width,
                    stickyElDim.width,
                    windowWidth,
                    params?.offAxisAlignment,
                ),
            );
            coordinates.y = Math.max(
                0,
                fixedElDim.top - stickyElDim.height - offset,
            );
            coordinates.arrowX =
                fixedElDim.left + fixedElDim.width / 2 - coordinates.x;
            coordinates.maxHeight = Math.min(
                windowHeight,
                fixedElDim.top - offset,
            );
            coordinates.maxWidth = windowWidth;
            // When in the top position we position the item from the bottom upwards instead
            // of the top downwards, so that we don't get any gaps if the floating element changes height.
            coordinates.bottom = Math.max(
                0,
                windowHeight - (fixedElDim.top - offset),
            );
            break;
        case 'bottom':
            coordinates.x = Math.max(
                0,
                _alignElements(
                    fixedElDim.left,
                    fixedElDim.width,
                    stickyElDim.width,
                    windowWidth,
                    params?.offAxisAlignment,
                ),
            );
            coordinates.y = Math.max(
                0,
                fixedElDim.top + fixedElDim.height + offset,
            );
            coordinates.arrowX =
                fixedElDim.left + fixedElDim.width / 2 - coordinates.x;
            coordinates.maxHeight = Math.min(
                windowHeight,
                windowHeight - (fixedElDim.bottom + offset),
            );
            coordinates.maxWidth = windowWidth;
            break;
        case 'right':
            coordinates.y = Math.max(
                0,
                _alignElements(
                    fixedElDim.top,
                    fixedElDim.height,
                    stickyElDim.height,
                    windowHeight,
                    params?.offAxisAlignment,
                ),
            );
            coordinates.x = Math.max(
                0,
                fixedElDim.left + fixedElDim.width + offset,
            );
            coordinates.arrowY =
                fixedElDim.top + fixedElDim.height / 2 - coordinates.y;
            coordinates.maxHeight = windowHeight;
            coordinates.maxWidth = Math.min(
                windowWidth,
                windowWidth - (fixedElDim.right + offset),
            );
            break;
        case 'left':
            coordinates.y = Math.max(
                0,
                _alignElements(
                    fixedElDim.top,
                    fixedElDim.height,
                    stickyElDim.height,
                    windowHeight,
                    params?.offAxisAlignment,
                ),
            );
            coordinates.x = Math.max(
                0,
                fixedElDim.left - stickyElDim.width - offset,
            );
            coordinates.arrowY =
                fixedElDim.top + fixedElDim.height / 2 - coordinates.y;
            coordinates.maxHeight = windowHeight;
            coordinates.maxWidth = Math.min(
                windowWidth,
                fixedElDim.left - offset,
            );
            break;
    }
    coordinates.position = foundDirection;
    coordinates.fixedElementWidth = fixedElDim.width;

    return coordinates;
};
