import Pusher from 'pusher-js';

import External from 'Config/External';
import Pubsub from 'Services/pubsub/Pubsub';

import {AnyFunction} from '../pubsub/types';

type WebSocketListener = AnyFunction;
type WebSocketSubscription = {
    subscriptionCount: number;
    listeners: Record<string, WebSocketListener>;
};
class WebSocket {
    constructor() {
        this.session = false;
        this.subscriptions = {};
        this.logLevel = null;

        this.init();
    }

    private session: false | Pusher.Pusher;
    private subscriptions: Record<string, WebSocketSubscription>;
    private logLevel: null | 'info' | 'debug';

    private init = () => {
        const {key, cluster, encrypted, log} =
            External.getConfig().pushNotificationSettings;

        if (log === 'debug' || log === 'info') {
            this.logLevel = log;
        }

        if (this.logLevel === 'debug') {
            Pusher.logToConsole = true;
        }

        this.log('Connecting to', key, cluster);

        if (key) {
            // Fail-safe mechanism for missing config
            const pusher = new Pusher(key, {
                cluster: cluster,
                encrypted: encrypted,
            });

            pusher.connection.bind(
                'connected',
                this._initConnectionBind.bind(this, pusher),
            );
        }
    };

    private _initConnectionBind = (pusher: Pusher.Pusher) => {
        this.log('Connected to Pusher service');

        this.session = pusher;

        /**
         * In case any components subscribed before the connection to Pusher service was established,
         * subscribe and attach event listeners now.
         */
        for (const channel in this.subscriptions) {
            if (this.subscriptions.hasOwnProperty(channel)) {
                if (channel && !pusher.channel(channel)) {
                    // Skip channels with no more subscriptions
                    if (this.subscriptions[channel].subscriptionCount) {
                        for (const event in this.subscriptions[channel]
                            .listeners) {
                            if (
                                this.subscriptions[
                                    channel
                                ].listeners.hasOwnProperty(event)
                            ) {
                                this.subscribeToPusher(channel, event);
                            }
                        }
                    }
                }
            }
        }
    };

    /**
     * This function is called by external components that need to subscribe to websockets
     */
    subscribe = (channel: string, eventName: string, listener: AnyFunction) => {
        this.log('Subscribing to', channel, eventName); //eslint-disable-line

        /**
         * Because connection to Pusher service might not be established yet,
         * components are listening on WebSocket.Proxy events instead of Pusher events.
         */
        const pubsubSubscriptionId = Pubsub.subscribe(
            'MessageReceived_' + channel + '_' + eventName,
            listener,
        );
        /**
         * Keep track of channel subscription count because multiple components can listen on same channels.
         */
        if (this.subscriptions[channel]) {
            this.subscriptions[channel].subscriptionCount++;
        } else {
            this.subscriptions[channel] = {
                subscriptionCount: 1,
                listeners: {},
            };
        }

        /**
         * Keep track of event listeners in case connection to Pusher service is not established yet.
         * We can not bind event listeners before that.
         */
        this.subscriptions[channel].listeners[eventName] = listener;
        this.subscribeToPusher(channel, eventName);

        return pubsubSubscriptionId;
    };

    private subscribeToPusher = (channel: string, eventName: string) => {
        if (this.session) {
            const pusher = this.session;
            let pusherChannel = pusher.channel(channel);

            if (!pusherChannel) {
                pusherChannel = pusher.subscribe(channel);
                pusherChannel.bind(
                    'pusher:subscription_succeeded',
                    this._successfulSubscriptionBind.bind(this, channel),
                );
            }

            pusherChannel.bind(
                eventName,
                this._messageReceivedBind.bind(this, channel, eventName),
            );
        }
    };

    private _successfulSubscriptionBind = (channel: string) => {
        this.log('Subscribed to', channel);
    };

    private _messageReceivedBind = (
        channel: string,
        eventName: string,
        message: string,
    ) => {
        this.log(channel, eventName, message);
        Pubsub.publish('MessageReceived_' + channel + '_' + eventName, message);
    };

    /**
     * This function is called by external components that need to unsubscribe to websockets
     */
    unsubscribe = (
        channel: string,
        eventName: string,
        pubsubSubscriptionId: string,
    ) => {
        this.subscriptions[channel].subscriptionCount--;

        /**
         * Only unsubscribe if there are no more components listening in this channel.
         */
        if (this.subscriptions[channel].subscriptionCount === 0) {
            this.unsubscribeFromPusher(channel);
        }

        // Remove event listener.
        Pubsub.unsubscribe(
            'MessageReceived_' + channel + '_' + eventName,
            pubsubSubscriptionId,
        );
    };

    private unsubscribeFromPusher = (channel: string) => {
        if (this.session) {
            // FailSafe check
            this.session.unsubscribe(channel);
            this.log('Unsubscribed from', channel);
        }
    };

    private log = (...args) => {
        if (this.logLevel === 'info' || this.logLevel === 'debug') {
            /*eslint no-console: 0*/
            console.log.apply(console, ['[PUSHER]', ...args]);
        }
    };
}

let webSocketInstance: WebSocket | null = null;
export const getWebSocketService = () => {
    if (!webSocketInstance) {
        webSocketInstance = new WebSocket();
    }
    return webSocketInstance;
};
