export const sanitizeLinks = (node: HTMLUnknownElement) => {
    if (node.hasAttribute('href')) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }

    if (node.hasAttribute('data-url')) {
        node.setAttribute(
            'onClick',
            `Arbor.Interfaces.Loader.loadPage('${node.getAttribute(
                'data-url',
            )}')`,
        );
        node.removeAttribute('data-url');
    }
};
