import {SISMergeFieldConfig, SisSnippetBtnConfig} from '../types';

export type GenerateToolbarParams = {
    enableAlignments?: boolean;
    enableForegroundColor?: boolean;
    enableBackgroundColor?: boolean;
    enableFont?: boolean;
    enableFontSize?: boolean;
    enableFormat?: boolean;
    enableImage?: boolean;
    enableLinks?: boolean;
    enableLists?: boolean;
    enableTables?: boolean;
    enablePageBreak?: boolean;
    mergeFieldBtnConfig?: SISMergeFieldConfig;
    snippetBtnConfig?: SisSnippetBtnConfig;
    autoFillUrl?: string;
    autoFillParams?: object;
    enablePrint?: boolean;
};

export const generateToolbar = (params: GenerateToolbarParams) => {
    const {
        autoFillUrl,
        enableAlignments,
        enableForegroundColor,
        enableBackgroundColor,
        enableFont,
        enableFontSize,
        enableFormat,
        enableImage,
        enableLinks,
        enableLists,
        enableTables,
        enablePageBreak,
        mergeFieldBtnConfig,
        snippetBtnConfig,
        enablePrint = true,
    } = params;

    const toolbarItems: string[] = ['undo redo'];

    if (autoFillUrl) {
        toolbarItems.push('autoFillButton');
    }

    if (enableFormat) {
        toolbarItems.push('bold italic underline');
    }

    if (enableAlignments) {
        toolbarItems.push('alignleft aligncenter alignright alignjustify');
    }

    if (enableLists) {
        toolbarItems.push('numlist bullist');
    }

    if (
        enableFont ||
        enableFontSize ||
        enableForegroundColor ||
        enableBackgroundColor
    ) {
        const block: string[] = [];
        if (enableFont) {
            block.push('fontfamily');
        }
        if (enableFontSize) {
            block.push('fontsize');
        }
        if (enableForegroundColor) {
            block.push('forecolor');
        }
        if (enableBackgroundColor) {
            block.push('backcolor');
        }

        toolbarItems.push(block.join(' '));
    }

    if (enableLinks) {
        toolbarItems.push('link');
    }

    if (enableImage) {
        toolbarItems.push('image');
    }

    if (enableTables) {
        toolbarItems.push('table');
    }

    if (snippetBtnConfig) {
        toolbarItems.push('snippetbutton');
    }

    if (mergeFieldBtnConfig) {
        toolbarItems.push('mergefieldbutton');
    }

    if (enablePageBreak) {
        toolbarItems.push('pagebreak');
    }

    if (enablePrint) {
        toolbarItems.push('print');
    }

    toolbarItems.push('fullscreen');

    return toolbarItems.join(' | ');
};
