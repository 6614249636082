import classNames from 'classnames';
import {
    KeyboardEventHandler,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import {createPortal} from 'react-dom';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {FullScreenButton} from 'Components/table/tableHeader/fullScreenButton';
import {WINDOW_PLACEHOLDER_ID} from 'Constants/idStrings';
import {
    focusFirstFocusableElement,
    focusTrappingTabKeyHandler,
} from 'Utils/focusUtils';
import {ESCAPE_KEY_CODE} from 'Utils/keyCodeConstants';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import './focusTrappingModal.scss';

type FocusTrappingModalContentProps = {
    onClose: () => void;
    children?: ReactNode;
    dataTestId?: string;
    className?: string;
    ariaLabel?: string;
    removeChildrenPadding?: boolean;
    title?: string;
    hideFullScreenButton?: boolean;
    fullScreen?: boolean;
    responsive?: boolean;
};

/**
 * This component takes care of the following things:
 *
 * - Position and overall design of the modal (e.g. padding and border-radius)
 * - Close button in top right corner
 * - Automatically focus the first element when opened
 * - Trap focus within the modal
 * - Render the modal using portal into window placeholder
 */
export const FocusTrappingModalContent = (
    props: FocusTrappingModalContentProps,
) => {
    const {
        onClose,
        children,
        dataTestId,
        className,
        ariaLabel,
        removeChildrenPadding = false,
        hideFullScreenButton,
        fullScreen,
        title,
        responsive = false,
    } = props;
    const [isFullScreen, setIsFullScreen] = useState(fullScreen ?? false);

    const modalRef = useRef<HTMLDivElement | null>(null);

    const keyDownHandler: KeyboardEventHandler<HTMLDivElement> = (event) => {
        if (event.keyCode === ESCAPE_KEY_CODE) {
            // if fullscreen esc will return to normal view
            // if normal view esc will close popup
            if (isFullScreen) {
                setIsFullScreen(false);
            } else {
                onClose();
            }
        } else {
            focusTrappingTabKeyHandler(modalRef.current, event);
        }
    };

    useComponentDidMount(() => {
        if (modalRef.current) {
            // wrapping in a timeout so that the contents of the modal can render first
            setTimeout(() => {
                setTimeout(() => {
                    focusFirstFocusableElement(modalRef.current);
                });
            });
        }
    });

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
            setTimeout(() => {
                focusFirstFocusableElement(modalRef.current);
            });
        });
    }, [isFullScreen]);

    // Note: The close button is placed in top right corner with position: absolute, and is
    // after the children in the dom so that it is the last focusable element in the modal
    return (
        <div
            role="presentation"
            className="focus-trapping-modal__background-overlay"
            tabIndex={0}
            onKeyDown={keyDownHandler}
            ref={modalRef}
        >
            <div
                role="dialog"
                className={classNames('focus-trapping-modal__container', {
                    'focus-trapping-modal__container--no-padding':
                        removeChildrenPadding,
                    'focus-trapping-modal--full-screen': isFullScreen,
                    'focus-trapping-modal--responsive':
                        !isFullScreen && responsive,
                    ...(className && {[className]: !isFullScreen}),
                })}
                data-testid={dataTestId}
                aria-label={ariaLabel}
            >
                {title && (
                    <h1 className="focus-trapping-modal__title">{title}</h1>
                )}
                {children}
                {!hideFullScreenButton && (
                    <div className="focus-trapping-modal--full-screen-button-wrapper">
                        <FullScreenButton
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            // TODO sort out this type error, find an appropriate key
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            key={isFullScreen} //force tooltip to remount to avoid weird positioning
                        />
                    </div>
                )}
                <KeyboardFocusableButton
                    buttonWrapperClassName="focus-trapping-modal__close-button"
                    onClick={onClose}
                    borderRadius="4px"
                    aria-label="Close popup"
                >
                    <Icon
                        iconName="cross"
                        className="focus-trapping-modal__close-icon"
                        aria-hidden
                    />
                </KeyboardFocusableButton>
            </div>
        </div>
    );
};

type FocusTrappingModalProps = FocusTrappingModalContentProps & {
    portalTargetNodeId?: string;
};
export const FocusTrappingModal = (props: FocusTrappingModalProps) => {
    const targetNodeRef = useRef<HTMLDivElement>();

    if (!targetNodeRef.current) {
        const targetNodeId = props.portalTargetNodeId ?? WINDOW_PLACEHOLDER_ID;
        // Try setting target node to placeholder div
        const windowPlaceholder = document.getElementById(
            targetNodeId,
        ) as HTMLDivElement;

        if (windowPlaceholder) {
            targetNodeRef.current = windowPlaceholder;
        } else {
            // Fallback in case placeholder div is not already created (e.g. in tests)
            targetNodeRef.current = document.createElement('div');
            targetNodeRef.current.setAttribute('id', targetNodeId);
            document.body.appendChild(targetNodeRef.current);
        }
    }

    return createPortal(
        <FocusTrappingModalContent {...props} />,
        targetNodeRef.current,
    );
};
