import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {logEventToGainsight} from 'Root/core/services/gainsight';

import {NewTabButton} from '../NewTabButton';
import {SearchItem} from '../utils/useSearchQuery';

import {Highlighter} from './Highlighter';
import {transformZendeskDescription} from './transformZendeskDescription';

type HelpCentreItemProps = {
    item: SearchItem;
    itemIndex: number;
    searchValue: string;
};

export const HelpCentreItem = (props: HelpCentreItemProps) => {
    const {item, itemIndex, searchValue} = props;

    const {description: rawDescription} = item;

    const description = transformZendeskDescription(rawDescription);

    return (
        <KeyboardFocusableLink
            url={item.clickUrl}
            insetFocusRing
            tabIndex={-1}
            key={itemIndex}
            linkWrapperClassName="search-dropdown__group-item__container"
            target="_blank"
            onClick={() =>
                logEventToGainsight('SearchItemVisited', {
                    type: 'Help Centre',
                    url: item.clickUrl,
                })
            }
        >
            <span className="search-dropdown__group-item__title">
                <Highlighter
                    text={item.title}
                    highlight={searchValue.split(' ')}
                />
            </span>
            <div
                className="search-dropdown__help-centre-item__description"
                dangerouslySetInnerHTML={{__html: description}}
            />
            {item.clickUrl && (
                <NewTabButton forceHoverStyles itemUrl={item.clickUrl} />
            )}
        </KeyboardFocusableLink>
    );
};
