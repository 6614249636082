import exportInterface from 'Interfaces/Base';
import {AnyFunction} from 'Services/pubsub/types';
import {getWebSocketService} from 'Services/websocket/WebSocket';

export function subscribe(
    channel: string,
    eventName: string,
    listener: AnyFunction,
) {
    return getWebSocketService().subscribe(channel, eventName, listener);
}

export function unsubscribe(
    channel: string,
    eventName: string,
    subscriptionId: string,
) {
    return getWebSocketService().unsubscribe(
        channel,
        eventName,
        subscriptionId,
    );
}

export const registerWebSocketInterface = () => {
    exportInterface('WebSocket', {subscribe, unsubscribe});
};
