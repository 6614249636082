import PropTypes from 'prop-types';

import {ALERT_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {Icon} from 'Components/icon';

import {BirthdayCake} from './BirthdayCake';
import {ThumbsDown} from './ThumbsDown';
import {ThumbTack} from './ThumbTack';

const generateIconType = (type) => {
    switch (type) {
        case ALERT_TYPE_ENUM.PINNED_NOTE:
            return 'thumb-tack';

        case ALERT_TYPE_ENUM.BIRTHDAY:
            return 'birthday-cake';

        case ALERT_TYPE_ENUM.DETENTION:
            return 'thumb-down';

        case ALERT_TYPE_ENUM.SAFEGUARDING_NOTE:
            return 'tag';

        case ALERT_TYPE_ENUM.CUSTOM_GROUP_JOINED:
        case ALERT_TYPE_ENUM.CUSTOM_GROUP_LEFT:
            return 'cover';

        default:
            return '';
    }
};

export function NotificationIcon({type, className}) {
    const iconType = generateIconType(type);
    if (iconType === '') {
        return null;
    }

    if (iconType === 'birthday-cake') {
        return <BirthdayCake width="12px" className={className} />;
    }

    if (iconType === 'thumb-down') {
        return <ThumbsDown width="12px" className={className} />;
    }

    if (iconType === 'thumb-tack') {
        return <ThumbTack width="12px" className={className} />;
    }

    return <Icon iconName={iconType} className={className} />;
}

NotificationIcon.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
};
