import classNames from 'classnames';
import {useCallback, useEffect, useRef} from 'react';
import {FaTrashAlt} from 'react-icons/fa';

import {LoadingWidget} from 'BackendRenderedComponents/chatPanel/LoadingWidget';
import helper from 'Utils/helper';

type StepTwoScreenProps = {
    generatedContent: string | null;
    outputContent: string[];
    setOutputContent: React.Dispatch<React.SetStateAction<string[]>>;
    hoveredIndex: number | null;
    setHoveredIndex: (index: number | null) => void;
    isLoading: boolean;
    error: string;
};

export const StepTwoScreen = ({
    generatedContent,
    outputContent,
    setOutputContent,
    hoveredIndex,
    setHoveredIndex,
    isLoading,
    error,
}: StepTwoScreenProps) => {
    const selectionRef = useRef<{
        node: Node | null;
        offset: number;
    }>({node: null, offset: 0});
    const debouncedHandleSentenceChange = useCallback(
        helper.debounce((index: number, newText: string) => {
            setOutputContent((prevContent) => {
                const updatedContent = [...prevContent];
                updatedContent[index] = newText;
                return updatedContent;
            });

            // Restore cursor position after state update using setTimeout
            setTimeout(() => {
                if (selectionRef.current.node) {
                    const range = document.createRange();
                    const selection = window.getSelection();

                    range.setStart(
                        selectionRef.current.node,
                        Math.min(
                            selectionRef.current.offset,
                            selectionRef.current.node.textContent?.length || 0,
                        ),
                    );
                    range.collapse(true);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                }
            }, 0);
        }, 500),
        [setOutputContent],
    );

    useEffect(() => {
        return () => {
            debouncedHandleSentenceChange.clear();
        };
    }, [debouncedHandleSentenceChange]);

    const saveCursorPosition = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            selectionRef.current = {
                node: range.startContainer,
                offset: range.startOffset,
            };
        }
    };

    return (
        <>
            <div
                className={classNames('auto-fill-modal__response-container', {
                    'auto-fill-modal__loading-style':
                        isLoading && !generatedContent && !error, // Add the class if isLoading is true
                })}
            >
                {generatedContent && !error && (
                    <div className="auto-fill-modal__output-box">
                        <p className="auto-fill-modal__output-paragraph">
                            {outputContent.map((sentence, index) => (
                                <span
                                    key={index}
                                    className="auto-fill-modal__hoverable-sentence"
                                    contentEditable
                                    suppressContentEditableWarning
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onInput={(e) => {
                                        saveCursorPosition(); // Save cursor position before the change
                                        const newText =
                                            e.currentTarget.textContent || '';
                                        debouncedHandleSentenceChange(
                                            index,
                                            newText,
                                        );
                                    }}
                                >
                                    <span
                                        className={
                                            'auto-fill-modal__output-sentence'
                                        }
                                    >
                                        {sentence}
                                    </span>
                                    {hoveredIndex === index && (
                                        <span
                                            className="delete-icon"
                                            onClick={() => {
                                                const updatedContent = [
                                                    ...outputContent,
                                                ];
                                                updatedContent.splice(index, 1);
                                                setOutputContent(
                                                    updatedContent,
                                                );
                                            }}
                                        >
                                            <FaTrashAlt />
                                        </span>
                                    )}
                                </span>
                            ))}
                        </p>
                    </div>
                )}
                {!generatedContent && !error && (
                    <div className="auto-fill-modal__loading-container">
                        {isLoading && <LoadingWidget />}
                    </div>
                )}
                {error && <p className="auto-fill-modal__error">{error}</p>}
            </div>

            {generatedContent && (
                <>
                    <hr style={{}} />
                    <p>
                        Arbor AI can make mistakes. Please check important
                        information.
                    </p>
                </>
            )}
        </>
    );
};
