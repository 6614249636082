export default Object.freeze({
    ANALYTICS: {
        GET_FILTER_OPTIONS: '/analytics/get-filter-options/',
    },
    ARBOR: {
        WEB_SITE: 'http://arbor-education.com',
        LOGIN_SUPPORT:
            'https://support.arbor-education.com/hc/en-us/articles/115000148485-Logging-in-and-out-of-Arbor-and-troubleshooting',
        COMMUNITY: 'https://arbor-hq.circle.so/',
        MY_HOMEPAGE_SUPPORT:
            'https://support.arbor-education.com/hc/en-us/articles/360014867198-My-Homepage-your-personalised-space-to-help-you-keep-up-to-date-and-speed-through-admin',
        ZENDESK: 'https://arboreducation.zendesk.com/hc/en-us/categories/',
        LOGIN: 'https://login.arbor.sc/',
        TABLE_SUPPORT_PAGE:
            'https://support.arbor-education.com/hc/en-us/articles/5909082569373-New-tables-across-the-MIS',
        HOME_PAGE_KPI_SUPPORT:
            'https://support.arbor-education.com/hc/en-us/articles/360014867198-My-Homepage-your-personalised-Arbor-Homepage#what-are-the-kpis--0-4',
        TRAINING_HUB: 'https://arboreducation.northpass.com/app',
    },
    ASK_ARBOR: {
        CHAT_PANEL: '/ask-arbor-ui/chat-panel-agent/',
    },
    AUTH: {
        ACCEPT_TERMS: '/user/accept-license/', // Handles licence and/or ToS. See: https://orchard.atlassian.net/browse/MIS-37162
        CURRENT_USER_SETTINGS: '/auth/current-user-settings/format/json',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        RESET_PASSWORD: '/auth/reset-password',
        EXPIRED_RESET_PASSWORD: '/expired-reset-password-hash/true',
    },
    CALENDAR_ENTRY: {
        LIST_STATIC: '/calendar-entry/list-static/format/json/',
        VIEW_EVENT: '/calendar-entry/view-event/',
    },
    GLOBAL_SEARCH: {
        AUTOCOMPLETE: '/global-search/autocomplete/format/json/',
        SEARCH_UI: '/global-search-ui/search/query/',
        HELP_CENTRE_SEARCH: '/global-search/help-centre-search/',
    },
    GROUP: {
        MY_REPORTS: '/group/performance-reports-ui/my-reports',
    },
    NAVIGATION: {
        MAIN_MENU: '/navigation/main-menu/format/json',
    },
    NEWS_STORY_COMMENT: {
        CREATE: '/news-story-comment/create/format/json/',
        CHANGE: '/news-story-comment/change/format/json/',
        DELETE: '/news-story-comment/delete/format/json/',
    },
    SCHOOL_EVENT_UI: {
        CREATE_SCHOOL_EVENT: '/school-event-ui/create-school-event/',
    },
    USER_NOTIFICATION: {
        LIST: '/user-notification/list/format/json/',
        UPDATE: '/user-notification/update/format/json/',
        GET_NOTIFICATIONS: '/user-notification/get-notifications/format/json/',
        MARK_NOTIFICATIONS_AS_SEEN:
            '/user-notification/mark-notifications-as-seen/format/json/',
        MARK_NOTIFICATION_AS_READ:
            '/user-notification/mark-notification-as-read/format/json/',
        MARK_NOTIFICATION_AS_HIDDEN:
            '/user-notification/mark-notification-as-hidden/format/json/',
    },
    WIDGET_DATA: {
        GET_NOTICES: '/widget-data/get-notices/format/json',
        GET_CALENDAR_DATA: '/widget-data/get-calendar-data/format/json/',
    },
    LOGO: '/img/logos/default-institution-logo.png',
    ARBOR_LOGO_SVG: 'img/logos/arbor-logo-white-background.svg',
    HOME_PAGE: '/home-ui/index',
    DASHBOARD: {
        SAVE_FILTERS: 'dashboard/kpi-options',
        GET_TO_DOS: 'dashboard/get-todos',
        GET_SCHOOL_NOTICES: 'dashboard/get-school-notices',
        GET_STUDENT_ALERTS: 'dashboard/get-student-alerts',
        DISMISS_STUDENT_ALERT: 'dashboard/dismiss-student-alert',
        GET_MY_STUDENTS_KPI_DATA: 'dashboard/kpi-my-students',
        GET_WHOLE_SCHOOL_KPI_DATA: 'dashboard/kpi-whole-school',
    },
    GROUP_GLOBAL_FILTERS: {
        GET_FILTER_OPTIONS: '/group/live-analytics/global-filter-options',
        GET_FILTER_SETTINGS: '/group/live-analytics/global-filter-settings',
        SAVE_FILTER_SETTINGS:
            '/group/live-analytics/edit-global-filter-settings',
    },
});

const _endsWithSlash = (url: string) => {
    if (url.slice(-1) === '/') {
        return url;
    }
    return url + '/';
};

export const appendFormatJson = (url: string) => {
    if (!~url.indexOf('?format=json') && !~url.indexOf('/format/json')) {
        return _endsWithSlash(url) + '?format=json';
    }
    return url;
};

let currentHelpCentreUrl: string | null = null;
export const getCurrentHelpCentreUrl = () => {
    return currentHelpCentreUrl;
};
export const setCurrentHelpCentreUrl = (url: string | null) => {
    currentHelpCentreUrl = url;
};

export {removeSisUrlParameters} from './removeSisUrlParameters';
export {createUrlWithReplacedHash} from './createUrlWithReplacedHash';
export {findHashInUrl} from './findHashInUrl';
export {isUrlExternal} from './isUrlExternal';
