import PropTypes from 'prop-types';
import {Component} from 'react';

import {formatNotificationDate} from 'BackendRenderedComponents/dashboard/utils';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import Loader from 'Services/Loader';
import {BACKSPACE_KEY_CODE} from 'Utils/keyCodeConstants';
import {sanitizeButtonTitleAttribute} from 'Utils/sanitizeHtml';

import {NotificationIcon} from './icons';

import './notificationItem.scss';

export class NotificationItem extends Component {
    constructor(props) {
        super(props);
        this.titleRef = null;

        this.state = {
            isTitleOverflown: false,
        };
    }

    setTitleRef = (element) => {
        this.titleRef = element;
    };

    componentDidMount() {
        this.setState({
            isTitleOverflown: this.isTitleOverflown(),
        });
    }

    handleDismiss = (e) => {
        const {id, onDismiss} = this.props;

        e.stopPropagation();
        onDismiss(id);
    };

    handleClick = () => {
        const url = this.props.url;
        if (url) {
            Loader.loadPage(url);
        }
    };

    handleKeyDownPress = (e) => {
        const {onDismiss, id} = this.props;

        if (onDismiss && e.keyCode === BACKSPACE_KEY_CODE) {
            onDismiss(id);
        }
    };

    isTitleOverflown = () => {
        if (this.titleRef) {
            // scrollHeight on firefox is 1px higher compared to other browsers.
            // If we have two-line title, scrollHeight would be at least clientHeight x 2,
            // so -1 won't do any harm
            return (
                this.titleRef.scrollHeight - 1 > this.titleRef.clientHeight ||
                this.titleRef.scrollWidth > this.titleRef.clientWidth
            );
        }
        return false;
    };

    render() {
        const {title, subtitle, iconType, onDismiss} = this.props;

        const {isTitleOverflown} = this.state;

        return (
            <KeyboardFocusableButton
                className="dashboard-notification-item-container"
                buttonWrapperClassName="dashboard-notification-item-container__wrapper"
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDownPress}
                title={sanitizeButtonTitleAttribute(title)}
            >
                <div className="dashboard-notification-item-title">
                    <span
                        ref={this.setTitleRef}
                        dangerouslySetInnerHTML={{__html: title}}
                        className="dashboard-notification-item-title-content"
                    />
                    {isTitleOverflown && (
                        <span className="dashboard-notification-item-title-ellipsis">
                            ...
                        </span>
                    )}
                </div>
                {subtitle && (
                    <div className="dashboard-notification-item-subtitle-container">
                        {iconType && (
                            <NotificationIcon
                                iconName={iconType}
                                className="dashboard-notification-item-icon"
                            />
                        )}
                        {formatNotificationDate(
                            subtitle,
                            iconType === 'birthday-cake',
                        )}
                    </div>
                )}
                {onDismiss && (
                    <span onClick={this.handleDismiss}>
                        <Icon
                            iconName="cross"
                            className="dashboard-notification-item-dismiss-icon"
                        />
                    </span>
                )}
            </KeyboardFocusableButton>
        );
    }
}

NotificationItem.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    iconType: PropTypes.string,
    onDismiss: PropTypes.func,
    url: PropTypes.string,
    id: PropTypes.string,
};
