import {UserFavouritePage} from 'Root/core/services/session/fetchCurrentUserSettings';
import {splitSisUrlIntoSections} from 'Services/url/splitSisUrlIntoSections';

import {
    CLEAR_DYNAMIC_NAV,
    TOGGLE_SUB_NAV_ITEM,
    UPDATE_DYNAMIC_NAV,
    SET_SUBNAV_HIDDEN_BY_USER,
    CLEAR_NAVIGATION,
    UPDATE_NAVIGATION,
    USER_FAVOURITE_PAGES,
    ADD_USER_FAVOURITE_PAGE,
} from './constants';
import {TransformedNavigationItem} from './types';

export function clearNavigationAction() {
    return {
        type: CLEAR_NAVIGATION,
    } as const;
}

export function updateNavigationAction({
    treeData,
    isNewNavigation,
}: {
    treeData: TransformedNavigationItem[];
    isNewNavigation: boolean;
}) {
    return {
        type: UPDATE_NAVIGATION,
        treeData,
        isNewNavigation,
    };
}

export function clearDynamicNavAction({currentUrl}: {currentUrl: string}) {
    if (typeof currentUrl === 'string') {
        const {baseUrl, urlParamParts} = splitSisUrlIntoSections(currentUrl);

        return {
            type: CLEAR_DYNAMIC_NAV,
            currentRoute: baseUrl,
            currentUrlParams: urlParamParts.length > 0 ? urlParamParts : null,
        };
    }
    return {
        type: CLEAR_DYNAMIC_NAV,
        currentRoute: null,
        currentUrlParams: null,
    };
}

export function updateDynamicNavAction({
    title,
    treeData,
    parentStaticRoute,
    currentUrl,
    parentStaticTreeOptionalParams,
    isNewDynamicNavigationData,
}: {
    treeData: TransformedNavigationItem[];
    title: string;
    parentStaticRoute?: string;
    currentUrl: string;
    parentStaticTreeOptionalParams?: Record<string, string | null>;
    isNewDynamicNavigationData: boolean;
}) {
    if (typeof currentUrl === 'string') {
        const {baseUrl, urlParamParts} = splitSisUrlIntoSections(currentUrl);
        return {
            type: UPDATE_DYNAMIC_NAV,
            title,
            dynamicTreeData: treeData,
            parentStaticRoute,
            currentRoute: baseUrl,
            currentUrlParams: urlParamParts.length > 0 ? urlParamParts : null,
            parentStaticTreeOptionalParams,
            isNewDynamicNavigationData,
        };
    }

    return {
        type: UPDATE_DYNAMIC_NAV,
        title,
        dynamicTreeData: treeData,
        parentStaticRoute,
        currentRoute: null,
        currentUrlParams: null,
        parentStaticTreeOptionalParams,
        isNewDynamicNavigationData,
    };
}

export function toggleSubNavItemAction({itemId}: {itemId: string}) {
    return {
        type: TOGGLE_SUB_NAV_ITEM,
        itemId,
    };
}

export function setSubNavHiddenByUserAction({isHidden}) {
    return {
        type: SET_SUBNAV_HIDDEN_BY_USER,
        isHidden,
    };
}

export function userFavouritePagesAction(
    userFavouritePages: UserFavouritePage[],
) {
    return {
        type: USER_FAVOURITE_PAGES,
        userFavouritePages,
    };
}

export function addUserFavouritePageAction(
    userFavouritePageId: number,
    favouritedRoute: string,
    customName: string,
) {
    return {
        type: ADD_USER_FAVOURITE_PAGE,
        userFavouritePageId,
        favouritedRoute,
        customName,
    };
}
