import {useCallback, useState} from 'react';

import {httpPost} from 'Interfaces/httpClient';
import {useIsMounted} from 'Root/core/utils/useIsMounted';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {SnippetData} from './types';

type useSnippetDataProps = {
    replacement: {objectTypeId: number; objectId: number};
    context: {objectTypeId: number; objectId: number};
};

const LIST_SNIPPETS = 'snippet/list-snippets';

const transformSisSnippet = (sisSnippet): SnippetData => {
    const {
        fields: {
            editable: {value: editable},
            snippet_name: {value: snippetName},
            snippet_text_merged: {value: snippetValue},
            global: {value: global},
            snippet_id: {value: id},
        },
    } = sisSnippet;

    return {
        editable,
        snippetName,
        snippetValue,
        global,
        id,
    };
};

export const useSnippetData = (props: useSnippetDataProps) => {
    const {replacement, context} = props;

    const _isMounted = useIsMounted();

    const [isLoading, setIsLoading] = useState(true);

    const [snippetList, setSnippetList] = useState<SnippetData[]>([]);

    const fetchSnippetList = useCallback(async () => {
        setIsLoading(true);
        const rawSnippetList = await httpPost(
            `${LIST_SNIPPETS}/replacement/${replacement.objectTypeId},${replacement.objectId}/context/${context.objectTypeId},${context.objectId}`,
        );

        if (_isMounted.current) {
            setSnippetList(rawSnippetList.items.map(transformSisSnippet));
            setIsLoading(false);
        }
    }, [
        _isMounted,
        context.objectId,
        context.objectTypeId,
        replacement.objectId,
        replacement.objectTypeId,
    ]);

    useComponentDidMount(() => {
        fetchSnippetList();
    });

    return {
        snippetList,
        isLoading,
        fetchSnippetList,
    };
};
