import {TransformedNavigationItem} from '../types';

import {findItemInTree} from './findItemInTree';

export const findRouteInTree = (
    route: string,
    treeData: TransformedNavigationItem[],
): TransformedNavigationItem | null => {
    return findItemInTree(
        (item: TransformedNavigationItem) =>
            !!item.url?.includes(route) && item.isDuplicate !== true,
        treeData,
    );
};
