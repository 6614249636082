export const sanitizeImageSrc = (node: HTMLUnknownElement) => {
    if (node.nodeName === 'IMG' && node.hasAttribute('src')) {
        if (
            (node as HTMLImageElement).src.startsWith(window.location.origin) ||
            (node as HTMLImageElement).src.startsWith('data:image/jpeg;base64,')
        ) {
            return;
        }
        node.remove();
    }
};
