import {SECTION_SELECTOR} from 'Config/Events';
import pubsub from 'Services/pubsub/Pubsub';

import exportInterface from './Base';

const reload = () => {
    pubsub.publish(SECTION_SELECTOR.RELOAD);
};

export const registerSectionSelectorInterface = () => {
    exportInterface('SectionSelector', {
        reload,
    });
};
