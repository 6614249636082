import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';

import {LOADER} from 'Config/Events';
import externalConfig from 'Config/External';
import {getState, setCurrentUrl} from 'Interfaces/history/History';
import {httpGet, httpPost} from 'Interfaces/httpClient';
import {maskMain, unmaskMain} from 'Interfaces/Mask';
import reportToSisSentry from 'Interfaces/raven/Raven';
import Scroll from 'Interfaces/Scroll';
import {isLoggedIn, setLastRequestTime} from 'Interfaces/Session';
import {addSystemModal} from 'Interfaces/SystemModal';
import {removeAllWindows} from 'Interfaces/WindowManager';
import {renderLoginPage} from 'Root/renderLoginPage';
import pubsub from 'Services/pubsub/Pubsub';
import {isUrlExternal} from 'Services/url/Url';
import {closeBurgerMenuAction} from 'State/burgerMenu';
import Store from 'State/Store';
import {closeAllExtTooltips} from 'Utils/closeAllExtTooltips';
import {closeAllExtWindows} from 'Utils/closeAllExtWindows';
import {isAbsoluteUrl} from 'Utils/isAbsoluteUrl';

import {ExtPartialComponent, render} from '../renderer/Renderer';

import {getRenderTargetsService} from './renderTargets/RenderTargets';
import {getUnsavedDataRegistryService} from './unsavedData';

type RequestParams = {
    event?: Event;
    reload?: boolean;
    scope?;
    jsonData?;
    back?;
    elementToRefocus?;
    refocusCallback?;
    launchedFromActionLauncher?: boolean;
};

class Loader {
    private disablePageLoad = false;
    private pageUrlRequested: null | string = null;

    externalConfig = externalConfig.getConfig();

    constructor() {
        pubsub.subscribe('historypop', () => {
            if (this.disablePageLoad) {
                return;
            }

            const historyState = getState();

            if (
                isObject(historyState) &&
                'url' in historyState &&
                isString(historyState.url)
            ) {
                let url = historyState.url;

                url = url.substring(url.indexOf('?') + 1, url.length);

                if (
                    decodeURIComponent(`${this.pageUrlRequested}`) ===
                    decodeURIComponent(url)
                ) {
                    //Don't load the page, already loaded
                    return;
                }

                //TODO FIX WHEN WE REMOVE SENCHA
                this._requestContent(url, {back: true});
            }
        });
    }

    _setLastRequestTime() {
        setLastRequestTime(new Date().getTime());
    }

    //TODO FIX WHEN WE REMOVE SENCHA
    private _requestContent = (url: string, params: RequestParams = {}) => {
        // Event is currently unused, but keeping for now to show the full list of parameters
        const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            event,
            reload,
            scope,
            jsonData,
            back,
            elementToRefocus,
            refocusCallback,
            launchedFromActionLauncher,
        } = params;

        setCurrentUrl(url);
        Raven.setExtraContext({lastRequestedUrl: url});

        this._setLastRequestTime();

        let skipHistoryPush = back;

        if (!scope) {
            maskMain();
        }

        if (reload) {
            Scroll.savePageScroll();
            skipHistoryPush = true;
        }

        const handleResponse = (data) => {
            // TODO [MIS-36571] Investigate whether this should be moved to Renderer.js.
            // Perhaps it only should be called if pages are loaded, or maybe also for the
            // window and slideover case.
            closeAllExtWindows();
            removeAllWindows();
            closeAllExtTooltips();

            Store.store.dispatch(closeBurgerMenuAction());

            render({
                data,
                url,
                reload,
                skipHistoryPush,
                elementToRefocus,
                refocusCallback,
                launchedFromActionLauncher,
            });
            this.pageUrlRequested = null;
            unmaskMain();
            //TODO handle bad response
        };

        const handleResponseError = (e) => {
            console.error('Error requesting content (handleResponseError)', e);

            unmaskMain();
            const loggedIn = isLoggedIn();
            // If response status is 401 or 403 (when user is not logged in) show login page
            if (e && e.status) {
                if (e.status === 401 || (!loggedIn && e.status === 403)) {
                    renderLoginPage();
                    return;
                }
            }
        };

        const formattedUrl =
            typeof url === 'string' && url.endsWith('?format=javascript')
                ? url
                : url + '?format=javascript';

        if (jsonData) {
            httpPost(formattedUrl, jsonData)
                .then(handleResponse)
                .catch(handleResponseError);
        } else {
            httpGet(formattedUrl)
                .then(handleResponse)
                .catch(handleResponseError);
        }
    };

    //TODO FIX WHEN WE REMOVE SENCHA
    loadPage = (url: string, params: RequestParams = {}) => {
        // All of the logic around loading the page is now in this callback here
        // to allow for unsaved data validation to interrupt page loading if necessary
        pubsub.publish(LOADER.PAGE_LOAD);

        const loadTargetPage = () => {
            // If a different domain specified in the URL then load that as a complete new page
            if (isUrlExternal(url)) {
                document.location.href = url;
            } else {
                const shortenedUrl = url.substring(
                    url.indexOf('?') + 1,
                    url.length,
                );

                if (isAbsoluteUrl(shortenedUrl)) {
                    console.error(
                        'Trying to load external page as relative path. Blocking for security reasons',
                        shortenedUrl,
                    );
                    reportToSisSentry(
                        new Error('Absolute url used as relative'),
                        {
                            url,
                            shortenedUrl,
                        },
                    );
                    return;
                }

                if (typeof params.reload === 'undefined') {
                    // Still allowing callers to loadPage to decide the reload param
                    // if they want to. If none are necessary then we can remove the param.
                    const currentPage = window.location.search.startsWith('?')
                        ? window.location.search.slice(1)
                        : window.location.search;

                    if (currentPage === shortenedUrl) {
                        // If page is the same, we'd like to preserve scoll position, and
                        // not push a new history item, so set reload=true
                        params.reload = true;
                    }
                }

                if (params.scope) {
                    this._requestContent(shortenedUrl, params);
                } else {
                    this.pageUrlRequested = shortenedUrl; //Set the url ONLY IF it's not a partial view (to avoid a double page loading)
                    this._requestContent(shortenedUrl, params);
                }
            }
        };

        if (getUnsavedDataRegistryService().hasInvalidDataSources()) {
            addSystemModal({
                icon: 'info',
                title: 'Changes may not be saved!',
                message: getUnsavedDataRegistryService().getErrorMessage(),
                onConfirm: () => {
                    getUnsavedDataRegistryService().dismiss();
                    loadTargetPage();
                },
                confirmationButtonText: 'Leave without saving your changes',
            });
        } else {
            getUnsavedDataRegistryService().dismiss();
            loadTargetPage();
        }
    };

    loadCurrentPage = (reload?: boolean) => {
        const currentLocation = document.location.href;
        if (currentLocation.indexOf('?') !== -1) {
            this.loadPage(currentLocation, {reload});
        }
    };

    loadPartial(
        url: string,
        partial: ExtPartialComponent,
        callback: () => void,
    ) {
        Raven.setExtraContext({lastRequestedUrl: url});

        const handleResponse = (data) => {
            render({data, url, partial});
            //TODO handle bad response
            if (callback) {
                callback();
            }
        };

        const handleResponseError = (e) => {
            unmaskMain();
            // If response status is 401 or 403
            if (e && e.status) {
                const loggedIn = isLoggedIn();
                if (e.status === 401 || (!loggedIn && e.status === 403)) {
                    renderLoginPage();
                }
            }
        };

        const formattedUrl = url + '?format=javascript';

        httpGet(formattedUrl).then(handleResponse).catch(handleResponseError);
    }

    unmountPartialById(partialId: string) {
        getRenderTargetsService().unmountAdHocRoot(partialId);
    }

    openInNewTab(url: string) {
        const win = window.open(url, '_blank');
        if (win) {
            win.focus();
        }
    }
}

export default new Loader();
