/**
 * These constants are based on the values in application/modules/default/models/Entity/Type.php in SIS
 */
export const ENTITY_TYPES = {
    STUDENT: {
        displayNameSingular: 'Student',
        displayNamePlural: 'Students',
        entityTypeId: 1,
    },
    GUARDIAN: {
        displayNameSingular: 'Guardian',
        displayNamePlural: 'Guardians',
        entityTypeId: 2,
    },
    STAFF: {
        displayNameSingular: 'Staff',
        displayNamePlural: 'Staff',
        entityTypeId: 3,
    },
    CUSTOM_REPORT: {
        displayNameSingular: 'Custom Report',
        displayNamePlural: 'Custom Reports',
        entityTypeId: 845,
    },
};
