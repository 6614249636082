import classnames from 'classnames';
import PropTypes from 'prop-types';

import {Button, IButtonProps} from 'Components/button/base';

import './systemButton.scss';

interface ISystemButtonProps extends IButtonProps {
    className?: string;
    icon: string;
}

export function SystemButton(props: ISystemButtonProps) {
    const {className, ...rest} = props;
    return (
        <Button
            {...rest}
            uiType="secondary"
            className={classnames('arbor-btn-system', className)}
        />
    );
}

SystemButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
};
