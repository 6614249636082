import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

type HelpCentreErrorButtonProps = {
    sentryId?: string;
};
export const HelpCentreErrorButton = ({
    sentryId,
}: HelpCentreErrorButtonProps) => {
    const baseUrl =
        'https://support.arbor-education.com/hc/en-us/requests/new?ticket_form_id=360003229018';

    const prefilledFormUrl = `${baseUrl}${
        sentryId ? `&tf_360019515937=${sentryId}` : ''
    }&tf_360019403518=${encodeURIComponent(document.location.href)}`;

    return (
        <KeyboardFocusableLink url={prefilledFormUrl} target="_blank">
            Press here
        </KeyboardFocusableLink>
    );
};
