import classnames from 'classnames';
import PropTypes from 'prop-types';

import {dropdownFieldRenderProps} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import './multiSelectDropdownField.scss';

const MultiSelectDropdownField = ({
    isDropdownVisible,
    toggleDropdown,
    toggleDropdownOnEnterOrSpace,
    description,
    className,
}) => {
    return (
        <KeyboardFocusableButton
            onClick={toggleDropdown}
            onKeyDown={toggleDropdownOnEnterOrSpace}
            className={classnames('multi-select-dropdown-field', className, {
                'multi-select-dropdown-field__active': isDropdownVisible,
            })}
            buttonWrapperClassName="multi-select-dropdown-field__wrapper"
            borderRadius="4px"
        >
            <span>{description}</span>
            <Icon
                iconName={isDropdownVisible ? 'arrow-go-up' : 'arrow-go-down'}
                className="multi-select-dropdown-field-icon"
            />
        </KeyboardFocusableButton>
    );
};

MultiSelectDropdownField.propTypes = {
    ...dropdownFieldRenderProps,
    description: PropTypes.string,
    className: PropTypes.string,
    selectedCount: PropTypes.number,
    itemsCount: PropTypes.number,
};

export {MultiSelectDropdownField};
