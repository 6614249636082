import PropTypes from 'prop-types';

import {
    dropdownContentsRenderProps,
    DropdownContentsEmptyText,
} from 'Components/dropdown';
import {FilterTag, FILTER_TAG_COLOR_ENUM} from 'Components/filters';

import './searchFilterDropdownContents.scss';

export const SearchFilterDropdownContents = ({
    dropdownContentsRef,
    emptyText,
    focusDropdownField,
    searchItems,
    searchValue,
    onSearchItemClicked,
}) => {
    const filteredSearchItems = searchItems.filter(
        ({displayName, selected}) =>
            !selected &&
            displayName.toUpperCase().indexOf(searchValue.toUpperCase()) >= 0,
    );

    const focusNextTag = (shouldShowKeyboardFocus) => {
        const selector = shouldShowKeyboardFocus ? 'button' : 'button > span';
        if (document && dropdownContentsRef.contains(document.activeElement)) {
            const tags = Array.from(
                dropdownContentsRef.querySelectorAll(selector),
            );
            const focusedIndex = tags.findIndex((tag) =>
                tag.contains(document.activeElement),
            );

            // if there is only one tag, focus input field
            if (tags.length === 1) {
                focusDropdownField();
            } else if (focusedIndex > -1) {
                const indexToFocus = focusedIndex + 1;

                // if it's not the last one, focus next tag
                if (indexToFocus <= tags.length - 1) {
                    tags[indexToFocus].focus();

                    // focus previous tag
                } else {
                    tags[indexToFocus - 2].focus();
                }
            }
        }
    };

    const handleFilterClick = (id) => (e) => {
        const isKeyboardEvent = e.detail === 0;
        focusNextTag(isKeyboardEvent);

        // Dropdown's click listener is checking if click target is part of the dropdown
        // If it's not part of it, dropdown will close itself
        //
        // Wrapped with setTimeout to make sure removing tag will always occur after click listener
        // to prevent dropdown from closing on every tag click
        setTimeout(() => {
            onSearchItemClicked(id);
        }, 0);
    };

    return (
        <div>
            {filteredSearchItems.length > 0 ? (
                <div
                    className="search-filter-dropdown-contents__tags-container"
                    data-testid="SearchFilterDropdownContents-container"
                >
                    {filteredSearchItems.map((item) => (
                        <FilterTag
                            key={item.id}
                            tabIndex={-1}
                            filter={item}
                            color={FILTER_TAG_COLOR_ENUM.BLUE}
                            onClick={handleFilterClick(item.id)}
                        />
                    ))}
                </div>
            ) : (
                <DropdownContentsEmptyText>
                    {emptyText}
                </DropdownContentsEmptyText>
            )}
        </div>
    );
};

SearchFilterDropdownContents.propTypes = {
    ...dropdownContentsRenderProps,
    emptyText: PropTypes.string,
    searchItems: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    searchValue: PropTypes.string,
    onSearchItemClicked: PropTypes.func,
};
