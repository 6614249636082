import isString from 'lodash-es/isString';
import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import {httpGet} from 'Interfaces/httpClient';

import Tooltip from './Tooltip';

export default class TooltipAdvanced extends PureComponent {
    // Simple cache for storing the tooltip data. This component is fairly short lived
    // so hopefully don't need to worry about this data becoming stale. We keep track of the
    // loading state of the data in the cache to prevent it being requested twice
    tooltipCache = {};
    constructor(props) {
        super(props);
        this.state = {content: null, loading: false};
    }
    componentDidUpdate = (previousProps) => {
        if (!this.props.tooltipUrl) {
            return;
        }
        if (this.props.visible && previousProps !== this.props) {
            // This url needs to remain constant in the rest of the callbacks
            const currentTooltipUrl = this.props.tooltipUrl;

            if (
                previousProps.tooltipUrl !== currentTooltipUrl ||
                (!this.state.content && !this.state.loading)
            ) {
                // Use cached tooltip data if possible
                if (
                    this.tooltipCache[currentTooltipUrl] &&
                    this.tooltipCache[currentTooltipUrl].content
                ) {
                    this.setState(
                        {
                            content:
                                this.tooltipCache[currentTooltipUrl].content,
                        },
                        () => this.props.onShow(),
                    );
                } else {
                    if (!this.tooltipCache[currentTooltipUrl]) {
                        this.tooltipCache[currentTooltipUrl] = {};
                    }

                    // Don't request data for a tooltip if its currently being requested
                    if (!this.tooltipCache[currentTooltipUrl].loading) {
                        this.tooltipCache[currentTooltipUrl].loading = true;
                        this.setState({loading: true, content: null});
                        httpGet(currentTooltipUrl)
                            .then((data) => {
                                this.tooltipCache[currentTooltipUrl].content =
                                    data;
                                this.tooltipCache[
                                    currentTooltipUrl
                                ].loading = false;

                                if (
                                    this.props.tooltipUrl === currentTooltipUrl
                                ) {
                                    this.setState(
                                        {content: data, loading: false},
                                        () => this.props.onShow(),
                                    );
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                this.tooltipCache[
                                    currentTooltipUrl
                                ].loading = false;

                                if (
                                    this.props.tooltipUrl === currentTooltipUrl
                                ) {
                                    this.setState({loading: false});
                                }
                            });
                    }
                }
            }
        }
    };
    renderContent = (header, subHeader, content) => {
        // TODO [MIS-36782]: TootlipHeader and TooltipSubheader are currently unused anywhere
        // on the backend and the other frotend files that use tooltipWrapper don't currently
        // pass them through to this. Need to decide whether to finish or abandon them.
        let TooltipHeader = header && (
            <div
                className={'tooltip-header'}
                dangerouslySetInnerHTML={this.dangerousHtml(header)}
            />
        );
        let TooltipSubHeader = subHeader && (
            <div
                className={'tooltip-sub-header'}
                dangerouslySetInnerHTML={this.dangerousHtml(subHeader)}
            />
        );
        let TooltipContent = isString(content) ? (
            <div
                className={'tooltip-content'}
                dangerouslySetInnerHTML={this.dangerousHtml(content)}
            />
        ) : (
            <div className={'tooltip-content'}> {content} </div>
        );

        return (
            <div className={'tooltip-content-wrapper'}>
                {TooltipHeader}
                {TooltipSubHeader}
                {TooltipContent}
            </div>
        );
    };
    dangerousHtml = (content) => ({__html: content});
    render() {
        const {header, subHeader, content, tooltipUrl, ...rest} = this.props;
        if (tooltipUrl) {
            return (
                <Tooltip
                    isLoading={this.state.loading}
                    className={'tooltip-advanced'}
                    content={this.renderContent(
                        header,
                        subHeader,
                        this.state.content,
                    )}
                    {...rest}
                />
            );
        }
        return (
            <Tooltip
                className={'tooltip-advanced'}
                content={this.renderContent(header, subHeader, content)}
                {...rest}
            />
        );
    }
}

TooltipAdvanced.propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    tooltipUrl: PropTypes.string,
    visible: PropTypes.bool,
    clearCache: PropTypes.bool,
    onShow: PropTypes.func,
    targetElement: PropTypes.object,
};
