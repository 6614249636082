import {MutableRefObject, useEffect, useState} from 'react';
import {Editor} from 'tinymce';

import {stripHTMLTagsAndDecodeSymbols} from 'Utils/sanitizeHtml';

import {SISWordCounterConfig} from '../types';

import {CHARSET_7_BIT_EXT, GSM_7_BIT_CHARS} from './constants';

export const useWordCounter = (
    cfg: SISWordCounterConfig | undefined,
    editor: MutableRefObject<Editor | undefined>,
) => {
    const {
        props: {
            data: {
                limit: unsafeLimit = null,
                type = 'word',
                signatureLength: unsafeSignatureLength = 0,
            } = {},
        } = {},
    } = cfg || {};

    const hasCharacterLimit = unsafeLimit !== null;
    const limit = Number(unsafeLimit);
    const signatureLength = Number(unsafeSignatureLength);

    const [isValid, setIsValid] = useState(true);
    const [unsafeCharacterCount, updateCharacterCount] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [remainingCharacterCount, setRemainingCharacterCount] = useState(
        Number(limit),
    );

    useEffect(() => {
        if (!hasCharacterLimit) {
            return;
        }
        setRemainingCharacterCount(limit - unsafeCharacterCount);

        if (type === 'sms') {
            setIsValid(true);
        } else {
            setIsValid(limit - unsafeCharacterCount >= 0);
        }
    }, [unsafeCharacterCount, hasCharacterLimit, limit, type]);

    let smsNon7BitCoding = false;

    let smsSafeCharacterCount = unsafeCharacterCount;

    // TODO need to figure out how to get merge fields to behave properly with the word counter
    if (type === 'sms') {
        smsSafeCharacterCount = 0;

        const content = stripHTMLTagsAndDecodeSymbols(
            editor?.current?.getContent() || '',
        );
        const contentSplitLength = content.split('').length;
        for (let i = 0; i < contentSplitLength; i++) {
            if (GSM_7_BIT_CHARS.indexOf(content[i]) !== -1) {
                smsSafeCharacterCount += 1;
            } else if (CHARSET_7_BIT_EXT.indexOf(content[i]) !== -1) {
                smsSafeCharacterCount += 2;
            } else {
                smsNon7BitCoding = true;
                smsSafeCharacterCount += content[i].length;
            }
        }
    }

    const totalCharacterCount = smsSafeCharacterCount + signatureLength;

    const stdMessageLength = smsNon7BitCoding ? 67 : 153;

    const estimatedCredits = Math.ceil(totalCharacterCount / stdMessageLength);

    return {
        updateCharacterCount,
        setWordCount,
        wordCounterParams: {
            characterCount: smsSafeCharacterCount,
            remainingCharacterCount,
            totalCharacterCount,
            estimatedCredits,
            hasCharacterLimit,
            wordCount,
            isValid,
            signatureLength,
            stdMessageLength,
            smsNon7BitCoding,
            type,
        },
    };
};
