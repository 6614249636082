/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:07
 */

import exportInterface from 'Interfaces/Base';
import {getHistoryService} from 'Services/history/History';

export function back() {
    getHistoryService().back();
}

export function pushState(state, title, url) {
    getHistoryService().pushState(state, title, url);
}

export function replaceState(state, title, url) {
    getHistoryService().replaceState(state, title, url);
}

export function getState() {
    return getHistoryService().getState();
}

export function getHistoryLength() {
    return getHistoryService().getHistoryLength();
}

export function getCurrentUrl() {
    return getHistoryService().getCurrentUrl();
}

export function setCurrentUrl(url) {
    return getHistoryService().setCurrentUrl(url);
}

export function clearCurrentUrl() {
    return getHistoryService().clearCurrentUrl();
}

export const registerHistoryInterface = () => {
    exportInterface('History', {
        back,
        pushState,
        replaceState,
        getState,
        getHistoryLength,
        getCurrentUrl,
        setCurrentUrl,
        clearCurrentUrl,
    });
};
