import {addSlideover} from 'Interfaces/Slideover';
import {removeSisUrlParameters} from 'Interfaces/Url';
import {isExtComponent} from 'Renderer/componentCache';
import {ExtLayoutRenderer} from 'Renderer/ExtLayoutRenderer';
import {getIsComboboxAlphaModeEnabled} from 'Renderer/getIsComboboxAlphaModeEnabled';
import {
    renderUIContent,
    renderExtContent,
    setComponentKey,
} from 'Renderer/Helper';
import {
    SlideoverRendererData,
    RendererParams,
    SlideoverContentItem,
} from 'Renderer/Renderer';
import {logEventToGainsight} from 'Root/core/services/gainsight';
import {getCurrentPageFromUrl} from 'Root/core/services/url/getCurrentPageFromUrl';

import type {ReactNode} from 'react';

const renderSlideoverContent = (
    data: SlideoverContentItem,
    rendererParams: RendererParams,
) => {
    // We always expect the content for a slideover to be an array, so if its not,
    // return an empty array
    if (typeof data.content === 'string') {
        console.warn('Slideover content should be an array', data);
        return [];
    }

    const slideoverChildren = data.content.map((cont, index) => {
        setComponentKey(cont, index);

        if (cont.componentName === 'Arbor.formfield.TagField') {
            if (getIsComboboxAlphaModeEnabled()) {
                cont.componentName = 'Arbor.formfield.ReactCombobox';
            }
        }

        if (isExtComponent(cont.componentName)) {
            return renderExtContent(cont, rendererParams);
        }
        return renderUIContent(cont, rendererParams);
    });

    const slideoverContainsExtChildren = data.content.find((child) =>
        isExtComponent(child.componentName),
    );

    return slideoverContainsExtChildren ? (
        <ExtLayoutRenderer
            children={slideoverChildren}
            {...data.props}
            componentName="Mis.container.ExtLayoutContainer"
        />
    ) : (
        slideoverChildren
    );
};

/**
 * Method that takes the json response data, goes through all the slideover config objects (if there are any), creates a slideover configuration out of the data,
 * and shows the created slideover.
 * Used for slideover rendering.
 */
export function renderSlideovers(
    data: SlideoverRendererData,
    rendererParams: RendererParams,
) {
    if (
        data.content &&
        typeof data.content !== 'string' &&
        data.content.length > 0
    ) {
        data.content.forEach((item) => {
            let footerItems: ReactNode[] | null = null;
            const {
                dockedItems,
                panelTitle,
                panelTitleBackButton,
                editUrl,
                editUrlAccessible,
                editUrlTooltip,
                formActions,
                enableCustomFavourite,
                id,
            } = item.props;

            const {launchedFromActionLauncher, contentRequestUrl} =
                rendererParams;

            let childRendererParams = rendererParams;
            if (typeof formActions !== 'undefined') {
                childRendererParams = {
                    ...rendererParams,
                    parentFormActions: formActions,
                };
            }

            if (dockedItems && dockedItems.length > 0) {
                // Render the footer content with react

                footerItems = dockedItems.map((dockedItem, index) => {
                    setComponentKey(dockedItem, index);
                    return renderUIContent(dockedItem, childRendererParams);
                });
            }

            const slideoverContent = renderSlideoverContent(
                item,
                childRendererParams,
            );

            addSlideover({
                body: slideoverContent,
                header: {
                    title: panelTitle,
                    backButton: panelTitleBackButton,
                    editUrl: editUrl,
                    editUrlAccessible: editUrlAccessible,
                    editUrlTooltip: editUrlTooltip,
                    enableCustomFavourite: enableCustomFavourite,
                },
                footerItems,
                rendererParams: childRendererParams,
                id,
            });

            try {
                // exclude slideovers that don't have a url (i.e. spawned directly by FE components)
                if (contentRequestUrl) {
                    const route = removeSisUrlParameters(
                        contentRequestUrl || '',
                    );
                    const currentPageRoute = removeSisUrlParameters(
                        getCurrentPageFromUrl(),
                    );

                    logEventToGainsight('SlideoverOpened', {
                        launchedFromActionLauncher,
                        route: route,
                        currentPageRoute,
                    });
                }
            } catch (error) {
                console.error('Error logging SlideoverOpened event', error);
            }
        });
    } else {
        throw new Error(
            "The response doesn't have content for Slideovers, so we can't create any!",
        );
    }
}
