import {Button, IButtonProps} from 'Components/button/base';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type DownloadPDFFileButtonProps = IButtonProps & {
    pageUrl?: string;
};
export function DownloadPDFFileButton(props: DownloadPDFFileButtonProps) {
    const handleClick = () => {
        const {pageUrl} = props;

        if (pageUrl) {
            window.open(pageUrl);
        } else {
            throw new Error('No current action provided!');
        }
    };

    return (
        <WrappedButton
            {...props}
            className="arbor-download-pdf-file-button"
            onClick={handleClick}
        />
    );
}

DownloadPDFFileButton.defaultProps = {
    icon: 'file-pdf',
};
