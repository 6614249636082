import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import Box from 'Components/layout/Box';
import Container from 'Components/layout/container/Container';

import './purchasePanelGroupProperty.scss';

class PurchasePanelGroupProperty extends PureComponent {
    renderItem = (item) => (
        <Box className="arbor-purchase-panel-group-text">
            <span className="arbor-purchase-panel-property-label-text">
                {item}
            </span>
        </Box>
    );

    render() {
        const {label, price, status} = this.props;

        return (
            <Container
                className="arbor-purchase-panel-group-property"
                align="center"
                wrap="nowrap"
            >
                {this.renderItem(label)}
                {this.renderItem(price)}
                {this.renderItem(status)}
            </Container>
        );
    }
}

PurchasePanelGroupProperty.propTypes = {
    label: PropTypes.string,
    price: PropTypes.string,
    status: PropTypes.string,
};

export default PurchasePanelGroupProperty;
