export const imageUrlToBase64 = async (url: string) => {
    const blob = await fetch(url).then((r) => r.blob());

    const base64Encoded = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
        };
        reader.onerror = reject;
    });

    return base64Encoded;
};
