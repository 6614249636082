import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {updatePrice} from 'State/actionCreators/Basket';

import './checkout.scss';

// Only used in buying SMS credits
class CheckoutPriceCalculator extends Component {
    componentDidMount() {
        if (this.props.data.total) {
            this.props.dispatchUpdatePrice(this.props.data.total);
        }
    }

    render() {
        return (
            <div className="arbor-checkout__price-calculator">
                <div>
                    <b>{this.props.data.subTotalTitle}</b>:{' '}
                    <span>{this.props.data.subTotal}</span>
                </div>
                <div>
                    <b>{this.props.data.VATTitle}</b>:{' '}
                    <span> {this.props.data.VAT}</span>
                </div>
                <div>
                    <b>{this.props.data.totalTitle}</b>:{' '}
                    <span>
                        <b> {this.props.data.total}</b>
                    </span>
                </div>
            </div>
        );
    }
}

CheckoutPriceCalculator.propTypes = {
    data: PropTypes.object,
    dispatchUpdatePrice: PropTypes.func,
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchUpdatePrice: (price) => {
            dispatch(updatePrice(price));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CheckoutPriceCalculator);
