import {Component, ReactNode} from 'react';

import {initialiseFlatObjectFromTemplate, validateField} from './formUtils';

type FormContainerProps = {
    validationSchema?;
    initialValues;
    onValidSubmit?: (arg) => void;
    renderContents: (arg) => ReactNode;
    dataTestId?: string;
};

type FormContainerState = {
    formState;
    formErrors;
    hasAttemptedFormSubmit: boolean;
};

export class FormContainer extends Component<
    FormContainerProps,
    FormContainerState
> {
    // A component for constucting forms, handles field level validation.
    // It is responsible for storing the values and error messages for each field,
    // and for running the field level validation.
    //
    // Works in a similar way to Formik, but is much simpler. May be worth replacing with
    // Formik in the future if we need more advanced functionalities, to avoid re-inventing
    // the wheel.
    //
    // Limitations:
    // - Does not support dependant form fields.
    // - the value, errorText, onChange and hasAttemptedFormSubmit variables must be manually
    //   passed to each form field.

    constructor(props) {
        super(props);

        const {initialValues} = props;

        this.state = {
            formState: initialValues,
            formErrors: initialiseFlatObjectFromTemplate(initialValues, ''),
            hasAttemptedFormSubmit: false,
        };
    }

    validateAllFields = (formState) => {
        const validatedFields = Object.keys(formState).map((key) => {
            const field = formState[key];
            const {isFieldValid, errorMessage} = validateField(
                key,
                field,
                this.props.validationSchema,
            );
            return {
                name: key,
                isValid: isFieldValid,
                errorMessage,
            };
        });

        const isFormValid = validatedFields.reduce(
            (result, field) => field.isValid && result,
            true,
        );

        const errorObject = validatedFields.reduce(
            (result, field) => ({...result, [field.name]: field.errorMessage}),
            {},
        );

        this.setState({formErrors: errorObject});

        return isFormValid;
    };

    onFormFieldChange = (fieldName) => (e) => {
        const value = e.target && e.target.value;

        const {errorMessage} = validateField(
            fieldName,
            value,
            this.props.validationSchema,
        );

        this.setState((prevState) => ({
            formState: {
                ...prevState.formState,
                [fieldName]: value,
            },
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: errorMessage,
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const {formState, hasAttemptedFormSubmit} = this.state;
        const {onValidSubmit} = this.props;

        // Once the form has been submitted for the first time, force all error messages to be shown
        if (!hasAttemptedFormSubmit) {
            this.setState({hasAttemptedFormSubmit: true});
        }

        if (!this.validateAllFields(formState)) {
            return;
        }

        if (typeof onValidSubmit === 'function') {
            onValidSubmit(formState);
        }
    };

    render() {
        const {
            initialValues,
            validationSchema,
            onValidSubmit,
            renderContents,
            dataTestId,
            ...others
        } = this.props;

        const {formState, formErrors, hasAttemptedFormSubmit} = this.state;
        return (
            <form
                onSubmit={this.handleSubmit}
                {...others}
                data-testid={dataTestId}
            >
                {renderContents({
                    hasAttemptedFormSubmit,
                    formState,
                    formErrors,
                    onFormFieldChange: this.onFormFieldChange,
                })}
            </form>
        );
    }
}
