import classNames from 'classnames';
import {useRef} from 'react';

import {Button} from 'Components/button/base';
import {Icon} from 'Components/icon';
import {Pill, PillColor} from 'Components/pill/Pill';
import {useSimpleTooltip} from 'Components/tooltip';
import {ENTER_KEY_CODE, SPACE_KEY_CODE} from 'Root/core/utils/keyCodeConstants';
import {loadPageWithModifierKeys} from 'Root/core/utils/loadPageWithModifierKeys';

import {RightArrowIcon} from './RightArrowIcon';

import './card.scss';

type CardProps = {
    title?: string;
    subtitle?: string;
    paragraph?: string;
    help?: string;
    icon?: string;
    iconColor?: string;
    buttonText?: string;
    buttonFullWidth?: boolean;
    compact?: boolean;
    url?: string;
    disabled?: boolean;
    disabledTooltip?: string;
    pillText?: string;
    pillColor?: PillColor;
    onClick?: () => void;
};

export const Card = ({
    title,
    subtitle,
    paragraph,
    help,
    icon,
    iconColor,
    buttonText,
    buttonFullWidth,
    compact,
    url,
    onClick,
    disabled,
    disabledTooltip,
    pillText,
    pillColor,
}: CardProps) => {
    const iconRef = useRef<HTMLSpanElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    const {tooltipEventHandlers: iconTooltipEventHandlers} = useSimpleTooltip({
        tooltip: help,
        elementRef: iconRef,
        preferPosition: 'bottom',
    });

    let cardTooltipText: string | undefined;
    if (disabled) {
        cardTooltipText = disabledTooltip || 'This content is disabled';
    }
    const {tooltipEventHandlers: cardTooltipEventHandlers} = useSimpleTooltip({
        tooltip: cardTooltipText,
        elementRef: cardRef,
        preferPosition: 'bottom',
    });

    const handleClick = (e) => {
        if (url) {
            loadPageWithModifierKeys(url, e);
        }
        if (onClick) {
            onClick();
        }
    };

    const isCardClickable = (onClick || url) && !buttonText && !disabled;

    return (
        <div
            className={classNames('card', {
                'card--clickable': isCardClickable,
                'card--disabled': disabled,
                'card--compact': compact,
            })}
            onClick={(e) => {
                if (isCardClickable) {
                    handleClick(e);
                }
            }}
            onKeyDown={(e) => {
                if (
                    isCardClickable &&
                    (e.keyCode === ENTER_KEY_CODE ||
                        e.keyCode === SPACE_KEY_CODE)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClick(e);
                }
            }}
            data-testid="card-container"
            aria-label="Card"
            tabIndex={isCardClickable ? 0 : -1}
            ref={cardRef}
            {...cardTooltipEventHandlers}
        >
            <div className="card__content">
                {icon && (
                    <Icon
                        iconName={icon}
                        className="card__icon-left"
                        aria-label={icon + ' icon'}
                        color={iconColor}
                    />
                )}
                <div className="card__text">
                    {subtitle && <p className="card__subtitle">{subtitle}</p>}
                    {(title || help) && (
                        <span className="card__title-container">
                            {title && <h2 className="card__title">{title}</h2>}
                            {help && !disabled && (
                                <Icon
                                    iconName="help"
                                    setRef={iconRef}
                                    className="card__help-icon"
                                    aria-label="Card tooltip icon"
                                    {...iconTooltipEventHandlers}
                                ></Icon>
                            )}
                        </span>
                    )}
                    {paragraph && (
                        <p className="card__paragraph">{paragraph}</p>
                    )}
                </div>
                {isCardClickable && <RightArrowIcon />}
            </div>
            {buttonText && (
                <Button
                    disabled={disabled}
                    onClick={handleClick}
                    className={classNames('card__button', {
                        'card__button--full-width': buttonFullWidth,
                    })}
                    text={buttonText}
                    uiType="secondary"
                    v2
                />
            )}
            {pillText && <Pill text={pillText} color={pillColor} />}
        </div>
    );
};
