import classNames from 'classnames';
import {ReactNode} from 'react';

import {Icon} from 'Components/icon';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';

export interface IFormFieldLabelProps {
    label?: string;
    tooltip?: string;
    tooltipUrl?: string;
    children: ReactNode;
    disabled?: boolean;
    inlineLabel?: boolean;
    focusCallback: () => void;
    required?: boolean;
}

const IconWithTooltip = tooltipWrapper(Icon);

export const FormFieldLabel = ({
    focusCallback,
    disabled,
    label,
    tooltip,
    tooltipUrl,
    inlineLabel = true,
    required,
}: IFormFieldLabelProps) => {
    return (
        <label
            className={classNames('form-field-label', {
                'form-field-label--inline-label': inlineLabel,
                'form-field-label--disabled': disabled,
            })}
            onClick={focusCallback}
        >
            <span
                role="presentation"
                className={classNames('form-field-label-text', {
                    'form-field-label-text--required': required,
                })}
            >
                {label}
            </span>
            {((tooltip && tooltip !== '') ||
                (tooltipUrl && tooltipUrl !== '')) && (
                <div role="presentation" className="form-field-label-tooltip">
                    <IconWithTooltip
                        iconName="help"
                        className="form-field-label-tooltip-icon"
                        tooltip={tooltip}
                        tooltipUrl={tooltipUrl}
                        aria-label={`Info tooltip: ${tooltip}`}
                    ></IconWithTooltip>
                </div>
            )}
        </label>
    );
};
