import {ReactNode, useRef, useState} from 'react';

import {DropdownMenu} from 'Components/dropdown';
import {renderUIContent} from 'Renderer/Helper';
import {RendererParams} from 'Renderer/Renderer';
import {getSectionRegistryService} from 'Root/core/services/sectionSelector/SectionRegistry';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';

import Row from './row/Row';
import {ReactSectionView, TreeData} from './types';

import './section.scss';

type SectionProps = {
    children?: ReactNode;
    title: string;
    reactSectionViews?: ReactSectionView[];
    rendererParams?: RendererParams;
    treeData?: TreeData;
};

export const Section = (props: SectionProps) => {
    const {
        children = [],
        title,
        reactSectionViews,
        rendererParams = {elementToRefocus: null, refocusCallback: null},
        treeData,
    } = props;

    const [isVisible, setIsVisible] = useState(true);
    const [selectedViewId, setSelectedViewId] = useState(
        reactSectionViews?.[0].id || null,
    );

    const containerRef = useRef<HTMLElement>(null);

    const isEmpty =
        !treeData &&
        (!children || (Array.isArray(children) && children.length === 0));

    useComponentDidMount(() => {
        if (containerRef.current) {
            const id = getSectionRegistryService().registerSection({
                show: () => setIsVisible(true),
                hide: () => setIsVisible(false),
                title,
                container: containerRef.current,
            });

            return () => {
                getSectionRegistryService().deregisterSection(id);
            };
        }

        return () => {
            // just making the linter happy
        };
    });

    const renderSelectedSectionView = () => {
        if (!selectedViewId) {
            return null;
        }
        const selectedView = reactSectionViews?.find(
            (view) => view.id === selectedViewId,
        );
        if (!selectedView) {
            return null;
        }
        if (typeof selectedView.children === 'string') {
            return (
                <div className="section__section-view">
                    {selectedView.children}
                </div>
            );
        }
        return selectedView.children.map((viewChild) => {
            return (
                <div className="section__section-view">
                    {renderUIContent(viewChild, rendererParams)}
                </div>
            );
        });
    };

    if (isVisible) {
        return (
            <section ref={containerRef} className="section__container">
                <h2 className="section__header">{title}</h2>
                {reactSectionViews?.length && (
                    <div className="section__section-view-selector-container">
                        <DropdownMenu
                            fieldClassName="section__section-view-selector"
                            color="light-grey"
                            label={
                                reactSectionViews.find(
                                    (view) => view.id === selectedViewId,
                                )?.title || ''
                            }
                            items={reactSectionViews?.map((view) => ({
                                title: view.title,
                                onClick: () => setSelectedViewId(view.id),
                            }))}
                        />
                    </div>
                )}

                <div className="section__contents">
                    {renderSelectedSectionView()}
                    {isEmpty ? (
                        <div className="section__placeholder">
                            No results found
                        </div>
                    ) : (
                        children
                    )}
                    {treeData && (
                        <ul className="section__row-data-container" role="tree">
                            {treeData.children.map((child) => {
                                return (
                                    <Row
                                        key={child.fields.id.value}
                                        rowData={child}
                                    />
                                );
                            })}
                        </ul>
                    )}
                </div>
            </section>
        );
    }

    return null;
};
