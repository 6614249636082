import {JSXElementConstructor, ReactElement} from 'react';

import './Layout.scss';
type LayoutColumnProps = {
    children:
        | ReactElement<any, string | JSXElementConstructor<any>>[]
        | ReactElement<any, string | JSXElementConstructor<any>>;
};

export const LayoutRow = ({children}: LayoutColumnProps) => {
    return (
        <div className="layout-row">
            {Array.isArray(children) ? (
                children.map((child) => (
                    <div
                        style={{flex: child.props.flex}}
                        className="row-element"
                    >
                        {child}
                    </div>
                ))
            ) : (
                <div>{children}</div>
            )}
        </div>
    );
};
