export type NewKpiBoxResponseBar = {
    color: string;
    label: string;
    name: string;
    value: number | string;
};
export type NewKpiBoxBar = {
    color: string;
    label: string;
    name: string;
    value: number;
};

export type NewKpiBoxTrend = {
    color: string;
    comparisonLabel: string;
    icon: string;
    valueLabel: string;
};

export type NewKpiResponseItem = {
    bars?: NewKpiBoxResponseBar[];
    mainValue: string;
    mainValueColor: string;
    mainValueUnits?: string | null;
    max: number | null;
    midPoint: number | null;
    min: number | null;
    segments?: boolean;
    numberOfSegments?: number;
    subTitle: string;
    title: string;
    trend?: NewKpiBoxTrend;
    unbounded?: boolean;
    tooltip?: string;
    tooltipUrl?: string;
    tooltipHTML?: string;
    url?: string;
    description?: string;
};

export type NewKpiData = Omit<NewKpiResponseItem, 'max' | 'min' | 'bars'> & {
    max: number;
    min: number;
    bars: NewKpiBoxBar[];
};

export type OldKpiData = {
    title?: string;
    tooltip?: string;
    tooltipUrl?: string;
    tooltipHTML?: string;
    html: string;
    url?: string;
};

export function isOldKpiData(
    data: OldKpiData | NewKpiData | null,
): data is OldKpiData {
    return data !== null && 'html' in data;
}
