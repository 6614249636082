import {logEventToGainsight} from 'Root/core/services/gainsight';
import {getCurrentPageFromUrl} from 'Root/core/services/url/getCurrentPageFromUrl';
import url from 'Services/url/Url';

import {SideBarButton} from '../sideBarButton';

import {TrainingHubIcon} from './TrainingHubIcon';

const openTrainingHub = () => window.open(url.ARBOR.TRAINING_HUB, '_blank');

export const TrainingHubButton = () => (
    <SideBarButton
        buttonText="Training Hub"
        tooltip="Recordings, resources and live sessions"
        onClick={() => {
            openTrainingHub();
            logEventToGainsight('TrainingHubButtonClicked', {
                clickedFrom: getCurrentPageFromUrl(),
            });
        }}
    >
        <TrainingHubIcon />
    </SideBarButton>
);
