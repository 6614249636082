import {PropertyRow, PropertyRowProps} from './PropertyRow';

type PropertyRowsProps = {
    rows: PropertyRowProps[];
};

export const PropertyRows = (props: PropertyRowsProps) => {
    const {rows} = props;

    return (
        <ul className="property-rows__container">
            {rows.map((rowProps, i) => (
                <PropertyRow {...rowProps} key={`PropertyRow_${i}`} />
            ))}
        </ul>
    );
};
