import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Icon} from 'Components/icon';
import {clearBasket} from 'State/actionCreators/Basket';
import {selectSelectedIds} from 'State/selectors/Basket';

import BasketProperties from './BasketProperties';

import './basket.scss';

class BasketPanel extends Component {
    componentWillUnmount = () => {
        this.props.clearBasket();
    };

    render() {
        const {title, children, selectedIds, checkoutUrl, currencyCode} =
            this.props;
        return (
            <div className="basket-panel">
                <div className="basket-header">
                    <Icon iconName="cart" className="header-icon" />
                    <div className="header-title">{title}</div>
                </div>
                <div className="basket-body">
                    {children}
                    {selectedIds.length === 0 ? (
                        <div className="empty-basket">
                            {_t('Your basket is currently empty')}
                        </div>
                    ) : (
                        <BasketProperties
                            checkoutUrl={checkoutUrl}
                            currencyCode={currencyCode}
                        />
                    )}
                    {this.renderAwaitingPayments()}
                </div>
            </div>
        );
    }

    renderAwaitingPayments() {
        let basketAPProperties = this.props.basketAPProperties;
        if (basketAPProperties && basketAPProperties.length > 0) {
            return (
                <div className="basket-ap">
                    <div className="title">{_t('Awaiting payment for:')}</div>
                    <ul>
                        {basketAPProperties.map((basketAPProperty, i) => (
                            <li
                                className="basket-app-property"
                                key={i}
                                onClick={() =>
                                    window.open(basketAPProperty.url)
                                }
                            >
                                <span className="basket-app-property-label">
                                    {basketAPProperty.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    }
}

BasketPanel.propTypes = {
    children: PropTypes.node,
    basketAPProperties: PropTypes.array,
    selectedIds: PropTypes.array,
    checkoutUrl: PropTypes.string,
    title: PropTypes.string,
    currencyCode: PropTypes.string,
    clearBasket: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
    };
};

export default connect(mapStateToProps, {clearBasket})(BasketPanel);
