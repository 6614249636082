import {Button, IButtonProps} from 'Components/button/base';
import {downloadFile} from 'Interfaces/Loader';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type DownloadFileButtonProps = IButtonProps & {
    pageUrl?: string;
};
export function DownloadFileButton(props: DownloadFileButtonProps) {
    const {pageUrl} = props;

    const onClickHandler = () => {
        if (pageUrl) {
            downloadFile(pageUrl);
        } else {
            throw new Error('No url provided!');
        }
    };

    return (
        <WrappedButton
            {...props}
            className="arbor-download-file-button"
            onClick={onClickHandler}
        />
    );
}
