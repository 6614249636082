import classNames from 'classnames';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Button} from 'Components/button/base';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {RendererParams} from 'Renderer/Renderer';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';
import {useIsMounted} from 'Root/core/utils/useIsMounted';
import helper from 'Utils/helper';
import {parseUnsafeInt} from 'Utils/parseUnsafeInt';
import {removeAllTags, sanitizeChatPanelMessage} from 'Utils/sanitizeHtml';

import {getHistoricMessages} from './getHistoricMessages';
import {getNewAgentMessage} from './getNewAgentMessage';
import {LoadingWidget} from './LoadingWidget';
import {RatingThumbs} from './RatingThumbs';

import './chatPanel.scss';

export type Message = {
    text: string;
    type: 'user' | 'agent';
    id: string;
};

export type LastInputType = 'NONE' | 'KEYBOARD' | 'VOICE' | 'MIXED';

type ChatPanelProps = {
    title?: string;
    ratingUrl?: string;
    chatUrl: string;
    newChatUrl?: string;
    chatDashboardUrl?: string;
    logUrl?: string;
    historicChatUrl?: string;
    lockConversation?: boolean;
    messageLimit?: string | number;
    chatId?: string;
    placeholder?: string;
    rendererParams?: RendererParams;
    samplePrompts?: string[];
    speechEnabled?: boolean;
    speechLanguage?: string;
    speechSubmissionDelay?: number;
};

export const CHATBOT_INPUT_TYPE_NONE = 'NONE';
export const CHATBOT_INPUT_TYPE_KEYBOARD = 'KEYBOARD';
export const CHATBOT_INPUT_TYPE_VOICE = 'VOICE';
export const CHATBOT_INPUT_TYPE_MIXED = 'MIXED';

export const ChatPanel = ({
    title,
    ratingUrl,
    chatUrl,
    chatDashboardUrl,
    logUrl,
    newChatUrl,
    lockConversation = false,
    messageLimit: unsafeMessageLimit,
    placeholder = 'Ask a question...',
    chatId: chatIdFromProps,
    historicChatUrl,
    rendererParams,
    samplePrompts,
    speechEnabled = true,
    speechLanguage = 'en-GB',
    speechSubmissionDelay = 1500,
}: ChatPanelProps) => {
    const _isMounted = useIsMounted();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const speechSilenceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const speechRecognitionRef = useRef<any>(null);

    const [inputValue, setInputValue] = useState<string>('');
    const [chatId, setChatId] = useState<undefined | string>(chatIdFromProps);
    const [messages, setMessages] = useState<Message[]>([]);
    const addMessage = (message: Message) =>
        setMessages((prevMessages) => [...prevMessages, message]);
    const [isPending, setIsPending] = useState(false);
    const [isHistoricDataLoading, setIsHistoricalDataLoading] = useState(false);
    const [hasHistoricalDataLoaded, setHasHistoricalDataLoaded] =
        useState(false);
    const [userInputDeviceLastUsed, setUserInputDeviceLastUsed] =
        useState<LastInputType>(CHATBOT_INPUT_TYPE_NONE); // This is used for telemetry purposes. By default, we assume the user hasn't interacted with the chat panel. This can happen when the user uses the default prompts, or just hits the "Enter" key.
    const [speechEngaged, setSpeechEngaged] = useState<boolean>(false);
    const [allowDebouncedRequest, setAllowDebouncedRequest] =
        useState<boolean>(true); // Used to disable the debounced request if the user interrupts with a keypress. This is required as  debouncedHandleResult.cancel() isn't quick enough

    const messageLimit = parseUnsafeInt(unsafeMessageLimit, 0);
    const hasReachedMessageLimit =
        messageLimit > 0 && messages.length >= messageLimit;

    useComponentDidMount(() => {
        if (rendererParams?.type === 'slideover') {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    });

    useEffect(() => {
        if (!_isMounted) {
            return;
        }
        if (!historicChatUrl) {
            return;
        }
        if (hasHistoricalDataLoaded) {
            return;
        }
        const fetchHistoricMessages = async () => {
            setIsHistoricalDataLoading(true);
            const historicMessages = await getHistoricMessages(
                `${historicChatUrl}/${chatIdFromProps}`,
            );

            if (!_isMounted) {
                return;
            }
            setMessages((prevMessages) => [
                ...prevMessages,
                ...historicMessages,
            ]);
            setIsHistoricalDataLoading(false);
            setHasHistoricalDataLoaded(true);
        };
        fetchHistoricMessages();
    }, [chatIdFromProps, historicChatUrl, _isMounted, hasHistoricalDataLoaded]);

    // Always scroll to the bottom when a new message appears
    useLayoutEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTo({
                top: listRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);

    // Fetch a new agent message
    const fetchNewAgentMessage = async (userMessage: string) => {
        setIsPending(true);
        const message = await getNewAgentMessage({
            chatId,
            chatUrl,
            userMessage,
            userInputDeviceLastUsed,
        });
        if (message) {
            addMessage({
                text: sanitizeChatPanelMessage(message.text),
                type: 'agent',
                id: message.id,
            });
            if (message.chatId) {
                setChatId(message.chatId);
            }
            inputRef.current?.focus();
        }
        setIsPending(false);
    };

    const submitNewMessage = (prompt: string | null = null) => {
        let agentMessage = prompt;
        if (agentMessage === null) {
            agentMessage = removeAllTags(inputValue);
        }
        addMessage({
            type: 'user',
            text: agentMessage,
            id: helper.getUid(),
        });
        fetchNewAgentMessage(agentMessage);
        setInputValue('');
        setUserInputDeviceLastUsed(CHATBOT_INPUT_TYPE_NONE); // Start again
    };

    const stopSpeechRecognition = () => {
        if (speechRecognitionRef.current) {
            speechRecognitionRef.current.stop();
            speechRecognitionRef.current = null;
        }
        setSpeechEngaged(false);
        if (speechSilenceTimeoutRef.current) {
            clearTimeout(speechSilenceTimeoutRef.current);
            speechSilenceTimeoutRef.current = null;
        }
    };

    const debouncedHandleResult = useDebouncedCallback((transcript: string) => {
        stopSpeechRecognition();
        if (transcript.length > 0 && allowDebouncedRequest) {
            submitNewMessage();
        }
    }, speechSubmissionDelay);

    const determineAndSetUserInputDeviceLastUsed = (
        userInputType: LastInputType,
    ) => {
        // First input, set the state and return
        if (userInputDeviceLastUsed === CHATBOT_INPUT_TYPE_NONE) {
            setUserInputDeviceLastUsed(userInputType);
            return;
        }
        // More than one input device has been used, must be "MIXED" then ("NONE" is not an option at this stage)
        if (userInputDeviceLastUsed !== userInputType) {
            setUserInputDeviceLastUsed(CHATBOT_INPUT_TYPE_MIXED);
            return;
        }
        // The same input device is being used. No need to change the state.
        if (userInputDeviceLastUsed === userInputType) {
            return;
        }
    };

    const startSpeechRecognition = () => {
        setSpeechEngaged(true);
        determineAndSetUserInputDeviceLastUsed(CHATBOT_INPUT_TYPE_VOICE);
        setAllowDebouncedRequest(true);
        const recognition = new (window as any).webkitSpeechRecognition();
        speechRecognitionRef.current = recognition;
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = speechLanguage;
        recognition.start();

        recognition.onresult = (event: any) => {
            const transcript = Array.from(event.results)
                .map((result: any) => result[0])
                .map((result: any) => result.transcript)
                .join('');
            setInputValue(transcript);
            debouncedHandleResult(transcript); // I've used debounce here instead of recognition.onspeechend as it was too temperamental and didn't trigger the callback until a while after the user had stopped speaking. It contributed to poor UX as the user was waiting too long
        };

        recognition.onerror = () => {
            stopSpeechRecognition();
        };
    };

    const toggleSpeechEngaged = () => {
        if (speechEngaged) {
            stopSpeechRecognition(); // Stop speech recognition if it's already engaged
        } else {
            startSpeechRecognition();
        }
    };

    // Browser detection for Firefox. Speech recognition is not supported in Firefox.
    const browserIsFirefox =
        typeof navigator !== 'undefined' &&
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    const canSubmit =
        !isHistoricDataLoading && !isPending && inputValue.length > 0;
    return (
        <section aria-label={`Chat Panel: ${title}`} className="chat-panel">
            {title && (
                <section className="chat-panel__header">
                    <h2>{title}</h2>
                </section>
            )}
            <div className="chat-panel__message-list-wrapper">
                <ul className="chat-panel-message-list" ref={listRef}>
                    {!isHistoricDataLoading && messages.length === 0 && (
                        <span>
                            {lockConversation
                                ? 'This conversation is locked but has no messages'
                                : placeholder}
                        </span>
                    )}
                    {messages.map((message, index) => (
                        <li
                            className={classNames('chat-panel-message', {
                                'chat-panel-message--user':
                                    message.type === 'user',
                                'chat-panel-message--agent':
                                    message.type === 'agent',
                            })}
                            key={`${message.id}_${index}`}
                        >
                            <span
                                dangerouslySetInnerHTML={{__html: message.text}}
                            ></span>
                            {ratingUrl &&
                                chatId &&
                                message.type === 'agent' && (
                                    <RatingThumbs
                                        message={message}
                                        ratingUrl={ratingUrl}
                                        chatId={chatId}
                                    />
                                )}
                        </li>
                    ))}
                    {(isPending || isHistoricDataLoading) && <LoadingWidget />}
                </ul>
                {messageLimit > 0 && messages.length > 0 && (
                    <div className="chat-panel__message-limit">
                        {messages.length} / {messageLimit}
                    </div>
                )}
            </div>
            {messages.length === 0 && samplePrompts && (
                <div className="chat-panel__sample-prompt-container">
                    {samplePrompts.map((prompt, index) => (
                        <button
                            key={index}
                            className="chat-panel__sample-prompt"
                            onClick={() => submitNewMessage(prompt)}
                        >
                            {prompt}
                        </button>
                    ))}
                </div>
            )}
            {!lockConversation && !hasReachedMessageLimit && (
                <div className="chat-panel-footer">
                    <textarea
                        className="chat-panel__input"
                        ref={inputRef}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                        aria-label="Chat panel input"
                        onKeyDown={(e) => {
                            if (e.key !== 'Enter') {
                                // Set the usage
                                determineAndSetUserInputDeviceLastUsed(
                                    CHATBOT_INPUT_TYPE_KEYBOARD,
                                );
                                if (speechEngaged) {
                                    // If the user interjects with the keyboard immediately after speaking or during their speech, then cancel automatic message submission (via the debounce)
                                    setAllowDebouncedRequest(false);
                                }
                            }
                            if (e.key === 'Enter' && !e.shiftKey) {
                                // Submit on enter, but still allow shift enter to make newlines
                                e.preventDefault();
                                if (canSubmit) {
                                    submitNewMessage();
                                }
                            }
                        }}
                    />
                    {speechEnabled &&
                    inputValue.length === 0 &&
                    !browserIsFirefox ? (
                        <Button
                            onClick={() => toggleSpeechEngaged()}
                            color="green"
                            icon="comment"
                            ariaLabel="speak with agent"
                            tooltipHTML="Click here to speak to Arbor."
                            disabled={speechEngaged}
                        ></Button>
                    ) : (
                        <Button
                            disabled={!canSubmit}
                            onClick={() => submitNewMessage()}
                            askArborLogo
                            color="green"
                            ariaLabel="submit message"
                            tooltipHTML="You can submit the message using the shortcut <b>enter</b>, and create new lines with <b>shift enter</b>."
                        ></Button>
                    )}
                </div>
            )}
            {hasReachedMessageLimit && (
                <div className="chat-panel-footer">
                    You've reached the maximum number of messages for this
                    conversation
                </div>
            )}
            {((chatId && logUrl) || newChatUrl) && (
                <div className="chat-panel-footer">
                    {chatId && logUrl && (
                        <KeyboardFocusableLink
                            url={`${logUrl}/chat-id/${chatId}`}
                        >
                            See message log
                        </KeyboardFocusableLink>
                    )}
                    {newChatUrl && (
                        <KeyboardFocusableLink
                            linkWrapperClassName={classNames({
                                'chat-panel-link--active':
                                    hasReachedMessageLimit,
                            })}
                            url={newChatUrl}
                        >
                            New chat
                        </KeyboardFocusableLink>
                    )}
                    {chatDashboardUrl && (
                        <KeyboardFocusableLink url={chatDashboardUrl}>
                            My Ask Arbor
                        </KeyboardFocusableLink>
                    )}
                </div>
            )}
        </section>
    );
};
