import {DESKTOP} from 'Utils/screenType';

import {SET_SCREEN_TYPE} from './constants';

const initialState = {
    screenType: DESKTOP,
};

export const screenTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCREEN_TYPE:
            return {
                ...state,
                screenType: action.screenType,
            };

        default:
            return state;
    }
};
