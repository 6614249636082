import Pubsub from 'Root/core/services/pubsub/Pubsub';
import {FloatingElementDirection} from 'Root/core/utils/getFloatingElementPosition';

type UseSimpleTooltipProps = {
    title?: string;
    tooltip?: string;
    elementRef?: React.RefObject<HTMLElement>;
    preferPosition?: FloatingElementDirection;
};

export const useSimpleTooltip = ({
    title,
    tooltip,
    elementRef,
    preferPosition,
}: UseSimpleTooltipProps) => {
    const showTooltip = () => {
        if (typeof title !== 'undefined' || typeof tooltip !== 'undefined') {
            Pubsub.publish('showSimpleTooltip', {
                title,
                tooltip,
                elementRef,
                preferPosition,
            });
        }
    };
    const hideTooltip = () => {
        Pubsub.publish('hideSimpleTooltip');
    };
    const onMouseEnter: React.MouseEventHandler<Element> = () => {
        showTooltip();
    };
    const onMouseLeave: React.MouseEventHandler<Element> = () => {
        hideTooltip();
    };
    const onFocus: React.FocusEventHandler<Element> = () => {
        showTooltip();
    };
    const onBlur: React.FocusEventHandler<Element> = () => {
        hideTooltip();
    };
    const tooltipOnClick: React.MouseEventHandler<Element> = () => {
        hideTooltip();
    };
    return {
        tooltipEventHandlers: {
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onBlur,
        },
        tooltipOnClick,
    };
};
