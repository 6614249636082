import classNames from 'classnames';
import {useState} from 'react';

import {Button} from 'BackendRenderedComponents/button';
import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {Icon} from 'Components/icon';
import {httpPost} from 'Interfaces/httpClient';
import {downloadFile} from 'Interfaces/Loader';

export const AttachedFile = ({
    file,
    lastElement,
    fileController,
    onActionClicked,
    deleteAction,
    downloadAction,
    module,
}) => {
    const [showConfirmtationModal, setShowConfirmtationModal] = useState(false);

    /* eslint-disable camelcase */
    const {mimeType, mimeTypeDescription, fileName, fileSize, file_id} =
        file.fields;
    const fileId = file_id.value;
    const items = [{fields: {file_id: {value: fileId}}}];
    /* eslint-enable camelcase */

    const deleteUrl = `${module}/${fileController}/${deleteAction}`;
    const downloadUrl = downloadAction
        ? `${module}/${fileController}/${downloadAction}/content-id/${fileId}`
        : file.actions.file.url;

    const deleteAttachment = async () => {
        await httpPost(deleteUrl, {items});
        setShowConfirmtationModal(false);
        onActionClicked();
    };

    const downloadAttachment = () => {
        downloadFile(downloadUrl);
        onActionClicked();
    };
    return (
        <div
            className={classNames('attached-file', {
                'attached-file-last-element': lastElement,
            })}
        >
            <div className="attached-file-icon">
                <img src={`/img/${mimeType.value}.png`} />
            </div>
            <div className="attached-file-details">
                <span className="attached-file-name">{fileName.value}</span>
                <span className="attached-file-details">
                    {mimeTypeDescription.value}, {fileSize.value}
                </span>
            </div>
            <div className="attachment-action-icons">
                <div>
                    {(downloadAction || file.actions.file.url) && (
                        <Icon
                            data-testid="AttachmentsWidget-downloadIcon"
                            iconName={'download'}
                            onClick={downloadAttachment}
                        />
                    )}
                </div>
                <div>
                    {deleteAction && (
                        <Icon
                            data-testid="AttachmentsWidget-deleteIcon"
                            iconName={'delete'}
                            onClick={() => setShowConfirmtationModal(true)}
                        />
                    )}
                </div>
            </div>
            {showConfirmtationModal && (
                <FocusTrappingModal
                    title="Delete File?"
                    onClose={() => setShowConfirmtationModal(false)}
                    hideFullScreenButton
                >
                    <div className="attachment-delete-confirmation-modal-content">
                        Are you sure you want to delete the file?
                        <br />
                        <img src={`/img/${mimeType.value}.png`} />{' '}
                        {fileName.value}
                    </div>
                    <div className="attachment-delete-confirmation-modal-button-container">
                        <Button
                            text={'Cancel'}
                            onClick={() => setShowConfirmtationModal(false)}
                            uiType="primary"
                        />
                        <Button
                            text={'Delete'}
                            onClick={deleteAttachment}
                            color="green"
                            uiType="primary"
                        />
                    </div>
                </FocusTrappingModal>
            )}
        </div>
    );
};
