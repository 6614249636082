import PropTypes from 'prop-types';

export const DESKTOP = 'screenType::desktop';
export const TABLET = 'screenType::tablet';
export const TABLET_PORTRAIT = 'screenType::tablet_portrait';
export const MOBILE = 'screenType::mobile';

export const screenTypePropType = PropTypes.oneOf([
    DESKTOP,
    TABLET,
    TABLET_PORTRAIT,
    MOBILE,
]);

export type ScreenType =
    | 'screenType::desktop'
    | 'screenType::tablet'
    | 'screenType::tablet_portrait'
    | 'screenType::mobile';
