import classNames from 'classnames';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {loadPage} from 'Interfaces/Loader';

import './propertyRows.scss';

export type PropertyRowProps = {
    url?: string;
    value?: string;
    disabled?: boolean;
};

export const PropertyRow = (props: PropertyRowProps) => {
    const {url, value, disabled} = props;

    return (
        <li
            className={classNames('property-row__container', {
                'property-row__container--disabled': disabled,
            })}
        >
            <KeyboardFocusableButton
                buttonWrapperClassName="property-row__button-wrapper"
                className="property-row__inner"
                onClick={() => {
                    if (!disabled) {
                        loadPage(url);
                    }
                }}
                disabled={disabled}
                border-radius="inherit"
            >
                <span className="property-row__value">{value}</span>
                <Icon className="property-row__icon" iconName="right" />
            </KeyboardFocusableButton>
        </li>
    );
};
