import {lazy, Suspense} from 'react';

import type {LookerStudioBIDashboardProps} from './types';

const LookerStudioBIDashboard = lazy(
    () => import('./LookerStudioBIDashboardAsDefaultExport'),
);

export const LazyLoadedLookerStudioBIDashboard = (
    props: LookerStudioBIDashboardProps,
) => {
    return (
        <Suspense fallback={<div> Loading data </div>}>
            <LookerStudioBIDashboard {...props} />
        </Suspense>
    );
};
