import {Button, IButtonProps} from 'Components/button/base';
import {SYSTEM_NOTIFICATION} from 'Config/Events';
import pubsub from 'Services/pubsub/Pubsub';
import buttonWrapperExt from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapperExt(Button);

type OpenInNewTabButtonProps = IButtonProps & {
    systemNotification: string;
};
export function SystemNotificationButton(props: OpenInNewTabButtonProps) {
    const {systemNotification, ...rest} = props;

    const handleClick = () => {
        pubsub.publish(SYSTEM_NOTIFICATION.ADD, JSON.parse(systemNotification));
    };

    return (
        <WrappedButton
            className="arbor-system-notification-button"
            {...rest}
            onClick={handleClick}
        />
    );
}
