import {KpiPanel} from 'Components/kpiPanel';

import {useKpiData} from './useKpiData';

type KpiPanelWithDataFetchingProps = {
    collapsible?: boolean;
    collapsed?: boolean;
    url: string;
    title?: string;
    iconCls?: string;
    kpiCount?: number;
    preview?: boolean;
};

export const KpiPanelWithDataFetching = ({
    iconCls,
    collapsible,
    collapsed,
    kpiCount,
    title,
    preview,
    url,
}: KpiPanelWithDataFetchingProps) => {
    const {items, isLoading} = useKpiData(url);

    return (
        <KpiPanel
            title={title}
            iconCls={iconCls}
            kpiCount={kpiCount}
            items={items}
            isLoading={isLoading || !items?.length}
            isPreviewMaskVisible={preview}
            isCollapsible={collapsible}
            isInitiallyCollapsed={collapsed}
        />
    );
};
