import {connect} from 'react-redux';

import {
    EmergencyAlertButton,
    HomeButton,
    NotificationButton,
    SignOutButton,
    FavouritesButton,
    CalendarButton,
    HelpDropdownButton,
} from 'App/header/buttons';
import {selectIsSubNavHiddenByUser} from 'State/navigation/selectors';

import './sideBar.scss';

type SideBarProps = {
    isSubNavHiddenByUser: ReturnType<typeof selectIsSubNavHiddenByUser>;
};

const UnconnectedSideBar = ({isSubNavHiddenByUser}: SideBarProps) => {
    const currentPage = window.location.search;

    return (
        <>
            {(!isSubNavHiddenByUser ||
                currentPage.includes('/home-ui/index')) && (
                <div className="side-bar">
                    <HomeButton tooltip="Go to homepage" />
                    <FavouritesButton isDesktop />
                    <NotificationButton isDesktop />
                    <CalendarButton isSideBar />
                    <EmergencyAlertButton />
                    <HelpDropdownButton />
                    <SignOutButton />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    isSubNavHiddenByUser: selectIsSubNavHiddenByUser(state),
});

export const SideBar = connect(mapStateToProps)(UnconnectedSideBar);
