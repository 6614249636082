import {Component} from 'react';

import {Button, IButtonProps} from 'Components/button/base';
import Loader from 'Services/Loader';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type OpenInNewTabButtonProps = IButtonProps & {
    pageUrl?: string;
};
export class OpenInNewTabButton extends Component<OpenInNewTabButtonProps> {
    handleClick = () => {
        const {pageUrl} = this.props;

        if (pageUrl) {
            Loader.openInNewTab(pageUrl);
        } else {
            throw new Error('No url provided!');
        }
    };

    render() {
        return (
            <WrappedButton
                {...this.props}
                className="arbor-load-page-button"
                onClick={this.handleClick}
            />
        );
    }
}
