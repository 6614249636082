import {getUser} from 'Interfaces/Session';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentHelpCentreUrl} from 'Services/url/Url';

import {SideBarButton} from '../sideBarButton';

import {HelpCentreIcon} from './HelpCentreIcon';

export const HelpCentreButton = () => {
    const openHelp = () => {
        const {helpCentreUrl} = getUser() || {};
        const pageHelpCentreUrl = getCurrentHelpCentreUrl();
        const urlToOpen = pageHelpCentreUrl ?? helpCentreUrl;
        logEventToGainsight('HelpCentreButtonClicked', {
            url: urlToOpen,
        });
        window.open(urlToOpen, '_blank');
    };

    return (
        <SideBarButton
            tooltip="Articles based on your current page"
            buttonText="Help Centre"
            onClick={openHelp}
        >
            <HelpCentreIcon />
        </SideBarButton>
    );
};
