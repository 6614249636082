import {httpPost} from 'Interfaces/httpClient';

export const sendMessageRating = async ({
    rating,
    messageId,
    chatId,
    ratingUrl,
}: {
    rating: 'up' | 'down';
    messageId: string;
    chatId: string;
    ratingUrl: string;
}) => {
    try {
        await httpPost(ratingUrl, {
            messageId: messageId,
            rating,
            chatId,
        });
        return;
    } catch (e) {
        console.error('Error rating message', e);
        return;
    }
};
