import classNames from 'classnames';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';
import {setSubNavHiddenByUserAction} from 'State/navigation/actions';

const KeyboardFocusableButtonWithTooltip = tooltipWrapper(
    KeyboardFocusableButton,
);

const CollapseSideNavButton = () => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.75"
            y="0.75"
            width="14.5"
            height="9.92857"
            rx="2.25"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path
            className={'side-nav-button-arrow-icon-path'}
            d="M8.46498 4.95304H5.35468L5.89678 4.35198C6.02438 4.20872 6.09607 4.0144 6.09607 3.81179C6.09607 3.60918 6.02438 3.41487 5.89678 3.2716C5.76918 3.12834 5.59612 3.04785 5.41567 3.04785C5.23522 3.04785 5.06215 3.12834 4.93456 3.2716L3.2405 5.17368C3.1788 5.24604 3.13045 5.33136 3.09819 5.42475C3.03042 5.60999 3.03042 5.81775 3.09819 6.00298C3.13045 6.09638 3.1788 6.1817 3.2405 6.25406L4.93456 8.15613C4.99755 8.22744 5.07249 8.28404 5.15507 8.32267C5.23764 8.3613 5.32621 8.38118 5.41567 8.38118C5.50512 8.38118 5.59369 8.3613 5.67627 8.32267C5.75884 8.28404 5.83379 8.22744 5.89678 8.15613C5.96029 8.0854 6.01071 8.00125 6.04511 7.90854C6.07951 7.81583 6.09722 7.71638 6.09722 7.61594C6.09722 7.5155 6.07951 7.41606 6.04511 7.32335C6.01071 7.23063 5.96029 7.14648 5.89678 7.07575L5.35468 6.4747H8.46498C8.64469 6.4747 8.81705 6.39454 8.94413 6.25186C9.07121 6.10917 9.1426 5.91565 9.1426 5.71387C9.1426 5.51208 9.07121 5.31856 8.94413 5.17588C8.81705 5.0332 8.64469 4.95304 8.46498 4.95304Z"
        />
        <path
            className={'side-nav-button-line-path'}
            d="M11.4287 0.761719V10.6665"
            strokeWidth="1.5"
        />
    </svg>
);

type ToggleSideNavButtonProps = {
    isSubNavHiddenByUser: boolean;
    setSubNavHiddenByUser: typeof setSubNavHiddenByUserAction;
};

export const UnconnectedToggleSideNavButton = ({
    isSubNavHiddenByUser,
    setSubNavHiddenByUser,
}: ToggleSideNavButtonProps) => {
    return (
        <KeyboardFocusableButtonWithTooltip
            borderRadius="5px"
            className={classNames('toggle-side-nav-button', {
                'toggle-side-nav-button--collapsed': isSubNavHiddenByUser,
            })}
            onClick={() => {
                setSubNavHiddenByUser({isHidden: !isSubNavHiddenByUser});
            }}
            tooltip={isSubNavHiddenByUser ? 'Show sidebar' : 'Hide sidebar'}
            aria-label={
                isSubNavHiddenByUser
                    ? 'Show side navigation'
                    : 'Hide side navigation'
            }
        >
            <CollapseSideNavButton />
        </KeyboardFocusableButtonWithTooltip>
    );
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setSubNavHiddenByUser: setSubNavHiddenByUserAction,
        },
        dispatch,
    );

export const ToggleSideNavButton = connect(
    null,
    mapDispatchToProps,
)(UnconnectedToggleSideNavButton);
