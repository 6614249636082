import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {selectIsBurgerMenuVisible} from 'State/burgerMenu';
import {updateNavigationAction} from 'State/navigation/actions';
import {getNavigationDataService} from 'State/navigation/api/getNavigationDataService';
import {setScreenTypeAction} from 'State/screenType/actions';
import {selectScreenType} from 'State/screenType/selectors';
import {focusTrappingTabKeyHandler} from 'Utils/focusUtils';
import {getScreenType, ScreenType} from 'Utils/screenType';

import {BurgerMenu} from './burgerMenu';
import {Header} from './Header';

import './headerContainer.scss';

type UnconnectedHeaderContainerProps = {
    isBurgerMenuVisible: boolean;
    screenType: ScreenType;
    setScreenType: typeof setScreenTypeAction;
    updateNavigation: typeof updateNavigationAction;
};

let queryClient;
// prevent creating multiple query clients on separate imports
if (!queryClient) {
    queryClient = new QueryClient();
}

export class UnconnectedHeaderContainer extends Component<UnconnectedHeaderContainerProps> {
    private headerContainer;
    setHeaderContainerRef = (headerContainer) => {
        this.headerContainer = headerContainer;
    };

    checkScreenType = () => {
        const {screenType, setScreenType} = this.props;

        const newScreenType = getScreenType();

        if (screenType !== newScreenType) {
            setScreenType(newScreenType);
        }
    };
    async componentDidMount() {
        this.checkScreenType();
        window.addEventListener('resize', this.checkScreenType);
        document.addEventListener('keydown', this.keyDownHandler, false);

        const {treeData, isNewNavigation} = await getNavigationDataService();
        this.props.updateNavigation({
            treeData,
            isNewNavigation,
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkScreenType);
        document.removeEventListener('keydown', this.keyDownHandler, false);
    }

    keyDownHandler = (event: KeyboardEvent) => {
        if (this.props.isBurgerMenuVisible) {
            focusTrappingTabKeyHandler(this.headerContainer, event);
        }
    };

    render() {
        const {screenType} = this.props;

        return (
            <QueryClientProvider client={queryClient}>
                <div
                    ref={this.setHeaderContainerRef}
                    role="presentation"
                    className="header-container"
                >
                    <Header screenType={screenType} />
                    <BurgerMenu screenType={screenType} />
                </div>
                <ReactQueryDevtools />
            </QueryClientProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    isBurgerMenuVisible: selectIsBurgerMenuVisible(state),
    screenType: selectScreenType(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScreenType: setScreenTypeAction,
            updateNavigation: updateNavigationAction,
        },
        dispatch,
    );

export const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedHeaderContainer);
