import {DEPENDEE_FIELD_NO_FORM_ACTION_NAME} from 'Interfaces/formFields/constants';
import {RendererParams} from 'Renderer/Renderer';
import helper from 'Root/core/utils/helper';

export type ExtFormField = FormField & {
    value: string;
};

export type FormField = {
    getSubmitValue: () => any;
    getValue: () => any;
    setValue?: (newValue: unknown) => void;
    clearValue?: () => void;
    actionMappings?: Record<string, string>;
    name: string;
    markInvalid: (errorMessages: string[] | string) => void;
    dependencyFields?: string[];
    reference?: string;
    fetchNewData: (params: {
        dependeeFormFieldValues: Record<string, unknown>;
        successCallback: () => void;
        failureCallback: () => void;
    }) => void;
    getFilterPanelTitleItem?: () => string;
    isReactFormField?: boolean;
    required?: boolean;
    isDisplayFormField?: false;
    parentFilterPanelId?: string;
    componentId: string;
};
export type DisplayFormField = {
    name?: string;
    url: string;
    dependencyFields: string[];
    fetchNewData?: (dependeeFormFieldValues: Record<string, unknown>) => void;
    isDisplayFormField: true;
};
function isFormFieldDisplayField(
    formField: FormField | DisplayFormField,
): formField is DisplayFormField {
    return 'isDisplayFormField' in formField;
}

export type SISFormFieldLabelProps = {
    fieldLabel?: string;
    tooltipMIS?: string;
    tooltip?: string;
    tooltipUrl?: string;
    tooltipHTML?: string;
    required?: boolean;
};

export type SISFormFieldCommonProps = {
    actionMappings?: Record<string, string>;
    name: string;
    dependencyFields?: string[];
    reference?: string; // used as an ID for dependee form fields
    url?: string; // Used to refetch data for dependant form fields
    rendererParams?: RendererParams;
    id: string;
} & SISFormFieldLabelProps;

export type RefetchCallbackParams = {isRefetching: boolean};

class FormFieldRegistry {
    private formFields: Record<
        string,
        (FormField | DisplayFormField) & {formActionNames: string[]; id: string}
    >;

    // Should we just use this for dependant form fields, or also for getting form data for submission?
    private formFieldValues: Record<string, unknown>;

    // We track the dependandee -> dependant relationship both ways to make it easy to
    // work with from both directions. (The dependant depends on the dependee, so if the
    // dependee's value changes, the dependant needs to refetch)
    private formFieldDependees: Record<string, string[]>;
    private formFieldDependants: Record<string, string[]>;
    private unmatchedDependencies: Record<string, string[]>;
    private dependantFieldsWaitingToFetch: Set<string>;
    private dependantFieldsFetching: Set<string>;
    private refetchListeners: Record<
        string,
        (params: RefetchCallbackParams) => void
    >;

    private debouncedValueUpdaters: Record<
        string,
        ReturnType<
            typeof helper.debounce<
                (formFieldId: string, value: unknown) => void
            >
        >
    > = {};

    constructor() {
        this.formFields = {};
        this.formFieldValues = {};
        this.formFieldDependees = {};
        this.formFieldDependants = {};
        this.unmatchedDependencies = {};
        this.dependantFieldsWaitingToFetch = new Set();
        this.dependantFieldsFetching = new Set();
        this.refetchListeners = {};
    }

    private createDebouncedValueUpdaters = (formFieldId: string) => {
        // Adding small debounce for 2 reasons:
        // - prevent some duplicates on fields where you type
        // - some ext form fields (like comboboxes) have dodgy onChange behaviour,
        //   especially during loading, where they send several events, sometimes
        //   switching back and forth between new and old values. Don't want all
        //   of these to trigger network events
        // We need to have an independant debouncer for every form field, otherwise one
        // form field updating could block another one. Especially important during
        // initialisation of values.
        this.debouncedValueUpdaters[formFieldId] = helper.debounce(
            this.updateFormFieldValue,
            60,
        );
    };
    updateFormFieldValueDebounced = (formFieldId: string, newValue) => {
        if (typeof this.debouncedValueUpdaters[formFieldId] === 'undefined') {
            console.error(
                'Cannot update value as debouncedValueUpdaters does not contain',
                formFieldId,
            );
            return;
        }
        this.debouncedValueUpdaters[formFieldId](formFieldId, newValue);
    };

    getFormFieldId = (
        formActionNames: string[],
        formField: FormField | DisplayFormField,
    ) => {
        const isDisplayFormField = isFormFieldDisplayField(formField);
        // Display form fields don't always have a name or an id or a reference,
        // however for them to be dependant they must have a url, so using the url as an id
        if (isDisplayFormField) {
            return formField.url;
        }

        const formFieldName = formField.name;
        // Find a unique reference to a form field, as forms can have
        // several possible form action names at the same time, and form-names
        // are not unique across forms.
        return `${formFieldName}||${formActionNames.join('|')}`;
    };

    registerFormField = (
        formActionNames: string[],
        formField: FormField | DisplayFormField,
    ) => {
        const isDisplayFormField = isFormFieldDisplayField(formField);
        const currentFormFieldId = this.getFormFieldId(
            formActionNames,
            formField,
        );

        if (this.formFields.hasOwnProperty(currentFormFieldId)) {
            console.error(
                `Form field with ID ${currentFormFieldId}, already registered`,
            );
            return null;
        }
        if (!isDisplayFormField) {
            this.formFieldValues[currentFormFieldId] =
                formField.getSubmitValue();
            this.formFieldDependees[currentFormFieldId] = [];

            this.createDebouncedValueUpdaters(currentFormFieldId);
        }
        this.formFields[currentFormFieldId] = {
            ...formField,
            formActionNames,
            id: currentFormFieldId,
        };
        this.formFieldDependants[currentFormFieldId] = [];

        if (formField.dependencyFields) {
            /* 
                Form fields can be dependant on other form fields.
                The dependant form field has a dependencyFields attribute, with a list of ids, which
                match the 'reference' attribue on the dependee form field.
                Two important edge cases to consider:
                - If the dependee form field has already been mounted, then when the dependant form
                    field is mounted, we can link the two fields together via their computed form ids (see getFormFieldId),
                    in the this.formFieldDependees and this.formFieldDependants objects
                - If the dependee form field has not been mounted yet, we cannot do the linking.
                    So add the dependant form field details to this.unmatchedDependencies for later use.
                    When the dependee form field is finally mounted, we check this.unmatchedDependencies
                    so see if there are any matching dependencies that need to be linked.
            */

            let numberOfUnmatchedDependencies = 0;
            formField.dependencyFields.forEach((dependencyFieldName) => {
                const dependeeFormFieldId = Object.keys(this.formFields).find(
                    (key) => {
                        const formFieldDataToCheck = this.formFields[key];
                        if (isFormFieldDisplayField(formFieldDataToCheck)) {
                            // Display fields can't be depended on
                            return false;
                        }
                        const matchingReference =
                            formFieldDataToCheck.reference ===
                            dependencyFieldName;
                        if (!matchingReference) {
                            return false;
                        }
                        if (
                            formFieldDataToCheck.formActionNames.some((item) =>
                                // itemId is appended to the formActionName, to ensure uniqueness, but we need to ignore it
                                item.includes(
                                    DEPENDEE_FIELD_NO_FORM_ACTION_NAME,
                                ),
                            )
                        ) {
                            // There is an edge case of form fields that are
                            // only used for dependant form fields, but don't have a
                            // form-action-name so that they aren't submitted with form
                            // If the dependendee form is one of these we don't need to check
                            // for a matching form-action-name on the dependant field.
                            return matchingReference;
                        }

                        const sharedFormActionName =
                            formActionNames.find((actionName) =>
                                formFieldDataToCheck.formActionNames.includes(
                                    actionName,
                                ),
                            ) !== 'undefined';
                        return sharedFormActionName && matchingReference;
                    },
                );

                if (typeof dependeeFormFieldId === 'undefined') {
                    if (
                        typeof this.unmatchedDependencies[
                            dependencyFieldName
                        ] === 'undefined'
                    ) {
                        this.unmatchedDependencies[dependencyFieldName] = [];
                    }
                    this.unmatchedDependencies[dependencyFieldName].push(
                        currentFormFieldId,
                    );
                    numberOfUnmatchedDependencies++;
                    return;
                }

                this.formFieldDependees[dependeeFormFieldId].push(
                    currentFormFieldId,
                );
                this.formFieldDependants[currentFormFieldId].push(
                    dependeeFormFieldId,
                );
            });
            if (numberOfUnmatchedDependencies === 0) {
                // All dependandee form fields have already been registered, so we
                // can consider this form field fully registered, and can call the
                // fetchNewData function
                this.dependantFieldsFetching.add(currentFormFieldId);
                this.refetchDependantFormFieldData({
                    formFieldToRefetch: this.formFields[currentFormFieldId],
                    dependeeFieldIds:
                        this.formFieldDependants[currentFormFieldId],
                });
            } else {
                this.dependantFieldsWaitingToFetch.add(currentFormFieldId);
            }
        }

        if (
            !isDisplayFormField &&
            typeof formField.reference !== 'undefined' &&
            typeof this.unmatchedDependencies[formField.reference] !==
                'undefined' &&
            this.unmatchedDependencies[formField.reference].length > 0
        ) {
            // A previously registered form field tried to mark the current form field
            // as a dependency, but the current form field didn't exist yet.
            const dependantFieldIds =
                this.unmatchedDependencies[formField.reference];
            // TODO: add the sharedFormActionName check here
            dependantFieldIds.forEach((dependantFieldId) => {
                this.formFieldDependees[currentFormFieldId].push(
                    dependantFieldId,
                );
                this.formFieldDependants[dependantFieldId].push(
                    currentFormFieldId,
                );
            });
            this.unmatchedDependencies[formField.reference] = [];
            // For this dependee field, all the dependencies have been matched up.
            // However the dependant fields may still be dependant on some other values.
            // We should check if they have matched all their dependencies and if so, fetch data.
            this.dependantFieldsWaitingToFetch.forEach((fieldId) => {
                if (
                    this.dependantFieldInUnmatchedDependencies(
                        fieldId,
                        this.unmatchedDependencies,
                    )
                ) {
                    // still need to wait for some form field(s) to register before this form
                    // field can fetch.
                    return;
                }
                this.dependantFieldsWaitingToFetch.delete(fieldId);
                this.dependantFieldsFetching.add(fieldId);
                this.refetchDependantFormFieldData({
                    formFieldToRefetch: this.formFields[fieldId],
                    dependeeFieldIds: this.formFieldDependants[fieldId],
                });
            });
        }

        // This ID can be used to deregister the form field, and to update its value
        return currentFormFieldId;
    };
    registerDisplayFormField = (displayFormField: DisplayFormField) => {
        return this.registerFormField([], {
            ...displayFormField,
            isDisplayFormField: true,
        });
    };

    dependantFieldInUnmatchedDependencies = (
        dependantFieldId: string,
        dependenciesObject: Record<string, string[]>,
    ) => {
        const mathchingDependencyArray = Object.keys(dependenciesObject).find(
            (key) => {
                const dependencies = dependenciesObject[key];
                const matchingDependency = dependencies.find(
                    (dependencyId) => dependencyId === dependantFieldId,
                );
                return typeof matchingDependency !== 'undefined';
            },
        );
        return typeof mathchingDependencyArray !== 'undefined';
    };

    deregisterFormField = (formFieldId: string) => {
        // Clean up and avoid memory leaks
        delete this.formFields[formFieldId];
        delete this.formFieldDependees[formFieldId];
        delete this.formFieldDependants[formFieldId];
        delete this.formFieldValues[formFieldId];
        this.dependantFieldsWaitingToFetch.delete(formFieldId);
        this.dependantFieldsFetching.delete(formFieldId);
        if (
            typeof this.debouncedValueUpdaters[formFieldId]?.clear ===
            'function'
        ) {
            this.debouncedValueUpdaters[formFieldId].clear();
        }
        delete this.debouncedValueUpdaters[formFieldId];
    };

    refetchDependantFormFieldData = ({
        formFieldToRefetch,
        dependeeFieldIds,
    }: {
        formFieldToRefetch: (FormField | DisplayFormField) & {
            formActionNames: string[];
            id: string;
        };
        dependeeFieldIds: string[];
    }) => {
        // A potential future improvement to make here is to fetch items when they are ready to fetch,
        // i.e. all dependanee form fields are registered, and are not fetching.
        // One issue is with comboboxes as they have a kind of different behaviour.
        // When you fetch, the value doesn't necessarily change, and the dependant
        // field only needs to refetch if the value changes.
        //  - For chains of dependant comboboxes, in some cases the dependant comboboxes are optional,
        //    so we don't need to wait until the first combobox has been chosen before fetching.
        //  - However if a field is dependant on a required combobox, then we
        //    possibly need to wait until the combobox is loaded, and a value selected, before we fetch
        //    - in this case should we show a loading state? or a message to say "Choose value for X first"
        // Would need to be careful about what if fetching fails. We now have the fetching
        // callbacks, used for the filter panel, so could use those, and do some logic in refetchCallback
        //
        // The advantage of this improvement would be that we'd make less redundant network requests.
        // The disadvantage is that as it's frought with complexity, and isn't done in the Ext version,
        // so adds to the risk of this change.
        if (typeof formFieldToRefetch?.fetchNewData !== 'function') {
            console.error(
                'Dependant form field does not have a fetchNewData function',
                formFieldToRefetch,
            );
            return;
        }

        // Now for each dependant form field, we need to collect all the values
        // it depends on
        const dependeeValues = dependeeFieldIds.reduce(
            (acc, dependeeFieldId) => {
                const formFieldToCheck = this.formFields[dependeeFieldId];
                if (isFormFieldDisplayField(formFieldToCheck)) {
                    // Display form fields cannot be depended on
                    return acc;
                }
                const dependeeValue = this.formFieldValues[dependeeFieldId];
                const dependeeReference = formFieldToCheck?.reference;
                if (typeof dependeeReference === 'undefined') {
                    console.error(
                        'Undefined dependee reference',
                        dependeeFieldId,
                        this.formFields[dependeeFieldId],
                    );
                    return acc;
                }
                return {
                    ...acc,
                    [dependeeReference]: dependeeValue,
                };
            },
            {},
        );

        // Using the same behaviour for success and errors in fetching form field values,
        // As in both cases the fetching has finished.
        const refetchCallback = () => {
            this.dependantFieldsFetching.delete(formFieldToRefetch.id);
            if (
                isFormFieldDisplayField(formFieldToRefetch) ||
                !formFieldToRefetch.formActionNames
            ) {
                return;
            }
            formFieldToRefetch.formActionNames.forEach((formName) => {
                const refetchListener = this.refetchListeners[formName];
                if (typeof refetchListener === 'function') {
                    const fetchingFormFieldsWithMatchingAction = [
                        ...this.dependantFieldsFetching,
                    ].filter((item) => item.includes(formName));
                    refetchListener({
                        isRefetching:
                            fetchingFormFieldsWithMatchingAction.length > 0,
                    });
                }
            });
        };
        // Once we've removed all Ext form fields, may be worth swithing to
        // `await`ing return value, and catching errors
        formFieldToRefetch.fetchNewData({
            dependeeFormFieldValues: dependeeValues,
            successCallback: refetchCallback,
            failureCallback: refetchCallback,
        });
        // TODO: When form fields fetch new data, they should fall back to their initial value
    };

    updateFormFieldValue = (formFieldId: string, value: unknown) => {
        if (
            JSON.stringify(this.formFieldValues[formFieldId]) ===
            JSON.stringify(value)
        ) {
            // New value the same as previous value, so no need to do any dependant refetches
            // Deep comparing using JSON.stringify to handle cases of object/array values,
            // such as multi-select comboboxes
            return;
        }
        this.formFieldValues[formFieldId] = value;

        if (this.formFieldDependees[formFieldId]?.length > 0) {
            // Some other form fields depend on this form field, so we must
            // tell them to refetch
            this.formFieldDependees[formFieldId]
                .map((dependantFieldId) => ({
                    formFieldToRefetch: this.formFields[dependantFieldId],
                    dependeeFieldIds:
                        this.formFieldDependants[dependantFieldId],
                }))
                .forEach(this.refetchDependantFormFieldData);
        }
    };

    getFormFieldsForAction = (formActionName: string): FormField[] => {
        return (
            Object.keys(this.formFields)
                .filter((key) => {
                    const formFieldToCheck = this.formFields[key];
                    return (
                        !isFormFieldDisplayField(formFieldToCheck) &&
                        formFieldToCheck.formActionNames.includes(
                            formActionName,
                        )
                    );
                })
                .map((key) => {
                    const {formActionNames, ...others} = this.formFields[key];
                    return others as FormField;
                }) || []
        );
    };

    getReactFormFieldsForAction = (formActionName: string): FormField[] => {
        const allFormFieldsMatchingAction =
            this.getFormFieldsForAction(formActionName);
        return allFormFieldsMatchingAction.filter(
            (field) => field.isReactFormField,
        );
    };

    getFormFieldsForActionAsExt = (formActionName: string): ExtFormField[] => {
        const fields = this.getFormFieldsForAction(formActionName);
        const output: ExtFormField[] = [];
        for (const field of fields) {
            output.push({
                ...field,
                get value(): string {
                    return field.getValue() as string;
                },
                set value(newValue: string) {
                    //intentionally empty, to make the compiler happy
                },
            });
        }

        return output;
    };

    getReactFormFieldsForActionAsExt = (
        formActionName: string,
    ): ExtFormField[] => {
        const allFormFieldsMatchingAction =
            this.getFormFieldsForActionAsExt(formActionName);
        return allFormFieldsMatchingAction.filter(
            (field) => field.isReactFormField,
        );
    };

    addRefetchListener = (
        formName: string,
        refetchCallback: (params: RefetchCallbackParams) => void,
    ) => {
        const returnObject = {
            remove: () => {
                delete this.refetchListeners[formName];
            },
        };
        if (typeof this.refetchListeners[formName] !== 'undefined') {
            console.error('Refetch listener already added for form', {
                formName,
                listners: this.refetchListeners,
            });
            return returnObject;
        }
        this.refetchListeners[formName] = refetchCallback;
        return {
            returnObject,
        };
    };
}

let formFieldRegistryInstance: FormFieldRegistry | null = null;
export const getFormFieldRegistryService = () => {
    if (!formFieldRegistryInstance) {
        formFieldRegistryInstance = new FormFieldRegistry();
    }

    return formFieldRegistryInstance;
};
