import {TransitionGroup} from 'react-transition-group';

import Slide from 'Components/transition/Slide';
import {APP_CONTAINER_ID} from 'Constants/idStrings';
import {ISystemNotification} from 'Root/types/systemNotifications';

import {SystemNotification} from './SystemNotification';

import './systemNotificationList.scss';

type SystemNotificationListProps = {
    notifications: (ISystemNotification & {id: number})[];
    onClose: (id: number) => void;
};

export function SystemNotificationList(props: SystemNotificationListProps) {
    const {notifications, onClose} = props;

    // Apply extra styling when the page's scrollbar is visible, so that the
    // list is aligned with the inner page container
    const scrollContainer = document.getElementById(APP_CONTAINER_ID);
    const scrollBarVisible =
        scrollContainer &&
        scrollContainer.scrollHeight > scrollContainer.clientHeight;

    return (
        <TransitionGroup
            className={`arbor-system-notifications-list ${
                scrollBarVisible ? 'scroll-bar-visible' : ''
            }`}
        >
            {notifications.map((notification) => (
                <Slide type="in" key={notification.id}>
                    <SystemNotification
                        {...notification}
                        onClose={() => onClose(notification.id)}
                    />
                </Slide>
            ))}
        </TransitionGroup>
    );
}
