import classNames from 'classnames';

import {Icon} from 'Components/icon';
import {stripHTMLTagsAndDecodeSymbols} from 'Utils/sanitizeHtml';
export type dataFieldProperties = {
    options: {text: string; role?: string}[];
    iconType: string;
};

export const DataControlPanelField = (props: dataFieldProperties) => {
    const fieldOptions = props.options;
    const icon = props.iconType;
    return (
        <section
            className="data-control-panel--field"
            aria-label="list of filter options"
        >
            {icon !== 'add' ? (
                <Icon
                    iconName={icon}
                    className={'data-control-panel--field-icon'}
                    aria-label={icon + ' icon'}
                />
            ) : (
                <span className="data-control-panel--icon-spacer"></span>
            )}
            <div className="data-control-panel--field-label-wrapper">
                {fieldOptions.map((fieldOption, index) => (
                    <span
                        key={index}
                        className={classNames(
                            fieldOption.role === 'add'
                                ? 'data-control-panel--field-label--add'
                                : 'data-control-panel--field-label data-control-panel--filter-on',
                        )}
                    >
                        {fieldOption.role === 'add' && (
                            <Icon
                                iconName="add"
                                className="data-control-panel--field-icon--add"
                                aria-label="add icon"
                            ></Icon>
                        )}
                        {stripHTMLTagsAndDecodeSymbols(fieldOption.text)}
                    </span>
                ))}
            </div>
        </section>
    );
};
