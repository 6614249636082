import classnames from 'classnames';
import {useEffect, useState, useRef} from 'react';
import {createPortal} from 'react-dom';

import {SideBarButton} from 'App/header/buttons/sideBarButton';
import {DropdownContainer} from 'Components/dropdown';
import loader from 'Components/Loader';
import {logEventToGainsight} from 'Services/gainsight';
import {usePubSub} from 'Services/pubsub';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';
import {
    getUserNotificationService,
    INotification,
    initNotificationPreviewPortal,
    NOTIFICATION_PREVIEW_PORTAL_ID,
} from 'Services/userNotifications/UserNotification';
import {FloatingElementDirection} from 'Utils/getFloatingElementPosition';

import {ItemsContainer} from './ItemsContainer';
import {NotificationIcon} from './NotificationIcon';
import {NotificationPreview} from './NotificationPreview';

import './notificationButton.scss';

const ItemsContainerLoader = loader(ItemsContainer);

type NotificationButtonProps = {
    preferTooltipPosition?: FloatingElementDirection;
    isDesktop?: boolean;
};

export const NotificationButton = ({
    preferTooltipPosition,
    isDesktop,
}: NotificationButtonProps) => {
    const [showMask, setShowMask] = useState(false);
    const [items, setItems] = useState<INotification[]>([]);
    const [counter, setCounter] = useState<number>(
        getUserNotificationService().notifications.unreadCount,
    );
    const targetNode = useRef(
        document.getElementById(NOTIFICATION_PREVIEW_PORTAL_ID),
    );
    const [previewNotifications, setPreviewNotifications] = useState<
        INotification[]
    >([]);

    const clearAllPreviews = () => setPreviewNotifications([]);

    const hidePreviewNotification = (id: number) =>
        setPreviewNotifications((prev) =>
            prev.filter(({userNotificationId}) => userNotificationId !== id),
        );

    const clearPreviewNotification = (id: number) => {
        hidePreviewNotification(id);
        setCounter((prevCounter) => prevCounter - 1 || 0);
    };

    useEffect(() => {
        if (!targetNode.current) {
            // Fallback in case placeholder div is not already created (e.g. in tests)
            targetNode.current = initNotificationPreviewPortal();
        }
    }, []);

    usePubSub('updateNotificationCounter', (value) => {
        if (typeof value === 'number') {
            setCounter(value);
        }
    });

    usePubSub('refreshNotificationList', (newList) => {
        // has to be spread because it's actually pointing to the same object, and react
        // doesn't know the array contents have changed
        setItems([...newList]);
    });

    usePubSub('receivedPreviewNotification', (notification: INotification) => {
        setPreviewNotifications((prev) => [notification, ...prev]);
    });

    const getNotifications = () => {
        return getUserNotificationService().getNotifications();
    };

    const fetchNotifications = () => {
        setShowMask(true);
        getNotifications()
            .then((notificationList) => {
                setItems(notificationList);
            })
            .catch((response) => {
                console.log(response); // eslint-disable-line
            })
            .then(() => {
                setShowMask(false);
            });
    };

    const handleDropdownOpened = () => {
        fetchNotifications();
        clearAllPreviews();
    };

    const isUnread = counter > 0;

    return (
        <>
            <DropdownContainer
                alignDropdown="end"
                preferPosition={isDesktop ? 'right' : 'bottom'}
                offset={0}
                renderDropdownField={({toggleDropdown, isDropdownVisible}) => (
                    <SideBarButton
                        onClick={() => {
                            toggleDropdown();
                            logEventToGainsight('NotificationButtonClicked', {
                                clickedFrom: getCurrentPageFromUrl(),
                            });
                        }}
                        className={classnames('notification-button')}
                        aria-label={`${counter} unread notifications`}
                        buttonText="Notifications"
                        tooltip="Check your personal alerts"
                        preferTooltipPosition={preferTooltipPosition}
                        isActive={isDropdownVisible}
                    >
                        <NotificationIcon />
                        {isUnread && (
                            <div className="notification-button-icon-number">
                                {counter}
                            </div>
                        )}
                    </SideBarButton>
                )}
                renderDropdownContents={() => (
                    <div>
                        <div className="notification-header">Notifications</div>
                        <ItemsContainerLoader
                            items={items}
                            showMask={showMask}
                        />
                    </div>
                )}
                dropdownWidth="400px"
                dropdownOpenedHandler={handleDropdownOpened}
            />
            {targetNode.current ? (
                createPortal(
                    <NotificationPreview
                        notifications={previewNotifications}
                        onNotificationClick={clearPreviewNotification}
                        hideNotification={hidePreviewNotification}
                    />,
                    targetNode.current,
                )
            ) : (
                <NotificationPreview
                    notifications={previewNotifications}
                    onNotificationClick={clearPreviewNotification}
                    hideNotification={hidePreviewNotification}
                />
            )}
        </>
    );
};
