import * as React from 'react';

import {loadPage} from 'Interfaces/Loader';
import {isUrlExternal} from 'Root/core/services/url/isUrlExternal';
import {getHrefFromUrl} from 'Utils/getHrefFromUrl';

import {TARGET_OVERRIDE} from './constants';
import {openLinkWithTemporaryATag} from './openLinkWithTemporaryATag';

// This function is used if you want to provide link-like behaviour
// of loading pages with modifier key support. This should only be used in
// cases where it is not possible to use a KeyboardFocusableLink or <a>, for
// example when working with third party components.
export const loadPageWithModifierKeys = (
    url: string,
    userEvent: MouseEvent | KeyboardEvent | React.MouseEvent,
    params?: {
        jsonData?: unknown;
        refocusCallback?: () => void;
        targetOverride?: (typeof TARGET_OVERRIDE)[keyof typeof TARGET_OVERRIDE];
    },
) => {
    if (!url || !userEvent) {
        return;
    }
    const {shiftKey, altKey, ctrlKey, metaKey} = userEvent;

    let shouldLoadInNewPage: boolean;
    if (shiftKey || altKey || ctrlKey || metaKey) {
        shouldLoadInNewPage = true;
    } else if (params?.targetOverride === TARGET_OVERRIDE.CURRENT_TAB) {
        shouldLoadInNewPage = false;
    } else if (params?.targetOverride === TARGET_OVERRIDE.NEW_TAB) {
        shouldLoadInNewPage = true;
    } else {
        shouldLoadInNewPage = isUrlExternal(url);
    }

    if (!shouldLoadInNewPage) {
        loadPage(url, params);
    } else if (shiftKey || altKey || ctrlKey || metaKey) {
        const href = getHrefFromUrl(url);
        // Note: the params.jsonData is not used by openLinkWithTemporaryATag
        // It doesn't seem possible to actually do this
        // I tried this approach: https://stackoverflow.com/a/64635407 but it
        // doesn't work with nested data, and the params won't actually get passed
        // to the first loadPage call by the frontend, just to the initial html request
        //
        // This can lead to some different behaviours on normal link visit vs clicking
        // with modifier keys. Unfortunatley this seems unavoidable. Would recommend that
        // pages are refactored on the backend to not use post data.

        openLinkWithTemporaryATag(href, {
            shiftKey,
            altKey,
            ctrlKey,
            metaKey,
        });

        userEvent.preventDefault();
        userEvent.stopPropagation();
    } else {
        window.open(getHrefFromUrl(url), '_blank')?.focus();
        userEvent.preventDefault();
        userEvent.stopPropagation();
    }
};
