import {TAB} from 'Config/Events';
import Pubsub from 'Services/pubsub/Pubsub';

import exportInterface from './Base';

export function tabChanged(title: string) {
    Pubsub.publish(TAB.CHANGE, title);
}

export const registerTabInterface = () => {
    exportInterface('Tab', {tabChanged});
};
