import classnames from 'classnames';
import PropTypes from 'prop-types';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import './toggleButton.scss';

export const ToggleButton = ({
    options,
    selectedOption,
    onChange,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <div className="group-global-filter-toggle-button__container">
                {options.map((_option, i) => (
                    <span
                        className="group-global-filter-toggle-button__loading-box"
                        key={i}
                    ></span>
                ))}
            </div>
        );
    }
    return (
        <div className="group-global-filter-toggle-button__container">
            {options.map(({label, id}) => {
                const isSelected = selectedOption === id;
                return (
                    <KeyboardFocusableButton
                        onClick={() => onChange(id)}
                        key={id}
                        buttonWrapperClassName={classnames(
                            'group-global-filter-toggle-button__button-wrapper',
                            {
                                'group-global-filter-toggle-button__button-wrapper--selected':
                                    isSelected,
                            },
                        )}
                        className={classnames(
                            'group-global-filter-toggle-button__button',
                            {
                                'group-global-filter-toggle-button__button--selected':
                                    isSelected,
                            },
                        )}
                        data-testid="toggle-button"
                    >
                        {label}
                    </KeyboardFocusableButton>
                );
            })}
        </div>
    );
};

ToggleButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    selectedOption: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
};
