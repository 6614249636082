/*
 * This is a reimplementation of Java's String.hashCode() method.
 * IT IS NOT CRYPTOGRAPHICALLY SAFE, but fast and reasonably resistant to collisions
 */
export const fastWeakEncode = (str: string) => {
    //Adapted from https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
    let hash = 0;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return hash;
};
