import PropTypes from 'prop-types';
import {Component} from 'react';

import {getExtLayoutRendererCounter} from './getExtLayoutRendererCounter';
import {renderChildrenToExtConfig} from './Helper';
export class ExtLayoutRenderer extends Component {
    constructor(props) {
        super(props);

        this.container = null;

        this.setContainerRef = (element) => {
            this.container = element;
        };

        this.containerId = `ExtLayoutRenderer_container_${getExtLayoutRendererCounter()}`;
    }
    setSizeOfExtColumn = () => {
        // TODO [MIS-37824]: Investigate whether we should debounce this
        const extColumnId =
            this.container &&
            this.container.children &&
            this.container.children[0] &&
            this.container.children[0].id;
        const columnWidth = this.container && this.container.clientWidth;
        if (extColumnId && columnWidth) {
            Ext.getCmp(extColumnId).setConfig('width', columnWidth);
        }
    };
    componentDidMount() {
        window.addEventListener('resize', this.setSizeOfExtColumn);

        const {children, componentName, ...others} = this.props;

        const columnWidth = this.container && this.container.clientWidth;
        const fallbackColumnWidth = 500;

        const ExtComponent = Ext.create(componentName, {
            renderTo: this.containerId,
            width: columnWidth || fallbackColumnWidth,
            items: renderChildrenToExtConfig(children),
            ...others,
        });
        this.componentId = ExtComponent.id;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setSizeOfExtColumn);

        const extComponent = Ext.getCmp(this.componentId);
        if (extComponent && typeof extComponent.destroy === 'function') {
            extComponent.destroy();
        }
    }
    render() {
        return (
            <div
                role="presentation"
                className="ext-layout-renderer-container"
                data-testid="ext-layout-renderer"
                ref={this.setContainerRef}
                id={this.containerId}
            />
        );
    }
}

ExtLayoutRenderer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.object,
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
        ),
    ]),
    componentName: PropTypes.string,
};
