import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import {SecondaryButton} from 'Components/button/base';
import Box from 'Components/layout/Box';
import Container from 'Components/layout/container/Container';

import './purchasePanelProperty.scss';

class PurchasePanelProperty extends PureComponent {
    handleAction = () => {
        if (this.props.actionUrl) {
            window.open(this.props.actionUrl);
        }
    };

    render() {
        const {label, labelIcon, actionText, actionIcon, actionCls} =
            this.props;

        const colorsMap = {
            download: 'green',
            preview: 'green',
        };

        const buttonColor = actionCls || colorsMap[actionIcon] || 'blue';

        return (
            <Container
                className="arbor-purchase-panel-property"
                align="center"
                wrap="nowrap"
            >
                <Container
                    grow
                    align="center"
                    wrap="nowrap"
                    className="arbor-purchase-panel-property-label"
                >
                    <img src={labelIcon === 'pdf' && 'img/pdf.png'} />
                    <Box>{label}</Box>
                </Container>
                <SecondaryButton
                    text={actionText}
                    iconRight={actionIcon}
                    color={buttonColor}
                    onClick={this.handleAction}
                />
            </Container>
        );
    }
}

PurchasePanelProperty.propTypes = {
    label: PropTypes.string,
    labelIcon: PropTypes.string,
    actionText: PropTypes.string,
    actionIcon: PropTypes.string,
    actionCls: PropTypes.string,
    actionUrl: PropTypes.string,
};

export default PurchasePanelProperty;
