export const APP_CONTAINER_ID = 'app-container';
export const HEADER_CONTAINER_ID = 'header-container';
export const SLIDEOVER_PLACEHOLDER_ID = 'slideover-placeholder';
export const WINDOW_PLACEHOLDER_ID = 'window-placeholder';
export const REACT_WINDOW_MANAGER_PLACEHOLDER_ID =
    'react-window-manager-placeholder';
export const EXT_WINDOW_MANAGER_PLACEHOLDER_ID =
    'ext-window-manager-placeholder';
export const STRIPE_WINDOW_PLACEHOLDER_ID = 'stripe-window-placeholder';
export const WINDOW_MANAGER_EXT_CONTENT_PLACEHOLDER_ID =
    'window-manager-content-placeholder';
export const SYSTEM_NOTIFICATION_PLACEHOLDER_ID =
    'system-notification-placeholder';
export const SYSTEM_MODAL_PLACEHOLDER_ID = 'system-modal-placeholder';
export const SESSION_MODAL_PLACEHOLDER_ID = 'session-modal-placeholder';
export const TOOLTIP_PORTAL_ID = 'tooltip-portal';
export const SIMPLE_TOOLTIP_PLACEHOLDER_ID = 'simple-tooltip-placeholder';
