import {splitSisUrlIntoSections} from './splitSisUrlIntoSections';

const urlParamsFromPairs = (paramPairs: {key: string; value: string}[]) => {
    if (paramPairs.length === 0) {
        return '';
    }
    return `/${paramPairs
        .map((pair) => `${pair.key}/${pair.value}`)
        .join('/')}`;
};

// Note: this function expects relative urls
export const createUrlWithAdditionalParams = (
    inputUrl: string,
    newParams: Record<string, string>,
): string => {
    if (!inputUrl || typeof inputUrl.split !== 'function') {
        console.error(
            'Invalid inputUrl supplied to createUrlWithAdditionalParams',
            inputUrl,
        );
        return '';
    }
    if (!newParams || Object.keys(newParams).length < 1) {
        return inputUrl;
    }

    const {query, baseUrl, urlParamParts} = splitSisUrlIntoSections(inputUrl);

    // Using an array of objects to preserve parameter order
    const urlParamPairs: {key: string; value: string}[] = [];
    const additionalUrlParamPairs: {key: string; value: string}[] = [];
    // Sometimes url params are used that have a key but no value
    let finalUrlParam: string | null = null;

    urlParamParts.forEach((param, index) => {
        if (index % 2 !== 0) {
            // url params come in pairs, so ignore even indexes
            return;
        }
        if (
            index === urlParamParts.length - 1 &&
            typeof newParams[param] === 'undefined'
        ) {
            finalUrlParam = param;
            return;
        }

        urlParamPairs.push({
            key: param,
            value: urlParamParts[index + 1],
        });
    });

    // Use newParams to update or add url params
    Object.keys(newParams).forEach((newParamKey) => {
        const newParamValue = newParams[newParamKey];

        const matchingIndex = urlParamPairs.findIndex(
            (existingParam) => existingParam.key === newParamKey,
        );
        if (matchingIndex > -1) {
            urlParamPairs[matchingIndex].value = newParamValue;
            return;
        }
        additionalUrlParamPairs.push({
            key: newParamKey,
            value: newParamValue,
        });
    });

    const paramString = urlParamsFromPairs(urlParamPairs);
    const newParamsString = urlParamsFromPairs(additionalUrlParamPairs);
    const queryString = query === null ? '' : `/?${query}`;
    const finalUrlParamString =
        finalUrlParam === null ? '' : `/${finalUrlParam}`;

    return `${baseUrl}${paramString}${newParamsString}${finalUrlParamString}${queryString}`;
};
