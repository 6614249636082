import {HistoricCanvasResponseType} from 'BackendRenderedComponents/chatCanvas/getHistoricCanvas';
import {httpGet} from 'Interfaces/httpClient';

import {ChatMessage} from './ChatPanel';

export type HistoricMessagesResponseType = {
    items: ChatMessage[];
};

type HistoricMessagesResult = {
    chatItems: ChatMessage[];
    followUpPrompts: string[];
};

export const getHistoricMessages = async (
    url: string,
): Promise<HistoricMessagesResult> => {
    try {
        const response:
            | HistoricMessagesResponseType
            | HistoricCanvasResponseType = await httpGet(url);

        if (!response || !response.items) {
            console.error(
                'Invalid response in getHistoricMessages',
                url,
                response,
            );
            return {chatItems: [], followUpPrompts: []};
        }

        let chatItems: ChatMessage[] = [];
        let followUpPrompts: string[] = [];
        response.items.forEach((item) => {
            if ('chatItems' in item) {
                chatItems = item.chatItems.map(({id, type, text}) => ({
                    id,
                    type,
                    text,
                }));
            } else if ('id' in item && 'type' in item && 'text' in item) {
                chatItems.push({id: item.id, type: item.type, text: item.text});
            }
            if ('followUpPrompts' in item) {
                followUpPrompts = item.followUpPrompts || [];
            }
        });

        chatItems = chatItems.filter(
            (item) =>
                typeof item.id === 'string' &&
                (item.type === 'agent' || item.type === 'user') &&
                typeof item.text === 'string',
        );

        return {chatItems, followUpPrompts};
    } catch (e) {
        console.error('Error in getHistoricMessages', e, url);
        return {chatItems: [], followUpPrompts: []};
    }
};
