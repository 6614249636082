import {
    DESKTOP,
    TABLET,
    TABLET_PORTRAIT,
    MOBILE,
    ScreenType,
} from './screenTypes';

export const getScreenType = (): ScreenType => {
    const screenWidth = window.innerWidth;

    if (screenWidth > 1024) {
        return DESKTOP;
    }
    if (screenWidth > 800) {
        // Note: matches the value $tablet-device-breakpoint in react/styles/constants.scss
        return TABLET;
    }

    if (screenWidth > 480) {
        // Note: iPads in portait mode fall into the TABLET_PORTRAIT category. This is because they are
        // not wide enough to fit a SubNav on the page with main page content
        // Note: matches the value $tablet-device-breakpoint in react/styles/constants.scss
        return TABLET_PORTRAIT;
    }

    return MOBILE;
};
