/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:07
 */

import ExternalConfig from 'Config/External';
import exportInterface from 'Interfaces/Base';
import {addSystemModal} from 'Interfaces/SystemModal';
import {ErrorResponseData} from 'Types/response';

export function raiseError(title: string, message: string, logTitle?: string) {
    addSystemModal({
        title: title,
        message: message,
        icon: 'error',
    });
    if (logTitle) {
        console.error(logTitle, title, message);
    } else {
        console.error(title, message);
    }
}

export function raiseUserError(errors: string[]) {
    // errors is an array of error message strings

    const messageList = errors.map((error) => `<li>${error}</li>`).join('');
    const title =
        errors.length > 1
            ? 'Please correct these errors:'
            : 'Please correct this error:';

    addSystemModal({
        title,
        message: `<ul>${messageList}</ul>`,
        icon: 'error',
    });
}

export function raiseResponseError(
    response: ErrorResponseData,
    errorTitle = 'Response Error',
) {
    // Used in cases where the backend sends some HTML for the body of the error message
    const debugMode = ExternalConfig.getConfig().debugEnvironment;
    addSystemModal({
        message: response.message,
        icon: 'error',
        exception: debugMode
            ? response.exception && response.exception.message
            : undefined,
        sentryId: response.sentryId,
        isNotFoundException: response.isNotFoundException,
    });

    // Log error to console with a trace if possible
    console.error(errorTitle, response);
    if (
        debugMode &&
        typeof console.table === 'function' && // eslint-disable-line no-console
        response.exception &&
        response.exception.trace
    ) {
        // eslint-disable-next-line no-console
        console.table(
            response.exception.trace.map((item) => ({
                function: item.class + item.type + item.function,
                location: item.file + ' : ' + item.line,
            })),
        );
    }
}

interface IRaiseDevErrorParams {
    title?: string;
    message?: string;
    component?: string;
    response?: ErrorResponseData;
}

export function raiseDevError({
    title,
    message,
    component,
    response,
}: IRaiseDevErrorParams) {
    // These errors only show in Dev mode
    if (ExternalConfig.getConfig().debugEnvironment) {
        if (response) {
            addSystemModal({
                message: response.message,
                icon: 'error',
                exception: response.exception && response.exception.message,
            });

            // Log error to console with a trace if possible
            console.error(`Development error: ${title}`, response);
            if (
                typeof console.table === 'function' && // eslint-disable-line no-console
                response.exception &&
                response.exception.trace
            ) {
                // eslint-disable-next-line no-console
                console.table(
                    response.exception.trace.map((item) => ({
                        function: item.class + item.type + item.function,
                        location: item.file + ' : ' + item.line,
                    })),
                );
            }
        } else {
            let sourceClassName = '';
            if (component) {
                if (Ext.isObject(component)) {
                    sourceClassName = Ext.getClassName(component) + ': ';
                } else if (typeof component === 'string') {
                    sourceClassName = component + ': ';
                }
            }
            addSystemModal({
                title: 'Development Error',
                message: sourceClassName + message,
                icon: 'error',
            });
            console.error(title || '', sourceClassName || '', message || '');
        }
    }
}

export const registerErrorInterface = () => {
    exportInterface('Error', {
        raiseError,
        raiseDevError,
        raiseUserError,
        raiseResponseError,
    });
};
