import './loadingDots.scss';

export const LoadingDots = () => (
    <span
        className="loading-dots-animation-container"
        aria-label="Data loading animation"
    >
        <span className="loading-dots-animation-dot"></span>
        <span className="loading-dots-animation-dot"></span>
        <span className="loading-dots-animation-dot"></span>
    </span>
);
