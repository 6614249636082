import DOMPurify from 'dompurify';

import {sanitizeImageSrc} from './sanitizeImageSrc';
import {sanitizeInlineStyles} from './sanitizeInlineStyles';

const allowedCellContentTags = [
    'b',
    'br',
    'img',
    'div',
    'em',
    'i',
    'li',
    'ol',
    'p',
    's',
    'span',
    'strong',
    'u',
    'ul',
] as const;
const allowedCellContentAttributes = [
    'align',
    'class',
    'start',
    'style',
    'title',
    'type',
    'src',
    'width',
    'height',
] as const;

export const sanitizeMarksheetCell = (content: string): string => {
    DOMPurify.addHook('afterSanitizeAttributes', sanitizeImageSrc);

    const result = DOMPurify.sanitize(content, {
        ALLOWED_TAGS: [...allowedCellContentTags],
        ALLOWED_ATTR: [...allowedCellContentAttributes],
    });
    DOMPurify.removeHook('afterSanitizeAttributes');

    return sanitizeInlineStyles(result);
};
