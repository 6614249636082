import React, {useState, useEffect, useRef} from 'react';

import {LoadingSpinner} from 'Components/loadingSpinner';

import './LoadingWidget.scss';

const DISPLAY_DURATION = 4000;
const FADE_DURATION = 500;
const loadingPhrases = [
    "I'm working on it…",
    'Generating insights…',
    'Piecing things together…',
    'Processing your request…',
    'Refining the details…',
    'Just a moment…',
    'Organising the information…',
    'Bringing it all together…',
    'Gathering facts…',
    'Crafting a response…',
    'Thinking things through…',
    'Preparing results…',
    'Double-checking my output…',
    'Reviewing the options…',
    'Gathering data…',
    'Looking at data…',
    'Digging deeper…',
    'Reaching a conclusion…',
    'Checking the data…',
    'Wrapping things up…',
    'Thinking…',
    'Putting ideas together…',
    'Cross-checking information…',
    'Filling in the gaps…',
    'Finding the answers…',
    'Organising my thoughts…',
    'On it…',
    'Working on my response…',
    'Building your response…',
    'Exploring the possibilities…',
    'Completing the task…',
    'Shaping the outcome…',
    'Fine-tuning the details…',
    'Crunching the data…',
    'Looking up insights…',
    'Composing an answer…',
    'Pulling it all together…',
    'Preparing a solution…',
    'Making connections…',
    'Polishing the results…',
    'Steady progress…',
    'Testing assumptions…',
    'Checking possibilities…',
    'Sifting through some ideas…',
    'Organising concepts…',
    'Analysing your query…',
    'Analysing results…',
    'Working through the details…',
];

export const LoadingWidget = () => {
    const getRandomIndex = () =>
        Math.floor(Math.random() * loadingPhrases.length);
    const [currentPhrase, setCurrentPhrase] = useState(
        loadingPhrases[getRandomIndex()],
    );
    const [fadeState, setFadeState] = useState('fade-in');
    const previousIndexRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeState('fade-out');
            setTimeout(() => {
                let newIndex;
                do {
                    newIndex = getRandomIndex();
                } while (newIndex === previousIndexRef.current);
                previousIndexRef.current = newIndex;
                setCurrentPhrase(loadingPhrases[newIndex]);
                setFadeState('fade-in');
            }, FADE_DURATION);
        }, DISPLAY_DURATION);

        return () => clearInterval(interval);
    }, []);

    return (
        <li
            aria-label="Loading response"
            className="chat-panel__loading-container"
            data-testid="loading-widget"
        >
            <span className="chat-panel__loading-spinner-container">
                <LoadingSpinner className="chat-panel__loading-spinner" />
            </span>
            <span className={`chat-panel__loading-phrase ${fadeState}`}>
                {currentPhrase}
            </span>
        </li>
    );
};
