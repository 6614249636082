import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';
import {relayoutAllExtContainers} from 'Utils/relayoutExtContainer';

import {
    CLEAR_NAVIGATION,
    UPDATE_NAVIGATION,
    CLEAR_DYNAMIC_NAV,
    SET_SUBNAV_HIDDEN_BY_USER,
    TOGGLE_SUB_NAV_ITEM,
    UPDATE_DYNAMIC_NAV,
    USER_FAVOURITE_PAGES,
    ADD_USER_FAVOURITE_PAGE,
} from './constants';
import {createExpandedIdsFromTreeData} from './createExpandedIdsFromTreeData';
import {findRouteInTree, sortFavouritePagesAlphabetically} from './utils';

import type {TransformedNavigationItem} from 'State/navigation/types';

export type NavigationReducerState = {
    treeData: TransformedNavigationItem[];
    isNewNavigation: boolean;
    currentPageId: string | null;
    currentRoute: string | null;
    staticParentId: string | null;
    initialRequestedRoute: string | null;
    dynamicTreeTitle: string | null;
    dynamicTreeData: TransformedNavigationItem[];
    expandedIds: Record<string, boolean>;
    isSubNavHiddenByUser: boolean;
    initialParentStaticRoute: string | null;
    currentUrlParams: string[] | null;
    parentStaticTreeOptionalParams: Record<string, string | null> | null;
    isDebugUiEnabled: boolean;
    userFavouritePages: UserFavouritePage[];
    isFavouritesEnabled: boolean;
};
const initialState: NavigationReducerState = {
    treeData: [],
    isNewNavigation: false,
    currentPageId: null,
    currentRoute: null,
    staticParentId: null,
    initialRequestedRoute: null,
    dynamicTreeTitle: null,
    dynamicTreeData: [],
    expandedIds: {},
    isSubNavHiddenByUser: false,
    initialParentStaticRoute: null,
    currentUrlParams: null,
    parentStaticTreeOptionalParams: null,
    isDebugUiEnabled: false,
    userFavouritePages: [],
    isFavouritesEnabled: false,
};

export const navigationReducer = (
    state: NavigationReducerState = initialState,
    // TODO: properly type the actions, should probably start using @reduxjs/toolkit
    action,
): NavigationReducerState => {
    switch (action.type) {
        case CLEAR_NAVIGATION:
            return {
                ...state,
                treeData: [],
            };

        case UPDATE_NAVIGATION:
            let pageIdInState = state.currentPageId;
            let staticParentRouteId: string | null = null;

            // Only checking this on initial navigation load, as unlikely to change often.
            // To switch on, you need to set in local storage and refresh the page.
            // i.e. localStorage.setItem('navigationDebugUi', 'true'); in devtools console
            const isDebugUiEnabled =
                localStorage.getItem('navigationDebugUi') === 'true';

            if (state.initialRequestedRoute) {
                const route = findRouteInTree(
                    state.initialRequestedRoute,
                    action.treeData,
                );
                pageIdInState = route?.id ?? null;
                if (typeof route?.text === 'string') {
                    document.title = route.text;
                }
            }
            if (state.initialParentStaticRoute) {
                const staticParentRoute = findRouteInTree(
                    state.initialParentStaticRoute,
                    action.treeData,
                );
                staticParentRouteId = staticParentRoute?.id ?? null;
            }
            const forcedNewNavigation =
                localStorage.getItem('forceNewNavigation') !== 'false';

            setTimeout(() => {
                relayoutAllExtContainers();
            }, 0);
            return {
                ...state,
                treeData: action.treeData,
                isNewNavigation: forcedNewNavigation || action.isNewNavigation,
                expandedIds: {
                    ...createExpandedIdsFromTreeData(
                        action.treeData,
                        staticParentRouteId ?? pageIdInState,
                    ),
                    ...createExpandedIdsFromTreeData(
                        state.dynamicTreeData,
                        pageIdInState,
                    ),
                    dynamicNav: true,
                },
                ...(state.initialRequestedRoute
                    ? {
                          initialRequestedRoute: null,
                          currentPageId: pageIdInState,
                      }
                    : {}),
                ...(state.initialParentStaticRoute
                    ? {
                          initialParentStaticRoute: null,
                          staticParentId: staticParentRouteId,
                      }
                    : {}),
                isDebugUiEnabled,
            };

        case CLEAR_DYNAMIC_NAV:
            const currentPageInStaticTree = findRouteInTree(
                action.currentRoute,
                // Here we know the route isn't in the dynamic tree as we are clearing the dynamic nav
                state.treeData,
            );
            if (typeof currentPageInStaticTree?.text === 'string') {
                document.title = currentPageInStaticTree.text;
            }
            // When the page first loads, if a page is not in the static tree, this
            // action will often get called before UPDATE_NAVIGATION, as it takes time
            // for the API request for main navigation data. If this is the case, we should store
            // the initial requested route, and use this to compute the current page id once
            // the navigation data is received.
            return {
                ...state,
                ...(state.treeData.length === 0
                    ? {initialRequestedRoute: action.currentRoute}
                    : {}),
                dynamicTreeTitle: null,
                dynamicTreeData: [],
                staticParentId: null,
                currentPageId: currentPageInStaticTree?.id ?? null,
                expandedIds: {
                    ...createExpandedIdsFromTreeData(
                        state.treeData,
                        currentPageInStaticTree?.id,
                    ),
                },
                currentRoute: action.currentRoute,
                currentUrlParams: action.currentUrlParams,
                parentStaticTreeOptionalParams: null,
            };

        case UPDATE_DYNAMIC_NAV:
            const route = findRouteInTree(
                action.currentRoute,
                // Here we know the route is in the dynamic tree as we are setting the dynamic nav
                action.dynamicTreeData,
            );

            if (
                action.isNewDynamicNavigationData &&
                typeof route?.text === 'string'
            ) {
                // For old dynamic navigation, we don't want to use the navigation
                // page name as the browser title, as it may possibly contain sensitive information.
                // In new navigation we never include dynamic data in the item names, so we can
                // update the title
                document.title = route.text;
            }

            const currentPageId = route?.id || null;
            const staticParentId =
                findRouteInTree(action.parentStaticRoute, state.treeData)?.id ??
                null;

            return {
                ...state,
                dynamicTreeTitle: action.title,
                dynamicTreeData: action.dynamicTreeData,
                expandedIds: {
                    dynamicNav: true,
                    ...createExpandedIdsFromTreeData(
                        state.treeData,
                        // Maybe can just use staticParentId
                        staticParentId ?? currentPageId,
                    ),
                    ...createExpandedIdsFromTreeData(
                        action.dynamicTreeData,
                        currentPageId,
                    ),
                },
                ...(state.treeData.length === 0
                    ? {initialParentStaticRoute: action.parentStaticRoute}
                    : {}),
                staticParentId: staticParentId,
                currentPageId: currentPageId,
                currentRoute: action.currentRoute,
                currentUrlParams: action.currentUrlParams,
                parentStaticTreeOptionalParams:
                    action.parentStaticTreeOptionalParams ?? null,
            };

        case TOGGLE_SUB_NAV_ITEM:
            return {
                ...state,
                expandedIds: {
                    ...state.expandedIds,
                    [action.itemId]: !state.expandedIds[action.itemId],
                },
            };

        case SET_SUBNAV_HIDDEN_BY_USER:
            setTimeout(() => {
                relayoutAllExtContainers();
            }, 0);
            return {
                ...state,
                isSubNavHiddenByUser: action.isHidden,
            };

        case ADD_USER_FAVOURITE_PAGE:
            const {userFavouritePageId, favouritedRoute, customName} = action;

            const userFavouritePages = [...state.userFavouritePages];

            const existingPageIndex = userFavouritePages.findIndex(
                (page) => page.userFavouritePageId === userFavouritePageId,
            );
            if (existingPageIndex > -1) {
                userFavouritePages[existingPageIndex] = {
                    ...userFavouritePages[existingPageIndex],
                    customName,
                };
            } else {
                userFavouritePages.push({
                    userFavouritePageId,
                    route: favouritedRoute,
                    customName,
                });
            }
            return {
                ...state,
                userFavouritePages:
                    sortFavouritePagesAlphabetically(userFavouritePages),
            };
        case USER_FAVOURITE_PAGES:
            return {
                ...state,
                userFavouritePages: action.userFavouritePages,
                isFavouritesEnabled: true,
            };
        default:
            return state;
    }
};
