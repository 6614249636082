import {ReportingDashboardHeader} from 'BackendRenderedComponents/reportingDashboard/ReportingDashboardHeader';
import {Card} from 'Components/card';
import {FlexGridContainer} from 'Components/flexGridContainer';
import {LoadingSpinner} from 'Components/loadingSpinner';
import {logEventToGainsight} from 'Root/core/services/gainsight';

import {useReportsData} from './useReportsData';
import {isCustomReportHelper} from './utils';

import './reportingDashboard.scss';

type ReportingDashboardProps = {
    fetchUrl: string;
};

export type ReportData = {
    id: string;
    categories?: string[];
    description?: string;
    name: string;
    parameters?;
    url: string;
    isCreatedByCurrentUser?: boolean;
};

export const ReportingDashboard = (props: ReportingDashboardProps) => {
    const {fetchUrl} = props;

    const {
        reportsList,
        filteredReports,
        categories,
        setSearchQuery,
        selectedCategory,
        setSelectedCategory,
        isLoading,
        hasError,
        searchQuery,
        showArborReports,
        showOthersCustomReports,
        showMyCustomReports,
        setShowArborReports,
        setShowOthersCustomReports,
        setShowMyCustomReports,
    } = useReportsData(fetchUrl);

    if (hasError) {
        return <p>Error fetching report list</p>;
    }

    return (
        <div className="reporting-dashboard__container">
            <ReportingDashboardHeader
                isLoading={isLoading}
                categories={categories}
                selectedCategory={selectedCategory}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setSelectedCategory={setSelectedCategory}
                displayCount={filteredReports.length}
                totalCount={reportsList.length}
                showArborReports={showArborReports}
                showOthersCustomReports={showOthersCustomReports}
                showMyCustomReports={showMyCustomReports}
                setShowArborReports={setShowArborReports}
                setShowOthersCustomReports={setShowOthersCustomReports}
                setShowMyCustomReports={setShowMyCustomReports}
            />
            {!isLoading ? (
                <FlexGridContainer>
                    {filteredReports.map((report: ReportData) => {
                        const isCustomReport = isCustomReportHelper(report);
                        return (
                            <Card
                                key={report.id}
                                onClick={() => {
                                    if (!isCustomReport) {
                                        logEventToGainsight(
                                            'ReportingLibrary_ArborReportOpened',
                                            {
                                                reportTitle: report.name,
                                                url: report.url,
                                            },
                                        );
                                    } else {
                                        logEventToGainsight(
                                            'ReportingLibrary_CustomReportOpened',
                                            {},
                                        );
                                    }
                                }}
                                title={report.name}
                                paragraph={report.description}
                                url={report.url}
                                pillColor={isCustomReport ? 'orange' : 'green'}
                                pillText={
                                    isCustomReport
                                        ? 'Custom Report'
                                        : 'Arbor Report'
                                }
                                compact
                            />
                        );
                    })}
                </FlexGridContainer>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};
