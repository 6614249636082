import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {toggleFilterById} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectStudentGroupFilter} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {FILTER_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {DropdownContainer} from 'Components/dropdown';
import {
    SearchFilterDropdownContents,
    SearchFilterDropdownField,
} from 'Components/dropdown/searchFilterDropdown';

import './studentGroupFilterDropdown.scss';

class UnconnectedStudentGroupFilterDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
        };
    }

    handleSearchValueChange = (event) =>
        this.setState({searchValue: event.target.value});

    render() {
        const {actions, studentGroups} = this.props;
        const {searchValue} = this.state;
        return (
            <div className="student-group-filter-dropdown__container">
                <DropdownContainer
                    renderDropdownField={(fieldProps) => (
                        <SearchFilterDropdownField
                            {...fieldProps}
                            searchValue={searchValue}
                            handleSearchValueChange={
                                this.handleSearchValueChange
                            }
                            placeholderText="Search groups and courses e.g. Geography or Year 7"
                        />
                    )}
                    renderDropdownContents={(contentsProps) => (
                        <SearchFilterDropdownContents
                            {...contentsProps}
                            searchValue={searchValue}
                            searchItems={studentGroups}
                            onSearchItemClicked={(id) =>
                                actions.toggleFilterById({
                                    id,
                                    filterType: FILTER_TYPE_ENUM.STUDENT_GROUP,
                                })
                            }
                            emptyText={
                                searchValue
                                    ? 'You don’t have any student groups that match your search.'
                                    : 'All of your assigned student groups have been selected.'
                            }
                        />
                    )}
                    shiftFocusWithLeftAndRightArrows
                />
            </div>
        );
    }
}

UnconnectedStudentGroupFilterDropdown.propTypes = {
    studentGroups: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            displayName: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        toggleFilterById: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    studentGroups: selectStudentGroupFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            toggleFilterById,
        },
        dispatch,
    ),
});

export const StudentGroupFilterDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedStudentGroupFilterDropdown);
