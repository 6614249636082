import classnames from 'classnames';
import PropTypes, {arrayOf} from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {
    selectAttainmentSelected,
    selectAttendanceSelected,
    selectBehaviourSelected,
} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {KpiPanel} from 'Components/kpiPanel';
import {relayoutExtContainer} from 'Utils/relayoutExtContainer';

import {KpiPanelsEmptyState} from './KpiPanelsEmptyState';

import './kpiPanels.scss';

class UnconnectedKpiPanels extends Component {
    constructor(props) {
        super(props);
        this.kpiPanels = null;
        this.setKpiPanelsRef = (element) => {
            this.kpiPanels = element;
        };
    }
    componentDidUpdate = (previousProps) => {
        if (
            this.props.isAttendanceSelected !==
                previousProps.isAttendanceSelected ||
            this.props.isAttainmentSelected !==
                previousProps.isAttainmentSelected ||
            this.props.isBehaviourSelected !== previousProps.isBehaviourSelected
        ) {
            relayoutExtContainer(this.kpiPanels);
        }
    };
    render() {
        const {
            isAttendanceSelected,
            isAttainmentSelected,
            isBehaviourSelected,
            attendanceKpis,
            attainmentKpis,
            behaviourKpis,
            isLoading,
        } = this.props;

        const numberOfSelectedKpiGroups = [
            isAttendanceSelected,
            isBehaviourSelected,
            isAttainmentSelected,
        ].filter(Boolean).length;

        return (
            <div>
                <div
                    ref={this.setKpiPanelsRef}
                    className={classnames(
                        'kpi-panels-container',
                        `kpi-panels-container__${numberOfSelectedKpiGroups}-selected`,
                    )}
                >
                    {isAttendanceSelected && (
                        <KpiPanel
                            title="Attendance"
                            items={attendanceKpis}
                            isLoading={isLoading}
                            isCollapsible={false}
                        />
                    )}
                    {isBehaviourSelected && (
                        <KpiPanel
                            title="Behaviour"
                            items={behaviourKpis}
                            isLoading={isLoading}
                            isCollapsible={false}
                        />
                    )}
                    {isAttainmentSelected && (
                        <KpiPanel
                            title="Attainment"
                            items={attainmentKpis}
                            isLoading={isLoading}
                            isCollapsible={false}
                        />
                    )}
                </div>
                {!isAttendanceSelected &&
                    !isBehaviourSelected &&
                    !isAttainmentSelected && <KpiPanelsEmptyState />}
            </div>
        );
    }
}

const kpiPropType = PropTypes.shape({
    url: PropTypes.string,
    html: PropTypes.string.isRequired,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipUrl: PropTypes.string,
});

UnconnectedKpiPanels.propTypes = {
    attendanceKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    attainmentKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    behaviourKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    isAttendanceSelected: PropTypes.bool,
    isAttainmentSelected: PropTypes.bool,
    isBehaviourSelected: PropTypes.bool,
    isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAttainmentSelected: selectAttainmentSelected(state),
    isAttendanceSelected: selectAttendanceSelected(state),
    isBehaviourSelected: selectBehaviourSelected(state),
});

export const KpiPanels = connect(mapStateToProps)(UnconnectedKpiPanels);
