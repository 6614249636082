import classnames from 'classnames';
import {Component} from 'react';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {parseUnsafeInt} from 'Utils/parseUnsafeInt';
import {relayoutExtContainer} from 'Utils/relayoutExtContainer';

import {KpiBox, KpiBoxLink, LoadingKpiBox, NewKpiBox} from './kpiBox';
import {NewKpiData, OldKpiData, isOldKpiData} from './types';

import './kpiPanel.scss';

type KpiPanelProps = {
    isCollapsible?: boolean;
    isInitiallyCollapsed?: boolean;
    title?: string;
    iconCls?: string;
    kpiCount?: number | string;
    items: (OldKpiData | NewKpiData)[] | null;
    isLoading?: boolean;
    isPreviewMaskVisible?: boolean;
    onKpiBoxClicked?: (kpiIndex: number) => void;
    selectedKpiIndex?: number;
    isTooltipsEnabled?: boolean;
};

type KpiPanelState = {
    isCollapsed?: boolean;
};

export class KpiPanel extends Component<KpiPanelProps, KpiPanelState> {
    private panel: Element | null;
    private setPanelRef: (element: Element | null) => void;

    static defaultProps: {
        isCollapsible: boolean;
        isInitiallyCollapsed: boolean;
        isTooltipsEnabled: boolean;
    } = {
        isCollapsible: true,
        isInitiallyCollapsed: false,
        isTooltipsEnabled: true,
    };
    constructor(props: KpiPanelProps) {
        super(props);
        this.panel = null;
        this.setPanelRef = (element) => {
            this.panel = element;
        };

        // We only ever want to collapse the KPI Panels if collapsible is true.
        // Otherwise passing collapsible=false collapsed=true would cause unusable component
        this.state = {
            isCollapsed:
                this.props.isCollapsible && this.props.isInitiallyCollapsed,
        };
    }
    componentDidUpdate = (
        previousProps: KpiPanelProps,
        previousState: KpiPanelState,
    ) => {
        if (
            (previousProps.items &&
                this.props.items &&
                previousProps.items.length !== this.props.items.length) ||
            previousProps.isLoading !== this.props.isLoading ||
            previousState.isCollapsed !== this.state.isCollapsed
        ) {
            relayoutExtContainer(this.panel);
        }
    };
    toggleCollapse = () => {
        if (this.props.isCollapsible) {
            this.setState((prevState) => ({
                isCollapsed: !prevState.isCollapsed,
            }));
        }
    };
    render() {
        const {
            title,
            isCollapsible,
            iconCls,
            kpiCount,
            items,
            isLoading,
            isPreviewMaskVisible,
            selectedKpiIndex,
            isTooltipsEnabled,
        } = this.props;

        const {isCollapsed} = this.state;

        const parsedKpiCount = parseUnsafeInt(kpiCount, 6);

        return (
            <section
                ref={this.setPanelRef}
                className={classnames('arbor-kpi-panel', {
                    'arbor-kpi-panel-collapsed': isCollapsed,
                })}
                aria-label={title ? `KPI Panel: ${title}` : 'KPI Panel'}
            >
                {typeof title !== 'undefined' && (
                    <KeyboardFocusableButton
                        buttonWrapperClassName={classnames(
                            'arbor-kpi-panel-header__wrapper',
                            {
                                'arbor-kpi-panel-header__wrapper--collapsed':
                                    isCollapsed,
                            },
                        )}
                        className={classnames('arbor-kpi-panel-header', {
                            'arbor-kpi-panel-header--collapsible':
                                isCollapsible,
                        })}
                        onClick={this.toggleCollapse}
                        insetFocusRing
                        tabIndex={isCollapsible ? 0 : -1}
                        aria-label={
                            isCollapsible
                                ? `Expand/Collapse Kpi Panel ${title}`
                                : `-Kpi Panel ${title}`
                        }
                    >
                        {iconCls && <Icon iconName={iconCls} />}
                        <span>{title}</span>
                        {isCollapsible && (
                            <Icon
                                data-testid="kpi-panel-collapse-icon"
                                className="arbor-kpi-panel-header__collapse-icon"
                                iconName={
                                    isCollapsed
                                        ? 'arrow-go-down'
                                        : 'arrow-go-up'
                                }
                            />
                        )}
                    </KeyboardFocusableButton>
                )}
                {isPreviewMaskVisible && (
                    <div className="kpi-panel__preview-subheader">
                        <Icon
                            iconName="private"
                            className="kpi-panel__preview-subheader__icon"
                        />
                        <span>You do not have access to these measures!</span>
                    </div>
                )}
                {isLoading || (items && items.length === 0) ? (
                    <div
                        className="arbor-kpi-panel-content"
                        style={isCollapsed ? {display: 'none'} : {}}
                    >
                        {new Array(parsedKpiCount)
                            .fill(0)
                            .map((_loadingBox, index) => (
                                <div className="kpi-box-wrapper" key={index}>
                                    <LoadingKpiBox />
                                </div>
                            ))}
                    </div>
                ) : (
                    <div
                        className="arbor-kpi-panel-content"
                        style={isCollapsed ? {display: 'none'} : {}}
                    >
                        {items &&
                            items.map(
                                (item: OldKpiData | NewKpiData, index) => (
                                    <div
                                        className="kpi-box-wrapper"
                                        style={
                                            isOldKpiData(item)
                                                ? {}
                                                : {height: '100%'}
                                        }
                                        key={`${title}-${index}`}
                                    >
                                        <KpiBoxLink
                                            url={item.url}
                                            title={item.title}
                                            {...(isTooltipsEnabled
                                                ? {
                                                      tooltip:
                                                          item.tooltip ||
                                                          item.tooltipHTML,
                                                      tooltipUrl:
                                                          item.tooltipUrl,
                                                  }
                                                : {})}
                                            onClick={() => {
                                                if (
                                                    typeof this.props
                                                        .onKpiBoxClicked ===
                                                    'function'
                                                ) {
                                                    this.props.onKpiBoxClicked(
                                                        index,
                                                    );
                                                }
                                            }}
                                        >
                                            {isOldKpiData(item) ? (
                                                <KpiBox
                                                    html={item.html}
                                                    isClickable={
                                                        !!item.url ||
                                                        typeof this.props
                                                            .onKpiBoxClicked ===
                                                            'function'
                                                    }
                                                    isSelected={
                                                        selectedKpiIndex ===
                                                        index
                                                    }
                                                />
                                            ) : (
                                                <NewKpiBox
                                                    {...item}
                                                    isClickable={
                                                        !!item.url ||
                                                        typeof this.props
                                                            .onKpiBoxClicked ===
                                                            'function'
                                                    }
                                                    isSelected={
                                                        selectedKpiIndex ===
                                                        index
                                                    }
                                                />
                                            )}
                                        </KpiBoxLink>
                                    </div>
                                ),
                            )}
                        {isPreviewMaskVisible && (
                            <div className="kpi-panel__preview-mask" />
                        )}
                    </div>
                )}
            </section>
        );
    }
}
