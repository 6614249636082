import Scroll from 'Interfaces/Scroll';

class Debouncer {
    // This class debounces any function passed in, even if they are different functions,
    // or called with different arguments.
    currentTimeout = null;

    debounce = (callback, wait) => {
        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        }
        this.currentTimeout = setTimeout(callback, wait);
    };
}

const debouncer = new Debouncer();
const relayoutDebounceTime = 500;

export const relayoutExtContainer = (element) => {
    if (!element) {
        console.error('no element ref passed to relayoutExtContainer');
    }

    debouncer.debounce(() => {
        if (typeof element?.closest !== 'function') {
            return;
        }
        const extContainerNode = element.closest(
            '.mis-ext-layout-container, .mis-window',
        );
        if (extContainerNode && extContainerNode.id) {
            const extContainerComponent = Ext.getCmp(extContainerNode.id);
            if (
                extContainerComponent &&
                typeof extContainerComponent.updateLayout === 'function'
            ) {
                Scroll.savePageScroll();
                extContainerComponent.updateLayout();
                Scroll.restorePageScroll();
            }
        }
    }, relayoutDebounceTime);
};

export const relayoutAllExtContainers = () => {
    window.dispatchEvent(new Event('resize'));
};
