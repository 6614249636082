import './emergencyAlertIcon.scss';

export const EmergencyAlertIcon = () => (
    <svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
        <path
            className="emergency-alert-icon"
            d="M14.6385 1.53336L22.8234 15.4854C23.0993 15.9415 23.2465 16.4613 23.2499 16.9915C23.2533 17.5218 23.1128 18.0434 22.8427 18.5028C22.5726 18.9623 22.1827 19.3431 21.713 19.6061C21.2433 19.8692 20.7106 20.0051 20.1697 19.9999H3.77946C3.2428 19.9963 2.7166 19.854 2.254 19.5873C1.79139 19.3206 1.40875 18.9391 1.14471 18.4811C0.880671 18.0232 0.744575 17.5051 0.750166 16.9791C0.755756 16.4531 0.902834 15.9378 1.17655 15.4854L9.31064 1.53336C9.5752 1.06847 9.96159 0.681246 10.4299 0.411684C10.8982 0.142122 11.4315 0 11.9746 0C12.5177 0 13.0509 0.142122 13.5192 0.411684C13.9875 0.681246 14.3739 1.06847 14.6385 1.53336Z"
        />
        <path
            className="emergency-alert-icon-exclamation"
            d="M12 14C11.7528 14 11.5111 14.066 11.3055 14.1896C11.1 14.3132 10.9398 14.4889 10.8452 14.6945C10.7505 14.9 10.7258 15.1262 10.774 15.3445C10.8223 15.5627 10.9413 15.7632 11.1161 15.9205C11.2909 16.0778 11.5137 16.185 11.7561 16.2284C11.9986 16.2718 12.2499 16.2495 12.4784 16.1644C12.7068 16.0792 12.902 15.935 13.0393 15.75C13.1767 15.565 13.25 15.3475 13.25 15.125C13.25 14.8266 13.1183 14.5405 12.8839 14.3295C12.6495 14.1185 12.3315 14 12 14ZM12 6.25C11.6685 6.25 11.3505 6.36853 11.1161 6.57951C10.8817 6.79048 10.75 7.07663 10.75 7.375V11.875C10.75 12.1734 10.8817 12.4595 11.1161 12.6705C11.3505 12.8815 11.6685 13 12 13C12.3315 13 12.6495 12.8815 12.8839 12.6705C13.1183 12.4595 13.25 12.1734 13.25 11.875V7.375C13.25 7.07663 13.1183 6.79048 12.8839 6.57951C12.6495 6.36853 12.3315 6.25 12 6.25Z"
        />
    </svg>
);
