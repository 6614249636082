import classnames from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import {
    FILTER_TAG_COLOR_ENUM,
    filterTagColorsPropType,
} from './filterTagColors';

import './filterTag.scss';

export function FilterTag({
    filter: {displayName, selected},
    onClick: handleClick,
    color,
    isInactive,
    ...rest
}) {
    return (
        <KeyboardFocusableButton
            className={classnames('dashboard-kpi-filter-tag', {
                [`dashboard-kpi-filter-tag--${color}`]: !isInactive,
                'dashboard-kpi-filter-tag-selected': selected && !isInactive,
            })}
            buttonWrapperClassName="dashboard-kpi-filter-tag-keyboard-focusable-wrapper"
            onClick={handleClick}
            {...rest}
        >
            <span>{displayName}</span>
            {selected && (
                <Icon
                    iconName="cross"
                    className="dashboard-kpi-filter-tag-close"
                />
            )}
        </KeyboardFocusableButton>
    );
}

FilterTag.defaultProps = {
    color: FILTER_TAG_COLOR_ENUM.BLUE,
};
FilterTag.propTypes = {
    filter: PropTypes.exact({
        id: PropTypes.string,
        displayName: PropTypes.string,
        selected: PropTypes.bool,
    }).isRequired,
    color: filterTagColorsPropType,
    onClick: PropTypes.func,
    isInactive: PropTypes.bool,
};
