import {ComboboxItem, SelectedItems} from './types';

export const createInitialSelectedValues = (
    initialValue: (string | number)[],
    currentValue: SelectedItems,
    items: ComboboxItem[],
    isMultiselect: boolean,
    isLoading: boolean,
): SelectedItems => {
    const initialSelectedValues = initialValue.reduce<SelectedItems>(
        (acc, current) => {
            if (current === '') {
                return acc;
            }
            if (isLoading) {
                // During remote combobox loading it is common that the value
                // is not in the combobox's options. So only filter the selected values
                // once the combobox has actually loaded
                acc[current] = true;
                return acc;
            }

            const match = items.find(
                (item) => String(item.value) === String(current),
            );
            if (match) {
                acc[match.value] = true;
                return acc;
            }

            console.error('No item in combobox with value', current);
            return acc;
        },
        {},
    );

    const currentSelectedValues = Object.keys(
        currentValue,
    ).reduce<SelectedItems>((acc, currentKey) => {
        const value = currentValue[currentKey];
        if (!value) {
            return acc;
        }
        if (isLoading) {
            // During remote combobox loading it is common that the value
            // is not in the combobox's options. So only filter the selected values
            // once the combobox has actually loaded
            acc[currentKey] = true;
            return acc;
        }

        const match = items.find(
            (item) => String(item.value) === String(currentKey),
        );
        if (match) {
            acc[match.value] = true;
            return acc;
        }

        console.error('No item in combobox with value', currentKey);
        return acc;
    }, {});

    if (isMultiselect) {
        return {
            ...initialSelectedValues,
            ...currentSelectedValues,
        };
    }
    return Object.keys(currentSelectedValues).length > 0
        ? currentSelectedValues
        : initialSelectedValues;
};

export const isAnythingSelected = (selectedItems: SelectedItems) => {
    return Object.entries(selectedItems).some(([, value]) => value);
};
