export const FavouritesIcon = () => (
    <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_6659_3128)">
            <path
                d="M19.9665 7.18716C19.9034 7.00544 19.7887 6.84582 19.6362 6.72766C19.4838 6.60951 19.3002 6.5379 19.1076 6.52151L13.4254 5.6969L10.8788 0.560449C10.797 0.392475 10.6694 0.250814 10.5104 0.151693C10.3515 0.0525715 10.1676 0 9.98005 0C9.79244 0 9.60864 0.0525715 9.44968 0.151693C9.29072 0.250814 9.16304 0.392475 9.08127 0.560449L6.53474 5.68696L0.852469 6.52151C0.667643 6.54764 0.493882 6.6248 0.350902 6.74421C0.207923 6.86363 0.101452 7.02052 0.0435705 7.1971C-0.00941244 7.36964 -0.014167 7.55329 0.0298169 7.72832C0.0738009 7.90335 0.164863 8.06316 0.293231 8.19061L4.41762 12.1646L3.41898 17.8078C3.38333 17.994 3.40199 18.1866 3.47277 18.3626C3.54356 18.5387 3.6635 18.6909 3.81843 18.8013C3.96944 18.9087 4.14756 18.9721 4.33278 18.9844C4.518 18.9967 4.703 18.9573 4.86701 18.8708L9.98005 16.2182L15.0731 18.8808C15.2133 18.9594 15.3716 19.0005 15.5325 19C15.744 19.0007 15.9503 18.9346 16.1217 18.8112C16.2766 18.7008 16.3966 18.5486 16.4673 18.3725C16.5381 18.1965 16.5568 18.004 16.5211 17.8177L15.5225 12.1746L19.6469 8.20054C19.7911 8.07901 19.8976 7.91928 19.9542 7.7399C20.0108 7.56052 20.015 7.36885 19.9665 7.18716ZM13.8248 11.1612C13.7077 11.2739 13.6201 11.4134 13.5696 11.5675C13.5191 11.7216 13.5073 11.8857 13.5352 12.0454L14.2542 16.2082L10.4993 14.2212C10.3549 14.1447 10.1937 14.1046 10.03 14.1046C9.86629 14.1046 9.7051 14.1447 9.56062 14.2212L5.80573 16.2082L6.52475 12.0454C6.55265 11.8857 6.54084 11.7216 6.49036 11.5675C6.43988 11.4134 6.35226 11.2739 6.23514 11.1612L3.23922 8.18067L7.4435 7.57463C7.60528 7.55224 7.75907 7.49072 7.89139 7.39545C8.02371 7.30019 8.13052 7.17408 8.20247 7.0282L9.98005 3.24293L11.8575 7.03813C11.9294 7.18402 12.0362 7.31012 12.1686 7.40539C12.3009 7.50065 12.4547 7.56218 12.6165 7.58456L16.8207 8.19061L13.8248 11.1612Z"
                fill="#776E6A"
            />
        </g>
        <defs>
            <clipPath id="clip0_6659_3128">
                <rect width="20" height="19" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
