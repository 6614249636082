import PropTypes, {arrayOf} from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchKpiDataRequest} from 'BackendRenderedComponents/dashboard/redux/actions';
import {
    selectAttendanceKpis,
    selectBehaviourKpis,
    selectAttainmentKpis,
    selectNumberOfStudents,
    selectKpiDataLoading,
} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {TAB_IDS} from 'BackendRenderedComponents/dashboard/redux/types';
import {LoadingDots} from 'Components/animations';

import {ViewFilterDropdown} from '../dropdowns';
import {KpiPanels} from '../KpiPanels';

import './wholeSchoolTab.scss';

class UnconnectedWholeSchoolTab extends Component {
    componentDidMount = () => {
        this.props.actions.fetchKpiDataRequest({tabId: TAB_IDS.WHOLE_SCHOOL});
    };
    render() {
        const {
            attendanceKpis,
            behaviourKpis,
            attainmentKpis,
            numberOfStudents,
            isLoading,
        } = this.props;

        return (
            <div>
                <div className="dashboard-whole-school-container">
                    <p className="dashboard-whole-school-student-number">
                        Showing: All students (
                        {isLoading ? <LoadingDots /> : numberOfStudents})
                    </p>
                    <div className="view-filter-dropdown-wrapper">
                        <ViewFilterDropdown />
                    </div>
                </div>
                <KpiPanels
                    attendanceKpis={attendanceKpis}
                    behaviourKpis={behaviourKpis}
                    attainmentKpis={attainmentKpis}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

const kpiPropType = PropTypes.shape({
    url: PropTypes.string,
    html: PropTypes.string.isRequired,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipUrl: PropTypes.string,
});

UnconnectedWholeSchoolTab.propTypes = {
    attendanceKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    attainmentKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    behaviourKpis: PropTypes.exact({
        id: PropTypes.string,
        kpis: arrayOf(kpiPropType),
    }),
    numberOfStudents: PropTypes.number,
    isLoading: PropTypes.bool,
    actions: PropTypes.exact({
        fetchKpiDataRequest: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    attendanceKpis: selectAttendanceKpis(state, TAB_IDS.WHOLE_SCHOOL),
    behaviourKpis: selectBehaviourKpis(state, TAB_IDS.WHOLE_SCHOOL),
    attainmentKpis: selectAttainmentKpis(state, TAB_IDS.WHOLE_SCHOOL),
    numberOfStudents: selectNumberOfStudents(state, TAB_IDS.WHOLE_SCHOOL),
    isLoading: selectKpiDataLoading(state, TAB_IDS.WHOLE_SCHOOL),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            fetchKpiDataRequest,
        },
        dispatch,
    ),
});

export const WholeSchoolTab = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedWholeSchoolTab);
