import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import './burgerMenuBackButton.scss';

const LeftArrowIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M12.4938 1.69841L6.20016 7.99206L12.4938 14.2857C12.5906 14.3825 12.6541 14.527 12.6541 14.654C12.6541 14.7825 12.5906 14.9254 12.4938 15.0222L11.6938 15.8222C11.597 15.919 11.4525 15.9825 11.3256 15.9825C11.197 15.9825 11.0541 15.919 10.9573 15.8222L3.49381 8.36032C3.39699 8.26349 3.3335 8.11905 3.3335 7.99206C3.3335 7.86349 3.39699 7.72064 3.49381 7.62381L10.9557 0.160317C11.0525 0.0634921 11.197 0 11.324 0C11.4525 0 11.5954 0.0634921 11.6922 0.160317L12.4938 0.960317C12.5906 1.05714 12.6541 1.18571 12.6541 1.32857C12.6541 1.45873 12.5906 1.60159 12.4938 1.69841Z"
            fill="#33AABF"
        />
    </svg>
);

type BurgerMenuBackButtonProps = {
    backButtonOnClick: () => void;
    backButtonText?: string;
};

export const BurgerMenuBackButton = ({
    backButtonOnClick,
    backButtonText,
}: BurgerMenuBackButtonProps) => {
    return (
        <KeyboardFocusableButton
            onClick={backButtonOnClick}
            className="navigation-back-button"
            buttonWrapperClassName="navigation-back-button__wrapper"
            insetFocusRing
        >
            <LeftArrowIcon />
            {backButtonText && <span>{backButtonText}</span>}
        </KeyboardFocusableButton>
    );
};
