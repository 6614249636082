import Export from 'Config/External';
import {raiseDevError} from 'Interfaces/error/Error';
import {httpGet, httpPost} from 'Interfaces/httpClient';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {isLoggedIn} from 'Interfaces/Session';
import {subscribe} from 'Interfaces/websocket';
import Pubsub from 'Services/pubsub/Pubsub';
import Url from 'Services/url/Url';
import {GenericResponse, ErrorResponseData} from 'Types/response';

export interface INotificationMetadata {
    unreadCount: number;
    pushChannel: string;
}

export interface INotification {
    action: string;
    content?: string;
    created?: number;
    icon?: string;
    image?: string;
    isRead: boolean;
    status: string;
    title?: string;
    url?: string;
    userNotificationId: number;
    enablePreview?: boolean;
    previewAutoDismissable?: boolean;
}

export const NOTIFICATION_PREVIEW_PORTAL_ID = 'notification-preview-portal';

export function initNotificationPreviewPortal() {
    const notificationPreviewPortal = document.createElement('div');
    notificationPreviewPortal.id = NOTIFICATION_PREVIEW_PORTAL_ID;
    notificationPreviewPortal.style['z-index'] = 20000;
    notificationPreviewPortal.style.position = 'fixed';
    document.body.appendChild(notificationPreviewPortal);

    return notificationPreviewPortal;
}

interface IPolllingNotification {
    body: {
        lastChangeId: number;
        total: number;
    };
    header: {
        channel: string;
    };
}

type INotificationPollingResponse = GenericResponse<IPolllingNotification>;

class UserNotification {
    private longPollingInterval: number;
    notifications: INotificationMetadata;
    private notificationsList: INotification[];
    private notificationsLoaded: boolean;

    constructor() {
        this.longPollingInterval = 30000;

        this.notifications = {
            unreadCount: 0,
            pushChannel: '',
        };
        this.notificationsList = [];
        this.notificationsLoaded = false;
    }

    init = (notifications?: INotificationMetadata) => {
        Object.assign(this.notifications, notifications);
        if (isLoggedIn()) {
            if (
                Export.getConfig().pushNotificationSettings &&
                Export.getConfig().pushNotificationSettings.enabled
            ) {
                this.initSocket();
            } else {
                this.initLongPolling();
            }
        }
    };

    initSocket = () => {
        subscribe(
            this.notifications.pushChannel,
            'notifications:new_notification',
            this.addNotification,
        );
    };

    initLongPolling = () => {
        return httpGet<INotificationPollingResponse>(Url.USER_NOTIFICATION.LIST)
            .then((response) => {
                if (response) {
                    if (!response.success) {
                        raiseDevError({
                            title: 'User notification list error!',
                            response: response as unknown as ErrorResponseData,
                        });
                        reportToSisSentry(
                            new Error('Error with XHR response!'),
                            {
                                url: Url.USER_NOTIFICATION.LIST,
                                response,
                            },
                        );
                    }

                    if (!response.items) {
                        //Error
                        raiseDevError({
                            title: 'User notification list error!',
                            response: response as unknown as ErrorResponseData,
                        });
                        reportToSisSentry(
                            new Error('Error with XHR response!'),
                            {
                                url: Url.USER_NOTIFICATION.LIST,
                                response,
                            },
                        );
                        return;
                    }

                    for (const k in response.items) {
                        if (response.items.hasOwnProperty(k)) {
                            const item = response.items[k];
                            const channel = item.header.channel;
                            if (
                                channel.substr(channel.lastIndexOf('.') + 1) ===
                                'UserNotifications'
                            ) {
                                this.setCounter(item.body.total);
                            } else {
                                raiseDevError({
                                    title: 'User Notifications',
                                    message: 'Message type not recognized',
                                });
                                reportToSisSentry(
                                    new Error('Error with XHR response!'),
                                    {
                                        url: Url.USER_NOTIFICATION.LIST,
                                        response,
                                        message: 'Message type not recognized!',
                                    },
                                );
                            }
                        }
                    }

                    setTimeout(this.initLongPolling, this.longPollingInterval);
                } else {
                    raiseDevError({
                        title: 'User Notifications',
                        message: 'Messages - No response received',
                    });
                    reportToSisSentry(new Error('Error with XHR response!'), {
                        url: Url.USER_NOTIFICATION.LIST,
                        response,
                        message: 'Empty response!',
                    });
                }
            })
            .catch((response) => {
                raiseDevError({
                    title: 'User notification list error!',
                    response,
                });
                reportToSisSentry(new Error('Error with XHR response!'), {
                    url: Url.USER_NOTIFICATION.LIST,
                    response,
                });
            });
    };

    fetchNotifications = async () => {
        try {
            // eslint-disable-next-line camelcase
            const response = await httpPost(
                Url.USER_NOTIFICATION.GET_NOTIFICATIONS,
                // eslint-disable-next-line camelcase
                {action_params: {limit: 40}},
            );

            this.notificationsList = response.items || [];
            this.notificationsLoaded = true;
            this.calculateCounter(this.notificationsList);
            return this.notificationsList;
        } catch (error) {
            console.error('getNotifications error', error);
            return [];
        }
    };

    getNotifications = async () => {
        if (this.notificationsLoaded) {
            if (this.notifications.unreadCount) {
                this.setCounter(0);
                httpPost(Url.USER_NOTIFICATION.MARK_NOTIFICATIONS_AS_SEEN);
            }
            return Promise.resolve(this.notificationsList);
        }

        return await this.fetchNotifications();
    };

    addNotification = (notification: INotification) => {
        this.notificationsList.unshift(notification);
        this.notifications.unreadCount++;
        this.setCounter(this.notifications.unreadCount);
        // if a notification is pushed set notificationLoaded to false so they will be refetched on notification panel open
        this.notificationsLoaded = false;
        if (notification.enablePreview) {
            Pubsub.publish('receivedPreviewNotification', notification);
        }
    };

    refreshNotifications = () => {
        Pubsub.publish('refreshNotificationList', this.notificationsList);
    };

    markNotificationAsRead = async (notification: INotification) => {
        try {
            const response = await httpPost(
                Url.USER_NOTIFICATION.MARK_NOTIFICATION_AS_READ,
                {
                    fields: {
                        userNotification: {
                            value: {
                                _objectId: notification.userNotificationId,
                                _objectTypeId: 730,
                            },
                        },
                    },
                },
            );

            const {success} = response;
            if (success) {
                const targetNotification = this.notificationsList.find(
                    (x) =>
                        x.userNotificationId ===
                        notification.userNotificationId,
                );
                if (targetNotification) {
                    targetNotification.isRead = true;
                    this.refreshNotifications();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    markNotificationAsHidden = async (notification: INotification) => {
        try {
            const response = await httpPost(
                Url.USER_NOTIFICATION.MARK_NOTIFICATION_AS_HIDDEN,
                {
                    fields: {
                        userNotification: {
                            value: {
                                _objectId: notification.userNotificationId,
                                _objectTypeId: 730,
                            },
                        },
                    },
                },
            );

            const {success} = response;
            if (success) {
                const targetNotificationIndex =
                    this.notificationsList.findIndex(
                        (x) =>
                            x.userNotificationId ===
                            notification.userNotificationId,
                    );
                if (targetNotificationIndex !== -1) {
                    this.notificationsList.splice(targetNotificationIndex, 1);
                    if (this.notificationsList.length === 0) {
                        await this.fetchNotifications();
                    }
                    this.refreshNotifications();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    setCounter = (count: number) => {
        this.notifications.unreadCount = count;
        Pubsub.publish('updateNotificationCounter', count);
    };

    calculateCounter = (notificationsList: INotification[]) => {
        let unreadCount = 0;
        for (const message of notificationsList) {
            if (message.status === 'NEW') {
                unreadCount++;
            }
        }
        this.setCounter(unreadCount);
    };
}

let userNotificationInstance: UserNotification | null = null;
export const getUserNotificationService = () => {
    if (!userNotificationInstance) {
        userNotificationInstance = new UserNotification();
    }
    return userNotificationInstance;
};
