import {updateDynamicNavAction} from 'State/navigation/actions';
import Store from 'State/Store';

import type {TransformedNavigationItem} from 'State/navigation/types';
// Note: this helper function exists to make it easier to mock this functionality
// in renderPage.test.js, and hide the store details
export const updateDynamicNavData = ({
    treeData,
    title,
    parentStaticRoute,
    currentUrl,
    parentStaticTreeOptionalParams,
    isNewDynamicNavigationData,
}: {
    treeData: TransformedNavigationItem[];
    title: string;
    currentUrl: string;
    parentStaticRoute?: string;
    parentStaticTreeOptionalParams?: Record<string, string | null>;
    isNewDynamicNavigationData: boolean;
}) =>
    Store.getStore().dispatch(
        updateDynamicNavAction({
            treeData,
            title,
            currentUrl,
            parentStaticRoute,
            parentStaticTreeOptionalParams,
            isNewDynamicNavigationData,
        }),
    );
