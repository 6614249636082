import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {toggleFilterById} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectKpiGroupFilter} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {FILTER_TYPE_ENUM} from 'BackendRenderedComponents/dashboard/redux/types';
import {getSelectedCount} from 'BackendRenderedComponents/dashboard/utils';
import {DropdownContainer} from 'Components/dropdown';
import {
    MultiSelectDropdownContents,
    MultiSelectDropdownField,
} from 'Components/dropdown/multiSelectDropdown';

import './viewFilterDropdown.scss';

const kpiGroupDisplayNames = {
    attendance: 'Attendance',
    behaviour: 'Behaviour',
    attainment: 'Attainment',
};

const UnconnectedViewFilterDropdown = ({actions, kpiGroups}) => {
    const viewChanged = ({id}) => {
        actions.toggleFilterById({filterType: FILTER_TYPE_ENUM.KPI_GROUP, id});
    };
    const kpiGroupsWithDisplayNames = kpiGroups.map((group) => ({
        ...group,
        displayName: kpiGroupDisplayNames[group.id],
    }));

    const selectedCount = getSelectedCount(kpiGroups);

    let description;
    if (selectedCount === 0) {
        description = 'View: None';
    } else if (selectedCount === kpiGroups.length) {
        description = 'View: All';
    } else {
        description = `View: ${selectedCount} selected`;
    }
    return (
        <div className="view-filter-dropdown-container">
            <DropdownContainer
                renderDropdownField={(dropdownProps) => (
                    <MultiSelectDropdownField
                        {...dropdownProps}
                        description={description}
                    />
                )}
                renderDropdownContents={(dropdownProps) => (
                    <MultiSelectDropdownContents
                        {...dropdownProps}
                        items={kpiGroupsWithDisplayNames}
                        onChange={viewChanged}
                    />
                )}
            />
        </div>
    );
};

UnconnectedViewFilterDropdown.propTypes = {
    kpiGroups: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    actions: PropTypes.exact({
        toggleFilterById: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    kpiGroups: selectKpiGroupFilter(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            toggleFilterById,
        },
        dispatch,
    ),
});

export const ViewFilterDropdown = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedViewFilterDropdown);
