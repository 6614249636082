import {
    NewKpiData,
    NewKpiResponseItem,
    OldKpiData,
    isOldKpiData,
} from 'Components/kpiPanel';
import {httpGet} from 'Interfaces/httpClient';

type OldKpiDataResponseItem = {
    fields: {
        title: {value: string};
        tooltip?: {value: string};
        tooltipUrl?: {value: string};
        html: {value: string};
        url?: {value: string};
    };
};

type OldKpiDataResponse = {
    items: OldKpiDataResponseItem[];
};

type NewKpiDataResponse = NewKpiResponseItem[];

type KpiDataServiceResponse = OldKpiDataResponse | NewKpiDataResponse;

export const normaliseKpiResponseData = (data: OldKpiDataResponseItem) => {
    if (!data.fields) {
        return null;
    }
    const {url, html, title, tooltip, tooltipUrl} = data.fields;
    const normalisedData: OldKpiData = {html: html?.value, title: title?.value};

    if (typeof url?.value === 'string') {
        normalisedData.url = url?.value;
    }

    if (typeof tooltipUrl?.value === 'string') {
        normalisedData.tooltipUrl = tooltipUrl?.value;
    } else if (typeof tooltip?.value === 'string') {
        normalisedData.tooltip = tooltip?.value;
    }

    if (Object.keys(normalisedData).length === 0) {
        return null;
    }
    return normalisedData;
};

export const normaliseNewKpiResponseData = (inputData: NewKpiResponseItem) => {
    let numberOfSegments: number | undefined;
    let maxAbsValue = 0;
    let unbounded: boolean | undefined;
    let {min, max, midPoint} = inputData;

    const bars =
        typeof inputData.bars === 'undefined'
            ? inputData.bars
            : inputData.bars.map(({value, ...others}) => ({
                  value: typeof value === 'number' ? value : Number(value),
                  ...others,
              }));

    if (bars && bars.length > 0) {
        bars.forEach((bar) => {
            const barAbsValue =
                inputData.midPoint === null
                    ? bar.value
                    : Math.abs(inputData.midPoint - bar.value);

            if (barAbsValue > maxAbsValue) {
                maxAbsValue = barAbsValue;
            }
        });
    }
    if (!(inputData.midPoint === null)) {
        maxAbsValue += inputData.midPoint;
    }

    if (inputData.max === null && !inputData.segments) {
        unbounded = true;
    }

    if (inputData.midPoint !== null) {
        if (inputData.min !== null && inputData.max !== null) {
            // set midPoint to the actual middle
            midPoint = (inputData.max + inputData.min) / 2;
        } else {
            max = maxAbsValue;
            min = 2 * inputData.midPoint - maxAbsValue;
        }
    } else {
        if (inputData.min === null) {
            min = 0;
        }
        if (inputData.max === null) {
            max = maxAbsValue;
        }
    }

    if (inputData.segments && max !== null && min !== null) {
        numberOfSegments = max - min;
    }

    return {
        ...inputData,
        numberOfSegments,
        unbounded,
        bars,
        min,
        max,
        midPoint,
    } as NewKpiData;
};

function isOldKpiResponse(
    data: KpiDataServiceResponse,
): data is OldKpiDataResponse {
    return 'items' in data && data.items.length > 0;
}

function isNewKpiResponse(
    data: KpiDataServiceResponse,
): data is NewKpiDataResponse {
    if ('items' in data) {
        return false;
    }
    return data.length > 0;
}

export const getKpiDataService = async (
    url: string,
    signal: AbortSignal | undefined,
) => {
    const response: KpiDataServiceResponse | undefined = await httpGet(
        url,
        {},
        signal,
    );
    if (!response) {
        // Responses may be cancelled via AbortSignal. If so response will be undefined
        return null;
    }

    if (isOldKpiResponse(response)) {
        return response.items
            .map((item) => normaliseKpiResponseData(item))
            .filter(isOldKpiData);
    }
    if (isNewKpiResponse(response)) {
        return response.map((item) => normaliseNewKpiResponseData(item));
    }
    return null;
};
