import {UserFavouritePage} from 'Root/core/services/session/fetchCurrentUserSettings';

import {TransformedNavigationItem} from '../types';

export type FlattenedTreeItem = {
    id: string;
    text?: string;
    url?: string;
    hidden?: boolean;
    hiddenReason?: string;
    favouritedPage?: UserFavouritePage;
    description?: string;
    alternativeNames?: string[];
};
const createSimpleItem = (
    item: TransformedNavigationItem,
    breadCrumbTrail: string[],
): FlattenedTreeItem => {
    return {
        id: item.id,
        text: item.text,
        url: item.url,
        hidden: item.hidden,
        hiddenReason: item.hiddenReason,
        favouritedPage: item.favouritedPage,
        alternativeNames: item.alternativeNames,
        description: breadCrumbTrail.join(' > '),
    };
};

export const flattenNavigationTree = (
    treeData: TransformedNavigationItem[],
    breadCrumbTrail: string[] = [],
): FlattenedTreeItem[] => {
    const flattened = treeData.reduce<FlattenedTreeItem[]>((acc, item) => {
        if (item.url) {
            return [...acc, createSimpleItem(item, breadCrumbTrail)];
        }

        if (!item.items) {
            return acc;
        }
        // handle entry points
        if (item.entryPoint) {
            return [
                ...acc,
                createSimpleItem(
                    {...item, url: item.entryPoint},
                    breadCrumbTrail,
                ),
                ...flattenNavigationTree(item.items, [
                    ...breadCrumbTrail,
                    item.text ?? '',
                ]),
            ];
        }
        return [
            ...acc,
            ...flattenNavigationTree(item.items, [
                ...breadCrumbTrail,
                item.text ?? '',
            ]),
        ];
    }, [] as FlattenedTreeItem[]);
    return flattened;
};
