const delayMap = {
    0: 0,
    1: 0,
    2: 10,
    3: 100,
    4: 500,
    5: 1000,
    6: 2000,
} as const;

const maxDepth = 6;

/**
 * Simple retry with exponential(ish) backoff.
 * Condition function is called to check if the function is ready to be called.
 * For now just used by the chart component. If needed by other components
 * may be worth making the maxDepth an option that can be passed
 */
export const callFunctionWithRetry = async (
    functionToCall: () => void,
    conditionFunction: () => boolean,
    errorCallback: () => void,
    depth = 0,
) => {
    if (conditionFunction()) {
        functionToCall();
        return;
    }
    if (depth > maxDepth) {
        errorCallback();
        return;
    }
    await new Promise((resolve) => setTimeout(resolve, delayMap[depth]));
    await callFunctionWithRetry(
        functionToCall,
        conditionFunction,
        errorCallback,
        depth + 1,
    );
};
