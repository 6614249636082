import PropTypes from 'prop-types';
import {Component} from 'react';

import './checkout.scss';

class Checkout extends Component {
    render() {
        const {children} = this.props;

        return <div className="arbor-checkout">{children}</div>;
    }
}

Checkout.propTypes = {
    children: PropTypes.node,
};

export default Checkout;
