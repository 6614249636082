import PropTypes from 'prop-types';
import {Component} from 'react';

import {NavigationTree} from 'Components/navigationTree';

import {BurgerMenuBackButton} from '../buttons/burgerMenuBackButton/BurgerMenuBackButton';

import './navigation.scss';

export class NavigationSubTree extends Component {
    constructor() {
        super();

        this.state = {
            expandedIds: {},
        };
    }

    toggleItemExpanded = ({itemId}) => {
        this.setState((prevState) => ({
            expandedIds: {
                ...prevState.expandedIds,
                [itemId]: !prevState.expandedIds[itemId],
            },
        }));
    };
    render() {
        const {navigationData, onBackButtonClicked} = this.props;
        const {expandedIds} = this.state;

        return (
            <div>
                <BurgerMenuBackButton
                    backButtonOnClick={onBackButtonClicked}
                    backButtonText="All Categories"
                />
                <h2 className="navigation-tree-title">{navigationData.text}</h2>
                <NavigationTree
                    treeData={navigationData.items}
                    toggleItemExpanded={this.toggleItemExpanded}
                    expandedIds={expandedIds}
                    isMobileLayout
                    gainsightEventType="Burger Menu Main Nav Link"
                />
            </div>
        );
    }
}
NavigationSubTree.propTypes = {
    navigationData: PropTypes.array,
    onBackButtonClicked: PropTypes.func,
};
