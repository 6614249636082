import {useRef, useState} from 'react';

import {autoDismiss} from 'Config/Durations';
import {SYSTEM_NOTIFICATION} from 'Config/Events';
import {ISystemNotification} from 'Root/types/systemNotifications';
import {usePubSub} from 'Services/pubsub';

function isNotification(
    possibleNotification: ISystemNotification | object,
): possibleNotification is ISystemNotification {
    return (
        Object.keys(possibleNotification).length > 0 &&
        'message' in possibleNotification
    );
}

import {SystemNotificationList} from './SystemNotificationList';

export const SystemNotificationManager = () => {
    const [notifications, setNofifications] = useState<
        (ISystemNotification & {id: number})[]
    >([]);
    // a counter for unique IDs for notifications
    const idRef = useRef(-1);

    const timeouts = useRef<Record<number, NodeJS.Timeout>>({});

    const setId = (notification: ISystemNotification) => {
        const newId = idRef.current + 1;
        idRef.current = newId;
        return {
            ...notification,
            id: newId,
        };
    };

    const add = (notification: ISystemNotification & {id: number}) => {
        setNofifications((prevState) => [notification, ...prevState]);
    };

    const remove = (id: number) => {
        setNofifications((prevNotifications) =>
            prevNotifications.filter((n) => n.id !== id),
        );
    };

    const setTimer = (id: number) => {
        timeouts[id] = setTimeout(() => {
            remove(id);
        }, autoDismiss);
    };

    const removeTimer = (id: number) => {
        clearTimeout(timeouts[id]);
        delete timeouts[id];
    };

    const handleAdd = (notification: ISystemNotification | object = {}) => {
        if (isNotification(notification)) {
            const notificationWithId = setId(notification);

            const shouldDismiss =
                !notification.type || notification.type === 'info';

            add(notificationWithId);
            if (shouldDismiss) {
                setTimer(notificationWithId.id);
            }
        }
    };

    const handleRemove = (id: number) => {
        remove(id);
        removeTimer(id);
    };

    usePubSub(SYSTEM_NOTIFICATION.ADD, handleAdd);
    usePubSub(SYSTEM_NOTIFICATION.REMOVE, handleRemove);
    return (
        <SystemNotificationList
            notifications={notifications}
            onClose={handleRemove}
        />
    );
};
