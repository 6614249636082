import classNames from 'classnames';
import {useState, useRef, useEffect} from 'react';

import {Button} from 'Components/button/base';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {StepOneScreen} from './screens/StepOneScreen';
import {StepTwoScreen} from './screens/StepTwoScreen';
import {useAutoFillRequest} from './useAutoFillRequest';

import './autoFillModal.scss';

type AutoFillModalProps = {
    autoFillUrl: string;
    suggestTextOptions?: {
        [key: string]: {label: string; checked: boolean; optionValue: string};
    };
    showToolGuideLink?: {
        url: string;
        display: boolean;
    };
    closeCallback: () => void;
    insertCallback: (content: string) => void;
};

export const AutoFillModal = ({
    autoFillUrl,
    suggestTextOptions = {},
    showToolGuideLink,
    insertCallback,
    closeCallback,
}: AutoFillModalProps) => {
    const userInputTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const [userInputContent, setUserInputContent] = useState('');
    const [outputContent, setOutputContent] = useState<string[]>([]);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [stepOneScreen, setStepOneScreen] = useState(true);
    const [autoFillOptions, setAutoFillOptions] = useState<{
        [key: string]: {checked: boolean; optionValue: string};
    }>(
        Object.keys(suggestTextOptions).reduce((acc, key) => {
            const {label, checked, optionValue} = suggestTextOptions[key];
            acc[label] = {checked, optionValue};
            return acc;
        }, {} as {[key: string]: {checked: boolean; optionValue: string}}),
    );

    const defaultOptions = Object.entries(autoFillOptions)
        .filter(([, {optionValue}]) => optionValue === 'DEFAULT')
        .map(([key, {checked, optionValue}]) => ({
            keyName: key,
            label: key,
            isChecked: checked,
            optionValue,
        }));

    const otherOptions = Object.entries(autoFillOptions)
        .filter(([, {optionValue}]) => optionValue !== 'DEFAULT')
        .map(([key, {checked, optionValue}]) => ({
            keyName: key,
            label: key,
            isChecked: checked,
            optionValue,
        }));

    const {
        isLoading,
        error,
        generatedContent,
        setGeneratedContent,
        submitFunction,
        cancelFunction,
    } = useAutoFillRequest({autoFillOptions, autoFillUrl});

    // Autofocus on the textarea when the component loads.
    useComponentDidMount(() => {
        if (userInputTextAreaRef.current) {
            userInputTextAreaRef.current.focus();
        }
    });

    useEffect(() => {
        if (generatedContent) {
            const parsedContent = JSON.parse(generatedContent);
            const sentences: string[][] = Object.values(parsedContent);
            const processedContent = sentences.map((sentenceArray) =>
                sentenceArray.join(' '),
            ); // Combine each sentence array into a single string.

            setOutputContent(processedContent);
        }
    }, [generatedContent]);

    const toggleStepOneScreen = () => {
        setStepOneScreen((prevValue) => !prevValue);
    };

    return (
        <div className="auto-fill-modal__body">
            {!stepOneScreen && (
                <div className="auto-fill-modal__header">
                    <Button
                        text="< Back"
                        onClick={() => {
                            cancelFunction();
                            toggleStepOneScreen();
                            setGeneratedContent('');
                        }}
                        className="auto-fill-modal__back-button"
                    />
                    <h3 className="auto-fill-modal__suggested-text-header">
                        Suggested Text
                    </h3>
                </div>
            )}

            <form
                className={classNames('auto-fill-modal__form', {
                    'auto-fill-modal__form-loading':
                        isLoading && !generatedContent && !error, // Add the class if isLoading is true
                })}
                onSubmit={(event) => {
                    event.preventDefault();
                    submitFunction(userInputContent);
                    toggleStepOneScreen();
                }}
            >
                {stepOneScreen ? (
                    <StepOneScreen
                        defaultOptions={defaultOptions}
                        otherOptions={otherOptions}
                        autoFillOptions={autoFillOptions}
                        setAutoFillOptions={(options: {
                            [key: string]: {
                                checked: boolean;
                                optionValue: string;
                            };
                        }) => {
                            setAutoFillOptions(options);
                        }}
                        showToolGuideLink={showToolGuideLink}
                        autoFillUrl={autoFillUrl}
                        userInputTextAreaRef={userInputTextAreaRef}
                        userInputContent={userInputContent}
                        setUserInputContent={(content) =>
                            setUserInputContent(content)
                        }
                    />
                ) : (
                    <StepTwoScreen
                        generatedContent={generatedContent}
                        outputContent={outputContent}
                        setOutputContent={setOutputContent}
                        hoveredIndex={hoveredIndex}
                        setHoveredIndex={(index) => setHoveredIndex(index)}
                        isLoading={isLoading}
                        error={error}
                    />
                )}
            </form>

            <div className="auto-fill-modal__footer">
                {stepOneScreen ? (
                    <div className="auto-fill-modal__footer-buttons">
                        <Button text="Cancel" onClick={() => closeCallback()} />
                        <Button
                            text={
                                generatedContent
                                    ? 'Regenerate text'
                                    : 'Suggest text'
                            }
                            disabled={isLoading}
                            color="green"
                            askArborLogo
                            type="submit"
                            onClick={() => {
                                submitFunction(userInputContent);
                                toggleStepOneScreen();
                            }}
                        />
                    </div>
                ) : (
                    <div className="auto-fill-modal__accept-buttons">
                        <Button text="Cancel" onClick={() => closeCallback()} />
                        <Button
                            text="Accept"
                            disabled={generatedContent === ''}
                            color="green"
                            askArborLogo
                            onClick={() => {
                                insertCallback(outputContent.join(' '));
                                closeCallback();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
