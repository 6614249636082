import {ComboboxValue} from 'Components/combobox';

export const getSafeSubmitValue = (
    valueToSubmit: ComboboxValue | ComboboxValue[],
    defaultValueOnEmpty?: string | number,
) => {
    const emptyValue = defaultValueOnEmpty ?? null;

    if (Array.isArray(valueToSubmit) && valueToSubmit.length === 0) {
        return emptyValue;
    }
    if (valueToSubmit === '' || valueToSubmit === null) {
        return emptyValue;
    }
    return valueToSubmit;
};
