import {prependZeros} from './prependZeros';

import type {TransformedNavigationItem} from 'State/navigation/types';

// The subnav data from the backend often has an empty last item with no text, url or children
const isValidItem = (item: OldNavigationItem) =>
    item.fields && item.fields.text && item.fields.text.value;
type OldNavigationItem = {
    fields: {
        text?: {
            value?: string;
        };
        url?: {
            value?: string;
        };
        selected?: {
            value?: boolean;
        };
    };
    expanded?: boolean;
    items?: OldNavigationItem[];
};
const transformTreeItem =
    (parentId?: string) => (item: OldNavigationItem, index: number) => {
        const {text, url} = item.fields;

        const itemId =
            typeof parentId === 'undefined'
                ? `root_${prependZeros(index)}`
                : `${parentId}_${prependZeros(index)}`;

        const childItems =
            item.items &&
            item.items.length > 0 &&
            item.items.filter(isValidItem).map(transformTreeItem(itemId));

        return {
            id: itemId,
            ...(text && text.value
                ? {text: text.value.replace(/\&amp;/g, '&')}
                : {}),
            ...(url && url.value ? {url: url.value} : {}),
            ...(childItems
                ? {
                      items: childItems,
                  }
                : {}),
        };
    };

export const transformNavigationData = (data: {items: OldNavigationItem[]}) => {
    if (!(data && data.items && data.items.length > 0)) {
        return [];
    }

    const transformedTreeData = data.items
        .filter(isValidItem)
        .map(transformTreeItem());
    return transformedTreeData as TransformedNavigationItem[];
};
