import {Root} from 'react-dom/client';

import exportInterface from 'Interfaces/Base';
import {getRenderTargetsService} from 'Services/renderTargets/RenderTargets';

import type {ReactNode} from 'react';

export const registerAdHocRoot = (newRoot: Root, id: string) => {
    getRenderTargetsService().registerAdHocRoot(newRoot, id);
};

export const getAdHocRoot = (id: string) => {
    return getRenderTargetsService().getAdHocRoot(id);
};

export const unmountAdHocRoot = (id: string) => {
    getRenderTargetsService().unmountAdHocRoot(id);
};

export const getHeaderRoot = () => getRenderTargetsService().getHeaderRoot();
export const getAppRoot = () => getRenderTargetsService().getAppRoot();
export const renderToAppRoot: (children: ReactNode) => void = (children) =>
    getAppRoot()?.render(children);
export const unmountAppRoot: () => void = () => getAppRoot()?.unmount();

export const getSlideoverRoot = () =>
    getRenderTargetsService().getSlideoverRoot();
export const getFocusTrappingModalRoot = () =>
    getRenderTargetsService().getFocusTrappingModalRoot();
export const getReactWindowRoot = () =>
    getRenderTargetsService().getReactWindowRoot();
export const getExtWindowRoot = () =>
    getRenderTargetsService().getExtWindowRoot();
export const getSystemNotificationRoot = () =>
    getRenderTargetsService().getSystemNotificationRoot();
export const getSystemModalRoot = () =>
    getRenderTargetsService().getSystemModalRoot();
export const getSessionModalRoot = () =>
    getRenderTargetsService().getSessionModalRoot();
export const getSimpleTooltipRoot = () =>
    getRenderTargetsService().getSimpleTooltipRoot();

export const setHeaderRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setHeaderRootContainer(container);
};
export const setAppRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setAppRootContainer(container);
};
export const setSlideoverRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setSlideoverRootContainer(container);
};
export const setFocusTrappingModalRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setFocusTrappingModalRootContainer(container);
};
export const setReactWindowRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setReactWindowRootContainer(container);
};
export const setExtWindowRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setExtWindowRootContainer(container);
};
export const setSystemNotificationRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setSystemNotificationRootContainer(container);
};
export const setSystemModalRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setSystemModalRootContainer(container);
};
export const setSessionModalRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setSessionModalRootContainer(container);
};
export const setSimpleTooltipRootContainer = (container: HTMLElement) => {
    getRenderTargetsService().setSimpleTooltipRootContainer(container);
};

export const registerRenderTargetsInterface = () => {
    exportInterface('RenderTargets', {
        registerAdHocRoot,
        getAdHocRoot,
        unmountAdHocRoot,
        getHeaderRoot,
        getAppRoot,
        getSlideoverRoot,
        getFocusTrappingModalRoot,
        getReactWindowRoot,
        getExtWindowRoot,
        getSystemNotificationRoot,
        getSystemModalRoot,
        getSessionModalRoot,
        setHeaderRootContainer,
        setAppRootContainer,
        setSlideoverRootContainer,
        setFocusTrappingModalRootContainer,
        setReactWindowRootContainer,
        setExtWindowRootContainer,
        setSystemNotificationRootContainer,
        setSystemModalRootContainer,
        setSessionModalRootContainer,
    });
};
