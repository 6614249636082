import {isSectionAlphaModeEnabled} from 'Renderer/isSectionAlphaModeEnabled';
import {
    getSectionRegistryService,
    SectionRegistryEntry,
} from 'Root/core/services/sectionSelector/SectionRegistry';

export const getAllSectionsWithinColumn = (
    sectionSelectorRef,
): SectionRegistryEntry[] => {
    const layoutContainerElement = sectionSelectorRef.closest(
        '.mis-ext-layout-container',
    );

    if (!layoutContainerElement) {
        console.error(
            'No layout container found in getAllSectionsWithinColumn',
            sectionSelectorRef,
        );
        return [];
    }

    if (isSectionAlphaModeEnabled()) {
        return getSectionRegistryService().getSectionsInColumn(
            layoutContainerElement,
        );
    }

    const column = Ext.getCmp(layoutContainerElement.id);

    if (column) {
        const newSections = column.query('mis-section') ?? [];

        const partialContainerId = column.query('mis-container-partial')[0]?.el
            ?.dom?.firstChild?.firstChild?.firstChild?.id;
        if (partialContainerId) {
            const strippedId = partialContainerId.replace('-outerCt', '');

            const furtherSections = Ext.getCmp(strippedId).query('mis-section');

            newSections.push(...furtherSections);
        }

        return newSections;
    }

    return [];
};
