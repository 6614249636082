import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

import {slide} from 'Config/Durations';

import './slide.scss';

function Slide(props) {
    const {children, type, ...rest} = props;
    const classNames = `slide-${type}`;

    return (
        <CSSTransition {...rest} classNames={classNames}>
            {children}
        </CSSTransition>
    );
}

Slide.propTypes = {
    type: PropTypes.string,
    timeout: PropTypes.number,
    children: PropTypes.node,
};

Slide.defaultProps = {
    timeout: slide,
};

export default Slide;
