import classnames from 'classnames';

import {SystemButton} from 'Components/button/base';
import {Icon} from 'Components/icon';
import {SimpleText} from 'Components/simpleText';
import {ISystemNotification} from 'Types/systemNotifications';

import './systemNotification.scss';

interface ISystemNotificationProps extends ISystemNotification {
    className?: string;
    onClose: () => void;
}

export function SystemNotification(props: ISystemNotificationProps) {
    const {className, icon, type, message, onClose} = props;

    return (
        <div
            className={classnames(
                'arbor-system-notification',
                {
                    [`arbor-system-notification-${type}`]: type,
                },
                className,
            )}
        >
            {icon && <Icon iconName={icon} />}
            <div className="arbor-system-notification-content">
                <SimpleText>{message}</SimpleText>
            </div>
            <SystemButton
                ariaLabel="Remove Notification"
                icon="remove"
                onClick={onClose}
            />
        </div>
    );
}
