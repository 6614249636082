import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import './checkoutPaymentPanelContent.scss';

// Only used by CheckoutPaymentPanel
class CheckoutPaymentPanelContent extends PureComponent {
    componentDidMount() {
        if (!this.props.collapsed) {
            // Use requestAnimationFrame to wait until the next browser render before
            // setting the height. This is because we need to wait until the content
            // has actually been rendered, and it has a height.
            window.requestAnimationFrame(() => {
                this.el.parentNode.style.height = this.getHeight();
            });
        }
    }

    getHeight() {
        return this.el && `${this.el.offsetHeight}px`;
    }

    setRef = (el) => {
        this.el = el;
    };

    render() {
        const {collapsed, children} = this.props;

        const height = collapsed ? 0 : this.getHeight();

        return (
            <div
                className="arbor-checkout-payment-panel-content"
                style={{height}}
            >
                <div className="collapse-wrapper" ref={this.setRef}>
                    {children}
                </div>
            </div>
        );
    }
}

CheckoutPaymentPanelContent.propTypes = {
    collapsed: PropTypes.bool,
    children: PropTypes.node,
};

export default CheckoutPaymentPanelContent;
