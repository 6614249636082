import {useEffect, useState} from 'react';

import Loader from 'Services/Loader';

import {getUserBetaService} from './getUserBetaService';
import {setUserBetaService} from './setUserBetaService';

import './betaToggleButton.scss';

export const BetaToggleButton = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEligible, setIsEligible] = useState(false);

    const getUserBeta = async () => {
        try {
            const {isEnabled, isEligible: isEligibleFromResponse} =
                await getUserBetaService();
            setIsEligible(isEligibleFromResponse);
            setIsChecked(isEnabled);
        } catch (e) {
            console.warn('Error while fetching beta mode options', e);
        } finally {
            setIsLoading(false);
        }
    };

    const setUserBeta = async (newValue: boolean) => {
        try {
            setIsLoading(true);
            setUserBetaService(newValue);
            Loader.loadCurrentPage();
        } catch (error) {
            console.error('Error enabling beta mode', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserBeta();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        setIsChecked(newValue);
        setUserBeta(newValue);
    };

    if (!isEligible) {
        return null;
    }

    return (
        <div className="beta-toggle-button__container">
            <div className="beta-toggle-button__toggle">
                <input
                    type="checkbox"
                    id="beta-toggle-button"
                    checked={isChecked}
                    onChange={handleChange}
                    disabled={isLoading}
                />
                <label
                    htmlFor="beta-toggle-button"
                    className="beta-toggle-button__toggle-label"
                />
            </div>
            <label
                htmlFor="beta-toggle-button"
                className="beta-toggle-button__label"
            >
                Toggle New Tables
            </label>
        </div>
    );
};
