import {lazy, Suspense} from 'react';

const Table = lazy(() => import('./TableAsDefaultExport'));

export const LazyLoadedTable = (props) => {
    return (
        <Suspense fallback={<div> Loading data </div>}>
            <Table {...props} />
        </Suspense>
    );
};
