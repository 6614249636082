import classNames from 'classnames';
import * as React from 'react';

import {getSVGIconComponent} from './getSVGIconComponent';

import './icon.scss';

interface IIconProps extends React.ButtonHTMLAttributes<HTMLSpanElement> {
    iconName?: string;
    flipX?: boolean;
    setRef?: React.RefObject<HTMLSpanElement>;
    color?: string;
}

export const Icon = ({
    className,
    iconName,
    flipX,
    setRef,
    color,
    ...others
}: IIconProps) => {
    const innerSVGIconComponent = getSVGIconComponent(iconName);

    const isLucideIcon = iconName?.startsWith('lucide-');

    return (
        <span
            className={classNames(
                'arbor-icon',
                'arbor-icon-' + iconName,
                {
                    'arbor-icon--flip-x': flipX,
                    'arbor-icon--green': color === 'green',
                    'lucide-icon': isLucideIcon,
                },
                className,
                isLucideIcon && iconName?.replace('lucide-', 'icon-'),
            )}
            ref={setRef}
            {...others}
        >
            {innerSVGIconComponent}
        </span>
    );
};
