export const CLEAR_NAVIGATION = 'NAVIGATION::CLEAR_NAVIGATION';
export const UPDATE_NAVIGATION = 'NAVIGATION::UPDATE_NAVIGATION';
export const USER_FAVOURITE_PAGES = 'NAVIGATION::USER_FAVOURITE_PAGES';
export const ADD_USER_FAVOURITE_PAGE = 'NAVIGATION::ADD_USER_FAVOURITE_PAGE';
export const UPDATE_USER_FAVOURITE_PAGES =
    'NAVIGATION::UPDATE_USER_FAVOURITE_PAGES';
export const REMOVE_USER_FAVOURITE_PAGES =
    'NAVIGATION::REMOVE_USER_FAVOURITE_PAGES';

export const CLEAR_DYNAMIC_NAV = 'SUB_NAV::CLEAR_DYNAMIC_NAV';
export const UPDATE_DYNAMIC_NAV = 'SUB_NAV::UPDATE_DYNAMIC_NAV';
export const TOGGLE_SUB_NAV_ITEM = 'SUB_NAV::TOGGLE_SUB_NAV_ITEM';
export const SET_SUBNAV_HIDDEN_BY_USER = 'SUB_NAV::SET_SUBNAV_HIDDEN_BY_USER';
