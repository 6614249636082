import {selectSelectedIds} from 'State/selectors/Basket';

const actions = {
    registerItem: 'BASKET::REGISTER_ITEM',
    selectItem: 'BASKET::SELECT_ITEM',
    deselectItem: 'BASKET::DESELECT_ITEM',
    clearBasket: 'BASKET::CLEAR_BASKET',
    upgradeToBundle: 'BASKET::UPGRADE_TO_BUNDLE',
    updateInsightPrice: 'CHECKOUT::UPDATE_PRICE',
};

const initialState = {
    allItems: {},
    selectedIds: [],
    calculator: {
        subTotal: 0,
        vat: 0,
        total: 0,
        currencyCode: '',
    },
};

function _selectItem(state, id, selectedState, lastInBundle = false) {
    // If item is selected by selecting all the individual items in a bundle, select bundle and remove all items related to bundle selected
    if (lastInBundle) {
        // Get children ID
        let childIds = state.allItems[id].purchasePanel.bundleRelatedPanels.map(
            (item) => item._serviceId,
        );
        // Map current items with children id in new array with selected false value
        let childrenUnselectWithDefaultKeys = childIds.map((itemId) => {
            return {...state.allItems[itemId], selected: false};
        });
        // Since childrenUnselectWithDefaultKeys is array from 0 to n and we need object with keys with id preformat it
        let childrenUnselect = {};
        childrenUnselectWithDefaultKeys.forEach((child, key) => {
            childrenUnselect[childIds[key]] = child;
        });

        return {
            ...state,
            allItems: {
                ...state.allItems,
                [id]: {...state.allItems[id], selected: selectedState},
                ...childrenUnselect,
            },
            lastSelectedId: id,
        };
    }

    // // If item is selected by selecting all the individual items in a bundle, select bundle and remove last item selected to enable showing only prevously selected items when user removes the bundle
    // if (lastInBundle) {
    //     return {
    //         ...state,
    //         allItems: {..._selectItem(state, state.lastSelectedId, false).allItems, [id]: {...state.allItems[id], selected: selectedState}},
    //         lastSelectedId: id
    //     };
    // }

    return {
        ...state,
        allItems: {
            ...state.allItems,
            [id]: {...state.allItems[id], selected: selectedState},
        },
        lastSelectedId: id,
    };
}

function _registerItem(state, action) {
    var newState = {...state};

    // If bundle is being added go thought all his panels and set parents in already added allItems
    if (action.item.type === 'bundle') {
        action.item.purchasePanel.bundleRelatedPanels.map((panelInBundle) => {
            if (newState.allItems.hasOwnProperty(panelInBundle._serviceId)) {
                newState.allItems[panelInBundle._serviceId] = {
                    ...newState.allItems[panelInBundle._serviceId],
                    parentBundles: [
                        ...newState.allItems[panelInBundle._serviceId],
                        action.item.serviceId,
                    ],
                };
            }
        });
        newState.allItems[action.item.serviceId] = action.item;
    } else {
        // If it is single item being added go thought allItems, find bundles and add parents if it is a child of one of them
        var actionItem = {...action.item};
        Object.keys(newState.allItems).forEach((key) => {
            let stateItem = newState.allItems[key];
            if (stateItem.type === 'bundle') {
                stateItem.purchasePanel.bundleRelatedPanels.forEach(
                    (panelInBundle) => {
                        if (panelInBundle._serviceId === actionItem.serviceId) {
                            if (actionItem.parentBundles) {
                                actionItem.parentBundles = [
                                    ...actionItem.parentBundles,
                                    stateItem.serviceId,
                                ];
                            } else {
                                actionItem.parentBundles = [
                                    stateItem.serviceId,
                                ];
                            }
                        }
                    },
                );
            }
        });
        newState.allItems[actionItem.serviceId] = actionItem;
    }

    return newState;
}

function _calculatePrice(state) {
    let subTotal = 0;
    let vat = 0;
    let total = 0;

    let selectedIds = selectSelectedIds({basket: state});
    selectedIds.forEach((id) => {
        if (state.allItems.hasOwnProperty(id)) {
            subTotal += state.allItems[id].price;
            vat += state.allItems[id].vat;
        }
    });

    total = subTotal + vat;

    subTotal = Number(subTotal).toFixed(2);
    vat = Number(vat).toFixed(2);
    total = Number(total).toFixed(2);

    return {
        ...state,
        calculator: {
            subTotal,
            vat,
            total,
        },
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.registerItem:
            return _registerItem(state, action);
        // return {
        //     ...state,
        //     allItems: {...state.allItems, [action.item.serviceId]: action.item}
        // };
        case actions.selectItem:
            return _calculatePrice(
                _selectItem(state, action.id, true, action.lastInBundle),
            );
        case actions.deselectItem:
            return _calculatePrice(_selectItem(state, action.id, false));
        case actions.clearBasket:
            return {...initialState};
        case actions.updateInsightPrice:
            return {...state, insightBasketPrice: action.price};
        // case actions.upgradeToBundle:
        //     let bundle = state.allItems[action.bundleId];
        //     // Get all selectedIds that are not part of the bundle
        //     let modifiedSelectedIds = state.selectedIds.filter((selectedId) => {
        //         return (selectedId !== bundle.serviceId &&
        //             !bundle.purchasePanel.bundleRelatedPanels.find(({_serviceId}) => _serviceId === selectedId));
        //     });
        //     // Add bundle into selectedIds
        //     modifiedSelectedIds.push(bundle.serviceId);
        //     return _calculatePrice({...state, selectedIds: modifiedSelectedIds});
        default:
            return state;
    }
}
