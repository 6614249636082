import {TransformedNavigationItem} from '../types';

import {findRouteInTree} from './findRouteInTree';

const addMatchingOptionalParamsToUrl = (
    url: string,
    optionalParams: string[],
    currentUrlParams: string[] | null,
    parentStaticTreeOptionalParams: Record<string, string | null> | null,
) => {
    let urlWithParams = url;
    optionalParams.forEach((paramName) => {
        if (currentUrlParams) {
            const indexInRouteParams = currentUrlParams.findIndex(
                (item) => item === paramName,
            );
            if (indexInRouteParams < 0) {
                return;
            }
            if (
                typeof currentUrlParams[indexInRouteParams + 1] === 'undefined'
            ) {
                return;
            }
            urlWithParams = `${urlWithParams}/${paramName}/${
                currentUrlParams[indexInRouteParams + 1]
            }`;
        }
    });
    optionalParams.forEach((paramName) => {
        if (parentStaticTreeOptionalParams) {
            if (
                typeof parentStaticTreeOptionalParams[paramName] !==
                    'undefined' &&
                parentStaticTreeOptionalParams[paramName] !== null
            ) {
                urlWithParams = `${urlWithParams}/${paramName}/${parentStaticTreeOptionalParams[paramName]}`;
            }
        }
    });

    return urlWithParams;
};

export const addMatchingOptionalParamsToItems = (
    treeData: TransformedNavigationItem[],
    currentUrlParams: string[] | null,
    parentStaticTreeOptionalParams: Record<string, string | null> | null,
) => {
    const transformedData: TransformedNavigationItem[] = treeData.map(
        (item) => {
            const {url, entryPoint, optionalParams, items} = item;
            const entryPointParams = entryPoint
                ? findRouteInTree(entryPoint, treeData)?.optionalParams ?? null
                : null;
            return {
                ...item,
                ...(url && optionalParams
                    ? {
                          url: addMatchingOptionalParamsToUrl(
                              url,
                              optionalParams,
                              currentUrlParams,
                              parentStaticTreeOptionalParams,
                          ),
                      }
                    : {}),
                ...(entryPoint && entryPointParams
                    ? {
                          entryPoint: addMatchingOptionalParamsToUrl(
                              entryPoint,
                              entryPointParams,
                              currentUrlParams,
                              parentStaticTreeOptionalParams,
                          ),
                      }
                    : {}),
                ...(items
                    ? {
                          items: addMatchingOptionalParamsToItems(
                              items,
                              currentUrlParams,
                              parentStaticTreeOptionalParams,
                          ),
                      }
                    : {}),
            };
        },
    );
    return transformedData;
};
