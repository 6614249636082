import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';

import './noResult.scss';

type NoResultProps = {
    onViewAllClick: () => void;
};
export const NoResult = ({onViewAllClick}: NoResultProps) => {
    return (
        <>
            <div className="search-dropdown__no-result__title">
                Sorry, no results found
            </div>
            <div className="search-dropdown__no-result__text">
                <p>
                    We couldn’t find what you’re looking for within the current
                    academic year. Please double check your spelling or try
                    different search criteria.
                </p>
                <p>
                    Alternatively, check the{' '}
                    <KeyboardFocusableLink
                        tabIndex={-1}
                        onClick={onViewAllClick}
                    >
                        All Results
                    </KeyboardFocusableLink>{' '}
                    page. It might contain additional search results found in
                    previous academic years.
                </p>
            </div>
        </>
    );
};
