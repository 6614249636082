import {removeAllTagsExceptBold} from 'Utils/sanitizeHtml';

export const transformZendeskDescription = (
    rawDescription?: string,
): string => {
    if (!rawDescription) {
        return '';
    }

    let description = rawDescription;

    if (description[0] !== description[0].toUpperCase()) {
        description = '…' + description;
    }

    description = description
        .replace(/<em>/g, '<b>')
        .replace(/<\/em>/g, '</b>');

    description += '…';

    description = removeAllTagsExceptBold(description);
    return description;
};
