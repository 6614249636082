import classNames from 'classnames';
import {useRef} from 'react';

import {Button} from 'Components/button/base';
import {Icon} from 'Components/icon';
import {SearchField} from 'Components/searchField';
import {useSimpleTooltip} from 'Components/tooltip';

type ReportingDashboardHeaderProps = {
    isLoading: boolean;
    categories: string[];
    selectedCategory: string;
    setSelectedCategory: (selectedCategory: string) => void;
    showArborReports: boolean;
    showOthersCustomReports: boolean;
    showMyCustomReports: boolean;
    setShowArborReports: (newVal: boolean) => void;
    setShowOthersCustomReports: (newVal: boolean) => void;
    setShowMyCustomReports: (newVal: boolean) => void;
    setSearchQuery: (query: string) => void;
    displayCount: number;
    searchQuery?: string;
    totalCount: number;
};

export const ReportingDashboardHeader = ({
    categories,
    setSearchQuery,
    selectedCategory,
    setSelectedCategory,
    displayCount,
    totalCount,
    showArborReports,
    showOthersCustomReports,
    showMyCustomReports,
    setShowArborReports,
    setShowOthersCustomReports,
    setShowMyCustomReports,
    searchQuery,
}: ReportingDashboardHeaderProps) => {
    const arborRef = useRef<HTMLAnchorElement>(null);
    const myCustomRef = useRef<HTMLAnchorElement>(null);
    const othersCustomRef = useRef<HTMLAnchorElement>(null);
    const {tooltipEventHandlers: arborEventHandlers} = useSimpleTooltip({
        tooltip: 'Built-in reports found throughout the MIS.',
        elementRef: arborRef,
    });
    const {tooltipEventHandlers: myCustomEventHandlers} = useSimpleTooltip({
        tooltip: 'Reports that you have built using Custom Report Writer.',
        elementRef: myCustomRef,
    });
    const {tooltipEventHandlers: othersCustomEventHandlers} = useSimpleTooltip({
        tooltip:
            'Reports created by other users using Custom Report Writer that you have permission to view.',
        elementRef: othersCustomRef,
    });
    return (
        <div className="reporting-dashboard__header">
            <span className="reporting-dashboard__header__report-origin-container">
                <label>
                    <input
                        checked={showArborReports}
                        type="checkbox"
                        onChange={() => {
                            setShowArborReports(!showArborReports);
                        }}
                    />
                    Arbor Built-In Reports
                    <Icon
                        setRef={arborRef}
                        iconName="help"
                        className="reporting-dashboard__origin-selector__icon"
                        {...arborEventHandlers}
                    />
                </label>
                <label>
                    <input
                        checked={showMyCustomReports}
                        type="checkbox"
                        onChange={() => {
                            setShowMyCustomReports(!showMyCustomReports);
                        }}
                    />
                    My Custom Reports
                    <Icon
                        setRef={myCustomRef}
                        iconName="help"
                        className="reporting-dashboard__origin-selector__icon"
                        {...myCustomEventHandlers}
                    />
                </label>
                <label>
                    <input
                        checked={showOthersCustomReports}
                        type="checkbox"
                        onChange={() => {
                            setShowOthersCustomReports(
                                !showOthersCustomReports,
                            );
                        }}
                    />
                    Other Custom Reports
                    <Icon
                        setRef={othersCustomRef}
                        iconName="help"
                        className="reporting-dashboard__origin-selector__icon"
                        {...othersCustomEventHandlers}
                    />
                </label>
            </span>
            <div className="reporting-dashboard__header__lower">
                <div className="reporting-dashboard__header__categories">
                    {categories.map((category) => {
                        const isSelected = selectedCategory === category;
                        return (
                            <Button
                                key={category}
                                text={category}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (isSelected) {
                                        setSelectedCategory('All');
                                    } else {
                                        setSelectedCategory(category);
                                    }
                                }}
                                className={classNames(
                                    'search-filters__button',
                                    {
                                        'search-filters__button--active':
                                            isSelected,
                                    },
                                )}
                                fontSize="14px"
                            />
                        );
                    })}
                </div>
                <div className="reporting-dashboard__header__right-align-container">
                    <SearchField
                        placeholder="Search Reports"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        value={searchQuery}
                    />
                    <p
                        data-testid="ReportingDashboard-reportCount"
                        className="reporting-dashboard__counter"
                    >
                        Showing{' '}
                        <b>
                            {displayCount}/{totalCount}
                        </b>{' '}
                        reports
                    </p>
                </div>
            </div>
        </div>
    );
};
