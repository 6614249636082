// TODO [MIS-44036]: Would be nice to convert this to TS but this confuses
// the typescript compiler as it tries to use this file when importing from the
// 'react' npm package

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// TODO [MIS-44042]: Figure out how to make import order work nicely so we don't have to
// manually import this file here.

import {initialiseSession} from 'Interfaces/Session';

import {preLoginInitialization} from './preLoginInitialization';

import './styles/style.scss';
import './styles/custom/acceptLicence.scss';
import 'Components/keyboardFocusableLink/keyboardFocusableLink.scss';

preLoginInitialization();

initialiseSession();
