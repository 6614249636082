import {combineReducers} from 'redux';

import {sortStudentGroupFilters} from 'BackendRenderedComponents/dashboard/utils';

import {
    SET_INITIAL_FILTERS,
    TOGGLE_FILTER_BY_ID,
    SET_SELECTED_TAB_BY_ID,
    SAVE_FILTERS_REQUEST,
    SAVE_FILTERS_SUCCESS,
    SAVE_FILTERS_FAILURE,
    FETCH_KPI_DATA_REQUEST,
    FETCH_KPI_DATA_SUCCESS,
    FETCH_KPI_DATA_CACHED,
    FETCH_KPI_DATA_FAILURE,
    GET_TO_DOS_REQUEST,
    GET_TO_DOS_SUCCESS,
    GET_TO_DOS_FAILURE,
    GET_SCHOOL_NOTICES_REQUEST,
    GET_SCHOOL_NOTICES_SUCCESS,
    GET_SCHOOL_NOTICES_FAILURE,
    GET_STUDENT_ALERTS_REQUEST,
    GET_STUDENT_ALERTS_SUCCESS,
    GET_STUDENT_ALERTS_FAILURE,
    DISMISS_STUDENT_ALERT_REQUEST,
    DISMISS_STUDENT_ALERT_SUCCESS,
    DISMISS_STUDENT_ALERT_FAILURE,
} from './constants';
import {TAB_IDS} from './types';

const initialFiltersState = {
    studentGroups: [],
    demographics: [],
    kpiGroups: [],
    tabs: [],
    isLoading: false,
};

function filters(state = initialFiltersState, action) {
    switch (action.type) {
        case SET_INITIAL_FILTERS:
            const {studentGroups, ...rest} = action.initialFilters;

            return {
                ...state,
                studentGroups: sortStudentGroupFilters(studentGroups),
                ...rest,
            };

        case TOGGLE_FILTER_BY_ID:
            const {filterType, id} = action;
            return {
                ...state,
                [filterType]: [
                    ...state[filterType].map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                selected: !item.selected,
                            };
                        }
                        return item;
                    }),
                ],
            };
        case SET_SELECTED_TAB_BY_ID:
            return {
                ...state,
                tabs: [
                    ...state.tabs.map((item) => ({
                        ...item,
                        selected: item.id === action.id,
                    })),
                ],
            };

        case SAVE_FILTERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case SAVE_FILTERS_SUCCESS:
        case SAVE_FILTERS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}

// TODO [MIS-37023]: Need to do error handling for when the APIs fail.
// What should we show for numberOfStudents?

const kpiDataInitialState = {
    [TAB_IDS.MY_STUDENTS]: {
        numberOfStudents: undefined,
        kpiGroups: [],
        isLoading: false,
        cache: {},
    },
    [TAB_IDS.WHOLE_SCHOOL]: {
        numberOfStudents: undefined,
        kpiGroups: [],
        isLoading: false,
        cache: {},
    },
};

function kpiData(state = kpiDataInitialState, action) {
    switch (action.type) {
        case FETCH_KPI_DATA_REQUEST:
            if (Object.values(TAB_IDS).indexOf(action.tabId) === -1) {
                console.warn(
                    'Incorrect tab id:',
                    action.tabId,
                    'correct values are:',
                    Object.values(TAB_IDS),
                );
            }
            return {
                ...state,
                [action.tabId]: {
                    ...state[action.tabId],
                    isLoading: true,
                },
            };
        case FETCH_KPI_DATA_SUCCESS:
            return {
                ...state,
                [action.tabId]: {
                    ...state[action.tabId],
                    kpiGroups: action.kpiGroups,
                    numberOfStudents: action.numberOfStudents,
                    isLoading: false,
                    cache: {
                        ...state[action.tabId].cache,
                        [action.url]: {
                            kpiGroups: action.kpiGroups,
                            numberOfStudents: action.numberOfStudents,
                            lastRequestTime: Date.now(),
                        },
                    },
                },
            };
        case FETCH_KPI_DATA_CACHED:
            return {
                ...state,
                [action.tabId]: {
                    ...state[action.tabId],
                    isLoading: false,
                    kpiGroups:
                        state[action.tabId].cache[action.url] &&
                        state[action.tabId].cache[action.url].kpiGroups,
                    numberOfStudents:
                        state[action.tabId].cache[action.url] &&
                        state[action.tabId].cache[action.url].numberOfStudents,
                },
            };
        case FETCH_KPI_DATA_FAILURE:
            return {
                ...state,
                [action.tabId]: {
                    ...state[action.tabId],
                    isLoading: false,
                },
            };
        default:
            return state;
    }
}

const initialNotificationsState = {
    studentAlerts: {
        isLoading: true,
        items: [],
        total: 0,
    },
    toDos: {
        isLoading: true,
        items: [],
        total: 0,
    },
    schoolNotices: {
        isLoading: true,
        items: [],
        total: 0,
    },
};

function toDos(state = initialNotificationsState.toDos, action) {
    switch (action.type) {
        case GET_TO_DOS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case GET_TO_DOS_SUCCESS:
            return {
                ...state,
                total: action.total,
                items: action.toDos,
                isLoading: false,
            };

        case GET_TO_DOS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

function schoolNotices(
    state = initialNotificationsState.schoolNotices,
    action,
) {
    switch (action.type) {
        case GET_SCHOOL_NOTICES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case GET_SCHOOL_NOTICES_SUCCESS:
            return {
                ...state,
                total: action.total,
                items: action.schoolNotices,
                isLoading: false,
            };

        case GET_SCHOOL_NOTICES_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

function studentAlerts(
    state = initialNotificationsState.studentAlerts,
    action,
) {
    switch (action.type) {
        case GET_STUDENT_ALERTS_REQUEST:
        case DISMISS_STUDENT_ALERT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case GET_STUDENT_ALERTS_SUCCESS:
        case DISMISS_STUDENT_ALERT_SUCCESS:
            return {
                ...state,
                total: action.total,
                items: action.studentAlerts,
                isLoading: false,
            };

        case GET_STUDENT_ALERTS_FAILURE:
        case DISMISS_STUDENT_ALERT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default combineReducers({
    kpi: combineReducers({
        filters,
        kpiData,
    }),
    notifications: combineReducers({
        studentAlerts,
        toDos,
        schoolNotices,
    }),
});
