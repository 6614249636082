import {TransformedNavigationItem} from '../types';

export const findItemInTree = (
    findFunction: (item: TransformedNavigationItem) => boolean,
    treeData: TransformedNavigationItem[],
): TransformedNavigationItem | null => {
    let foundRoute: TransformedNavigationItem | null = null;
    treeData.find((item) => {
        if (findFunction(item)) {
            foundRoute = item;
            return true;
        }
        if (item.items) {
            const innerFoundRoute = findItemInTree(findFunction, item.items);
            if (innerFoundRoute) {
                foundRoute = innerFoundRoute;
            }
            return innerFoundRoute;
        }
        if (item.subTree) {
            const innerFoundRoute = findItemInTree(
                findFunction,
                item.subTree.items,
            );
            if (innerFoundRoute) {
                foundRoute = innerFoundRoute;
            }
            return innerFoundRoute;
        }
        return false;
    });
    return foundRoute;
};
