export const getSelectedOptionsFromIDs = (options = {}, IDs = {}) => {
    return Object.entries(IDs)
        .filter(([, isSelected]) => !!isSelected)
        .map(([key]) => {
            const {name} = options[key];

            return {
                id: key,
                displayName: name,
                selected: true,
            };
        });
};

export const transformOptions = (options = {}, selectedIDs = {}) => {
    return Object.entries(options).map(([, {name, id, isMySchool}]) => ({
        displayName: name,
        id,
        selected: selectedIDs[id] || false,
        ...(isMySchool && {isMySchool}),
    }));
};

export const getNumberOfSchoolsCopy = ({
    isFilterDirty,
    isMySchoolSelected,
    isAnyFilterSelected,
    numberOfSchools = 0,
}) => {
    if (isFilterDirty) {
        return 'Apply filters to update the KPIs and see the number of schools';
    }

    if (isAnyFilterSelected) {
        return `Showing: ${numberOfSchools} schools`;
    }

    if (isMySchoolSelected) {
        return `Showing: All your schools (${numberOfSchools})`;
    }

    return `Showing: All schools (${numberOfSchools})`;
};

export const getSchoolsEmptyTextCopy = ({
    schoolSearchValue,
    isMySchoolSelected,
    mySchoolOptionsLength,
    schoolOptionsLength,
}) => {
    if (schoolSearchValue) {
        return 'You don’t have any schools that match your search.';
    }
    if (isMySchoolSelected && mySchoolOptionsLength === 0) {
        return 'It looks like you don’t have any schools assigned to you. Please get in contact with your Arbor administrator and ask them to add a school to your "Associated Institutions".';
    }

    if (!isMySchoolSelected && schoolOptionsLength === 0) {
        return 'Whoops! It looks like we don’t have any schools to show. This is likely because they haven’t accepted their data-sharing agreement.';
    }

    return 'All of your assigned schools have been selected.';
};

export const getClustersEmptyTextCopy = ({
    clusterSearchValue,
    clusterOptionsLength,
}) => {
    if (clusterSearchValue) {
        return 'You don’t have any clusters that match your search.';
    }
    if (clusterOptionsLength === 0) {
        return 'It looks like you haven’t set up any clusters yet. If you have permission, click "View the clusters list" in the quick actions to get started.';
    }

    return 'All of your assigned clusters have been selected.';
};
