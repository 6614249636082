import exportInterface from 'Interfaces/Base';
import {
    FormField,
    DisplayFormField,
    getFormFieldRegistryService,
    RefetchCallbackParams,
} from 'Services/formField/FormField';

export const registerFormField = (
    formActionNames: string[],
    formField: FormField,
) => {
    return getFormFieldRegistryService().registerFormField(
        formActionNames,
        formField,
    );
};
export const registerDisplayFormField = (
    displayFormField: DisplayFormField,
) => {
    return getFormFieldRegistryService().registerDisplayFormField(
        displayFormField,
    );
};

export const deregisterFormField = (formFieldId: string) => {
    getFormFieldRegistryService().deregisterFormField(formFieldId);
};

export const updateFormFieldValue = (formFieldId: string, value: unknown) => {
    getFormFieldRegistryService().updateFormFieldValueDebounced(
        formFieldId,
        value,
    );
};

export const getFormFieldsForAction = (formActionName: string) => {
    return getFormFieldRegistryService().getFormFieldsForAction(formActionName);
};
export const getReactFormFieldsForAction = (formActionName: string) => {
    return getFormFieldRegistryService().getReactFormFieldsForAction(
        formActionName,
    );
};

export const getFormFieldsForActionAsExt = (formActionName: string) => {
    return getFormFieldRegistryService().getFormFieldsForActionAsExt(
        formActionName,
    );
};
export const getReactFormFieldsForActionAsExt = (formActionName: string) => {
    return getFormFieldRegistryService().getReactFormFieldsForActionAsExt(
        formActionName,
    );
};

export const addRefetchListener = (
    formName: string,
    refetchCallback: (params: RefetchCallbackParams) => {remove: () => void},
) => {
    return getFormFieldRegistryService().addRefetchListener(
        formName,
        refetchCallback,
    );
};

export const registerFormFieldInterface = () => {
    exportInterface('FormField', {
        getFormFieldsForActionAsExt,
        getReactFormFieldsForActionAsExt,
        getReactFormFieldsForAction,
        registerFormField,
        deregisterFormField,
        registerDisplayFormField,
        updateFormFieldValue,
        addRefetchListener,
    });
};
