import classNames from 'classnames';

import {
    TableHeaderButton,
    TableHeaderButtonProps,
} from 'Components/sharedTableSubComponents/tableHeaderButton';

import './hideTooltipsButton.scss';

type IHideTooltipsButtonProps = TableHeaderButtonProps & {
    isTooltipsEnabled: boolean;
    setTooltipsEnabled: (value: boolean) => void;
};

const TooltipsEnabledIcon = () => (
    <svg width="16px" height="16px" viewBox="3 3 18 18">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.25 16.4325L7.8175 14L6.98917 14.8225L10.25 18.0833L17.25 11.0833L16.4275 10.2608L10.25 16.4325Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 3L10 7H5C3.89 7 3 7.9 3 9V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V9C21 7 19 7 19 7H14L12 3ZM7.8175 14L10.25 16.4325L16.4275 10.2608L17.25 11.0833L10.25 18.0833L6.98917 14.8225L7.8175 14Z"
        />
    </svg>
);

const TooltipsDisabledIcon = () => (
    <svg width="16px" height="16px" viewBox="3 3 18 18">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 7H14L12 3L10 7H5C3.89 7 3 7.9 3 9V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V9C21 7 19 7 19 7ZM15.1943 10L16 10.8057L12.8057 14L16 17.1943L15.1943 18L12 14.8057L8.80571 18L8 17.1943L11.1943 14L8 10.8057L8.80571 10L12 13.1943L15.1943 10Z"
        />
    </svg>
);

export const HideTooltipsButton = ({
    isTooltipsEnabled,
    setTooltipsEnabled,
    ...others
}: IHideTooltipsButtonProps) => {
    return (
        <TableHeaderButton
            className={classNames('hide-tooltips-button', {
                'hide-tooltips-button--disabled': !isTooltipsEnabled,
            })}
            onClick={() => {
                setTooltipsEnabled(!isTooltipsEnabled);
            }}
            aria-label={
                isTooltipsEnabled
                    ? 'Tooltips are currently visible. Click here to hide them'
                    : 'Tooltips are currently hidden. Click here to show them'
            }
            tooltip={
                isTooltipsEnabled
                    ? 'Tooltips are currently visible. Click to here hide them'
                    : 'Tooltips are currently hidden. Click here to show them'
            }
            {...others}
        >
            {isTooltipsEnabled ? (
                <TooltipsEnabledIcon />
            ) : (
                <TooltipsDisabledIcon />
            )}
        </TableHeaderButton>
    );
};
