/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:39
 */

export default function exportInterface(
    interfaceName,
    interfaceFunctions = {},
) {
    if (!window.Arbor) {
        window.Arbor = {};
    }

    if (!window.Arbor.Interfaces) {
        window.Arbor.Interfaces = {};
    }

    window.Arbor.Interfaces[interfaceName] = {
        ...window.Arbor.Interfaces[interfaceName],
        ...interfaceFunctions,
    };
}

export function exportInterfaceSingleton(interfaceName, interfaceInstance) {
    if (!window.Arbor) {
        window.Arbor = {};
    }

    if (!window.Arbor.Interfaces) {
        window.Arbor.Interfaces = {};
    }

    if (!window.Arbor.Interfaces[interfaceName]) {
        window.Arbor.Interfaces[interfaceName] = {};
    }

    window.Arbor.Interfaces[interfaceName] = interfaceInstance;
}
