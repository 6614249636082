/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 8/6/18
 * Time: 11:52
 */

import hotkeys from 'hotkeys-js';

import {slideover as slideoverDuration} from 'Config/Durations';
import externalConfig from 'Config/External';
import {getLatestSlideover, removeSlideover} from 'Interfaces/Slideover';
import Loader from 'Services/Loader';
import {getUserNotificationService} from 'Services/userNotifications/UserNotification';

export function refreshCurrentPage() {
    Loader.loadCurrentPage();
}

export function refreshLatestSlideover() {
    const latestSlideoverURL = getLatestSlideover();
    if (latestSlideoverURL) {
        removeSlideover();
        // We need to wait until the closing animation is finished for the current slideover
        // before re-loading it
        setTimeout(() => {
            Loader.loadPage(latestSlideoverURL);
        }, slideoverDuration + 200);
    }
}

export function initDevTools() {
    if (externalConfig.getConfig().debugEnvironment) {
        hotkeys('command+alt+r', refreshCurrentPage);
        hotkeys('command+alt+shift+r', refreshLatestSlideover);
    }
}

export const triggerNotification = (params) => {
    const now = Date.now();
    getUserNotificationService().addNotification({
        action: 'loadPage',
        content: 'Test notification content' + now,
        created: now,
        icon: 'message',
        image: '/img/mis-icon-student.png',
        isRead: false,
        status: 'UNREAD',
        title: 'Test notifications title',
        url: '/live-rulebook-ui/standard-page',
        userNotificationId: now,
        enablePreview: true,
        previewAutoDismissable: true,
        ...params,
    });
};
