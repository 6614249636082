import {createSlice} from '@reduxjs/toolkit';

const burgerMenuSlice = createSlice({
    name: 'burgerMenu',
    initialState: {
        isBurgerMenuVisible: false,
    },
    reducers: {
        toggleBurgerMenuVisibilityAction: (state) => {
            state.isBurgerMenuVisible = !state.isBurgerMenuVisible;
        },
        closeBurgerMenuAction: (state) => {
            state.isBurgerMenuVisible = false;
        },
    },
    selectors: {
        selectIsBurgerMenuVisible: (state) => state.isBurgerMenuVisible,
    },
});

export const {toggleBurgerMenuVisibilityAction, closeBurgerMenuAction} =
    burgerMenuSlice.actions;

export const {selectIsBurgerMenuVisible} = burgerMenuSlice.selectors;

export const burgerMenuReducer = burgerMenuSlice.reducer;
