import './image.scss';

interface IImageProps {
    url?: string;
    title?: string;
    dataTestId?: string;
}

// A simple image component to be used in from twig templates. If you need an image
// elsewhere in react, you are better just directly using an <img>.

export const Image = ({url, title, dataTestId}: IImageProps) => (
    <div className="image-component__container">
        <img
            src={url}
            alt={title}
            className="image-component"
            data-testid={dataTestId}
        />
    </div>
);
