import {lazy, Suspense} from 'react';

const SnippetTable = lazy(() => import('./SnippetTableAsDefaultExport'));

export const LazyLoadedSnippetTable = (props) => {
    return (
        <Suspense fallback={<div>Loading snippets</div>}>
            <SnippetTable {...props} />
        </Suspense>
    );
};
