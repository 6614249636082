import './eventBox.scss';

interface IEventBoxProps {
    children: JSX.Element;
}

export const EventBox = ({children}: IEventBoxProps) => {
    return (
        <div className="event-box" role="presentation">
            {children}
        </div>
    );
};
