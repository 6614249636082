import {logOut, getUser} from 'Interfaces/Session';
import {logEventToGainsight} from 'Services/gainsight';

import {SideBarButton} from '../sideBarButton';

import {SignOutIcon} from './SignOutIcon';

export const SignOutButton = () => {
    const {display_name: displayName} = getUser() || {};
    return (
        <SideBarButton
            onClick={() => {
                logOut();
                logEventToGainsight('SignOutButtonClicked', {});
            }}
            buttonText="Sign Out"
            tooltip={displayName}
        >
            <SignOutIcon />
        </SideBarButton>
    );
};
