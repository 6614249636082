type MoveTinyPopupDivParams = {
    targetContainer: Node;
    editorId: number;
};

/*
 * TinyMCE spawns a placeholder div at the bottom of the main body of the document to hold any popup
 * elements it needs to render (mainly dropdowns). This means that it is competing for stacking with
 * our modal and slideover managers. This takes the div assigned to the current editor, and moves it
 * into the container of the TinyMCE instance, so that they are in the same stacking context, and don't
 * compete with anything they shouldn't have been competing with in the first place
 */

export const moveTinyPopupDiv = ({
    targetContainer,
    editorId,
}: MoveTinyPopupDivParams) => {
    const overflowDivsArr = Array.from(
        document.getElementsByClassName('tox-tinymce-aux'),
    );
    const firstAvailableOverflowDiv = overflowDivsArr.find(
        (element) => element.id === '',
    );
    if (firstAvailableOverflowDiv) {
        firstAvailableOverflowDiv.id = `overflow-for-editor__${editorId}`;
        firstAvailableOverflowDiv.classList.add(
            'html-editor__tiny-mce-overflow-div-hack',
        );
        targetContainer.appendChild(firstAvailableOverflowDiv);
    }
};
