import {CalendarWidget} from 'Components/calendarWidget';
import {DropdownContainer} from 'Components/dropdown';
import {getUser} from 'Interfaces/Session';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';

import {SideBarButton} from '../sideBarButton';

import {CalendarIcon} from './CalendarIcon';

type CalendarButtonProps = {
    onClick?: () => void;
    isSideBar?: boolean;
    isBurgerMenu?: boolean;
};

export const CalendarButton = ({
    onClick,
    isSideBar,
    isBurgerMenu,
}: CalendarButtonProps) => {
    const {calendarUrl} = getUser() || {};

    if (!calendarUrl) {
        return null;
    }

    return (
        <DropdownContainer
            openOnFocus={!isBurgerMenu}
            renderDropdownField={({toggleDropdown, isDropdownVisible}) => (
                <SideBarButton
                    onClick={() => {
                        if (typeof onClick === 'function') {
                            onClick();
                        } else {
                            toggleDropdown();
                        }
                        if (isSideBar) {
                            logEventToGainsight(
                                'CalendarSideBarButtonClicked',
                                {
                                    location: 'SideBar',
                                    clickedFrom: getCurrentPageFromUrl(),
                                },
                            );
                        } else if (isBurgerMenu) {
                            logEventToGainsight(
                                'CalendarSideBarButtonClicked',
                                {
                                    location: 'BurgerMenu',
                                    clickedFrom: getCurrentPageFromUrl(),
                                },
                            );
                        }
                    }}
                    buttonText="My Calendar"
                    tooltip="View today's events in your calendar"
                    isActive={isDropdownVisible}
                >
                    <CalendarIcon />
                </SideBarButton>
            )}
            renderDropdownContents={({isDropdownVisible}) =>
                isDropdownVisible ? (
                    <CalendarWidget
                        widgetLocation="SideBar"
                        titleUrl="/my-mis-ui/calendar/"
                        configs={{url: calendarUrl}}
                        focusHeaderOnOpen={isSideBar}
                    />
                ) : null
            }
            dropdownWidth="320px"
            preferPosition="right"
            alignDropdown="end"
            offset={0}
        />
    );
};
