export const AskArborLogo = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 14 14"
        fill="none"
        className="arbor-button__ask-arbor-logo"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="7" cy="7" r="6.5" fill="white" />
        <path
            d="M9.76952 5.1605L10.9861 5.50809C11.1019 5.54083 11.18 5.64662 11.18 5.765C11.18 5.88338 11.1019 5.98917 10.9861 6.02191L9.76952 6.3695L9.42194 7.58606C9.38919 7.70192 9.28341 7.78 9.16502 7.78C9.04664 7.78 8.94086 7.70192 8.90811 7.58606L8.56052 6.3695L7.34397 6.02191C7.22811 5.98917 7.15002 5.88338 7.15002 5.765C7.15002 5.64662 7.22811 5.54083 7.34397 5.50809L8.56052 5.1605L8.90811 3.94394C8.94086 3.82808 9.04664 3.75 9.16502 3.75C9.28341 3.75 9.38919 3.82808 9.42194 3.94394L9.76952 5.1605Z"
            fill="#F7941D"
        />
        <path
            d="M6.67551 3.31451L7.42114 3.52755C7.49215 3.54762 7.54001 3.61246 7.54001 3.68501C7.54001 3.75757 7.49215 3.82241 7.42114 3.84247L6.67551 4.05551L6.46247 4.80114C6.4424 4.87216 6.37756 4.92001 6.30501 4.92001C6.23245 4.92001 6.16761 4.87216 6.14754 4.80114L5.93451 4.05551L5.18888 3.84247C5.11786 3.82241 5.07001 3.75757 5.07001 3.68501C5.07001 3.61246 5.11786 3.54762 5.18888 3.52755L5.93451 3.31451L6.14754 2.56888C6.16761 2.49787 6.23245 2.45001 6.30501 2.45001C6.37756 2.45001 6.4424 2.49787 6.46247 2.56888L6.67551 3.31451Z"
            fill="#CFDD27"
        />
        <path
            d="M6.49355 7.78652L8.18103 8.26865C8.34174 8.31407 8.45005 8.46081 8.45005 8.62502C8.45005 8.78922 8.34174 8.93596 8.18103 8.98138L6.49355 9.46352L6.01141 11.151C5.96599 11.3117 5.81925 11.42 5.65505 11.42C5.49084 11.42 5.3441 11.3117 5.29868 11.151L4.81655 9.46352L3.12907 8.98138C2.96835 8.93596 2.86005 8.78922 2.86005 8.62502C2.86005 8.46081 2.96835 8.31407 3.12907 8.26865L4.81655 7.78652L5.29868 6.09904C5.3441 5.93832 5.49084 5.83002 5.65505 5.83002C5.81925 5.83002 5.96599 5.93832 6.01141 6.09904L6.49355 7.78652Z"
            fill="#7BB93C"
        />
    </svg>
);
