import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import {useRef, useState, useEffect} from 'react';
import {createPortal} from 'react-dom';

import {
    FloatingElementOffAxisAlignment,
    IFloatingElementPosition,
    getFloatingElementPosition,
    FloatingElementDirection,
} from 'Utils/getFloatingElementPosition';
import {useIsMounted} from 'Utils/useIsMounted';

import {DROPDOWN_PORTAL_ID} from './DropdownContainer';

import type {ReactNode} from 'react';

type ControlledDropdownContainerProps = {
    renderDropdownField: (props: {
        dropdownFieldRef: HTMLDivElement | null;
        dropdownContentsRef: HTMLDivElement | null;
    }) => ReactNode;
    renderDropdownContents: (props: {
        dropdownFieldRef: HTMLDivElement | null;
        dropdownContentsRef: HTMLDivElement | null;
    }) => ReactNode;
    dropdownWidth?: string;
    preferPosition?: FloatingElementDirection;
    alignDropdown?: FloatingElementOffAxisAlignment;
    offset?: number;
    isDropdownVisible: boolean;
    restrictPositionToVertical?: boolean;
    contentsWrapperClassName?: string;
};

/**
 * ControlledDropdownContainer is similar to DropdownContainer, and is a component
 * to be used for building dropdown-like components. The key difference is that
 * this component is a "Controlled Component".
 * (See https://legacy.reactjs.org/docs/uncontrolled-components.html & https://legacy.reactjs.org/docs/forms.html#controlled-components)
 *
 * This component takes care of dropdown positioning, and has several positioning options as props.
 * All other behaviours are expected to be handled by the parent component, for example
 * opening/closing (via the isDropdownVisible prop), and any mouse/keyboard interactions.
 *
 * For most cases it is recommended to use DropdownContainer instead, however ControlledDropdownContainer
 * is useful for cases where you have nested dropdowns, or some complicated keyboard behaviour (e.g.  NavigationDropdownMenuItem)
 * @returns React.ReactNode
 */
export const ControlledDropdownContainer = ({
    renderDropdownField,
    renderDropdownContents,
    dropdownWidth,
    preferPosition,
    offset,
    alignDropdown,
    isDropdownVisible,
    restrictPositionToVertical,
    contentsWrapperClassName,
}: ControlledDropdownContainerProps) => {
    const dropdownField = useRef<HTMLDivElement>(null);
    const dropdownContents = useRef<HTMLDivElement>(null);

    const [dropdownPosition, setDropdownPosition] =
        useState<IFloatingElementPosition | null>(null);

    const targetNode = useRef(document.getElementById(DROPDOWN_PORTAL_ID));

    const _isMounted = useIsMounted();

    const repositionDropdown = () => {
        if (_isMounted && dropdownField.current && dropdownContents.current) {
            setDropdownPosition(
                getFloatingElementPosition(
                    dropdownField.current,
                    dropdownContents.current,
                    {
                        offset: offset ?? 4,
                        allowHorizontal: !restrictPositionToVertical,
                        preferPosition,
                        offAxisAlignment: alignDropdown,
                    },
                ),
            );
        }
    };
    useResizeObserver(dropdownField.current, () => {
        if (isDropdownVisible) {
            repositionDropdown();
        }
    });
    useEffect(() => {
        const onScroll: EventListener = (e) => {
            if (dropdownContents.current?.contains(e.target as Node)) {
                // Don't reposition on scrolls inside the dropdown contents, so that
                // dropdowns can scroll internally without affecting anything
                return;
            }
            repositionDropdown();
        };
        const onResize: EventListener = () => {
            repositionDropdown();
        };

        if (isDropdownVisible) {
            // While dropdown was closed, the screen may have been resized, scrolled, and so
            // positioning twice helps us ensure that the position is accurate.
            repositionDropdown();
            setTimeout(() => {
                repositionDropdown();
                setTimeout(() => {
                    // handle deeply nested dropdowns
                    repositionDropdown();
                }, 0);
            }, 0);

            // 3rd parameter set to `true` to use the capture phase of the event,
            // as we want to listen to all scroll events
            document.addEventListener('scroll', onScroll, true);
            window.addEventListener('resize', onResize);
        } else {
            document.removeEventListener('scroll', onScroll, true);
            window.removeEventListener('resize', onResize);
        }
        return () => {
            if (isDropdownVisible) {
                document.removeEventListener('scroll', onScroll, true);
                window.removeEventListener('resize', onResize);
            }
        };
        // repositionDropdown might change when other props change, but this effect
        // is only intended to run on visibility changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownVisible]);

    const renderContents = () => (
        <div
            ref={dropdownContents}
            className={classNames(
                'dropdown-contents-wrapper',
                {
                    'dropdown-open': isDropdownVisible,
                },
                contentsWrapperClassName,
            )}
            style={{
                ...(dropdownWidth ? {minWidth: dropdownWidth} : {}),
                ...(dropdownPosition !== null &&
                typeof dropdownPosition !== 'undefined'
                    ? {
                          left: dropdownPosition.x + 'px',
                          ...(dropdownPosition.position === 'top'
                              ? {bottom: dropdownPosition.bottom}
                              : {top: dropdownPosition.y + 'px'}),
                          maxWidth: dropdownPosition.maxWidth,
                          maxHeight: dropdownPosition.maxHeight,
                          width: dropdownPosition.fixedElementWidth,
                      }
                    : {}),
            }}
        >
            {/**
             * Don't render contents if not visible.
             * This is to avoid performance regressions in large nested dropdowns,
             * such as NavigationDropdownMenu (the only usage of ControlledDropdownContainer so far).
             * If there is a use-case where we want the items to be rendered but hidden, we can add
             * a prop to ControlledDropdownContainer to decide this behaviour
             * */}
            {isDropdownVisible &&
                renderDropdownContents({
                    dropdownFieldRef: dropdownField.current,
                    dropdownContentsRef: dropdownContents.current,
                })}
        </div>
    );

    return (
        <div
            className={classNames('dropdown-container', {
                'dropdown-open': isDropdownVisible,
            })}
            data-testid="dropdown-container"
            role="presentation"
        >
            <div
                className="dropdown-field-wrapper"
                ref={dropdownField}
                role="presentation"
            >
                {renderDropdownField({
                    dropdownFieldRef: dropdownField.current,
                    dropdownContentsRef: dropdownContents.current,
                })}
            </div>
            {targetNode.current
                ? createPortal(renderContents(), targetNode.current)
                : renderContents()}
        </div>
    );
};
