import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {Component} from 'react';

import {Button} from 'Components/button/base';
import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {STRIPE_WINDOW_PLACEHOLDER_ID} from 'Constants/idStrings';
import {loadCurrentPage} from 'Interfaces/Loader';
import {getUser} from 'Interfaces/Session';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';

import CheckoutForm from './CheckoutForm';
import {wrapperStyle} from './style';

const WrappedButton = buttonWrapper(Button);

const API_KEY = {
    TEST_KEY: 'pk_test_PTddzqrNlqag9uH9lZiKXDN6',
};

type CheckoutWrapperProps = {
    banAmex?: boolean;
    url: string;
    amount: string | number;
    onModalClose: () => void;
    currency?: string;
    fields: Record<string, unknown>;
    isMobile?: boolean;
    scaAuthUrl?: string;
    scaRejectedAuthUrl?: string;
    isSmsStripeAccount?: boolean;
};

type CheckoutWrapperState = {
    success: boolean;
    stripePromise: Promise<Stripe | null>;
};

class CheckoutWrapper extends Component<
    CheckoutWrapperProps,
    CheckoutWrapperState
> {
    constructor(props: CheckoutWrapperProps) {
        super(props);

        let apiKey = global.externalConfig.stripeKeyNew
            ? global.externalConfig.stripeKeyNew
            : API_KEY.TEST_KEY;
        if (props.isSmsStripeAccount) {
            apiKey = global.externalConfig.insightStripeKey
                ? global.externalConfig.insightStripeKey
                : API_KEY.TEST_KEY;
        }
        const userSettings = getUser();
        const apiVersion = userSettings?.payment?.stripeApiVersion;
        const stripePromise = loadStripe(
            apiKey,
            apiVersion
                ? {
                      apiVersion,
                  }
                : {},
        );

        this.onModalClose = this.onModalClose.bind(this);

        this.state = {
            success: false,
            stripePromise,
        };
    }
    onModalClose() {
        if (this.state.success) {
            this.setState({
                success: false,
            });
            loadCurrentPage();
        }
        this.props.onModalClose();
    }

    onSuccess() {
        this.setState({
            success: true,
        });
    }

    render() {
        if (this.state.success) {
            return (
                <FocusTrappingModal
                    onClose={this.onModalClose}
                    title="Payment Successful"
                    hideFullScreenButton
                    fullScreen={this.props.isMobile}
                    portalTargetNodeId={STRIPE_WINDOW_PLACEHOLDER_ID}
                >
                    <div style={wrapperStyle.main}>
                        <span
                            style={wrapperStyle.successIcon}
                            className="arbor-icon arbor-icon-tick"
                        ></span>
                        <div style={wrapperStyle.text}>
                            Thank you! Your payment is complete
                        </div>
                        <div>
                            <WrappedButton
                                text="Done"
                                color="green"
                                onClick={this.onModalClose}
                            />
                        </div>
                    </div>
                </FocusTrappingModal>
            );
        }

        return (
            <FocusTrappingModal
                onClose={this.onModalClose}
                title="Make Payment"
                hideFullScreenButton
                fullScreen={this.props.isMobile}
                portalTargetNodeId={STRIPE_WINDOW_PLACEHOLDER_ID}
            >
                <Elements stripe={this.state.stripePromise}>
                    <CheckoutForm
                        banAmex={this.props.banAmex}
                        amount={this.props.amount}
                        url={this.props.url}
                        onModalClose={this.props.onModalClose}
                        currency={this.props.currency}
                        fields={this.props.fields}
                        onSuccess={this.onSuccess.bind(this)}
                        scaAuthUrl={this.props.scaAuthUrl}
                        scaRejectedAuthUrl={this.props.scaRejectedAuthUrl}
                        isSmsStripeAccount={this.props.isSmsStripeAccount}
                    />
                </Elements>
            </FocusTrappingModal>
        );
    }
}

export default CheckoutWrapper;
