import {CanvasElement} from 'BackendRenderedComponents/chatCanvas/ChatCanvas';
import {isLoggedIn} from 'Interfaces/Session';
import {subscribe} from 'Interfaces/websocket';
import Pubsub from 'Services/pubsub/Pubsub';

export interface CanvasElementMetadata {
    pushChannel: string;
}

const CHANNEL = 'agentMessage';

export type AgentNotificationSubscriptionDetails = {
    channel: string;
    eventName: string;
    subscriptionId: string;
};
const EVENT_NAME = 'agentMessage:new_message';

class AgentNotification {
    agentMessageMetadata: CanvasElementMetadata;
    private lastMessageContent: string | null = null;
    public subscriptionId: string | null = null;

    constructor() {
        this.agentMessageMetadata = {
            pushChannel: CHANNEL,
        };
    }

    getPushChannel = (): string => {
        return CHANNEL;
    };

    getEventName = (): string => {
        return EVENT_NAME;
    };

    init = (agentMessageMetadata?: CanvasElementMetadata) => {
        Object.assign(this.agentMessageMetadata, agentMessageMetadata);
        if (isLoggedIn()) {
            this.initSocket();
        }
    };

    initSocket = () => {
        subscribe(
            this.agentMessageMetadata.pushChannel,
            EVENT_NAME,
            this.handleAgentMessage,
        );
    };

    subscribe = (callback: (message: CanvasElement) => void) => {
        const subscriptionId = Pubsub.subscribe(
            'receivedAgentMessage',
            callback,
        );
        this.subscriptionId = subscriptionId;
        return {
            channel: this.agentMessageMetadata.pushChannel,
            eventName: EVENT_NAME,
            subscriptionId,
        } as AgentNotificationSubscriptionDetails;
    };

    unsubscribe = (subscriptionId: string | undefined = undefined) => {
        if (subscriptionId) {
            return Pubsub.unsubscribe('receivedAgentMessage', subscriptionId);
        }
        return Pubsub.unsubscribe('receivedAgentMessage', this.subscriptionId!);
    };

    handleAgentMessage = (message: CanvasElement) => {
        if (message.content === this.lastMessageContent) {
            return; // Don't publish a duplicate. This seems to be a bug that occurs when a component is updated during Dev. It doesn't happen in Prod.
        }
        this.lastMessageContent = message.content || null;
        Pubsub.publish('receivedAgentMessage', message);
    };
}

let agentNotificationInstance: AgentNotification | null = null;
export const getAgentNotificationService = () => {
    if (!agentNotificationInstance) {
        agentNotificationInstance = new AgentNotification();
    }
    return agentNotificationInstance;
};
