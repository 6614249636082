import {combineReducers, createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';

import dashboard from 'BackendRenderedComponents/dashboard/redux/reducers';
import {getDashboardSaga} from 'BackendRenderedComponents/dashboard/redux/sagas';
import {navigationReducer} from 'State/navigation/reducers';
import basket from 'State/reducers/Basket';

import {burgerMenuReducer} from './burgerMenu';
import {screenTypeReducer} from './screenType/reducers';

let instance = null;

const reducers = combineReducers({
    basket,
    burgerMenu: burgerMenuReducer,
    dashboard,
    screenType: screenTypeReducer,
    navigation: navigationReducer,
});

function* saga() {
    yield all([getDashboardSaga()]);
}

class Store {
    constructor() {
        if (!instance) {
            this._resetStore();
        }

        return instance;
    }

    getStore() {
        return this.store;
    }

    _setStore(store) {
        this.store = store;
    }

    _resetStore() {
        instance = this;
        this.store = null;
        const sagaMiddleware = createSagaMiddleware();
        let middlewares = [sagaMiddleware];

        let store = createStore(
            reducers,
            {},
            composeWithDevTools(applyMiddleware(...middlewares)),
        );
        sagaMiddleware.run(saga);
        this._setStore(store);
    }
}

export default new Store();
