import {UserFavouritePage} from 'Services/session/fetchCurrentUserSettings';

import {TransformedNavigationItem} from '../types';

export const addFavouritesToTree = (
    treeData: TransformedNavigationItem[],
    userFavouritePages: UserFavouritePage[],
) => {
    const transformedData: TransformedNavigationItem[] = treeData.map(
        (item) => {
            const {items, subTree} = item;
            const foundUserFavourite = userFavouritePages.find(
                (page) => page.route === item.url,
            );
            return {
                ...item,
                ...(foundUserFavourite
                    ? {favouritedPage: foundUserFavourite}
                    : {}),
                ...(items
                    ? {
                          items: addFavouritesToTree(items, userFavouritePages),
                      }
                    : {}),
                ...(subTree
                    ? {
                          subTree: {
                              ...subTree,
                              items: addFavouritesToTree(
                                  subTree.items,
                                  userFavouritePages,
                              ),
                          },
                      }
                    : {}),
            };
        },
    );
    return transformedData;
};
