import {useState} from 'react';

import {ComboboxItem, ComboboxValue} from 'Components/combobox';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {fetchComboboxOptions} from './fetchComboboxOptions';

export const useFetchComboboxOptions = ({
    optionsUrl,
    hasDependencyFields,
    params,
    onOptionsFetched,
}: {
    optionsUrl?: string;
    hasDependencyFields?: boolean;
    params?: unknown;
    onOptionsFetched?: (params: {
        options: ComboboxItem[];
        initialValues: ComboboxValue[];
    }) => void;
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [comboboxOptions, setComboboxOptions] = useState<ComboboxItem[]>([]);
    const [error, setError] = useState('');
    const [initialOptionValues, setInitialOptionValues] = useState<string[]>(
        [],
    );

    const fetchData = async ({
        urlToFetch,
        fetchParams,
        successCallback,
        failureCallback,
    }: {
        urlToFetch: string;
        fetchParams?: unknown;
        successCallback?: () => void;
        failureCallback?: () => void;
    }) => {
        try {
            const options = await fetchComboboxOptions(urlToFetch, fetchParams);
            const selectedOptions = options.filter(
                (option) => option.selected === true,
            );
            const initialValues = selectedOptions.map((item) =>
                String(item.value),
            );
            setInitialOptionValues((prevValues) => {
                // Use a deep comparison to check if the values have actually changed.
                // When re-fetching dependent comboboxes it's quite common that the
                // seleceted values don't actualy change.
                // When they haven't changed return prevValues so that object references don't change
                // This helps avoid unnecessary renders, effects etc.
                if (
                    JSON.stringify(initialValues) === JSON.stringify(prevValues)
                ) {
                    return prevValues;
                }
                return initialValues;
            });
            setComboboxOptions((prevOptions) => {
                // Use a deep comparison to check if the options have actually changed.
                // When re-fetching dependent comboboxes it's quite common that the
                // options don't actualy change.
                // When they haven't changed return prevOptions so that object references don't change
                // This helps avoid unnecessary renders, effects etc.
                if (JSON.stringify(options) === JSON.stringify(prevOptions)) {
                    return prevOptions;
                }
                return options;
            });
            if (typeof onOptionsFetched === 'function') {
                // To be used in components using useFetchComboboxOptions
                onOptionsFetched({options, initialValues});
            }
            if (typeof successCallback === 'function') {
                // Used by the FormField service
                successCallback();
            }
        } catch (e) {
            console.error(e);
            setError(
                `Something went wrong fetching combobox options from: ${urlToFetch}.`,
            );
            if (typeof failureCallback === 'function') {
                // Used by the FormField service
                failureCallback();
            }
        } finally {
            setIsLoading(false);
        }
    };

    useComponentDidMount(() => {
        if (typeof optionsUrl === 'undefined') {
            // Note: due to the "Rules of Hooks" you are not allowed to conditionally
            // call hooks, so we have support for optional url here.
            // See https://legacy.reactjs.org/docs/hooks-rules.html
            setIsLoading(false);
            return;
        }
        if (hasDependencyFields) {
            // If the combobox has dependency fields, we should wait to get the initial
            // values of those dependency fields before fetching. This fetch will be
            // handled by the form field registry
            return;
        }
        fetchData({urlToFetch: optionsUrl, fetchParams: params});
    });

    const refetchComboboxOptions = ({
        dependeeFormFieldValues,
        successCallback,
        failureCallback,
    }: {
        dependeeFormFieldValues: Record<string, unknown>;
        successCallback?: () => void;
        failureCallback?: () => void;
    }) => {
        if (typeof optionsUrl === 'undefined') {
            console.error('refetchComboboxOptions, optionsUrl === "undefined"');
            return;
        }
        setIsLoading(true);
        fetchData({
            urlToFetch: optionsUrl,
            fetchParams: {
                ...(params ?? {}),
                'parent-combo-values': dependeeFormFieldValues,
                // It's a bit weird that we send this instead of just parent-combo-values, however
                // it is definietely used a bunch in SIS.
                'parent-combo-value':
                    dependeeFormFieldValues[
                        Object.keys(dependeeFormFieldValues)[0]
                    ],
            },
            successCallback,
            failureCallback,
        });
    };

    return {
        isLoading,
        comboboxOptions,
        error,
        initialOptionValues,
        refetchComboboxOptions,
    };
};
