import classnames from 'classnames';

import './kpiBox.scss';

type KpiBoxProps = {
    html: string;
    isClickable?: boolean;
    isSelected?: boolean;
    hideBorder?: boolean;
};

export const KpiBox = ({
    html,
    isClickable,
    isSelected,
    hideBorder,
}: KpiBoxProps) => (
    <div
        data-testid="kpi-box"
        // Note: the mis-kpi-box class lives in the shared scss directory
        // (client/sass/src/container/KpiBox.scss) and is also used
        // by the Ext version of KpiBox
        className={classnames('old-kpi-box', {
            'old-kpi-box--clickable': isClickable,
            'old-kpi-box--selected': isSelected,
            'old-kpi-box--hide-border': hideBorder,
        })}
        // Prevent this inner div being keyboard focused as the
        // KeyboardFocusableLink handles the focus state.
        // Note: this is only necessary on Firefox
        tabIndex={-1}
        dangerouslySetInnerHTML={{__html: html}}
    />
);
