import {PropertyRows} from './propertyRows';

import './createActionPopup.scss';

type CreateActionPopupProps = {
    createActions: {
        title: string;
        url: string;
        disabled?: boolean;
    }[];
    createText: string;
    sectionTitle: string;
};

export const CreateActionPopup = (props: CreateActionPopupProps) => {
    const {createText, sectionTitle, createActions} = props;

    return (
        <div className="create-action-popup__container" role="presentation">
            <h1 className="create-action-popup__header">
                {createText} in:{' '}
                <span className="create-action-popup__header--bold">
                    {sectionTitle}
                </span>
            </h1>

            <PropertyRows
                rows={createActions.map((action) => ({
                    url: action.url,
                    value: action.title,
                    disabled: action.disabled,
                }))}
            />
        </div>
    );
};
