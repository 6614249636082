// Each interface registers itself in the global Arbor object
import {registerBasketInterface} from 'Interfaces/basket/Basket';
import {registerConstantsInterface} from 'Interfaces/constants/Constants';
import {registerCreateActionPopupInterface} from 'Interfaces/CreateActionPopup';
import {registerCurrencyInterface} from 'Interfaces/currency/Currency';
import {registerDecisionPopupInterface} from 'Interfaces/decisionPopup';
import {registerErrorInterface} from 'Interfaces/error/Error';
import {registerFormInterface} from 'Interfaces/form/Form';
import {registerFormFieldInterface} from 'Interfaces/formFields';
import {registerHashInterface} from 'Interfaces/Hash';
import {registerHistoryInterface} from 'Interfaces/history/History';
import {registerHtmlSanitizeInterface} from 'Interfaces/htmlSanitize';
import {registerInterceptBadResponseInterface} from 'Interfaces/httpClient/interceptors/InterceptBadResponse';
import {registerInterceptResponseInterface} from 'Interfaces/httpClient/interceptors/InterceptResponse';
import {registerLoaderInterface} from 'Interfaces/Loader';
import {registerMaskInterface} from 'Interfaces/Mask';
import {registerRavenInterface} from 'Interfaces/raven/Raven';
import {registerScrollInterface} from 'Interfaces/Scroll';
import {registerSectionSelectorInterface} from 'Interfaces/SectionSelector';
import {registerSessionInterface} from 'Interfaces/Session';
import {registerSlideoverInterface} from 'Interfaces/Slideover';
import {registerSystemModalInterface} from 'Interfaces/SystemModal';
import {registerSystemNotificationInterface} from 'Interfaces/SystemNotification';
import {registerUrlInterface} from 'Interfaces/Url';
import {registerWebSocketInterface} from 'Interfaces/websocket/WebSocket';
import {registerWindowInterface} from 'Interfaces/window/Window';
import {registerWindowManagerInterface} from 'Interfaces/WindowManager';

import {registerRefreshableComponentsInterface} from './RefreshableComponents';
import {registerRenderTargetsInterface} from './renderTargets';
import {registerSnippetInterface} from './Snippets';
import {registerTabInterface} from './Tab';
import {registerUnsavedDataInterface} from './UnsavedData';

export const registerInterfaces = () => {
    registerTabInterface();
    registerBasketInterface();
    registerConstantsInterface();
    registerCurrencyInterface();
    registerErrorInterface();
    registerFormInterface();
    registerHistoryInterface();
    registerInterceptBadResponseInterface();
    registerInterceptResponseInterface();
    registerLoaderInterface();
    registerMaskInterface();
    registerRavenInterface();
    registerScrollInterface();
    registerSessionInterface();
    registerSlideoverInterface();
    registerSystemModalInterface();
    registerSystemNotificationInterface();
    registerUrlInterface();
    registerWebSocketInterface();
    registerWindowInterface();
    registerWindowManagerInterface();
    registerSectionSelectorInterface();
    registerHashInterface();
    registerHtmlSanitizeInterface();
    registerRefreshableComponentsInterface();
    registerFormFieldInterface();
    registerSnippetInterface();
    registerCreateActionPopupInterface();
    registerUnsavedDataInterface();
    registerDecisionPopupInterface();
    registerRenderTargetsInterface();
};
