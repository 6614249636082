import {imageUrlToBase64} from './imageUrlToBase64';

export const addSisMergeFieldSyntax = async (
    str: string,
    label: string,
): Promise<string> => {
    const url = `/image/placeholder/value/${encodeURIComponent(
        str,
    )}/?text=${encodeURIComponent(label)}`;

    const base64Encoded = await imageUrlToBase64(url);

    return `<img class="mis-merge-field" src="${base64Encoded}" height="18" alt="${label}" data-arbor-mis-merge-field-value="${str}">`;
};
