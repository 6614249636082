import {useEffect, useState} from 'react';

export const ChartToggleContainer = (props) => {
    const [hasBeenSelected, setHasBeenSelected] = useState(props.isSelected);
    useEffect(() => {
        if (props.isSelected && !hasBeenSelected) {
            setHasBeenSelected(true);
        }
        // hasBeenSelected gets set in this effect, so we'd end up with excessive re-renders
        // is it was a dependency of this effect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSelected]);

    return (
        (props.isSelected || hasBeenSelected) && (
            <div
                role="tabpanel"
                className="chart-toggle-container"
                style={{display: props.isSelected ? 'block' : 'none'}}
                aria-hidden={hasBeenSelected ? 'false' : 'true'}
            >
                {props.children}
            </div>
        )
    );
};
