import {SYSTEM_MODAL} from 'Config/Events';
import {SystemModalConfig} from 'Renderer/systemModal/types';
import pubsub from 'Services/pubsub/Pubsub';

import exportInterface from './Base';

export const addSystemModal = (systemModal: SystemModalConfig) => {
    pubsub.publish(SYSTEM_MODAL.ADD, systemModal);
};

export const registerSystemModalInterface = () => {
    exportInterface('SystemModal', {add: addSystemModal});
};
