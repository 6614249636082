import {DropdownContainer} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {
    refreshCurrentPage,
    refreshLatestSlideover,
    triggerNotification,
} from 'Services/devTools/DevTools';

import {SideBarButton} from '../sideBarButton';

import './devToolsButton.scss';

export const DevToolsButton = () => {
    const items = [
        {
            text: 'Refresh current page',
            onClick: () => refreshCurrentPage(),
        },
        {
            text: 'Refresh latest slideover',
            onClick: () => refreshLatestSlideover(),
        },
        {
            text: 'Trigger user notification',
            onClick: () => triggerNotification(),
        },
    ];
    return (
        <DropdownContainer
            dropdownWidth="170px"
            renderDropdownField={({
                toggleDropdown,
                isDropdownVisible,
                toggleDropdownOnEnterOrSpace,
            }) => (
                <SideBarButton
                    onClick={toggleDropdown}
                    onKeyDown={toggleDropdownOnEnterOrSpace}
                    buttonText="Dev Tools"
                    isActive={isDropdownVisible}
                >
                    <Icon
                        iconName={
                            isDropdownVisible ? 'arrow-go-up' : 'arrow-go-down'
                        }
                        aria-hidden
                    />
                </SideBarButton>
            )}
            renderDropdownContents={({}) => (
                <ul className="dev-tools-dropdown-container">
                    {items.map((item, index) => (
                        <li key={index}>
                            <KeyboardFocusableButton
                                buttonWrapperClassName="dev-tools-dropdown-item__wrapper"
                                onClick={item.onClick}
                                tabIndex={-1}
                                insetFocusRing
                            >
                                {item.text}
                            </KeyboardFocusableButton>
                        </li>
                    ))}
                </ul>
            )}
        />
    );
};
