import {useRef} from 'react';

import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {useSimpleTooltip} from 'Components/tooltip';
import externalConfig from 'Config/External';
import url from 'Services/url/Url';

import './schoolLogoButton.scss';

type SchoolLogoButtonProps = {
    organizationName?: string;
};

export const SchoolLogoButton = ({organizationName}: SchoolLogoButtonProps) => {
    const buttonRef = useRef<HTMLAnchorElement>(null);
    const schoolLogoUrl =
        externalConfig.getConfig().schoolInfo.schoolLogo || url.LOGO;

    const {tooltipEventHandlers} = useSimpleTooltip({
        title: organizationName,
        elementRef: buttonRef,
        preferPosition: 'bottom',
    });

    return (
        <KeyboardFocusableLink
            url={url.HOME_PAGE}
            linkWrapperClassName="school-logo-button__wrapper"
            className="school-logo-button"
            insetFocusRing
            setRef={buttonRef}
            {...tooltipEventHandlers}
        >
            <div
                style={{backgroundImage: `url(${schoolLogoUrl})`}}
                className="school-logo-button__image"
                role="img"
                aria-label="School logo button. Visit homepage"
                title="Visit homepage"
                data-testid="header-school-logo"
            />
        </KeyboardFocusableLink>
    );
};
