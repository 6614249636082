import classnames from 'classnames';
import {Component} from 'react';

import externalConfig from 'Config/External';
import {isLoggedIn, getUser} from 'Interfaces/Session';
import {ScreenType} from 'Root/core/utils/screenType/screenTypes';
import {reportToSisSentry} from 'Services/raven/Raven';
import {TABLET_PORTRAIT, MOBILE} from 'Utils/screenType';

import {
    BurgerMenuButton,
    DevToolsButton,
    NotificationButton,
    SkipToContentButton,
    SchoolLogoButton,
    EmergencyAlertButton,
    FavouritesButton,
} from './buttons';
import {AskArborButton} from './buttons/askArborButton';
import {NavigationDropdownMenu} from './navigation';
import {SearchBar} from './searchbar';

import './header.scss';

const config = externalConfig.getConfig();

interface IHeaderProps {
    screenType: ScreenType;
}

export class Header extends Component<IHeaderProps> {
    private devEnv = config.debugEnvironment;

    constructor(props: IHeaderProps) {
        super(props);

        const {organizationName, showSearch} = getUser() || {};
        if (!organizationName && !showSearch) {
            console.error(
                'User has no name, organisation or search bar in header!',
                getUser(),
                isLoggedIn(),
            );
            reportToSisSentry(
                new Error(
                    'User has no name, organisation or search bar in header!',
                ),
                {
                    userServiceData: getUser(),
                    loggedIn: isLoggedIn(),
                },
            );
        }
    }

    render() {
        const {showSearch, organizationName, showAskArborButton} =
            getUser() || {};
        const {screenType} = this.props;

        const isTabletOrMobile =
            screenType === TABLET_PORTRAIT || screenType === MOBILE;
        const isMobile = screenType === MOBILE;

        return (
            <header className="header" aria-label="Header">
                <SkipToContentButton />
                <div className="container-bar">
                    <div
                        className={classnames('top-bar', {
                            'top-bar__mobile': isTabletOrMobile,
                        })}
                    >
                        {isTabletOrMobile ? (
                            <>
                                <BurgerMenuButton />
                                <FavouritesButton />
                                {showSearch && !isMobile && (
                                    <div className="top-bar__search-container">
                                        <SearchBar />
                                    </div>
                                )}
                                {!isMobile && (
                                    <EmergencyAlertButton preferTooltipPosition="bottom" />
                                )}
                                <NotificationButton preferTooltipPosition="bottom" />
                            </>
                        ) : (
                            <>
                                <SchoolLogoButton
                                    organizationName={organizationName}
                                />
                                <span className="header-image-spacer" />
                                <NavigationDropdownMenu />
                                {showSearch && (
                                    <div className="top-bar__search-container">
                                        <SearchBar />
                                    </div>
                                )}
                                {showAskArborButton && <AskArborButton />}
                                <div className="arbor-logo">
                                    <img
                                        src="/img/logos/arbor_logo_square_big.png"
                                        width="26px"
                                        height="26px"
                                    />
                                    <span className="arbor-logo__text">
                                        Arbor
                                    </span>
                                </div>
                            </>
                        )}
                        {this.devEnv && <DevToolsButton />}
                    </div>
                </div>
            </header>
        );
    }
}
