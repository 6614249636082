import {
    SET_INITIAL_FILTERS,
    TOGGLE_FILTER_BY_ID,
    SET_SELECTED_TAB_BY_ID,
    SAVE_FILTERS_REQUEST,
    SAVE_FILTERS_SUCCESS,
    SAVE_FILTERS_FAILURE,
    FETCH_KPI_DATA_REQUEST,
    FETCH_KPI_DATA_SUCCESS,
    FETCH_KPI_DATA_CACHED,
    FETCH_KPI_DATA_FAILURE,
    GET_TO_DOS_REQUEST,
    GET_TO_DOS_SUCCESS,
    GET_TO_DOS_FAILURE,
    GET_SCHOOL_NOTICES_REQUEST,
    GET_SCHOOL_NOTICES_SUCCESS,
    GET_SCHOOL_NOTICES_FAILURE,
    GET_STUDENT_ALERTS_REQUEST,
    GET_STUDENT_ALERTS_SUCCESS,
    GET_STUDENT_ALERTS_FAILURE,
    DISMISS_STUDENT_ALERT_REQUEST,
    DISMISS_STUDENT_ALERT_SUCCESS,
    DISMISS_STUDENT_ALERT_FAILURE,
} from './constants';

export function setInitialFilters(initialFilters) {
    return {
        type: SET_INITIAL_FILTERS,
        initialFilters,
    };
}

export function toggleFilterById({filterType, id}) {
    return {
        type: TOGGLE_FILTER_BY_ID,
        filterType,
        id,
    };
}

export function setSelectedTabById({id}) {
    return {
        type: SET_SELECTED_TAB_BY_ID,
        id,
    };
}

export function saveFiltersRequest() {
    return {
        type: SAVE_FILTERS_REQUEST,
    };
}

export function saveFiltersSuccess() {
    return {
        type: SAVE_FILTERS_SUCCESS,
    };
}

export function saveFiltersFailure(error) {
    return {
        type: SAVE_FILTERS_FAILURE,
        error,
    };
}

export function fetchKpiDataRequest({tabId}) {
    return {
        type: FETCH_KPI_DATA_REQUEST,
        tabId,
    };
}

export function fetchKpiDataSuccess({tabId, kpiGroups, numberOfStudents, url}) {
    return {
        type: FETCH_KPI_DATA_SUCCESS,
        tabId,
        kpiGroups,
        numberOfStudents,
        url,
    };
}

export function fetchKpiDataCached({tabId, url}) {
    return {
        type: FETCH_KPI_DATA_CACHED,
        tabId,
        url,
    };
}

export function fetchKpiDataFailure({tabId, error}) {
    return {
        type: FETCH_KPI_DATA_FAILURE,
        tabId,
        error,
    };
}

export function getToDosRequest(limit = 3) {
    return {
        type: GET_TO_DOS_REQUEST,
        limit,
    };
}

export function getToDosSuccess({toDos, total}) {
    return {
        type: GET_TO_DOS_SUCCESS,
        toDos,
        total,
    };
}

export function getToDosFailure(error) {
    return {
        type: GET_TO_DOS_FAILURE,
        error,
    };
}

export function getSchoolNoticesRequest(limit = 3) {
    return {
        type: GET_SCHOOL_NOTICES_REQUEST,
        limit,
    };
}

export function getSchoolNoticesSuccess({schoolNotices, total}) {
    return {
        type: GET_SCHOOL_NOTICES_SUCCESS,
        schoolNotices,
        total,
    };
}

export function getSchoolNoticesFailure(error) {
    return {
        type: GET_SCHOOL_NOTICES_FAILURE,
        error,
    };
}

export function getStudentAlertsRequest(limit = 3) {
    return {
        type: GET_STUDENT_ALERTS_REQUEST,
        limit,
    };
}

export function getStudentAlertsSuccess({studentAlerts, total}) {
    return {
        type: GET_STUDENT_ALERTS_SUCCESS,
        studentAlerts,
        total,
    };
}

export function getStudentAlertsFailure(error) {
    return {
        type: GET_STUDENT_ALERTS_FAILURE,
        error,
    };
}

export function dismissStudentAlertRequest(id, limit = 3) {
    return {
        type: DISMISS_STUDENT_ALERT_REQUEST,
        id,
        limit,
    };
}

export function dismissStudentAlertSuccess({studentAlerts, total}) {
    return {
        type: DISMISS_STUDENT_ALERT_SUCCESS,
        studentAlerts,
        total,
    };
}

export function dismissStudentAlertFailure(error) {
    return {
        type: DISMISS_STUDENT_ALERT_FAILURE,
        error,
    };
}
