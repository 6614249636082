import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import {logEventToGainsight} from 'Services/gainsight';
import {getCurrentPageFromUrl} from 'Services/url/getCurrentPageFromUrl';

import {ICalendarWidgetItem} from './CalendarWidget';

export const CalendarWidgetItem = (props: ICalendarWidgetItem) => (
    <li
        className={
            'calendar-widget-item calendar-widget-item-color--' +
            props.colorIndex
        }
    >
        <KeyboardFocusableLink
            url={props.url}
            disableDefaultLinkStyles
            className="calendar-widget-item-link"
            linkWrapperClassName="calendar-widget-item-link__wrapper"
            onClick={() => {
                logEventToGainsight('CalendarWidgetClicked', {
                    type: 'Item',
                    location: props.widgetLocation,
                    clickedFrom: getCurrentPageFromUrl(),
                });
            }}
        >
            <span className="calendar-widget-item-date">
                {props.startTime} - {props.endTime}
            </span>
            <span className="calendar-widget-item-title">{props.title}</span>
            {props.location !== null && (
                <span className="calendar-widget-item-location">
                    {props.location}
                </span>
            )}
        </KeyboardFocusableLink>
    </li>
);
