/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 9/7/18
 * Time: 15:04
 */

import {AxiosError} from 'axios';
import isObject from 'lodash-es/isObject';

import {raiseError, raiseResponseError} from 'Interfaces/error/Error';
import {ErrorResponseData} from 'Types/response';

const isString = (errorData: unknown): errorData is string =>
    typeof errorData === 'string';
const isResponseData = (data: unknown): data is ErrorResponseData =>
    isObject(data);

export default function interceptBadResponse(
    error: AxiosError<ErrorResponseData | string>,
) {
    if (error.response && error.code !== AxiosError.ERR_CANCELED) {
        const HTTP_MULTIPLE_CHOICE_STATUS = 300;
        const HTTP_NOT_AUTHORIZED_STATUS = 401;

        if (error.response.status === HTTP_NOT_AUTHORIZED_STATUS) {
            // Needed to show specific errors on login page
            if (
                error.response.data &&
                (error.response.data as any).status === 'error'
            ) {
                const customError = (error.response.data as any).error;
                if (customError) {
                    raiseError(
                        customError.title,
                        customError.reason,
                        'Not authorized',
                    );
                }
            }
            // Need to return promise with response object to show login page without errors
            return Promise.reject(error.response);
        }

        // Handle RedirectException
        if (
            error.response.status === HTTP_MULTIPLE_CHOICE_STATUS &&
            isResponseData(error.response.data)
        ) {
            window.location.replace(error.response.data.message);
            return Promise.reject(error.response.data);
        }

        const errorTitle = error.response.statusText;
        if (isResponseData(error.response.data)) {
            raiseResponseError(error.response.data, errorTitle);
        } else if (isString(error.response.data)) {
            try {
                const parsedResponse = JSON.parse(error.response.data);
                raiseResponseError(parsedResponse, errorTitle);
            } catch (e) {
                const message = error.response.data;
                raiseError(errorTitle, message);
            }
        }
        return Promise.reject(error.response);
    }
}
