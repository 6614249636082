import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';

import helper from 'Utils/helper';

import './box.scss';

class Box extends Component {
    cssPrefix = 'arbor-box';

    render() {
        const {
            container,
            direction,
            wrap,
            justify,
            align,
            alignSelf,
            grow,
            noGutter,
            className,
            children,
            mobile,
            tablet,
            desktop,
            wide,
            order,
            tabletOrder,
            desktopOrder,
            wideOrder,
            ...rest
        } = this.props;

        const prefix = this.cssPrefix;

        return (
            <div
                className={classNames(
                    prefix,
                    {
                        [`${prefix}-container`]: container,
                        [`${prefix}-flex-grow`]: grow,
                        [`${prefix}-align-self--${align}`]:
                            alignSelf !== 'auto',
                        [`${prefix}-flex-${mobile}`]: mobile,
                        [`${prefix}-flex-tablet-${tablet}`]: tablet,
                        [`${prefix}-flex-desktop-${desktop}`]: desktop,
                        [`${prefix}-flex-wide-${wide}`]: wide,
                        [`${prefix}-order-${order}`]: order >= 0,
                        [`${prefix}-order-tablet-${tabletOrder}`]:
                            tabletOrder >= 0,
                        [`${prefix}-order-desktop-${desktopOrder}`]:
                            desktopOrder >= 0,
                        [`${prefix}-order-wide-${wideOrder}`]: wideOrder >= 0,
                    },
                    className,
                )}
                {...rest}
            >
                <div
                    className={classNames(`${prefix}-inner`, {
                        [`${prefix}-direction--${direction}`]:
                            direction !== 'row',
                        [`${prefix}-wrap--${wrap}`]: wrap,
                        [`${prefix}-justify-content--${justify}`]:
                            justify !== 'flex-start',
                        [`${prefix}-align-items--${align}`]:
                            align !== 'stretch',
                        [`${prefix}-no-gutter`]: noGutter,
                    })}
                >
                    {' '}
                    {children}
                </div>
            </div>
        );
    }
}

Box.propTypes = {
    container: PropTypes.bool,
    direction: PropTypes.oneOf([
        'row',
        'row-reverse',
        'column',
        'column-reverse',
    ]),
    wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
    justify: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
    ]),
    align: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'baseline',
        'stretch',
    ]),
    alignSelf: PropTypes.oneOf([
        'auto',
        'flex-start',
        'flex-end',
        'center',
        'baseline',
        'stretch',
    ]),
    grow: PropTypes.bool,
    noGutter: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    // flex
    mobile: helper.range(1, 12),
    tablet: helper.range(1, 12),
    desktop: helper.range(1, 12),
    wide: helper.range(1, 12),
    // order
    order: helper.range(0, 10),
    tabletOrder: helper.range(0, 10),
    desktopOrder: helper.range(0, 10),
    wideOrder: helper.range(0, 10),
};

Box.defaultProps = {
    direction: 'row',
    justify: 'flex-start',
    align: 'stretch',
    alignSelf: 'auto',
};

export default Box;
