import classnames from 'classnames';
import {useRef} from 'react';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {useSimpleTooltip} from 'Components/tooltip';
import {FloatingElementDirection} from 'Root/core/utils/getFloatingElementPosition';

import './sideBarButton.scss';

export type SideBarButtonProps =
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        children?: React.ReactNode;
        className?: string;
        buttonWrapperClassName?: string;
        tooltip?: string;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
        buttonText?: string;
        preferTooltipPosition?: FloatingElementDirection;
        isActive?: boolean;
    };

/**
 * Button used in the SideBar, Header and BurgerMenu
 * Styles automatically adjust based on parent container
 * @param isActive An active state for styles, to use in cases where button is used to open dropdowns.
 */
export const SideBarButton = ({
    children,
    className,
    buttonWrapperClassName,
    tooltip,
    onClick,
    buttonText,
    preferTooltipPosition = 'right',
    isActive,
    ...others
}: SideBarButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const {tooltipEventHandlers, tooltipOnClick} = useSimpleTooltip({
        ...(tooltip ? {tooltip, title: buttonText} : {}),
        elementRef: buttonRef,
        preferPosition: preferTooltipPosition,
    });

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (typeof onClick === 'function') {
            onClick(event);
        }
        tooltipOnClick(event);
    };

    return (
        <KeyboardFocusableButton
            setRef={buttonRef}
            className={classnames(
                'side-bar-button',
                {'side-bar-button--active': isActive},
                className,
            )}
            buttonWrapperClassName={classnames(
                'side-bar-button__wrapper',
                buttonWrapperClassName,
            )}
            insetFocusRing
            onClick={handleClick}
            {...tooltipEventHandlers}
            {...others}
        >
            <div className="side-bar-button__icon">{children}</div>
            <div className="side-bar-button__text">{buttonText}</div>
        </KeyboardFocusableButton>
    );
};
