/*
    <br> gets submitted as <div>&nbsp;</div> so we assume any divs like this should be a <div><br></div>
    <span><br></span> and <br> are changed to <p><br/><p> so the correct styling is applied to them

    We need to allow the div/spans around the $nbsp; to have arbitary attributes
*/
export const formatHTML = (input: string) => {
    let formattedHTML = input?.replace(
        /(<div[^>]*>)&nbsp;<\/div>/g,
        '$1<br/></div>',
    );
    formattedHTML = formattedHTML.replace(
        /(<p[^>]*>)&nbsp;<\/p>/g,
        '$1<br/></p>',
    );

    const placeholderElement = document.createElement('div');
    placeholderElement.innerHTML = formattedHTML;

    if (placeholderElement.children.length === 1) {
        const [firstChild] = placeholderElement.children;

        if (firstChild.tagName === 'P') {
            const contents = firstChild.innerHTML;
            formattedHTML = contents;
        }
    }

    placeholderElement.remove();

    formattedHTML = formattedHTML.replace(/<br> /g, '<p><br/></p>');

    return formattedHTML;
};
