import exportInterface from 'Interfaces/Base';
import {sanitizeSimpleText as sanitizeSimpleTextService} from 'Utils/sanitizeHtml';

export const sanitizeSimpleTextInterface = (text) =>
    sanitizeSimpleTextService(text);

export const registerHtmlSanitizeInterface = () => {
    exportInterface('HtmlSanitize', {
        sanitizeSimpleText: sanitizeSimpleTextInterface,
    });
};
