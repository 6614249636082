import {SESSION_MODAL_PLACEHOLDER_ID} from 'Constants/idStrings';
import {
    getSessionModalRoot,
    setSessionModalRootContainer,
} from 'Interfaces/renderTargets';

export const unmountSessionTimeoutModal = () => {
    const sessionModalContainer = document.getElementById(
        SESSION_MODAL_PLACEHOLDER_ID,
    ) as HTMLElement;
    getSessionModalRoot()?.unmount();
    setSessionModalRootContainer(sessionModalContainer);
};
