import {Root, createRoot} from 'react-dom/client';

const logRootIfUndefined = (rootName: string, root: Root | undefined) => {
    if (typeof root === 'undefined') {
        console.error(
            `Count not find ${rootName}. Make sure you set the root before using it`,
        );
    }
};

class RenderTargets {
    private headerRoot?: Root;
    private appRoot?: Root;
    private simpleTooltipRoot?: Root;
    private slideoverRoot?: Root;
    private focusTrappingModalRoot?: Root;
    private reactWindowRoot?: Root;
    private extWindowRoot?: Root;
    private systemNotificationRoot?: Root;
    private systemModalRoot?: Root;
    private sessionModalRoot?: Root;

    private adHocRoots: Record<string, Root> = {};

    registerAdHocRoot = (newRoot: Root, id: string) => {
        this.adHocRoots[id] = newRoot;
    };
    unmountAdHocRoot = (id: string) => {
        if (!this.adHocRoots[id]) {
            return;
        }
        this.adHocRoots[id].unmount();
        delete this.adHocRoots[id];
    };
    getAdHocRoot = (id: string): Root | undefined => {
        logRootIfUndefined(`adHoc root with id:  ${id}`, this.adHocRoots[id]);
        if (typeof this.adHocRoots[id] === 'undefined') {
            console.error('Count not find adHoc root with id', id);
        }
        return this.adHocRoots[id];
    };

    getHeaderRoot = () => {
        logRootIfUndefined('headerRoot', this.headerRoot);
        return this.headerRoot;
    };
    getAppRoot = () => {
        logRootIfUndefined('appRoot', this.appRoot);
        return this.appRoot;
    };
    getSlideoverRoot = () => {
        logRootIfUndefined('slideoverRoot', this.slideoverRoot);
        return this.slideoverRoot;
    };
    getFocusTrappingModalRoot = () => {
        logRootIfUndefined(
            'focusTrappingModalRoot',
            this.focusTrappingModalRoot,
        );
        return this.focusTrappingModalRoot;
    };
    getReactWindowRoot = () => {
        logRootIfUndefined('reactWindowRoot', this.reactWindowRoot);
        return this.reactWindowRoot;
    };
    getExtWindowRoot = () => {
        logRootIfUndefined('extWindowRoot', this.extWindowRoot);
        return this.extWindowRoot;
    };
    getSystemNotificationRoot = () => {
        logRootIfUndefined(
            'systemNotificationRoot',
            this.systemNotificationRoot,
        );
        return this.systemNotificationRoot;
    };
    getSystemModalRoot = () => {
        logRootIfUndefined('systemModalRoot', this.systemModalRoot);
        return this.systemModalRoot;
    };
    getSimpleTooltipRoot = () => {
        logRootIfUndefined('simpleTooltipRoot', this.simpleTooltipRoot);
        return this.simpleTooltipRoot;
    };
    getSessionModalRoot = () => {
        logRootIfUndefined('sessionModalRoot', this.sessionModalRoot);
        return this.sessionModalRoot;
    };

    setHeaderRootContainer = (container: HTMLElement) => {
        this.headerRoot = createRoot(container);
    };
    setAppRootContainer = (container: HTMLElement) => {
        this.appRoot = createRoot(container);
    };
    setSlideoverRootContainer = (container: HTMLElement) => {
        this.slideoverRoot = createRoot(container);
    };
    setFocusTrappingModalRootContainer = (container: HTMLElement) => {
        this.focusTrappingModalRoot = createRoot(container);
    };
    setReactWindowRootContainer = (container: HTMLElement) => {
        this.reactWindowRoot = createRoot(container);
    };
    setExtWindowRootContainer = (container: HTMLElement) => {
        this.extWindowRoot = createRoot(container);
    };
    setSystemNotificationRootContainer = (container: HTMLElement) => {
        this.systemNotificationRoot = createRoot(container);
    };
    setSystemModalRootContainer = (container: HTMLElement) => {
        this.systemModalRoot = createRoot(container);
    };
    setSessionModalRootContainer = (container: HTMLElement) => {
        this.sessionModalRoot = createRoot(container);
    };
    setSimpleTooltipRootContainer = (container: HTMLElement) => {
        this.simpleTooltipRoot = createRoot(container);
    };
}

let renderTargetsInstance: RenderTargets | undefined;

export const getRenderTargetsService = () => {
    if (!renderTargetsInstance) {
        renderTargetsInstance = new RenderTargets();
    }

    return renderTargetsInstance;
};
