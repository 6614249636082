import {BarDatum} from '@nivo/bar';

export interface ChartData {
    data: BarDatum[];
    annotation: any;
    query: {
        measures: string[];
        dimensions: string[];
    };
}

export const formatChartDataRequest = (inputData: any): ChartData => {
    const chartDataRequest = {
        data: inputData.data,
        annotation: inputData.annotation,
        query: {
            measures: Object.keys(inputData.annotation.measures),
            dimensions: [
                ...Object.keys(inputData.annotation.dimensions ?? {}),
                ...Object.keys(inputData.annotation.timeDimensions ?? {}),
            ],
        },
    };
    return chartDataRequest;
};
