import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {LoadPageButton} from 'Components/button/loadPageButton';
import {selectSelectedIds, selectAllItems} from 'State/selectors/Basket';

import BasketCalculator from './BasketCalculator';
import BasketProperty from './BasketProperty';

class BasketProperties extends Component {
    render() {
        const {checkoutUrl, selectedIds, allItems, currencyCode} = this.props;

        return (
            <div className="basket-properties">
                {selectedIds.map((selectedId) => (
                    <BasketProperty
                        key={selectedId}
                        {...allItems[selectedId]}
                    />
                ))}
                <BasketCalculator currencyCode={currencyCode} />
                <div className="btn">
                    <LoadPageButton
                        text="Checkout"
                        color="green"
                        pageUrl={checkoutUrl}
                        jsonData={{
                            fields: {
                                selectedIds: {value: selectedIds},
                                isBundle: {
                                    value: Mis.application.Basket
                                        .bundlePurchase,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        );
    }
}

BasketProperties.propTypes = {
    checkoutUrl: PropTypes.string,
    selectedIds: PropTypes.array,
    allItems: PropTypes.object,
    currencyCode: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        selectedIds: selectSelectedIds(state),
        allItems: selectAllItems(state),
    };
};

export default connect(mapStateToProps)(BasketProperties);
