import PropTypes from 'prop-types';

export const FILTER_TAG_COLOR_ENUM = {
    BLUE: 'blue',
    PINK: 'pink',
};

export const filterTagColorsPropType = PropTypes.oneOf([
    FILTER_TAG_COLOR_ENUM.BLUE,
    FILTER_TAG_COLOR_ENUM.PINK,
]);
