import {ComboboxValue} from 'Components/combobox';
import {httpPost} from 'Interfaces/httpClient';
import {appendFormatJson} from 'Interfaces/Url';
import {GenericResponse} from 'Types/response';

export interface ISendEmergencyAlertParams {
    location: ComboboxValue;
    event: ComboboxValue;
    alertType?: ComboboxValue;
    students?: ComboboxValue[];
    comment?: string;
}

export const sendEmergencyAlert = async (params: ISendEmergencyAlertParams) => {
    const {
        location: alertLocation,
        event: alertEvent,
        alertType = '',
        students = [],
        comment = '',
    } = params;

    return httpPost<GenericResponse>(
        appendFormatJson('/emergency-alert/send-alert'),
        {
            fields: {
                location: {value: alertLocation},
                event: {value: alertEvent},
                alertType: {value: alertType},
                students: {value: students},
                comment: {value: comment},
            },
        },
    );
};
