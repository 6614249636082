import {useDispatch, useSelector} from 'react-redux';

import {
    selectIsBurgerMenuVisible,
    toggleBurgerMenuVisibilityAction,
} from 'State/burgerMenu';

import {SideBarButton} from '../sideBarButton';
import {SideBarButtonProps} from '../sideBarButton/SideBarButton';

import './burgerMenuButton.scss';

export const focusBurgerMenuButton = () => {
    const burgerMenuButton = document.querySelector(
        '.burger-menu-button__wrapper',
    );
    if (burgerMenuButton) {
        (burgerMenuButton as HTMLButtonElement)?.focus();
    }
};

const BurgerMenuIcon = () => (
    <svg width="24" height="18" fill="none" viewBox="0 0 24 18">
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M1 3c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h22c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H1zM0 9.5c0 .552.448 1 1 1h22c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1H1c-.552 0-1 .448-1 1v1zM0 17c0 .552.448 1 1 1h22c.552 0 1-.448 1-1v-1c0-.552-.448-1-1-1H1c-.552 0-1 .448-1 1v1z"
            clipRule="evenodd"
        />
    </svg>
);

export const BurgerMenuButton = ({...others}: SideBarButtonProps) => {
    const dispatch = useDispatch();
    const isBurgerMenuVisible = useSelector(selectIsBurgerMenuVisible);

    return (
        <SideBarButton
            buttonText="Menu"
            tooltip="Navigation, Help and More"
            buttonWrapperClassName="burger-menu-button__wrapper"
            aria-label={
                isBurgerMenuVisible
                    ? 'Hide main navigation'
                    : 'Show main navigation'
            }
            isActive={isBurgerMenuVisible}
            data-testid="burger-menu-button"
            onClick={() => dispatch(toggleBurgerMenuVisibilityAction())}
            {...others}
        >
            <BurgerMenuIcon />
        </SideBarButton>
    );
};
