/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 3/1/18
 * Time: 11:01
 */
import {ExtPartialComponent} from 'Renderer/Renderer';
import Loader from 'Services/Loader';

import exportInterface from './Base';

export function downloadFile(
    url: string,
    postData?,
    successCallback?,
    errorCallback?,
) {
    Mis.application.Loader.downloadFile(
        url,
        postData,
        successCallback,
        errorCallback,
    );
}

// params = {
//     event,
//     reload,
//     scope,
//     jsonData,
//     elementToRefocus,
// }
export function loadPage(url, params?) {
    Loader.loadPage(url, params);
}

export function loadCurrentPage(reload?) {
    Loader.loadCurrentPage(reload);
    // Mis.application.Loader.loadCurrentPage(reload);
}

export function loadPartial(
    url: string,
    partial: ExtPartialComponent,
    callback: () => void,
) {
    Loader.loadPartial(url, partial, callback);
}

export function unmountPartialById(id: string) {
    Loader.unmountPartialById(id);
}

export const registerLoaderInterface = () => {
    exportInterface('Loader', {
        downloadFile,
        loadPage,
        loadCurrentPage,
        loadPartial,
        unmountPartialById,
    });
};
