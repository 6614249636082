import {httpGet} from 'Interfaces/httpClient';
import url from 'Services/url/Url';

import {ALL_SCHOOLS, MY_SCHOOLS} from '../filterModes';

const normalizeFilterReducer = (acc, curr) => {
    acc[curr] = true;
    return acc;
};

export const normalizeFilterSettingsResponse = (response) => {
    const {
        numberOfSchools,
        selectedFilterMode,
        mySchools = {
            selectedSchools: [],
            selectedClusters: [],
        },
        allSchools = {
            selectedSchools: [],
            selectedClusters: [],
        },
    } = response;

    const normalizedNumberOfSchools =
        typeof numberOfSchools === 'number' && numberOfSchools >= 0
            ? numberOfSchools
            : 0;

    const normalizedSelectedFilterMode =
        selectedFilterMode === ALL_SCHOOLS || selectedFilterMode === MY_SCHOOLS
            ? selectedFilterMode
            : ALL_SCHOOLS;

    const normalizedMySchools = {
        selectedSchools: mySchools.selectedSchools.reduce(
            normalizeFilterReducer,
            {},
        ),
        selectedClusters: mySchools.selectedClusters.reduce(
            normalizeFilterReducer,
            {},
        ),
    };

    const normalizedAllSchools = {
        selectedSchools: allSchools.selectedSchools.reduce(
            normalizeFilterReducer,
            {},
        ),
        selectedClusters: allSchools.selectedClusters.reduce(
            normalizeFilterReducer,
            {},
        ),
    };

    return {
        numberOfSchools: normalizedNumberOfSchools,
        selectedFilterMode: normalizedSelectedFilterMode,
        mySchools: normalizedMySchools,
        allSchools: normalizedAllSchools,
    };
};

export const getFilterSettingsService = async () => {
    const response = await httpGet(
        url.GROUP_GLOBAL_FILTERS.GET_FILTER_SETTINGS,
    );

    return normalizeFilterSettingsResponse(response);
};
