import {takeLatest, debounce, put, select} from 'redux-saga/effects';

import {
    urlGenerator,
    getSanitizedNetworkSettings,
} from 'BackendRenderedComponents/dashboard/utils';
import {httpGet} from 'Interfaces/httpClient';
import url from 'Services/url/Url';
import {sanitizeSchoolNotices} from 'Utils/sanitizeHtml';

import {
    saveFiltersRequest,
    saveFiltersSuccess,
    saveFiltersFailure,
    fetchKpiDataRequest,
    fetchKpiDataSuccess,
    fetchKpiDataCached,
    fetchKpiDataFailure,
    getToDosSuccess,
    getToDosFailure,
    getSchoolNoticesSuccess,
    getSchoolNoticesFailure,
    getStudentAlertsSuccess,
    getStudentAlertsFailure,
    dismissStudentAlertSuccess,
    dismissStudentAlertFailure,
} from './actions';
import {
    SAVE_FILTERS_REQUEST,
    TOGGLE_FILTER_BY_ID,
    SET_SELECTED_TAB_BY_ID,
    FETCH_KPI_DATA_REQUEST,
    GET_TO_DOS_REQUEST,
    GET_SCHOOL_NOTICES_REQUEST,
    GET_STUDENT_ALERTS_REQUEST,
    DISMISS_STUDENT_ALERT_REQUEST,
} from './constants';
import {
    selectKpiFilters,
    selectKpiData,
    selectIsMyStudentsTabSelected,
    selectIsWholeSchoolTabSelected,
} from './selectors';
import {TAB_IDS} from './types';

const networkingSettings = getSanitizedNetworkSettings();

function* saveFilters() {
    try {
        const {demographics, kpiGroups, studentGroups, tabs} = yield select(
            selectKpiFilters,
        );

        const saveFilterUrl = `${
            url.DASHBOARD.SAVE_FILTERS
        }/student-groups/${urlGenerator(
            studentGroups,
        )}/demographics/${urlGenerator(demographics)}/kpi-groups/${urlGenerator(
            kpiGroups,
        )}/tabs/${urlGenerator(tabs)}`;
        yield httpGet(saveFilterUrl);

        yield put(saveFiltersSuccess());
    } catch (e) {
        yield put(saveFiltersFailure(e));
    }
}

function* fetchKpiData(action) {
    const {tabId} = action;
    try {
        const {demographics, kpiGroups, studentGroups} = yield select(
            selectKpiFilters,
        );

        const kpiDataUrl =
            tabId === TAB_IDS.MY_STUDENTS
                ? `${
                      url.DASHBOARD.GET_MY_STUDENTS_KPI_DATA
                  }/kpi-groups/${urlGenerator(
                      kpiGroups,
                  )}/student-groups/${urlGenerator(
                      studentGroups,
                  )}/demographics/${urlGenerator(demographics)}`
                : `${
                      url.DASHBOARD.GET_WHOLE_SCHOOL_KPI_DATA
                  }/kpi-groups/${urlGenerator(kpiGroups)}`;

        const kpiData = yield select(selectKpiData, tabId);

        if (
            kpiData.cache[kpiDataUrl] &&
            Date.now() - kpiData.cache[kpiDataUrl].lastRequestTime <
                networkingSettings.kpiCacheLifeTime
        ) {
            yield put(fetchKpiDataCached({tabId, url: kpiDataUrl}));
        } else {
            const {numberOfStudents, kpiGroups: kpiGroupsData} = yield httpGet(
                kpiDataUrl,
            );
            yield put(
                fetchKpiDataSuccess({
                    tabId,
                    numberOfStudents,
                    kpiGroups: kpiGroupsData,
                    url: kpiDataUrl,
                }),
            );
        }
    } catch (error) {
        yield put(fetchKpiDataFailure({tabId, error}));
    }
}

function* getToDos({limit}) {
    try {
        const {total, toDos} = yield httpGet(
            `${url.DASHBOARD.GET_TO_DOS}/limit/${limit}`,
        );

        yield put(getToDosSuccess({total: total || 0, toDos}));
    } catch (e) {
        yield put(getToDosFailure(e));
    }
}

function* getSchoolNotices({limit}) {
    try {
        const {total, schoolNotices: dirtySchoolNotices} = yield httpGet(
            `${url.DASHBOARD.GET_SCHOOL_NOTICES}/limit/${limit}`,
        );

        const cleanSchoolNotices = dirtySchoolNotices.map(
            ({title, ...rest}) => ({
                title: sanitizeSchoolNotices(title),
                ...rest,
            }),
        );

        yield put(
            getSchoolNoticesSuccess({
                total: total || 0,
                schoolNotices: cleanSchoolNotices,
            }),
        );
    } catch (e) {
        yield put(getSchoolNoticesFailure(e));
    }
}

function* getStudentAlerts({limit}) {
    try {
        const {studentAlerts, total} = yield httpGet(
            `${url.DASHBOARD.GET_STUDENT_ALERTS}/limit/${limit}`,
        );

        yield put(getStudentAlertsSuccess({total: total || 0, studentAlerts}));
    } catch (e) {
        yield put(getStudentAlertsFailure(e));
    }
}

function* dismissStudentAlert({id, limit}) {
    try {
        const {studentAlerts, total} = yield httpGet(
            `${url.DASHBOARD.DISMISS_STUDENT_ALERT}/student-alert-id/${id}/limit/${limit}`,
        );

        yield put(
            dismissStudentAlertSuccess({total: total || 0, studentAlerts}),
        );
    } catch (e) {
        yield put(dismissStudentAlertFailure(e));
    }
}

function* dispatchSaveFiltersAction() {
    // We let the toggleFilterById and setSelectedTabById directly update the store,
    // and then trigger the saveFilters action to save the data to the backend.
    // The data in the backend is only used for persisting the user settings, and so
    // only the final request matters, so we debounce these requests to limit the ammount
    // of network traffic.
    yield put(saveFiltersRequest());
}

function* fetchKpiDataForCurrentTab() {
    const isMyStudentsTabSelected = yield select(selectIsMyStudentsTabSelected);
    const isWholeSchoolTabSelected = yield select(
        selectIsWholeSchoolTabSelected,
    );
    if (isMyStudentsTabSelected) {
        yield put(fetchKpiDataRequest({tabId: TAB_IDS.MY_STUDENTS}));
    } else if (isWholeSchoolTabSelected) {
        yield put(fetchKpiDataRequest({tabId: TAB_IDS.WHOLE_SCHOOL}));
    }
}

export function* getDashboardSaga() {
    yield debounce(
        networkingSettings.kpiSettingsDebounceTime,
        [TOGGLE_FILTER_BY_ID, SET_SELECTED_TAB_BY_ID],
        dispatchSaveFiltersAction,
    );

    yield debounce(
        networkingSettings.kpiDataDebounceTime,
        TOGGLE_FILTER_BY_ID,
        fetchKpiDataForCurrentTab,
    );

    yield takeLatest(SAVE_FILTERS_REQUEST, saveFilters);
    yield takeLatest(FETCH_KPI_DATA_REQUEST, fetchKpiData);

    yield takeLatest(GET_TO_DOS_REQUEST, getToDos);
    yield takeLatest(GET_SCHOOL_NOTICES_REQUEST, getSchoolNotices);
    yield takeLatest(GET_STUDENT_ALERTS_REQUEST, getStudentAlerts);

    // Perhaps this one should be takeEvery
    yield takeLatest(DISMISS_STUDENT_ALERT_REQUEST, dismissStudentAlert);
}
