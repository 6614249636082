import {FormActionValidatorsData} from './Renderer';

/**
 * The backend has a mechanism for automatically adding validation to form fields,
 * based on their PHP form class. The validation info gets passed as a prop to the
 * Column/Slideover/Window, and so we need to match it up with the form fields.
 * It's best if we do this before even rendering the components so that the components
 * don't have to know that this happens.
 */
export const findMatchingRequiredValidator = (
    actionMappings: Record<string, string>,
    parentFormActions: FormActionValidatorsData,
) => {
    const shouldBeRequired = Object.keys(actionMappings).find((key) => {
        const formFieldName = actionMappings[key];
        const match = Object.keys(parentFormActions).find((innerKey) => {
            return innerKey === key;
        });
        if (!match) {
            return false;
        }
        const formFieldsInfo = parentFormActions[match];
        if (Array.isArray(formFieldsInfo)) {
            // formFieldsInto has a weird type of [] | Record<string, {}>, as
            // the backend returns an empty array if there are no validators
            return false;
        }
        const validators = formFieldsInfo?.[formFieldName];
        return (
            validators &&
            validators.find((validator) => validator.type === 'required')
        );
    });
    return !!shouldBeRequired;
};
