function selectBasket(state) {
    return state.basket;
}

function selectInsightBasketPrice(state) {
    let basket = selectBasket(state);
    return basket.insightBasketPrice;
}

function selectAllItems(state) {
    let basket = selectBasket(state);
    return basket.allItems;
}

function selectItemById(state, id) {
    let allItems = selectAllItems(state);
    if (allItems.hasOwnProperty(id)) {
        return allItems[id];
    }
    return {};
}

function selectIsItemSelected(state, id) {
    let item = selectItemById(state, id);
    return item.selected;
}

function selectPrice(state) {
    let basket = selectBasket(state);
    return basket.calculator;
}

function selectSelectedIds(state) {
    let selectedIds = [];
    let allItems = selectAllItems(state);
    Object.keys(allItems).forEach((key) => {
        if (allItems[key].selected) {
            let noParentBundleSelected = true;
            if (allItems[key].hasOwnProperty('parentBundles')) {
                allItems[key].parentBundles.forEach((parentBundleId) => {
                    if (
                        allItems.hasOwnProperty(parentBundleId) &&
                        allItems[parentBundleId].selected
                    ) {
                        noParentBundleSelected = false;
                    }
                });
            }
            if (noParentBundleSelected) {
                selectedIds.push(key);
            }
        }
    });
    return selectedIds;
}

function selectIsParentBundleAdded(state, id) {
    let allItems = selectAllItems(state);
    if (!allItems.hasOwnProperty(id) || !allItems[id].parentBundles) {
        return false;
    }

    let hasParentBundleSelected = false;
    allItems[id].parentBundles.forEach((parentBundleId) => {
        if (
            allItems.hasOwnProperty(parentBundleId) &&
            allItems[parentBundleId].selected
        ) {
            hasParentBundleSelected = true;
        }
    });
    return hasParentBundleSelected;
}

export {
    selectAllItems,
    selectItemById,
    selectIsItemSelected,
    selectSelectedIds,
    selectPrice,
    selectIsParentBundleAdded,
    selectInsightBasketPrice,
};
