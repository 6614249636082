import {useRef, useState} from 'react';

import {HtmlEditor as BaseEditor} from 'Components/htmlEditor';
import {HtmlEditorProps} from 'Components/htmlEditor/HtmlEditor';
import {
    extractCommonFormFieldParams,
    useFormFieldRegistry,
    useRefetchFormValue,
} from 'Interfaces/formFields';
import {SISFormFieldCommonProps} from 'Services/formField/FormField';

export const HtmlEditor = (
    props: HtmlEditorProps & SISFormFieldCommonProps,
) => {
    const {value: initialValue, url} = props;

    const [value, setValue] = useState(initialValue ?? '');
    const currentValueRef = useRef(value);
    const [errors, setErrors] = useState<string[]>([]);

    const {isLoading, newFormValue, refetchFormValue} =
        useRefetchFormValue(url);

    const {updateFormRegistryValue} = useFormFieldRegistry({
        ...extractCommonFormFieldParams(props),
        getValue() {
            return currentValueRef.current;
        },
        getSubmitValue() {
            return currentValueRef.current;
        },

        markInvalid(errorMessages) {
            if (Array.isArray(errorMessages)) {
                setErrors(errorMessages);
            } else {
                setErrors([errorMessages]);
            }
        },

        fetchNewData: refetchFormValue,
        setValue: (newValue) => {
            if (typeof newValue !== 'string') {
                console.error(
                    'Non string value in HtmlEditor useFormFieldRegistry->setvalue',
                    newValue,
                );
                return;
            }
            setValue(newValue);
        },
    });

    return (
        <BaseEditor
            {...props}
            isLoading={isLoading}
            value={
                typeof newFormValue === 'string' ? newFormValue : props.value
            }
            onChange={(newVal) => {
                setValue(newVal);
                setErrors([]);
                currentValueRef.current = newVal;
                updateFormRegistryValue(newVal);
            }}
            errors={errors}
        />
    );
};
