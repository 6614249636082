import {useRef} from 'react';

import {useComponentDidMount} from './useComponentDidMount';

export const useIsFirstRender = () => {
    const firstRenderRef = useRef(true);
    useComponentDidMount(() => {
        firstRenderRef.current = false;
    });

    return firstRenderRef.current;
};
