export const removeUrlParams = (
    currentUrl: string,
    paramNamesToRemove: string[],
) => {
    let newUrl = currentUrl;

    paramNamesToRemove.forEach((name) => {
        const re = new RegExp(`\/${name}\/[^\/]*`, 'g');
        newUrl = newUrl.replace(re, '');
    });

    return newUrl;
};
