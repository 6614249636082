/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 9/7/18
 * Time: 15:04
 */

import {AxiosResponse} from 'axios';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';

import ExternalConfig from 'Config/External';
import Text from 'Config/Text';
import {raiseDevError, raiseError} from 'Interfaces/error/Error';
import Url from 'Services/url/Url';

import {detectPageRedirect} from './detectPageRedirect';

export type ResponseData = {
    success?: boolean;
    message?: string;
    action_params?: unknown;
    redirectUrl?: string;
    type?: string;
};

export default function interceptResponse(response: AxiosResponse<unknown>) {
    let jsonResponse: ResponseData | undefined;

    // Different URLs coming from either Axios http request or Ext http request
    const responseUrl = response.request.responseURL || response.request.url;

    //Blank response
    if (!response.data) {
        if (ExternalConfig.getConfig().debugEnvironment) {
            raiseDevError({title: 'Blank response'});
        } else {
            raiseError(
                Text.ERROR.GENERIC_TITLE,
                Text.ERROR.GENERIC_MESSAGE,
                'Blank response',
            );
        }
        return Promise.reject(response.data);
    }
    if (isObject(response.data)) {
        jsonResponse = response.data;
    } else {
        //HTML or String response
        if (typeof response.data === 'string') {
            const stringData: string = response.data;
            try {
                jsonResponse = JSON.parse(stringData);
            } catch (e) {
                // A (HTML) string will fall into this catch
                // An example of this is remote tooltip data
                return stringData;
            }
        }
    }

    const responseURL = response?.request?.responseURL;
    const redirectUrl = detectPageRedirect({
        responseURL,
        jsonResponse,
        response,
    });
    if (redirectUrl !== null && jsonResponse) {
        jsonResponse.redirectUrl = redirectUrl;
    }

    if (jsonResponse && 'success' in jsonResponse && !jsonResponse.success) {
        //TODO REFACTOR - Special case for login screen
        if (
            responseUrl &&
            typeof responseUrl === 'string' &&
            responseUrl.indexOf(Url.AUTH.LOGIN) > -1
        ) {
            return Promise.reject(response.data);
        }

        if (
            !jsonResponse.action_params ||
            isEmpty(jsonResponse.action_params)
        ) {
            if (ExternalConfig.getConfig().debugEnvironment) {
                raiseDevError({
                    response: {
                        ...jsonResponse,
                        message: jsonResponse.message ?? '',
                    },
                });
            } else {
                raiseError(
                    Text.ERROR.GENERIC_TITLE,
                    Text.ERROR.GENERIC_MESSAGE,
                );
            }
            return Promise.reject(response.data);
        }
    }

    return response.data;
}
