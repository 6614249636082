import classnames from 'classnames';
import * as React from 'react';

import {Icon} from 'Components/icon';

import {ClearButton} from './ClearButton';

import './searchField.scss';

interface ISearchFieldProps
    extends Omit<React.HTMLProps<HTMLInputElement>, 'className'> {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    inputClassName?: string;
    containerClassName?: string;
    value?: string;
}

type ClearButtonProps =
    | {
          enableClearButton?: false | undefined;
          onValueClear?: never;
      }
    | {
          enableClearButton: true;
          onValueClear: () => void;
          value: string;
      };

export const SearchField = ({
    onChange,
    placeholder,
    inputClassName,
    containerClassName,
    value,
    onValueClear,
    enableClearButton,
}: ISearchFieldProps & ClearButtonProps) => {
    return (
        <label
            aria-label="Search this table"
            className={classnames('search-field__label', containerClassName)}
        >
            <input
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                className={classnames('search-field', inputClassName)}
                value={value}
            />
            <Icon
                className="search-field__search-icon"
                iconName="search"
                role="presentation"
            />
            {enableClearButton && value && (
                <ClearButton onClick={onValueClear} />
            )}
        </label>
    );
};
