export const SLIDEOVER = Object.freeze({
    ADD: 'slideover:add',
    REMOVE: 'slideover:remove',
    REMOVE_ALL: 'slideover:removeall',
    REMOVE_ALL_WITHOUT_ANIMATION: 'slideover:removeallwithoutanimation',
});

export const SYSTEM_NOTIFICATION = Object.freeze({
    ADD: 'systemnotification:add',
    REMOVE: 'systemnotification:remove',
});

export const SYSTEM_MODAL = Object.freeze({
    ADD: 'systemmodal:add',
    REMOVE: 'systemmodal:remove',
});

export const WINDOW_MANAGER = Object.freeze({
    SHOW: 'window:show',
    HIDE: 'window:hide',
    ADD: 'window:add',
    REMOVE: 'window:remove',
    REMOVE_ALL: 'window:removeall',
    REMOVE_ALL_AND_ADD: 'window:removeallandadd',
});

export const SECTION_SELECTOR = Object.freeze({
    RELOAD: 'sectionselector:reload',
});

export const TAB = Object.freeze({
    CHANGE: 'tab:change',
});

export const LOADER = Object.freeze({
    PAGE_LOAD: 'loader:pageload',
});
