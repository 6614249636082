import {getSessionModalRoot} from 'Interfaces/renderTargets';

import {
    SessionTimeoutModal,
    ISessionTimeoutModalProps,
} from './SessionTimeoutModal';

export const renderSessionTimeoutModal = (props: ISessionTimeoutModalProps) => {
    getSessionModalRoot()?.render(<SessionTimeoutModal {...props} />);
};
