import classnames from 'classnames';
import PropTypes from 'prop-types';

import './widgetZoneColumn.scss';

export function WidgetZoneColumn(props) {
    const {children, narrowWidth} = props;

    return (
        <section
            aria-label="Side column"
            className={classnames(
                'widget-zone-column-flex-wrapper',
                {'widget-zone-column-flex-wrapper__narrow': narrowWidth},
                'full-width-action-panels',
            )}
        >
            {children}
        </section>
    );
}

WidgetZoneColumn.propTypes = {
    children: PropTypes.node,
    narrowWidth: PropTypes.bool,
};
