import pubsub from 'Services/pubsub/Pubsub';

class History {
    constructor() {
        this.history = window.history;
        this.currentUrl = '';
        window.onpopstate = (event) => {
            pubsub.publish('historypop', event.state);
        };
    }

    _prepareState(state, title, url) {
        return Object.assign({}, state, {title, url});
    }

    pushState(state, title, url) {
        const data = this._prepareState(state, title, url);
        this.history.pushState(data, title, url);
    }

    replaceState(state, title, url) {
        const data = this._prepareState(state, title, url);
        this.history.replaceState(data, title, url);
    }

    getHistoryLength() {
        return this.history.length;
    }

    getState() {
        return this.history.state;
    }

    go(index) {
        this.history.go(index);
    }

    back() {
        this.history.back();
    }

    forward() {
        this.history.forward();
    }

    getCurrentUrl() {
        return this.currentUrl;
    }

    setCurrentUrl(url) {
        this.currentUrl = url;
    }

    clearCurrentUrl() {
        this.currentUrl = '';
    }
}

let historyServiceInstance = null;
export const getHistoryService = () => {
    if (!historyServiceInstance) {
        historyServiceInstance = new History();
    }
    return historyServiceInstance;
};
