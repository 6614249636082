import PropTypes from 'prop-types';

import {DropdownMenu} from 'Components/dropdown';

import './selectors.scss';

export const PageToggle = ({options, fieldLabel}) => {
    const selectedItem = options.find((item) => item.selected);

    return (
        <section
            className="selector__wrapper"
            aria-label={`Page Toggle: ${fieldLabel}`}
        >
            {fieldLabel && (
                <label className="selector__label">{fieldLabel}</label>
            )}
            <div className="selector__container">
                <DropdownMenu
                    label={selectedItem ? selectedItem.label : 'Select Page'}
                    items={options.map(({label: title, value: url}) => ({
                        title,
                        url,
                    }))}
                    color="light-grey"
                />
            </div>
        </section>
    );
};

PageToggle.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
            selected: PropTypes.bool,
        }),
    ),
    fieldLabel: PropTypes.string,
};
