import {ReactNode} from 'react';

import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import tooltipWrapper from 'Components/tooltip/TooltipWrapper';

import './kpiBoxLink.scss';

type KpiBoxLinkProps = {
    url?: string;
    children: ReactNode;
    title?: string;
    onClick?: () => void;
};

// Used to wrap KpiBox components to provide link and tooltip functionality
const UnwrappedKpiBoxLink = ({
    url,
    title,
    children,
    onClick,
}: KpiBoxLinkProps) => (
    <KeyboardFocusableLink
        linkWrapperClassName="kpi-box-link__wrapper"
        url={url}
        aria-label={title}
        borderRadius="0"
        insetFocusRing
        disableDefaultLinkStyles
        onClick={onClick}
    >
        {children}
    </KeyboardFocusableLink>
);

export const KpiBoxLink = tooltipWrapper(UnwrappedKpiBoxLink);
