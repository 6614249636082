import PropTypes from 'prop-types';
import {Component} from 'react';

// Deterministic method to generate unique IDs for each use case of this component
let extComponentRendererCounter = 0;

const getExtComponentRendererCounter = () => {
    extComponentRendererCounter += 1;
    return extComponentRendererCounter;
};

export class ExtComponentRenderer extends Component {
    constructor(props) {
        super(props);

        this.container = null;

        this.setContainerRef = (element) => {
            this.container = element;
        };

        this.containerId = `ExtComponentRenderer_container_${getExtComponentRendererCounter()}`;
    }
    createExtComponent = () => {
        const {componentName} = this.props;

        const ExtComponent = Ext.create(componentName, {
            renderTo: this.containerId,
            ...this.props,
        });

        this.componentId = ExtComponent.id;
    };
    componentDidMount() {
        if (document.getElementById(this.containerId)) {
            this.createExtComponent();
        } else {
            // if ExtComponentRenderer is inside an ExtLayoutRenderer, the contents of the
            // ExtLayoutRenderer will not be rendered yet, so need to wait for the next event cycle.

            // The only current known case of this is an Ext button inside a Banner, which can be
            // tested on /live-rulebook-ui/component-banners/
            setTimeout(() => {
                if (document.getElementById(this.containerId)) {
                    this.createExtComponent();
                } else {
                    console.error(
                        `ExtComponentRenderer could not render element ${this.props.componentName} as container with id "${this.containerId}" could not be found.`,
                    );
                }
            }, 0);
        }
    }
    componentWillUnmount() {
        const extComponent = Ext.getCmp(this.componentId);
        if (extComponent) {
            extComponent.destroy();
        }
    }

    render() {
        return <span id={this.containerId} />;
    }
}

ExtComponentRenderer.propTypes = {
    componentName: PropTypes.string,
};
