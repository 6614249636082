import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {loadPage} from 'Interfaces/Loader';

import type {ReactNode} from 'react';

import './DataControlPanel.scss';

type dataControlPanelProperties = {
    children: ReactNode;
    url: string;
    params: string;
};

export const DataControlPanel = (props: dataControlPanelProperties) => {
    const params = JSON.parse(props.params);
    const url = props.url;
    return (
        <KeyboardFocusableButton
            onClick={() => loadPage(url, {jsonData: params})}
            buttonWrapperClassName="data-control-panel"
            aria-label="Adjust page filters"
            borderRadius="4px"
        >
            {props.children}
        </KeyboardFocusableButton>
    );
};
