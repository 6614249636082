import PropTypes from 'prop-types';
import {Component} from 'react';

import {Button} from 'Components/button/base';
import {FocusTrappingModal} from 'Components/focusTrappingModal';

import {FormContainer, TextField, validationRules} from '../form';

import {sendResetPasswordEmail} from './sendResetPasswordEmail';

import './emailFormModals.scss';

const ModalLayout = ({children, title, footer}) => (
    <div className="email-form-modal-layout-wrapper">
        <h2 className="email-form-modal-title">{title}</h2>
        <div className="email-form-modal-contents">{children}</div>
        <div className="email-form-modal-footer-container">{footer}</div>
    </div>
);

ModalLayout.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    footer: PropTypes.node,
};

export class EmailFormModals extends Component {
    // This is a multi-step modal.
    // - In the first step a form is shown where the user inputs an email address.
    // - In the second step a success message is shown
    //
    // Note: we use a seperate modal for each step to ensure focus is not lost.
    // Modals automatically focus the first focusable element, and trap focus.
    constructor() {
        super();
        this.state = {
            hasFormBeenCompleted: false,
            successEmail: '',
        };
    }

    handleValidSubmit = async (formState) => {
        await sendResetPasswordEmail(formState.email);
        this.setState({
            hasFormBeenCompleted: true,
            successEmail: formState.email,
        });
    };

    renderEmailForm() {
        const {onClose, formTitle, formDescription, submitButtonText} =
            this.props;
        return (
            <FocusTrappingModal
                onClose={onClose}
                key="login-modal-form"
                title={formTitle}
            >
                <FormContainer
                    aria-label={formTitle}
                    dataTestId="login-modal-form"
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={{
                        email: validationRules.email,
                    }}
                    onValidSubmit={this.handleValidSubmit}
                    renderContents={({
                        hasAttemptedFormSubmit,
                        formState,
                        formErrors,
                        onFormFieldChange,
                    }) => (
                        <ModalLayout
                            footer={
                                <Button
                                    text={submitButtonText}
                                    color="green"
                                    type="submit"
                                />
                            }
                        >
                            <div className="email-form-modal-description-wrapper">
                                {formDescription}
                            </div>
                            <div className="email-form-modal-field-wrapper">
                                <TextField
                                    placeholder="Enter email address"
                                    value={formState.email}
                                    onChange={onFormFieldChange('email')}
                                    errorText={formErrors.email}
                                    showErrorText={hasAttemptedFormSubmit}
                                    aria-label="Enter email address"
                                />
                            </div>
                        </ModalLayout>
                    )}
                />
            </FocusTrappingModal>
        );
    }

    renderSuccessPanel() {
        const {onClose, renderSuccessMessage} = this.props;
        const {successEmail} = this.state;
        return (
            <FocusTrappingModal
                onClose={onClose}
                key="login-modal-success"
                title="Check your emails"
            >
                <ModalLayout
                    footer={
                        <Button
                            text="Return to log in"
                            onClick={onClose}
                            color="green"
                        />
                    }
                >
                    <div className="email-form-modal-success-description-wrapper">
                        {renderSuccessMessage(successEmail)}
                    </div>
                </ModalLayout>
            </FocusTrappingModal>
        );
    }

    render() {
        const {hasFormBeenCompleted} = this.state;

        return hasFormBeenCompleted
            ? this.renderSuccessPanel()
            : this.renderEmailForm();
    }
}

EmailFormModals.propTypes = {
    onClose: PropTypes.func,
    formTitle: PropTypes.string,
    submitButtonText: PropTypes.string,
    formDescription: PropTypes.node,
    renderSuccessMessage: PropTypes.func,
};
