import {AxiosRequestConfig} from 'axios';

import {getHttpClientService} from 'Services/httpClient/HttpClient';

export const httpGet = <T = any, R = T, D = any>(
    path: string,
    params?: any,
    signal?: AbortSignal,
) => {
    return getHttpClientService().get<T, R, D>(path, params, signal);
};

export const httpPost = <T = any, R = T, D = any>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig,
) => {
    return getHttpClientService().post<T, R, D>(path, data, config);
};
