import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';

import {Button} from 'Components/button/base';
import {getCurrentUrl} from 'Interfaces/history/History';
import Loader from 'Services/Loader';
import {selectItem} from 'State/actionCreators/Basket';
import {selectIsItemSelected, selectSelectedIds} from 'State/selectors/Basket';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';

import './checkoutUpgradeButton.scss';

const WrappedButton = buttonWrapper(Button);

// Never used
export class UnconnectedCheckoutUpgradeButton extends Component {
    handleClick() {
        // do something on click
        this.props.onSelectItem(this.props.bundleId);
    }

    reloadPage() {
        const jsonData = {
            fields: {
                selectedIds: {
                    value: this.props.selectedIds,
                },
                isBundle: {
                    // #TODO Remove isBundle when it is removed on server side
                    value: true,
                },
            },
        };

        Loader.loadPage(getCurrentUrl() + '/reload/true', {jsonData});
    }

    constructor(props) {
        super(props);

        this.state = {
            upgradedToBundle: false,
            prevIsBundleSelected: props.isBundleSelected,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isBundleSelected !== prevState.prevIsBundleSelected) {
            return {
                upgradedToBundle: nextProps.isBundleSelected,
            };
        }
        return null;
    }

    componentDidUpdate() {
        if (this.state.upgradedToBundle) {
            this.reloadPage();
        }
    }

    render() {
        const {text} = this.props;

        const {upgradedToBundle} = this.state;

        return (
            <div className="upgrade-button-wrapper">
                <WrappedButton
                    color="green"
                    text={text}
                    disabled={upgradedToBundle}
                    onClick={this.handleClick.bind(this)}
                    small={false}
                />
            </div>
        );
    }
}

UnconnectedCheckoutUpgradeButton.propTypes = {
    text: PropTypes.string.isRequired,
    onSelectItem: PropTypes.func,
    selectedIds: PropTypes.array,
    bundleId: PropTypes.string,
    isBundleSelected: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        selectedIds: selectSelectedIds(state),
        isBundleSelected: selectIsItemSelected(state, props.bundleId),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectItem: (id) => {
            dispatch(selectItem(id));
        },
    };
};

export const CheckoutUpgradeButton = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {forwardRef: true},
)(UnconnectedCheckoutUpgradeButton);
