import {findQueryStringInUrl} from './findQueryStringInUrl';
import {sisModuleNames} from './sisModuleNames';
import {removeLeadingSlash, removeTrailingSlashes} from './slashHelpers';

// The SIS backend allows a format for url parameters by appending
// key value pairs to the url in the format /key/value
// The core part of the url has format [/module]?/controller/action
// Note: this logic is similar to that in `library/Ext/Table.php` L121
//
// SIS also supports regular query string parameters, i.e. ?key=value&key2=value2

export const splitSisUrlIntoSections = (sisUrl: string) => {
    let urlParamParts: string[] = [];
    let baseUrl: string | null = null;
    let module: string | null = null;
    let controller: string | null = null;
    let action: string | null = null;

    const query = findQueryStringInUrl(sisUrl);
    const urlWithNoQuery = sisUrl.replace(`?${query}`, '');

    const trimmedUrl = removeLeadingSlash(
        removeTrailingSlashes(urlWithNoQuery),
    );
    const urlParts = trimmedUrl.split('/');

    if (urlParts.length < 2) {
        baseUrl = sisUrl;
        controller = trimmedUrl;
        console.warn(
            'url with no action passed to splitSisUrlIntoSections',
            sisUrl,
        );
    } else if (sisModuleNames.includes(urlParts[0])) {
        if (urlParts.length === 2) {
            [module, controller] = urlParts;
            baseUrl = `/${module}/${controller}`;
            console.warn(
                'url with module but no action passed to splitSisUrlIntoSections',
                sisUrl,
            );
        } else {
            [module, controller, action, ...urlParamParts] = urlParts;
            baseUrl = `/${module}/${controller}/${action}`;
        }
    } else {
        [controller, action, ...urlParamParts] = urlParts;
        baseUrl = `/${controller}/${action}`;
    }
    return {
        query,
        baseUrl,
        urlParamParts,
        module,
        controller,
        action,
    };
};
