import {Button, IButtonProps} from 'Components/button/base';
import {loadPage} from 'Interfaces/Loader';
import buttonWrapper from 'Wrappers/ButtonWithExtModal';
const WrappedButton = buttonWrapper(Button);

type LinkButtonProps = IButtonProps & {
    pageUrl?: string;
};

export function LinkButton(props: LinkButtonProps) {
    const {pageUrl, ...rest} = props;

    const handleClick = () => {
        if (!pageUrl) {
            throw new Error('No url provided!');
        }

        loadPage(pageUrl);
    };

    return (
        <WrappedButton
            onClick={handleClick}
            className="arbor-link-button"
            {...rest}
        />
    );
}

LinkButton.defaultProps = {
    uiType: 'secondary',
    color: 'green',
};
