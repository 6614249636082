import {useEffect, useRef, useState} from 'react';

import {LoadingSpinner} from 'Components/loadingSpinner';
import {TAB} from 'Config/Events';
import {fastWeakEncode} from 'Interfaces/Hash';
import {loadPage} from 'Interfaces/Loader';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {usePubSub} from 'Services/pubsub';
import helper from 'Utils/helper';
import {relayoutExtContainer} from 'Utils/relayoutExtContainer';
import {useComponentDidMount} from 'Utils/useComponentDidMount';
import {useIsMounted} from 'Utils/useIsMounted';

import {callFunctionWithRetry} from './callFunctionWithRetry';
import {createChartWithChartingLibrary} from './createChartWithChartingLibrary';

import './chart.scss';

interface IChartProps {
    url?: string;
    type?: string;
    componentParams?: string;
    data?: string;
}

export const Chart = ({url, type, data, componentParams}: IChartProps) => {
    const _isMounted = useIsMounted();

    const ChartInstanceRef = useRef<any>();
    const ChartContainerRef = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const populateSvg = () => {
        ChartInstanceRef.current?.populateChart();
    };
    const chartID = `chart-container-id-${fastWeakEncode(url ?? '')}`;

    const initialiseChart = () => {
        const chartContainer = document.getElementById(chartID);
        const possibleHiddenTabParent = chartContainer?.closest(
            '.x-tabpanel-child[aria-hidden=true]',
        );
        if (possibleHiddenTabParent) {
            return;
        }
        ChartInstanceRef.current = createChartWithChartingLibrary({
            targetElemId: chartID,
            dataUrl: url,
            dataLocal: data,
            dataParams: componentParams,
            type: type,
            actionHandler: {
                clickHandler: function (clickUrl) {
                    loadPage(clickUrl);
                },
            },
            afterBuild: function () {
                populateSvg();
                relayoutExtContainer(ChartContainerRef.current);
                setIsLoading(false);
            },
            onError: function (errorMessage) {
                console.error('Error initialising chart: ', errorMessage);
                setIsLoading(false);
            },
        });
    };

    useComponentDidMount(() => {
        if (typeof url === 'undefined') {
            return;
        }
        setIsLoading(true);
        setError(null);
        callFunctionWithRetry(
            initialiseChart,
            () => !!document.getElementById(chartID),
            () => {
                if (_isMounted.current) {
                    reportToSisSentry(
                        "Could not find the element so can't render chart",
                    );
                    setError('Something went wrong displaying this chart');
                    setIsLoading(false);
                }
            },
        );
    });

    const handleResize = () => {
        if (!ChartInstanceRef.current) {
            return;
        }
        try {
            ChartInstanceRef.current.buildChart();
        } catch (e) {
            console.error('Error resizing chart', e);
            reportToSisSentry(e, 'Error resizing chart');
        }
    };

    usePubSub(TAB.CHANGE, () => {
        if (document.getElementById(chartID) && !ChartInstanceRef.current) {
            initialiseChart();
        }
    });

    useEffect(() => {
        setIsLoading(true);
        const debounceResize = helper.debounce(handleResize);
        window.addEventListener('resize', debounceResize);
        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    return (
        <section aria-label="Chart" role="presentation" ref={ChartContainerRef}>
            <div id={chartID}></div>

            {isLoading && (
                <div className="chart-content__loading-spinner-container">
                    <LoadingSpinner className="chart-content__loading-spinner" />
                </div>
            )}
            {error && (
                <div className="chart-content__error-message">{error}</div>
            )}
        </section>
    );
};
