import {useEffect, useState, useRef} from 'react';

import {Button} from 'Components/button/base';
import {FocusTrappingModalContent} from 'Components/focusTrappingModal';

import {logoutDelayTime} from '../sessionConstants';

import './sessionTimeoutModal.scss';

export interface ISessionTimeoutModalProps {
    refreshSession: () => void;
    onTimeRanOut: () => void;
}

export const SessionTimeoutModal = ({refreshSession, onTimeRanOut}) => {
    const [timeLeft, setTimeLeft] = useState(logoutDelayTime / 1000);
    const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        countdownIntervalRef.current = setInterval(function () {
            setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
        }, 1000);
        return () => {
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) {
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
            onTimeRanOut();
        }
        // we don't want this effect to be triggered by a different callback being passed in
        // as a prop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    return (
        <FocusTrappingModalContent
            onClose={refreshSession}
            title="Session Expired"
        >
            <div className="session-timeout-modal">
                <p>Ooops, you've not been using Arbor for a while.</p>
                <p>
                    We're going to log you out for security reasons in{' '}
                    <b>{timeLeft} seconds</b>.
                </p>
                <Button
                    color="green"
                    text="Don't log me out"
                    onClick={refreshSession}
                />
            </div>
        </FocusTrappingModalContent>
    );
};
