import {Component} from 'react';

import CheckoutWrapper from 'Components/checkout/sca/CheckoutWrapper';
import {APP_CONTAINER_ID, HEADER_CONTAINER_ID} from 'Constants/idStrings';

// Only used in old payment flow. Should be removed after MIS-53376
class CheckoutWrapperMobile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            banAmex: true,
            url: '',
            amount: 0,
            currency: '',
            fields: {},
        };

        // Used for transfering data from webView (ReactNatuve) to sis web app (React).
        // This function is called from webView once all payment data is set into window.sca.
        window.notifySis = () => {
            const {url, amount, currency, fields} = window.sca;
            this.setState({
                url,
                amount,
                currency,
                fields,
            });
        };

        document.getElementById(HEADER_CONTAINER_ID).style.display = 'none';

        const bodyWrapper = document.getElementById(APP_CONTAINER_ID);
        if (bodyWrapper) {
            bodyWrapper.style.height = '100%';
            bodyWrapper.style.top = '0px';
            bodyWrapper.style.overflow = 'hidden';

            document.addEventListener('scroll', () => {
                bodyWrapper.style.height = '100%';
                bodyWrapper.style.top = '0px';
            });
        }
    }

    handleModalClose() {
        if (!window.sca) {
            return;
        }

        const {isIOS, iosBuildNumber, version} = window.sca;

        // Since iOS has issues closing the webview on sdk 35,
        // make sure we are implementing this hack only for devices using sdk 35 and newer
        // and not mess up older version and android
        if (isIOS && ((version === 6 && iosBuildNumber >= 2) || version > 6)) {
            window.ReactNativeWebView.postMessage();
        } else {
            window.postMessage();
        }
    }

    render() {
        const {url, amount, currency, fields, banAmex} = this.state;

        const bodyWrapper = document.getElementById(APP_CONTAINER_ID);
        if (bodyWrapper) {
            bodyWrapper.style.height = '100%';
            bodyWrapper.style.top = '0px';
        }

        return (
            <div>
                <CheckoutWrapper
                    banAmex={banAmex}
                    onModalClose={this.handleModalClose}
                    url={url}
                    amount={amount}
                    currency={currency}
                    fields={fields}
                    isMobile={true}
                />
            </div>
        );
    }
}

export default CheckoutWrapperMobile;
