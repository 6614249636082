import {useRef} from 'react';

import {FormField} from 'Services/formField/FormField';
import {useComponentDidMount} from 'Utils/useComponentDidMount';

import {DEPENDEE_FIELD_NO_FORM_ACTION_NAME} from './constants';
import {
    deregisterFormField,
    registerFormField,
    updateFormFieldValue,
} from './FormFields';

/**
 * @param formField is the config for the form field to register
 */
export const useFormFieldRegistry = (formField: FormField) => {
    const {actionMappings, parentFilterPanelId, reference, componentId} =
        formField;
    const formFieldRegistryId = useRef<string | null>(null);
    const deregister = () => {
        if (formFieldRegistryId.current === null) {
            return;
        }
        deregisterFormField(formFieldRegistryId.current);
    };

    useComponentDidMount(() => {
        // Handling the case where actionMappings is not defined, as you can't use
        // hooks conditionally due to the "Rules of Hooks"
        if (!actionMappings) {
            if (parentFilterPanelId) {
                formFieldRegistryId.current = registerFormField(
                    [parentFilterPanelId],
                    {
                        ...formField,
                        isReactFormField: true,
                    },
                );
            } else if (reference) {
                formFieldRegistryId.current = registerFormField(
                    [`${DEPENDEE_FIELD_NO_FORM_ACTION_NAME}--${componentId}`],
                    {
                        ...formField,
                        isReactFormField: true,
                    },
                );
            } else {
                console.error(
                    'No actionMappings passed to form field',
                    formField,
                );
            }

            return () => {
                deregister();
            };
        }
        const actionNames = Object.keys(actionMappings);

        // registering/deregistering can be simplified by returning a deregister function to call, or a subscription object or something.
        // Then we don't have to worry about the parameters.
        // If we go with subscription object, then it can include the formfieldid, for easier value updating.
        formFieldRegistryId.current = registerFormField(actionNames, {
            ...formField,
            isReactFormField: true,
        });

        return () => {
            deregister();
        };
    });

    const updateFormRegistryValue = (newValue: unknown) => {
        if (formFieldRegistryId.current === null) {
            console.error(
                'No formFieldRegistryId in updateFormRegistryValue',
                formField,
            );
            return;
        }
        updateFormFieldValue(formFieldRegistryId.current, newValue);
    };
    return {
        updateFormRegistryValue,
    };
};
