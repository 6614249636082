import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Component} from 'react';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';
import {relayoutExtContainer} from 'Utils/relayoutExtContainer';

import './tabs.scss';

export class Tabs extends Component {
    constructor(props) {
        super(props);
        this.tabs = null;
        this.setTabsRef = (element) => {
            this.tabs = element;
        };
    }

    componentDidUpdate = (previousProps) => {
        if (this.props.selectedTabIndex !== previousProps.selectedTabIndex) {
            relayoutExtContainer(this.tabs);
        }
    };
    render() {
        const {tabs, selectedTabIndex, tabClickedHandler} = this.props;
        const currentTab = tabs && tabs[selectedTabIndex];
        return (
            <div ref={this.setTabsRef}>
                {tabs.length === 1 ? (
                    <div className="dashboard-tab-contents dashboard-tabs-single-tab-container">
                        {currentTab.contents}
                    </div>
                ) : (
                    <div className="dashboard-tabs-container">
                        <div className="dashboard-tabs-top-container">
                            <span className="dashboard-tabs-left-spacer" />
                            {tabs &&
                                tabs.map((tab, index) => (
                                    <KeyboardFocusableButton
                                        key={`tab-${index}-${tab.title}`}
                                        className={classnames('dashboard-tab', {
                                            'dashboard-tab__selected':
                                                index === selectedTabIndex,
                                        })}
                                        buttonWrapperClassName={classnames(
                                            'dashboard-tab-wrapper',
                                            {
                                                'dashboard-tab-wrapper__selected':
                                                    index === selectedTabIndex,
                                            },
                                        )}
                                        onClick={() => tabClickedHandler(index)}
                                    >
                                        {tab.title}
                                    </KeyboardFocusableButton>
                                ))}
                            <span className="dashboard-tabs-right-spacer" />
                        </div>
                        {currentTab && (
                            <div className="dashboard-tab-contents">
                                {currentTab.contents}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.exact({
            title: PropTypes.string,
            contents: PropTypes.node,
        }),
    ).isRequired,
    tabClickedHandler: PropTypes.func.isRequired,
    selectedTabIndex: PropTypes.number.isRequired,
};
