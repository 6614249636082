/**
 * Created by PhpStorm.
 * User: bogdanbegovic
 * Date: 6/7/18
 * Time: 14:54
 */

import Config from 'Config/External';

export function initRaven() {
    const isSentryEnabled = Config.getConfig().sentryJsEnabled;
    const feLibraryVersion = Config.getConfig().feVersion;
    const feSentryDSN = Config.getConfig().feSentryDSN;

    let ravenConfig = {};

    if (feLibraryVersion) {
        ravenConfig.release = feLibraryVersion;
    }

    if (isSentryEnabled && feSentryDSN) {
        Raven.config(feSentryDSN, ravenConfig).install();
    }
}

export function configureRaven(userData) {
    const isSentryEnabled = Config.getConfig().sentryJsEnabled;

    const {
        userId = 'N/A',
        display_name: displayName = 'N/A',
        username = 'N/A',
        applicationId = 'N/A',
        organizationName = 'N/A',
        user_type: userType = 'N/A',
    } = userData;

    if (isSentryEnabled) {
        Raven.setUserContext({
            id: userId,
            username: displayName,
            email: username,
        });

        Raven.setTagsContext({
            applicationId: applicationId,
            organizationName: organizationName,
            userType: userType,
        });
    }
}

export function reportToSisSentry(error, extra) {
    const isSentryEnabled = Config.getConfig().sentryJsEnabled;
    const feSentryDSN = Config.getConfig().feSentryDSN;
    const sisSentryDSN = Config.getConfig().sisSentryDSN;

    if (isSentryEnabled) {
        Raven.setDSN(sisSentryDSN);
        Raven.captureException(error, {
            extra: {message: extra},
        });
        Raven.setDSN(feSentryDSN);
    }
}
