import {getExtWindowRoot, getReactWindowRoot} from 'Interfaces/renderTargets';
import {renderWithStore} from 'Renderer/Store';

import {ExtWindowManager, ReactWindowManager} from './WindowManager';

import 'Interfaces/WindowManager';

export function renderWindowManager() {
    getReactWindowRoot()?.render(renderWithStore(<ReactWindowManager />));
    getExtWindowRoot()?.render(renderWithStore(<ExtWindowManager />));
}
