import classNames from 'classnames';

import {
    DropdownContentsEmptyText,
    IDropdownContentsRenderProps,
} from 'Components/dropdown';
import {Icon} from 'Components/icon';
import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import type {ReactNode} from 'react';

import './multiSelectDropdownContents.scss';

interface IMultiSelectDropdownItemProps {
    item: IDropdownItem;
    onChange: (item: IDropdownItem) => void;
    disabled?: boolean;
    narrowSpacing?: boolean;
}

const MultiSelectDropdownItem = ({
    onChange,
    item,
    disabled,
    narrowSpacing,
}: IMultiSelectDropdownItemProps) => (
    <KeyboardFocusableButton
        className={classNames('multi-select-dropdown-list-item', {
            'multi-select-dropdown-list-item--narrow-spacing': narrowSpacing,
        })}
        role="checkbox"
        aria-checked={item.selected}
        disabled={disabled}
        onClick={() => onChange(item)}
        tabIndex={-1}
        buttonWrapperClassName="multi-select-dropdown-list-item__wrapper"
    >
        {item.selected ? (
            <Icon
                iconName="checkbox-checked"
                className="multi-select-dropdown-list-item-checkbox multi-select-dropdown-list-item-checkbox__checked"
            />
        ) : (
            <span className="multi-select-dropdown-list-item-checkbox multi-select-dropdown-list-item-checkbox__unchecked" />
        )}
        {item.displayName}
    </KeyboardFocusableButton>
);

interface IDropdownItem {
    id: string;
    displayName: string | ReactNode;
    selected?: boolean;
}

interface IMultiSelectDropdownContentsProps
    extends IDropdownContentsRenderProps {
    items?: IDropdownItem[];
    onChange: (item: IDropdownItem) => void;
    maxSelected?: number;
    narrowSpacing?: boolean;
    dataTestId?: string;
}

export const getSelectedCount = (items: IDropdownItem[] | undefined) =>
    items?.reduce((acc, item) => (item.selected ? acc + 1 : acc), 0) || 0;

export const MultiSelectDropdownContents = ({
    items,
    onChange,
    maxSelected,
    narrowSpacing,
    dataTestId,
}: IMultiSelectDropdownContentsProps) => {
    const selectedCount = getSelectedCount(items);
    const disableUnselected =
        typeof maxSelected !== 'undefined' && selectedCount >= maxSelected;
    return (
        <div data-testid={dataTestId}>
            {items && items.length > 0 ? (
                <ul className="multi-select-dropdown-list">
                    {items.map((item) => (
                        <li key={`checkbox-${item.id}`}>
                            <MultiSelectDropdownItem
                                item={item}
                                onChange={onChange}
                                disabled={disableUnselected && !item.selected}
                                narrowSpacing={narrowSpacing}
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <DropdownContentsEmptyText>
                    None available
                </DropdownContentsEmptyText>
            )}
        </div>
    );
};
