import {Component} from 'react';

import CheckoutWrapper from 'Components/checkout/sca/CheckoutWrapper';
import {APP_CONTAINER_ID, HEADER_CONTAINER_ID} from 'Constants/idStrings';

function getUrlParameter(name) {
    // https://davidwalsh.name/query-string-javascript
    const escapedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + escapedName + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class CheckoutWrapperMobile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stripeIsLoaded: false,
            banAmex: true,
            url: '',
            amount: 0,
            currency: '£',
            fields: {},
        };

        document.getElementById(HEADER_CONTAINER_ID).style.display = 'none';
        const bodyWrapper = document.getElementById(APP_CONTAINER_ID);
        if (bodyWrapper) {
            bodyWrapper.style.height = '100%';
            bodyWrapper.style.top = '0px';
            bodyWrapper.style.overflow = 'hidden';

            document.addEventListener('scroll', () => {
                bodyWrapper.style.height = '100%';
                bodyWrapper.style.top = '0px';
            });
        }
    }

    componentDidMount() {
        // Get stripe parameters from query string and save to state
        const amount = getUrlParameter('amount');
        const url = getUrlParameter('stripeurl');
        const originalFields = getUrlParameter('fields');

        if (amount && url && originalFields) {
            const fields = JSON.parse(originalFields);
            this.setState({
                amount,
                url,
                fields,
            });
        } else {
            console.error(
                `amount, stripeurl and fields must all be specified in url query parameters. url: ${window.location.href}`,
            );
        }
    }

    handleModalClose() {
        // The app can watch for this navigation change with the onNavigationStateChange
        // callback, and close the webview
        window.location = '/?/close-webview';
    }

    render() {
        const {url, amount, currency, fields, banAmex} = this.state;

        const bodyWrapper = document.getElementById(APP_CONTAINER_ID);
        if (bodyWrapper) {
            bodyWrapper.style.height = '100%';
            bodyWrapper.style.top = '0px';
        }

        return (
            <div>
                <CheckoutWrapper
                    banAmex={banAmex}
                    onModalClose={this.handleModalClose}
                    url={url}
                    amount={amount}
                    currency={currency}
                    fields={fields}
                    isMobile={true}
                />
            </div>
        );
    }
}

export default CheckoutWrapperMobile;
