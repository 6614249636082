import {raiseError} from 'Interfaces/error/Error';

export const isValidCurrencyNumber = (source) =>
    /^£?(\d+|([1-9]\d{0,2}(,\d{3})*))(\.\d{1,2})?$/.test(source);

const raiseInvalidAmountError = (errorMessage) => {
    raiseError('Error', errorMessage);
    throw new Error(errorMessage);
};

export const parseCurrencyString = (source) => {
    if (source[0] !== '£') {
        const errorMessage = `${source[0]} currency is not supported. £ have to be used`;
        raiseInvalidAmountError(errorMessage);
    }

    if (!isValidCurrencyNumber(source)) {
        const errorMessage = `${source} is not a valid currency number`;
        raiseInvalidAmountError(errorMessage);
    }

    const withoutCurrencySign = source.slice(1);
    const withoutThousandSeparator = withoutCurrencySign.replace(/,/g, '');

    return parseFloat(withoutThousandSeparator).toFixed(2);
};
