import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getToDosRequest} from 'BackendRenderedComponents/dashboard/redux/actions';
import {selectToDos} from 'BackendRenderedComponents/dashboard/redux/selectors';
import {KeyboardFocusableLink} from 'Components/keyboardFocusableLink';
import url from 'Services/url/Url';

import {NotificationPanel, NotificationEmptyState} from '../NotificationPanel';

import {ClipboardIcon} from './ClipboardIcon';

export class UnconnectedToDos extends Component {
    componentDidMount() {
        this.props.actions.getToDosRequest();
    }

    render() {
        const {
            toDos: {total, items, isLoading},
            redirectUrl,
        } = this.props;
        return (
            <NotificationPanel
                title="To Do"
                total={total}
                items={items}
                isLoading={isLoading}
                redirectUrl={redirectUrl}
                emptyState={
                    <NotificationEmptyState
                        title={'All done!'}
                        description={
                            <span>
                                Your to-do list is empty right now. Find out how
                                the to-do list works{' '}
                                <KeyboardFocusableLink
                                    url={url.ARBOR.MY_HOMEPAGE_SUPPORT}
                                    target="_blank"
                                >
                                    here
                                </KeyboardFocusableLink>
                                .
                            </span>
                        }
                        icon={<ClipboardIcon />}
                    />
                }
            />
        );
    }
}

UnconnectedToDos.propTypes = {
    toDos: PropTypes.exact({
        isLoading: PropTypes.bool,
        total: PropTypes.number,
        items: PropTypes.arrayOf(
            PropTypes.exact({
                id: PropTypes.string,
                title: PropTypes.string,
                startDate: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
    }),
    redirectUrl: PropTypes.string,
    actions: PropTypes.exact({
        getToDosRequest: PropTypes.func,
    }),
};

const mapStateToProps = (state) => ({
    toDos: selectToDos(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getToDosRequest,
        },
        dispatch,
    ),
});

export const ToDos = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnconnectedToDos);
