import {useKpiData} from 'BackendRenderedComponents/kpiPanel/KpiPanelWithDataFetching/useKpiData';
import {isOldKpiData} from 'Components/kpiPanel';
import {KpiBox, KpiBoxLink, NewKpiBox} from 'Components/kpiPanel/kpiBox';

type KpiDisplayProps = {
    url: string;
};

export const KpiDisplay = (props: KpiDisplayProps) => {
    const {url} = props;

    const {
        items,
        // isLoading,
    } = useKpiData(url);

    return (
        <div>
            {items && (
                <div
                    className="arbor-kpi-panel-content"
                    style={{border: 'none'}}
                >
                    {items.map((item) => (
                        <KpiBoxLink
                            url={item.url}
                            title={item.title}
                            tooltip={item.tooltip || item.tooltipHTML}
                            tooltipUrl={item.tooltipUrl}
                        >
                            {isOldKpiData(item) ? (
                                <KpiBox hideBorder html={item.html} />
                            ) : (
                                <NewKpiBox hideBorder {...item} />
                            )}
                        </KpiBoxLink>
                    ))}
                </div>
            )}
        </div>
    );
};
