import PropTypes from 'prop-types';

import {KeyboardFocusableButton} from 'Components/keyboardFocusableButton';

import './navigation.scss';

export const NavigationRootItems = ({
    navigationData,
    showNavigationSubTree,
}) => (
    <nav className="navigation-root" role="menubar">
        {navigationData &&
            navigationData.map((item, index) => (
                <KeyboardFocusableButton
                    onClick={() => showNavigationSubTree(item.id)}
                    className="navigation-root__item"
                    buttonWrapperClassName="navigation-root__item__wrapper"
                    insetFocusRing
                    role="menuitem"
                    key={index}
                    id={`navigation-root-item_${item.id}`}
                >
                    <span>{item.text}</span>
                    <span className="navigation-root__icon" />
                </KeyboardFocusableButton>
            ))}
    </nav>
);
NavigationRootItems.propTypes = {
    navigationData: PropTypes.array,
    showNavigationSubTree: PropTypes.func,
};
