import {useRef} from 'react';

import {
    DropdownButton,
    IDropdownButtonItem,
    IDropdownButtonItemSeparator,
} from 'Components/button/base/dropdownButton';
import {downloadFile, loadPage} from 'Interfaces/Loader';

interface IMenuButtonItem {
    text?: string;
    url: string;
    icon?: string;
    role?: 'load-page' | 'download-file';
    props?: {
        disabled?: boolean;
    };
}

interface IMenuButtonItemSeparator {
    type?: 'menu-item-separator';
    xtype?: 'menuseparator';
}

interface IMenuButtonProps {
    menu: {
        items: (IMenuButtonItem | IMenuButtonItemSeparator)[];
    };
}

function isItemSeparator(
    item: IMenuButtonItem | IMenuButtonItemSeparator,
): item is IMenuButtonItemSeparator {
    return (
        ('type' in item && item.type === 'menu-item-separator') ||
        ('xtype' in item && item.xtype === 'menuseparator')
    );
}

const sanitizeItems = (
    items: (IMenuButtonItem | IMenuButtonItemSeparator)[],
) => {
    return items.map((item) => {
        if (isItemSeparator(item)) {
            return {
                isDropdownButtonItemSeparator: true,
            } as IDropdownButtonItemSeparator;
        }
        const {props, ...others} = item;
        return {...others, ...props} as IDropdownButtonItem;
    });
};

export function MenuButton(props: IMenuButtonProps) {
    const {menu} = props;

    const menuButton = useRef<HTMLButtonElement>(null);

    const handleItemClick = (item: IDropdownButtonItem) => {
        const {url, role} = item;

        if (!url) {
            throw new Error('No url provided!');
        }

        if (role === 'download-file') {
            downloadFile(url);
        } else {
            loadPage(url, {elementToRefocus: menuButton.current});
        }
    };

    return (
        <DropdownButton
            {...props}
            menu={sanitizeItems(menu.items)}
            onItemClick={handleItemClick}
            ref={menuButton}
        />
    );
}
