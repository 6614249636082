import {renderUIContent} from './Helper';

export const IEBannerData = {
    componentName: 'Arbor.container.Banner',
    props: {
        type: 'error',
        icon: 'danger',
        title: 'We no longer support Internet Explorer',
    },
    content: [
        {
            componentName: 'Arbor.container.SimpleText',
            content:
                '<p>You will not be able to use some features if you continue using Internet Explorer.</p><p>Please choose another browser - Chrome (recommended), Firefox or Microsoft Edge.</p>',
            type: 'content',
        },
    ],
};

export const IEBanner = () => renderUIContent(IEBannerData);
