import PropTypes from 'prop-types';

export const ThumbTack = ({
    style = {},
    width = '100%',
    className = '',
    viewBox = '0 0 8 8',
}) => (
    <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path d="M7.61362 3.97788L6.11837 5.51123L7.78214 7.48932C7.83629 7.55593 7.84056 7.65102 7.78475 7.70825L7.7813 7.71179C7.72549 7.76902 7.6385 7.77512 7.5671 7.7262L5.45279 6.19503L4.04499 7.6387C3.92302 7.76377 3.71099 7.75959 3.57757 7.62948C2.95197 7.01943 2.78991 5.99699 3.25016 5.52501L1.29785 3.6212C1.05392 3.87135 0.631074 3.86301 0.36422 3.60279C0.097367 3.34257 0.0783401 2.91886 0.322275 2.66871L2.55278 0.381379C2.79671 0.13123 3.21956 0.13957 3.48641 0.399794C3.75327 0.660018 3.77224 1.08252 3.5283 1.33267L5.48061 3.23647C5.94087 2.76449 6.96769 2.9014 7.59265 3.51084C7.72613 3.64216 7.73502 3.8534 7.61362 3.97788Z" />
    </svg>
);

ThumbTack.propTypes = {
    style: PropTypes.object,
    width: PropTypes.string,
    className: PropTypes.string,
    viewBox: PropTypes.string,
};
