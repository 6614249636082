import {JiraFeedbackButton} from 'App/header/buttons/jiraFeedback/JiraFeedbackButton';
import {DropdownContainer} from 'Components/dropdown';
import {getUser} from 'Interfaces/Session';

import {ArborHQButton} from '../arborHQButton';
import {HelpCentreButton} from '../helpCentreButton';
import {HelpCentreIcon} from '../helpCentreButton/HelpCentreIcon';
import {SideBarButton} from '../sideBarButton';
import {TrainingHubButton} from '../trainingHubButton';

import './helpDropdownButton.scss';

export const HelpDropdownButton = () => {
    const {showCommunityButton, showTrainingHubButton} = getUser() || {};

    if (!showCommunityButton && !showTrainingHubButton) {
        return <HelpCentreButton />;
    }

    return (
        <DropdownContainer
            renderDropdownField={({toggleDropdown, isDropdownVisible}) => (
                <SideBarButton
                    onClick={toggleDropdown}
                    buttonText="Help & Learn with Arbor"
                    tooltip="Arbor HQ, Help Centre, and training resource"
                    isActive={isDropdownVisible}
                >
                    <HelpCentreIcon />
                </SideBarButton>
            )}
            renderDropdownContents={() => (
                <div className="help-dropdown-container">
                    <HelpCentreButton />
                    {showCommunityButton && <ArborHQButton />}
                    {showTrainingHubButton && <TrainingHubButton />}
                    <JiraFeedbackButton />
                </div>
            )}
            dropdownWidth="150px"
            preferPosition="right"
            alignDropdown="end"
            offset={0}
        />
    );
};
