export const FILTER_TYPE_ENUM = {
    STUDENT_GROUP: 'studentGroups',
    DEMOGRAPHIC: 'demographics',
    KPI_GROUP: 'kpiGroups',
};

export const ALERT_TYPE_ENUM = {
    PINNED_NOTE: 'NEW_PINNED_NOTE',
    BIRTHDAY: 'BIRTHDAY',
    DETENTION: 'ASSIGNED_TO_DETENTION',
    SAFEGUARDING_NOTE: 'NEW_SAFEGUARDING_NOTE',
    CUSTOM_GROUP_JOINED: 'CUSTOM_GROUP_JOINED',
    CUSTOM_GROUP_LEFT: 'CUSTOM_GROUP_LEFT',
};

export const TAB_IDS = {
    WHOLE_SCHOOL: 'whole-school',
    MY_STUDENTS: 'my-students',
};
