import {TableHeaderButton} from 'Components/sharedTableSubComponents/tableHeaderButton';
import url from 'Services/url/Url';

type ITableHeaderProps = {
    helpUrl?: string;
    helpText?: string;
};
export const TableHelpButton = ({
    helpUrl = url.ARBOR.TABLE_SUPPORT_PAGE,
    helpText = "Click here to learn about the new features we've added to tables.",
}: ITableHeaderProps) => {
    return (
        <TableHeaderButton
            icon="help"
            aria-label={helpText}
            tooltip={helpText}
            onClick={() => {
                window.open(helpUrl, '_blank')?.focus();
            }}
        />
    );
};
