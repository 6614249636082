export default Object.freeze({
    SECTION: {
        EMPTY_TEXT: 'No results found',
    },
    BANNER: {
        EMPTY_TEXT: 'There is no user content',
    },
    ERROR: {
        GENERIC_TITLE: 'GENERIC_ERROR_TITLE',
        GENERIC_MESSAGE: 'GENERIC_ERROR_MESSAGE',
    },
    CHECKOUT_STIPE: {
        TITLE: 'Purchase complete!',
        CONTENT:
            'Your reports are now available in the My Reports section of your portal. As a reminder, your schools will need to sign up or log in to their own portal to access their reports themselves at: ',
        BUTTON: 'Go to My Reports page',
    },
    CLIPBOARD: {
        SUCCESS: 'Data has been copied to clipboard',
    },
});
