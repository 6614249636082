import classNames from 'classnames';
import {useState} from 'react';

import {Button} from 'Components/button/base';
import {SimpleText} from 'Components/simpleText';
import {getFormFieldsForAction} from 'Interfaces/formFields';
import {ExtComponentRenderer} from 'Renderer/ExtComponentRenderer';

import './formFieldValueChecker.scss';
type FormFieldValueCheckerProps = {
    actionMappings: Record<string, string>;
    formFieldToCheck: string;
};

export const FormFieldValueChecker = (props: FormFieldValueCheckerProps) => {
    const [previewContent, setPreviewContent] = useState('');

    const updatePreview = () => {
        const formName = Object.keys(props.actionMappings)[0];
        const formFields = getFormFieldsForAction(formName);
        const matchingFormField = formFields.find(
            (field) => field.name === props.formFieldToCheck,
        );
        if (!matchingFormField) {
            console.error(
                'Could not match value checker to form field',
                formName,
                formFields,
                props.formFieldToCheck,
            );
            return;
        }
        const value = matchingFormField.getValue();
        setPreviewContent(value);

        // eslint-disable-next-line no-console
        console.log(
            `FormFieldValueChecker value for ${props.formFieldToCheck}: `,
            value,
        );
    };

    return (
        <div className="form-field-value-checker__container">
            <Button
                onClick={updatePreview}
                text={`Show preview for: ${props.formFieldToCheck}`}
            ></Button>
            <div
                className={classNames({
                    'form-field-value-checker__preview-box--hidden':
                        previewContent === '',
                })}
            >
                <h3>SimpleText</h3>
                <div className="form-field-value-checker__preview-box">
                    <SimpleText>{previewContent}</SimpleText>
                </div>
                <h3>HTMLContainer</h3>
                <div className="form-field-value-checker__preview-box">
                    <ExtComponentRenderer
                        componentName="Mis.container.HtmlContainer"
                        content={previewContent}
                        key={previewContent}
                    ></ExtComponentRenderer>
                </div>
            </div>
        </div>
    );
};
