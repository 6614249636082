import exportInterface from 'Interfaces/Base';

export function showMsg(config) {
    Ext.Msg.show(config);
}

export function showOkButton() {
    return Ext.MessageBox.OK;
}

export const registerWindowInterface = () => {
    exportInterface('Window', {showMsg});
};
