export const colorNames = {
    grapePurple: 'grape_purple',
    blueberryBlue: 'blueberry_blue',
    skyBlue: 'sky_blue',
    basilGreen: 'basil_green',
    bananaYellow: 'banana_yellow',
    tangerineOrange: 'tangerine_orange',
    tomatoRed: 'tomato_red',
    white: 'white',
} as const;

type ColorName = (typeof colorNames)[keyof typeof colorNames];

export const colorPrefixes = {
    base: '',
    light: 'light_',
    lighter: 'lighter_',
} as const;

const capitalizeFirstLetter = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1);
const snakeCaseToSentenceCase = (snakeCase: string): string =>
    capitalizeFirstLetter(snakeCase.replace(/_/g, ' '));

type Color = {
    background: string;
    foreground: string;
    name: string;
    displayName: string;
};

type ColorPaletteEntry = {
    name: ColorName;
    colors: Color[];
};

export const colorPalette: ColorPaletteEntry[] = [
    {
        name: colorNames.grapePurple,
        colors: [
            {
                background: '#e0afca',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.grapePurple}`,
            },
            {
                background: '#f0d7e5',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.grapePurple}`,
            },
        ],
    },
    {
        name: colorNames.blueberryBlue,
        colors: [
            {
                background: '#61afda',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.blueberryBlue}`,
            },
            {
                background: '#a5d1ea',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.blueberryBlue}`,
            },
        ],
    },
    {
        name: colorNames.skyBlue,
        colors: [
            {
                background: '#93d6fc',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.skyBlue}`,
            },
            {
                background: '#c1e8fd',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.skyBlue}`,
            },
        ],
    },
    {
        name: colorNames.basilGreen,
        colors: [
            {
                background: '#8cd0ca',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.basilGreen}`,
            },
            {
                background: '#c6e7e4',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.basilGreen}`,
            },
        ],
    },
    {
        name: colorNames.bananaYellow,
        colors: [
            {
                background: '#f6ef8e',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.bananaYellow}`,
            },
            {
                background: '#fbf7c6',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.bananaYellow}`,
            },
        ],
    },
    {
        name: colorNames.tangerineOrange,
        colors: [
            {
                background: '#f0c566',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.tangerineOrange}`,
            },
            {
                background: '#f8e2b3',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.tangerineOrange}`,
            },
        ],
    },
    {
        name: colorNames.tomatoRed,
        colors: [
            {
                background: '#e06e6e',
                foreground: '#000',
                name: `${colorPrefixes.light}${colorNames.tomatoRed}`,
            },
            {
                background: '#efb6b6',
                foreground: '#000',
                name: `${colorPrefixes.lighter}${colorNames.tomatoRed}`,
            },
        ],
    },
    {
        name: colorNames.white,
        colors: [
            {
                background: '#fff',
                foreground: '#000',
                name: `${colorPrefixes.base}${colorNames.white}`,
            },
        ],
    },
].map((colorGroup) => ({
    ...colorGroup,
    colors: colorGroup.colors.map((color) => ({
        ...color,
        displayName: snakeCaseToSentenceCase(color.name),
    })),
}));

// Gets the color object associated with the color name. If no color found for the input,
// or if the input is null, undefined is returned. (Note, the correct value for empty color is null,
// but we handle the empty string for backwards compatibility)
export const getColorFromName = (name): Color | undefined => {
    if (name === null || name === '') {
        return undefined;
    }
    const colorsArray = colorPalette
        .map((colorGroup) => colorGroup.colors)
        .reduce((acc, val) => acc.concat(val), []);
    const foundColor = colorsArray.find((color) => color.name === name);

    if (typeof foundColor === 'undefined') {
        console.warn(
            `Color name ${name} does not match one in the color palette!`,
        );
    }
    return foundColor;
};
