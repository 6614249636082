import {SearchItem} from './useSearchQuery';

export const groupSearchResults = (items: SearchItem[]) => {
    const groupedItems = items.reduce<Record<string, SearchItem[]>>(
        (acc, curr) => {
            acc[curr.groupId] = [...(acc[curr.groupId] || []), curr];

            return acc;
        },
        {},
    );

    return Object.entries(groupedItems).map(([, value]) => value);
};
